import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { IoIosArrowDropdownCircle } from "react-icons/io";
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  accordion: {
    background: '#F3F6FF',
    width:'60%'
  },
}));

export default function AccordionFaq({datas,setData}) {

  React.useEffect(() => {
		if (datas?.faqs && typeof datas?.faqs === "string") {
			const faqsArray = datas?.faqs
				.replace(/\["/g, "")
				.replace(/"\]/g, "")
				.split('", "');
			setData((prevData) => ({
				...prevData,
				faqs: faqsArray,
			}));
		}

	}, [ datas?.faqs, setData]);

console.log(datas?.faqs, 'allDatsAreHere');
  return (
    <div>
      <Accordion className="accordionData">
        <AccordionSummary
          expandIcon={<IoIosArrowDropdownCircle />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Typography className='font-bold font-plusJakartaSans text-sm'>Accordion 2</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            malesuada lacus ex, sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
