import { useEffect, useState } from "react";
import EntranceExamHeadPage from "../../Components/EntranceExam/EntranceExamDetailsPage/EntranceExamHeadPage";
import SelectEntranceExamCardChips from "../../Components/EntranceExam/EntranceExamDetailsPage/SelectEntranceExamCardChips";
import EntranceExamOverview from "../../Components/EntranceExam/EntranceExamDetailsPage/EntranceExamOverview";
import AboutExam from "../../Components/EntranceExam/EntranceExamDetailsPage/Overview/AboutExam";
import HighlightExam from "../../Components/EntranceExam/EntranceExamDetailsPage/Overview/HighlightExam";
import CourseAvailable from "../../Components/EntranceExam/EntranceExamDetailsPage/Overview/CourseAvailable";
import AlternativeExam from "../../Components/EntranceExam/EntranceExamDetailsPage/Overview/AlternativesExam";
import FlowChart from "../../Components/EntranceExam/EntranceExamDetailsPage/Overview/FlowChartExam";
import TopCollegesAcceptingScore from "../../Components/EntranceExam/EntranceExamDetailsPage/Overview/TopCollegesAcceptingScore";
import ExamReviews from "../../Components/EntranceExam/EntranceExamDetailsPage/Overview/ExamReviews";
import LastestEntranceExamNews from "../../Components/EntranceExam/EntranceExamDetailsPage/Overview/LatestEntranceExamNews";
import DownloadPractice from "../../Components/EntranceExam/EntranceExamDetailsPage/Overview/DownloadPractice";
import AskQuestion from "../../Components/EntranceExam/EntranceExamDetailsPage/Overview/AskQuestion";
import ConfusionCard from "../../Components/EntranceExam/EntranceExamDetailsPage/Overview/ConfusionCard";
import AdmissionChances from "../../Components/EntranceExam/EntranceExamDetailsPage/Overview/AdmissionChances";
import { useParams } from "react-router-dom";
import axiosInstance from "../../config/Axios/axiosInstance";
import { API_URLS } from "../../config/API_URLS";
import EntranceCourseTable from "../../Components/EntranceExam/EntranceExamDetailsPage/Application/EntranceCourseTable";
import EntranceRegistration from "../../Components/EntranceExam/EntranceExamDetailsPage/Application/EntranceRegistration";
import ApplicationAndWebsiteLink from "../../Components/EntranceExam/EntranceExamDetailsPage/Application/ApplicationAndWebsiteLink";
import EntranceFeeApplicationTable from "../../Components/EntranceExam/EntranceExamDetailsPage/Application/EntranceFeeApplicationTable";
import EntraceApplicationProcedure from "../../Components/EntranceExam/EntranceExamDetailsPage/Application/EntranceApplicationProcedure";
import EntranceFormCorrections from "../../Components/EntranceExam/EntranceExamDetailsPage/Application/EntranceFormCorrections";
import EntranceAdmitCards from "../../Components/EntranceExam/EntranceExamDetailsPage/Application/EntranceAdmitCard";
import EntranceExamMode from "../../Components/EntranceExam/EntranceExamDetailsPage/EntranceExamPattern/EntranceExamMode";
import EntranceRulesAndRegulations from "../../Components/EntranceExam/EntranceExamDetailsPage/EntranceExamPattern/EntranceRulesRegulations";
import EntranceExamDay from "../../Components/EntranceExam/EntranceExamDetailsPage/EntranceExamPattern/EntranceExamDay";
import EntranceCheckList from "../../Components/EntranceExam/EntranceExamDetailsPage/EntranceExamPattern/EntranceCheckList";
import EntranceExamCentersCards from "../../Components/EntranceExam/EntranceExamDetailsPage/EntranceExamPattern/EntranceExamCentersCards";
import EntranceSyllabus from "../../Components/EntranceExam/EntranceExamDetailsPage/EntranceExamPattern/EntranceSyllabus";
import EntranceSyllabusPoints from "../../Components/EntranceExam/EntranceExamDetailsPage/EntranceExamPattern/EntranceSyllabusPoints";
import EntranceExamImportantPoints from "../../Components/EntranceExam/EntranceExamDetailsPage/EntranceExamPattern/EntranceExamImportantPoints";
import EntranceStudyMaterial from "../../Components/EntranceExam/EntranceExamDetailsPage/EntranceExamPattern/EntranceStudyMaterial";
import ImportantDatesTables from "../../Components/EntranceExam/EntranceExamDetailsPage/ImportantDates/ImportantDatesTable";
import SelecteEntranceDates from "../../Components/EntranceExam/PreviousPapers/SelectEntranceDates";
import SamplePapersQuestions from "../../Components/EntranceExam/PreviousPapers/SamplePapersQuestions";
import EntranceCourseChip from "../../Components/EntranceExam/EntranceExamDetailsPage/EntranceExamPattern/EntranceCourseChip";
import PreviousPaperSubjectsChips from "../../Components/EntranceExam/PreviousPapers/PreviousPaperSubjectsChips";
import TipsFromExpertsChips from "../../Components/EntranceExam/EntranceExamDetailsPage/TipsFromExperts/TipsFromExpertsChips";
import TipsFromExpertsSubjectName from "../../Components/EntranceExam/EntranceExamDetailsPage/TipsFromExperts/TipsFromExpertsSubjectName";
import CollegeExam from "../../Components/College/CollegeDetailsView/CollegeExam";
import ExploreOtherSection from "../Profile/SessionSchedule/ExploreOtherSection";
import ResultHighlights from "../../Components/EntranceExam/EntranceExamDetailsPage/Result/ResultHighlights";
import ResultScoreCard from "../../Components/EntranceExam/EntranceExamDetailsPage/Result/ResultScoreCard";
import ResultTieBreakup from "../../Components/EntranceExam/EntranceExamDetailsPage/Result/ResultTieBreakUp";
import ResultTieBreakUpTable from "../../Components/EntranceExam/EntranceExamDetailsPage/Result/ResultTieBreakUpTable";
import ResultTopCandidates from "../../Components/EntranceExam/EntranceExamDetailsPage/Result/ResultTopCandidates";
import ResultAnswerKeys from "../../Components/EntranceExam/EntranceExamDetailsPage/Result/ResultAnswerKeys";
import ResultKeys from "../../Components/EntranceExam/EntranceExamDetailsPage/Result/ResultKeys";
import ResultRevaluating from "../../Components/EntranceExam/EntranceExamDetailsPage/Result/ResultReevaluating";
import AgenciesChips from "../../Components/EntranceExam/EntranceExamDetailsPage/Counselling/AgenciesChips";
import AgencyIntroduction from "../../Components/EntranceExam/EntranceExamDetailsPage/Counselling/AgencyIntroduction";
import AgencyRegister from "../../Components/EntranceExam/EntranceExamDetailsPage/Counselling/AgencyRegister";
import AgencyImportantLinks from "../../Components/EntranceExam/EntranceExamDetailsPage/Counselling/AgenctImportantLinks";
import AgencyFeeApplicable from "../../Components/EntranceExam/EntranceExamDetailsPage/Counselling/AgencyFeeApplicable";
import AgencyCouncellingProcess from "../../Components/EntranceExam/EntranceExamDetailsPage/Counselling/AgencyCouncellingProcess";
import AgencyQuotaReservation from "../../Components/EntranceExam/EntranceExamDetailsPage/Counselling/AgencyQuotaReservation";
import AgencySeatMatrix from "../../Components/EntranceExam/EntranceExamDetailsPage/Counselling/AgencySeatMatrix";
import AgencyChoiceModification from "../../Components/EntranceExam/EntranceExamDetailsPage/Counselling/AgencyChoiceModification";
import AgencyCheckList from "../../Components/EntranceExam/EntranceExamDetailsPage/Counselling/AgencyCheckList";
import AgencyPdf from "../../Components/EntranceExam/EntranceExamDetailsPage/Counselling/AgencyPdf";
import AgencyPdfsCards from "../../Components/EntranceExam/EntranceExamDetailsPage/Counselling/AgencyPdfsCards";
import AgencyTopColleges from "../../Components/EntranceExam/EntranceExamDetailsPage/Counselling/AgencyTopColleges";
import AgencyImportantLinks1 from "../../Components/EntranceExam/EntranceExamDetailsPage/Counselling/AgencyImportantLinks1";
import NewsCard from "../../Components/EntranceExam/EntranceExamDetailsPage/News/NewsCards";
import NewsArticlesChips from "../../Components/EntranceExam/EntranceExamDetailsPage/News/NewsArticlesChips";
import TipsFromExpertSubject from "../../Components/EntranceExam/EntranceExamDetailsPage/TipsFromExperts/TipsFromExpertSubject";

function EntranceExamDetails() {
	const [showTabs, setShowtabs] = useState(1);
	const [ExamOverview, setExamOverview] = useState();
	const [ImportantDates, setImportanceDates] = useState([]);
	const [EntranceExam, setEntranceExam] = useState();
	const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
	const [subjectIdChip, setSubjectIdChip] = useState(null);
	const [courseIdChip, sertCourseChipId] = useState(null);
	const [TipsExperts, setTipsExperts] = useState([]);
	const [papersChips, setPapersChips] = useState();
	const [previuosPapers, serPreviousPapers] = useState([]);
	const [examPattern, setExamPattern] = useState();
	const [result, setResult] = useState();
	const [councelling, setCouncelling] = useState([]);
	const [councellingChips, setCouncellingChips] = useState();

	const { id } = useParams();
	const api = {
		1: `${API_URLS.getEntranceExamOverview}?exam_id=${id}`,
		2: `${API_URLS.getEntranceExamDates}?exam_id=${id}`,
		3: `${API_URLS.getEntranceExamApplication}?exam_id=${id}`,
		4: `${API_URLS.getEntranceExamPattern}?exam_id=${id}`,
		6: `${API_URLS.getEntranceExamPreviousPapers}?exam_id=${id}&course_id=${courseIdChip}&subject_id=${subjectIdChip}&year=${selectedYear}&type=${papersChips}`,
		7: `${API_URLS.getEntranceExamExpertTips}?exam_id=${id}&course_id=${courseIdChip}&subject_id=${subjectIdChip}`,
		8: `${API_URLS.getEntranceExamResultCounselling}?exam_id=${id}`,
		9: `${API_URLS.getEntranceExamResultCounselling}?exam_id=${id}`,
	};
	const setDataMapping = {
		1: setExamOverview,
		2: setImportanceDates,
		3: setEntranceExam,
		4: setExamPattern,
		6: serPreviousPapers,
		7: setTipsExperts,
		8: setResult,
		9: setCouncelling,
	};

	console.log(councelling?.counselling_agencies, "councelling");
	console.log(councellingChips, "councellingChips");

	useEffect(() => {
		const fetchCollegeDetails = async () => {
			if (showTabs === 7 && (!courseIdChip || !subjectIdChip)) {
				return;
			}

			const url = api[showTabs];
			const res = await axiosInstance.get(url);
			if (res) {
				setDataMapping[showTabs](res?.result);
			}
		};

		fetchCollegeDetails();
	}, [id, showTabs, subjectIdChip, courseIdChip, papersChips, selectedYear]);

	useEffect(() => {
		if (showTabs !== 7) {
			sertCourseChipId(null);
			setSubjectIdChip(null);
			setTipsExperts([]);
		}
	}, [showTabs]);

	return (
		<div className="bg-[#F3F6FF] pb-10 h-auto">
			<div className="ml-auto mr-auto pt-10 w-[67%]">
				<EntranceExamHeadPage ExamOverview={ExamOverview} />
				<div className="flex w-[100%]">
					<div className="w-[12%] mt-5">
						<SelectEntranceExamCardChips setShowtabs={setShowtabs} />
					</div>
					<div className="w-[59%] h-[100%] ml-10">
						{showTabs === 1 && (
							<>
								<div className="bg-white rounded-lg shadow-custom1 mt-5 pb-10">
									<EntranceExamOverview />
									<AboutExam ExamOverview={ExamOverview} />
									<HighlightExam ExamOverview={ExamOverview} />
									<CourseAvailable ExamOverview={ExamOverview} />
									<AlternativeExam ExamOverview={ExamOverview} />
									<FlowChart ExamOverview={ExamOverview} />
									<TopCollegesAcceptingScore ExamOverview={ExamOverview} />
								</div>
								<div className="mt-5 w-[100%]">
									<ExamReviews />
								</div>
								<div className="mt-5 w-[100%] mb-10  bg-white rounded-lg shadow-custom1">
									<LastestEntranceExamNews />
								</div>
							</>
						)}
						{showTabs === 2 && (
							<>
								<div className="bg-white rounded-lg shadow-custom1 mt-5 pb-10">
									<EntranceExamOverview showTabs={showTabs} />
									<div className="w-[100%] flex items-center text-center gap-1">
										{/* <ImportantDatesCalender />
										<ImportantDatesCards /> */}
										<ImportantDatesTables ImportantDates={ImportantDates} />
									</div>
								</div>
								<CollegeExam />
								<div className="mt-5 w-[100%] mb-10  bg-white rounded-lg shadow-custom1">
									<LastestEntranceExamNews />
								</div>
							</>
						)}
						{showTabs === 3 && (
							<>
								<div className="bg-white rounded-lg shadow-custom1 mt-5 pb-16 mb-20">
									<EntranceExamOverview showTabs={showTabs} />
									<AboutExam showTabs={showTabs} EntranceExam={EntranceExam} />
									{/* <EntranceCourseTable /> */}
									<EntranceRegistration EntranceExam={EntranceExam} />
									<ApplicationAndWebsiteLink EntranceExam={EntranceExam} />
									<EntranceFeeApplicationTable EntranceExam={EntranceExam} />
									<EntraceApplicationProcedure EntranceExam={EntranceExam} />
									<EntranceFormCorrections EntranceExam={EntranceExam} />
									<EntranceAdmitCards EntranceExam={EntranceExam} />
								</div>
							</>
						)}
						{showTabs === 4 && (
							<>
								<div className="bg-white rounded-lg shadow-custom1 mt-5 pb-16 mb-20">
									<EntranceExamOverview showTabs={showTabs} />
									<AboutExam showTabs={showTabs} examPattern={examPattern} />
									<EntranceExamMode examPattern={examPattern} />
									<EntranceRulesAndRegulations examPattern={examPattern} />
									<EntranceExamDay examPattern={examPattern} />
									<EntranceCheckList examPattern={examPattern} />
									<EntranceExamCentersCards examPattern={examPattern} />
									<EntranceSyllabus examPattern={examPattern} />
									<EntranceSyllabusPoints examPattern={examPattern} />
									<EntranceExamImportantPoints examPattern={examPattern} />
									<EntranceStudyMaterial
										examPattern={examPattern}
										showTabs={showTabs}
									/>
								</div>
							</>
						)}
						{showTabs === 6 && (
							<>
								<div className="bg-white rounded-lg shadow-custom1 mt-5 pb-16">
									<EntranceExamOverview showTabs={showTabs} />
									<SelecteEntranceDates
										selectedYear={selectedYear}
										setSelectedYear={setSelectedYear}
									/>
									<SamplePapersQuestions setPapersChips={setPapersChips} />
									<TipsFromExpertsChips
										id={id}
										sertCourseChipId={sertCourseChipId}
									/>
									<TipsFromExpertSubject
										setSubjectIdChip={setSubjectIdChip}
										id={id}
									/>
									<EntranceStudyMaterial
										previuosPapers={previuosPapers}
										showTabs={showTabs}
									/>
								</div>
								<div className="mt-5 w-[100%] mb-5">
									<ExamReviews />
								</div>
							</>
						)}
						{showTabs === 7 && (
							<>
								<div className="bg-white rounded-lg shadow-custom1 mt-5 pb-16">
									<EntranceExamOverview showTabs={showTabs} />
									<TipsFromExpertsChips
										id={id}
										sertCourseChipId={sertCourseChipId}
									/>
									<TipsFromExpertSubject
										setSubjectIdChip={setSubjectIdChip}
										id={id}
									/>
									<TipsFromExpertsSubjectName TipsExperts={TipsExperts} />
								</div>
							</>
						)}
						{showTabs === 8 && (
							<>
								<div className="bg-white rounded-lg shadow-custom1 mt-5 pb-16">
									<EntranceExamOverview showTabs={showTabs} />
									<ResultHighlights result={result} />
									<ResultScoreCard result={result} />
									<ResultTieBreakup result={result} />
									{/* <ResultTieBreakUpTable /> */}
									<ResultTopCandidates result={result} />
									<ResultAnswerKeys result={result} />
								</div>
							</>
						)}
						{showTabs === 9 && (
							<>
								<div className="bg-white rounded-lg shadow-custom1 mt-5 pb-16">
									<EntranceExamOverview showTabs={showTabs} />
									<AgenciesChips
										councelling={councelling}
										setCouncellingChips={setCouncellingChips}
									/>
									<AgencyIntroduction
										councelling={councelling}
										councellingChips={councellingChips}
									/>
									<AgencyRegister
										councelling={councelling}
										councellingChips={councellingChips}
									/>
									{/* <AgencyImportantLinks /> */}
									{/* <AgencyFeeApplicable /> */}
									<AgencyCouncellingProcess
										councelling={councelling}
										councellingChips={councellingChips}
									/>
									<AgencyQuotaReservation
										councelling={councelling}
										councellingChips={councellingChips}
									/>
									<AgencySeatMatrix
										councelling={councelling}
										councellingChips={councellingChips}
									/>
									{/* <AgencyChoiceModification
										councelling={councelling}
										councellingChips={councellingChips}
									/> */}
									<AgencyCheckList
										councelling={councelling}
										councellingChips={councellingChips}
									/>
									<AgencyPdf
										councelling={councelling}
										councellingChips={councellingChips}
									/>
									<AgencyPdfsCards
										councelling={councelling}
										councellingChips={councellingChips}
									/>
									<AgencyTopColleges
										councelling={councelling}
										councellingChips={councellingChips}
									/>
									{/* <AgencyImportantLinks1 /> */}
								</div>
							</>
						)}
						{showTabs === 10 && (
							<>
								<div className="bg-white rounded-lg shadow-custom1 mt-5 pb-16">
									<EntranceExamOverview showTabs={showTabs} />
									<NewsArticlesChips />
									<NewsCard />
								</div>
							</>
						)}
					</div>
					<div className="w-[24%] mt-5 ml-5 flex flex-col items-center">
						<div className="bg-white w-[100%] rounded-lg shadow-custom1 pb-6 pt-3">
							<DownloadPractice />
						</div>
						<div className="mt-11 w-[100%]">
							<AskQuestion />
						</div>
						<div className="mt-5 w-[100%] flex justify-center items-center">
							<ConfusionCard />
						</div>
						<div className="mt-5 w-[100%] mb-10 flex justify-center items-center">
							<AdmissionChances />
						</div>
					</div>
				</div>
			</div>
			{(showTabs === 2 || showTabs === 6) && (
				<div className="w-[67%] bg-white rounded-lg shadow-custom1 pt-3 pb-3 ml-auto mr-auto">
					<ExploreOtherSection />
				</div>
			)}
			{showTabs === 7 && (
				<div className="w-[67%] bg-white rounded-lg shadow-custom1 pt-3 pb-3 mt-5 ml-auto mr-auto">
					<EntranceStudyMaterial
						TipsExperts={TipsExperts}
						showTabs={showTabs}
					/>
				</div>
			)}
			{showTabs === 8 && (
				<>
					<div className="w-[67%] bg-white rounded-lg shadow-custom1 pt-3 pb-3 mt-5 ml-[27%]">
						<ResultKeys result={result} />
					</div>
					<div className="w-[67%] bg-white rounded-lg shadow-custom1 pt-3 pb-3 mt-5 ml-[27%]">
						<ResultRevaluating result={result} />
					</div>
				</>
			)}
		</div>
	);
}

export default EntranceExamDetails;
