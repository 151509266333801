import { medias } from "../../../../config/Resources";
import AvailableSeatsTable from "./AvailableSeatsTable";
import ExamPattern from "./ExamPattern";

function ApplicationProcedure({scholarshipDetails}) {
	return (
		<div className="ml-4 mr-4 mt-5 border border-[#707070] rounded-lg border-opacity-30">
			<div className="pl-3 pr-3 pt-4">
				<p className="font-plusJakartaSans font-bold text-[14px] flex items-center text-center gap-1">
					<img
						src={medias.images.College.Procedure}
						alt="LeftChevron"
						className="w-6 h-6"
					/>{" "}
					<span className="font-plusJakartaSans font-bold text-[14px] mt-[3px]">
                    Application Procedure
					</span>
				</p>
			</div>
			{
				scholarshipDetails?.map((data) => {
					return (

					
			<div
				dangerouslySetInnerHTML={{ __html: data?.application_procedure }}
				className="font-plusJakartaSans font-medium text-[10.5px] text-[#000000] text-opacity-70 pl-3 pr-3 pt-2 pb-4"
			></div>
			)
		})
			}
            <ExamPattern scholarshipDetails={scholarshipDetails}/>
            <AvailableSeatsTable scholarshipDetails ={scholarshipDetails}/>
		</div>
	);
}

export default ApplicationProcedure;
