import { GoDotFill } from "react-icons/go";
import { medias } from "../../../config/Resources";
import { IoEyeOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

function EditorPickCards({ blogsData }) {
	const formatDate = (dateString) => {
		const options = { day: "numeric", month: "long", year: "numeric" };
		const date = new Date(dateString);
		return new Intl.DateTimeFormat("en-GB", options).format(date);
	};
	const navigate = useNavigate();
	return (
		<div className="pr-6 w-[100%]">
			<div className="grid grid-cols-1 gap-x-10 ">
				{blogsData?.editorsPicks?.map((item, index) => (
					<div
						onClick={() => navigate(`/blog-details/${item?.id}`)}
						key={index}
						className={`pb-4 mb-4 ${
							index < item.length - 2
								? "border-b-2 border-dashed border-[#707070] border-opacity-20"
								: ""
						}`}
					>
						<div className="flex items-center text-center gap-[12px]">
							<img
								className="w-[8.8rem] h-[7.8rem] mb-2 rounded-lg scale-95"
								src={item?.cover_image}
								alt="CollegeImage"
							/>
							<div className="text-left mt-[-10px]">
								<div className="flex flex-wrap gap-[10px] mb-2">
									{item?.tags?.split(",").map((tag, idx) => (
										<p className="font-plusJakartaSans rounded-full bg-[#F37400] bg-opacity-10 pt-[5px] pb-[5px] pl-4 pr-4 inline-flex text-[13px] text-center gap-1 font-semibold cursor-pointer">
											{tag.trim()}
										</p>
									))}
								</div>
								<p className="font-plusJakartaSans text-[17px] font-bold text-[#000000] pr-5 mb-1">
									{item?.title}
								</p>
								<p className="flex items-center text-center gap-1">
									<IoEyeOutline className="w-6 h-6 mt-[-3px] text-[#000000] text-opacity-80" />
									<span className="font-plusJakartaSans font-medium text-[14.5px] text-[#000000] text-opacity-80">
										44k views
									</span>
									<GoDotFill className="text-[#000000] text-opacity-40 w-2 h-2" />
									<span className="font-plusJakartaSans font-medium text-[14.5px] text-[#000000] text-opacity-80">
										2 min read
									</span>
									<GoDotFill className="text-[#000000] text-opacity-40 w-2 h-2" />
									<span className="font-plusJakartaSans font-medium text-[14.5px] text-[#000000] text-opacity-80">
										{formatDate(item?.created_at)}
									</span>
								</p>
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	);
}

export default EditorPickCards;
