function CouncellorAndMentor() {
	return (
		<div className="m-5">
			<p className="font-bold font-plusJakartaSans text-lg">Counsellor Remarks</p>
			<p className="font-normal font-plusJakartaSans text-sm mt-2">
				Lorem Ipsum is simply dummy text of the printing and typesetting
				industry. Lorem Ipsum has been the industry's standard dummy text ever
				since the 1500s, when an unknown printer took a galley of type and
				scrambled it to make a type specimen book. It has survived not only five
				centuries, but also the leap into electronic typesetting, remaining
				essentially unchanged.Lorem Ipsum is simply dummy text of the printing
				and typesetting industry. Lorem Ipsum has been the industry's standard
				dummy text ever since the 1500s, when an unknown printer took a galley
				of type and scrambled it to make a type specimen book. It has survived
				not only five centuries, but also the leap into electronic typesetting,
				remaining essentially unchanged.
			</p>
			<p className="font-bold font-plusJakartaSans text-lg mt-4">Mentor Remarks</p>
			<p className="font-normal font-plusJakartaSans text-sm mt-2">
				Lorem Ipsum is simply dummy text of the printing and typesetting
				industry. Lorem Ipsum has been the industry's standard dummy text ever
				since the 1500s, when an unknown printer took a galley of type and
				scrambled it to make a type specimen book. It has survived not only five
				centuries, but also the leap into electronic typesetting, remaining
				essentially unchanged.Lorem Ipsum is simply dummy text of the printing
				and typesetting industry. Lorem Ipsum has been the industry's standard
				dummy text ever since the 1500s, when an unknown printer took a galley
				of type and scrambled it to make a type specimen book. It has survived
				not only five centuries, but also the leap into electronic typesetting,
				remaining essentially unchanged.
			</p>
		</div>
	);
}

export default CouncellorAndMentor;
