import React, { useRef } from "react";
import { medias } from "../../../../config/Resources";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";

/* eslint-disable jsx-a11y/img-redundant-alt */
function ResultKeys({ result }) {
	const scrollContainerRef = useRef(null);

	const handleScroll = () => {
		if (scrollContainerRef.current) {
			scrollContainerRef.current.scrollBy({
				left: 200,
				behavior: "smooth",
			});
		}
	};

	const handleImageClick = (fileUrl) => {
		if (fileUrl) {
			window.open(fileUrl, "_blank");
		}
	};

	return (
		<div className="">
			<div className="ml-4 mr-4 relative">
				<h6 className="mt-5 font-plusJakartaSans flex items-center text-center gap-[5px] font-extrabold text-[#000000] text-[16px]">
					<img
						className="w-7 h-7"
						src={medias.images.Exam.Eligibility}
						alt="Eligibility"
					/>
					Keys
				</h6>
				<MdOutlineKeyboardArrowRight
					className="absolute right-[-5%] top-36 transform -translate-x-1/2 -translate-y-1/2 bg-[#ffffff] w-8 h-8 rounded-full p-1 border border-[#C2C2C2] cursor-pointer"
					onClick={handleScroll}
				/>
			</div>
			{/* <ResultChips /> */}
			<div
				className="overflow-x-auto scrollable-content"
				ref={scrollContainerRef}
			>
				<div className="flex space-x-4 pb-4">
					{result?.keys?.map((data, index) => (
						<div
							key={index}
							className="flex-shrink-0 pt-2 pb-4 pl-2 pr-2 w-[180px] ml-4 mr-4 mt-4 rounded-xl shadow-md dark:bg-gray-800 dark:border-gray-700"
						>
							<div>
								<img
									className="w-28 h-28 mt-2 ml-auto mr-auto p-5 bg-[#FBC343] bg-opacity-20 rounded-full cursor-pointer"
									src={medias.images.Exam.Papers}
									alt="Download file"
									onClick={() => handleImageClick(data?.file)}
								/>
							</div>
							<div className="pb-4 pl-2 pr-1 pt-4">
								<p className="font-plusJakartaSans flex items-center justify-center text-center font-bold text-[13px] text-[#000000]">
									{data?.subject_name}
								</p>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
}

export default ResultKeys;
