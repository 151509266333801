function Application({ details }) {
	console.log(details, "detailsndfg,gn,df");
	return (
		<div className="ml-4 mr-4 mt-5">
			<h1 className="font-plusJakartaSans font-bold text-[12px]">
				Registration
			</h1>
			<p className="font-plusJakartaSans mt-[2px] font-medium text-[11px] text-white pt-1 pb-1 pl-2 pr-2 bg-[#db4a4a] rounded-full bg-opacity-80 inline-block">
				{details?.application?.registration}
			</p>
			<h1 className="font-plusJakartaSans font-bold mt-5 text-[12px]">
				Application Link
			</h1>
			<p className="font-plusJakartaSans font-medium text-[11px] rounded-full bg-opacity-80 inline-block">
				{details?.application?.application_link}
			</p>
			<h1 className="font-plusJakartaSans font-bold mt-5 text-[12px]">
				Website
			</h1>
			<p className="font-plusJakartaSans font-medium text-[11px] rounded-full bg-opacity-80 inline-block">
				{details?.application?.website}
			</p>
			<h1 className="font-plusJakartaSans font-bold mt-5 text-[12px]">
				Form Corrections and Modifications
			</h1>
			<div
				dangerouslySetInnerHTML={{
					__html: details?.application?.form_correction_dates,
				}}
				className="font-plusJakartaSans font-medium text-[11px] rounded-full bg-opacity-80 inline-block"
			></div>
			<h1 className="font-plusJakartaSans font-bold mt-5 text-[12px]">
				Admit Card
			</h1>
			<div
				dangerouslySetInnerHTML={{
					__html: details?.application?.admit_card,
				}}
				className="font-plusJakartaSans font-medium text-[11px] rounded-full bg-opacity-80 inline-block"
			></div>
		</div>
	);
}

export default Application;
