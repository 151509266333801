import { MdKeyboardArrowRight } from "react-icons/md";
import { medias } from "../../../config/Resources";
import BlogsCardsCarousels from "./BlogsCardsCarousels";
function FeaturedBlogs({ blogsData }) {
	return (
		<>
			<div className="mr-7 ml-9">
				<div className="flex items-center text-center justify-between">
					<h1 className="font-plusJakartaSans font-bold text-[23px] flex items-center text-center gap-[5px]">
						<img
							className="w-11 h-11"
							src={medias.images.Blogs.Fire}
							alt="Fire"
						/>
						Featured Blogs
					</h1>
					<p className="font-plusJakartaSans pt-[11px] pb-[11px] pl-[21px] rounded-full pr-[21px] bg-[#239403] bg-opacity-[10%] font-semibold text-[13.5px] text-[#239403] flex items-center text-center gap-2">
						<span className="font-plusJakartaSans font-semibold">View all</span>
						<MdKeyboardArrowRight className="w-5 h-5" />
					</p>
				</div>
			</div>
			<div className="mr-1 ml-9">
				<BlogsCardsCarousels blogsData={blogsData} />
			</div>
		</>
	);
}

export default FeaturedBlogs;
