import { useState } from "react";

function EntranceCourseChip({ examPattern }) {
	const [selectedId, setSelectedId] = useState();

	const handleChipClick = (id) => {
		const newSelectedId = selectedId === id ? null : id;
		setSelectedId(newSelectedId);
	};

	return (
		<>
			<h6 className="font-plusJakartaSans mt-3 ml-4 text-[13px] font-bold text-[#000000] text-opacity-50">
				Courses
			</h6>
			<div className="flex flex-wrap gap-[10px] mt-3 mb-3 ml-4 mr-4">
				{examPattern?.syllabus?.map((chip) => (
					<p
						key={chip.id}
						onClick={() => handleChipClick(chip?.course_id)}
						className={`font-plusJakartaSans rounded-full border pt-1 pb-1 pl-4 pr-4 inline-flex text-[12px] text-center items-center gap-1 font-medium cursor-pointer ${
							selectedId === chip.id
								? "bg-[#2771E7] border-[#2771E7] text-white"
								: "border-[#D1D1D1] text-[#000000]"
						}`}
					>
						{chip?.course_name}
					</p>
				))}
			</div>
		</>
	);
}

export default EntranceCourseChip;
