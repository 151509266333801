/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { medias } from "../../../config/Resources";

function FeeAndEligibilityTable() {
	const TableDetails = [
		{
			Courses: "MSc in Economics",
			Fees: "Rs 2.63 Lakhs (1st year Fees)",
			Eligibility: "10+2 with 75% + JEE Advanced",
		},
		{
			Courses: "MSc in Chemistry",
			Fees: "Rs 2.63 Lakhs (1st year Fees)",
			Eligibility: "Graduation with 60% + GATE",
		},
		{
			Courses: "MSc in Mathematics",
			Fees: "Rs 2.63 Lakhs (1st year Fees)",
			Eligibility: "Post Graduation with 60%",
		},
		{
			Courses: "MSc in Physics",
			Fees: "Rs 2.63 Lakhs (1st year Fees)",
			Eligibility: "Graduation with 60% + CAT",
		},
	];

	return (
		<div>
			<p className="flex items-center mt-7 ml-4 font-plusJakartaSans font-bold text-[18px] text-center gap-2">
				<span className="bg-[#00ACEA] rounded-lg bg-opacity-10 p-2">
					<img
						className="h-8 w-8"
						src={medias.images.College.getMoney}
						alt="image"
					/>
				</span>
				IIT Delhi Fees & Eligibility
			</p>
			<div className="ml-4 mr-4 mt-3">
				<div className="overflow-x-auto">
					<table className="min-w-full border-collapse">
						<thead>
							<tr>
								<th className="px-4 py-2 bg-[#FFEDC0] text-center font-plusJakartaSans font-bold text-[13.3px] rounded-l-sm border-r-2 border-white">
									Courses
								</th>
								<th className="px-4 py-2 bg-[#FFEDC0] text-center font-plusJakartaSans font-bold text-[13.3px] border-r-2 border-white">
									Fee
								</th>
								<th className="px-4 py-2 bg-[#FFEDC0] text-center font-plusJakartaSans font-bold text-[13.3px] rounded-r-sm">
									Eligibility
								</th>
							</tr>
						</thead>
						<tbody className="bg-[#ffffff]">
							{TableDetails.map((detail, index) => (
								<tr
									key={index}
									className="border-b border-dotted last:border-0"
								>
									<td className="px-4 py-2 text-center text-[#000000] text-opacity-[100%] font-plusJakartaSans font-medium text-[11.5px]">
										{detail.Courses}
									</td>
									<td className="px-4 py-2 text-center text-[#000000] text-opacity-[100%] font-plusJakartaSans font-medium text-[11.5px]">
										{detail.Fees}
									</td>
									<td className="px-4 py-2 text-center text-[#000000] text-opacity-[100%] font-plusJakartaSans font-medium text-[11.5px]">
										{detail.Eligibility}
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
}

export default FeeAndEligibilityTable;
