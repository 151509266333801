function BlogListingTitle() {
	return (
		<div>
			<h1 className="font-plusJakartaSans font-bold text-[23px]">
				Browse by Category
			</h1>
		</div>
	);
}

export default BlogListingTitle;
