import React from "react";
import { FaArrowRightLong } from "react-icons/fa6";
import { medias } from "../../../config/Resources";
// medias.images.Profile.Colleges

const CollegeExam = () => {
	return (
		<div className="w-[100%] rounded-xl flex overflow-hidden shadow-lg mt-5 bg-[#FFF2E4]">
			<div className="pl-5 pt-5 pb-5 w-[75%]">
				<p className="font-plusJakartaSans font-extrabold mb-2 text-[18px]">
					Confused about which college or exam to opt for?
				</p>
				<p className="text-[#000000] opacity-80 font-plusJakartaSans text-[12px] font-semibold mb-2">
					• Chat with our counselor
				</p>
				<p className="text-[#000000] opacity-80 font-plusJakartaSans text-[12px] font-semibold mb-4">
					• Get your personalized list of colleges & exams matching your
					preferences
				</p>
				<button className="bg-[#FA9C23] flex items-center text-center gap-1 text-black font-plusJakartaSans font-semibold text-opacity-[100%] text-[12px] py-2 px-6 rounded-full">
					Get Free Counseling <FaArrowRightLong />
				</button>
			</div>
			<div className="w-[26%] mr-3 mt-5">
				<img
					className="w-[100%] h-[100%]"
					src={medias.images.College.Find}
					alt="find"
				/>
			</div>
		</div>
	);
};

export default CollegeExam;
