import * as React from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import TabsDropdown from "./ProgressTrackerDropdown";

export default function SliderPercent() {
	return (
		<div className="flex mt-5 ml-5">
			<Box className="mt-5" sx={{ width: 400 }}>
				<div className="flex items-center justify-between">
					<p className="font-extrabold font-plusJakartaSans">start</p>
					<p className="font-extrabold font-plusJakartaSans">End Goal</p>
				</div>
				<Slider
					sx={{
						height: 10,
						color: "#42C31D", // Set the color here
						"& .MuiSlider-thumb": {
							"&:hover, &.Mui-focusVisible": {
								boxShadow: "0px 0px 0px 8px rgba(66, 195, 29, 0.16)",
							},
						},
					}}
					defaultValue={50}
					aria-label="Default"
					valueLabelDisplay=""
				/>
			</Box>
           <div className="mt-10">
			<TabsDropdown />
            </div>
		</div>
	);
}
