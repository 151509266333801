import React, { useEffect, useRef, useState } from "react";
import { API_URLS } from "../../../../config/API_URLS";
import axiosAdminInstance from "../../../../config/Axios/axiosAdminInstance";
import { medias } from "../../../../config/Resources";
import {
	MdOutlineKeyboardArrowLeft,
	MdOutlineKeyboardArrowRight,
} from "react-icons/md";

const CareerClusterRanking = ({ setClickedClusterId, clickedClusterId }) => {
	const [cluster, setCluster] = useState([]);

	useEffect(() => {
		(async () => {
			const res = await axiosAdminInstance.get(
				`${API_URLS.getAllDropdowns}?type=cluster`
			);
			if (res?.statusCode === 200) {
				setCluster(res?.result.cluster);
			}
		})();
	}, []);

	const scrollContainerRef = useRef(null);

	const scrollRight = () => {
		if (scrollContainerRef.current) {
			scrollContainerRef.current.scrollBy({
				left: 200,
				behavior: "smooth",
			});
		}
	};

	const handleClusterClick = (id) => {
		if (clickedClusterId === id) {
			setClickedClusterId(null); // Deselect if already selected
		} else {
			setClickedClusterId(id); // Select if not already selected
		}
	};

	return (
		<div>
			<div className="tracking-wide text-[12px] font-bold pt-4 pl-4 pr-4 flex justify-between">
				<h6 className="font-plusJakartaSans flex text-center items-center gap-2">
					<img
						className="w-10 h-10"
						src={medias.images.College.Eligibility}
						alt="courses"
					/>
					Career Cluster
				</h6>
			</div>
			<div className="relative">
				<div
					className="max-w-md md:max-w-[96.5%] rounded-xl overflow-x-auto scrollable-content"
					ref={scrollContainerRef}
				>
					<div className="w-full">
						<div className="flex text-center gap-8 pt-5 pb-1 pl-3 pr-[20px]">
							{cluster?.map((detail) => (
								<div
									key={detail.id}
									className="flex item-center justify-center text-center gap-24"
								>
									<div className="">
										<div
											className={`bg-[#FFFFFF] rounded-lg ml-[5px] flex item-center w-16 h-16 text-center pt-[15px] pb-[15px] pl-[10px] pr-[10px] justify-center aspect-square shadow-custom1 ${
												clickedClusterId === detail.id
													? "bg-gradient-to-br from-[#FFB83F] to-[#FFEC8D]"
													: ""
											}`}
											onClick={() => handleClusterClick(detail.id)}
										>
											<img
												src={detail.icon}
												alt={detail.name}
												className="h-7 w-7 scale-105"
											/>
										</div>
										<div className="mt-3">
											<p className="text-[#000000] text-center font-medium w-full font-plusJakartaSans text-[10px]">
												{detail.name}
											</p>
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
				<div className="absolute top-[-40px] right-1 h-full flex items-center">
					<MdOutlineKeyboardArrowRight
						onClick={scrollRight}
						className="bg-[#ffffff] w-6 h-6 rounded-full p-1 border border-[#C2C2C2] cursor-pointer"
					/>
				</div>
			</div>
		</div>
	);
};

export default CareerClusterRanking;
