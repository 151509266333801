/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/img-redundant-alt */
import { useEffect, useState } from "react";
import { medias } from "../../config/Resources";
import AccordionFaq from "./AccordionFaq";
import axiosInstance from "../../config/Axios/axiosInstance";
import { useParams } from "react-router-dom";
import { API_URLS } from "../../config/API_URLS";

function SingleIntershipDetail() {
	const [datas, setData] = useState({});
	const { id } = useParams();
	console.log(id, "ids");

	const getAcademicRoadMapData = async () => {
		try {
			console.log("value");
			const res = await axiosInstance.get(
				`${API_URLS.baseURL}/internship/getInternshipById?internshipId=${id}`
			);
			setData(res.result);
		} catch (error) {
			console.log(error, "erroro");
		}
	};

	useEffect(() => {
		getAcademicRoadMapData();
	}, []);
	console.log(datas, "allDatas");
	const getFormattedDate = (dateString) => {
		const date = new Date(dateString);
		const options = {
			day: "numeric",
			month: "short",
			year: "numeric",
		};
		return date.toLocaleDateString("en-US", options);
	};

	const getDuration = (startDateString, endDateString) => {
		const startDate = new Date(startDateString);
		const endDate = new Date(endDateString);
		const differenceMs = endDate - startDate;
		const days = Math.floor(differenceMs / (1000 * 60 * 60 * 24));

		let duration;
		if (days >= 365) {
			duration = `${Math.floor(days / 365)} years`;
		} else if (days >= 30) {
			duration = `${Math.floor(days / 30)} months`;
		} else {
			duration = `${days} days`;
		}

		return duration;
	};
	const details = [
		{
			text: "Experience Level",
			label: datas?.experienceRequired,
			skill: "Skill1",
		},
		{
			text: "Application Deadline",
			label: getFormattedDate(datas?.applicationDeadline),
			skill: "Skill2",
		},
		{
			text: "Duration",
			label: getDuration(datas?.startDate, datas?.endDate),
			skill: "Skill3",
		},
		{
			text: "Working hours",
			label: datas?.workingHours,
			skill: "Skill4",
		},
		{
			text: "Type of interview",
			label: datas?.typeOfInterview,
			skill: "Skill5",
		},
	];

	const Perks = [
		{
			text: "Flexible Working fours",
		},
		{
			text: "Travel Allowance",
		},
	];

	const days = [
		{
			day: "Mon",
		},
		{
			day: "Tue",
		},
		{
			day: "Wed",
		},
		{
			day: "Thu",
		},
		{
			day: "Fri",
		},
	];

	useEffect(() => {
		if (datas?.skills && typeof datas?.skills === "string") {
			const skillsArray = datas?.skills
				.replace(/\["/g, "")
				.replace(/"\]/g, "")
				.split('", "');
			setData((prevData) => ({
				...prevData,
				skills: skillsArray,
			}));
		}

		if (datas?.additionalPerks && typeof datas?.additionalPerks === "string") {
			const perksArray = datas?.additionalPerks
				.replace(/\["/g, "")
				.replace(/"\]/g, "")
				.split('", "');
			setData((prevData) => ({
				...prevData,
				additionalPerks: perksArray,
			}));
		}
		if (datas?.workingDays && typeof datas?.workingDays === "string") {
			const workingDaysArray = datas?.workingDays
				.replace(/\["/g, "")
				.replace(/"\]/g, "")
				.split('", "');
			setData((prevData) => ({
				...prevData,
				workingDays: workingDaysArray,
			}));
		}
	}, [datas?.skills, datas?.additionalPerks, datas?.workingDays]);

	return (
		<div className="border border-custom-color rounded-lg p-8 mt-8">
			<div className="flex items-center align-center justify-between">
				<div>
					<div className="flex align align-center items-center mt-5">
						<img
							className="w-[50px]"
							src={medias.images.Profile.NoteBook1}
							alt="notebook1"
						/>
						<h1 className="font-bold font-plusJakartaSans text-lg ml-2 mt-[5px]">
							UI/UX Designer
						</h1>
						<p className="font-normal font-plusJakartaSans text-xs ml-2 mt-[5px]">
							Designation
						</p>
						<p className="font-bold font-plusJakartaSans text-sm ml-2 mt-[5px] ">
							{datas?.designation}
						</p>
					</div>
					<div className="flex item-center mt-2">
						<div className="w-6 h-6 rounded-full border-2 border-gray-700"></div>
						<p className="font-normal font-plusJakartaSans pt-1 text-xs ml-1">
							Techugo Private Limited
						</p>
					</div>
					<div className="flex items-center gap-12 mt-3">
						{details?.map((item) => {
							return (
								<div className="flex flex-col border-r border-gray-400 pr-4">
									<p className="font-normal font-plusJakartaSans text-xs">
										{item.text}
									</p>
									<p className="font-bold font-plusJakartaSans text-sm">
										{item.label}
									</p>
								</div>
							);
						})}
					</div>
					<div className="flex mt-4 items-center">
						<img
							className="w-[15px]"
							src={medias.images.Profile.Location2}
							alt="location2"
						/>
						<p className="font-bold font-plusJakartaSans text-sm ml-2">
							{datas?.internshipLocation}
						</p>
					</div>
				</div>
				<div className="mt-10">
					<p className="font-bold font-plusJakartaSans text-[13px] text-[#959595]">
						YOUR MENTOR
					</p>
					<span className="flex align-center items-center">
						<img
							className="rounded-[100%] w-8 h-8"
							src="https://picsum.photos/200/300"
							alt="profile image"
						/>
						<p className="font-bold font-plusJakartaSans text-xs ml-1">
							Lizza Thomas
						</p>
					</span>
					<button className="mt-3 bg-[#F0BC5E] text-black font-plusJakartaSans font-semibold py-2 px-5 rounded">
						Apply Now
					</button>
				</div>
			</div>
			<div className="border-b border-gray-400 border-dotted mt-5"></div>
			<div className="mt-5">
				<p className="font-semibold font-plusJakartaSans text-lg">
					Qualification Requirement
				</p>
				<p className="font-normal font-plusJakartaSans text-sm mt-2">
					{datas?.qualificationsRequired}
				</p>
			</div>
			<p className="font-semibold font-plusJakartaSans text-lg mt-5">
				Skill Required
			</p>
			<div className="flex items-center gap-4">
				{Array?.isArray(datas?.skills) &&
					datas.skills?.map((item, index) => {
						return (
							<div
								key={index}
								className=" border border-custom-gray py-2 px-4 mt-2 rounded-full "
							>
								<p className="font-semibold font-plusJakartaSans text-sm">
									{item}
								</p>
							</div>
						);
					})}
			</div>
			<div className="mt-4">
				<h1 className="font-semibold font-plusJakartaSans text-lg">
					Internship Description
				</h1>
				<ul className="list-disc ml-5 mt-3">
					<li className="font-normal font-plusJakartaSans text-sm">
						{datas?.internshipDescription}
					</li>
				</ul>
				{/* <p className="font-normal font-plusJakartaSans text-sm mt-4">
					when an unknown printer took a galley of type and scrambled it to make
					a type specimen book. It has survived not only five centuries, but
					also the leap into electronic typesetting, remaining essentially
					unchanged. It was popularised in the 1960s with the release of
					Letraset sheets containing Lorem Ipsum passages, and more recently
					with desktop publishing software like Aldus PageMaker including
					versions of Lorem Ipsum.
				</p> */}
			</div>
			<div className="mt-5">
				<h1 className="font-semibold font-plusJakartaSans text-lg">
					Responsibilities
				</h1>
				<p className="font-normal font-plusJakartaSans text-sm mt-1">
					{datas?.responsibilities}
				</p>
			</div>
			<div className="mt-4">
				<h1 className="font-semibold font-plusJakartaSans text-lg">
					Payment Type
				</h1>
				<ul className="list-disc ml-5 mt-2">
					<li className="font-normal font-plusJakartaSans text-sm">
						{datas?.payType}
					</li>
				</ul>
			</div>
			<h1 className="font-semibold font-plusJakartaSans text-lg mt-4">
				Additional Perks
			</h1>
			<div className="flex items-center gap-4">
				{Array?.isArray(datas?.additionalPerks) &&
					datas.additionalPerks?.map((item, index) => {
						return (
							<div
								key={index}
								className=" border border-custom-gray py-2 px-4 mt-2 rounded-full "
							>
								<p className="font-semibold font-plusJakartaSans text-sm">
									{item}
								</p>
							</div>
						);
					})}
			</div>
			<h1 className="font-semibold font-plusJakartaSans text-lg mt-5">
				Working days
			</h1>
			<div className="flex items-center gap-4">
				{Array?.isArray(datas?.workingDays) &&
					datas.workingDays?.map((item, index) => {
						return (
							<div
								key={index}
								className=" border border-custom-gray py-2 px-4 mt-2 rounded-full "
							>
								<p className="font-semibold font-plusJakartaSans text-sm">
									{item}
								</p>
							</div>
						);
					})}
				<p className="font-semibold font-plusJakartaSans text-lg mt-2">
					5 days works
				</p>
			</div>

			<p className="font-bold font-plusJakartaSans text-lg mt-8">FAQS</p>
			<div className="mt-5">
				<AccordionFaq datas={datas} setData={setData} />
			</div>
		</div>
	);
}

export default SingleIntershipDetail;
