/* eslint-disable no-unused-vars */
import React, { useContext, useEffect } from "react";
import { Grid, Typography, Paper, makeStyles } from "@material-ui/core";
import MicOffIcon from "@mui/icons-material/MicOff";
import MicIcon from "@mui/icons-material/Mic";

import { SocketContext } from "../Context";

const useStyles = makeStyles((theme) => ({
  video: {
    width: "550px",
    [theme.breakpoints.down("xs")]: {
      width: "300px",
    },
  },
  gridContainer: {
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  paper: {
    padding: "10px",
    border: "2px solid black",
    margin: "10px",
  },
}));

const VideoPlayer = ({ userInfo }) => {
  const {
    name,
    callAccepted,
    myVideo,
    userVideo,
    callEnded,
    stream,
    call,
    screenRef,
    sharingStatus,
    pausePlayVideo,
    remoteScreenRef,
  } = useContext(SocketContext);
  const classes = useStyles();

  console.log("sharingStatus", sharingStatus);
  return (
    <>
      <div className="flex  w-screen ">
        <div className="flex w-full flex-col items-center ">
          {stream && (
            <div className=" rounded-3xl mt-1 relative">
              {/* <h6>{name || "Name"}</h6>
              <div onClick={() => pausePlayVideo()}>Pause Video</div> */}

              {sharingStatus ? (
                <video
                  playsInline
                  muted
                  ref={myVideo}
                  autoPlay
                  className=" rounded-3xl h-[150px] w-[290px] custom_css_video2 bg-white"
                />
              ) : (
                <video
                  playsInline
                  muted
                  ref={myVideo}
                  autoPlay
                  className=" rounded-3xl h-[150px] w-[290px] custom_css_video2 bg-white"
                />
              )}
              {!!name && (
                <div className="absolute flex text-center bottom-[6px] left-[6px] text-white p-1 bg-black rounded-3xl">
                  <MicOffIcon className="text-red-500 text-[8px] w-[8px]" />
                  <h1 className="text-[11px] font-light p-1 capitalize ">
                    {name}
                  </h1>
                </div>
              )}
            </div>
          )}

          <div className="  rounded-3xl ">
            <h6>{call.name || "Name"}</h6>
            <video
              playsInline
              muted
              ref={userVideo}
              // ref={remoteScreenRef}
              // src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4"
              autoPlay
              className=" rounded-3xl h-[450px] w-[1200px] custom_css_video2 bg-white border-solid border-[4.3px] border-[#0E72ED]"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default VideoPlayer;
