import { IconButton, Input } from "@mui/material";

const MainSearch = () => {
  return (
    <div className="w-full rounded-[15px] flex 3xl:rounded-[15px] overflow-hidden  bg-goldenYellow">
      <div className="h-[50px] desktop:!h-[43px] desktop:px-[10px] 3xl:h-[49px]  px-6 w-[90%] miniDesk:px-[10px] flex items-center gap-x-4 bg-white">
        {/* <Search className="text-Charcoal-1 desktop:text-[20px]" /> */}
        <svg
          className="text-Charcoal-1 opacity-25"
          width="22.311"
          height="22.311"
          viewBox="0 0 25.311 25.311"
        >
          <g transform="translate(1.5 1.5)">
            <path
              d="M14.416,3a11.416,11.416,0,0,1,8.072,19.488A11.416,11.416,0,0,1,6.344,6.344,11.341,11.341,0,0,1,14.416,3Zm0,19.832A8.416,8.416,0,1,0,6,14.416,8.425,8.425,0,0,0,14.416,22.832Z"
              transform="translate(-4.5 -4.5)"
            />
            <path
              d="M30.367,31.867a1.5,1.5,0,0,1-1.061-.439l-5.392-5.392a1.5,1.5,0,0,1,2.121-2.121l5.392,5.392a1.5,1.5,0,0,1-1.061,2.561Z"
              transform="translate(-8.056 -8.056)"
            />
          </g>
        </svg>
        <Input
          classes={{
            input: "w-full  !border-none !outline-none !placeholder:text-black",
          }}
          className="h-[62px] desktop:text-[12px] before:!border-none after:!border-none w-full !border-none    bg-white"
          placeholder="Search Colleges, Courses, Exams"
        />
      </div>
      <IconButton className="w-[10%] min-w-[70px]">
        <svg width="22.311" height="22.311" viewBox="0 0 25.311 25.311">
          <g transform="translate(1.5 1.5)">
            <path
              d="M14.416,3a11.416,11.416,0,0,1,8.072,19.488A11.416,11.416,0,0,1,6.344,6.344,11.341,11.341,0,0,1,14.416,3Zm0,19.832A8.416,8.416,0,1,0,6,14.416,8.425,8.425,0,0,0,14.416,22.832Z"
              transform="translate(-4.5 -4.5)"
            />
            <path
              d="M30.367,31.867a1.5,1.5,0,0,1-1.061-.439l-5.392-5.392a1.5,1.5,0,0,1,2.121-2.121l5.392,5.392a1.5,1.5,0,0,1-1.061,2.561Z"
              transform="translate(-8.056 -8.056)"
            />
          </g>
        </svg>
        {/* <Search className="text-black desktop:text-[20px] text" /> */}
      </IconButton>
    </div>
  );
};

export default MainSearch;
