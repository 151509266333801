import { Controls, Player } from "@lottiefiles/react-lottie-player";
import NotificationAnimation from "../../../animations/19114-notification-bell.json";
import { MdArrowForward } from "react-icons/md";

function Notifications() {
	const Alltexts = [
		{
			id: 1,
			text: "IIT JAM 2023: Second Admission List (Released), Admission List, ScoreCard, Merit",
		},
		{
			id: 2,
			text: "IIT JAM 2023 answer key out at jam.iitg.ac.in",
		},
		{
			id: 3,
			text: "IIT JAM 2023 Application Data Correction Window Open Till April 3",
		},
		{
			id: 4,
			text: "UCEED Answer key 2023 (OUT) - Download Draft Key Here",
		},
	];

	return (
		<div className="max-w-sm p-4 w-full bg-white rounded-sm shadow dark:bg-gray-800 dark:border-gray-700">
			<div className="flex items-center text-center gap-2">
				<div className="bg-[#FFECCA] rounded-lg p-1">
					<Player
						autoplay
						speed={1.5}
						loop
						src={NotificationAnimation}
						className="h-6 w-6"
					>
						<Controls
							visible={false}
							buttons={[
								"play",
								"repeat",
								"frame",
								"debug",
								"snapshot",
								"background",
							]}
						/>
					</Player>
				</div>
				<h6 className="font-plusJakartaSans font-semibold mt-[4px] text-[14px]">
					IIT Delhi Notifications
				</h6>
			</div>
			<div className="mt-5 ml-1">
				{Alltexts.map((data, index) => {
					return (
						<p
							key={data.id}
							className="font-plusJakartaSans font-semibold text-[10.5px] text-[#5E95ED] mb-2 pb-3 border-b border-gray-300"
						>
							{data.text}
						</p>
					);
				})}
			</div>
			<div className="flex items-center mt-2 mb-3 justify-center">
				<button className="border border-[#035A94] text-[#035A94] gap-1 text-[10.5px] font-semibold rounded-full flex items-center text-center pt-1 pb-1 pl-3 pr-3">
					View All Updates <MdArrowForward className="mt-[3px]" />
				</button>
			</div>
		</div>
	);
}

export default Notifications;
