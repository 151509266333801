/* eslint-disable jsx-a11y/img-redundant-alt */
import { useRef } from "react";
import { medias } from "../../../../config/Resources";
import {
	MdOutlineKeyboardArrowLeft,
	MdOutlineKeyboardArrowRight,
} from "react-icons/md";

function PlacementSummary({ placement }) {

	const scrollContainerRef = useRef(null);

	const scrollLeft = () => {
		if (scrollContainerRef.current) {
			scrollContainerRef.current.scrollBy({
				left: -200, // Adjust the scroll distance as needed
				behavior: "smooth",
			});
		}
	};

	const scrollRight = () => {
		if (scrollContainerRef.current) {
			scrollContainerRef.current.scrollBy({
				left: 200, // Adjust the scroll distance as needed
				behavior: "smooth",
			});
		}
	};

	return (
		<div className="ml-4 mr-4">
			<div className="flex items-center text-center gap-1">
				<img
					className="bg-[#FF7C7A] bg-opacity-[16%] p-[6px] rounded-md w-9 h-9"
					src={medias.images.College.CopyPen}
					alt="Placements"
				/>
				<h6 className="font-plusJakartaSans mt-[5.5px] font-bold text-[15px]">
					Placement Summary
				</h6>
			</div>
			<div>
				<p
					dangerouslySetInnerHTML={{
						__html: placement?.placement_summary,
					}}
					className="font-plusJakartaSans font-medium text-[10.5px] pt-[7px] text-opacity-60 text-[#000000]"
				></p>
			</div>
			<div className="relative">
				<div
					className="flex overflow-x-scroll space-x-4 pt-4 scrollable-content"
					ref={scrollContainerRef}
				>
					{
						placement?.placement_images?.map((imageItem) => (
							<img
								key={imageItem.id}
								className="w-36 h-20 rounded-lg"
								src={imageItem?.placement_image}
								alt={`Image ${imageItem.id}`}
							/>
						))}
				</div>
				<div className="absolute top-0 left-[-10px] h-full flex items-center">
					<MdOutlineKeyboardArrowLeft
						onClick={scrollLeft}
						className="bg-[#ffffff] w-6 h-6 rounded-full p-1 border border-[#C2C2C2] cursor-pointer"
					/>
				</div>
				<div className="absolute top-0 right-0 h-full flex items-center">
					<MdOutlineKeyboardArrowRight
						onClick={scrollRight}
						className="bg-[#ffffff] w-6 h-6 rounded-full p-1 border border-[#C2C2C2] cursor-pointer"
					/>
				</div>
			</div>
		</div>
	);
}

export default PlacementSummary;
