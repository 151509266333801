import { LuCalendarDays } from "react-icons/lu";
import { BsOutlet } from "react-icons/bs";

import { MdAccessTime } from "react-icons/md";

const IconPair = ({ icon = "", title = "" }) => {
  return (
    <div className="flex gap-2 text-center items-center font-medium">
      <span>{icon}</span>
      <p>{title}</p>
    </div>
  );
};

const ReletedProjects = () => {
  return (
    <div className=" mt-6 max-w-[400px] min-w-[310px] rounded-[21px] border shadow-[0px_5px_16px_rgba(0,0,0,0.06)]">
      <div className="p-6 gap-2 flex flex-col">
        <img
          src="https://cdn.discordapp.com/attachments/998764908479004743/1214194867916505098/Unigoal-Student-Dashboard.png?ex=662f9917&is=661d2417&hm=41b35a77e89c8f5eb8df4ffa632acbcb721620b53df29f3e7ff51eff0ed98946&"
          alt="Laptop"
          className=" max-h-[200px] rounded-md object-cover"
        />
        <h6 className="text-[18px] font-bold">Master In Pshychology</h6>
        <div className="flex gap-3 items-center text-center">
          <img
            src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&amp;ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTV8fGh1bWFufGVufDB8fDB8fA%3D%3D&amp;auto=format&amp;fit=crop&amp;w=800&amp;q=60"
            alt="Laptop"
            className=" w-8 h-8  rounded-full object-cover"
          />
          <p className="font-semibold">Dr. Sachin Rashal</p>
        </div>
        <IconPair icon={<LuCalendarDays />} title="Time 6:00AM IST" />
        <IconPair icon={<MdAccessTime />} title="02 Dec 2023" />
        <IconPair icon={<BsOutlet />} title="1.5 Hrs Duration" />
      </div>
    </div>
  );
};

export default ReletedProjects;
