const Home = () => {
  return (
    <div className=" flex w-full h-full py-4">
      {/* mid section */}
      <div className=" w-[72%] space-y-4 mr-[1.5%]">
        <div className=" h-[200px] w-full shadow-[0px_5px_16px_rgba(0,0,0,0.06)] rounded-2xl"></div>
        <div className=" h-[250px] w-full shadow-[0px_5px_16px_rgba(0,0,0,0.06)] rounded-2xl"></div>
      </div>
      {/* right section */}
      <div className="bg-blue-100 w-[28%] h-[600px] shadow-[0px_5px_16px_rgba(0,0,0,0.06)] rounded-2xl"></div>
    </div>
  );
};

export default Home;
