import { Check } from "@mui/icons-material";
import { Button, Tab, Tabs } from "@mui/material";
import React from "react";
import Image from "../../Image";
import { guidancePageContant } from "../../../config/pageContent";
import Text from "../../Text";
import classNames from "classnames";

const Face7 = ({
  isTab = true,
  bodyContainer,
  plans = guidancePageContant.Face5.plans,
}: {
  isTab?: boolean;
  bodyContainer?: string;
  plans?: Array<{
    Title?: string;
    amount?: string;
    color?: string;
    image?: string;
    perks?: Array<string>;
    subTitle?: string;
    border?: string;
    priceColor?: string;
  }>;
}) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };
  return (
    <>
      <div className="pt-[69px] pb-[99px] flex justify-center flex-col items-center">
        <div>
          <Text className="text-[47px] font-bold">
            {guidancePageContant.Face5.designRoadMap}
          </Text>
        </div>
        {isTab && (
          <div className="mt-[71px] mb-[46px]">
            <Tabs
              value={value}
              classes={{
                indicator: "!bg-none !bg-transparent",
              }}
              onChange={handleChange}
              className="border border-[#00000040]  rounded-[13px] px-[16px] py-[10px] "
              aria-label="basic tabs example"
            >
              {guidancePageContant.Face5.tabs.map((items, i) => {
                return (
                  <Tab
                    key={i}
                    className="!h-[55px] !w-[220px] "
                    classes={{
                      selected:
                        "!bg-[#2771E7] !text-white !rounded-[11px] !border-none shadow-lg shadow-[#acc3ec] m-[4px]",
                    }}
                    label={
                      <>
                        <Text className="text-[18.5px] font-bold">
                          {items.Title}
                        </Text>
                        <Text className="text-[13px] font-medium">
                          {items.description}
                        </Text>
                      </>
                    }
                  />
                );
              })}
            </Tabs>
          </div>
        )}
        <div className={classNames(bodyContainer, "flex gap-[36.2px]")}>
          {plans.map(
            (
              {
                Title,
                amount,
                color,
                image,
                perks,
                subTitle,
                border,
                priceColor,
              },
              i
            ) => {
              return (
                <>
                  <div
                    className={` bg-[${color}] p-[30.67px] flex flex-col min-w-[300px] max-w-[300px]   border-t-[12px] border-[${border}] bg-opacity-[10%] rounded-lg `}
                  >
                    <div className="text-[#000000] text-[28px] font-black w-full flex justify-center">
                      {Title}
                    </div>
                    <div className="flex justify-center w-full mt-[15px]">
                      <Image src={image} alt="Plan1" />
                    </div>
                    <div className="mt-[23.66px]">
                      <div className="text-[#1B1D21] text-[16px] font-medium">
                        {subTitle}
                      </div>
                      <div
                        className={`text-[40px] mt-[21px] justify-center flex font-semibold ${priceColor} items-center gap-[4px]`}
                      >
                        ${amount}
                        <span className="text-[16px] text-[#1B1D21] font-normal">
                          /month
                        </span>
                      </div>
                    </div>
                    <div className="mt-[20px] flex flex-col justify-between h-full">
                      <ul className="flex text-[#1B1D21] font-[14px] flex-col gap-[14px]">
                        {perks?.map((data) => {
                          return (
                            <li className="flex gap-[5px]  items-center font-medium ">
                              <Check className="bg-[#EEF9F3] p-1 !stroke-2 rounded-full text-[#5AC587]" />{" "}
                              {data}
                            </li>
                          );
                        })}
                      </ul>
                      <div className="mt-[20px]">
                        <Button
                          variant="outlined"
                          className={`hover:!bg-opacity-100 !bg-[${color}] !bg-opacity-[0.1]  !text-[${color}] !border !border-[${color}] !w-full rounded-[6px] !text-lg gap-4 !mt-4 !capitalize  !px-6 !py-4 !font-bold hover:!text-white hover:!bg-[#637CED]`}
                        >
                          Get Started
                        </Button>
                      </div>
                    </div>
                  </div>
                </>
              );
            }
          )}
        </div>
      </div>
    </>
  );
};

export default Face7;
