import React from "react";
import { medias } from "../../config/Resources";

const QualitiesOfYours = () => {
  const AcdameyDetails = [
    { image: medias.images.Profile.Time1, label: 'Time Management', },
    { image: medias.images.Profile.Team, label: 'Team Work /', label1: 'Collaboration' },
    { image: medias.images.Profile.Problem, label: 'Problem Solving' },
    { image: medias.images.Profile.Flexibility, label: 'Flexibility' },
    { image: medias.images.Profile.Critical, label: 'Critical Thinking' }
  ];

  return (
    <div className="w-[65%] flex bg-white rounded-xl shadow-md overflow-hidden mt-10 ml-5 border border-gray-300">
      <div className="w-full">
        <div className="uppercase tracking-wide text-sm font-plusJakartaSans font-bold pt-4 pl-4 pr-4">
          Top 5 Qualities of Yours
        </div>
        <div className="mt-4">
          <div className="h-px bg-gray-300"></div>
        </div>
        <div className="mt-2 pl-7 pb-12 pt-6 flex">
          {AcdameyDetails?.map((detail, index) => (
            <div key={index} className="flex items-center gap-12">
              <div className="items-center">
                <img src={detail.image} alt={detail.label} className="h-[55%] w-[55%]" />
                <div className="text-sm ml-1 mt-3">
                  <p className="font-bold text-xs font-plusJakartaSans">{detail.label}</p>
                  <p className="font-bold text-xs font-plusJakartaSans">{detail.label1}</p>
                </div>
              </div>
              <div className="text-sm"></div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default QualitiesOfYours;
