import { Controls, Player } from "@lottiefiles/react-lottie-player";
import StartsAnimation from "../../../animations/292-5-stars.json";
import { medias } from "../../../config/Resources";

function ReviewsStars() {
	return (
		<div className="relative max-w-sm p-4 w-full bg-[#FFECCA] rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
			<img
				src={medias.images.Profile.logo}
				alt="College Logo"
				className="absolute top-[-20%] left-1/2 transform -translate-x-1/2"
				style={{ width: "72px", height: "70px" }}
			/>
			<div className="flex item-center justify-center mt-7">
				<div className="bg-[#000000] rounded-full pt-[1.5px] pb-[1.5px] pl-5 pr-5 inline-block">
					<Player
						autoplay
						speed={1.5}
						loop
						src={StartsAnimation}
						className="h-auto w-20"
					>
						<Controls
							visible={false}
							buttons={[
								"play",
								"repeat",
								"frame",
								"debug",
								"snapshot",
								"background",
							]}
						/>
					</Player>
				</div>
			</div>
			<div className="flex justify-center text-center mt-1">
				<p className="font-plusJakartaSans font-bold text-[11px]">
					Write a Review and Get Upto{" "}
					<span className="text-[#5E95ED] text-[11.5px]">Rs 250</span> Approval
					in 15 min
				</p>
			</div>
			<div className="flex items-center mt-[6px] justify-center">
				<button className=" text-[9.5px] bg-[#FA9C23] font-bold rounded-full flex items-center text-center pt-1 pb-1 pl-7 pr-7">
					Write a Review
				</button>
			</div>
			<div className="flex items-center mt-[6px] justify-center">
				<p className="font-plusJakartaSans text-[7px] font-medium">
					*only for the First 20 reviews this month
				</p>
			</div>
		</div>
	);
}

export default ReviewsStars;
