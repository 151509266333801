import React from "react";
import goldflare from "../../../animations/explore.json";
import { Controls, Player } from "@lottiefiles/react-lottie-player";
import { mediaResources } from "../../../config/Constants";
import { Button } from "@mui/material";
import { EastRounded } from "@mui/icons-material";

const ExploreOtherSectionData = [
  { color: "bg-[#E3FFEF]", title: "Find Your Dream Colleges in India" },
  {
    color: "bg-[#FFFBF1]",
    title: "Get Answer on Test Preparation,Admission, & Campus Life",
  },
  {
    color: "bg-[#E4EAFF]",
    title: "Find Courses with Help of AI Based Course Finder",
  },
  {
    color: "bg-[#E4FFFF]",
    title: "Compare your College with there top colleges",
  },
  { color: "bg-[#FFFBF1]", title: "Find Your Dream Colleges in India" },
  { color: "bg-[#E4EAFF]", title: "Find Your Dream Colleges in India" },
  { color: "bg-[#E4FFFF]", title: "Find Your Dream Colleges in India" },
];

const ExploreOtherSection = () => {
  return (
    <>
      <div className="w-[100%] my-8">
        {" "}
        <div className="flex gap-2 items-center text-center my-4">
          <div>
            <Player
              autoplay
              speed={1.5}
              loop
              src={goldflare}
              style={{
                height: "64px",
                padding: 0,
                width: "64px",
              }}
            >
              <Controls
                visible={false}
                buttons={[
                  "play",
                  "repeat",
                  "frame",
                  "debug",
                  "snapshot",
                  "background",
                ]}
              />
            </Player>
          </div>
          <h2 className="text-[#000000] text-[31px] font-bold">
            Explore Others Section
          </h2>
        </div>
        <div className="flex gap-3 overflow-x-scroll no-scrollbar pl-6 ">
          {ExploreOtherSectionData?.map((ele, i) => (
            <div
              key={i}
              className={` ${ele?.color} h-[400px] min-w-[250px] rounded-[18px] flex flex-col justify-between pb-3 pt-2 overflow-hidden break-words`}
            >
              <div>
                <img
                  className="px-6 h-[200px]"
                  src={`${mediaResources}/Explore/${i + 1}.svg`}
                  alt={"img" + (i + 1)}
                />
                <h4 className="px-6 py-2 text-center text-[19px] font-extrabold">
                  {ele?.title}
                </h4>
              </div>
              <Button
                variant="contained"
                className={` hover:!bg-transparent mx-10 py-[14px] flex gap-[6.2px] text-nowrap miniDesk:!px-[2px] text-[15px] font-medium capitalize !text-[#000000]  3xl:py-[10px]  3xl:px-3 3xl:text-[13px] 3xl:h-fit !shadow-none px-[28px]  !border-[#2F2D3A] !border-[1px] !border-solid rounded-[23px]`}
              >
                Explore Now{" "}
                <EastRounded className="3xl:text-[16px] miniDesk:hidden" />
              </Button>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default ExploreOtherSection;
