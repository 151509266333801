import { medias } from "../../../config/Resources";

function Banner() {
	return (
		<div className="flex ml-[14%] mt-8">
			<img
				className="w-[85%]"
				src={medias.images.Profile.Promotion}
				alt="promotion"
			/>
		</div>
	);
}

export default Banner;
