function CourseTable({ placement }) {
	const TableDetails = [
		{
			Branch: "Computer Science",
			StudentsRegistered: "305",
			StudentsPlaced: "304",
			SalaryOffered: "$ 304",
		},
		{
			Branch: "Computer Science",
			StudentsRegistered: "305",
			StudentsPlaced: "304",
			SalaryOffered: "$ 304",
		},
		{
			Branch: "Computer Science",
			StudentsRegistered: "305",
			StudentsPlaced: "304",
			SalaryOffered: "$ 304",
		},
		{
			Branch: "Computer Science",
			StudentsRegistered: "305",
			StudentsPlaced: "304",
			SalaryOffered: "$ 304",
		},
		{
			Branch: "Computer Science",
			StudentsRegistered: "305",
			StudentsPlaced: "304",
			SalaryOffered: "$ 304",
		},
		{
			Branch: "Computer Science",
			StudentsRegistered: "305",
			StudentsPlaced: "304",
			SalaryOffered: "$ 304",
		},
		{
			Branch: "Computer Science",
			StudentsRegistered: "305",
			StudentsPlaced: "304",
			SalaryOffered: "$ 304",
		},
		{
			Branch: "Computer Science",
			StudentsRegistered: "305",
			StudentsPlaced: "304",
			SalaryOffered: "$ 304",
		},
		{
			Branch: "Computer Science",
			StudentsRegistered: "305",
			StudentsPlaced: "304",
			SalaryOffered: "$ 304",
		},
		{
			Branch: "Computer Science",
			StudentsRegistered: "305",
			StudentsPlaced: "304",
			SalaryOffered: "$ 304",
		},
	];
	return (
		<div className="mt-5">
			<div className="overflow-x-auto">
				<table className="min-w-full border-collapse">
					<thead>
						<tr>
							<th className="px-4 py-2 bg-[#FFEDC0] text-center font-plusJakartaSans font-bold text-[11px] rounded-l-sm border-r-2 border-white">
								Courses
							</th>
							<th className="px-4 py-2 bg-[#FFEDC0] text-center font-plusJakartaSans font-bold text-[11px] border-r-2 border-white">
								Students Registered
							</th>
							<th className="px-4 py-2 bg-[#FFEDC0] text-center font-plusJakartaSans font-bold text-[11px] border-r-2 border-white">
								No. of students placed
							</th>
							<th className="px-4 py-2 bg-[#FFEDC0] text-center font-plusJakartaSans font-bold text-[11px] rounded-r-sm">
								Salary offered
								<p className="text-center font-plusJakartaSans font-bold text-[11px]">
									(in LPA)
								</p>
							</th>
						</tr>
					</thead>
					<tbody className="bg-[#ffffff]">
						{
							placement?.course_wise_data?.map((detail, index) => (
								<tr
									key={index}
									className="border-b border-dotted last:border-0"
								>
									<td className="px-4 py-2 text-center font-plusJakartaSans font-medium text-[11px]">
										{detail?.course_name}
									</td>
									<td className="px-4 py-2 text-center font-plusJakartaSans font-medium text-[11px]">
										{detail?.students_registered}
									</td>
									<td className="px-4 py-2 text-center font-plusJakartaSans font-medium text-[11px]">
										{detail?.students_placed}
									</td>
									<td className="px-4 py-2 text-center font-plusJakartaSans font-medium text-[11px]">
										{detail?.salary_offered}
									</td>
								</tr>
							))}
					</tbody>
				</table>
			</div>
		</div>
	);
}

export default CourseTable;
