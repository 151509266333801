import { medias } from "../../../../config/Resources";

const PostCard = () => {
	const dataCards = [{}, {}, {}, {}, {}];
	return (
		<div>
			<p className="font-plusJakartaSans ml-4 mr-4 mt-4 text-[12px] bg-[#000000] text-white pt-1 pb-1 pl-3 pr-3 rounded-sm inline-block">
				Featured News
			</p>

			{dataCards.map((_item, index) => {
				const isLast = index === dataCards.length - 1;
				return (
					<div
						key={index}
						className={`max-w-2xl mx-auto bg-white pb-4 pt-6 ${
							!isLast && "border-b border-dashed mr-4 ml-4"
						}`}
					>
						<div className="flex items-center mb-[14px]">
							<img
								src={medias.images.Exam.CollegeDetails}
								alt="College"
								className="w-24 h-24 rounded-lg mr-4"
							/>
							<div className="ml-[-7px]">
								<div className="flex space-x-2 mb-[6px]">
									<span className="bg-[#F37400] bg-opacity-10 text-[#000000] text-[11px] font-medium font-plusJakartaSans pt-1 pb-1 pl-5 pr-5 rounded-full">
										Exam
									</span>
									<span className="bg-[#F37400] bg-opacity-10 text-[#000000] text-[11px] font-medium font-plusJakartaSans pt-1 pb-1 pl-5 pr-5 rounded-full">
										Admissions
									</span>
								</div>
								<h2 className="text-[14px] font-plusJakartaSans font-bold mb-[3px]">
									JoSAA 2023 Counselling Round 3 Seat Allotment Result Today
								</h2>
								<p className="text-[#252525] font-medium text-[11px] font-plusJakartaSans line-clamp-2">
									Indian Institute of Technology, Delhi or IIT Delhi is an
									Institute of Eminence offering about 102 undergraduate,
									postgraduate, integrated and doctoral degree courses. Indian
									Institute of Technology, Delhi courses include B.Tech, M.Tech,
									M.Sc and Ph.D in various streams of Engineering, Physical
									Sciences, Management, Humanities, and Social Sciences.
									Students seeking admission for B.Tech in any stream must
									appear for JEE Advanced and JoSaa counselling. Students
									completing their degrees are offered IIT Delhi placements to
									prestigious organisations at a salary package ranging from Rs.
									26 LPA to Rs. 40 LPA (approximately). Established in 1961, IIT
									Delhi is one of the 23 IITs created to be Centres of
									Excellence for training, research and development in science,
									engineering and technology in India. Being an Institution of
									National Importance under the 'Institutes of Technology’, the
									institute has bagged 4th rank in overall category as per NIRF
									2022 data. The Indian Institute of Technology Delhi covers an
									area of about 320 acres equipped with all basic
									infrastructural facilities. The institute also has world class
									and experienced faculties to ensure extraordinary learning.
								</p>
							</div>
						</div>

						<div className="flex items-center text-xs ">
							<span className="flex items-center font-semibold text-[#000000] font-plusJakartaSans text-[12px] mr-5 gap-2">
								<img
									className="w-5 h-4"
									src={medias.images.Exam.HumanEyes}
									alt="Human Eyes"
								/>
								12k
							</span>
							<span className="flex items-center font-semibold text-[#000000] font-plusJakartaSans text-[12px] mr-5 gap-[5px]">
								<img
									className="w-4 h-4"
									src={medias.images.Exam.Chat}
									alt="Chat"
								/>
								333
							</span>
							<span className="flex items-center font-semibold text-[#000000] font-plusJakartaSans text-[12px] gap-2">
								<img
									className="w-4 h-4"
									src={medias.images.Exam.ShareImage}
									alt="ShareImage"
								/>
								1k
							</span>
							<span className="flex items-center font-medium font-plusJakartaSans text-[#000000] text-opacity-60 text-[12px] ml-auto gap-[5px]">
								<img
									className="w-3 h-4"
									src={medias.images.Exam.Clock}
									alt="Clock"
								/>
								July 12, 2023
							</span>
						</div>
					</div>
				);
			})}
		</div>
	);
};

export default PostCard;
