// EntranceExam.jsx
import { useState } from "react";
import FilterSearch from "../../Components/CareersDetail/AllFilterSearch";
import CareerCluster from "../../Components/CareersDetail/CareerCluster";
import FilterCourses from "../../Components/CareersDetail/FilterCourses";
import Filters from "../../Components/CareersDetail/Filters";
import Search from "../../Components/CareersDetail/Search";
import StreamFilter from "../../Components/CareersDetail/StreamFilter";
import DifferentCollege from "../../Components/EntranceExam/EntranceExamCards/DifferentCollege";
import EntranceExamDetailsCards from "../../Components/EntranceExam/EntranceExamCards/EntranceExamDetailsCards";

function EntranceExam() {
	const [filterData, setFilterData] = useState([]);
	return (
		<div className="bg-[#F3F6FF] h-auto">
			<DifferentCollege />
			<CareerCluster />

			<div className="w-[70%] flex ml-auto mr-auto">
				<div className="w-[20%] h-auto bg-white rounded-lg mb-20">
					<Filters setFilterData={setFilterData} filterData={filterData} />
					<FilterSearch filterData={filterData} setFilterData={setFilterData} />
					<div className="border border-dotted border-b mt-10 mb-10"></div>
					<FilterCourses />
					<div className="border border-dotted border-b mt-10"></div>
					<StreamFilter />
				</div>
				<div className="w-[80%] ml-5">
					<Search context="entrance-exam" />
					<EntranceExamDetailsCards />
				</div>
			</div>
		</div>
	);
}

export default EntranceExam;
