import React, { useState } from "react";
import CardsMentorship from "./card";
import Tab from "./tab";
import RightCards from "./rightCards";
import ReletedProjects from "../../../Components/BookedMentor/ReletedProjects";
import UpcomingSession from "./upcomingSession";
import Calendar from "./calender";
import { ChooseCouncellor } from "./chooseCouncellor";
import CompleteSessionRight from "./completeSessionRight";
import CalendarContent from "./calenderContent";
import ReferEarn from "./ReferAndEarn";

const Mentorship = () => {
	const [activeTab, setActiveTab] = useState(1);

	return (
		<div>
			<div style={{ width: "100%", display: "flex" }}>
				<div style={{ width: "70%" }}>
					<Tab setActiveTab={setActiveTab} />
					{activeTab === 1 && <CardsMentorship activeTab={activeTab} />}
				</div>
				<div style={{ width: "30%" }}>{activeTab === 1 && <RightCards />}</div>
			</div>
			{activeTab === 1 && (
				<>
					<div className="flex justify-between pt-12 pb-6">
						<h4 className="font-bold text-[28px] font-quitery">
							Related Projects
						</h4>
					</div>
					<div className="flex gap-3 overflow-x-scroll no-scrollbar pl-6 ">
						{Array.from({ length: 9 }).map((ele, i) => (
							<ReletedProjects key={i} />
						))}
					</div>
				</>
			)}
      {
        activeTab === 1 && (
          <>
          <h1 style={{ fontWeight: "1000", marginTop:'5%' }}>
            Choose The Best Career Councilor Now
          </h1><ChooseCouncellor />
          </>
        )
      }
			<div style={{ width: "100%", display: "flex" }}>
				{activeTab === 2 && (
					<>
						<div style={{ width: "70%" }}>
							<CardsMentorship activeTab={activeTab} />
						</div>
						<div style={{ width: "30%" }}>
							<Calendar />
							<UpcomingSession />
						</div>
					</>
				)}
			</div>
			{activeTab === 2 && (
				<>
					<h1 style={{ fontWeight: "1000" }}>
						Choose The Best Career Councilor Now
					</h1>
					<ChooseCouncellor />
				</>
			)}
			<div style={{ width: "100%", display: "flex" }}>
				{activeTab === 3 && (
					<>
						<div style={{ width: "70%" }}>
							<CardsMentorship activeTab={activeTab} />
							<ReferEarn />
						</div>
						<div style={{ width: "30%" }}>
							<CompleteSessionRight />
							<UpcomingSession />
						</div>
					</>
				)}
			</div>
			<hr style={{ border: "1 px solid black", marginTop: "5%" }} />
      {activeTab === 3 && (
      <div className="flex" style={{justifyContent:'space-between', marginTop:'5%'}}>
					<h1 className="font-black text-lg">Notes</h1>
          <h1 className="font-black text-lg" style={{color:'#239403'}}>Add Notes</h1>
          </div>
        )}
			{activeTab === 3 && (
				<div className="mt-4" style={{ width: "30%" }}>
					<CalendarContent />
				</div>
			)}
		</div>
	);
};

export default Mentorship;
