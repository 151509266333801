import React, { useState } from "react";
import Chip from "../../../Components/ProfileBuilding/chips";
import Tab from "../../../Components/ProfileBuilding/tab";
import AcademyDetailsCard from "../../../Components/ProfileBuilding/AcademicDetailsCard";
import Notes from "../../../Components/ProfileBuilding/Notes";
import FutureGoals from "../../../Components/ProfileBuilding/FitureGoals";
import Accordion from "../../../Components/ProfileBuilding/Accordion";
import FutureGoalCards from "../../../Components/ProfileBuilding/FutureGoalCards";
import ExtraCurricularActivities from "../../../Components/ProfileBuilding/ExtraCurricularActivities";
import TrainingAndInternship from "../../../Components/ProfileBuilding/trainingAndInternship";
import LifeSkills from "../../../Components/ProfileBuilding/LifeSkills";
import PassionProject from "../../../Components/ProfileBuilding/PassionProject";
import Health from "../../../Components/ProfileBuilding/Health";
import DailyActivities from "../../../Components/ProfileBuilding/DailyAvtivities";
import ProgressTracker from "../../../Components/ProfileBuilding/ProgressTracker";

function ProfileBuilding() {
	const [showTab, setShowTab] = useState(1);
	const [activeTab, setActiveTab] = useState(1);
	const [datas, setData] = useState([]);

	return (
		<div className="pt-10 bg-white w-full">
			<Chip setShowTab={setShowTab} />
			{showTab === 1 && <Tab setActiveTab={setActiveTab} />}

			{activeTab === 1 && (
				<>
					{showTab === 1 && (
						<>
							<AcademyDetailsCard />
							<div className="w-full flex mt-5 mb-5">
								<div className="w-3/4">
									<Accordion activeTab={activeTab} setData={setData} datas={datas}/>
								</div>
								<div className="w-1/4">
									<FutureGoalCards />
									<FutureGoals />
								</div>
							</div>
							<div className=" flex justify-between mt-5 mb-1 ml-2 mr-5">
								<h1 className="ml-8 font-quitery font-normal text-2xl">
									Notes
								</h1>
								<h1 className="text-green-500 text-xl font-plusJakartaSans font-semibold">
									Add Notes
								</h1>
							</div>
							<Notes />
						</>
					)}
				</>
			)}
			{showTab === 1 && (
				<>
					{activeTab === 2 && (
						<ExtraCurricularActivities activeTab={activeTab} />
					)}
					{activeTab === 3 && <TrainingAndInternship activeTab={activeTab} />}
					{activeTab === 4 && <LifeSkills />}
					{activeTab === 5 && <PassionProject activeTab={activeTab} />}
					{activeTab === 6 && <Health activeTab={activeTab} />}
				</>
			)}
			{showTab === 2 && <DailyActivities />}
			{showTab === 3 && <ProgressTracker />}
		</div>
	);
}

export default ProfileBuilding;
