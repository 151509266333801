/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from "react";
import { MdChevronLeft } from "react-icons/md";
import { medias } from "../../../config/Resources";
import { IoTimeOutline } from "react-icons/io5";
import { Verified } from "@mui/icons-material";
import { AiOutlineQuestion } from "react-icons/ai";
import { IoIosSearch } from "react-icons/io";
import { RiArrowDropDownLine } from "react-icons/ri";
import { FaAngleUp } from "react-icons/fa";
import { Controls, Player } from "@lottiefiles/react-lottie-player";
import ProfileAnimation from "../../../animations/Profileanimation.json";
import { GoDownload } from "react-icons/go";

function OverView({ CollegeDetails, showTabs, admissions, campusLife }) {
	const [isExpanded, setIsExpanded] = useState(false);

	const toggleReadMore = () => {
		setIsExpanded(!isExpanded);
	};

	const staticText = `Indian Institute of Technology, Delhi or IIT Delhi is an Institute of Eminence offering about 102 undergraduate, postgraduate, integrated and doctoral degree courses. Indian Institute of Technology, Delhi courses include B.Tech, M.Tech, M.Sc and Ph.D in various streams of Engineering, Physical Sciences, Management, Humanities, and Social Sciences. Students seeking admission for B.Tech in any stream must appear for JEE Advanced and JoSaa counselling. Students completing their degrees are offered IIT Delhi placements to prestigious organisations at a salary package ranging from Rs. 26 LPA to Rs. 40 LPA (approximately). Established in 1961, IIT Delhi is one of the 23 IITs created to be Centres of Excellence for training, research and development in science, engineering and technology in India. Being an Institution of National Importance under the 'Institutes of Technology’, the institute has bagged 4th rank in overall category as per NIRF 2022 data. The Indian Institute of Technology Delhi covers an area of about 320 acres equipped with all basic infrastructural facilities. The institute also has world class and experienced faculties to ensure extraordinary learning.`;

	return (
		<div>
			<div className="mt-5">
				<div className="flex items-center text-center gap-1 ml-4 pt-5">
					<p className="font-plusJakartaSans font-medium text-[#909090] text-[13.5px]">
						All Colleges
					</p>
					<p className="font-plusJakartaSans font-medium text-[#909090] text-[13.5px] mt-[3px]">
						<MdChevronLeft />
					</p>
					<p className="font-plusJakartaSans font-medium text-[#909090] text-[13.5px]">
						New Delhi
					</p>
					<p className="font-plusJakartaSans font-medium text-[#909090] text-[13.5px] mt-[3px]">
						<MdChevronLeft />
					</p>
					<p className="font-plusJakartaSans font-medium text-[#000000] text-[13.5px]">
						IIT Delhi
					</p>
				</div>
				<div className="relative mt-2 w-48 -left-2">
					<img
						className="w-full"
						src={medias.images.Profile.Image6}
						alt="image 6"
					/>
					<div className="absolute inset-0 flex items-center justify-center text-[22px] text-black font-plusJakartaSans font-semibold">
						{showTabs === 2
							? "Course"
							: showTabs === 3
							? "Admissions"
							: showTabs === 4
							? "Notice Board"
							: showTabs === 5
							? "Scholarship"
							: showTabs === 6
							? "Placements"
							: showTabs === 7
							? "Cutoff"
							: showTabs === 8
							? "Campus Life"
							: showTabs === 10
							? "Faculty"
							: showTabs === 12
							? "Gallery"
							: showTabs === 13
							? "News & Articles"
							: showTabs === 14
							? "Ranking"
							: "Overview"}
					</div>
				</div>
				<div className="ml-1 mt-2 flex items-center">
					<div className="relative h-20 w-20">
						<Player
							autoplay
							speed={1.5}
							loop
							src={ProfileAnimation}
							className="h-20 w-20 p-0"
						>
							<Controls
								visible={false}
								buttons={[
									"play",
									"repeat",
									"frame",
									"debug",
									"snapshot",
									"background",
								]}
							/>
						</Player>
						<img
							className="absolute inset-0 h-12 w-[60%] rounded-full m-auto top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
							src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=400&fit=max&ixid=eyJhcHBfaWQiOjE0NTg5fQ"
							alt="Woman looking front"
						/>
					</div>
					<div className="mt-1">
						<p className="font-plusJakartaSans text-[14px] miniDesk:text-[12px] font-semibold flex items-center text-center gap-1">
							Jaypal Sharma
							<Verified className="text-[rgba(39,113,231,1)] ml-[1px] 3xl:text-[18px]" />
						</p>
						<p className="font-plusJakartaSans mt-[5px] text-[#707070] text-[12.5px] font-medium flex items-center text-center gap-1">
							<IoTimeOutline className="w-4 h-4" />
							<span className="font-plusJakartaSans miniDesk:text-[7px] text-[#707070] ">
								Last updated on 28 Jun 23
							</span>
						</p>
					</div>
					<div className="ml-4 mt-3">
						<p className="border rounded-full py-2 px-3 flex items-center text-center gap-[5px] font-plusJakartaSans font-medium">
							<AiOutlineQuestion />
							<span className="text-[12px] miniDesk:text-[7px] text-[#000000] mt-[1px] font-plusJakartaSans font-medium">
								246q&A
							</span>
						</p>
					</div>
					<div className="ml-3 mt-3">
						<p className="border rounded-full py-2 px-3 flex items-center text-center font-plusJakartaSans font-medium">
							<IoIosSearch />
							<span className="text-[12px] miniDesk:text-[10px] text-[#000000]">
								Will you get in
							</span>
							<AiOutlineQuestion className="w-[15px] pt-[2px] h-[15px]" />
						</p>
					</div>
					<button
						variant="outlined"
						className="font-medium flex mt-[10px] ml-3 text-center gap-[2px] pt-2 pb-2 pl-3 pr-3 bg-[#239403] text-white rounded-full text-[12px]  miniDesk:text-[10px] font-plusJakartaSans"
					>
						<GoDownload className="text-white h-4 w-4" />
						<span className="mt-[1px] font-plusJakartaSans">
							Download brochure
						</span>
					</button>
				</div>
				<div className="ml-4 mr-4 mt-5">
					<img src={medias.images.Profile.Promotion} alt="promotion" />
				</div>
				{showTabs !== 4 &&
					showTabs !== 6 &&
					showTabs !== 7 &&
					showTabs !== 10 &&
					showTabs !== 12 &&
					showTabs !== 8 &&
					showTabs !== 5 &&
					showTabs !== 13 &&
					showTabs !== 14 && (
						<div className="ml-4 mr-4">
							<h6 className="mt-5 font-plusJakartaSans font-bold text-[#000000] text-[17px]">
								{showTabs === 2
									? "Overview"
									: showTabs === 3
									? "Introduction"
									: "About this college"}
							</h6>
							<div className="relative overflow-hidden">
								{showTabs === 3 ? (
									<div
										dangerouslySetInnerHTML={{
											__html: admissions?.introduction,
										}}
										className="font-plusJakartaSans font-medium  leading-[24px] text-[13px] text-[#000000] text-opacity-70 mt-1"
									></div>
								) : (
									<div>
										<div
											dangerouslySetInnerHTML={{
												__html:
													showTabs === 2
														? staticText
														: CollegeDetails?.about_college,
											}}
											className={`font-plusJakartaSans font-medium text-[13px] leading-[24px] text-[#000000] text-opacity-70 mt-1 ${
												!isExpanded ? "line-clamp-5" : ""
											}`}
										></div>
										{!isExpanded && (
											<div className="absolute bottom-0 left-0 w-full h-8 bg-gradient-to-t from-white to-transparent"></div>
										)}
									</div>
								)}
							</div>

							{showTabs !== 3 && (
								<button
									onClick={toggleReadMore}
									className={`mt-1 text-blue-500 font-plusJakartaSans font-bold ${
										showTabs === 2 ? "ml-0" : "ml-[85%]"
									} text-[13px] flex items-center`}
								>
									{isExpanded ? (
										<>
											Read less
											<FaAngleUp className="w-4 h-4 ml-1" />
										</>
									) : (
										<>
											Read more
											<RiArrowDropDownLine className="w-6 h-6" />
										</>
									)}
								</button>
							)}
						</div>
					)}

				{showTabs === 8 && (
					<div className="ml-4 mr-4">
						<h6 className="mt-5 font-plusJakartaSans font-bold text-[#000000] text-[15px]">
							Campus Overview
						</h6>
						<div className="relative overflow-hidden">
							<div>
								{campusLife?.map((item, index) => (
									<div
										key={index}
										dangerouslySetInnerHTML={{ __html: item?.campus_overview }}
										className={`font-plusJakartaSans font-medium text-[10.5px] text-[#000000] text-opacity-70 mt-1 ${
											!isExpanded ? "line-clamp-5" : ""
										}`}
									></div>
								))}
								{!isExpanded && (
									<div className="absolute bottom-0 left-0 w-full h-8 bg-gradient-to-t from-white to-transparent"></div>
								)}
							</div>
						</div>
						<button
							onClick={toggleReadMore}
							className="mt-1 text-blue-500 font-plusJakartaSans font-bold ml-[80%] text-[12px] flex items-center"
						>
							{isExpanded ? (
								<>
									Read less
									<FaAngleUp className="w-4 h-4 ml-1" />
								</>
							) : (
								<>
									Read more
									<RiArrowDropDownLine className="w-6 h-6" />
								</>
							)}
						</button>
					</div>
				)}
			</div>
		</div>
	);
}

export default OverView;
