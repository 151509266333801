//this file Should Contain the common veriable valuess




const mediaResources = `${process.env.PUBLIC_URL}/Images`

const categories = [
  {sub_category_name:"Perceptual Speed", sub_category_id:41},
  {sub_category_name:"Spatial Reasoning", sub_category_id:34},
    {sub_category_name:"Verbal Reasoning", sub_category_id:35},
    {sub_category_name:"Mechanical Reasoning", sub_category_id:36},
    {sub_category_name:"Critical Thinking", sub_category_id:37},
    {sub_category_name:"Abstract Reasoning", sub_category_id:38},
    {sub_category_name:"Quantitative Reasoning", sub_category_id:39},
    {sub_category_name:"Attention to Detail", sub_category_id:40},
  ]

const options = [{ name: "Strongly Disagree", value: 1 }, { name: "Disagree", value: 2 }, { name: "Neutral", value: 3 }, { name: "Agree", value: 4 }, { name: "Strongly Agree", value: 5 }]



export {
    mediaResources,
    options,
    categories
}


