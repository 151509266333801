/* eslint-disable jsx-a11y/img-redundant-alt */
import { medias } from "../../../../config/Resources";
import { RiExternalLinkLine } from "react-icons/ri";

function IntroductionCards({scholarshipDetails}) {
	const data = [
		{
			name: "Total Scholarships Available",
			Based: scholarshipDetails?.map((data) => (data?.no_of_scholarships)),
			image: medias.images.College.Money,
		},
		{
			name: "Website Link",
			Based: scholarshipDetails?.map((data) => (data?.website)),
			image: medias.images.College.Web,
		},
		{
			name: "Application Fees",
			Based: scholarshipDetails?.map((data) => (data?.application_fee)),
			image: medias.images.College.ApplicationFee,
		},
	];

	return (
		<div className="ml-4 mr-4 mt-6 flex flex-wrap gap-5 justify-between">
			{data?.map((item, index) => {
				return (
					<div
						key={index}
						className="w-full sm:w-[calc(50%-10px)] border border-[#707070] rounded-lg border-opacity-30 pt-2 pl-4 pr-4 pb-5"
					>
						<div className="flex items-center text-center">
							<span className="pt-[5px] pb-[5px] pl-[3px] pr-[3px]">
								<img className="h-[30px] w-8" src={item?.image} alt="image" />
							</span>
							<h6 className="font-plusJakartaSans ml-[3px] mt-[2px] font-extrabold text-[#000000] text-[12px]">
								{item?.name}
							</h6>
						</div>
						<div className="mt-1">
							{item?.name === "Website Link" ? (
								<a
									href={`https://${item?.Based}`}
									className="font-plusJakartaSans ml-[5px] font-medium text-[10px] text-[#2771E7] flex items-center"
									target="_blank"
									rel="noopener noreferrer"
								>
									{item?.Based}
									<RiExternalLinkLine className="ml-1" />
								</a>
							) : (
								<p className="font-plusJakartaSans ml-[5px] font-extrabold text-[22px]">
									{item?.Based}
								</p>
							)}
						</div>
					</div>
				);
			})}
		</div>
	);
}

export default IntroductionCards;
