/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import { medias } from "../../config/Resources";
import { VscClose } from "react-icons/vsc";
import { IoIosArrowRoundForward } from "react-icons/io";
import { API_URLS } from "../../config/API_URLS";
import axiosInstance from "../../config/Axios/axiosInstance";
import { toast } from "react-toastify";

function EditModal({
	isModalOpen1,
	setIsModalOpen1,
	toggleModal1,
	selectedItemId,
	setData,
}) {
	const [goal, setGoal] = useState("");

	const getAcademicRoadMapData = async () => {
		try {
			const res = await axiosInstance.get(
				`${API_URLS.baseURL}/getPassionRoadmap?type=`
			);
			setData(res.result);
			console.log(res, "responseData");
		} catch (error) {
			console.log(error);
		}
	};


	useEffect(() => {
		if (selectedItemId) {
			setGoal(selectedItemId.category);
		}
	}, [selectedItemId]);

	const handleSave = async () => {
		const payload = {
			passionData: {
				type: "",
				passion_id: selectedItemId.passion_id,
				category: goal,
			},
		};

		try {
			const response = await axiosInstance.post(
				`${API_URLS.baseURL}/editPassionCategory`,
				payload
			);

			if (response) {
				toast("Goal Updated Successfully", { type: "success" });
				getAcademicRoadMapData()
				setIsModalOpen1(false);
			} else {
				console.error("Error:", response.statusText);
			}
		} catch (error) {
			console.error("Error:", error);
		}
	};

	return (
		<div>
			<div
				id="default-modal"
				tabIndex="-1"
				aria-hidden="true"
				className={`fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full h-screen bg-black bg-opacity-10 ${
					isModalOpen1 ? "" : "hidden"
				}`}
			>
				<div className="relative p-4 w-full max-w-lg">
					<div className="relative bg-[#E4F6FF] rounded-lg shadow dark:bg-gray-700">
						<div className="flex text-center ml-5">
							<img
								className="w-8 h-8 mt-5"
								src={medias.images.Profile.category}
								alt="image"
							/>
							<h6 className="ml-1 mt-7">Edit Goal</h6>
						</div>
						<div className="p-4 md:p-5 space-y-4 max-h-96 overflow-y-auto scrollbar-hide">
							<input
								type="text"
								className="block w-full p-2 rounded-lg focus:outline-none"
								placeholder="Goal"
								value={goal}
								onChange={(e) => setGoal(e.target.value)}
							/>
							<div className="flex items-end justify-end">
								<button
									className="flex align-center items-center justify-center  w-[] mt-2 bg-[#FFDDCE]  text-black font-medium rounded-lg text-sm px-5 py-2.5 text-center"
									onClick={() => toggleModal1()}
								>
									<p className="font-plusJakartaSans text-sm font-semibold">
										Cancel
									</p>
									<VscClose className="ml-1" />
								</button>
								<button
									className="flex align-center items-center justify-center ml-2 w-[] mt-2 bg-[#F8D865]  text-black font-medium rounded-lg text-sm px-5 py-2.5 text-center"
									onClick={handleSave}
								>
									<p className="font-plusJakartaSans text-sm font-semibold">
										edit
									</p>
									<IoIosArrowRoundForward className="ml-1" />
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default EditModal;
