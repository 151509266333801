import { medias } from "../../../../config/Resources";

function ScholarShipName({ scholarshipDetails }) {
	return (
		<div className="ml-4 mr-4">
			<div className="flex items-center gap-2 mt-5">
				<img
					className="w-9 h-9 bg-[#EEEEEE] rounded-sm p-1"
					src={medias.images.College.Contract}
					alt="courses"
				/>
				{scholarshipDetails?.map((scholarShipName, index) => {
					return (
						<div key={index}>
							<p className="font-semibold text-[#000000] opacity-50 font-plusJakartaSans text-[9px]">
								Scholarship Name
							</p>
							<p className="font-bold text-[#000000] font-plusJakartaSans text-[14px] flex item-center gap-1">
								{scholarShipName?.scholarship_title}
								<span className="text-[#000000] text-[9px] rounded-md bg-[#000000] bg-opacity-10 pt-1 pb-1 pl-2 pr-2">
									Institute Based
								</span>
							</p>
						</div>
					);
				})}
			</div>
		</div>
	);
}

export default ScholarShipName;
