import React from "react";
import Image from "../../Image";
import { medias } from "../../../config/Resources";
import { guidancePageContant } from "../../../config/pageContent";
import { Button } from "@mui/material";
import Text from "../../Text";

const Face5 = () => {
  return (
    <>
      <div className="pt-[140px] bg-gradient-to-b from-white via-blue-50 to-blue-300 p-[0]">
        <div className="flex pl-[81px] gap-[65px]">
          <div className="w-[45%]">
            <Image
              src={medias.images.guidance.Face4.face4languageImages}
              alt="face4languageImages"
            />
          </div>
          <div className="w-[65%] flex flex-col justify-between ">
            <div className="text-[42px] font-bold z-30  ">
              {guidancePageContant.Face4.indiaMostScientific}
            </div>
            <div>
              <Text className="text-[16px] w-[80%] font-medium">
                {guidancePageContant.Face4.description}
              </Text>
            </div>
            <Button
              variant="contained"
              className="bg-[#2771E6] shadow-lg shadow-[#acc3ec] !w-fit !rounded-[10px] !text-[15px] gap-4 !mt-4 !capitalize  !px-10 py-4"
            >
              {guidancePageContant.Face4.getStrted}
            </Button>
          </div>
        </div>
        <div className="mt-[30px]">
          <Image
            src={medias.images.guidance.Face4.face4Base}
            alt="face4languageImages"
          />
        </div>
      </div>
    </>
  );
};

export default Face5;
