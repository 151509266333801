import { useState } from "react";

const useDebounce = <T extends any[]>(
  callback: (...args: T) => void,
  delay: number
) => {
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

  return (...args: T) => {
    if (timer) {
      clearTimeout(timer);
    }
    setTimer(setTimeout(() => callback(...args), delay));
  };
};

export default useDebounce;
