import { OutlinedInput, OutlinedInputProps } from "@mui/material";
import React from "react";

interface Props extends OutlinedInputProps {
  helperText?: string | JSX.Element | JSX.Element[];
}
const inputStyle = { WebkitBoxShadow: "0 0 0 1000px white inset" };

const TextField1 = ({ helperText, ...rest }: Props) => {
  return (
    <>
      <OutlinedInput
        className="!outline-none"
        inputProps={{ style: inputStyle }}
        classes={{
          root: "!border !rounded-[14px] !border-[#70707037]",
          input:
            "w-full !outline-none miniDesk:!py-[10px] !text-[17px] miniDesk:!text-[14px] !placeholder:opacity-50 !placeholder:text-black",
        }}
        {...rest}
      />
    </>
  );
};

export default TextField1;
