import React from "react";
import { medias } from "../../config/Resources";
import { useNavigate } from "react-router-dom";

const FutureGoalCards = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="flex justify-between items-center w-90">
        <span className="text-2xl font-normal font-quitery">Future Goals</span>
        <span className="font-bold">
          <div
            title="See Roadmap"
            onClick={() => {
              navigate("/roadmap/type/academic");
            }}
            className="flex cursor-pointer items-center text-[14px] font-plusJakartaSans text-blue-500"
          >
            See Roadmap
            <img
              className="w-8 h-8 ml-1"
              src={medias.images.Profile.Edit}
              alt="image2"
            />
          </div>
        </span>
      </div>

      <div className="bg-white rounded-xl shadow-md overflow-hidden border border-gray-300 w-full pt-3 pb-3 pl-2 pr-2 mb-8 mt-3">
        <div className="flex justify-between items-center">
          <span className="text-base font-bold font-plusJakartaSans text-green-600">
            Get 94% in 12th Board Exam
          </span>
          <span className="text-xs font-bold font-plusJakartaSans">
            120 Days
          </span>
        </div>

        <div className="flex mt-2">
          <span className="text-xs font-bold font-plusJakartaSans">
            12 Aug 2023
          </span>
          <span className="text-xs ml-14 font-bold font-plusJakartaSans">
            24 Aug 2026
          </span>
        </div>

        <div className="flex items-center mt-1 justify-between">
          <span className="text-xs font-bold text-gray-500 font-plusJakartaSans">
            Created on
          </span>
          <span className="text-xs font-bold text-gray-500 font-plusJakartaSans">
            Deadline
          </span>
          <span className="text-xs">
            <img
              className="w-4 h-4 mr-2"
              src={medias.images.Profile.Setting}
              alt="image2"
            />
          </span>
        </div>
      </div>
    </>
  );
};

export default FutureGoalCards;
