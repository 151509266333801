/* eslint-disable no-unused-vars */
import { useEffect, useRef, useState } from "react";
import { db } from "../firebase.config";
// import {
//   doc,
//   setDoc,
//   getDoc,
//   onSnapshot,
//   updateDoc,
//   deleteDoc,
//   addDoc,
//   query,
//   getDocs,
//   collection,
// } from "firebase/firestore";

const configuration = {
  iceServers: [{ urls: "stun:stun.l.google.com:19302" }],
};

const WebRTCComponent = ({ user, calleeId = 1 }) => {
  const localVideoRef = useRef();
  const remoteVideoRef = useRef();
  const pc = useRef(null);
  const [localStream, setLocalStream] = useState(null);
  const [remoteStream, setRemoteStream] = useState(new MediaStream());
  const [gettingCall, setGettingCall] = useState(false);
  const connecting = useRef(false);

  // useEffect(() => {
  //   const cRef = doc(collection(db, "meet"), "chatId");
  //   const unsubscribe = onSnapshot(cRef, async (snapshot) => {
  //     const data = snapshot.data();
  //     console.log("data", data);
  //     if (pc.current && !pc.current.remoteDescription && data?.answer) {
  //       pc.current.setRemoteDescription(new RTCSessionDescription(data.answer));
  //     }
  //     if (data?.offer && !connecting.current) {
  //       setGettingCall(true);
  //     }
  //   });

  //   const q = query(collection(db, "meet", "chatId", "callee"));
  //   const subscribeDelete = onSnapshot(q, (snapshot) => {
  //     snapshot.docChanges().forEach((change) => {
  //       if (change.type === "removed") {
  //         hangup();
  //       }
  //     });
  //   });

  //   return () => {
  //     unsubscribe();
  //     subscribeDelete();
  //   };
  // }, [user, calleeId]);

  // const setupWebrtc = async () => {
  //   pc.current = new RTCPeerConnection(configuration);

  //   try {
  //     const currentStream = await navigator.mediaDevices.getUserMedia({
  //       video: true,
  //       audio: true,
  //     });

  //     setLocalStream(currentStream);

  //     if (localVideoRef.current) {
  //       localVideoRef.current.srcObject = currentStream;
  //       currentStream.getTracks().forEach((track) => {
  //         pc.current.addTrack(track, currentStream);
  //       });
  //     }

  //     pc.current.ontrack = (event) => {
  //       event.streams[0].getTracks().forEach((track) => {
  //         remoteStream.addTrack(track);
  //       });
  //       setRemoteStream(remoteStream);
  //     };
  //   } catch (error) {
  //     console.error("Error accessing media devices.", error);
  //   }
  // };

  // useEffect(() => {
  //   setupWebrtc();
  // }, [gettingCall]);

  console.log("Local Stream", localStream);
  console.log("Remote Stream", remoteStream);

  // useEffect(() => {
  //   if (remoteVideoRef.current && remoteStream) {
  //     remoteVideoRef.current.srcObject = remoteStream;
  //   }
  // }, [remoteStream]);

  // const create = async () => {
  //   connecting.current = true;
  //   const cRef = doc(collection(db, "meet"), "chatId");

  //   console.log("cRef", cRef);
  //   collectIceCandidates(cRef, "caller", "callee");

  //   if (pc.current) {
  //     const offer = await pc.current.createOffer();
  //     await pc.current.setLocalDescription(offer);
  //     const cWithOffer = {
  //       offer: {
  //         type: offer.type,
  //         sdp: offer.sdp,
  //       },
  //     };
  //     await setDoc(cRef, cWithOffer);
  //   }
  // };

  // const join = async () => {
  //   connecting.current = true;
  //   setGettingCall(false);
  //   const cRef = doc(collection(db, "meet"), "chatId");
  //   const offer = (await getDoc(cRef)).data()?.offer;
  //   if (offer) {
  //     collectIceCandidates(cRef, "callee", "caller");
  //     if (pc.current) {
  //       await pc.current.setRemoteDescription(new RTCSessionDescription(offer));
  //       const answer = await pc.current.createAnswer();
  //       await pc.current.setLocalDescription(answer);
  //       const cWithAnswer = {
  //         answer: {
  //           type: answer.type,
  //           sdp: answer.sdp,
  //         },
  //       };
  //       await updateDoc(cRef, cWithAnswer);

  //       pc.current.ontrack = (event) => {
  //         event.streams[0].getTracks().forEach((track) => {
  //           remoteStream.addTrack(track);
  //         });
  //         setRemoteStream(remoteStream);
  //       };
  //     }
  //   }
  // };

  // const streamCleanUp = () => {
  //   if (localStream) {
  //     localStream.getTracks().forEach((t) => t.stop());
  //     setLocalStream(null);
  //     setRemoteStream(new MediaStream());
  //   }
  // };

  // const firestoreCleanUp = async () => {
  //   const cRef = doc(collection(db, "meet"), "chatId");
  //   const calleeCandidate = await getDocs(collection(cRef, "callee"));
  //   calleeCandidate.forEach(async (candidate) => {
  //     await deleteDoc(candidate.ref);
  //   });
  //   const callerCandidate = await getDocs(collection(cRef, "caller"));
  //   callerCandidate.forEach(async (candidate) => {
  //     await deleteDoc(candidate.ref);
  //   });
  //   await deleteDoc(cRef);
  // };

  // const hangup = async () => {
  //   setGettingCall(false);
  //   connecting.current = false;
  //   streamCleanUp();
  //   await firestoreCleanUp();
  //   if (pc.current) {
  //     pc.current.close();
  //   }
  // };

  // const collectIceCandidates = async (cRef, localName, remoteName) => {
  //   const candidateCollection = collection(cRef, localName);
  //   if (pc.current) {
  //     pc.current.onicecandidate = (event) => {
  //       if (event.candidate) {
  //         addDoc(candidateCollection, event.candidate.toJSON());
  //       }
  //     };
  //   }
  //   onSnapshot(collection(cRef, remoteName), (snapshot) => {
  //     snapshot.docChanges().forEach((change) => {
  //       if (change.type === "added") {
  //         const candidate = new RTCIceCandidate(change.doc.data());
  //         pc.current?.addIceCandidate(candidate);
  //       }
  //     });
  //   });
  // };

  return (
    // <div className="webrtc-container">
    //   {gettingCall ? (
    //     <div className="getting-call">
    //       <button onClick={join}>Join Call</button>
    //       <button onClick={hangup}>Hang Up</button>
    //     </div>
    //   ) : localStream ? (
    //     <div className="video-container">
    //       <video ref={localVideoRef} autoPlay playsInline muted />
    //       {remoteStream && remoteStream.active ? (
    //         <video ref={remoteVideoRef} autoPlay playsInline />
    //       ) : (
    //         <>No remote Video</>
    //       )}
    //       <button onClick={create}>Call Now</button>
    //     </div>
    //   ) : (
    //     <div className="call-button-container">
    //       <button onClick={hangup}>Hang Up</button>
    //     </div>
    //   )}
    // </div>
    <></>
  );
};

export default WebRTCComponent;
