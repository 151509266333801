import React from "react";
import DrowDown from "../DropDown/DropDown1";

type Props = {
  title?: string;
  menu?: JSX.Element | JSX.Element[];
};

const HeaderDrops = ({ title, menu }: Props) => {
  return (
    <div>
      <DrowDown title={title} menu={menu} />
    </div>
  );
};

export default HeaderDrops;
