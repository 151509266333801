import React, { useState } from "react";
import { medias } from "../../config/Resources";

const Tab = ({ setActiveTab }) => {
    const [active, setActive] = useState(1);
    const tabs = [
        { name: "Academics", id: 1, imageUrl: medias.images.Profile.Academy },
        { name: "Extra Curricular activities", id: 2, imageUrl: medias.images.Profile.Extra },
        { name: "Internship & Trainings", id: 3, imageUrl: medias.images.Profile.Bag },
        { name: "Life skills", id: 4, imageUrl: medias.images.Profile.Life },
        { name: "Passion Projects", id: 5, imageUrl: medias.images.Profile.Passion },
        { name: "Health(Physical & Mental)", id: 6, imageUrl: medias.images.Profile.Health },
    ];

    const handleTabClick = (id) => {
        setActive(id);
        setActiveTab(id);
    };

    return (
        <div className="flex flex-col mt-5">
            <div className={`${active === 2 ? "w-full" : "w-[100%]"}`}>
                <div className="flex ml-5 font-bold border-b border-gray-200">
                    {tabs?.map((tab) => (
                        <div
                            key={tab.id}
                            onClick={() => handleTabClick(tab.id)}
                            className={`relative  cursor-pointer flex items-center mx-2 ${
                                active === tab.id ? 'bg-[#DFDFDF] rounded-t-lg' : ''
                            }`}
                        >
                            <div className="flex items-center p-2">
                                <img
                                    src={tab.imageUrl}
                                    alt={tab.name}
                                    className="w-6 h-6"
                                />
                                <h3 className={`pb-3 px-2 text-sm ${active === tab.id ? 'font-bold' : 'font-light'}`}>
                                    <span className="font-plusJakartaSans">{tab.name}</span>
                                </h3>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Tab;
