import { FaStar } from "react-icons/fa";
import { medias } from "../../../config/Resources";

const CardsMentorship = ({ activeTab }) => {
	return (
		<div className="text-sm leading-6 mt-5 flex gap-4">
			<figure
				 className="border border-gray-300 dark:border-gray-600 w-2/5 rounded-2xl"
			>
				<figcaption className="items-center">
					<div className="flex p-3">
						<img
							src="https://images.unsplash.com/photo-1632910121591-29e2484c0259?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w0NzEyNjZ8MHwxfHNlYXJjaHw4fHxjb2RlcnxlbnwwfDB8fHwxNzEwMTY0NjIzfDA&ixlib=rb-4.0.3&q=80&w=1080"
							alt=""
							className="flex-none w-14 h-14 rounded-full object-cover"
							loading="lazy"
							decoding="async"
						/>
						<div className="flex-auto ml-3">
							<div className="text-base font-semibold">Roshan Singh</div>
							<div className="mt-0.5 dark:text-slate-300 flex gap-1">
								<img
									className="w-[9%]"
									src={medias.images.mentorship.scienceTechnology}
									alt="scienceTechnology"
								/>
								Science and Technology
							</div>
						</div>
						{activeTab === 2 && (
							<div className="flex-auto relative">
								<div
									className="text-base bg-[#FFE7D1] pl-2 pr-2 pb-1 absolute"
									style={{
										top: "-21.5%",
										right: "0",
										borderBottomLeftRadius: "10px",
										borderBottomRightRadius: "10px",
									}}
								>
									<div style={{ alignItems: "center" }}>
										<span
											className="text-xs font-semibold"
											style={{ fontSize: "10px" }}
										>
											Start&nbsp;in
										</span>
										<div style={{ marginLeft: "4px", display: "block" }}>
											<span className="font-bold">45</span>
											<span
												className="text-xs font-semibold"
												style={{ display: "block" }}
											>
												min
											</span>
										</div>
									</div>
								</div>
							</div>
						)}
					</div>
					<div
						className="flex items-center bg-gray-200 p-2"
						style={{ width: "70%" }}
					>
						<span className="font-bold" style={{ display: "flex" }}>
							<img
								className="w-4"
								src={medias.images.mentorship.calender}
								alt="calender"
							/>
							<span className="ml-1" style={{ fontSize: "12px" }}>
								12 May 24
							</span>
						</span>
						<span
							className="ml-4 font-bold"
							style={{ fontSize: "12px", display: "flex" }}
						>
							<img
								className="w-4"
								src={medias.images.mentorship.clock}
								alt="clock"
							/>
							<span className="ml-1">9:30-10:30pm</span>
						</span>
					</div>
					<div className="flex justify-between m-2">
						<span className="font-bold">
							Ratings <br />{" "}
							<span
								style={{
									display: "flex",
									alignContent: "center",
									alignItems: "center",
								}}
							>
								<FaStar style={{ color: "#F0D41B", fontSize: "15px" }} /> 4.5
							</span>
						</span>
						<span className="font-bold">
							Session Completed
							<br /> 24
						</span>
						<span className="font-bold">
							Duration <br /> 1hrs
						</span>
					</div>
					{activeTab === 1 && (
					<div className="mt-2 ml-2 mr-2 mb-4">
						<button
							className="text-green-800 rounded-lg w-full h-10"
							style={{ backgroundColor: "#239403" }}
						>
							<span style={{ color: "#ffffff", fontWeight: "bold" }}>
								Start Session
							</span>
						</button>
					</div>
					)}
					{activeTab === 2 && (
					<div className="mt-2 ml-2 mr-2 mb-4">
						<button
							className="text-green-800 rounded-lg w-full h-10"
							style={{ backgroundColor: "#239403" }}
						>
							<span style={{ color: "#ffffff", fontWeight: "bold" }}>
								Start Session
							</span>
						</button>
					</div>
					)}
					{activeTab === 3 && (
						<div className="mt-2 ml-2 mr-2 mb-4">
							<button
								className="text-green-800 rounded-lg w-full h-10 "
								style={{ backgroundColor: "#EBF0FF", border:'1px solid #2771E7' }}
							>
								<span style={{ color: "#2771E7", fontWeight: "bold" }}>
									Give Review
								</span>
							</button>
						</div>
					)}
				</figcaption>
			</figure>
			<figure
				className="border border-gray-300 dark:border-gray-600 w-4/12"
				style={{ borderRadius: "7%", width: "40%" }}
			>
				<figcaption className="items-center">
					<div className="flex p-3">
						<img
							src="https://images.unsplash.com/photo-1632910121591-29e2484c0259?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w0NzEyNjZ8MHwxfHNlYXJjaHw4fHxjb2RlcnxlbnwwfDB8fHwxNzEwMTY0NjIzfDA&ixlib=rb-4.0.3&q=80&w=1080"
							alt=""
							className="flex-none w-14 h-14 rounded-full object-cover"
							loading="lazy"
							decoding="async"
						/>
						<div className="flex-auto ml-3">
							<div className="text-base font-semibold">Roshan Singh</div>
							<div className="mt-0.5 dark:text-slate-300 flex gap-1">
								<img
									className="w-[9%]"
									src={medias.images.mentorship.scienceTechnology}
									alt="scienceTechnology"
								/>
								Science and Technology
							</div>
						</div>
						{activeTab === 2 && (
							<div className="flex-auto relative">
								<div
									className="text-base bg-[#FFE7D1] pl-2 pr-2 pb-1 absolute"
									style={{
										top: "-21.5%",
										right: "0",
										borderBottomLeftRadius: "10px",
										borderBottomRightRadius: "10px",
									}}
								>
									<div style={{ alignItems: "center" }}>
										<span
											className="text-xs font-semibold"
											style={{ fontSize: "10px" }}
										>
											Start&nbsp;in
										</span>
										<div style={{ marginLeft: "4px", display: "block" }}>
											<span className="font-bold">45</span>
											<span
												className="text-xs font-semibold"
												style={{ display: "block" }}
											>
												min
											</span>
										</div>
									</div>
								</div>
							</div>
						)}
					</div>
					<div
						className="flex items-center bg-gray-200 p-2"
						style={{ width: "70%" }}
					>
						<span className="font-bold" style={{ display: "flex" }}>
							<img
								className="w-4"
								src={medias.images.mentorship.calender}
								alt="calender"
							/>
							<span className="ml-1" style={{ fontSize: "12px" }}>
								12 May 24
							</span>
						</span>
						<span
							className="ml-4 font-bold"
							style={{ fontSize: "12px", display: "flex" }}
						>
							<img
								className="w-4"
								src={medias.images.mentorship.clock}
								alt="clock"
							/>
							<span className="ml-1">9:30-10:30pm</span>
						</span>
					</div>
					<div className="flex justify-between m-2">
						<span className="font-bold">
							Ratings <br />{" "}
							<span
								style={{
									display: "flex",
									alignContent: "center",
									alignItems: "center",
								}}
							>
								<FaStar style={{ color: "#F0D41B", fontSize: "15px" }} /> 4.5
							</span>
						</span>
						<span className="font-bold">
							Session Completed
							<br /> 24
						</span>
						<span className="font-bold">
							Duration <br /> 1hrs
						</span>
					</div>
					{activeTab === 1 && (
					<div className="mt-2 ml-2 mr-2 mb-4">
						<button
							className="text-green-800 rounded-lg w-full h-10"
							style={{ backgroundColor: "#239403" }}
						>
							<span style={{ color: "#ffffff", fontWeight: "bold" }}>
								Start Session
							</span>
						</button>
					</div>
					)}
					{activeTab === 2 && (
					<div className="mt-2 ml-2 mr-2 mb-4">
						<button
							className="text-green-800 rounded-lg w-full h-10"
							style={{ backgroundColor: "#239403" }}
						>
							<span style={{ color: "#ffffff", fontWeight: "bold" }}>
								Start Session
							</span>
						</button>
					</div>
					)}
					{activeTab === 3 && (
						<div className="mt-2 ml-2 mr-2 mb-4">
							<button
								className="text-green-800 rounded-lg w-full h-10 "
								style={{ backgroundColor: "#EBF0FF", border:'1px solid #2771E7' }}
							>
								<span style={{ color: "#2771E7", fontWeight: "bold" }}>
									Give Review
								</span>
							</button>
						</div>
					)}
				</figcaption>
			</figure>
		</div>
	);
};

export default CardsMentorship;
