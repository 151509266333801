/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import axiosInstance from "../../../config/Axios/axiosInstance";
import { API_URLS } from "../../../config/API_URLS";
import Image from "../../Image";
import { medias } from "../../../config/Resources";
import { Controls, Player } from "@lottiefiles/react-lottie-player";
import planet from "../../../animations/planet.json";
import { Button } from "@mui/material";
import { useIntrestStore } from "../../../store";
import { useUserInfoStore } from "../../../store/userinfo";
import { useNavigate } from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  Checklist,
  ChevronRight,
  Language,
  Monitor,
  VolumeUp,
} from "@mui/icons-material";
import Text from "../../Text";

const Getstarted = () => {
  const [page, setPage] = useState(1);
  const limit = 10;
  const navigate = useNavigate();
  const { userInfo, setIsloading }: any = useUserInfoStore((state) => state);
  const { intrestquestions }: any = useIntrestStore((state) => state);
  const [questionLists, setQuestionsList] = useState<any>(null);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const handleFetchQuestion = async (queryKey: any) => {
    return await axiosInstance
      .get(
        `${API_URLS.getInterestQuestionPaper}?page=${queryKey[1]}&limit=${limit}`
      )
      .then((res: any) => {
        const currentQuestions: any = questionLists?.result
          ? [...questionLists?.result, ...res?.result]
          : [...res?.result];
        const newValue = { ...res, result: [...currentQuestions] };
        setQuestionsList({ ...newValue });
        return newValue;
      });
  };

  const { mutate: submitQuestion, isPending } = useMutation({
    mutationFn: async () =>
      await axiosInstance
        .post(API_URLS.addInterestReport, {
          data: intrestquestions,
          student_id: userInfo?.id,
        })
        .then((res) => {
          navigate("/psychomatric-test/personality");
          return res;
        }),
  });

  useEffect(() => {
    setIsloading(isPending);
  }, [isPending]);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      const message =
        "Are you sure? You'll loose all the progress that you have achieved";
      event.returnValue = message; // Standard for most browsers
      return message; // For some older browsers
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const { isLoading: IsIntrestLoading }: { data: any; isLoading: boolean } =
    useQuery({
      queryKey: ["getInterestQuestionPaper", page],
      queryFn: ({ queryKey }) => handleFetchQuestion(queryKey),
      refetchOnMount: true,
      retry: false,
      refetchInterval: false,
    });

  useEffect(() => {
    if (
      questionLists?.total_count - currentQuestion + 1 > 10 &&
      (currentQuestion + 1) % 10 === 2
    ) {
      setPage(page + 1);
    }
  }, [currentQuestion]);

  return (
    <>
      <div className="flex items-center justify-center h-screen bg-no-repeat bg-cover IntrestMainBackground">
        <div className="getStarted z-50 relative bg-contain bg-no-repeat flex  pt-[75px] pb-[70px] px-[13px] items-center justify-center !w-[70%] desktop:w-[76%] h-[90%]">
          <div className="absolute w-[calc(100%-30px)] h-[calc(100%-135px)] desktop:!top-[12%] desktop:!h-[81%] bg-black bg-opacity-50 top-[calc(0%+85px)] gridBackGround-intrest   backdrop-blur-lg" />
          <div className="relative z-50 w-full h-full py-[19px] desktop:pt-0 px-[13px]">
            <Image
              src={medias.images.intrestImages.SelectorsDrops}
              alt="SelectorsDrops"
              className="absolute desktop:w-[8px]  top-[calc(0%+30px)] left-[calc(0%+15px)]"
            />
            <div className="px-[75px] flex flex-col py-[26px] h-full">
              <div id="header" className="flex w-full">
                <Button
                  onClick={() => navigate(-1)}
                  className="!bg-[#F37400] !flex !items-center desktop:!text-[16px] desktop:!py-[8px] !px-[28px] !font-quitery !py-[10px] !rounded-full !text-[20px]  !shadow-[0px_8px_0px_0px_rgba(136,65,0,1)]"
                  variant="contained"
                >
                  <ChevronLeftIcon className=" !text-[28px] desktop:!w-[20px]" />{" "}
                  Back
                </Button>
                <Button
                  className="!bg-[#F37400] !ml-auto !flex text-base gap-x-[5px] !items-center desktop:!text-[16px] desktop:!py-[8px] !px-[28px] !font-quitery !py-[10px] !rounded-full !text-[20px]  !shadow-[0px_8px_0px_0px_rgba(136,65,0,1)]"
                  variant="contained"
                >
                  Music <VolumeUp className=" !text-[28px] desktop:!w-[20px]" />
                </Button>
              </div>
              <div
                id="body"
                className="my-[40px] w-full desktop:gap-[40px] h-full gap-[52px] flex flex-col items-center"
              >
                <div className="w-3/4 rounded-full flex items-center gap-[27px] desktop:text-[20px] text-[29px] !shadow-[0px_8px_0px_0px_rgba(138,0,166,1)] !font-quitery !text-white px-[27px] py-[10px] !bg-[#B745CD]">
                  <Language className="!text-white !text-[29px] !w-[27px]" />{" "}
                  Language
                  <Text className="ml-auto desktop:text-[20px] !font-quitery">
                    English
                  </Text>
                </div>
                <div className="w-3/4 rounded-full desktop:text-[20px] flex items-center gap-[27px] text-[29px] !shadow-[0px_8px_0px_0px_rgba(22,88,3,1)] !font-quitery !text-white px-[27px] py-[10px] !bg-[rgba(35,148,3,1)]">
                  {/* <Monitor className='!text-white !text-[29px] !w-[27px]' /> */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32.434"
                    height="32.434"
                    viewBox="0 0 32.434 32.434"
                  >
                    <g
                      id="Group_329735"
                      data-name="Group 329735"
                      transform="translate(-7 -7)"
                    >
                      <path
                        id="Path_197473"
                        data-name="Path 197473"
                        d="M36.485,39.434H31.571a.983.983,0,1,1,0-1.966h4.914a.984.984,0,0,0,.983-.983V9.949a.984.984,0,0,0-.983-.983H9.949a.984.984,0,0,0-.983.983v4.914a.983.983,0,0,1-1.966,0V9.949A2.952,2.952,0,0,1,9.949,7H36.485a2.952,2.952,0,0,1,2.949,2.949V36.485A2.952,2.952,0,0,1,36.485,39.434Z"
                        fill="#fff"
                      />
                      <path
                        id="Path_197474"
                        data-name="Path 197474"
                        d="M7,34.177V29.949A2.952,2.952,0,0,1,9.949,27h1.836Zm21.622-2.263a.983.983,0,0,1-.983-.983v-.983a.984.984,0,0,0-.983-.983h-.983a.983.983,0,1,1,0-1.966h.983a2.952,2.952,0,0,1,2.949,2.949v.983A.983.983,0,0,1,28.622,31.914Z"
                        transform="translate(0 -10.172)"
                        fill="#fff"
                      />
                      <path
                        id="Line_2371"
                        data-name="Line 2371"
                        d="M.949-.034H-1.017A.983.983,0,0,1-2-1.017.983.983,0,0,1-1.017-2H.949a.983.983,0,0,1,.983.983A.983.983,0,0,1,.949-.034Z"
                        transform="translate(15.388 18.828)"
                        fill="#fff"
                      />
                      <path
                        id="Line_2372"
                        data-name="Line 2372"
                        d="M.949-.034H-1.017A.983.983,0,0,1-2-1.017.983.983,0,0,1-1.017-2H.949a.983.983,0,0,1,.983.983A.983.983,0,0,1,.949-.034Z"
                        transform="translate(21.285 18.828)"
                        fill="#fff"
                      />
                      <path
                        id="Line_2373"
                        data-name="Line 2373"
                        d="M-1.017,1.931A.983.983,0,0,1-2,.949V-1.017A.983.983,0,0,1-1.017-2a.983.983,0,0,1,.983.983V.949A.983.983,0,0,1-1.017,1.931Z"
                        transform="translate(29.64 25.217)"
                        fill="#fff"
                      />
                      <path
                        id="Line_2374"
                        data-name="Line 2374"
                        d="M-1.017,1.931A.983.983,0,0,1-2,.949V-1.017A.983.983,0,0,1-1.017-2a.983.983,0,0,1,.983.983V.949A.983.983,0,0,1-1.017,1.931Z"
                        transform="translate(29.64 31.114)"
                        fill="#fff"
                      />
                      <path
                        id="Line_2375"
                        data-name="Line 2375"
                        d="M-1.017,1.931A.983.983,0,0,1-2,.949V-1.017A.983.983,0,0,1-1.017-2a.983.983,0,0,1,.983.983V.949A.983.983,0,0,1-1.017,1.931Z"
                        transform="translate(9 25.217)"
                        fill="#fff"
                      />
                      <path
                        id="Line_2376"
                        data-name="Line 2376"
                        d="M-1.017,1.931A.983.983,0,0,1-2,.949V-1.017A.983.983,0,0,1-1.017-2a.983.983,0,0,1,.983.983V.949A.983.983,0,0,1-1.017,1.931Z"
                        transform="translate(9 31.114)"
                        fill="#fff"
                      />
                      <path
                        id="Path_197475"
                        data-name="Path 197475"
                        d="M26.657,65.572H24.82l4.785-7.177v4.229A2.952,2.952,0,0,1,26.657,65.572Zm-15.725,0H9.949A2.952,2.952,0,0,1,7,62.623v-.983a.983.983,0,1,1,1.966,0v.983a.984.984,0,0,0,.983.983h.983a.983.983,0,0,1,0,1.966Z"
                        transform="translate(0 -26.138)"
                        fill="#fff"
                      />
                      <path
                        id="Line_2377"
                        data-name="Line 2377"
                        d="M.949-.034H-1.017A.983.983,0,0,1-2-1.017.983.983,0,0,1-1.017-2H.949a.983.983,0,0,1,.983.983A.983.983,0,0,1,.949-.034Z"
                        transform="translate(21.285 39.468)"
                        fill="#fff"
                      />
                      <path
                        id="Line_2378"
                        data-name="Line 2378"
                        d="M.949-.034H-1.017A.983.983,0,0,1-2-1.017.983.983,0,0,1-1.017-2H.949a.983.983,0,0,1,.983.983A.983.983,0,0,1,.949-.034Z"
                        transform="translate(15.388 39.468)"
                        fill="#fff"
                      />
                    </g>
                  </svg>
                  Test for
                  <Text className="ml-auto  desktop:text-[16px]  !font-quitery ">
                    {userInfo?.class}
                  </Text>
                </div>
                <div className="w-3/4 desktop:text-[20px] rounded-full flex items-center gap-[27px] text-[29px] !shadow-[0px_8px_0px_0px_rgba(5,68,169,1)] !font-quitery !text-white px-[27px] py-[10px] !bg-[rgba(39,113,231,1)]">
                  <Checklist className="!text-white !text-[29px] !w-[27px]" />{" "}
                  Test Type
                  <Text className="ml-auto  desktop:text-[20px] !font-quitery">
                    Interest
                  </Text>
                </div>
                <Button
                  onClick={() => {
                    const element: any = document.getElementById("audioplayer");
                    if (element) {
                      element.play();
                    }
                    navigate("/psychomatric-test?category=intrest");
                  }}
                  className="!bg-[rgba(209,40,40,1)] mt-auto desktop:text-[20px] !flex !items-center !px-[28px] !font-quitery !py-[10px] !rounded-xl !text-[26px]  !shadow-[0px_8px_0px_0px_rgba(136,65,0,1)]"
                  variant="contained"
                >
                  Start{" "}
                  <ChevronRight className="  bg-[rgba(155,0,0,1)] ml-4 !w-[30px] desktop:!w-[25px] desktop:!h-[25px] !h-[30px] !rounded-full " />
                </Button>
              </div>
            </div>
          </div>
        </div>
        <Image
          src={medias.images.getStarted.bottommain}
          alt="baseScreen"
          className="absolute bottom-0 z-10 object-contain w-full"
        />
        <div className="absolute top-[calc(0%-100px)] right-0 z-0">
          <Player
            autoplay
            speed={1.5}
            loop
            src={planet}
            style={{ height: "500px", padding: 0, width: "500px" }}
          >
            <Controls
              visible={false}
              buttons={[
                "play",
                "repeat",
                "frame",
                "debug",
                "snapshot",
                "background",
              ]}
            />
          </Player>
        </div>
      </div>
    </>
  );
};

export default Getstarted;
