import { medias } from "../../../../config/Resources";
/* eslint-disable jsx-a11y/img-redundant-alt */
function AgencySeatMatrix({ councelling, councellingChips }) {
	return (
		<div>
			<div className="ml-4 mr-4">
				<h6 className="mt-5 font-plusJakartaSans flex items-center text-center gap-[10px] font-bold text-[#000000] text-[15px]">
					<img
						className="w-7 h-8"
						src={medias.images.Exam.Register}
						alt="Privilege"
					/>
					<span className="font-plusJakartaSans font-bold mt-[5px]">
						Seat Matrix
					</span>
				</h6>
				<div>
					<div>
						{councelling?.counselling_agencies?.map((data, index) => {
							return data?.agency_name === councellingChips ? (
								<div
									key={index}
									dangerouslySetInnerHTML={{
										__html: data?.seat_matrix,
									}}
									className="font-plusJakartaSans font-medium text-[11.5px] text-[#000000] text-opacity-70 mt-[8px]"
								></div>
							) : (
								""
							);
						})}
					</div>
				</div>
			</div>
		</div>
	);
}

export default AgencySeatMatrix;
