import { useEffect, useState } from "react";
import axiosInstance from "../../config/Axios/axiosInstance";
import { API_URLS } from "../../config/API_URLS";
import { useParams } from "react-router-dom";
import ColegeDetailsHeadPage from "../../Components/College/CollegeDetailsView/CollegeDetailsHeadPage";
import SelecteCardsChips from "../../Components/College/CollegeDetailsView/SelectCardChips";
import OverView from "../../Components/College/CollegeDetailsView/OverView";
import CollegeExam from "../../Components/College/CollegeDetailsView/CollegeExam";
import PopularCourses from "../../Components/College/CollegeDetailsView/PopularCourses";
import ContactDetails from "../../Components/College/CollegeDetailsView/ContactDetails";
import StudentsRatingsAndReviewsCards from "../../Components/College/CollegeDetailsView/StudentsRatingsAndReviewsCards";
import WriteReviews from "../../Components/College/CollegeDetailsView/WriteReviews";
import Review from "../../Components/College/CollegeDetailsView/Review";
import SimilarCollegeCards from "../../Components/College/CollegeDetailsView/SimilarCollegeCards";
import Notifications from "../../Components/College/CollegeDetailsView/Notification";
import ReviewsStars from "../../Components/College/CollegeDetailsView/ReviewsStars";
import IframeVideos from "../../Components/College/CollegeDetailsView/IFrameVideos";
import AllPhotos from "../../Components/College/CollegeDetailsView/AllPhotos";
import NutritionAndDiet from "../../Components/College/CollegeDetailsView/NutritionAndDietMicroBiology";
import FacultyNumber from "../../Components/College/CollegeDetailsView/FacultyNumber";
import CollegeFacts from "../../Components/College/CollegeDetailsView/CollegeFacts";
import UniversityDetailsCards from "../../Components/College/CollegeDetailsView/UniversityDetailsCards";
import FeeAndEligibilityTable from "../../Components/College/CollegeDetailsView/FeeAndEligibilityTable";
import SearchBar from "../../Components/College/CollegeDetailsView/CoursesAndFees/Search";
import CoursesLevel from "../../Components/College/CollegeDetailsView/CoursesAndFees/CoursesLevel";
import CoursesLevelCards from "../../Components/College/CollegeDetailsView/CoursesAndFees/CoursesLevelCards";
import CourseFinder from "../../Components/College/CollegeDetailsView/CoursesAndFees/CourseFinder";
import AnswerDreamsAndCourses from "../../Components/College/CollegeDetailsView/CoursesAndFees/AnswerDreamsAndCoursesCards";
import QuestionAccordion from "../../Components/College/CollegeDetailsView/QuestionAccordion";
import StudentsRatings from "../../Components/College/CollegeDetailsView/CoursesAndFees/StudentsRatings";
import CoursesOfferedAccordion from "../../Components/College/CollegeDetailsView/Admissions/CoursesOfferedAccordion";
import EngineeringColleges from "../../Components/College/CollegeDetailsView/Admissions/EngineeringColleges";
import NoticeBoard from "../../Components/College/CollegeDetailsView/NoticeBoard/NoticeBoard";
import PlacementsStatistics from "../../Components/College/CollegeDetailsView/Placements/PlacementStatistics";
import TopRecruitingCompanies from "../../Components/College/CollegeDetailsView/Placements/TopRecruitingCompanies";
import PlacementSummary from "../../Components/College/CollegeDetailsView/Placements/PlacementSummary";
import moment from "moment";
import BranchWisePlacement from "../../Components/College/CollegeDetailsView/Placements/BranchWisePlacement";
import CourseWisePlacements from "../../Components/College/CollegeDetailsView/Placements/CourseWisePlacements";
import AlmuniNetwork from "../../Components/College/CollegeDetailsView/Placements/AlmuniNetwork";
import IndustryPlacement from "../../Components/College/CollegeDetailsView/Placements/IndustryPlacement";
import PlacementCriteria from "../../Components/College/CollegeDetailsView/Placements/PlacementCriteria";
import InternshipOffered from "../../Components/College/CollegeDetailsView/Placements/InternshipOffered";
import CampusImage from "../../Components/College/CollegeDetailsView/CampusLife/CampusImage";
import Accomodation from "../../Components/College/CollegeDetailsView/CampusLife/Accomodation";
import GeneralFacilities from "../../Components/College/CollegeDetailsView/CampusLife/GeneralFacilities";
import Events from "../../Components/College/CollegeDetailsView/CampusLife/Events";
import ClubTitles from "../../Components/College/CollegeDetailsView/CampusLife/ClubsTitle";
import Researches from "../../Components/College/CollegeDetailsView/CampusLife/Researches";
import SelectedDate from "../../Components/College/CollegeDetailsView/Placements/SelectedDate";
import CutoffTable from "../../Components/College/CollegeDetailsView/Cutoff/CutoffTable";
import CutOffReviews from "../../Components/College/CollegeDetailsView/Cutoff/CutOffReview";
import GalleryChips from "../../Components/College/CollegeDetailsView/Gallery/GalleryChips";
import GalleryPhotos from "../../Components/College/CollegeDetailsView/Gallery/GalleryPhotos";
import FacultyCards from "../../Components/College/CollegeDetailsView/Faculty/FacultyCards";
import CareerClusterRanking from "../../Components/College/CollegeDetailsView/Ranking/CareerClusterRanking";
import Agency from "../../Components/College/CollegeDetailsView/Ranking/Agency";
import RankingYear from "../../Components/College/CollegeDetailsView/Ranking/RankingYear";
import RankingSummary from "../../Components/College/CollegeDetailsView/Ranking/RankingSummary";
import RankingTable from "../../Components/College/CollegeDetailsView/Ranking/RankingTable";
import ScholarShipChip from "../../Components/College/CollegeDetailsView/ScholarShip/ScholarShipChip";
import ScholarShipName from "../../Components/College/CollegeDetailsView/ScholarShip/ScholarShipName";
import IntroductionCards from "../../Components/College/CollegeDetailsView/ScholarShip/IntroductionCards";
import ScholarShipCards from "../../Components/College/CollegeDetailsView/ScholarShip/ScholarShipCards";
import ScholarshipIntroduction from "../../Components/College/CollegeDetailsView/ScholarShip/ScholarIntroduction";
import ScholarShipContactDetails from "../../Components/College/CollegeDetailsView/ScholarShip/ScholarShipContactDetails";
import ScholarExamTable from "../../Components/College/CollegeDetailsView/ScholarShip/ScholarExamTable";
import ExamDetails from "../../Components/College/CollegeDetailsView/ScholarShip/ExamDetails";
import ApplicationProcedure from "../../Components/College/CollegeDetailsView/ScholarShip/ApplicationProcedure";
import SelectionProcedure from "../../Components/College/CollegeDetailsView/ScholarShip/SelectionProcedure";
import CourseDetials from "../../Components/College/CollegeDetailsView/CourseDetails/CourseDetails";
import CourseDetilasOverview from "../../Components/College/CollegeDetailsView/CourseDetails/CourseDetailsOverview";
import CourseSpecilization from "../../Components/College/CollegeDetailsView/CourseDetails/CourseSpecilization";
import CourseCards from "../../Components/College/CollegeDetailsView/CourseDetails/CourseCards";
import CoursesAndFeeDetailsOverview from "../../Components/College/CollegeDetailsView/CourseDetails/CoursesAndFeeDetailsOverview";
import CourseDetailsTable from "../../Components/College/CollegeDetailsView/CourseDetails/CourseDetailsTable";
import QuestionAccordionScholarShip from "../../Components/College/CollegeDetailsView/ScholarShip/QuestionAccordionScholarShip";

function CollegDetailsPage() {
	const [showTabs, setShowtabs] = useState(1);
	const [CollegeDetails, setCollegeDetails] = useState();
	const [courseDetails, setCourseDetails] = useState([]);
	const [admissions, setAdmissions] = useState();
	const [noticeBoard, setNoticeBoard] = useState();
	const [startDate, setStartDate] = useState(
		moment(new Date()).format("YYYY-MM-DD")
	);
	const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
	const [placement, setPlacement] = useState([]);
	const [campusLife, setCampusLife] = useState([]);
	const [galleryData, setGalleryData] = useState([]);
	const [cutOff, setCutOff] = useState([]);
	const [galleryChip, setGalleryChip] = useState();
	const [selectedCoursechip, setSelectedCoursechip] = useState();
	const [faculty, setFaculty] = useState([]);
	const [scholarChip, setScholarChip] = useState();
	const [scholarshipDetails, setScholarshipDetails] = useState([]);
	const [rankingData, setRankingData] = useState([]);
	const [clickedClusterId, setClickedClusterId] = useState();
	const [selectedId, setSelectedId] = useState();
	const [isCoursesDetails, setIsCoursesDetails] = useState(false);
	const [courseId, setCourseId] = useState("");
	const [studyLevel, setStudyLevel] = useState([]);
	const [course, setCourses] = useState("");

	const { id } = useParams();

	const api = {
		1: `${API_URLS.getIndianCollegeOverview}?college_id=${id}`,
		2: `${API_URLS.getIndianCollegeCourses}?college_id=${id}&study_level=${studyLevel}`,
		3: `${API_URLS.getIndianCollegeAdmissions}?college_id=${id}`,
		4: `${API_URLS.getIndianCollegeNoticeboard}?college_id=${id}&notice_date=${startDate}`,
		5: `${API_URLS.getIndianCollegeScholarshipDetails}?college_id=${id}&scholarship_id=${scholarChip}`,
		6: `${API_URLS.getIndianCollegePlacements}?college_id=${id}&year=${selectedYear}`,
		7: `${API_URLS.getIndianCollegeCutOff}?college_id=${id}&entrance_exam=${selectedCoursechip}&year=${selectedYear}`,
		8: `${API_URLS.getIndianCollegeCampusLife}?college_id=${id}`,
		10: `${API_URLS.getIndianCollegeFaculty}?college_id=${id}`,
		12: `${API_URLS.getIndianCollegeGallery}?college_id=${id}&type=${galleryChip}`,
		14: `${
			API_URLS.getIndianCollegeRankings
		}?college_id=${id}&year=${selectedYear}&career_cluster=${
			clickedClusterId || ""
		}&ranking_organisation=${selectedId || ""}`,
	};

	console.log(courseId, "courseId");

	const setDataMapping = {
		1: setCollegeDetails,
		2: setCourseDetails,
		3: setAdmissions,
		4: setNoticeBoard,
		5: setScholarshipDetails,
		6: setPlacement,
		7: setCutOff,
		8: setCampusLife,
		10: setFaculty,
		12: setGalleryData,
		14: setRankingData,
	};

	useEffect(() => {
		const fetchCollegeDetails = async () => {
			const url = api[showTabs];
			const res = await axiosInstance.get(url);
			if (res) {
				setDataMapping[showTabs](res?.result);
			}
		};

		fetchCollegeDetails();
	}, [
		id,
		showTabs,
		startDate,
		selectedYear,
		galleryChip,
		selectedCoursechip,
		scholarChip,
		clickedClusterId,
		selectedId,
		studyLevel,
	]);

	useEffect(() => {
		const fetchCourseAndFeeDetails = async () => {
			if (courseId) {
				// Check if courseId is defined
				const res = await axiosInstance.get(
					`${API_URLS.getIndianCollegeCourseDetails}?college_id=${id}&course_id=${courseId}`
				);
				if (res) {
					setCourses(res?.result);
				}
			}
		};

		fetchCourseAndFeeDetails();
	}, [courseId]);

	console.log(CollegeDetails, "CollegeDetails");

	return (
		<div className="bg-[#F3F6FF] h-[100%]">
			<div className="ml-auto mr-auto pt-10 min-w-[79%] max-w-[80%]">
				<ColegeDetailsHeadPage CollegeDetails={CollegeDetails} />
				<div className="flex w-[100%]">
					<div className="w-[12%] mt-5">
						<SelecteCardsChips
							setShowtabs={setShowtabs}
							setIsCoursesDetails={setIsCoursesDetails}
						/>
					</div>
					<div className="w-[58%] h-[100%] ml-10">
						{showTabs === 1 && (
							<>
								<div className="bg-white rounded-lg shadow-custom1 mt-5 pb-10">
									<OverView
										showTabs={showTabs}
										CollegeDetails={CollegeDetails}
									/>
									<CollegeFacts CollegeDetails={CollegeDetails} />
									<UniversityDetailsCards CollegeDetails={CollegeDetails} />
									{/* <FeeAndEligibilityTable /> */}
								</div>
								<CollegeExam />
								<div className="bg-white rounded-lg shadow-custom1 mt-5">
									<PopularCourses CollegeDetails={CollegeDetails} />
								</div>
							</>
						)}
						{showTabs === 2 &&
							(!isCoursesDetails ? (
								<>
									<div className="bg-white rounded-lg shadow-custom1 mt-5 pb-10">
										<OverView
											showTabs={showTabs}
											CollegeDetails={CollegeDetails}
										/>
										{/* <FeeAndEligibilityTable /> */}
										<div className="ml-4 mr-4">
											<SearchBar />
										</div>
										<CoursesLevel
											setStudyLevel={setStudyLevel}
											studyLevel={studyLevel}
										/>
										<CoursesLevelCards
											CourseDetails={courseDetails}
											setIsCoursesDetails={setIsCoursesDetails}
											setCourseId={setCourseId}
										/>
									</div>
									<CourseFinder />
									<AnswerDreamsAndCourses />
									<div className="bg-white rounded-lg shadow-custom1 mt-6 pb-5 pt-1 pl-4 pr-5">
										<QuestionAccordion
											courseDetails={courseDetails}
											showTabs={showTabs}
										/>
									</div>
								</>
							) : (
								<>
									<div className="bg-white rounded-lg shadow-custom1 mt-5 pb-10">
										<CourseDetilasOverview />
										<CourseDetials CourseDetails={courseDetails} />
										<CourseSpecilization course={course} />
										<CourseCards course={course} />
										<CoursesAndFeeDetailsOverview course={course} />
										<CourseDetailsTable course={course} />
									</div>
								</>
							))}
						{showTabs === 3 && (
							<div className="bg-white rounded-lg shadow-custom1 mt-5 pb-10">
								<OverView
									showTabs={showTabs}
									CollegeDetails={CollegeDetails}
									admissions={admissions}
								/>
								<CoursesOfferedAccordion admissions={admissions} />
							</div>
						)}
						{showTabs === 4 && (
							<>
								<div className="bg-white rounded-lg shadow-custom1 mt-5 w-[100%] pb-10">
									<OverView
										showTabs={showTabs}
										CollegeDetails={CollegeDetails}
										admissions={admissions}
									/>
									<NoticeBoard
										CollegeDetails={CollegeDetails}
										setNoticeBoard={setNoticeBoard}
										noticeBoard={noticeBoard}
										setStartDate={setStartDate}
										startDate={startDate}
									/>
								</div>
								<CollegeExam />
							</>
						)}
						{showTabs === 5 && (
							<>
								<div className="bg-white rounded-lg shadow-custom1 mt-5 w-[100%] pb-10">
									<OverView
										showTabs={showTabs}
										CollegeDetails={CollegeDetails}
										admissions={admissions}
									/>
									<ScholarShipChip
										id={id}
										setScholarChip={setScholarChip}
										showTabs={showTabs}
									/>
									<ScholarShipName scholarshipDetails={scholarshipDetails} />
									<ScholarShipCards scholarshipDetails={scholarshipDetails} />
									<ScholarshipIntroduction
										scholarshipDetails={scholarshipDetails}
									/>
									<IntroductionCards scholarshipDetails={scholarshipDetails} />
									<ScholarShipContactDetails
										scholarshipDetails={scholarshipDetails}
									/>
									{/* <ScholarExamTable scholarshipDetails={scholarshipDetails} /> */}
									<ExamDetails scholarshipDetails={scholarshipDetails} />
									<ApplicationProcedure
										scholarshipDetails={scholarshipDetails}
									/>
									<SelectionProcedure scholarshipDetails={scholarshipDetails} />
									<div className="ml-4 mr-4">
										<QuestionAccordionScholarShip
											scholarshipDetails={scholarshipDetails}
										/>
									</div>
								</div>
								<CollegeExam />
							</>
						)}
						{showTabs === 6 && (
							<>
								<div className="bg-white rounded-lg shadow-custom1 mt-5 w-[100%] pb-10">
									<OverView
										showTabs={showTabs}
										CollegeDetails={CollegeDetails}
										admissions={admissions}
									/>
									<SelectedDate
										setSelectedYear={setSelectedYear}
										selectedYear={selectedYear}
									/>
									<PlacementsStatistics placement={placement} />
									<TopRecruitingCompanies placement={placement} />
									<PlacementSummary placement={placement} />
									<BranchWisePlacement placement={placement} />
									<CourseWisePlacements placement={placement} />
									<IndustryPlacement placement={placement} />
									<AlmuniNetwork placement={placement} />
									<PlacementCriteria placement={placement} />
									<InternshipOffered placement={placement} />
								</div>
								<CollegeExam />
							</>
						)}
						{showTabs === 7 && (
							<>
								<div className="bg-white rounded-lg shadow-custom1 mt-5 w-[100%] pb-10">
									<OverView showTabs={showTabs} />
									<CutoffTable
										id={id}
										cutOff={cutOff}
										setSelectedCoursechip={setSelectedCoursechip}
										setSelectedYear={setSelectedYear}
										selectedYear={selectedYear}
									/>
								</div>
								<CollegeExam />
								<div className="bg-white rounded-lg shadow-custom1 mt-5 w-[100%] pb-10">
									<CutOffReviews />
									<div className="ml-4 mr-4">
										<QuestionAccordion showTabs={showTabs} cutOff={cutOff} />
									</div>
								</div>
							</>
						)}
						{showTabs === 8 && (
							<>
								<div className="bg-white rounded-lg shadow-custom1 mt-5 w-[100%] pb-10">
									<OverView showTabs={showTabs} campusLife={campusLife} />
									<CampusImage campusLife={campusLife} />
									<Accomodation campusLife={campusLife} />
									<GeneralFacilities campusLife={campusLife} />
									<Events campusLife={campusLife} />
									<ClubTitles campusLife={campusLife} />
									<Researches campusLife={campusLife} />
								</div>
								<CollegeExam />
							</>
						)}
						{showTabs === 10 && (
							<>
								<div className="bg-white rounded-lg shadow-custom1 mt-5 w-[100%] pb-10">
									<OverView showTabs={showTabs} />
									<FacultyCards faculty={faculty} />
								</div>
								<CollegeExam />
							</>
						)}
						{showTabs === 12 && (
							<>
								<div className="bg-white rounded-lg shadow-custom1 mt-5 w-[100%] pb-10">
									<OverView showTabs={showTabs} />
									<GalleryChips setGalleryChip={setGalleryChip} />
									<GalleryPhotos
										galleryData={galleryData}
										galleryChip={galleryChip}
									/>
								</div>
								<CollegeExam />
								<div className="bg-white rounded-lg shadow-custom1 mt-5 w-[100%] pb-10">
									<CutOffReviews />
									<div className="ml-4 mr-4">
										<QuestionAccordion />
									</div>
								</div>
							</>
						)}
						{showTabs === 13 && (
							<>
								<div className="bg-white rounded-lg shadow-custom1 mt-5 w-[100%] pb-10">
									<OverView showTabs={showTabs} />
								</div>
							</>
						)}
						{showTabs === 14 && (
							<>
								<div className="bg-white rounded-lg shadow-custom1 mt-5 w-[100%] pb-10">
									<OverView showTabs={showTabs} />
									<CareerClusterRanking
										setClickedClusterId={setClickedClusterId}
										clickedClusterId={clickedClusterId}
									/>
									<Agency
										setSelectedId={setSelectedId}
										selectedId={selectedId}
									/>
									<RankingYear
										selectedYear={selectedYear}
										setSelectedYear={setSelectedYear}
									/>
									<RankingSummary rankingData={rankingData} />
									<RankingTable rankingData={rankingData} />
								</div>
								<CollegeExam />
								<div className="bg-white rounded-lg shadow-custom1 mt-5 w-[100%] pb-10">
									<CutOffReviews />
									<div className="ml-4 mr-4">
										<QuestionAccordion
											rankingData={rankingData}
											showTabs={showTabs}
										/>
									</div>
								</div>
							</>
						)}
					</div>
					<div className="w-[25%] mt-5 ml-5">
						<Notifications />
						<div className="mt-12">
							<ReviewsStars />
						</div>
						<div className="mt-5">
							<IframeVideos />
						</div>
						<div className="mt-5">
							<AllPhotos />
						</div>
						{showTabs !== 4 && (
							<>
								<div className="mt-8">
									<NutritionAndDiet />
								</div>
								{showTabs !== 10 && (
									<div className="mt-7">
										<FacultyNumber />
									</div>
								)}
							</>
						)}
					</div>
				</div>
				{showTabs === 1 && (
					<>
						<div className="pb-5">
							<ContactDetails />
						</div>
						<div className="pb-5">
							<StudentsRatingsAndReviewsCards />
						</div>
						<div className="pb-5">
							<WriteReviews />
						</div>
						<div className="pb-5">
							<Review />
						</div>
					</>
				)}
				{showTabs === 2 && (
					<>
						<div className="pb-5">
							<ContactDetails />
						</div>
						<StudentsRatings />
					</>
				)}
				{showTabs === 2 && (
					<>
						<div className="pb-5">
							<ContactDetails />
						</div>
						<StudentsRatings />
					</>
				)}
				{showTabs === 10 && (
					<>
						<div className="pb-5">
							<ContactDetails />
						</div>
					</>
				)}
			</div>
			{showTabs === 3 && (
				<div className="pb-14 pt-5 pl-12 pr-16">
					<EngineeringColleges />
				</div>
			)}
			<div className="pb-14 pt-20 pl-12 pr-15">
				<SimilarCollegeCards />
			</div>
		</div>
	);
}

export default CollegDetailsPage;
