import { mediaResources } from "../Constants";
import { medias } from "../Resources";

export const guidancePageContant = {
  Face1: {
    confusedAboutYourCareer: (
      <>
        Confused About Your{" "}
        <span className="relative PlusJakartaSans-ExtraBoldCustom ">
          Career?
          <div className="h-[31px] w-full bg-[#F0BC5E] absolute left-0 bottom-0 -z-10" />
        </span>
      </>
    ),
    indianInstituteOfTechnology:
      "Indian Institute of Technology, Delhi or IIT Delhi is an Institute of Eminence offering about 102 undergraduate, postgraduate, integrated and doctoral degree courses. Indian Institute of Technology, Delhi",
  },
  Face2: {
    howCareerGuidance: (
      <>
        <span className="relative PlusJakartaSans-ExtraBoldCustom">
          How Career guidance
          <div className="h-[31px]  w-3/4 bg-[#F0BC5E] absolute left-[13%] bottom-0 -z-10" />
        </span>{" "}
        can help you?
      </>
    ),
    careerGuidance: [
      {
        title: "Career Path Selection",
        image: medias.images.guidance.Face2.CareerGuide1,
        className: "bg-[#FFC7F8]",
        desc: "Indian Institute of Technology, Delhi or IIT Delhi is an Institute of Eminence offering about 102 undergraduate, postgraduate, integrated and doctoral degree courses. Indian Institute of Technology, Delhi courses include B.Tech, M.Tech, M.Sc and Ph.D in various streams of Engineering, Physical Sciences",
      },
      {
        title: "Road map to career",
        image: medias.images.guidance.Face2.CareerGuide2,
        className: "bg-[#B0CEFF]",
        desc: "Indian Institute of Technology, Delhi or IIT Delhi is an Institute of Eminence offering about 102 undergraduate, postgraduate, integrated and doctoral degree courses. Indian Institute of Technology, Delhi courses include B.Tech, M.Tech, M.Sc and Ph.D in various streams of Engineering, Physical Sciences",
      },
      {
        title: "Profile Building",
        image: medias.images.guidance.Face2.CareerGuide3,
        className: "bg-[#FFEAB0]",
        desc: "Indian Institute of Technology, Delhi or IIT Delhi is an Institute of Eminence offering about 102 undergraduate, postgraduate, integrated and doctoral degree courses. Indian Institute of Technology, Delhi courses include B.Tech, M.Tech, M.Sc and Ph.D in various streams of Engineering, Physical Sciences",
      },
      {
        title: "Course Selection",
        image: medias.images.guidance.Face2.CareerGuide4,
        className: "bg-[#FFDDB0]",
        desc: "Indian Institute of Technology, Delhi or IIT Delhi is an Institute of Eminence offering about 102 undergraduate, postgraduate, integrated and doctoral degree courses. Indian Institute of Technology, Delhi courses include B.Tech, M.Tech, M.Sc and Ph.D in various streams of Engineering, Physical Sciences",
      },
      {
        title: "Industry Experts",
        image: medias.images.guidance.Face2.CareerGuide5,
        className: "bg-[#C8B0FF]",
        desc: "Indian Institute of Technology, Delhi or IIT Delhi is an Institute of Eminence offering about 102 undergraduate, postgraduate, integrated and doctoral degree courses. Indian Institute of Technology, Delhi courses include B.Tech, M.Tech, M.Sc and Ph.D in various streams of Engineering, Physical Sciences",
      },
      {
        title: "Admissions Support",
        image: medias.images.guidance.Face2.CareerGuide1,
        className: "bg-[#B0F4FF]",
        desc: "Indian Institute of Technology, Delhi or IIT Delhi is an Institute of Eminence offering about 102 undergraduate, postgraduate, integrated and doctoral degree courses. Indian Institute of Technology, Delhi courses include B.Tech, M.Tech, M.Sc and Ph.D in various streams of Engineering, Physical Sciences",
      },
    ],
  },
  Face3: {
    howCareerGuidance: (
      <>
        How Career guidance <br />
        <span className="relative PlusJakartaSans-ExtraBoldCustom z-10">
          can help you?
          <div className="h-[31px] w-[90%] bg-[#F0BC5E] absolute left-0 bottom-0 -z-10 " />
        </span>
      </>
    ),
    whatCounselling: "What counselling from an expert can help your achieve!",
    indianInstitute:
      "Indian Institute of Technology, Delhi or IIT Delhi is an Institute of Eminence offering about 102 undergraduate, postgraduate, integrated and doctoral degree courses. Indian Institute of Technology, Delhi courses include B.Tech, M.Tech, M.Sc and Ph.D in various streams of Engineering, Physical Sciences, Management, Humanities, and Social Sciences. Students",
    establishin:
      "Established in 1961, IIT Delhi is one of the 23 IITs created to be Centres of Excellence for training, research and development in science, engineering and technology in India. Being an Institution of National Importance under the 'Institutes of Technology",
    weHelpYour: " We help your explore new age careers through",
    scientificProvenMethod: "scientific proven method",
    chatWithCounsellerNow: " Chat with Counseller Now",
    thisEasyToFollow:
      "This easy to follow 6 steps proves ensures to build your career selection and evaluate any career confustion along the way.",
    steps: [
      {
        title: "Psychometric Analysis",
        description:
          "Indian Institute of Technology, Delhi or IIT Delhi is an Institute of Eminence offering about 102 undergraduate, postgraduate, integrated and doctoral degree courses. Indian Institute of Technology, Delhi courses include B.Tech, M.Tech, M.Sc and Ph.D in various streams of Engineering, Physical Sciences, Management, Humanities, and Social Sciences. Students seeking admission for B.Tech in any stream must appear for JEE Advanced and JoSaa counselling. Students completing their degrees are offered IIT Delhi placements to prestigious",
      },
      {
        title: "Career Assessment Report",
        description:
          "Indian Institute of Technology, Delhi or IIT Delhi is an Institute of Eminence offering about 102 undergraduate, postgraduate, integrated and doctoral degree courses. Indian Institute of Technology, Delhi courses include B.Tech, M.Tech, M.Sc and Ph.D in various streams of Engineering, Physical Sciences, Management, Humanities, and Social Sciences. Students seeking admission for B.Tech in any stream must appear for JEE Advanced and JoSaa counselling. Students completing their degrees are offered IIT Delhi placements to prestigious",
      },
      {
        title: "Career Assessment Report",
        description:
          "Indian Institute of Technology, Delhi or IIT Delhi is an Institute of Eminence offering about 102 undergraduate, postgraduate, integrated and doctoral degree courses. Indian Institute of Technology, Delhi courses include B.Tech, M.Tech, M.Sc and Ph.D in various streams of Engineering, Physical Sciences, Management, Humanities, and Social Sciences. Students seeking admission for B.Tech in any stream must appear for JEE Advanced and JoSaa counselling. Students completing their degrees are offered IIT Delhi placements to prestigious",
      },
      {
        title: "Career Assessment Report",
        description:
          "Indian Institute of Technology, Delhi or IIT Delhi is an Institute of Eminence offering about 102 undergraduate, postgraduate, integrated and doctoral degree courses. Indian Institute of Technology, Delhi courses include B.Tech, M.Tech, M.Sc and Ph.D in various streams of Engineering, Physical Sciences, Management, Humanities, and Social Sciences. Students seeking admission for B.Tech in any stream must appear for JEE Advanced and JoSaa counselling. Students completing their degrees are offered IIT Delhi placements to prestigious",
      },
      {
        title: "Career Assessment Report",
        description:
          "Indian Institute of Technology, Delhi or IIT Delhi is an Institute of Eminence offering about 102 undergraduate, postgraduate, integrated and doctoral degree courses. Indian Institute of Technology, Delhi courses include B.Tech, M.Tech, M.Sc and Ph.D in various streams of Engineering, Physical Sciences, Management, Humanities, and Social Sciences. Students seeking admission for B.Tech in any stream must appear for JEE Advanced and JoSaa counselling. Students completing their degrees are offered IIT Delhi placements to prestigious",
      },
    ],
  },
  Face4: {
    indiaMostScientific: (
      <>
        India's Most Scientific & Comprehensive <br />{" "}
        <span className="relative">
          Career Development Mechanism
          <div className=" h-[22px]  w-[100%] bg-[#F0BC5E] absolute  bottom-0 -z-10" />
        </span>
      </>
    ),
    description: `Indian Institute of Technology, Delhi or IIT Delhi is an Institute of Eminence offering about 102 undergraduate, postgraduate, integrated and doctoral degree courses. Indian Institute of Technology, Delhi courses include B.Tech, M.Tech, M.Sc and Ph.D in various streams of Engineering, Physical Sciences, Management, Humanities, and Social Sciences. Students seeking admission for B.Tech in any stream must appear for JEE Advanced and JoSaa counselling. Students completing their degrees are`,
    getStrted: "Get Started",
  },
  Face5: {
    designRoadMap: (
      <>
        {" "}
        <span className="relative z-10 PlusJakartaSans-ExtraBoldCustom ">
          Our Partner Institution
          <div className="h-[19px]  w-[104%] bg-[#F0BC5E] absolute left-[-2%] bottom-0 -z-10" />
        </span>
      </>
    ),
    tabs: [
      {
        Title: "High School",
        description: "9-10th",
      },
      {
        Title: "High School",
        description: "11-12th",
      },
      {
        Title: "Graduates",
        description: "& Working Proffesiianl",
      },
    ],
    plans: [
      {
        border: " border-[#637CED] ",
        Title: "Basic",
        color: "#637CED",
        background: "bg-[#637CED]",
        image: medias.images.guidance.Face5.Plan1,
        subTitle: "All the basics for buisness that are just getting started",
        amount: "12",
        perks: [
          "Single project use",
          "Basic dashboard",
          "All components included",
        ],
        priceColor: "text-[#637CED]",
      },
      {
        border: "  border-[#ED62A5] ",
        Title: "Economic",
        background: "bg-[#EC539C]",
        color: "#EC539C",
        image: medias.images.guidance.Face5.Plan2,
        subTitle: "Better for growing buisness that want more customer",
        amount: "84",
        perks: [
          "Unlimited project use",
          "Advance dashbaord",
          "All components included",
          "Advance insight",
        ],
        priceColor: "text-[#ED62A5] ",
      },
      {
        border: " border-[#5BC587] ",
        Title: "Advance",
        background: "bg-[#5BC587]",
        color: "#5BC587",
        image: medias.images.guidance.Face5.Plan3,
        subTitle: "Advance feature for pros who need more custominzation",
        amount: "316",
        perks: [
          "Unlimited project use",
          "Advance dashbaord",
          "All components included",
          "Phone Support",
        ],
        priceColor: "text-[#5BC587]",
      },
    ],
  },
  Face6: {
    meetYourMentors: (
      <>
        {" "}
        <span className="relative">
          Meet your Mentors
          <div className="h-[22px]  w-[105%] bg-[#F0BC5E] absolute left-[1%] bottom-0 -z-10" />
        </span>
      </>
    ),
    describe: `Indian Institute of Technology, Delhi or IIT Delhi is an Institute of Eminence offering about 102 undergraduate, postgraduate, integrated and doctoral degree courses. Indian Institute of Technology, Delhi courses include B.Tech, M.Tech, M.Sc and Ph.D in various streams of Engineering, Physical Sciences, Management, Humanities, and Social Sciences. Students seeking admission for B.Tech in any stream must appear for JEE Advanced and JoSaa counselling`,
    mentors: [
      {
        image: medias.images.guidance.Face6.MetaMentor,
        logoImage: medias.images.guidance.Face6.metalogo,
        name: "Mena Thomas",
        occupation: "Software Engineer",
      },
      {
        image: medias.images.guidance.Face6.NetFlixMentor,
        logoImage: medias.images.guidance.Face6.netflixlogo,
        name: "Steve Smith",
        occupation: "Technical Head",
      },
    ],
  },
  Face7: {
    tabs: [
      {
        Title: "250+ partner schools",
      },
      {
        Title: "120+ Partner Universities",
      },
    ],
    Images: [
      { src: medias.images.guidance.Face7.Person1, classes: "row-start-2" },
      { src: medias.images.guidance.Face7.Person2, classes: "" },
      { src: medias.images.guidance.Face7.Person3, classes: "" },
      { src: medias.images.guidance.Face7.Person4, classes: "" },
      { src: medias.images.guidance.Face7.Person5, classes: "" },
      { src: medias.images.guidance.Face7.Person6, classes: "" },
      { src: medias.images.guidance.Face7.Person7, classes: "row-start-2" },
      { src: medias.images.guidance.Face7.Person8, classes: "" },
      { src: medias.images.guidance.Face7.Person9, classes: "" },
      { src: medias.images.guidance.Face7.Person10, classes: "" },
    ],
  },
  Face8: {
    realStoriesOfRealPeople: (
      <>
        {" "}
        <span className="relative PlusJakartaSans-ExtraBoldCustom tracking-wide text-[53px]">
          Real Stories of real people
          <div className="h-[18px]  w-[calc(100%+30px)]  bg-[#F0BC5E] absolute left-[calc(0%-15px)] -bottom-[0px] -z-10" />
        </span>
      </>
    ),
  },
  Face10: {
    Perks: [
      {
        img: medias.images.guidance.Face10.Perk3,
        desc: "Assessment through scientifically proven psychometric analysis",
        back: "bg-[#E2FFFA]",
      },
      {
        img: medias.images.guidance.Face10.Perk1,
        desc: 'Holistic development through courses in "Multiple Intelligences"',
        back: "bg-[#FFF6E6]",
      },
      {
        img: medias.images.guidance.Face10.Perk5,
        desc: "Extensive database on colleges, career, exams, scholarships and courses",
        back: "bg-[#FFE7FD]",
      },
      {
        img: medias.images.guidance.Face10.Perk6,
        desc: "Prepares students for skills of the future",
        back: "bg-[#E1E7FF]",
      },
      {
        img: medias.images.guidance.Face10.Perk4,
        desc: "Guidance towards selecting appropriate career options",
        back: "bg-[#FFD9B6]",
      },
      {
        img: medias.images.guidance.Face10.Perk2,
        desc: "Mentoring by counsellors and industry experts",
        back: "bg-[#E2FDFF]",
      },
    ],
  },
  Face11: {
    footerInfo: [
      {
        Title: "Top Colleges",
        pointes: [
          "About us",
          "Top Colleges in India",
          "Top Engineering Colleges in India",
          "Top MBA Colleges in India",
          "Top MBA Colleges in India",
          "Top Law Colleges in India",
          "Top Colleges in Chennai",
          "Top Medical Colleges in India",
          "LPU",
          "UPES",
        ],
      },
      {
        Title: "Top Exam",
        pointes: [
          "NEET UG",
          "NEET PG",
          "NEET SS",
          "NEET MDS",
          "INI CET",
          "FMGE AIAPGET",
          "Top Medical Colleges",
          "Medical Colleges",
          "Medical Exams",
        ],
      },
      {
        Title: "Study Abroad",
        pointes: [
          "Animation",
          "B.Com",
          "B.Sc",
          "BBA",
          "CA",
          "Fashion Designing",
          "Hotel Management",
          "Law",
          "Mass Communication & Media",
          "MBBS",
        ],
      },
      {
        Title: "Career Counsellor",
        pointes: [
          "Free Online courses",
          "Free Government courses",
          "Data Science Courses",
          "Artificial Intelligence Courses",
          "Machine Learning Courses",
          "Cloud Computing Courses",
          "AWS Certification Python Courses",
          "Cybersecurity Courses",
          "Digital Marketing Courses",
        ],
      },
      {
        Title: "Exams",
        pointes: [
          "RRB Group D",
          "RRB NTPC",
          "CTET",
          "UPTET",
          "UGC",
          "NET",
          "DSSSB",
          "SSC CGL",
          "SSC CHSL",
          "SSC GD",
          "NDA",
        ],
      },
      {
        Title: "Reviews",
        pointes: [
          "MAT 2023",
          "SNAP 2023",
          "IIT JAM 2023",
          "LPUNEST 2023",
          "MET 2023",
          "CUET 2023",
          "SET 2023",
          "JEE Advanced 2023",
        ],
      },
      {
        Title: "Important Updates",
        pointes: [
          "JoSAA 2023 Round 6",
          "NEET PG 2023 Counselling",
          "NExT Exam 2024",
          "CAT Syllabus 2023",
          "NEET UG Counselling 2023",
          "SSC CHSL 2023 Admit Card",
          "CUET UG 2023",
          "CUET result 2023",
          "CUET 2023",
          "CUET PG Result 2023",
        ],
      },
      {
        Title: "Mentors",
        pointes: [
          "Study Abroad Home",
          "BTech abroad",
          "MBA abroad",
          "MS abroad",
          "GRE",
          "GMAT",
          "SAT",
          "IELTS",
          "TOEFL",
        ],
      },
      {
        Title: "Predictors & Ebooks",
        pointes: [
          "JEE Main College Predictor",
          "JEE Main Rank Predictor",
          "JEE Advanced College Predictor",
          "NEET College Predictor",
          "GATE College Predictor with PSU",
          "CAT College Predictor",
          "CLAT College Predictor",
          "CAT Percentile Predictor",
          "E-books & Sample Paper",
        ],
      },
      {
        Title: "Top Colleges",
        pointes: [
          "JEE Main College Predictor",
          "JEE Main Rank Predictor",
          "JEE Advanced College Predictor",
          "NEET College Predictor",
          "GATE College Predictor with PSU",
          "CAT College Predictor",
          "CLAT College Predictor",
          "CAT Percentile Predictor",
          "E-books & Sample Paper",
        ],
      },
    ],
    lastData: [
      {
        title: "About Unigoal",
      },
      {
        title: "Contact us",
      },
      {
        title: "Careers",
      },
      {
        title: "Sitemap",
      },
      {
        title: "Terms & Conditions",
      },
      {
        title: "Privacy Policy",
      },
    ],
    copyRight: "Copyright © 2024 Sygnific Careers Pvt Ltd",
  },
};

export const elevenPageContant = {
  Face1: {
    confusedAboutYourCareer: (
      <>
        Why Unigoal <br />
        <span className="relative  PlusJakartaSans-ExtraBoldCustom text-[47px]">
          For Career Counselling?
          <div className="h-[31px] w-full  absolute left-0 bottom-0 -z-10" />
        </span>
      </>
    ),
    indianInstituteOfTechnology:
      "Indian Institute of Technology, Delhi or IIT Delhi is an Institute of Eminence offering about 102 undergraduate, postgraduate, integrated and doctoral degree courses. Indian Institute of Technology, Delhi",
  },
  Face2: {
    howCareerGuidance: (
      <>
        <span className="relative">
          What you will get
          <div className="h-[31px]  w-full bg-[#F0BC5E] absolute left-[0.5%] bottom-0 -z-10" />
        </span>{" "}
      </>
    ),
    careerGuidance: [
      {
        title: "Transitions Phase",
        image: medias.images.graduate.Face2.CareerGuide1,
        className: "bg-[#FFEAB0]",
        desc: "Indian Institute of Technology, Delhi or IIT Delhi is an Institute of Eminence offering about 102 undergraduate, postgraduate, integrated and doctoral degree courses. Indian Institute of Technology, Delhi courses include B.Tech, M.Tech, M.Sc and Ph.D in various streams of Engineering, Physical Sciences",
      },
      {
        title: "Job Search",
        image: medias.images.graduate.Face2.CareerGuide2,
        className: "bg-[#C7D9FF]",
        desc: "Indian Institute of Technology, Delhi or IIT Delhi is an Institute of Eminence offering about 102 undergraduate, postgraduate, integrated and doctoral degree courses. Indian Institute of Technology, Delhi courses include B.Tech, M.Tech, M.Sc and Ph.D in various streams of Engineering, Physical Sciences",
      },
      {
        title: "Study Aboard",
        image: medias.images.graduate.Face2.CareerGuide3,
        className: "bg-[#ADFFB8] ",
        desc: "Indian Institute of Technology, Delhi or IIT Delhi is an Institute of Eminence offering about 102 undergraduate, postgraduate, integrated and doctoral degree courses. Indian Institute of Technology, Delhi courses include B.Tech, M.Tech, M.Sc and Ph.D in various streams of Engineering, Physical Sciences",
      },
      {
        title: "Guided Explorations",
        image: medias.images.graduate.Face2.CareerGuide4,
        className: "bg-[#FADBFF]",
        desc: "Indian Institute of Technology, Delhi or IIT Delhi is an Institute of Eminence offering about 102 undergraduate, postgraduate, integrated and doctoral degree courses. Indian Institute of Technology, Delhi courses include B.Tech, M.Tech, M.Sc and Ph.D in various streams of Engineering, Physical Sciences",
      },
      {
        title: "Career Assesment",
        image: medias.images.graduate.Face2.CareerGuide5,
        className: "bg-[#FFD1D1]",
        desc: "Indian Institute of Technology, Delhi or IIT Delhi is an Institute of Eminence offering about 102 undergraduate, postgraduate, integrated and doctoral degree courses. Indian Institute of Technology, Delhi courses include B.Tech, M.Tech, M.Sc and Ph.D in various streams of Engineering, Physical Sciences",
      },
    ],
  },
  Face3: {
    howCareerGuidance: (
      <>
        How Career guidance{" "}
        <span className="relative">
          can help you?
          <div className="h-[31px] w-full bg-[#F0BC5E] absolute left-0 bottom-0 -z-10" />
        </span>
      </>
    ),
    whatCounselling: "What counselling from an expert can help your achieve!",
    indianInstitute:
      "Indian Institute of Technology, Delhi or IIT Delhi is an Institute of Eminence offering about 102 undergraduate, postgraduate, integrated and doctoral degree courses. Indian Institute of Technology, Delhi courses include B.Tech, M.Tech, M.Sc and Ph.D in various streams of Engineering, Physical Sciences, Management, Humanities, and Social Sciences. Students",
    establishin:
      "Established in 1961, IIT Delhi is one of the 23 IITs created to be Centres of Excellence for training, research and development in science, engineering and technology in India. Being an Institution of National Importance under the 'Institutes of Technology",
    weHelpYour: " We help your explore new age careers through",
    scientificProvenMethod: "scientific proven method",
    chatWithCounsellerNow: " Chat with Counseller Now",
    thisEasyToFollow:
      "This easy to follow 6 steps proves ensures to build your career selection and evaluate any career confustion along the way.",
    steps: [
      {
        title: "Psychometric Analysis",
        description:
          "Indian Institute of Technology, Delhi or IIT Delhi is an Institute of Eminence offering about 102 undergraduate, postgraduate, integrated and doctoral degree courses. Indian Institute of Technology, Delhi courses include B.Tech, M.Tech, M.Sc and Ph.D in various streams of Engineering, Physical Sciences, Management, Humanities, and Social Sciences. Students seeking admission for B.Tech in any stream must appear for JEE Advanced and JoSaa counselling. Students completing their degrees are offered IIT Delhi placements to prestigious",
      },
      {
        title: "Career Assessment Report",
        description:
          "Indian Institute of Technology, Delhi or IIT Delhi is an Institute of Eminence offering about 102 undergraduate, postgraduate, integrated and doctoral degree courses. Indian Institute of Technology, Delhi courses include B.Tech, M.Tech, M.Sc and Ph.D in various streams of Engineering, Physical Sciences, Management, Humanities, and Social Sciences. Students seeking admission for B.Tech in any stream must appear for JEE Advanced and JoSaa counselling. Students completing their degrees are offered IIT Delhi placements to prestigious",
      },
      {
        title: "Career Assessment Report",
        description:
          "Indian Institute of Technology, Delhi or IIT Delhi is an Institute of Eminence offering about 102 undergraduate, postgraduate, integrated and doctoral degree courses. Indian Institute of Technology, Delhi courses include B.Tech, M.Tech, M.Sc and Ph.D in various streams of Engineering, Physical Sciences, Management, Humanities, and Social Sciences. Students seeking admission for B.Tech in any stream must appear for JEE Advanced and JoSaa counselling. Students completing their degrees are offered IIT Delhi placements to prestigious",
      },
      {
        title: "Career Assessment Report",
        description:
          "Indian Institute of Technology, Delhi or IIT Delhi is an Institute of Eminence offering about 102 undergraduate, postgraduate, integrated and doctoral degree courses. Indian Institute of Technology, Delhi courses include B.Tech, M.Tech, M.Sc and Ph.D in various streams of Engineering, Physical Sciences, Management, Humanities, and Social Sciences. Students seeking admission for B.Tech in any stream must appear for JEE Advanced and JoSaa counselling. Students completing their degrees are offered IIT Delhi placements to prestigious",
      },
      {
        title: "Career Assessment Report",
        description:
          "Indian Institute of Technology, Delhi or IIT Delhi is an Institute of Eminence offering about 102 undergraduate, postgraduate, integrated and doctoral degree courses. Indian Institute of Technology, Delhi courses include B.Tech, M.Tech, M.Sc and Ph.D in various streams of Engineering, Physical Sciences, Management, Humanities, and Social Sciences. Students seeking admission for B.Tech in any stream must appear for JEE Advanced and JoSaa counselling. Students completing their degrees are offered IIT Delhi placements to prestigious",
      },
    ],
  },
};

export const graduatePageContant = {
  Face1: {
    confusedAboutYourCareer: (
      <>
        Why go for career guidance <br />
        <span className="relative  PlusJakartaSans-ExtraBoldCustom">
          in graduation?
          <div className="h-[31px] w-full  absolute left-0 bottom-0 -z-10" />
        </span>
      </>
    ),
    indianInstituteOfTechnology:
      "Indian Institute of Technology, Delhi or IIT Delhi is an Institute of Eminence offering about 102 undergraduate, postgraduate, integrated and doctoral degree courses. Indian Institute of Technology, Delhi",
  },
  Face2: {
    howCareerGuidance: (
      <>
        <span className="relative">
          What you will get
          <div className="h-[31px]  w-full bg-[#F0BC5E] absolute left-[0.5%] bottom-0 -z-10" />
        </span>{" "}
      </>
    ),
    careerGuidance: [
      {
        title: "Transitions Phase",
        image: medias.images.graduate.Face2.CareerGuide1,
        className: "bg-[#FFEAB0]",
        desc: "Indian Institute of Technology, Delhi or IIT Delhi is an Institute of Eminence offering about 102 undergraduate, postgraduate, integrated and doctoral degree courses. Indian Institute of Technology, Delhi courses include B.Tech, M.Tech, M.Sc and Ph.D in various streams of Engineering, Physical Sciences",
      },
      {
        title: "Job Search",
        image: medias.images.graduate.Face2.CareerGuide2,
        className: "bg-[#C7D9FF]",
        desc: "Indian Institute of Technology, Delhi or IIT Delhi is an Institute of Eminence offering about 102 undergraduate, postgraduate, integrated and doctoral degree courses. Indian Institute of Technology, Delhi courses include B.Tech, M.Tech, M.Sc and Ph.D in various streams of Engineering, Physical Sciences",
      },
      {
        title: "Study Aboard",
        image: medias.images.graduate.Face2.CareerGuide3,
        className: "bg-[#ADFFB8] ",
        desc: "Indian Institute of Technology, Delhi or IIT Delhi is an Institute of Eminence offering about 102 undergraduate, postgraduate, integrated and doctoral degree courses. Indian Institute of Technology, Delhi courses include B.Tech, M.Tech, M.Sc and Ph.D in various streams of Engineering, Physical Sciences",
      },
      {
        title: "Guided Explorations",
        image: medias.images.graduate.Face2.CareerGuide4,
        className: "bg-[#FADBFF]",
        desc: "Indian Institute of Technology, Delhi or IIT Delhi is an Institute of Eminence offering about 102 undergraduate, postgraduate, integrated and doctoral degree courses. Indian Institute of Technology, Delhi courses include B.Tech, M.Tech, M.Sc and Ph.D in various streams of Engineering, Physical Sciences",
      },
      {
        title: "Career Assesment",
        image: medias.images.graduate.Face2.CareerGuide5,
        className: "bg-[#FFD1D1]",
        desc: "Indian Institute of Technology, Delhi or IIT Delhi is an Institute of Eminence offering about 102 undergraduate, postgraduate, integrated and doctoral degree courses. Indian Institute of Technology, Delhi courses include B.Tech, M.Tech, M.Sc and Ph.D in various streams of Engineering, Physical Sciences",
      },
    ],
  },
  Face3: {
    howCareerGuidance: (
      <>
        How Career guidance{" "}
        <span className="relative">
          can help you?
          <div className="h-[31px] w-full bg-[#F0BC5E] absolute left-0 bottom-0 -z-10" />
        </span>
      </>
    ),
    whatCounselling: "What counselling from an expert can help your achieve!",
    indianInstitute:
      "Indian Institute of Technology, Delhi or IIT Delhi is an Institute of Eminence offering about 102 undergraduate, postgraduate, integrated and doctoral degree courses. Indian Institute of Technology, Delhi courses include B.Tech, M.Tech, M.Sc and Ph.D in various streams of Engineering, Physical Sciences, Management, Humanities, and Social Sciences. Students",
    establishin:
      "Established in 1961, IIT Delhi is one of the 23 IITs created to be Centres of Excellence for training, research and development in science, engineering and technology in India. Being an Institution of National Importance under the 'Institutes of Technology",
    weHelpYour: " We help your explore new age careers through",
    scientificProvenMethod: "scientific proven method",
    chatWithCounsellerNow: " Chat with Counseller Now",
    thisEasyToFollow:
      "This easy to follow 6 steps proves ensures to build your career selection and evaluate any career confustion along the way.",
    steps: [
      {
        title: "Psychometric Analysis",
        description:
          "Indian Institute of Technology, Delhi or IIT Delhi is an Institute of Eminence offering about 102 undergraduate, postgraduate, integrated and doctoral degree courses. Indian Institute of Technology, Delhi courses include B.Tech, M.Tech, M.Sc and Ph.D in various streams of Engineering, Physical Sciences, Management, Humanities, and Social Sciences. Students seeking admission for B.Tech in any stream must appear for JEE Advanced and JoSaa counselling. Students completing their degrees are offered IIT Delhi placements to prestigious",
      },
      {
        title: "Career Assessment Report",
        description:
          "Indian Institute of Technology, Delhi or IIT Delhi is an Institute of Eminence offering about 102 undergraduate, postgraduate, integrated and doctoral degree courses. Indian Institute of Technology, Delhi courses include B.Tech, M.Tech, M.Sc and Ph.D in various streams of Engineering, Physical Sciences, Management, Humanities, and Social Sciences. Students seeking admission for B.Tech in any stream must appear for JEE Advanced and JoSaa counselling. Students completing their degrees are offered IIT Delhi placements to prestigious",
      },
      {
        title: "Career Assessment Report",
        description:
          "Indian Institute of Technology, Delhi or IIT Delhi is an Institute of Eminence offering about 102 undergraduate, postgraduate, integrated and doctoral degree courses. Indian Institute of Technology, Delhi courses include B.Tech, M.Tech, M.Sc and Ph.D in various streams of Engineering, Physical Sciences, Management, Humanities, and Social Sciences. Students seeking admission for B.Tech in any stream must appear for JEE Advanced and JoSaa counselling. Students completing their degrees are offered IIT Delhi placements to prestigious",
      },
      {
        title: "Career Assessment Report",
        description:
          "Indian Institute of Technology, Delhi or IIT Delhi is an Institute of Eminence offering about 102 undergraduate, postgraduate, integrated and doctoral degree courses. Indian Institute of Technology, Delhi courses include B.Tech, M.Tech, M.Sc and Ph.D in various streams of Engineering, Physical Sciences, Management, Humanities, and Social Sciences. Students seeking admission for B.Tech in any stream must appear for JEE Advanced and JoSaa counselling. Students completing their degrees are offered IIT Delhi placements to prestigious",
      },
      {
        title: "Career Assessment Report",
        description:
          "Indian Institute of Technology, Delhi or IIT Delhi is an Institute of Eminence offering about 102 undergraduate, postgraduate, integrated and doctoral degree courses. Indian Institute of Technology, Delhi courses include B.Tech, M.Tech, M.Sc and Ph.D in various streams of Engineering, Physical Sciences, Management, Humanities, and Social Sciences. Students seeking admission for B.Tech in any stream must appear for JEE Advanced and JoSaa counselling. Students completing their degrees are offered IIT Delhi placements to prestigious",
      },
    ],
  },
  Face4: {
    indiaMostScientific: (
      <>
        India's Most Scientific & Comprehensive <br />{" "}
        <span className="relative">
          Career Development Mechanism
          <div className="h-[31px]  w-3/4 bg-[#F0BC5E] absolute left-[10%] bottom-0 -z-10" />
        </span>
      </>
    ),
    description: `Indian Institute of Technology, Delhi or IIT Delhi is an Institute of Eminence offering about 102 undergraduate, postgraduate, integrated and doctoral degree courses. Indian Institute of Technology, Delhi courses include B.Tech, M.Tech, M.Sc and Ph.D in various streams of Engineering, Physical Sciences, Management, Humanities, and Social Sciences. Students seeking admission for B.Tech in any stream must appear for JEE Advanced and JoSaa counselling. Students completing their degrees are`,
    getStrted: "Get Started",
  },
  Face5: {
    designRoadMap: (
      <>
        {" "}
        <span className="relative">
          We have Best Plans for You
          <div className="h-[31px]  w-3/4 bg-[#F0BC5E] absolute left-[10%] bottom-0 -z-10" />
        </span>
      </>
    ),
    tabs: [
      {
        Title: "High School",
        description: "9-10th",
      },
      {
        Title: "High School",
        description: "11-12th",
      },
      {
        Title: "Graduates",
        description: "& Working Proffesiianl",
      },
    ],
    plans: [
      {
        border: " border-[#637CED] ",
        Title: "Basic",
        color: "#637CED",
        background: "bg-[#637CED]",
        image: medias.images.guidance.Face5.Plan1,
        subTitle: "All the basics for buisness that are just getting started",
        amount: "12",
        perks: [
          "Single project use",
          "Basic dashboard",
          "All components included",
        ],
        priceColor: "text-[#637CED]",
      },
      {
        border: "  border-[#ED62A5] ",
        Title: "Economic",
        background: "bg-[#EC539C]",
        color: "#EC539C",
        image: medias.images.guidance.Face5.Plan2,
        subTitle: "Better for growing buisness that want more customer",
        amount: "84",
        perks: [
          "Unlimited project use",
          "Advance dashbaord",
          "All components included",
          "Advance insight",
        ],
        priceColor: "text-[#ED62A5] ",
      },
      {
        border: " border-[#5BC587] ",
        Title: "Advance",
        background: "bg-[#5BC587]",
        color: "#5BC587",
        image: medias.images.guidance.Face5.Plan3,
        subTitle: "Advance feature for pros who need more custominzation",
        amount: "316",
        perks: [
          "Unlimited project use",
          "Advance dashbaord",
          "All components included",
          "Phone Support",
        ],
        priceColor: "text-[#5BC587]",
      },
    ],
  },
  Face6: {
    meetYourMentors: (
      <>
        {" "}
        <span className="relative">
          Meet your Mentors
          <div className="h-[31px]  w-3/4 bg-[#F0BC5E] absolute left-[10%] bottom-0 -z-10" />
        </span>
      </>
    ),
    describe: `Indian Institute of Technology, Delhi or IIT Delhi is an Institute of Eminence offering about 102 undergraduate, postgraduate, integrated and doctoral degree courses. Indian Institute of Technology, Delhi courses include B.Tech, M.Tech, M.Sc and Ph.D in various streams of Engineering, Physical Sciences, Management, Humanities, and Social Sciences. Students seeking admission for B.Tech in any stream must appear for JEE Advanced and JoSaa counselling`,
    mentors: [
      {
        image: medias.images.guidance.Face6.MetaMentor,
        logoImage: medias.images.guidance.Face6.metalogo,
        name: "Mena Thomas",
        occupation: "Software Engineer",
      },
      {
        image: medias.images.guidance.Face6.NetFlixMentor,
        logoImage: medias.images.guidance.Face6.netflixlogo,
        name: "Steve Smith",
        occupation: "Technical Head",
      },
    ],
  },
  Face7: {
    tabs: [
      {
        Title: "250+ partner schools",
      },
      {
        Title: "120+ Partner Universities",
      },
    ],
    Images: [
      { src: medias.images.guidance.Face7.Person1, classes: "row-start-2" },
      { src: medias.images.guidance.Face7.Person2, classes: "" },
      { src: medias.images.guidance.Face7.Person3, classes: "" },
      { src: medias.images.guidance.Face7.Person4, classes: "" },
      { src: medias.images.guidance.Face7.Person5, classes: "" },
      { src: medias.images.guidance.Face7.Person6, classes: "" },
      { src: medias.images.guidance.Face7.Person7, classes: "row-start-2" },
      { src: medias.images.guidance.Face7.Person8, classes: "" },
      { src: medias.images.guidance.Face7.Person9, classes: "" },
      { src: medias.images.guidance.Face7.Person10, classes: "" },
    ],
  },
  Face8: {
    realStoriesOfRealPeople: (
      <>
        {" "}
        <span className="relative">
          Real Stories of real people
          <div className="h-[31px]  w-[calc(100%+30px)]  bg-[#F0BC5E] absolute left-[calc(0%-15px)] -bottom-[10px] -z-10" />
        </span>
      </>
    ),
  },
  Face10: {
    Perks: [
      {
        img: medias.images.guidance.Face10.Perk3,
        desc: "Assessment through scientifically proven psychometric analysis",
        back: "#E2FFFA",
      },
      {
        img: medias.images.guidance.Face10.Perk1,
        desc: 'Holistic development through courses in "Multiple Intelligences"',
        back: "#FFF6E6",
      },
      {
        img: medias.images.guidance.Face10.Perk5,
        desc: "Extensive database on colleges, career, exams, scholarships and courses",
        back: "#FFE7FD",
      },
      {
        img: medias.images.guidance.Face10.Perk6,
        desc: "Prepares students for skills of the future",
        back: "#E1E7FF",
      },
      {
        img: medias.images.guidance.Face10.Perk4,
        desc: "Guidance towards selecting appropriate career options",
        back: "#FFD9B6",
      },
      {
        img: medias.images.guidance.Face10.Perk2,
        desc: "Mentoring by counsellors and industry experts",
        back: "#E2FDFF",
      },
    ],
  },
  Face11: {
    footerInfo: [
      {
        Title: "Top Colleges",
        pointes: [
          "About us",
          "Top Colleges in India",
          "Top Engineering Colleges in India",
          "Top MBA Colleges in India",
          "Top MBA Colleges in India",
          "Top Law Colleges in India",
          "Top Colleges in Chennai",
          "Top Medical Colleges in India",
          "LPU",
          "UPES",
        ],
      },
      {
        Title: "Top Exam",
        pointes: [
          "NEET UG",
          "NEET PG",
          "NEET SS",
          "NEET MDS",
          "INI CET",
          "FMGE AIAPGET",
          "Top Medical Colleges",
          "Medical Colleges",
          "Medical Exams",
        ],
      },
      {
        Title: "Study Abroad",
        pointes: [
          "Animation",
          "B.Com",
          "B.Sc",
          "BBA",
          "CA",
          "Fashion Designing",
          "Hotel Management",
          "Law",
          "Mass Communication & Media",
          "MBBS",
        ],
      },
      {
        Title: "Career Counsellor",
        pointes: [
          "Free Online courses",
          "Free Government courses",
          "Data Science Courses",
          "Artificial Intelligence Courses",
          "Machine Learning Courses",
          "Cloud Computing Courses",
          "AWS Certification Python Courses",
          "Cybersecurity Courses",
          "Digital Marketing Courses",
        ],
      },
      {
        Title: "Exams",
        pointes: [
          "RRB Group D",
          "RRB NTPC",
          "CTET",
          "UPTET",
          "UGC",
          "NET",
          "DSSSB",
          "SSC CGL",
          "SSC CHSL",
          "SSC GD",
          "NDA",
        ],
      },
      {
        Title: "Reviews",
        pointes: [
          "MAT 2023",
          "SNAP 2023",
          "IIT JAM 2023",
          "LPUNEST 2023",
          "MET 2023",
          "CUET 2023",
          "SET 2023",
          "JEE Advanced 2023",
        ],
      },
      {
        Title: "Important Updates",
        pointes: [
          "JoSAA 2023 Round 6",
          "NEET PG 2023 Counselling",
          "NExT Exam 2024",
          "CAT Syllabus 2023",
          "NEET UG Counselling 2023",
          "SSC CHSL 2023 Admit Card",
          "CUET UG 2023",
          "CUET result 2023",
          "CUET 2023",
          "CUET PG Result 2023",
        ],
      },
      {
        Title: "Mentors",
        pointes: [
          "Study Abroad Home",
          "BTech abroad",
          "MBA abroad",
          "MS abroad",
          "GRE",
          "GMAT",
          "SAT",
          "IELTS",
          "TOEFL",
        ],
      },
      {
        Title: "Predictors & Ebooks",
        pointes: [
          "JEE Main College Predictor",
          "JEE Main Rank Predictor",
          "JEE Advanced College Predictor",
          "NEET College Predictor",
          "GATE College Predictor with PSU",
          "CAT College Predictor",
          "CLAT College Predictor",
          "CAT Percentile Predictor",
          "E-books & Sample Paper",
        ],
      },
      {
        Title: "Top Colleges",
        pointes: [
          "JEE Main College Predictor",
          "JEE Main Rank Predictor",
          "JEE Advanced College Predictor",
          "NEET College Predictor",
          "GATE College Predictor with PSU",
          "CAT College Predictor",
          "CLAT College Predictor",
          "CAT Percentile Predictor",
          "E-books & Sample Paper",
        ],
      },
    ],
    lastData: [
      {
        title: "About Unigoal",
      },
      {
        title: "Contact us",
      },
      {
        title: "Careers",
      },
      {
        title: "Sitemap",
      },
      {
        title: "Terms & Conditions",
      },
      {
        title: "Privacy Policy",
      },
    ],
    copyRight: "Copyright © 2024 Sygnific Careers Pvt Ltd",
  },
};

export const NineTenthPlanPageContent = {
  Face1: {
    titleText: (
      <>
        Solutions for Career{" "}
        <span className="font-medium  font-greatVibesRegular text-[#D91F1F]  !text-[55px] ">
          Guidance
        </span>
      </>
    ),
  },
  Face2: {
    Title: (
      <>
        {" "}
        Why Choosing an appropriate <br /> Career is important at this{" "}
        <span className="relative ">
          <span className="relative z-20  PlusJakartaSans-ExtraBoldCustom ">
            {" "}
            age group?{" "}
          </span>
          <div className="h-[31px]  w-[calc(95%)]  bg-[#F0BC5E] absolute z-10 left-[calc(0%-5px)] -bottom-[10px]" />
        </span>
      </>
    ),
  },
  Face3: {
    Title: (
      <>
        We{" "}
        <span className="relative ">
          <span className="relative z-20"> are here to guide </span>
          <div className="h-[31px]  w-[80%]  bg-[#F0BC5E] absolute z-10 left-[calc(0%+25px)] -bottom-[10px]" />
        </span>{" "}
        <span className="relative z-20">you</span>{" "}
      </>
    ),
    Cards: [
      {
        title: "Get assigned to an advisor",
        image: medias.images.nineTenthPlan.Face2.Face3Card1,
        className: "bg-[#D5CBFF]",
        desc: "Indian Institute of Technology, Delhi or IIT Delhi is an Institute of Eminence offering about 102 undergraduate, postgraduate, integrated and doctoral degree courses. Indian Institute of Technology, Delhi courses include B.Tech, M.Tech, M.Sc and Ph.D in various streams of Engineering, Physical Sciences",
      },
      {
        title: "Make a career plan",
        image: medias.images.nineTenthPlan.Face2.Face3Card2,
        className: "bg-[#FFEAB0]",
        desc: "Indian Institute of Technology, Delhi or IIT Delhi is an Institute of Eminence offering about 102 undergraduate, postgraduate, integrated and doctoral degree courses. Indian Institute of Technology, Delhi courses include B.Tech, M.Tech, M.Sc and Ph.D in various streams of Engineering, Physical Sciences",
      },
      {
        title: "Apply to top schools",
        image: medias.images.nineTenthPlan.Face2.Face3Card3,
        className: "bg-[#FFC7F8]",
        desc: "Indian Institute of Technology, Delhi or IIT Delhi is an Institute of Eminence offering about 102 undergraduate, postgraduate, integrated and doctoral degree courses. Indian Institute of Technology, Delhi courses include B.Tech, M.Tech, M.Sc and Ph.D in various streams of Engineering, Physical Sciences",
      },
    ],
  },
  Face4: {
    Title: (
      <>
        {" "}
        <span className="relative ">
          <span className="relative z-20 PlusJakartaSans-ExtraBoldCustom ">
            Career Guidance Team{" "}
          </span>
          <div className="h-[31px]  w-[calc(100%+30px)]  bg-[#F0BC5E] absolute z-10 left-[calc(0%-15px)] -bottom-[10px]" />
        </span>
      </>
    ),
    Description:
      "Indian Institute of Technology, Delhi or IIT Delhi is an Institute of Eminence offering about 102 undergraduate, postgraduate, integrated and doctoral degree courses. Indian Institute of Technology, Delhi courses include B.Tech, M.Tech, M.Sc and Ph.D in various streams of Engineering, Physical Sciences, Management, Humanities, and Social Sciences. Students seeking admission for B.Tech in any stream must appear for JEE Advanced and JoSaa counselling.",
    pointes: {
      conventional: [
        {
          isAvailable: true,
          desc: "Psychometric Test",
        },
        {
          isAvailable: true,
          desc: "Career Guidance Sessions",
        },
        {
          isAvailable: false,
          desc: "Research Material & Guidance tool",
        },
        {
          isAvailable: false,
          desc: "Career Guidance Sessions",
        },
        {
          isAvailable: false,
          desc: (
            <span>
              Profile Building by <b> Guidance Team </b> comprising of{" "}
              <b>Counsellors Mentors</b> and <b> Unigoal Platforms </b> tools
            </span>
          ),
        },
        {
          isAvailable: false,
          desc: (
            <>
              Career Specific <b>Webinar</b> by Top <b> Mentors </b>
            </>
          ),
        },
        {
          isAvailable: false,
          desc: (
            <>
              Support 24/7- <b>WhatsApp</b>, <b>Chatbot</b>
            </>
          ),
        },
      ],
      uniGoals: [
        {
          isAvailable: true,
          desc: "Psychometric Test",
        },
        {
          isAvailable: true,
          desc: "Career Guidance Sessions",
        },
        {
          isAvailable: true,
          desc: "Research Material & Guidance tool",
        },
        {
          isAvailable: true,
          desc: "Career Guidance Sessions",
        },
        {
          isAvailable: true,
          desc: (
            <>
              Profile Building by <b> Guidance Team </b> comprising of{" "}
              <b>Counsellors Mentors</b> and <b> Unigoal Platforms </b> tools
            </>
          ),
        },
        {
          isAvailable: true,
          desc: (
            <>
              Career Specific <b>Webinar</b> by Top <b> Mentors </b>
            </>
          ),
        },
        {
          isAvailable: true,
          desc: (
            <>
              Support 24/7- <b>WhatsApp</b>, <b>Chatbot</b>
            </>
          ),
        },
      ],
    },
  },
  Face5: {
    Title: (
      <>
        {" "}
        <span className="relative ">
          <span className="relative z-20 PlusJakartaSans-ExtraBoldCustom ">
            Stream Selector Programme{" "}
          </span>
          <div className="h-[26px]  w-[64%]  bg-[#F0BC5E] absolute z-10 left-[20%] -bottom-[10px]" />
        </span>
      </>
    ),
    desc: (
      <span className="text-[18px] font-semibold">
        Indian Institute of Technology, Delhi or IIT Delhi is an Institute of
        Eminence offering about 102 undergraduate, postgraduate, integrated and
        doctoral degree courses. Indian Institute of Technology, Delhi courses
        include B.Tech, M.Tech, M.Sc and Ph.D in various streams of Engineering,
        Physical Sciences
      </span>
    ),
  },
  Face7: {
    plans: [
      {
        border: " border-[#637CED] ",
        Title: "Basic",
        color: "#637CED",
        background: "bg-[#637CED]",
        image: medias.images.guidance.Face5.Plan1,
        subTitle: "All the basics for buisness that are just getting started",
        amount: "12",
        perks: [
          "Single project use",
          "Basic dashboard",
          "All components included",
        ],
        priceColor: "text-[#637CED]",
        bgBtn: "bg-[#637CED]",
        btnBorder: "border-[#637CED]",
      },

      {
        border: "  border-[#ED62A5] ",
        Title: "Economic",
        background: "bg-[#EC539C]",
        color: "#EC539C",
        image: medias.images.guidance.Face5.Plan2,
        subTitle: "Better for growing buisness that want more customer",
        amount: "84",
        perks: [
          "Unlimited project use",
          "Advance dashbaord",
          "All components included",
          "Advance insight",
        ],
        priceColor: "text-[#ED62A5] ",
        bgBtn: "bg-[#ED62A5]",
        btnBorder: "border-[#637CED]",
      },
      {
        border: " border-[#5BC587] ",
        Title: "Advance",
        background: "bg-[#5BC587]",
        color: "#5BC587",
        image: medias.images.guidance.Face5.Plan3,
        subTitle: "Advance feature for pros who need more custominzation",
        amount: "316",
        perks: [
          "Unlimited project use",
          "Advance dashbaord",
          "All components included",
          "Phone Support",
        ],
        priceColor: "text-[#5BC587]",
        bgBtn: "bg-[#5BC587]",
        btnBorder: "border-[#637CED]",
      },
      {
        border: " border-[#637CED] ",
        Title: "Basic",
        color: "#637CED",
        background: "bg-[#637CED]",
        image: medias.images.guidance.Face5.Plan1,
        subTitle: "All the basics for buisness that are just getting started",
        amount: "12",
        perks: [
          "Single project use",
          "Basic dashboard",
          "All components included",
        ],
        priceColor: "text-[#637CED]",
        bgBtn: "bg-[#637CED]",
        btnBorder: "border-[#637CED]",
      },

      {
        border: "  border-[#ED62A5] ",
        Title: "Economic",
        background: "bg-[#EC539C]",
        color: "#EC539C",
        image: medias.images.guidance.Face5.Plan2,
        subTitle: "Better for growing buisness that want more customer",
        amount: "84",
        perks: [
          "Unlimited project use",
          "Advance dashbaord",
          "All components included",
          "Advance insight",
        ],
        priceColor: "text-[#ED62A5] ",
        bgBtn: "bg-[#ED62A5]",
        btnBorder: "border-[#ED62A5]",
      },
      {
        border: " border-[#5BC587] ",
        Title: "Advance",
        background: "bg-[#5BC587]",
        color: "#5BC587",
        image: medias.images.guidance.Face5.Plan3,
        subTitle: "Advance feature for pros who need more custominzation",
        amount: "316",
        perks: [
          "Unlimited project use",
          "Advance dashbaord",
          "All components included",
          "Phone Support",
        ],
        priceColor: "text-[#5BC587]",
        bgBtn: "bg-[#5BC587]",
        btnBorder: "border-[#5BC587]",
      },
    ],
  },
};

export const HomeLayout = [
  {
    Icons: medias.images.Home.Menu.Home,
    title: "Home",
    path: "/profile/dashboard-home",
    id: "home",
  },
  {
    Icons: medias.images.Home.Menu.MyCollection,
    title: "My Collections",
    path: "/profile/my-colletions",
    id: "myCollection",
  },
  {
    Icons: medias.images.Home.Menu.CareerCounsellingSelected,
    title: "Career Counselling",
    path: "/profile/career-counselling?location=psychometricTest",
    id: "careercounselling",
  },
  {
    Icons: medias.images.Home.Menu.MentorShip,
    title: "Mentorship",
    path: "/profile/mentorship",
    id: "mentorship",
  },
  {
    Icons: medias.images.Home.Menu.Documents,
    title: "Documents",
    path: "/profile/documents",
    id: "documents",
  },
  {
    Icons: medias.images.Home.Menu.Documents,
    title: "My Application",
    path: "/profile/my-application",
    id: "myApplication",
  },
  {
    Icons: medias.images.Home.Menu.MyCourses,
    title: "My Courses",
    path: "/profile/my-courses",
    id: "myCourses",
  },
  {
    Icons: medias.images.Home.Menu.RewaredsBadges,
    title: "Rewards & Badges",
    path: "/profile/rewards-badges",
    id: "rewardsBadges",
  },
  {
    Icons: medias.images.Home.Menu.OrderPurchases,
    title: "Order & Purchases",
    path: "/profile/order-purchases",
    id: "orderPurchases",
  },
];

export const CounsellingLists = [
  {
    Name: "Plans",
    icons: medias.images.Profile.Plans,
    url: "plans",
  },
  {
    Name: "Psychometric Test",
    icons: medias.images.Profile.psychometricTest,
    url: "psychometricTest",
  },
  {
    Name: "Report",
    icons: medias.images.Profile.Report,
    url: "report",
  },
  {
    Name: "Sessions Schedule",
    icons: medias.images.Profile.Sessionschedule,
    url: "session-schedule",
  },
  {
    Name: "Career Mapping",
    icons: medias.images.Profile.CareerMapping,
    url: "career-mapping",
  },
  {
    Name: "Profile Buiding",
    icons: medias.images.Profile.Profilebuilding,
    url: "profile-building",
  },
  {
    Name: "Personality Development",
    icons: medias.images.Profile.PersonalityDevelopment,
    url: "",
  },
];

export const qualities = [
  {
    name: "Social Behaviour",
    icons: `${mediaResources}/Profile/CareerCounselling/SocialBehaviour.svg`,
    isActive: true,
  },
  {
    name: "Personality",
    icons: `${mediaResources}/Profile/CareerCounselling/Personality.svg`,
  },
  {
    name: "Aptitude",
    icons: `${mediaResources}/Profile/CareerCounselling/Aptitude.svg`,
  },
  {
    name: "Interest",
    icons: `${mediaResources}/Profile/CareerCounselling/Intrest.svg`,
  },
  {
    name: "LearningStyle",
    icons: `${mediaResources}/Profile/CareerCounselling/LearningStyle.svg`,
  },
  {
    name: "Value and Motives",
    icons: `${mediaResources}/Profile/CareerCounselling/value.png`,
  },
];

export const collegeData = [
  {
    Name: "IIT Delhi",
    Icons: medias.images.HomeMain.IIMDelhi,
  },
  {
    Name: "IITBhu",
    Icons: medias.images.HomeMain.IITBhu,
  },
  {
    Name: "IIM Delhi",
    Icons: medias.images.HomeMain.IIMDelhi,
  },
  {
    Name: "SSCC",
    Icons: medias.images.HomeMain.IIMDelhi,
  },
  {
    Name: "IIT Bom",
    Icons: medias.images.HomeMain.IITBom,
  },
  {
    Name: "IITBhu",
    Icons: medias.images.HomeMain.IIMDelhi,
  },
];

export const findOver = [
  {
    Icons: medias.images.HomeMain.FindCollege.Exams,
    Title: "Exams",
    tileColor: "bg-[#FFCEED]",
    description: "Know more about your exams",
    keyWords: ["BA", "B.SC", "BBA", "B.Sc", "BBA/BBM", "BCA", "BE/B. Tech"],
    navText: "Check all Entrance Exams in India",
  },
  {
    Icons: medias.images.HomeMain.FindCollege.CollegeP,
    Title: "College Predictor",
    tileColor: "bg-[#C0EFFE]",
    description: "Know your college admission chances",
    keyWords: ["CAT", "MAT", "IPO", "JEE", "NEET", "JEE Advance"],
    navText: "Find where you may get Admission",
  },
  {
    Icons: medias.images.HomeMain.FindCollege.Ranking,
    Title: "Ranking",
    tileColor: "bg-[#E1C6FF]",
    description: "College ranked based on real data",
    keyWords: ["4,355", "4,355", "4,355", "4,355", "4,355", "4,355"],
    navText: "Top Ranked Colleges in India",
  },
  {
    Icons: medias.images.HomeMain.FindCollege.FindCollege,
    Title: "Find Colleges",
    tileColor: "bg-[#FECABA]",
    description: "Discover 19000+ colleges via preferences",
    keyWords: ["Best MBA colleges in India", "Best B. Tech colleges in India"],
    navText: "Discover Top Colleges in India",
  },
  {
    Icons: medias.images.HomeMain.FindCollege.CompareCollege,
    Title: "Compare Colleges",
    tileColor: "bg-[#C3DAFF]",
    description: "Compare on the basis of rank, fees, etc.",
    keyWords: ["Best MBA colleges in India", "Best B. Tech colleges in India"],
    navText: "Compare Top Colleges",
  },
  {
    Icons: medias.images.HomeMain.FindCollege.CourseFinder,
    Title: "Course Finder",
    tileColor: "bg-[#FEF7AD]",
    description: "Know more about your exams.",
    keyWords: ["BA", "B.SC", "BBA", "B.Sc", "BBA/BBM", "BCA", "BE/B. Tech"],
    navText: "Get Top Courses in Indian Colleges",
  },
  {
    Icons: medias.images.HomeMain.FindCollege.Careers,
    Title: "Careers",
    tileColor: "bg-[#E3FFCA]",
    description: "Discover careers",
    keyWords: ["Management", "IT", "Finance", "Medical", "Design", "Law"],
    navText: "Discover Top Careers",
  },
  {
    Icons: medias.images.HomeMain.FindCollege.Scholarship,
    Title: "Scholarship",
    tileColor: "bg-[#FFEEBD]",
    description: "Get upto 100% scholarships",
    keyWords: ["Management", "IT", "Finance", "Medical", "Design", "Law"],
    navText: "Get 100% Scholarships",
  },
];

export const States = [
  {
    icons: medias.images.HomeMain.states.bhopal,
    name: "Bhopal",
  },
  {
    icons: medias.images.HomeMain.states.indiagate,
    name: "Delhi",
  },
  {
    icons: medias.images.HomeMain.states.hyderabadcharminar,
    name: "Hydrabad",
  },
  {
    icons: medias.images.HomeMain.states.chennai,
    name: "Chennai",
  },
  {
    icons: medias.images.HomeMain.states.pune,
    name: "Pune",
  },
  {
    icons: medias.images.HomeMain.states.mumbai,
    name: "Mumbai",
  },
];
