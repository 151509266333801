import { medias } from "../../../../config/Resources";

/* eslint-disable jsx-a11y/img-redundant-alt */

function CourseDetailsTable({ course }) {
	const TableDetails = [
		{
			Courses: "MSc in Economics",
			Fees: "Rs 2.63 Lakhs (1st year Fees)",
			Eligibility: "10+2 with 75% + JEE Advanced",
		},
		{
			Courses: "MSc in Chemistry",
			Fees: "Rs 2.63 Lakhs (1st year Fees)",
			Eligibility: "Graduation with 60% + GATE",
		},
		{
			Courses: "MSc in Mathematics",
			Fees: "Rs 2.63 Lakhs (1st year Fees)",
			Eligibility: "Post Graduation with 60%",
		},
		{
			Courses: "MSc in Physics",
			Fees: "Rs 2.63 Lakhs (1st year Fees)",
			Eligibility: "Graduation with 60% + CAT",
		},
	];

	return (
		<div>
			<div className="ml-4 mr-4 mt-8">
				<div className="overflow-x-auto">
					<table className="min-w-full border-collapse">
						<thead>
							<tr>
								<th className="px-4 py-2 bg-[#FFEDC0] text-center font-plusJakartaSans font-bold text-[13.3px] rounded-l-sm border-r-2 border-white">
									#
								</th>
								<th className="px-4 py-2 bg-[#FFEDC0] text-center font-plusJakartaSans font-bold text-[13.3px] border-r-2 border-white">
									Year 1
								</th>
								<th className="px-4 py-2 bg-[#FFEDC0] text-center font-plusJakartaSans font-bold text-[13.3px] border-r-2 border-white">
									Year 2
								</th>
								<th className="px-4 py-2 bg-[#FFEDC0] text-center font-plusJakartaSans font-bold text-[13.3px] border-r-2 border-white">
									Year 3
								</th>
								<th className="px-4 py-2 bg-[#FFEDC0] text-center font-plusJakartaSans font-bold text-[13.3px] border-r-2 border-white">
									Year 4
								</th>
								<th className="px-4 py-2 bg-[#FFEDC0] text-center font-plusJakartaSans font-bold text-[13.3px] rounded-r-sm">
									Year 5
								</th>
							</tr>
						</thead>
						<tbody className="bg-[#ffffff]">
							{TableDetails.map((detail, index) => (
								<tr
									key={index}
									className="border-b border-dotted last:border-0"
								>
									<td className="px-4 py-2 text-center text-[#000000] text-opacity-[100%] font-plusJakartaSans font-medium text-[11.5px]">
										{detail.Courses}
									</td>
									<td className="px-4 py-2 text-center text-[#000000] text-opacity-[100%] font-plusJakartaSans font-medium text-[11.5px]">
										{detail.Fees}
									</td>
									<td className="px-4 py-2 text-center text-[#000000] text-opacity-[100%] font-plusJakartaSans font-medium text-[11.5px]">
										{detail.Fees}
									</td>
									<td className="px-4 py-2 text-center text-[#000000] text-opacity-[100%] font-plusJakartaSans font-medium text-[11.5px]">
										{detail.Fees}
									</td>
									<td className="px-4 py-2 text-center text-[#000000] text-opacity-[100%] font-plusJakartaSans font-medium text-[11.5px]">
										{detail.Fees}
									</td>
									<td className="px-4 py-2 text-center text-[#000000] text-opacity-[100%] font-plusJakartaSans font-medium text-[11.5px]">
										{detail.Eligibility}
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
}

export default CourseDetailsTable;
