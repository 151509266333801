/* eslint-disable jsx-a11y/anchor-is-valid */
const CalendarContent = () => {
    return (
        <div className="max-w-screen-xl w-full mx-auto">
            <div className="gap-10">
                <div className="bg-white rounded-lg shadow-lg">
                    <div className="p-4 pt-2">
                        <div className="mb-2">
                            <p className="text-sm font-bold flex items-center bg-pink-200 p-1 rounded-xl mt-5 pl-2 pr-2" style={{ width: "fit-content" }}>
                                5th January
                            </p>
                            <a href="#" className="text-gray-900 font-bold mb-1 mt-1 hover:text-indigo-600 inline-block text-sm">
                                Can coffee make you a better developer?
                            </a>
                            <p className="text-gray-700 text-xs">
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia, nulla! Maiores et perferendis eaque, exercitationem praesentium nihil.
                            </p>
                        </div>
                        <div className="flex items-center">
                            <a href="#">
                                <img className="w-10 h-10 rounded-full mr-4" src="https://tailwindcss.com/img/jonathan.jpg" alt="Avatar of Jonathan Reinink" />
                            </a>
                            <div className="text-sm">
                                <a href="#" className="text-gray-900 font-semibold leading-none hover:text-indigo-600">
                                    Jonathan Reinink
                                </a>
                                <p className="text-gray-600">Aug 18</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CalendarContent;
