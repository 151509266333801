import React, { useEffect, useState } from "react";
import CalendarContent from "./calenderContent";

const Calendar = () => {
  // Get current date
  const currentDate = new Date();
  const [month, setMonth] = useState(currentDate.getMonth());
  const [year, setYear] = useState(currentDate.getFullYear());
  const [selectedDate, setSelectedDate] = useState(null);

  const daysInMonth = new Date(year, month + 1, 0).getDate(); // Number of days in the current month
  const startDayOfMonth = new Date(year, month, 1).getDay(); // Weekday of the first day of the month

  const days = [];
  // Fill in the days of the month
  for (let i = 1; i <= daysInMonth; i++) {
    days.push(i);
  }

  // Fill in empty slots for days before the first day of the month
  for (let i = 0; i < startDayOfMonth; i++) {
    days.unshift(null);
  }

  // Function to change to the previous month
  const prevMonth = () => {
    if (month === 0) {
      setMonth(11);
      setYear(year - 1);
    } else {
      setMonth(month - 1);
    }
  };

  // Function to change to the next month
  const nextMonth = () => {
    if (month === 11) {
      setMonth(0);
      setYear(year + 1);
    } else {
      setMonth(month + 1);
    }
  };

  useEffect(() => {
    const newDate = new Date().getDate();
    setSelectedDate(newDate);
  }, []);

  // Function to handle date selection
  const handleDateClick = (day) => {
    setSelectedDate(day);
  };

  return (
    <div className="max-w-md mx-auto bg-white rounded-lg overflow-hidden shadow-md p-4 text-xs ">
      <div className="flex justify-between items-center mb-0 bg-[#D2ACFD] rounded-t-md pt-1 pb-1">
        <button
          className="transition-colors duration-200 transform hover:bg-gray-200 rounded-full p-2"
          onClick={prevMonth}
        >
          &#8249;
        </button>
        <h1 className="text-xs font-semibold">
          {new Date(year, month).toLocaleString("default", {
            month: "long",
            year: "numeric",
          })}
        </h1>
        <button
          className="transition-colors duration-200 transform hover:bg-gray-200 rounded-full p-2"
          onClick={nextMonth}
        >
          &#8250;
        </button>
      </div>
      <div className="grid grid-cols-7 text-center border border-gray-200">
        {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((day) => (
          <div key={day} className="p-2 bg-[#46494F] text-white">
            {day}
          </div>
        ))}
        {days.map((day, index) => (
          <div
            key={index}
            className={`p-4 cursor-pointer font-bold ${
              selectedDate === day ? "bg-blue-200" : ""
            }`}
            onClick={() => handleDateClick(day)}
          >
            {day}
          </div>
        ))}
      </div>
      <CalendarContent />
    </div>
  );
};

export default Calendar;
