/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";

const Guidance = () => {
  return (
    <div className="p-2 flex flex-col bg-[#F3F6FF]">
      {/* <Link to={"/guidance"} className="cursor-pointer">
        Guidance
      </Link>
      <Link to={"/ninth-tenth-plan"} className="cursor-pointer">
        9th & 10th
      </Link>{" "}
      <Link to={"/eleven-twelfth-plan"} className="cursor-pointer">
        11th & 12th
      </Link>{" "}
      <Link
        to={"/graduate-and-working-professional"}
        className="cursor-pointer"
      >
        Graduate
      </Link> */}

      <div className="md:flex p-3 w-full rounded-3xl">
        <ul className="flex-column w-[35%] space-y space-y-4 text-sm font-medium text-gray-500 dark:text-gray-400 md:me-4 mb-4 md:mb-0">
          <li>
            <a
              href="#"
              className="inline-flex items-center px-4 py-2.5 text-black hover:bg-blue-100 rounded-lg active w-full "
              aria-current="page"
            >
              <svg
                id="Layer_73"
                xmlns="http://www.w3.org/2000/svg"
                width="55.066"
                height="59.319"
                viewBox="0 0 55.066 59.319"
              >
                <g
                  id="Group_294801"
                  data-name="Group 294801"
                  transform="translate(0)"
                >
                  <g
                    id="Group_294689"
                    data-name="Group 294689"
                    transform="translate(15.47 29.501)"
                  >
                    <g id="Group_294688" data-name="Group 294688">
                      <path
                        id="Path_158096"
                        data-name="Path 158096"
                        d="M87.85,134.916a9.091,9.091,0,0,1-8.444,0v1.492a3.16,3.16,0,0,1-.391,1.522l3.577,3.76a.518.518,0,0,1,.141.387c.252.021.505.035.762.035a9.058,9.058,0,0,0,1.026-.064h0a.521.521,0,0,1,.144-.36l3.577-3.76a3.16,3.16,0,0,1-.391-1.522v-1.491Z"
                        transform="translate(-79.014 -134.916)"
                        fill="#f9d0b4"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294691"
                    data-name="Group 294691"
                    transform="translate(10.918 9.895)"
                  >
                    <g id="Group_294690" data-name="Group 294690">
                      <path
                        id="Path_158097"
                        data-name="Path 158097"
                        d="M77.553,55.823a1.679,1.679,0,0,0-2.257-.15,9.2,9.2,0,0,1-11.2-.006,1.678,1.678,0,0,0-2.253.153l0,0a4.738,4.738,0,0,0-1.309,3.27v7.691a9.165,9.165,0,0,0,18.329,0V59.093a4.738,4.738,0,0,0-1.309-3.27Z"
                        transform="translate(-60.533 -55.304)"
                        fill="#f9d0b4"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294693"
                    data-name="Group 294693"
                    transform="translate(29.224 14.391)"
                  >
                    <g id="Group_294692" data-name="Group 294692">
                      <path
                        id="Path_158098"
                        data-name="Path 158098"
                        d="M135.48,73.561a3.855,3.855,0,0,0-.588.047v6.938c0,.207-.009.411-.023.615a3.824,3.824,0,1,0,.611-7.6Z"
                        transform="translate(-134.869 -73.561)"
                        fill="#f9d0b4"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294695"
                    data-name="Group 294695"
                    transform="translate(6.504 14.391)"
                  >
                    <g id="Group_294694" data-name="Group 294694">
                      <path
                        id="Path_158099"
                        data-name="Path 158099"
                        d="M47.021,80.545V73.608a3.828,3.828,0,1,0,.023,7.553h0C47.031,80.957,47.021,80.752,47.021,80.545Z"
                        transform="translate(-42.607 -73.561)"
                        fill="#f9d0b4"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294697"
                    data-name="Group 294697"
                    transform="translate(8.404 0.613)"
                  >
                    <g id="Group_294696" data-name="Group 294696">
                      <path
                        id="Path_158100"
                        data-name="Path 158100"
                        d="M71.132,23.572a.567.567,0,0,1-.577-.543c-.07-5.107-5.9-6.066-11.709-5.063a10.268,10.268,0,0,0-8.523,10.117v3.832a3.787,3.787,0,0,1,2.514-.478v-.753a4.739,4.739,0,0,1,1.309-3.27l0,0a1.678,1.678,0,0,1,2.253-.153,9.2,9.2,0,0,0,11.2.006,1.679,1.679,0,0,1,2.257.15,4.738,4.738,0,0,1,1.309,3.27v.753a3.786,3.786,0,0,1,2.534.49V28.78c0-4.573-1.651-5.208-2.568-5.208Z"
                        transform="translate(-50.323 -17.611)"
                        fill="#5b5d60"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294699"
                    data-name="Group 294699"
                    transform="translate(10.303 9.278)"
                  >
                    <g id="Group_294698" data-name="Group 294698">
                      <path
                        id="Path_158101"
                        data-name="Path 158101"
                        d="M67.813,74.676a9.791,9.791,0,0,1-9.78-9.781V57.2a5.331,5.331,0,0,1,1.479-3.695,2.289,2.289,0,0,1,3.081-.216,8.588,8.588,0,0,0,10.438.007,2.29,2.29,0,0,1,3.083.21h0A5.332,5.332,0,0,1,77.594,57.2V64.9A9.792,9.792,0,0,1,67.813,74.676ZM61.167,54.03a1.046,1.046,0,0,0-.76.325A4.107,4.107,0,0,0,59.264,57.2V64.9a8.549,8.549,0,1,0,17.1,0V57.2a4.106,4.106,0,0,0-1.139-2.845h0a1.062,1.062,0,0,0-1.431-.091,9.82,9.82,0,0,1-11.958-.005A1.082,1.082,0,0,0,61.167,54.03Z"
                        transform="translate(-58.033 -52.798)"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294701"
                    data-name="Group 294701"
                    transform="translate(28.833 13.775)"
                  >
                    <g id="Group_294700" data-name="Group 294700">
                      <path
                        id="Path_158102"
                        data-name="Path 158102"
                        d="M134.283,79.943a4.06,4.06,0,0,1-.447-.026.616.616,0,0,1,.134-1.224,2.838,2.838,0,0,0,.313.018,3.211,3.211,0,1,0,0-6.421,3.155,3.155,0,0,0-.323.017.616.616,0,1,1-.127-1.225,4.364,4.364,0,0,1,.45-.024,4.442,4.442,0,0,1,0,8.884Z"
                        transform="translate(-133.28 -71.059)"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294703"
                    data-name="Group 294703"
                    transform="translate(5.889 13.776)"
                  >
                    <g id="Group_294702" data-name="Group 294702">
                      <path
                        id="Path_158103"
                        data-name="Path 158103"
                        d="M44.55,79.945a4.442,4.442,0,0,1,0-8.884,4.255,4.255,0,0,1,.446.023.615.615,0,1,1-.127,1.224,3.122,3.122,0,0,0-.319-.016,3.211,3.211,0,0,0,0,6.421,2.853,2.853,0,0,0,.341-.021.616.616,0,0,1,.145,1.223,4.092,4.092,0,0,1-.486.03Z"
                        transform="translate(-40.108 -71.061)"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294705"
                    data-name="Group 294705"
                    transform="translate(17.118 23.181)"
                  >
                    <g id="Group_294704" data-name="Group 294704">
                      <path
                        id="Path_158104"
                        data-name="Path 158104"
                        d="M88.8,111.547a3.862,3.862,0,0,1-2.955-1.285.616.616,0,1,1,.944-.79,2.818,2.818,0,0,0,4.022,0,.616.616,0,0,1,.944.791A3.861,3.861,0,0,1,88.8,111.547Z"
                        transform="translate(-85.706 -109.252)"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294707"
                    data-name="Group 294707"
                    transform="translate(23.119 17.409)"
                  >
                    <g id="Group_294706" data-name="Group 294706">
                      <ellipse
                        id="Ellipse_3540"
                        data-name="Ellipse 3540"
                        cx="1.231"
                        cy="1.231"
                        rx="1.231"
                        ry="1.231"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294709"
                    data-name="Group 294709"
                    transform="translate(14.85 17.409)"
                  >
                    <g id="Group_294708" data-name="Group 294708">
                      <ellipse
                        id="Ellipse_3541"
                        data-name="Ellipse 3541"
                        cx="1.231"
                        cy="1.231"
                        rx="1.231"
                        ry="1.231"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294711"
                    data-name="Group 294711"
                    transform="translate(7.789)"
                  >
                    <g id="Group_294710" data-name="Group 294710">
                      <path
                        id="Path_158105"
                        data-name="Path 158105"
                        d="M48.44,30.5a.616.616,0,0,1-.616-.616V26.205a10.861,10.861,0,0,1,9.033-10.723c4.608-.795,8.444-.261,10.526,1.468a5.276,5.276,0,0,1,1.9,4.13c1.193.016,3.147.809,3.147,5.823v2.913a.616.616,0,1,1-1.231,0V26.9c0-2.918-.712-4.592-1.953-4.592a1.185,1.185,0,0,1-1.193-1.151A4.11,4.11,0,0,0,66.6,17.9c-1.8-1.5-5.277-1.936-9.53-1.2a9.632,9.632,0,0,0-8.012,9.51v3.678A.615.615,0,0,1,48.44,30.5Z"
                        transform="translate(-47.824 -15.122)"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294713"
                    data-name="Group 294713"
                    transform="translate(0.616 33.258)"
                  >
                    <g id="Group_294712" data-name="Group 294712">
                      <path
                        id="Path_158106"
                        data-name="Path 158106"
                        d="M18.7,174.2l1.194-16.727a4.358,4.358,0,0,1,2.641-3.712l8.293-3.529a.761.761,0,0,1,1.037.518l6.3,24.866H20.019A1.322,1.322,0,0,1,18.7,174.2Z"
                        transform="translate(-18.696 -150.173)"
                        fill="#754e34"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294715"
                    data-name="Group 294715"
                    transform="translate(20.083 33.258)"
                  >
                    <g id="Group_294714" data-name="Group 294714">
                      <path
                        id="Path_158107"
                        data-name="Path 158107"
                        d="M116.339,161.969h-.991a.631.631,0,0,1-.631-.631v-1.472a.615.615,0,0,0-.452-.594,8.7,8.7,0,0,1-2.059-.854.614.614,0,0,0-.741.1l-1.042,1.042a.632.632,0,0,1-.893,0l-1.708-1.708a.631.631,0,0,1,0-.893l1.042-1.042a.614.614,0,0,0,.1-.741,8.691,8.691,0,0,1-.854-2.058.614.614,0,0,0-.594-.452h-1.472a.631.631,0,0,1-.631-.631v-1.657l-.328-.139a.761.761,0,0,0-1.036.518l-6.3,24.866h18.145a1.323,1.323,0,0,0,1.319-1.417Z"
                        transform="translate(-97.748 -150.175)"
                        fill="#754e34"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294717"
                    data-name="Group 294717"
                    transform="translate(32.776 41.422)"
                  >
                    <g id="Group_294716" data-name="Group 294716">
                      <path
                        id="Path_158108"
                        data-name="Path 158108"
                        d="M155.189,186.955H154.2a.631.631,0,0,1-.631-.631v-1.472a.615.615,0,0,0-.452-.594,8.7,8.7,0,0,1-2.059-.854.608.608,0,0,0-.328-.079,6.369,6.369,0,0,0-1.436,4.026v13.255h5.451a1.322,1.322,0,0,0,1.319-1.417Z"
                        transform="translate(-149.292 -183.324)"
                        fill="#9b6b4b"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294719"
                    data-name="Group 294719"
                    transform="translate(0.616 38.26)"
                  >
                    <g id="Group_294718" data-name="Group 294718">
                      <path
                        id="Path_158109"
                        data-name="Path 158109"
                        d="M25.47,177.672a6.37,6.37,0,0,0-2.015-4.649l-2.71-2.539a4.352,4.352,0,0,0-.852,2.3L18.7,189.511a1.323,1.323,0,0,0,1.319,1.417H25.47Z"
                        transform="translate(-18.696 -170.484)"
                        fill="#9b6b4b"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294721"
                    data-name="Group 294721"
                    transform="translate(10.971 33.258)"
                  >
                    <g id="Group_294720" data-name="Group 294720">
                      <path
                        id="Path_158110"
                        data-name="Path 158110"
                        d="M63.56,150.753a.761.761,0,0,0-1.037-.518l-1.776.756v9.984a.454.454,0,0,0,.352.443l2.32.535a.455.455,0,0,1,.315.622l-1.052,2.454a1.2,1.2,0,0,0,.015.982l4.469,9.609H69.86Z"
                        transform="translate(-60.748 -150.173)"
                        fill="#9b6b4b"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294723"
                    data-name="Group 294723"
                    transform="translate(20.083 33.258)"
                  >
                    <g id="Group_294722" data-name="Group 294722">
                      <path
                        id="Path_158111"
                        data-name="Path 158111"
                        d="M106.043,152.664a.631.631,0,0,1-.631-.631v-1.657l-.328-.139a.761.761,0,0,0-1.036.518l-6.3,24.866h2.692l4.469-9.609a1.2,1.2,0,0,0,.015-.982l-1.052-2.454a.454.454,0,0,1,.315-.622l2.32-.535a.454.454,0,0,0,.352-.443v-8.311Z"
                        transform="translate(-97.748 -150.175)"
                        fill="#9b6b4b"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294725"
                    data-name="Group 294725"
                    transform="translate(13.311 32.07)"
                  >
                    <g id="Group_294724" data-name="Group 294724">
                      <path
                        id="Path_158112"
                        data-name="Path 158112"
                        d="M70.722,147.116l.87,3.435,1.544,1.869a.561.561,0,0,0,.845.023l2.009-2.176a.522.522,0,0,0-.005-.714l-3.863-4.061a.461.461,0,0,0-.645-.023l-1.227,1.12A.754.754,0,0,1,70.722,147.116Z"
                        transform="translate(-70.249 -145.348)"
                        fill="#edf4fc"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294727"
                    data-name="Group 294727"
                    transform="translate(20.975 32.07)"
                  >
                    <g id="Group_294726" data-name="Group 294726">
                      <path
                        id="Path_158113"
                        data-name="Path 158113"
                        d="M103.519,152.444a.561.561,0,0,0,.845-.023l1.544-1.869.87-3.435a.754.754,0,0,1,.472-.526l-1.227-1.12a.461.461,0,0,0-.645.023l-3.863,4.061a.522.522,0,0,0-.005.714Z"
                        transform="translate(-101.372 -145.349)"
                        fill="#edf4fc"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294730"
                    data-name="Group 294730"
                    transform="translate(14.654 37.272)"
                  >
                    <g id="Group_294729" data-name="Group 294729">
                      <g id="Group_294728" data-name="Group 294728">
                        <path
                          id="Path_158114"
                          data-name="Path 158114"
                          d="M85.016,168.341a.561.561,0,0,1-.845.023L83.094,167.2a2.393,2.393,0,0,1-3.926,0l-1.077,1.167a.561.561,0,0,1-.845-.023L75.7,166.472,81.131,187.9l5.429-21.432Z"
                          transform="translate(-75.702 -166.472)"
                          fill="#dae6f1"
                        />
                      </g>
                    </g>
                  </g>
                  <g
                    id="Group_294732"
                    data-name="Group 294732"
                    transform="translate(17.716 38.676)"
                  >
                    <g id="Group_294731" data-name="Group 294731">
                      <path
                        id="Path_158115"
                        data-name="Path 158115"
                        d="M90.472,192.079l.094-.125,2.3-9.1L91.74,172.175a2.381,2.381,0,0,1-2.473,0l-1.131,10.684Z"
                        transform="translate(-88.136 -172.175)"
                        fill="#fc657e"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294735"
                    data-name="Group 294735"
                    transform="translate(18.12 36.633)"
                  >
                    <g id="Group_294734" data-name="Group 294734">
                      <g id="Group_294733" data-name="Group 294733">
                        <path
                          id="Path_158116"
                          data-name="Path 158116"
                          d="M92.772,164.233a.521.521,0,0,1-.138-.355,9.109,9.109,0,0,1-1.026.064c-.257,0-.51-.014-.762-.035a.518.518,0,0,1-.136.327l-.932,1.01a2.392,2.392,0,0,0,3.926,0Z"
                          transform="translate(-89.778 -163.878)"
                          fill="#fc657e"
                        />
                      </g>
                    </g>
                  </g>
                  <g
                    id="Group_294737"
                    data-name="Group 294737"
                    transform="translate(0 32.642)"
                  >
                    <g id="Group_294736" data-name="Group 294736">
                      <path
                        id="Path_158117"
                        data-name="Path 158117"
                        d="M36.279,174.35H18.134a1.938,1.938,0,0,1-1.933-2.076l1.194-16.727a4.951,4.951,0,0,1,3.014-4.234l8.293-3.529a1.377,1.377,0,0,1,1.876.937l6.3,24.863a.616.616,0,0,1-.6.767Zm-18.85-1.989a.707.707,0,0,0,.705.757H35.488l-6.1-24.1a.144.144,0,0,0-.077-.1.142.142,0,0,0-.122,0l-8.293,3.529a3.725,3.725,0,0,0-2.268,3.187Z"
                        transform="translate(-16.196 -147.674)"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294739"
                    data-name="Group 294739"
                    transform="translate(23.69 29.037)"
                  >
                    <g id="Group_294738" data-name="Group 294738">
                      <path
                        id="Path_158118"
                        data-name="Path 158118"
                        d="M113.333,137a.616.616,0,0,1-.553-.345,3.8,3.8,0,0,1-.387-1.664v-1.34a.616.616,0,1,1,1.231,0v1.34a2.56,2.56,0,0,0,.261,1.121.616.616,0,0,1-.552.887Z"
                        transform="translate(-112.393 -133.032)"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294741"
                    data-name="Group 294741"
                    transform="translate(18.653 36.026)"
                  >
                    <g id="Group_294740" data-name="Group 294740">
                      <path
                        id="Path_158119"
                        data-name="Path 158119"
                        d="M93.237,162.7c-.222,0-.46-.01-.728-.031a.616.616,0,1,1,.095-1.228c.236.018.444.027.634.027a8.329,8.329,0,0,0,.884-.052.616.616,0,0,1,.131,1.224A9.415,9.415,0,0,1,93.237,162.7Z"
                        transform="translate(-91.94 -161.413)"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294743"
                    data-name="Group 294743"
                    transform="translate(14.953 29.055)"
                  >
                    <g id="Group_294742" data-name="Group 294742">
                      <path
                        id="Path_158120"
                        data-name="Path 158120"
                        d="M77.533,136.984a.616.616,0,0,1-.559-.874,2.527,2.527,0,0,0,.235-1.067v-1.322a.616.616,0,0,1,1.231,0v1.322a3.747,3.747,0,0,1-.349,1.583A.617.617,0,0,1,77.533,136.984Z"
                        transform="translate(-76.918 -133.106)"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294745"
                    data-name="Group 294745"
                    transform="translate(19.467 32.643)"
                  >
                    <g id="Group_294744" data-name="Group 294744">
                      <path
                        id="Path_158121"
                        data-name="Path 158121"
                        d="M114.008,174.351H95.864a.616.616,0,0,1-.6-.767l6.3-24.866a1.377,1.377,0,0,1,1.875-.933l.328.139a.616.616,0,0,1,.375.567v1.657l1.488.016a1.232,1.232,0,0,1,1.188.908,8.093,8.093,0,0,0,.794,1.912,1.232,1.232,0,0,1-.2,1.483l-1.042,1.042,1.708,1.731,1.064-1.042a1.231,1.231,0,0,1,1.483-.2,8.1,8.1,0,0,0,1.913.793,1.233,1.233,0,0,1,.907,1.188v1.472l1.006.016a.616.616,0,0,1,.614.572l.873,12.234a1.938,1.938,0,0,1-1.934,2.076ZM96.655,173.12h17.354a.707.707,0,0,0,.705-.757l-.832-11.662h-.417a1.248,1.248,0,0,1-1.247-1.247v-1.472a9.332,9.332,0,0,1-2.2-.914l-1.041,1.043a1.247,1.247,0,0,1-1.764,0L105.5,156.4a1.247,1.247,0,0,1,0-1.764l1.041-1.042a9.332,9.332,0,0,1-.914-2.2l-1.472,0a1.248,1.248,0,0,1-1.247-1.247v-1.242a.131.131,0,0,0-.075.015.142.142,0,0,0-.076.1Z"
                        transform="translate(-95.248 -147.674)"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294747"
                    data-name="Group 294747"
                    transform="translate(32.161 40.893)"
                  >
                    <g id="Group_294746" data-name="Group 294746">
                      <path
                        id="Path_158122"
                        data-name="Path 158122"
                        d="M147.409,199.327a.616.616,0,0,1-.616-.616V185.732a7.014,7.014,0,0,1,1.5-4.32.616.616,0,1,1,.967.762,5.779,5.779,0,0,0-1.234,3.558v12.978A.616.616,0,0,1,147.409,199.327Z"
                        transform="translate(-146.793 -181.178)"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294749"
                    data-name="Group 294749"
                    transform="translate(2.208 37.794)"
                  >
                    <g id="Group_294748" data-name="Group 294748">
                      <path
                        id="Path_158123"
                        data-name="Path 158123"
                        d="M30.344,189.941a.616.616,0,0,1-.616-.616v-13.08a5.776,5.776,0,0,0-1.82-4.2l-2.551-2.39a.616.616,0,1,1,.842-.9l2.551,2.39a7.01,7.01,0,0,1,2.21,5.1v13.08a.616.616,0,0,1-.616.616Z"
                        transform="translate(-25.163 -168.591)"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294751"
                    data-name="Group 294751"
                    transform="translate(10.356 33.708)"
                  >
                    <g id="Group_294750" data-name="Group 294750">
                      <path
                        id="Path_158124"
                        data-name="Path 158124"
                        d="M65.2,177.436a.616.616,0,0,1-.559-.356l-4.388-9.434a1.823,1.823,0,0,1-.023-1.484l.976-2.277-2.132-.492a1.065,1.065,0,0,1-.829-1.042v-9.735a.616.616,0,0,1,1.231,0v9.607l2.195.507a1.07,1.07,0,0,1,.743,1.464l-1.052,2.454a.588.588,0,0,0,.007.479l4.388,9.434a.615.615,0,0,1-.558.875Z"
                        transform="translate(-58.248 -152)"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294753"
                    data-name="Group 294753"
                    transform="translate(22.263 35.278)"
                  >
                    <g id="Group_294752" data-name="Group 294752">
                      <path
                        id="Path_158125"
                        data-name="Path 158125"
                        d="M107.217,182.192a.616.616,0,0,1-.558-.875l4.365-9.385a.587.587,0,0,0,.007-.479L109.98,169a1.07,1.07,0,0,1,.743-1.464l2.194-.507v-8.037a.616.616,0,0,1,1.231,0v8.165a1.065,1.065,0,0,1-.829,1.042l-2.132.492.976,2.277a1.82,1.82,0,0,1-.023,1.483l-4.365,9.385A.616.616,0,0,1,107.217,182.192Z"
                        transform="translate(-106.602 -158.375)"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294755"
                    data-name="Group 294755"
                    transform="translate(12.763 31.457)"
                  >
                    <g id="Group_294754" data-name="Group 294754">
                      <path
                        id="Path_158126"
                        data-name="Path 158126"
                        d="M71.892,151.363H71.86a1.177,1.177,0,0,1-.875-.427l-1.529-1.851a.616.616,0,1,1,.949-.784l1.489,1.8,1.907-2.066-3.7-3.887-1.048.956a.616.616,0,0,1-.83-.909l1.159-1.058a1.083,1.083,0,0,1,1.506.053l3.863,4.061a1.14,1.14,0,0,1,.012,1.556l-2.009,2.176A1.177,1.177,0,0,1,71.892,151.363Z"
                        transform="translate(-68.025 -142.859)"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294757"
                    data-name="Group 294757"
                    transform="translate(20.361 31.457)"
                  >
                    <g id="Group_294756" data-name="Group 294756">
                      <path
                        id="Path_158127"
                        data-name="Path 158127"
                        d="M102.051,151.363a1.177,1.177,0,0,1-.865-.378l-2.009-2.176a1.14,1.14,0,0,1,.012-1.556l3.863-4.061a1.082,1.082,0,0,1,1.506-.053l1.169,1.067a.616.616,0,0,1-.83.909l-1.057-.965-3.7,3.888,1.907,2.066,1.479-1.791a.616.616,0,1,1,.949.784l-1.519,1.839a1.178,1.178,0,0,1-.875.427Z"
                        transform="translate(-98.877 -142.859)"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294759"
                    data-name="Group 294759"
                    transform="translate(20.718 38.196)"
                  >
                    <g id="Group_294758" data-name="Group 294758">
                      <path
                        id="Path_158128"
                        data-name="Path 158128"
                        d="M102.051,181.941a.616.616,0,0,1-.611-.551l-1.109-10.484a.616.616,0,1,1,1.225-.129l1.109,10.484a.616.616,0,0,1-.547.677C102.095,181.94,102.073,181.941,102.051,181.941Z"
                        transform="translate(-100.327 -170.227)"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294761"
                    data-name="Group 294761"
                    transform="translate(17.111 38.166)"
                  >
                    <g id="Group_294760" data-name="Group 294760">
                      <path
                        id="Path_158129"
                        data-name="Path 158129"
                        d="M86.3,181.812c-.022,0-.044,0-.065,0a.616.616,0,0,1-.547-.677l1.108-10.477a.616.616,0,1,1,1.225.129l-1.108,10.477A.616.616,0,0,1,86.3,181.812Z"
                        transform="translate(-85.68 -170.105)"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294764"
                    data-name="Group 294764"
                    transform="translate(17.634 37.547)"
                  >
                    <g id="Group_294763" data-name="Group 294763">
                      <g id="Group_294762" data-name="Group 294762">
                        <path
                          id="Path_158130"
                          data-name="Path 158130"
                          d="M90.251,169.68a3,3,0,0,1-2.3-1.076.616.616,0,1,1,.943-.792,1.776,1.776,0,0,0,2.727,0,.616.616,0,0,1,.944.79,3.006,3.006,0,0,1-2.309,1.081Z"
                          transform="translate(-87.802 -167.589)"
                        />
                      </g>
                    </g>
                  </g>
                  <g
                    id="Group_294778"
                    data-name="Group 294778"
                    transform="translate(27.747 22.763)"
                  >
                    <g id="Group_294777" data-name="Group 294777">
                      <path
                        id="Path_158134"
                        data-name="Path 158134"
                        d="M129.5,116.862h1.472a.615.615,0,0,0,.594-.452,8.687,8.687,0,0,1,.854-2.058.614.614,0,0,0-.1-.741l-1.042-1.042a.632.632,0,0,1,0-.893l1.708-1.708a.632.632,0,0,1,.893,0l1.042,1.042a.615.615,0,0,0,.741.1,8.687,8.687,0,0,1,2.059-.854.615.615,0,0,0,.452-.594v-1.472a.631.631,0,0,1,.631-.631h2.416a.631.631,0,0,1,.631.631v1.472a.615.615,0,0,0,.452.594,8.688,8.688,0,0,1,2.059.854.614.614,0,0,0,.741-.1l1.042-1.042a.632.632,0,0,1,.893,0l1.708,1.708a.631.631,0,0,1,0,.893l-1.042,1.042a.615.615,0,0,0-.1.741,8.69,8.69,0,0,1,.854,2.058.614.614,0,0,0,.594.452h1.472a.631.631,0,0,1,.631.631v2.416a.631.631,0,0,1-.631.631h-1.039a.37.37,0,0,0-.05-.005h-1.492a.39.39,0,0,0-.39.39v.909a.379.379,0,0,1-.279.367,5.366,5.366,0,0,0-1.271.527.379.379,0,0,1-.457-.062l-.643-.643a.393.393,0,0,0-.1-.069,5.792,5.792,0,1,0-1.582,1.569.387.387,0,0,0,.074.107l.643.643a.379.379,0,0,1,.062.457,5.354,5.354,0,0,0-.527,1.271.379.379,0,0,1-.367.279H142.2a.39.39,0,0,0-.39.39v2.544a.6.6,0,0,1-.59.631H138.8a.631.631,0,0,1-.631-.631v-1.472a.615.615,0,0,0-.452-.594,8.7,8.7,0,0,1-2.059-.854.614.614,0,0,0-.741.1l-1.042,1.042a.632.632,0,0,1-.893,0l-1.708-1.708a.631.631,0,0,1,0-.893l1.042-1.042a.614.614,0,0,0,.1-.741,8.691,8.691,0,0,1-.854-2.058.614.614,0,0,0-.594-.452H129.5a.631.631,0,0,1-.631-.631v-2.416A.631.631,0,0,1,129.5,116.862Z"
                        transform="translate(-128.868 -107.557)"
                        fill="#fcdd86"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294780"
                    data-name="Group 294780"
                    transform="translate(33.098 28.115)"
                  >
                    <g
                      id="Group_294779"
                      data-name="Group 294779"
                      transform="translate(0)"
                    >
                      <path
                        id="Path_158135"
                        data-name="Path 158135"
                        d="M156.393,140.875a5.792,5.792,0,1,1,4.793-2.54.389.389,0,0,0-.453.069l-1.055,1.054a.388.388,0,0,0-.074.444A5.765,5.765,0,0,1,156.393,140.875Z"
                        transform="translate(-150.6 -129.29)"
                        fill="#f5c84c"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294782"
                    data-name="Group 294782"
                    transform="translate(40.689 35.743)"
                  >
                    <g
                      id="Group_294781"
                      data-name="Group 294781"
                      transform="translate(0)"
                    >
                      <path
                        id="Path_158136"
                        data-name="Path 158136"
                        d="M194.8,166.008h-.909a.379.379,0,0,1-.367-.279,5.385,5.385,0,0,0-.527-1.271.379.379,0,0,1,.062-.457l.643-.644a.39.39,0,0,0,0-.551l-1.055-1.054a.39.39,0,0,0-.551,0l-.643.643a.379.379,0,0,1-.457.062,5.368,5.368,0,0,0-1.271-.527.379.379,0,0,1-.279-.367v-.909a.39.39,0,0,0-.39-.39H187.56a.39.39,0,0,0-.39.39v.909a.379.379,0,0,1-.279.367,5.369,5.369,0,0,0-1.271.527.379.379,0,0,1-.457-.062l-.643-.643a.39.39,0,0,0-.551,0l-1.055,1.054a.39.39,0,0,0,0,.551l.643.644a.379.379,0,0,1,.062.457,5.354,5.354,0,0,0-.527,1.271.379.379,0,0,1-.367.279h-.909a.39.39,0,0,0-.39.39v1.492a.39.39,0,0,0,.39.39h.909a.379.379,0,0,1,.367.279,5.378,5.378,0,0,0,.527,1.271.379.379,0,0,1-.062.457l-.643.643a.39.39,0,0,0,0,.551l1.055,1.055a.39.39,0,0,0,.551,0l.643-.643a.379.379,0,0,1,.457-.062,5.379,5.379,0,0,0,1.271.527.379.379,0,0,1,.279.367v.909a.39.39,0,0,0,.39.39h1.492a.39.39,0,0,0,.39-.39v-.909a.379.379,0,0,1,.279-.367,5.378,5.378,0,0,0,1.271-.527.379.379,0,0,1,.457.062l.643.643a.39.39,0,0,0,.551,0l1.055-1.055a.39.39,0,0,0,0-.551l-.643-.643a.379.379,0,0,1-.062-.457,5.361,5.361,0,0,0,.527-1.271.379.379,0,0,1,.367-.279h.909a.39.39,0,0,0,.39-.39V166.4A.39.39,0,0,0,194.8,166.008Z"
                        transform="translate(-181.425 -160.263)"
                        fill="#70d6f9"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294784"
                    data-name="Group 294784"
                    transform="translate(44.533 39.586)"
                  >
                    <g id="Group_294783" data-name="Group 294783">
                      <path
                        id="Path_158137"
                        data-name="Path 158137"
                        d="M200.069,181.945a3.037,3.037,0,1,1,3.037-3.037A3.037,3.037,0,0,1,200.069,181.945Z"
                        transform="translate(-197.032 -175.871)"
                        fill="#3caadc"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294786"
                    data-name="Group 294786"
                    transform="translate(36.798 30.137)"
                  >
                    <g id="Group_294785" data-name="Group 294785">
                      <ellipse
                        id="Ellipse_3545"
                        data-name="Ellipse 3545"
                        cx="2.093"
                        cy="2.093"
                        rx="2.093"
                        ry="2.093"
                        fill="#f9d0b4"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294788"
                    data-name="Group 294788"
                    transform="translate(34.828 34.57)"
                  >
                    <g
                      id="Group_294787"
                      data-name="Group 294787"
                      transform="translate(0)"
                    >
                      <path
                        id="Path_158138"
                        data-name="Path 158138"
                        d="M159.723,155.5h3.926a2.107,2.107,0,0,1,2.1,2.1v.837l-.778.778a.389.389,0,0,0-.074.444,5.79,5.79,0,0,1-7.274-.694V157.6A2.107,2.107,0,0,1,159.723,155.5Z"
                        transform="translate(-157.623 -155.5)"
                        fill="#3a70bf"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294790"
                    data-name="Group 294790"
                    transform="translate(40.073 35.127)"
                  >
                    <g
                      id="Group_294789"
                      data-name="Group 294789"
                      transform="translate(0)"
                    >
                      <path
                        id="Path_158139"
                        data-name="Path 158139"
                        d="M187.164,172.755h-1.492a1.007,1.007,0,0,1-1.005-1.005v-.732a6.019,6.019,0,0,1-1.082-.449l-.518.518a1.008,1.008,0,0,1-1.422,0l-1.055-1.055a1.006,1.006,0,0,1,0-1.422l.518-.518a5.989,5.989,0,0,1-.449-1.082h-.732A1.007,1.007,0,0,1,178.922,166v-1.492a1.007,1.007,0,0,1,1.005-1.005h.732a6,6,0,0,1,.449-1.082l-.518-.518a1.007,1.007,0,0,1,0-1.422l1.055-1.055a1.005,1.005,0,0,1,1.422,0l.518.518a5.988,5.988,0,0,1,1.082-.449v-.732a1.007,1.007,0,0,1,1.005-1.005h1.492a1.007,1.007,0,0,1,1.006,1.005v.732a5.988,5.988,0,0,1,1.082.449l.518-.518a1.007,1.007,0,0,1,1.422,0l1.055,1.054a1.007,1.007,0,0,1,0,1.422l-.518.518a5.986,5.986,0,0,1,.449,1.082h.732a1.007,1.007,0,0,1,1.005,1.005V166a1.007,1.007,0,0,1-1.005,1.006h-.732a5.983,5.983,0,0,1-.449,1.082l.518.518a1.006,1.006,0,0,1,0,1.422l-1.055,1.055a1.007,1.007,0,0,1-1.421,0l-.519-.519a5.993,5.993,0,0,1-1.082.449v.732A1.007,1.007,0,0,1,187.164,172.755Zm-1.266-1.231h1.04v-.683a1,1,0,0,1,.734-.961,4.768,4.768,0,0,0,1.125-.467,1,1,0,0,1,1.2.16l.484.484.735-.735-.484-.484a1,1,0,0,1-.16-1.2,4.752,4.752,0,0,0,.467-1.125,1,1,0,0,1,.961-.734h.683v-1.04H192a1,1,0,0,1-.961-.734,4.763,4.763,0,0,0-.467-1.126,1,1,0,0,1,.16-1.2l.484-.484-.735-.735-.484.484a1,1,0,0,1-1.2.16,4.745,4.745,0,0,0-1.126-.467,1,1,0,0,1-.734-.961v-.683H185.9v.683a1,1,0,0,1-.734.961,4.765,4.765,0,0,0-1.125.467,1,1,0,0,1-1.2-.159l-.484-.484-.735.735.484.484a1,1,0,0,1,.159,1.2A4.769,4.769,0,0,0,181.8,164a1,1,0,0,1-.961.734h-.683v1.04h.683a1,1,0,0,1,.961.734,4.737,4.737,0,0,0,.467,1.125,1,1,0,0,1-.16,1.2l-.484.484.735.735.483-.484a1,1,0,0,1,1.2-.16,4.766,4.766,0,0,0,1.126.467,1,1,0,0,1,.734.961Zm-4.7-3.273h0Zm11.713-3.512h0ZM181.2,162.266h0Z"
                        transform="translate(-178.922 -157.762)"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294792"
                    data-name="Group 294792"
                    transform="translate(27.131 22.147)"
                  >
                    <g id="Group_294791" data-name="Group 294791">
                      <path
                        id="Path_158140"
                        data-name="Path 158140"
                        d="M139.334,128.577h-2.416a1.248,1.248,0,0,1-1.247-1.247v-1.472a9.323,9.323,0,0,1-2.2-.914l-1.041,1.043a1.248,1.248,0,0,1-1.763,0l-1.708-1.708a1.247,1.247,0,0,1,0-1.764L130,121.472a9.343,9.343,0,0,1-.914-2.2l-1.472,0a1.248,1.248,0,0,1-1.247-1.247v-2.416a1.248,1.248,0,0,1,1.247-1.247h1.472a9.314,9.314,0,0,1,.914-2.2l-1.043-1.041a1.249,1.249,0,0,1,0-1.764l1.709-1.708a1.246,1.246,0,0,1,1.764,0l1.042,1.041a9.324,9.324,0,0,1,2.2-.913l0-1.472a1.248,1.248,0,0,1,1.247-1.247h2.416a1.248,1.248,0,0,1,1.247,1.247v1.472a9.341,9.341,0,0,1,2.2.914l1.041-1.043a1.249,1.249,0,0,1,1.763,0l1.709,1.709a1.247,1.247,0,0,1,0,1.764l-1.041,1.042a9.337,9.337,0,0,1,.913,2.2l1.472,0a1.248,1.248,0,0,1,1.247,1.247v2.416a1.248,1.248,0,0,1-1.247,1.247H147.6a.616.616,0,0,1,0-1.231h1.039l.016-2.432-1.488-.016a1.232,1.232,0,0,1-1.188-.908,8.09,8.09,0,0,0-.794-1.913,1.232,1.232,0,0,1,.2-1.483l1.041-1.042-1.708-1.731-1.064,1.042a1.232,1.232,0,0,1-1.483.2,8.107,8.107,0,0,0-1.913-.793,1.233,1.233,0,0,1-.907-1.188V106.3l-2.432-.016-.016,1.488a1.233,1.233,0,0,1-.907,1.188,8.071,8.071,0,0,0-1.913.794,1.233,1.233,0,0,1-1.483-.2l-1.042-1.042-1.731,1.708,1.042,1.065a1.233,1.233,0,0,1,.2,1.483,8.1,8.1,0,0,0-.794,1.913,1.232,1.232,0,0,1-1.188.907h-1.472l-.016,2.432,1.488.016a1.232,1.232,0,0,1,1.188.908,8.087,8.087,0,0,0,.794,1.913,1.233,1.233,0,0,1-.2,1.483l-1.042,1.042,1.708,1.731,1.064-1.042a1.232,1.232,0,0,1,1.483-.2,8.088,8.088,0,0,0,1.913.794,1.233,1.233,0,0,1,.907,1.188v1.472l2.4.016a.138.138,0,0,0,0-.016v-.99a.616.616,0,1,1,1.231,0v.99a1.213,1.213,0,0,1-1.205,1.247Z"
                        transform="translate(-126.366 -105.056)"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294794"
                    data-name="Group 294794"
                    transform="translate(32.483 27.5)"
                  >
                    <g
                      id="Group_294793"
                      data-name="Group 294793"
                      transform="translate(0)"
                    >
                      <path
                        id="Path_158141"
                        data-name="Path 158141"
                        d="M154.508,139.607a6.408,6.408,0,1,1,5.3-2.809.616.616,0,1,1-1.018-.692,5.179,5.179,0,1,0-1.415,1.4.616.616,0,0,1,.684,1.024A6.377,6.377,0,0,1,154.508,139.607Z"
                        transform="translate(-148.1 -126.79)"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294796"
                    data-name="Group 294796"
                    transform="translate(43.917 38.971)"
                  >
                    <g id="Group_294795" data-name="Group 294795">
                      <path
                        id="Path_158142"
                        data-name="Path 158142"
                        d="M198.183,180.676a3.652,3.652,0,1,1,3.653-3.652A3.656,3.656,0,0,1,198.183,180.676Zm0-6.073a2.421,2.421,0,1,0,2.421,2.421A2.424,2.424,0,0,0,198.183,174.6Z"
                        transform="translate(-194.531 -173.371)"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294798"
                    data-name="Group 294798"
                    transform="translate(36.182 29.521)"
                  >
                    <g id="Group_294797" data-name="Group 294797">
                      <path
                        id="Path_158143"
                        data-name="Path 158143"
                        d="M165.832,140.418a2.709,2.709,0,1,1,2.709-2.709A2.712,2.712,0,0,1,165.832,140.418Zm0-4.186a1.478,1.478,0,1,0,1.478,1.478A1.479,1.479,0,0,0,165.832,136.231Z"
                        transform="translate(-163.123 -135)"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294800"
                    data-name="Group 294800"
                    transform="translate(34.212 33.954)"
                  >
                    <g
                      id="Group_294799"
                      data-name="Group 294799"
                      transform="translate(0)"
                    >
                      <path
                        id="Path_158144"
                        data-name="Path 158144"
                        d="M155.739,157.5a.616.616,0,0,1-.616-.616v-1.173A2.719,2.719,0,0,1,157.839,153h3.926a2.719,2.719,0,0,1,2.716,2.716v.724a.616.616,0,0,1-1.231,0v-.724a1.487,1.487,0,0,0-1.485-1.485h-3.926a1.486,1.486,0,0,0-1.485,1.485v1.173A.616.616,0,0,1,155.739,157.5Z"
                        transform="translate(-155.123 -153)"
                      />
                    </g>
                  </g>
                </g>
              </svg>
              <div className="p-2">
                <h5 className="font-bold text-[19px]">Career Guidance</h5>
                <h6 className="text-gray-500 mt-0.5">
                  {" "}
                  Avail best plan for career{" "}
                </h6>
              </div>
            </a>
          </li>
          <li>
            <a
              href="#"
              className="inline-flex items-center px-4 py-2.5 text-black hover:bg-blue-100 rounded-lg active w-full "
              aria-current="page"
            >
              <svg
                id="Layer_73"
                xmlns="http://www.w3.org/2000/svg"
                width="55.066"
                height="59.319"
                viewBox="0 0 55.066 59.319"
              >
                <g
                  id="Group_294801"
                  data-name="Group 294801"
                  transform="translate(0)"
                >
                  <g
                    id="Group_294689"
                    data-name="Group 294689"
                    transform="translate(15.47 29.501)"
                  >
                    <g id="Group_294688" data-name="Group 294688">
                      <path
                        id="Path_158096"
                        data-name="Path 158096"
                        d="M87.85,134.916a9.091,9.091,0,0,1-8.444,0v1.492a3.16,3.16,0,0,1-.391,1.522l3.577,3.76a.518.518,0,0,1,.141.387c.252.021.505.035.762.035a9.058,9.058,0,0,0,1.026-.064h0a.521.521,0,0,1,.144-.36l3.577-3.76a3.16,3.16,0,0,1-.391-1.522v-1.491Z"
                        transform="translate(-79.014 -134.916)"
                        fill="#f9d0b4"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294691"
                    data-name="Group 294691"
                    transform="translate(10.918 9.895)"
                  >
                    <g id="Group_294690" data-name="Group 294690">
                      <path
                        id="Path_158097"
                        data-name="Path 158097"
                        d="M77.553,55.823a1.679,1.679,0,0,0-2.257-.15,9.2,9.2,0,0,1-11.2-.006,1.678,1.678,0,0,0-2.253.153l0,0a4.738,4.738,0,0,0-1.309,3.27v7.691a9.165,9.165,0,0,0,18.329,0V59.093a4.738,4.738,0,0,0-1.309-3.27Z"
                        transform="translate(-60.533 -55.304)"
                        fill="#f9d0b4"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294693"
                    data-name="Group 294693"
                    transform="translate(29.224 14.391)"
                  >
                    <g id="Group_294692" data-name="Group 294692">
                      <path
                        id="Path_158098"
                        data-name="Path 158098"
                        d="M135.48,73.561a3.855,3.855,0,0,0-.588.047v6.938c0,.207-.009.411-.023.615a3.824,3.824,0,1,0,.611-7.6Z"
                        transform="translate(-134.869 -73.561)"
                        fill="#f9d0b4"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294695"
                    data-name="Group 294695"
                    transform="translate(6.504 14.391)"
                  >
                    <g id="Group_294694" data-name="Group 294694">
                      <path
                        id="Path_158099"
                        data-name="Path 158099"
                        d="M47.021,80.545V73.608a3.828,3.828,0,1,0,.023,7.553h0C47.031,80.957,47.021,80.752,47.021,80.545Z"
                        transform="translate(-42.607 -73.561)"
                        fill="#f9d0b4"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294697"
                    data-name="Group 294697"
                    transform="translate(8.404 0.613)"
                  >
                    <g id="Group_294696" data-name="Group 294696">
                      <path
                        id="Path_158100"
                        data-name="Path 158100"
                        d="M71.132,23.572a.567.567,0,0,1-.577-.543c-.07-5.107-5.9-6.066-11.709-5.063a10.268,10.268,0,0,0-8.523,10.117v3.832a3.787,3.787,0,0,1,2.514-.478v-.753a4.739,4.739,0,0,1,1.309-3.27l0,0a1.678,1.678,0,0,1,2.253-.153,9.2,9.2,0,0,0,11.2.006,1.679,1.679,0,0,1,2.257.15,4.738,4.738,0,0,1,1.309,3.27v.753a3.786,3.786,0,0,1,2.534.49V28.78c0-4.573-1.651-5.208-2.568-5.208Z"
                        transform="translate(-50.323 -17.611)"
                        fill="#5b5d60"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294699"
                    data-name="Group 294699"
                    transform="translate(10.303 9.278)"
                  >
                    <g id="Group_294698" data-name="Group 294698">
                      <path
                        id="Path_158101"
                        data-name="Path 158101"
                        d="M67.813,74.676a9.791,9.791,0,0,1-9.78-9.781V57.2a5.331,5.331,0,0,1,1.479-3.695,2.289,2.289,0,0,1,3.081-.216,8.588,8.588,0,0,0,10.438.007,2.29,2.29,0,0,1,3.083.21h0A5.332,5.332,0,0,1,77.594,57.2V64.9A9.792,9.792,0,0,1,67.813,74.676ZM61.167,54.03a1.046,1.046,0,0,0-.76.325A4.107,4.107,0,0,0,59.264,57.2V64.9a8.549,8.549,0,1,0,17.1,0V57.2a4.106,4.106,0,0,0-1.139-2.845h0a1.062,1.062,0,0,0-1.431-.091,9.82,9.82,0,0,1-11.958-.005A1.082,1.082,0,0,0,61.167,54.03Z"
                        transform="translate(-58.033 -52.798)"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294701"
                    data-name="Group 294701"
                    transform="translate(28.833 13.775)"
                  >
                    <g id="Group_294700" data-name="Group 294700">
                      <path
                        id="Path_158102"
                        data-name="Path 158102"
                        d="M134.283,79.943a4.06,4.06,0,0,1-.447-.026.616.616,0,0,1,.134-1.224,2.838,2.838,0,0,0,.313.018,3.211,3.211,0,1,0,0-6.421,3.155,3.155,0,0,0-.323.017.616.616,0,1,1-.127-1.225,4.364,4.364,0,0,1,.45-.024,4.442,4.442,0,0,1,0,8.884Z"
                        transform="translate(-133.28 -71.059)"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294703"
                    data-name="Group 294703"
                    transform="translate(5.889 13.776)"
                  >
                    <g id="Group_294702" data-name="Group 294702">
                      <path
                        id="Path_158103"
                        data-name="Path 158103"
                        d="M44.55,79.945a4.442,4.442,0,0,1,0-8.884,4.255,4.255,0,0,1,.446.023.615.615,0,1,1-.127,1.224,3.122,3.122,0,0,0-.319-.016,3.211,3.211,0,0,0,0,6.421,2.853,2.853,0,0,0,.341-.021.616.616,0,0,1,.145,1.223,4.092,4.092,0,0,1-.486.03Z"
                        transform="translate(-40.108 -71.061)"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294705"
                    data-name="Group 294705"
                    transform="translate(17.118 23.181)"
                  >
                    <g id="Group_294704" data-name="Group 294704">
                      <path
                        id="Path_158104"
                        data-name="Path 158104"
                        d="M88.8,111.547a3.862,3.862,0,0,1-2.955-1.285.616.616,0,1,1,.944-.79,2.818,2.818,0,0,0,4.022,0,.616.616,0,0,1,.944.791A3.861,3.861,0,0,1,88.8,111.547Z"
                        transform="translate(-85.706 -109.252)"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294707"
                    data-name="Group 294707"
                    transform="translate(23.119 17.409)"
                  >
                    <g id="Group_294706" data-name="Group 294706">
                      <ellipse
                        id="Ellipse_3540"
                        data-name="Ellipse 3540"
                        cx="1.231"
                        cy="1.231"
                        rx="1.231"
                        ry="1.231"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294709"
                    data-name="Group 294709"
                    transform="translate(14.85 17.409)"
                  >
                    <g id="Group_294708" data-name="Group 294708">
                      <ellipse
                        id="Ellipse_3541"
                        data-name="Ellipse 3541"
                        cx="1.231"
                        cy="1.231"
                        rx="1.231"
                        ry="1.231"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294711"
                    data-name="Group 294711"
                    transform="translate(7.789)"
                  >
                    <g id="Group_294710" data-name="Group 294710">
                      <path
                        id="Path_158105"
                        data-name="Path 158105"
                        d="M48.44,30.5a.616.616,0,0,1-.616-.616V26.205a10.861,10.861,0,0,1,9.033-10.723c4.608-.795,8.444-.261,10.526,1.468a5.276,5.276,0,0,1,1.9,4.13c1.193.016,3.147.809,3.147,5.823v2.913a.616.616,0,1,1-1.231,0V26.9c0-2.918-.712-4.592-1.953-4.592a1.185,1.185,0,0,1-1.193-1.151A4.11,4.11,0,0,0,66.6,17.9c-1.8-1.5-5.277-1.936-9.53-1.2a9.632,9.632,0,0,0-8.012,9.51v3.678A.615.615,0,0,1,48.44,30.5Z"
                        transform="translate(-47.824 -15.122)"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294713"
                    data-name="Group 294713"
                    transform="translate(0.616 33.258)"
                  >
                    <g id="Group_294712" data-name="Group 294712">
                      <path
                        id="Path_158106"
                        data-name="Path 158106"
                        d="M18.7,174.2l1.194-16.727a4.358,4.358,0,0,1,2.641-3.712l8.293-3.529a.761.761,0,0,1,1.037.518l6.3,24.866H20.019A1.322,1.322,0,0,1,18.7,174.2Z"
                        transform="translate(-18.696 -150.173)"
                        fill="#754e34"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294715"
                    data-name="Group 294715"
                    transform="translate(20.083 33.258)"
                  >
                    <g id="Group_294714" data-name="Group 294714">
                      <path
                        id="Path_158107"
                        data-name="Path 158107"
                        d="M116.339,161.969h-.991a.631.631,0,0,1-.631-.631v-1.472a.615.615,0,0,0-.452-.594,8.7,8.7,0,0,1-2.059-.854.614.614,0,0,0-.741.1l-1.042,1.042a.632.632,0,0,1-.893,0l-1.708-1.708a.631.631,0,0,1,0-.893l1.042-1.042a.614.614,0,0,0,.1-.741,8.691,8.691,0,0,1-.854-2.058.614.614,0,0,0-.594-.452h-1.472a.631.631,0,0,1-.631-.631v-1.657l-.328-.139a.761.761,0,0,0-1.036.518l-6.3,24.866h18.145a1.323,1.323,0,0,0,1.319-1.417Z"
                        transform="translate(-97.748 -150.175)"
                        fill="#754e34"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294717"
                    data-name="Group 294717"
                    transform="translate(32.776 41.422)"
                  >
                    <g id="Group_294716" data-name="Group 294716">
                      <path
                        id="Path_158108"
                        data-name="Path 158108"
                        d="M155.189,186.955H154.2a.631.631,0,0,1-.631-.631v-1.472a.615.615,0,0,0-.452-.594,8.7,8.7,0,0,1-2.059-.854.608.608,0,0,0-.328-.079,6.369,6.369,0,0,0-1.436,4.026v13.255h5.451a1.322,1.322,0,0,0,1.319-1.417Z"
                        transform="translate(-149.292 -183.324)"
                        fill="#9b6b4b"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294719"
                    data-name="Group 294719"
                    transform="translate(0.616 38.26)"
                  >
                    <g id="Group_294718" data-name="Group 294718">
                      <path
                        id="Path_158109"
                        data-name="Path 158109"
                        d="M25.47,177.672a6.37,6.37,0,0,0-2.015-4.649l-2.71-2.539a4.352,4.352,0,0,0-.852,2.3L18.7,189.511a1.323,1.323,0,0,0,1.319,1.417H25.47Z"
                        transform="translate(-18.696 -170.484)"
                        fill="#9b6b4b"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294721"
                    data-name="Group 294721"
                    transform="translate(10.971 33.258)"
                  >
                    <g id="Group_294720" data-name="Group 294720">
                      <path
                        id="Path_158110"
                        data-name="Path 158110"
                        d="M63.56,150.753a.761.761,0,0,0-1.037-.518l-1.776.756v9.984a.454.454,0,0,0,.352.443l2.32.535a.455.455,0,0,1,.315.622l-1.052,2.454a1.2,1.2,0,0,0,.015.982l4.469,9.609H69.86Z"
                        transform="translate(-60.748 -150.173)"
                        fill="#9b6b4b"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294723"
                    data-name="Group 294723"
                    transform="translate(20.083 33.258)"
                  >
                    <g id="Group_294722" data-name="Group 294722">
                      <path
                        id="Path_158111"
                        data-name="Path 158111"
                        d="M106.043,152.664a.631.631,0,0,1-.631-.631v-1.657l-.328-.139a.761.761,0,0,0-1.036.518l-6.3,24.866h2.692l4.469-9.609a1.2,1.2,0,0,0,.015-.982l-1.052-2.454a.454.454,0,0,1,.315-.622l2.32-.535a.454.454,0,0,0,.352-.443v-8.311Z"
                        transform="translate(-97.748 -150.175)"
                        fill="#9b6b4b"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294725"
                    data-name="Group 294725"
                    transform="translate(13.311 32.07)"
                  >
                    <g id="Group_294724" data-name="Group 294724">
                      <path
                        id="Path_158112"
                        data-name="Path 158112"
                        d="M70.722,147.116l.87,3.435,1.544,1.869a.561.561,0,0,0,.845.023l2.009-2.176a.522.522,0,0,0-.005-.714l-3.863-4.061a.461.461,0,0,0-.645-.023l-1.227,1.12A.754.754,0,0,1,70.722,147.116Z"
                        transform="translate(-70.249 -145.348)"
                        fill="#edf4fc"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294727"
                    data-name="Group 294727"
                    transform="translate(20.975 32.07)"
                  >
                    <g id="Group_294726" data-name="Group 294726">
                      <path
                        id="Path_158113"
                        data-name="Path 158113"
                        d="M103.519,152.444a.561.561,0,0,0,.845-.023l1.544-1.869.87-3.435a.754.754,0,0,1,.472-.526l-1.227-1.12a.461.461,0,0,0-.645.023l-3.863,4.061a.522.522,0,0,0-.005.714Z"
                        transform="translate(-101.372 -145.349)"
                        fill="#edf4fc"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294730"
                    data-name="Group 294730"
                    transform="translate(14.654 37.272)"
                  >
                    <g id="Group_294729" data-name="Group 294729">
                      <g id="Group_294728" data-name="Group 294728">
                        <path
                          id="Path_158114"
                          data-name="Path 158114"
                          d="M85.016,168.341a.561.561,0,0,1-.845.023L83.094,167.2a2.393,2.393,0,0,1-3.926,0l-1.077,1.167a.561.561,0,0,1-.845-.023L75.7,166.472,81.131,187.9l5.429-21.432Z"
                          transform="translate(-75.702 -166.472)"
                          fill="#dae6f1"
                        />
                      </g>
                    </g>
                  </g>
                  <g
                    id="Group_294732"
                    data-name="Group 294732"
                    transform="translate(17.716 38.676)"
                  >
                    <g id="Group_294731" data-name="Group 294731">
                      <path
                        id="Path_158115"
                        data-name="Path 158115"
                        d="M90.472,192.079l.094-.125,2.3-9.1L91.74,172.175a2.381,2.381,0,0,1-2.473,0l-1.131,10.684Z"
                        transform="translate(-88.136 -172.175)"
                        fill="#fc657e"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294735"
                    data-name="Group 294735"
                    transform="translate(18.12 36.633)"
                  >
                    <g id="Group_294734" data-name="Group 294734">
                      <g id="Group_294733" data-name="Group 294733">
                        <path
                          id="Path_158116"
                          data-name="Path 158116"
                          d="M92.772,164.233a.521.521,0,0,1-.138-.355,9.109,9.109,0,0,1-1.026.064c-.257,0-.51-.014-.762-.035a.518.518,0,0,1-.136.327l-.932,1.01a2.392,2.392,0,0,0,3.926,0Z"
                          transform="translate(-89.778 -163.878)"
                          fill="#fc657e"
                        />
                      </g>
                    </g>
                  </g>
                  <g
                    id="Group_294737"
                    data-name="Group 294737"
                    transform="translate(0 32.642)"
                  >
                    <g id="Group_294736" data-name="Group 294736">
                      <path
                        id="Path_158117"
                        data-name="Path 158117"
                        d="M36.279,174.35H18.134a1.938,1.938,0,0,1-1.933-2.076l1.194-16.727a4.951,4.951,0,0,1,3.014-4.234l8.293-3.529a1.377,1.377,0,0,1,1.876.937l6.3,24.863a.616.616,0,0,1-.6.767Zm-18.85-1.989a.707.707,0,0,0,.705.757H35.488l-6.1-24.1a.144.144,0,0,0-.077-.1.142.142,0,0,0-.122,0l-8.293,3.529a3.725,3.725,0,0,0-2.268,3.187Z"
                        transform="translate(-16.196 -147.674)"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294739"
                    data-name="Group 294739"
                    transform="translate(23.69 29.037)"
                  >
                    <g id="Group_294738" data-name="Group 294738">
                      <path
                        id="Path_158118"
                        data-name="Path 158118"
                        d="M113.333,137a.616.616,0,0,1-.553-.345,3.8,3.8,0,0,1-.387-1.664v-1.34a.616.616,0,1,1,1.231,0v1.34a2.56,2.56,0,0,0,.261,1.121.616.616,0,0,1-.552.887Z"
                        transform="translate(-112.393 -133.032)"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294741"
                    data-name="Group 294741"
                    transform="translate(18.653 36.026)"
                  >
                    <g id="Group_294740" data-name="Group 294740">
                      <path
                        id="Path_158119"
                        data-name="Path 158119"
                        d="M93.237,162.7c-.222,0-.46-.01-.728-.031a.616.616,0,1,1,.095-1.228c.236.018.444.027.634.027a8.329,8.329,0,0,0,.884-.052.616.616,0,0,1,.131,1.224A9.415,9.415,0,0,1,93.237,162.7Z"
                        transform="translate(-91.94 -161.413)"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294743"
                    data-name="Group 294743"
                    transform="translate(14.953 29.055)"
                  >
                    <g id="Group_294742" data-name="Group 294742">
                      <path
                        id="Path_158120"
                        data-name="Path 158120"
                        d="M77.533,136.984a.616.616,0,0,1-.559-.874,2.527,2.527,0,0,0,.235-1.067v-1.322a.616.616,0,0,1,1.231,0v1.322a3.747,3.747,0,0,1-.349,1.583A.617.617,0,0,1,77.533,136.984Z"
                        transform="translate(-76.918 -133.106)"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294745"
                    data-name="Group 294745"
                    transform="translate(19.467 32.643)"
                  >
                    <g id="Group_294744" data-name="Group 294744">
                      <path
                        id="Path_158121"
                        data-name="Path 158121"
                        d="M114.008,174.351H95.864a.616.616,0,0,1-.6-.767l6.3-24.866a1.377,1.377,0,0,1,1.875-.933l.328.139a.616.616,0,0,1,.375.567v1.657l1.488.016a1.232,1.232,0,0,1,1.188.908,8.093,8.093,0,0,0,.794,1.912,1.232,1.232,0,0,1-.2,1.483l-1.042,1.042,1.708,1.731,1.064-1.042a1.231,1.231,0,0,1,1.483-.2,8.1,8.1,0,0,0,1.913.793,1.233,1.233,0,0,1,.907,1.188v1.472l1.006.016a.616.616,0,0,1,.614.572l.873,12.234a1.938,1.938,0,0,1-1.934,2.076ZM96.655,173.12h17.354a.707.707,0,0,0,.705-.757l-.832-11.662h-.417a1.248,1.248,0,0,1-1.247-1.247v-1.472a9.332,9.332,0,0,1-2.2-.914l-1.041,1.043a1.247,1.247,0,0,1-1.764,0L105.5,156.4a1.247,1.247,0,0,1,0-1.764l1.041-1.042a9.332,9.332,0,0,1-.914-2.2l-1.472,0a1.248,1.248,0,0,1-1.247-1.247v-1.242a.131.131,0,0,0-.075.015.142.142,0,0,0-.076.1Z"
                        transform="translate(-95.248 -147.674)"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294747"
                    data-name="Group 294747"
                    transform="translate(32.161 40.893)"
                  >
                    <g id="Group_294746" data-name="Group 294746">
                      <path
                        id="Path_158122"
                        data-name="Path 158122"
                        d="M147.409,199.327a.616.616,0,0,1-.616-.616V185.732a7.014,7.014,0,0,1,1.5-4.32.616.616,0,1,1,.967.762,5.779,5.779,0,0,0-1.234,3.558v12.978A.616.616,0,0,1,147.409,199.327Z"
                        transform="translate(-146.793 -181.178)"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294749"
                    data-name="Group 294749"
                    transform="translate(2.208 37.794)"
                  >
                    <g id="Group_294748" data-name="Group 294748">
                      <path
                        id="Path_158123"
                        data-name="Path 158123"
                        d="M30.344,189.941a.616.616,0,0,1-.616-.616v-13.08a5.776,5.776,0,0,0-1.82-4.2l-2.551-2.39a.616.616,0,1,1,.842-.9l2.551,2.39a7.01,7.01,0,0,1,2.21,5.1v13.08a.616.616,0,0,1-.616.616Z"
                        transform="translate(-25.163 -168.591)"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294751"
                    data-name="Group 294751"
                    transform="translate(10.356 33.708)"
                  >
                    <g id="Group_294750" data-name="Group 294750">
                      <path
                        id="Path_158124"
                        data-name="Path 158124"
                        d="M65.2,177.436a.616.616,0,0,1-.559-.356l-4.388-9.434a1.823,1.823,0,0,1-.023-1.484l.976-2.277-2.132-.492a1.065,1.065,0,0,1-.829-1.042v-9.735a.616.616,0,0,1,1.231,0v9.607l2.195.507a1.07,1.07,0,0,1,.743,1.464l-1.052,2.454a.588.588,0,0,0,.007.479l4.388,9.434a.615.615,0,0,1-.558.875Z"
                        transform="translate(-58.248 -152)"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294753"
                    data-name="Group 294753"
                    transform="translate(22.263 35.278)"
                  >
                    <g id="Group_294752" data-name="Group 294752">
                      <path
                        id="Path_158125"
                        data-name="Path 158125"
                        d="M107.217,182.192a.616.616,0,0,1-.558-.875l4.365-9.385a.587.587,0,0,0,.007-.479L109.98,169a1.07,1.07,0,0,1,.743-1.464l2.194-.507v-8.037a.616.616,0,0,1,1.231,0v8.165a1.065,1.065,0,0,1-.829,1.042l-2.132.492.976,2.277a1.82,1.82,0,0,1-.023,1.483l-4.365,9.385A.616.616,0,0,1,107.217,182.192Z"
                        transform="translate(-106.602 -158.375)"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294755"
                    data-name="Group 294755"
                    transform="translate(12.763 31.457)"
                  >
                    <g id="Group_294754" data-name="Group 294754">
                      <path
                        id="Path_158126"
                        data-name="Path 158126"
                        d="M71.892,151.363H71.86a1.177,1.177,0,0,1-.875-.427l-1.529-1.851a.616.616,0,1,1,.949-.784l1.489,1.8,1.907-2.066-3.7-3.887-1.048.956a.616.616,0,0,1-.83-.909l1.159-1.058a1.083,1.083,0,0,1,1.506.053l3.863,4.061a1.14,1.14,0,0,1,.012,1.556l-2.009,2.176A1.177,1.177,0,0,1,71.892,151.363Z"
                        transform="translate(-68.025 -142.859)"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294757"
                    data-name="Group 294757"
                    transform="translate(20.361 31.457)"
                  >
                    <g id="Group_294756" data-name="Group 294756">
                      <path
                        id="Path_158127"
                        data-name="Path 158127"
                        d="M102.051,151.363a1.177,1.177,0,0,1-.865-.378l-2.009-2.176a1.14,1.14,0,0,1,.012-1.556l3.863-4.061a1.082,1.082,0,0,1,1.506-.053l1.169,1.067a.616.616,0,0,1-.83.909l-1.057-.965-3.7,3.888,1.907,2.066,1.479-1.791a.616.616,0,1,1,.949.784l-1.519,1.839a1.178,1.178,0,0,1-.875.427Z"
                        transform="translate(-98.877 -142.859)"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294759"
                    data-name="Group 294759"
                    transform="translate(20.718 38.196)"
                  >
                    <g id="Group_294758" data-name="Group 294758">
                      <path
                        id="Path_158128"
                        data-name="Path 158128"
                        d="M102.051,181.941a.616.616,0,0,1-.611-.551l-1.109-10.484a.616.616,0,1,1,1.225-.129l1.109,10.484a.616.616,0,0,1-.547.677C102.095,181.94,102.073,181.941,102.051,181.941Z"
                        transform="translate(-100.327 -170.227)"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294761"
                    data-name="Group 294761"
                    transform="translate(17.111 38.166)"
                  >
                    <g id="Group_294760" data-name="Group 294760">
                      <path
                        id="Path_158129"
                        data-name="Path 158129"
                        d="M86.3,181.812c-.022,0-.044,0-.065,0a.616.616,0,0,1-.547-.677l1.108-10.477a.616.616,0,1,1,1.225.129l-1.108,10.477A.616.616,0,0,1,86.3,181.812Z"
                        transform="translate(-85.68 -170.105)"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294764"
                    data-name="Group 294764"
                    transform="translate(17.634 37.547)"
                  >
                    <g id="Group_294763" data-name="Group 294763">
                      <g id="Group_294762" data-name="Group 294762">
                        <path
                          id="Path_158130"
                          data-name="Path 158130"
                          d="M90.251,169.68a3,3,0,0,1-2.3-1.076.616.616,0,1,1,.943-.792,1.776,1.776,0,0,0,2.727,0,.616.616,0,0,1,.944.79,3.006,3.006,0,0,1-2.309,1.081Z"
                          transform="translate(-87.802 -167.589)"
                        />
                      </g>
                    </g>
                  </g>
                  <g
                    id="Group_294778"
                    data-name="Group 294778"
                    transform="translate(27.747 22.763)"
                  >
                    <g id="Group_294777" data-name="Group 294777">
                      <path
                        id="Path_158134"
                        data-name="Path 158134"
                        d="M129.5,116.862h1.472a.615.615,0,0,0,.594-.452,8.687,8.687,0,0,1,.854-2.058.614.614,0,0,0-.1-.741l-1.042-1.042a.632.632,0,0,1,0-.893l1.708-1.708a.632.632,0,0,1,.893,0l1.042,1.042a.615.615,0,0,0,.741.1,8.687,8.687,0,0,1,2.059-.854.615.615,0,0,0,.452-.594v-1.472a.631.631,0,0,1,.631-.631h2.416a.631.631,0,0,1,.631.631v1.472a.615.615,0,0,0,.452.594,8.688,8.688,0,0,1,2.059.854.614.614,0,0,0,.741-.1l1.042-1.042a.632.632,0,0,1,.893,0l1.708,1.708a.631.631,0,0,1,0,.893l-1.042,1.042a.615.615,0,0,0-.1.741,8.69,8.69,0,0,1,.854,2.058.614.614,0,0,0,.594.452h1.472a.631.631,0,0,1,.631.631v2.416a.631.631,0,0,1-.631.631h-1.039a.37.37,0,0,0-.05-.005h-1.492a.39.39,0,0,0-.39.39v.909a.379.379,0,0,1-.279.367,5.366,5.366,0,0,0-1.271.527.379.379,0,0,1-.457-.062l-.643-.643a.393.393,0,0,0-.1-.069,5.792,5.792,0,1,0-1.582,1.569.387.387,0,0,0,.074.107l.643.643a.379.379,0,0,1,.062.457,5.354,5.354,0,0,0-.527,1.271.379.379,0,0,1-.367.279H142.2a.39.39,0,0,0-.39.39v2.544a.6.6,0,0,1-.59.631H138.8a.631.631,0,0,1-.631-.631v-1.472a.615.615,0,0,0-.452-.594,8.7,8.7,0,0,1-2.059-.854.614.614,0,0,0-.741.1l-1.042,1.042a.632.632,0,0,1-.893,0l-1.708-1.708a.631.631,0,0,1,0-.893l1.042-1.042a.614.614,0,0,0,.1-.741,8.691,8.691,0,0,1-.854-2.058.614.614,0,0,0-.594-.452H129.5a.631.631,0,0,1-.631-.631v-2.416A.631.631,0,0,1,129.5,116.862Z"
                        transform="translate(-128.868 -107.557)"
                        fill="#fcdd86"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294780"
                    data-name="Group 294780"
                    transform="translate(33.098 28.115)"
                  >
                    <g
                      id="Group_294779"
                      data-name="Group 294779"
                      transform="translate(0)"
                    >
                      <path
                        id="Path_158135"
                        data-name="Path 158135"
                        d="M156.393,140.875a5.792,5.792,0,1,1,4.793-2.54.389.389,0,0,0-.453.069l-1.055,1.054a.388.388,0,0,0-.074.444A5.765,5.765,0,0,1,156.393,140.875Z"
                        transform="translate(-150.6 -129.29)"
                        fill="#f5c84c"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294782"
                    data-name="Group 294782"
                    transform="translate(40.689 35.743)"
                  >
                    <g
                      id="Group_294781"
                      data-name="Group 294781"
                      transform="translate(0)"
                    >
                      <path
                        id="Path_158136"
                        data-name="Path 158136"
                        d="M194.8,166.008h-.909a.379.379,0,0,1-.367-.279,5.385,5.385,0,0,0-.527-1.271.379.379,0,0,1,.062-.457l.643-.644a.39.39,0,0,0,0-.551l-1.055-1.054a.39.39,0,0,0-.551,0l-.643.643a.379.379,0,0,1-.457.062,5.368,5.368,0,0,0-1.271-.527.379.379,0,0,1-.279-.367v-.909a.39.39,0,0,0-.39-.39H187.56a.39.39,0,0,0-.39.39v.909a.379.379,0,0,1-.279.367,5.369,5.369,0,0,0-1.271.527.379.379,0,0,1-.457-.062l-.643-.643a.39.39,0,0,0-.551,0l-1.055,1.054a.39.39,0,0,0,0,.551l.643.644a.379.379,0,0,1,.062.457,5.354,5.354,0,0,0-.527,1.271.379.379,0,0,1-.367.279h-.909a.39.39,0,0,0-.39.39v1.492a.39.39,0,0,0,.39.39h.909a.379.379,0,0,1,.367.279,5.378,5.378,0,0,0,.527,1.271.379.379,0,0,1-.062.457l-.643.643a.39.39,0,0,0,0,.551l1.055,1.055a.39.39,0,0,0,.551,0l.643-.643a.379.379,0,0,1,.457-.062,5.379,5.379,0,0,0,1.271.527.379.379,0,0,1,.279.367v.909a.39.39,0,0,0,.39.39h1.492a.39.39,0,0,0,.39-.39v-.909a.379.379,0,0,1,.279-.367,5.378,5.378,0,0,0,1.271-.527.379.379,0,0,1,.457.062l.643.643a.39.39,0,0,0,.551,0l1.055-1.055a.39.39,0,0,0,0-.551l-.643-.643a.379.379,0,0,1-.062-.457,5.361,5.361,0,0,0,.527-1.271.379.379,0,0,1,.367-.279h.909a.39.39,0,0,0,.39-.39V166.4A.39.39,0,0,0,194.8,166.008Z"
                        transform="translate(-181.425 -160.263)"
                        fill="#70d6f9"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294784"
                    data-name="Group 294784"
                    transform="translate(44.533 39.586)"
                  >
                    <g id="Group_294783" data-name="Group 294783">
                      <path
                        id="Path_158137"
                        data-name="Path 158137"
                        d="M200.069,181.945a3.037,3.037,0,1,1,3.037-3.037A3.037,3.037,0,0,1,200.069,181.945Z"
                        transform="translate(-197.032 -175.871)"
                        fill="#3caadc"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294786"
                    data-name="Group 294786"
                    transform="translate(36.798 30.137)"
                  >
                    <g id="Group_294785" data-name="Group 294785">
                      <ellipse
                        id="Ellipse_3545"
                        data-name="Ellipse 3545"
                        cx="2.093"
                        cy="2.093"
                        rx="2.093"
                        ry="2.093"
                        fill="#f9d0b4"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294788"
                    data-name="Group 294788"
                    transform="translate(34.828 34.57)"
                  >
                    <g
                      id="Group_294787"
                      data-name="Group 294787"
                      transform="translate(0)"
                    >
                      <path
                        id="Path_158138"
                        data-name="Path 158138"
                        d="M159.723,155.5h3.926a2.107,2.107,0,0,1,2.1,2.1v.837l-.778.778a.389.389,0,0,0-.074.444,5.79,5.79,0,0,1-7.274-.694V157.6A2.107,2.107,0,0,1,159.723,155.5Z"
                        transform="translate(-157.623 -155.5)"
                        fill="#3a70bf"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294790"
                    data-name="Group 294790"
                    transform="translate(40.073 35.127)"
                  >
                    <g
                      id="Group_294789"
                      data-name="Group 294789"
                      transform="translate(0)"
                    >
                      <path
                        id="Path_158139"
                        data-name="Path 158139"
                        d="M187.164,172.755h-1.492a1.007,1.007,0,0,1-1.005-1.005v-.732a6.019,6.019,0,0,1-1.082-.449l-.518.518a1.008,1.008,0,0,1-1.422,0l-1.055-1.055a1.006,1.006,0,0,1,0-1.422l.518-.518a5.989,5.989,0,0,1-.449-1.082h-.732A1.007,1.007,0,0,1,178.922,166v-1.492a1.007,1.007,0,0,1,1.005-1.005h.732a6,6,0,0,1,.449-1.082l-.518-.518a1.007,1.007,0,0,1,0-1.422l1.055-1.055a1.005,1.005,0,0,1,1.422,0l.518.518a5.988,5.988,0,0,1,1.082-.449v-.732a1.007,1.007,0,0,1,1.005-1.005h1.492a1.007,1.007,0,0,1,1.006,1.005v.732a5.988,5.988,0,0,1,1.082.449l.518-.518a1.007,1.007,0,0,1,1.422,0l1.055,1.054a1.007,1.007,0,0,1,0,1.422l-.518.518a5.986,5.986,0,0,1,.449,1.082h.732a1.007,1.007,0,0,1,1.005,1.005V166a1.007,1.007,0,0,1-1.005,1.006h-.732a5.983,5.983,0,0,1-.449,1.082l.518.518a1.006,1.006,0,0,1,0,1.422l-1.055,1.055a1.007,1.007,0,0,1-1.421,0l-.519-.519a5.993,5.993,0,0,1-1.082.449v.732A1.007,1.007,0,0,1,187.164,172.755Zm-1.266-1.231h1.04v-.683a1,1,0,0,1,.734-.961,4.768,4.768,0,0,0,1.125-.467,1,1,0,0,1,1.2.16l.484.484.735-.735-.484-.484a1,1,0,0,1-.16-1.2,4.752,4.752,0,0,0,.467-1.125,1,1,0,0,1,.961-.734h.683v-1.04H192a1,1,0,0,1-.961-.734,4.763,4.763,0,0,0-.467-1.126,1,1,0,0,1,.16-1.2l.484-.484-.735-.735-.484.484a1,1,0,0,1-1.2.16,4.745,4.745,0,0,0-1.126-.467,1,1,0,0,1-.734-.961v-.683H185.9v.683a1,1,0,0,1-.734.961,4.765,4.765,0,0,0-1.125.467,1,1,0,0,1-1.2-.159l-.484-.484-.735.735.484.484a1,1,0,0,1,.159,1.2A4.769,4.769,0,0,0,181.8,164a1,1,0,0,1-.961.734h-.683v1.04h.683a1,1,0,0,1,.961.734,4.737,4.737,0,0,0,.467,1.125,1,1,0,0,1-.16,1.2l-.484.484.735.735.483-.484a1,1,0,0,1,1.2-.16,4.766,4.766,0,0,0,1.126.467,1,1,0,0,1,.734.961Zm-4.7-3.273h0Zm11.713-3.512h0ZM181.2,162.266h0Z"
                        transform="translate(-178.922 -157.762)"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294792"
                    data-name="Group 294792"
                    transform="translate(27.131 22.147)"
                  >
                    <g id="Group_294791" data-name="Group 294791">
                      <path
                        id="Path_158140"
                        data-name="Path 158140"
                        d="M139.334,128.577h-2.416a1.248,1.248,0,0,1-1.247-1.247v-1.472a9.323,9.323,0,0,1-2.2-.914l-1.041,1.043a1.248,1.248,0,0,1-1.763,0l-1.708-1.708a1.247,1.247,0,0,1,0-1.764L130,121.472a9.343,9.343,0,0,1-.914-2.2l-1.472,0a1.248,1.248,0,0,1-1.247-1.247v-2.416a1.248,1.248,0,0,1,1.247-1.247h1.472a9.314,9.314,0,0,1,.914-2.2l-1.043-1.041a1.249,1.249,0,0,1,0-1.764l1.709-1.708a1.246,1.246,0,0,1,1.764,0l1.042,1.041a9.324,9.324,0,0,1,2.2-.913l0-1.472a1.248,1.248,0,0,1,1.247-1.247h2.416a1.248,1.248,0,0,1,1.247,1.247v1.472a9.341,9.341,0,0,1,2.2.914l1.041-1.043a1.249,1.249,0,0,1,1.763,0l1.709,1.709a1.247,1.247,0,0,1,0,1.764l-1.041,1.042a9.337,9.337,0,0,1,.913,2.2l1.472,0a1.248,1.248,0,0,1,1.247,1.247v2.416a1.248,1.248,0,0,1-1.247,1.247H147.6a.616.616,0,0,1,0-1.231h1.039l.016-2.432-1.488-.016a1.232,1.232,0,0,1-1.188-.908,8.09,8.09,0,0,0-.794-1.913,1.232,1.232,0,0,1,.2-1.483l1.041-1.042-1.708-1.731-1.064,1.042a1.232,1.232,0,0,1-1.483.2,8.107,8.107,0,0,0-1.913-.793,1.233,1.233,0,0,1-.907-1.188V106.3l-2.432-.016-.016,1.488a1.233,1.233,0,0,1-.907,1.188,8.071,8.071,0,0,0-1.913.794,1.233,1.233,0,0,1-1.483-.2l-1.042-1.042-1.731,1.708,1.042,1.065a1.233,1.233,0,0,1,.2,1.483,8.1,8.1,0,0,0-.794,1.913,1.232,1.232,0,0,1-1.188.907h-1.472l-.016,2.432,1.488.016a1.232,1.232,0,0,1,1.188.908,8.087,8.087,0,0,0,.794,1.913,1.233,1.233,0,0,1-.2,1.483l-1.042,1.042,1.708,1.731,1.064-1.042a1.232,1.232,0,0,1,1.483-.2,8.088,8.088,0,0,0,1.913.794,1.233,1.233,0,0,1,.907,1.188v1.472l2.4.016a.138.138,0,0,0,0-.016v-.99a.616.616,0,1,1,1.231,0v.99a1.213,1.213,0,0,1-1.205,1.247Z"
                        transform="translate(-126.366 -105.056)"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294794"
                    data-name="Group 294794"
                    transform="translate(32.483 27.5)"
                  >
                    <g
                      id="Group_294793"
                      data-name="Group 294793"
                      transform="translate(0)"
                    >
                      <path
                        id="Path_158141"
                        data-name="Path 158141"
                        d="M154.508,139.607a6.408,6.408,0,1,1,5.3-2.809.616.616,0,1,1-1.018-.692,5.179,5.179,0,1,0-1.415,1.4.616.616,0,0,1,.684,1.024A6.377,6.377,0,0,1,154.508,139.607Z"
                        transform="translate(-148.1 -126.79)"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294796"
                    data-name="Group 294796"
                    transform="translate(43.917 38.971)"
                  >
                    <g id="Group_294795" data-name="Group 294795">
                      <path
                        id="Path_158142"
                        data-name="Path 158142"
                        d="M198.183,180.676a3.652,3.652,0,1,1,3.653-3.652A3.656,3.656,0,0,1,198.183,180.676Zm0-6.073a2.421,2.421,0,1,0,2.421,2.421A2.424,2.424,0,0,0,198.183,174.6Z"
                        transform="translate(-194.531 -173.371)"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294798"
                    data-name="Group 294798"
                    transform="translate(36.182 29.521)"
                  >
                    <g id="Group_294797" data-name="Group 294797">
                      <path
                        id="Path_158143"
                        data-name="Path 158143"
                        d="M165.832,140.418a2.709,2.709,0,1,1,2.709-2.709A2.712,2.712,0,0,1,165.832,140.418Zm0-4.186a1.478,1.478,0,1,0,1.478,1.478A1.479,1.479,0,0,0,165.832,136.231Z"
                        transform="translate(-163.123 -135)"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294800"
                    data-name="Group 294800"
                    transform="translate(34.212 33.954)"
                  >
                    <g
                      id="Group_294799"
                      data-name="Group 294799"
                      transform="translate(0)"
                    >
                      <path
                        id="Path_158144"
                        data-name="Path 158144"
                        d="M155.739,157.5a.616.616,0,0,1-.616-.616v-1.173A2.719,2.719,0,0,1,157.839,153h3.926a2.719,2.719,0,0,1,2.716,2.716v.724a.616.616,0,0,1-1.231,0v-.724a1.487,1.487,0,0,0-1.485-1.485h-3.926a1.486,1.486,0,0,0-1.485,1.485v1.173A.616.616,0,0,1,155.739,157.5Z"
                        transform="translate(-155.123 -153)"
                      />
                    </g>
                  </g>
                </g>
              </svg>
              <div className="p-2">
                <h5 className="font-bold text-[19px]">Features</h5>
                <h6 className="text-gray-500 mt-0.5">
                  {" "}
                  Special features for Students{" "}
                </h6>
              </div>
            </a>
          </li>{" "}
          <li>
            <a
              href="#"
              className="inline-flex items-center px-4 py-2.5 text-black hover:bg-blue-100 rounded-lg active w-full "
              aria-current="page"
            >
              <svg
                id="Layer_73"
                xmlns="http://www.w3.org/2000/svg"
                width="55.066"
                height="59.319"
                viewBox="0 0 55.066 59.319"
              >
                <g
                  id="Group_294801"
                  data-name="Group 294801"
                  transform="translate(0)"
                >
                  <g
                    id="Group_294689"
                    data-name="Group 294689"
                    transform="translate(15.47 29.501)"
                  >
                    <g id="Group_294688" data-name="Group 294688">
                      <path
                        id="Path_158096"
                        data-name="Path 158096"
                        d="M87.85,134.916a9.091,9.091,0,0,1-8.444,0v1.492a3.16,3.16,0,0,1-.391,1.522l3.577,3.76a.518.518,0,0,1,.141.387c.252.021.505.035.762.035a9.058,9.058,0,0,0,1.026-.064h0a.521.521,0,0,1,.144-.36l3.577-3.76a3.16,3.16,0,0,1-.391-1.522v-1.491Z"
                        transform="translate(-79.014 -134.916)"
                        fill="#f9d0b4"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294691"
                    data-name="Group 294691"
                    transform="translate(10.918 9.895)"
                  >
                    <g id="Group_294690" data-name="Group 294690">
                      <path
                        id="Path_158097"
                        data-name="Path 158097"
                        d="M77.553,55.823a1.679,1.679,0,0,0-2.257-.15,9.2,9.2,0,0,1-11.2-.006,1.678,1.678,0,0,0-2.253.153l0,0a4.738,4.738,0,0,0-1.309,3.27v7.691a9.165,9.165,0,0,0,18.329,0V59.093a4.738,4.738,0,0,0-1.309-3.27Z"
                        transform="translate(-60.533 -55.304)"
                        fill="#f9d0b4"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294693"
                    data-name="Group 294693"
                    transform="translate(29.224 14.391)"
                  >
                    <g id="Group_294692" data-name="Group 294692">
                      <path
                        id="Path_158098"
                        data-name="Path 158098"
                        d="M135.48,73.561a3.855,3.855,0,0,0-.588.047v6.938c0,.207-.009.411-.023.615a3.824,3.824,0,1,0,.611-7.6Z"
                        transform="translate(-134.869 -73.561)"
                        fill="#f9d0b4"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294695"
                    data-name="Group 294695"
                    transform="translate(6.504 14.391)"
                  >
                    <g id="Group_294694" data-name="Group 294694">
                      <path
                        id="Path_158099"
                        data-name="Path 158099"
                        d="M47.021,80.545V73.608a3.828,3.828,0,1,0,.023,7.553h0C47.031,80.957,47.021,80.752,47.021,80.545Z"
                        transform="translate(-42.607 -73.561)"
                        fill="#f9d0b4"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294697"
                    data-name="Group 294697"
                    transform="translate(8.404 0.613)"
                  >
                    <g id="Group_294696" data-name="Group 294696">
                      <path
                        id="Path_158100"
                        data-name="Path 158100"
                        d="M71.132,23.572a.567.567,0,0,1-.577-.543c-.07-5.107-5.9-6.066-11.709-5.063a10.268,10.268,0,0,0-8.523,10.117v3.832a3.787,3.787,0,0,1,2.514-.478v-.753a4.739,4.739,0,0,1,1.309-3.27l0,0a1.678,1.678,0,0,1,2.253-.153,9.2,9.2,0,0,0,11.2.006,1.679,1.679,0,0,1,2.257.15,4.738,4.738,0,0,1,1.309,3.27v.753a3.786,3.786,0,0,1,2.534.49V28.78c0-4.573-1.651-5.208-2.568-5.208Z"
                        transform="translate(-50.323 -17.611)"
                        fill="#5b5d60"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294699"
                    data-name="Group 294699"
                    transform="translate(10.303 9.278)"
                  >
                    <g id="Group_294698" data-name="Group 294698">
                      <path
                        id="Path_158101"
                        data-name="Path 158101"
                        d="M67.813,74.676a9.791,9.791,0,0,1-9.78-9.781V57.2a5.331,5.331,0,0,1,1.479-3.695,2.289,2.289,0,0,1,3.081-.216,8.588,8.588,0,0,0,10.438.007,2.29,2.29,0,0,1,3.083.21h0A5.332,5.332,0,0,1,77.594,57.2V64.9A9.792,9.792,0,0,1,67.813,74.676ZM61.167,54.03a1.046,1.046,0,0,0-.76.325A4.107,4.107,0,0,0,59.264,57.2V64.9a8.549,8.549,0,1,0,17.1,0V57.2a4.106,4.106,0,0,0-1.139-2.845h0a1.062,1.062,0,0,0-1.431-.091,9.82,9.82,0,0,1-11.958-.005A1.082,1.082,0,0,0,61.167,54.03Z"
                        transform="translate(-58.033 -52.798)"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294701"
                    data-name="Group 294701"
                    transform="translate(28.833 13.775)"
                  >
                    <g id="Group_294700" data-name="Group 294700">
                      <path
                        id="Path_158102"
                        data-name="Path 158102"
                        d="M134.283,79.943a4.06,4.06,0,0,1-.447-.026.616.616,0,0,1,.134-1.224,2.838,2.838,0,0,0,.313.018,3.211,3.211,0,1,0,0-6.421,3.155,3.155,0,0,0-.323.017.616.616,0,1,1-.127-1.225,4.364,4.364,0,0,1,.45-.024,4.442,4.442,0,0,1,0,8.884Z"
                        transform="translate(-133.28 -71.059)"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294703"
                    data-name="Group 294703"
                    transform="translate(5.889 13.776)"
                  >
                    <g id="Group_294702" data-name="Group 294702">
                      <path
                        id="Path_158103"
                        data-name="Path 158103"
                        d="M44.55,79.945a4.442,4.442,0,0,1,0-8.884,4.255,4.255,0,0,1,.446.023.615.615,0,1,1-.127,1.224,3.122,3.122,0,0,0-.319-.016,3.211,3.211,0,0,0,0,6.421,2.853,2.853,0,0,0,.341-.021.616.616,0,0,1,.145,1.223,4.092,4.092,0,0,1-.486.03Z"
                        transform="translate(-40.108 -71.061)"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294705"
                    data-name="Group 294705"
                    transform="translate(17.118 23.181)"
                  >
                    <g id="Group_294704" data-name="Group 294704">
                      <path
                        id="Path_158104"
                        data-name="Path 158104"
                        d="M88.8,111.547a3.862,3.862,0,0,1-2.955-1.285.616.616,0,1,1,.944-.79,2.818,2.818,0,0,0,4.022,0,.616.616,0,0,1,.944.791A3.861,3.861,0,0,1,88.8,111.547Z"
                        transform="translate(-85.706 -109.252)"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294707"
                    data-name="Group 294707"
                    transform="translate(23.119 17.409)"
                  >
                    <g id="Group_294706" data-name="Group 294706">
                      <ellipse
                        id="Ellipse_3540"
                        data-name="Ellipse 3540"
                        cx="1.231"
                        cy="1.231"
                        rx="1.231"
                        ry="1.231"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294709"
                    data-name="Group 294709"
                    transform="translate(14.85 17.409)"
                  >
                    <g id="Group_294708" data-name="Group 294708">
                      <ellipse
                        id="Ellipse_3541"
                        data-name="Ellipse 3541"
                        cx="1.231"
                        cy="1.231"
                        rx="1.231"
                        ry="1.231"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294711"
                    data-name="Group 294711"
                    transform="translate(7.789)"
                  >
                    <g id="Group_294710" data-name="Group 294710">
                      <path
                        id="Path_158105"
                        data-name="Path 158105"
                        d="M48.44,30.5a.616.616,0,0,1-.616-.616V26.205a10.861,10.861,0,0,1,9.033-10.723c4.608-.795,8.444-.261,10.526,1.468a5.276,5.276,0,0,1,1.9,4.13c1.193.016,3.147.809,3.147,5.823v2.913a.616.616,0,1,1-1.231,0V26.9c0-2.918-.712-4.592-1.953-4.592a1.185,1.185,0,0,1-1.193-1.151A4.11,4.11,0,0,0,66.6,17.9c-1.8-1.5-5.277-1.936-9.53-1.2a9.632,9.632,0,0,0-8.012,9.51v3.678A.615.615,0,0,1,48.44,30.5Z"
                        transform="translate(-47.824 -15.122)"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294713"
                    data-name="Group 294713"
                    transform="translate(0.616 33.258)"
                  >
                    <g id="Group_294712" data-name="Group 294712">
                      <path
                        id="Path_158106"
                        data-name="Path 158106"
                        d="M18.7,174.2l1.194-16.727a4.358,4.358,0,0,1,2.641-3.712l8.293-3.529a.761.761,0,0,1,1.037.518l6.3,24.866H20.019A1.322,1.322,0,0,1,18.7,174.2Z"
                        transform="translate(-18.696 -150.173)"
                        fill="#754e34"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294715"
                    data-name="Group 294715"
                    transform="translate(20.083 33.258)"
                  >
                    <g id="Group_294714" data-name="Group 294714">
                      <path
                        id="Path_158107"
                        data-name="Path 158107"
                        d="M116.339,161.969h-.991a.631.631,0,0,1-.631-.631v-1.472a.615.615,0,0,0-.452-.594,8.7,8.7,0,0,1-2.059-.854.614.614,0,0,0-.741.1l-1.042,1.042a.632.632,0,0,1-.893,0l-1.708-1.708a.631.631,0,0,1,0-.893l1.042-1.042a.614.614,0,0,0,.1-.741,8.691,8.691,0,0,1-.854-2.058.614.614,0,0,0-.594-.452h-1.472a.631.631,0,0,1-.631-.631v-1.657l-.328-.139a.761.761,0,0,0-1.036.518l-6.3,24.866h18.145a1.323,1.323,0,0,0,1.319-1.417Z"
                        transform="translate(-97.748 -150.175)"
                        fill="#754e34"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294717"
                    data-name="Group 294717"
                    transform="translate(32.776 41.422)"
                  >
                    <g id="Group_294716" data-name="Group 294716">
                      <path
                        id="Path_158108"
                        data-name="Path 158108"
                        d="M155.189,186.955H154.2a.631.631,0,0,1-.631-.631v-1.472a.615.615,0,0,0-.452-.594,8.7,8.7,0,0,1-2.059-.854.608.608,0,0,0-.328-.079,6.369,6.369,0,0,0-1.436,4.026v13.255h5.451a1.322,1.322,0,0,0,1.319-1.417Z"
                        transform="translate(-149.292 -183.324)"
                        fill="#9b6b4b"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294719"
                    data-name="Group 294719"
                    transform="translate(0.616 38.26)"
                  >
                    <g id="Group_294718" data-name="Group 294718">
                      <path
                        id="Path_158109"
                        data-name="Path 158109"
                        d="M25.47,177.672a6.37,6.37,0,0,0-2.015-4.649l-2.71-2.539a4.352,4.352,0,0,0-.852,2.3L18.7,189.511a1.323,1.323,0,0,0,1.319,1.417H25.47Z"
                        transform="translate(-18.696 -170.484)"
                        fill="#9b6b4b"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294721"
                    data-name="Group 294721"
                    transform="translate(10.971 33.258)"
                  >
                    <g id="Group_294720" data-name="Group 294720">
                      <path
                        id="Path_158110"
                        data-name="Path 158110"
                        d="M63.56,150.753a.761.761,0,0,0-1.037-.518l-1.776.756v9.984a.454.454,0,0,0,.352.443l2.32.535a.455.455,0,0,1,.315.622l-1.052,2.454a1.2,1.2,0,0,0,.015.982l4.469,9.609H69.86Z"
                        transform="translate(-60.748 -150.173)"
                        fill="#9b6b4b"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294723"
                    data-name="Group 294723"
                    transform="translate(20.083 33.258)"
                  >
                    <g id="Group_294722" data-name="Group 294722">
                      <path
                        id="Path_158111"
                        data-name="Path 158111"
                        d="M106.043,152.664a.631.631,0,0,1-.631-.631v-1.657l-.328-.139a.761.761,0,0,0-1.036.518l-6.3,24.866h2.692l4.469-9.609a1.2,1.2,0,0,0,.015-.982l-1.052-2.454a.454.454,0,0,1,.315-.622l2.32-.535a.454.454,0,0,0,.352-.443v-8.311Z"
                        transform="translate(-97.748 -150.175)"
                        fill="#9b6b4b"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294725"
                    data-name="Group 294725"
                    transform="translate(13.311 32.07)"
                  >
                    <g id="Group_294724" data-name="Group 294724">
                      <path
                        id="Path_158112"
                        data-name="Path 158112"
                        d="M70.722,147.116l.87,3.435,1.544,1.869a.561.561,0,0,0,.845.023l2.009-2.176a.522.522,0,0,0-.005-.714l-3.863-4.061a.461.461,0,0,0-.645-.023l-1.227,1.12A.754.754,0,0,1,70.722,147.116Z"
                        transform="translate(-70.249 -145.348)"
                        fill="#edf4fc"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294727"
                    data-name="Group 294727"
                    transform="translate(20.975 32.07)"
                  >
                    <g id="Group_294726" data-name="Group 294726">
                      <path
                        id="Path_158113"
                        data-name="Path 158113"
                        d="M103.519,152.444a.561.561,0,0,0,.845-.023l1.544-1.869.87-3.435a.754.754,0,0,1,.472-.526l-1.227-1.12a.461.461,0,0,0-.645.023l-3.863,4.061a.522.522,0,0,0-.005.714Z"
                        transform="translate(-101.372 -145.349)"
                        fill="#edf4fc"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294730"
                    data-name="Group 294730"
                    transform="translate(14.654 37.272)"
                  >
                    <g id="Group_294729" data-name="Group 294729">
                      <g id="Group_294728" data-name="Group 294728">
                        <path
                          id="Path_158114"
                          data-name="Path 158114"
                          d="M85.016,168.341a.561.561,0,0,1-.845.023L83.094,167.2a2.393,2.393,0,0,1-3.926,0l-1.077,1.167a.561.561,0,0,1-.845-.023L75.7,166.472,81.131,187.9l5.429-21.432Z"
                          transform="translate(-75.702 -166.472)"
                          fill="#dae6f1"
                        />
                      </g>
                    </g>
                  </g>
                  <g
                    id="Group_294732"
                    data-name="Group 294732"
                    transform="translate(17.716 38.676)"
                  >
                    <g id="Group_294731" data-name="Group 294731">
                      <path
                        id="Path_158115"
                        data-name="Path 158115"
                        d="M90.472,192.079l.094-.125,2.3-9.1L91.74,172.175a2.381,2.381,0,0,1-2.473,0l-1.131,10.684Z"
                        transform="translate(-88.136 -172.175)"
                        fill="#fc657e"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294735"
                    data-name="Group 294735"
                    transform="translate(18.12 36.633)"
                  >
                    <g id="Group_294734" data-name="Group 294734">
                      <g id="Group_294733" data-name="Group 294733">
                        <path
                          id="Path_158116"
                          data-name="Path 158116"
                          d="M92.772,164.233a.521.521,0,0,1-.138-.355,9.109,9.109,0,0,1-1.026.064c-.257,0-.51-.014-.762-.035a.518.518,0,0,1-.136.327l-.932,1.01a2.392,2.392,0,0,0,3.926,0Z"
                          transform="translate(-89.778 -163.878)"
                          fill="#fc657e"
                        />
                      </g>
                    </g>
                  </g>
                  <g
                    id="Group_294737"
                    data-name="Group 294737"
                    transform="translate(0 32.642)"
                  >
                    <g id="Group_294736" data-name="Group 294736">
                      <path
                        id="Path_158117"
                        data-name="Path 158117"
                        d="M36.279,174.35H18.134a1.938,1.938,0,0,1-1.933-2.076l1.194-16.727a4.951,4.951,0,0,1,3.014-4.234l8.293-3.529a1.377,1.377,0,0,1,1.876.937l6.3,24.863a.616.616,0,0,1-.6.767Zm-18.85-1.989a.707.707,0,0,0,.705.757H35.488l-6.1-24.1a.144.144,0,0,0-.077-.1.142.142,0,0,0-.122,0l-8.293,3.529a3.725,3.725,0,0,0-2.268,3.187Z"
                        transform="translate(-16.196 -147.674)"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294739"
                    data-name="Group 294739"
                    transform="translate(23.69 29.037)"
                  >
                    <g id="Group_294738" data-name="Group 294738">
                      <path
                        id="Path_158118"
                        data-name="Path 158118"
                        d="M113.333,137a.616.616,0,0,1-.553-.345,3.8,3.8,0,0,1-.387-1.664v-1.34a.616.616,0,1,1,1.231,0v1.34a2.56,2.56,0,0,0,.261,1.121.616.616,0,0,1-.552.887Z"
                        transform="translate(-112.393 -133.032)"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294741"
                    data-name="Group 294741"
                    transform="translate(18.653 36.026)"
                  >
                    <g id="Group_294740" data-name="Group 294740">
                      <path
                        id="Path_158119"
                        data-name="Path 158119"
                        d="M93.237,162.7c-.222,0-.46-.01-.728-.031a.616.616,0,1,1,.095-1.228c.236.018.444.027.634.027a8.329,8.329,0,0,0,.884-.052.616.616,0,0,1,.131,1.224A9.415,9.415,0,0,1,93.237,162.7Z"
                        transform="translate(-91.94 -161.413)"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294743"
                    data-name="Group 294743"
                    transform="translate(14.953 29.055)"
                  >
                    <g id="Group_294742" data-name="Group 294742">
                      <path
                        id="Path_158120"
                        data-name="Path 158120"
                        d="M77.533,136.984a.616.616,0,0,1-.559-.874,2.527,2.527,0,0,0,.235-1.067v-1.322a.616.616,0,0,1,1.231,0v1.322a3.747,3.747,0,0,1-.349,1.583A.617.617,0,0,1,77.533,136.984Z"
                        transform="translate(-76.918 -133.106)"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294745"
                    data-name="Group 294745"
                    transform="translate(19.467 32.643)"
                  >
                    <g id="Group_294744" data-name="Group 294744">
                      <path
                        id="Path_158121"
                        data-name="Path 158121"
                        d="M114.008,174.351H95.864a.616.616,0,0,1-.6-.767l6.3-24.866a1.377,1.377,0,0,1,1.875-.933l.328.139a.616.616,0,0,1,.375.567v1.657l1.488.016a1.232,1.232,0,0,1,1.188.908,8.093,8.093,0,0,0,.794,1.912,1.232,1.232,0,0,1-.2,1.483l-1.042,1.042,1.708,1.731,1.064-1.042a1.231,1.231,0,0,1,1.483-.2,8.1,8.1,0,0,0,1.913.793,1.233,1.233,0,0,1,.907,1.188v1.472l1.006.016a.616.616,0,0,1,.614.572l.873,12.234a1.938,1.938,0,0,1-1.934,2.076ZM96.655,173.12h17.354a.707.707,0,0,0,.705-.757l-.832-11.662h-.417a1.248,1.248,0,0,1-1.247-1.247v-1.472a9.332,9.332,0,0,1-2.2-.914l-1.041,1.043a1.247,1.247,0,0,1-1.764,0L105.5,156.4a1.247,1.247,0,0,1,0-1.764l1.041-1.042a9.332,9.332,0,0,1-.914-2.2l-1.472,0a1.248,1.248,0,0,1-1.247-1.247v-1.242a.131.131,0,0,0-.075.015.142.142,0,0,0-.076.1Z"
                        transform="translate(-95.248 -147.674)"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294747"
                    data-name="Group 294747"
                    transform="translate(32.161 40.893)"
                  >
                    <g id="Group_294746" data-name="Group 294746">
                      <path
                        id="Path_158122"
                        data-name="Path 158122"
                        d="M147.409,199.327a.616.616,0,0,1-.616-.616V185.732a7.014,7.014,0,0,1,1.5-4.32.616.616,0,1,1,.967.762,5.779,5.779,0,0,0-1.234,3.558v12.978A.616.616,0,0,1,147.409,199.327Z"
                        transform="translate(-146.793 -181.178)"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294749"
                    data-name="Group 294749"
                    transform="translate(2.208 37.794)"
                  >
                    <g id="Group_294748" data-name="Group 294748">
                      <path
                        id="Path_158123"
                        data-name="Path 158123"
                        d="M30.344,189.941a.616.616,0,0,1-.616-.616v-13.08a5.776,5.776,0,0,0-1.82-4.2l-2.551-2.39a.616.616,0,1,1,.842-.9l2.551,2.39a7.01,7.01,0,0,1,2.21,5.1v13.08a.616.616,0,0,1-.616.616Z"
                        transform="translate(-25.163 -168.591)"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294751"
                    data-name="Group 294751"
                    transform="translate(10.356 33.708)"
                  >
                    <g id="Group_294750" data-name="Group 294750">
                      <path
                        id="Path_158124"
                        data-name="Path 158124"
                        d="M65.2,177.436a.616.616,0,0,1-.559-.356l-4.388-9.434a1.823,1.823,0,0,1-.023-1.484l.976-2.277-2.132-.492a1.065,1.065,0,0,1-.829-1.042v-9.735a.616.616,0,0,1,1.231,0v9.607l2.195.507a1.07,1.07,0,0,1,.743,1.464l-1.052,2.454a.588.588,0,0,0,.007.479l4.388,9.434a.615.615,0,0,1-.558.875Z"
                        transform="translate(-58.248 -152)"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294753"
                    data-name="Group 294753"
                    transform="translate(22.263 35.278)"
                  >
                    <g id="Group_294752" data-name="Group 294752">
                      <path
                        id="Path_158125"
                        data-name="Path 158125"
                        d="M107.217,182.192a.616.616,0,0,1-.558-.875l4.365-9.385a.587.587,0,0,0,.007-.479L109.98,169a1.07,1.07,0,0,1,.743-1.464l2.194-.507v-8.037a.616.616,0,0,1,1.231,0v8.165a1.065,1.065,0,0,1-.829,1.042l-2.132.492.976,2.277a1.82,1.82,0,0,1-.023,1.483l-4.365,9.385A.616.616,0,0,1,107.217,182.192Z"
                        transform="translate(-106.602 -158.375)"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294755"
                    data-name="Group 294755"
                    transform="translate(12.763 31.457)"
                  >
                    <g id="Group_294754" data-name="Group 294754">
                      <path
                        id="Path_158126"
                        data-name="Path 158126"
                        d="M71.892,151.363H71.86a1.177,1.177,0,0,1-.875-.427l-1.529-1.851a.616.616,0,1,1,.949-.784l1.489,1.8,1.907-2.066-3.7-3.887-1.048.956a.616.616,0,0,1-.83-.909l1.159-1.058a1.083,1.083,0,0,1,1.506.053l3.863,4.061a1.14,1.14,0,0,1,.012,1.556l-2.009,2.176A1.177,1.177,0,0,1,71.892,151.363Z"
                        transform="translate(-68.025 -142.859)"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294757"
                    data-name="Group 294757"
                    transform="translate(20.361 31.457)"
                  >
                    <g id="Group_294756" data-name="Group 294756">
                      <path
                        id="Path_158127"
                        data-name="Path 158127"
                        d="M102.051,151.363a1.177,1.177,0,0,1-.865-.378l-2.009-2.176a1.14,1.14,0,0,1,.012-1.556l3.863-4.061a1.082,1.082,0,0,1,1.506-.053l1.169,1.067a.616.616,0,0,1-.83.909l-1.057-.965-3.7,3.888,1.907,2.066,1.479-1.791a.616.616,0,1,1,.949.784l-1.519,1.839a1.178,1.178,0,0,1-.875.427Z"
                        transform="translate(-98.877 -142.859)"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294759"
                    data-name="Group 294759"
                    transform="translate(20.718 38.196)"
                  >
                    <g id="Group_294758" data-name="Group 294758">
                      <path
                        id="Path_158128"
                        data-name="Path 158128"
                        d="M102.051,181.941a.616.616,0,0,1-.611-.551l-1.109-10.484a.616.616,0,1,1,1.225-.129l1.109,10.484a.616.616,0,0,1-.547.677C102.095,181.94,102.073,181.941,102.051,181.941Z"
                        transform="translate(-100.327 -170.227)"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294761"
                    data-name="Group 294761"
                    transform="translate(17.111 38.166)"
                  >
                    <g id="Group_294760" data-name="Group 294760">
                      <path
                        id="Path_158129"
                        data-name="Path 158129"
                        d="M86.3,181.812c-.022,0-.044,0-.065,0a.616.616,0,0,1-.547-.677l1.108-10.477a.616.616,0,1,1,1.225.129l-1.108,10.477A.616.616,0,0,1,86.3,181.812Z"
                        transform="translate(-85.68 -170.105)"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294764"
                    data-name="Group 294764"
                    transform="translate(17.634 37.547)"
                  >
                    <g id="Group_294763" data-name="Group 294763">
                      <g id="Group_294762" data-name="Group 294762">
                        <path
                          id="Path_158130"
                          data-name="Path 158130"
                          d="M90.251,169.68a3,3,0,0,1-2.3-1.076.616.616,0,1,1,.943-.792,1.776,1.776,0,0,0,2.727,0,.616.616,0,0,1,.944.79,3.006,3.006,0,0,1-2.309,1.081Z"
                          transform="translate(-87.802 -167.589)"
                        />
                      </g>
                    </g>
                  </g>
                  <g
                    id="Group_294778"
                    data-name="Group 294778"
                    transform="translate(27.747 22.763)"
                  >
                    <g id="Group_294777" data-name="Group 294777">
                      <path
                        id="Path_158134"
                        data-name="Path 158134"
                        d="M129.5,116.862h1.472a.615.615,0,0,0,.594-.452,8.687,8.687,0,0,1,.854-2.058.614.614,0,0,0-.1-.741l-1.042-1.042a.632.632,0,0,1,0-.893l1.708-1.708a.632.632,0,0,1,.893,0l1.042,1.042a.615.615,0,0,0,.741.1,8.687,8.687,0,0,1,2.059-.854.615.615,0,0,0,.452-.594v-1.472a.631.631,0,0,1,.631-.631h2.416a.631.631,0,0,1,.631.631v1.472a.615.615,0,0,0,.452.594,8.688,8.688,0,0,1,2.059.854.614.614,0,0,0,.741-.1l1.042-1.042a.632.632,0,0,1,.893,0l1.708,1.708a.631.631,0,0,1,0,.893l-1.042,1.042a.615.615,0,0,0-.1.741,8.69,8.69,0,0,1,.854,2.058.614.614,0,0,0,.594.452h1.472a.631.631,0,0,1,.631.631v2.416a.631.631,0,0,1-.631.631h-1.039a.37.37,0,0,0-.05-.005h-1.492a.39.39,0,0,0-.39.39v.909a.379.379,0,0,1-.279.367,5.366,5.366,0,0,0-1.271.527.379.379,0,0,1-.457-.062l-.643-.643a.393.393,0,0,0-.1-.069,5.792,5.792,0,1,0-1.582,1.569.387.387,0,0,0,.074.107l.643.643a.379.379,0,0,1,.062.457,5.354,5.354,0,0,0-.527,1.271.379.379,0,0,1-.367.279H142.2a.39.39,0,0,0-.39.39v2.544a.6.6,0,0,1-.59.631H138.8a.631.631,0,0,1-.631-.631v-1.472a.615.615,0,0,0-.452-.594,8.7,8.7,0,0,1-2.059-.854.614.614,0,0,0-.741.1l-1.042,1.042a.632.632,0,0,1-.893,0l-1.708-1.708a.631.631,0,0,1,0-.893l1.042-1.042a.614.614,0,0,0,.1-.741,8.691,8.691,0,0,1-.854-2.058.614.614,0,0,0-.594-.452H129.5a.631.631,0,0,1-.631-.631v-2.416A.631.631,0,0,1,129.5,116.862Z"
                        transform="translate(-128.868 -107.557)"
                        fill="#fcdd86"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294780"
                    data-name="Group 294780"
                    transform="translate(33.098 28.115)"
                  >
                    <g
                      id="Group_294779"
                      data-name="Group 294779"
                      transform="translate(0)"
                    >
                      <path
                        id="Path_158135"
                        data-name="Path 158135"
                        d="M156.393,140.875a5.792,5.792,0,1,1,4.793-2.54.389.389,0,0,0-.453.069l-1.055,1.054a.388.388,0,0,0-.074.444A5.765,5.765,0,0,1,156.393,140.875Z"
                        transform="translate(-150.6 -129.29)"
                        fill="#f5c84c"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294782"
                    data-name="Group 294782"
                    transform="translate(40.689 35.743)"
                  >
                    <g
                      id="Group_294781"
                      data-name="Group 294781"
                      transform="translate(0)"
                    >
                      <path
                        id="Path_158136"
                        data-name="Path 158136"
                        d="M194.8,166.008h-.909a.379.379,0,0,1-.367-.279,5.385,5.385,0,0,0-.527-1.271.379.379,0,0,1,.062-.457l.643-.644a.39.39,0,0,0,0-.551l-1.055-1.054a.39.39,0,0,0-.551,0l-.643.643a.379.379,0,0,1-.457.062,5.368,5.368,0,0,0-1.271-.527.379.379,0,0,1-.279-.367v-.909a.39.39,0,0,0-.39-.39H187.56a.39.39,0,0,0-.39.39v.909a.379.379,0,0,1-.279.367,5.369,5.369,0,0,0-1.271.527.379.379,0,0,1-.457-.062l-.643-.643a.39.39,0,0,0-.551,0l-1.055,1.054a.39.39,0,0,0,0,.551l.643.644a.379.379,0,0,1,.062.457,5.354,5.354,0,0,0-.527,1.271.379.379,0,0,1-.367.279h-.909a.39.39,0,0,0-.39.39v1.492a.39.39,0,0,0,.39.39h.909a.379.379,0,0,1,.367.279,5.378,5.378,0,0,0,.527,1.271.379.379,0,0,1-.062.457l-.643.643a.39.39,0,0,0,0,.551l1.055,1.055a.39.39,0,0,0,.551,0l.643-.643a.379.379,0,0,1,.457-.062,5.379,5.379,0,0,0,1.271.527.379.379,0,0,1,.279.367v.909a.39.39,0,0,0,.39.39h1.492a.39.39,0,0,0,.39-.39v-.909a.379.379,0,0,1,.279-.367,5.378,5.378,0,0,0,1.271-.527.379.379,0,0,1,.457.062l.643.643a.39.39,0,0,0,.551,0l1.055-1.055a.39.39,0,0,0,0-.551l-.643-.643a.379.379,0,0,1-.062-.457,5.361,5.361,0,0,0,.527-1.271.379.379,0,0,1,.367-.279h.909a.39.39,0,0,0,.39-.39V166.4A.39.39,0,0,0,194.8,166.008Z"
                        transform="translate(-181.425 -160.263)"
                        fill="#70d6f9"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294784"
                    data-name="Group 294784"
                    transform="translate(44.533 39.586)"
                  >
                    <g id="Group_294783" data-name="Group 294783">
                      <path
                        id="Path_158137"
                        data-name="Path 158137"
                        d="M200.069,181.945a3.037,3.037,0,1,1,3.037-3.037A3.037,3.037,0,0,1,200.069,181.945Z"
                        transform="translate(-197.032 -175.871)"
                        fill="#3caadc"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294786"
                    data-name="Group 294786"
                    transform="translate(36.798 30.137)"
                  >
                    <g id="Group_294785" data-name="Group 294785">
                      <ellipse
                        id="Ellipse_3545"
                        data-name="Ellipse 3545"
                        cx="2.093"
                        cy="2.093"
                        rx="2.093"
                        ry="2.093"
                        fill="#f9d0b4"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294788"
                    data-name="Group 294788"
                    transform="translate(34.828 34.57)"
                  >
                    <g
                      id="Group_294787"
                      data-name="Group 294787"
                      transform="translate(0)"
                    >
                      <path
                        id="Path_158138"
                        data-name="Path 158138"
                        d="M159.723,155.5h3.926a2.107,2.107,0,0,1,2.1,2.1v.837l-.778.778a.389.389,0,0,0-.074.444,5.79,5.79,0,0,1-7.274-.694V157.6A2.107,2.107,0,0,1,159.723,155.5Z"
                        transform="translate(-157.623 -155.5)"
                        fill="#3a70bf"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294790"
                    data-name="Group 294790"
                    transform="translate(40.073 35.127)"
                  >
                    <g
                      id="Group_294789"
                      data-name="Group 294789"
                      transform="translate(0)"
                    >
                      <path
                        id="Path_158139"
                        data-name="Path 158139"
                        d="M187.164,172.755h-1.492a1.007,1.007,0,0,1-1.005-1.005v-.732a6.019,6.019,0,0,1-1.082-.449l-.518.518a1.008,1.008,0,0,1-1.422,0l-1.055-1.055a1.006,1.006,0,0,1,0-1.422l.518-.518a5.989,5.989,0,0,1-.449-1.082h-.732A1.007,1.007,0,0,1,178.922,166v-1.492a1.007,1.007,0,0,1,1.005-1.005h.732a6,6,0,0,1,.449-1.082l-.518-.518a1.007,1.007,0,0,1,0-1.422l1.055-1.055a1.005,1.005,0,0,1,1.422,0l.518.518a5.988,5.988,0,0,1,1.082-.449v-.732a1.007,1.007,0,0,1,1.005-1.005h1.492a1.007,1.007,0,0,1,1.006,1.005v.732a5.988,5.988,0,0,1,1.082.449l.518-.518a1.007,1.007,0,0,1,1.422,0l1.055,1.054a1.007,1.007,0,0,1,0,1.422l-.518.518a5.986,5.986,0,0,1,.449,1.082h.732a1.007,1.007,0,0,1,1.005,1.005V166a1.007,1.007,0,0,1-1.005,1.006h-.732a5.983,5.983,0,0,1-.449,1.082l.518.518a1.006,1.006,0,0,1,0,1.422l-1.055,1.055a1.007,1.007,0,0,1-1.421,0l-.519-.519a5.993,5.993,0,0,1-1.082.449v.732A1.007,1.007,0,0,1,187.164,172.755Zm-1.266-1.231h1.04v-.683a1,1,0,0,1,.734-.961,4.768,4.768,0,0,0,1.125-.467,1,1,0,0,1,1.2.16l.484.484.735-.735-.484-.484a1,1,0,0,1-.16-1.2,4.752,4.752,0,0,0,.467-1.125,1,1,0,0,1,.961-.734h.683v-1.04H192a1,1,0,0,1-.961-.734,4.763,4.763,0,0,0-.467-1.126,1,1,0,0,1,.16-1.2l.484-.484-.735-.735-.484.484a1,1,0,0,1-1.2.16,4.745,4.745,0,0,0-1.126-.467,1,1,0,0,1-.734-.961v-.683H185.9v.683a1,1,0,0,1-.734.961,4.765,4.765,0,0,0-1.125.467,1,1,0,0,1-1.2-.159l-.484-.484-.735.735.484.484a1,1,0,0,1,.159,1.2A4.769,4.769,0,0,0,181.8,164a1,1,0,0,1-.961.734h-.683v1.04h.683a1,1,0,0,1,.961.734,4.737,4.737,0,0,0,.467,1.125,1,1,0,0,1-.16,1.2l-.484.484.735.735.483-.484a1,1,0,0,1,1.2-.16,4.766,4.766,0,0,0,1.126.467,1,1,0,0,1,.734.961Zm-4.7-3.273h0Zm11.713-3.512h0ZM181.2,162.266h0Z"
                        transform="translate(-178.922 -157.762)"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294792"
                    data-name="Group 294792"
                    transform="translate(27.131 22.147)"
                  >
                    <g id="Group_294791" data-name="Group 294791">
                      <path
                        id="Path_158140"
                        data-name="Path 158140"
                        d="M139.334,128.577h-2.416a1.248,1.248,0,0,1-1.247-1.247v-1.472a9.323,9.323,0,0,1-2.2-.914l-1.041,1.043a1.248,1.248,0,0,1-1.763,0l-1.708-1.708a1.247,1.247,0,0,1,0-1.764L130,121.472a9.343,9.343,0,0,1-.914-2.2l-1.472,0a1.248,1.248,0,0,1-1.247-1.247v-2.416a1.248,1.248,0,0,1,1.247-1.247h1.472a9.314,9.314,0,0,1,.914-2.2l-1.043-1.041a1.249,1.249,0,0,1,0-1.764l1.709-1.708a1.246,1.246,0,0,1,1.764,0l1.042,1.041a9.324,9.324,0,0,1,2.2-.913l0-1.472a1.248,1.248,0,0,1,1.247-1.247h2.416a1.248,1.248,0,0,1,1.247,1.247v1.472a9.341,9.341,0,0,1,2.2.914l1.041-1.043a1.249,1.249,0,0,1,1.763,0l1.709,1.709a1.247,1.247,0,0,1,0,1.764l-1.041,1.042a9.337,9.337,0,0,1,.913,2.2l1.472,0a1.248,1.248,0,0,1,1.247,1.247v2.416a1.248,1.248,0,0,1-1.247,1.247H147.6a.616.616,0,0,1,0-1.231h1.039l.016-2.432-1.488-.016a1.232,1.232,0,0,1-1.188-.908,8.09,8.09,0,0,0-.794-1.913,1.232,1.232,0,0,1,.2-1.483l1.041-1.042-1.708-1.731-1.064,1.042a1.232,1.232,0,0,1-1.483.2,8.107,8.107,0,0,0-1.913-.793,1.233,1.233,0,0,1-.907-1.188V106.3l-2.432-.016-.016,1.488a1.233,1.233,0,0,1-.907,1.188,8.071,8.071,0,0,0-1.913.794,1.233,1.233,0,0,1-1.483-.2l-1.042-1.042-1.731,1.708,1.042,1.065a1.233,1.233,0,0,1,.2,1.483,8.1,8.1,0,0,0-.794,1.913,1.232,1.232,0,0,1-1.188.907h-1.472l-.016,2.432,1.488.016a1.232,1.232,0,0,1,1.188.908,8.087,8.087,0,0,0,.794,1.913,1.233,1.233,0,0,1-.2,1.483l-1.042,1.042,1.708,1.731,1.064-1.042a1.232,1.232,0,0,1,1.483-.2,8.088,8.088,0,0,0,1.913.794,1.233,1.233,0,0,1,.907,1.188v1.472l2.4.016a.138.138,0,0,0,0-.016v-.99a.616.616,0,1,1,1.231,0v.99a1.213,1.213,0,0,1-1.205,1.247Z"
                        transform="translate(-126.366 -105.056)"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294794"
                    data-name="Group 294794"
                    transform="translate(32.483 27.5)"
                  >
                    <g
                      id="Group_294793"
                      data-name="Group 294793"
                      transform="translate(0)"
                    >
                      <path
                        id="Path_158141"
                        data-name="Path 158141"
                        d="M154.508,139.607a6.408,6.408,0,1,1,5.3-2.809.616.616,0,1,1-1.018-.692,5.179,5.179,0,1,0-1.415,1.4.616.616,0,0,1,.684,1.024A6.377,6.377,0,0,1,154.508,139.607Z"
                        transform="translate(-148.1 -126.79)"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294796"
                    data-name="Group 294796"
                    transform="translate(43.917 38.971)"
                  >
                    <g id="Group_294795" data-name="Group 294795">
                      <path
                        id="Path_158142"
                        data-name="Path 158142"
                        d="M198.183,180.676a3.652,3.652,0,1,1,3.653-3.652A3.656,3.656,0,0,1,198.183,180.676Zm0-6.073a2.421,2.421,0,1,0,2.421,2.421A2.424,2.424,0,0,0,198.183,174.6Z"
                        transform="translate(-194.531 -173.371)"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294798"
                    data-name="Group 294798"
                    transform="translate(36.182 29.521)"
                  >
                    <g id="Group_294797" data-name="Group 294797">
                      <path
                        id="Path_158143"
                        data-name="Path 158143"
                        d="M165.832,140.418a2.709,2.709,0,1,1,2.709-2.709A2.712,2.712,0,0,1,165.832,140.418Zm0-4.186a1.478,1.478,0,1,0,1.478,1.478A1.479,1.479,0,0,0,165.832,136.231Z"
                        transform="translate(-163.123 -135)"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_294800"
                    data-name="Group 294800"
                    transform="translate(34.212 33.954)"
                  >
                    <g
                      id="Group_294799"
                      data-name="Group 294799"
                      transform="translate(0)"
                    >
                      <path
                        id="Path_158144"
                        data-name="Path 158144"
                        d="M155.739,157.5a.616.616,0,0,1-.616-.616v-1.173A2.719,2.719,0,0,1,157.839,153h3.926a2.719,2.719,0,0,1,2.716,2.716v.724a.616.616,0,0,1-1.231,0v-.724a1.487,1.487,0,0,0-1.485-1.485h-3.926a1.486,1.486,0,0,0-1.485,1.485v1.173A.616.616,0,0,1,155.739,157.5Z"
                        transform="translate(-155.123 -153)"
                      />
                    </g>
                  </g>
                </g>
              </svg>
              <div className="p-2">
                <h5 className="font-bold text-[19px]">Plans</h5>
                <h6 className="text-gray-500 mt-0.5">
                  {" "}
                  Avail best plan for career{" "}
                </h6>
              </div>
            </a>
          </li>{" "}
        </ul>
        <div className="p-6 bg-gray-50 text-medium text-gray-500 dark:text-gray-400 dark:bg-gray-800 rounded-lg w-full">
          <h3 className="text-lg font-bold text-gray-900 dark:text-white mb-2">
            Profile Tab
          </h3>
          <p className="mb-2">
            This is some placeholder content the Profile tab's associated
            content, clicking another tab will toggle the visibility of this one
            for the next.
          </p>
          <p>
            The tab JavaScript swaps classes to control the content visibility
            and styling.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Guidance;
