import { IoEyeOutline } from "react-icons/io5";
import { GoDotFill } from "react-icons/go";
import Pagination from "@mui/material/Pagination";
import { useNavigate } from "react-router-dom";

function RecentlyAddedCards({ blogsData, ChangePage, dataLimit, totalCount }) {
	const navigate = useNavigate();
	// const formatDate = (dateString) => {
	// 	const options = { day: "numeric", month: "long", year: "numeric" };
	// 	const date = new Date(dateString);
	// 	return  new Intl.DateTimeFormat("en-GB", options).format(date);
	// };
	return (
		<div className="ml-5 mb-1">
			<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
				{blogsData?.allBlogs?.map((item, index) => (
					<div
						onClick={() => navigate(`/blog-details/${item?.id}`)}
						key={index}
						className="bg-white shadow-lg rounded-xl dark:bg-gray-800 dark:border-gray-700"
					>
						<div>
							<img
								className="rounded-t-lg w-full h-[12.5rem]"
								src={item?.cover_image}
								alt="BackgroundImage"
							/>
						</div>
						<div>
							<div className="flex flex-wrap gap-[10px] mb-2 pl-[16px] pt-[16px]">
								{item?.tags?.split(",").map((tag, idx) => (
									<p
										key={idx}
										className="font-plusJakartaSans rounded-full bg-[#F37400] bg-opacity-10 pt-[7px] pb-[7px] pl-4 pr-4 inline-flex text-[13px] text-center items-center gap-1 font-medium cursor-pointer"
									>
										{tag?.trim()}
									</p>
								))}
							</div>
							<p className="font-plusJakartaSans font-bold text-[17px] pl-[16px] pt-[4px] pr-[10px]">
								{item?.title}
							</p>
							<p className="flex items-center text-center gap-1 pt-[12px] pb-[16px] pl-[14px] pr-[8px]">
								<IoEyeOutline className="w-5 h-5 text-[#000000] text-opacity-80" />
								<span className="font-plusJakartaSans font-medium text-[13.5px] miniDesk:text-[12px] text-[#000000] text-opacity-80">
									44k views
								</span>
								<GoDotFill className="text-[#000000] text-opacity-40 w-2 h-2" />
								<span className="font-plusJakartaSans font-medium text-[13.5px] miniDesk:text-[12px] text-[#000000] text-opacity-80">
									{item?.time_taken}
								</span>
								<GoDotFill className="text-[#000000] text-opacity-40 w-2 h-2" />
								<span className="font-plusJakartaSans font-medium text-[13.5px] miniDesk:text-[12px] text-[#000000] text-opacity-80">
									{item?.created_at}
								</span>
							</p>
						</div>
					</div>
				))}
			</div>
			<div className="mb-5 mt-[15px]">
				<Pagination
					onChange={ChangePage}
					count={Math.ceil(totalCount / dataLimit)}
					variant="outlined"
					color="primary"
				/>
			</div>
		</div>
	);
}

export default RecentlyAddedCards;
