import { mediaResources } from "../Constants";

export const medias = {
  images: {
    header: {
      pagelogo: `${mediaResources}/Header/pagelogo.svg`,
      graduationCap: `${mediaResources}/Header/graduationCap.svg`,
      select: `${mediaResources}/Header/select.svg`,
      bell: `${mediaResources}/Header/bell.svg`,
      profile: `${mediaResources}/Header/profile.svg`,
    },

    Blogs: {
      Cap: `${mediaResources}/Blogs/cap.svg`,
      HeaderImage: `${mediaResources}/Blogs/HeaderImage.png`,
      Fire: `${mediaResources}/Blogs/Fire.png`,
      University: `${mediaResources}/Blogs/university.jpg`,
      Human: `${mediaResources}/Blogs/human.jpg`,
      HumanEye:  `${mediaResources}/Blogs/human-eye.svg`,
      Share:  `${mediaResources}/Blogs/share.svg`,
      Chat1:  `${mediaResources}/Blogs/chat 1.svg`,
      CollegeImage:  `${mediaResources}/Blogs/CollegeImage.jpg`,
      CollegeImage1:  `${mediaResources}/Blogs/CollegeImage2.jpg`,
      PopularBlogs:  `${mediaResources}/Blogs/PopularBlogs.png`,
      Books:  `${mediaResources}/Blogs/Books.png`,
      Editors:  `${mediaResources}/Blogs/Editors.png`,
      RecentlyAdded:  `${mediaResources}/Blogs/RecentlyAdded.png`,
      Bg1:  `${mediaResources}/Blogs/Bg1.png`,
      Course:  `${mediaResources}/Blogs/course.svg`,
      Phone:  `${mediaResources}/Blogs/Phone.svg`,
      Email: `${mediaResources}/Blogs/email.svg`,
    },
  
    Exam: {
      BackgroundImage: `${mediaResources}/EntranceExam/BackgroundImage.png`,
      ShareButton: `${mediaResources}/EntranceExam/shareButton.svg`,
      LeftArrow: `${mediaResources}/EntranceExam/LeftArrow.svg`,
      IconFeather: `${mediaResources}/EntranceExam/Icon feather.svg`,
      CrystallBall: `${mediaResources}/EntranceExam/crystal-ball.svg`,
      EntranceHeadPage: `${mediaResources}/EntranceExam/EntrancePage.png`,
      Exam: `${mediaResources}/EntranceExam/Exam.svg`,
      Highlights: `${mediaResources}/EntranceExam/Highlights.svg`,
      Cap: `${mediaResources}/EntranceExam/cap.svg`,
      Engineering: `${mediaResources}/EntranceExam/engineering.svg`,
      Option: `${mediaResources}/EntranceExam/option.svg`,
      Home: `${mediaResources}/EntranceExam/Home.svg`,
      FlowChart: `${mediaResources}/EntranceExam/flowchart.png`,
      ImagesBack: `${mediaResources}/EntranceExam/imagesback.jpeg`,
      Papers: `${mediaResources}/EntranceExam/Papers.svg`,
      Icon: `${mediaResources}/EntranceExam/icon.svg`,
      QuestionBg: `${mediaResources}/EntranceExam/QuestionBg.svg`,
      Eligibility: `${mediaResources}/EntranceExam/Eligibility.svg`,
      Registration: `${mediaResources}/EntranceExam/Registration.svg`,
      Education: `${mediaResources}/EntranceExam/education.svg`,
      Website: `${mediaResources}/EntranceExam/Website.svg`,
      Phone: `${mediaResources}/EntranceExam/phone.svg`,
      FormCorrections: `${mediaResources}/EntranceExam/FormCorrections.svg`,
      ApplicationProcedure: `${mediaResources}/EntranceExam/ApplicationProcedure.svg`,
      AdmitCards: `${mediaResources}/EntranceExam/AdmitCards.svg`,
      Offline: `${mediaResources}/EntranceExam/Offline.svg`,
      Privilege: `${mediaResources}/EntranceExam/privilege.svg`,
      ExamDayChecklist: `${mediaResources}/EntranceExam/ExamDayChecklist.svg`,
      Syllabus: `${mediaResources}/EntranceExam/Syllabus.svg`,
      Important: `${mediaResources}/EntranceExam/Important.svg`,
      ImportantDates: `${mediaResources}/EntranceExam/ImportantDates.svg`,
      SyllabusPoints: `${mediaResources}/EntranceExam/Syllabus.png`,
      Highlights1: `${mediaResources}/EntranceExam/Highlights.png`,
      ScoreCard: `${mediaResources}/EntranceExam/ScoreCard.png`,
      TopCandidates: `${mediaResources}/EntranceExam/Candidate.png`,
      AnswerKeys: `${mediaResources}/EntranceExam/AnswerKeys.png`,
      Councelling: `${mediaResources}/EntranceExam/Councelling.svg`,
      CheckList1: `${mediaResources}/EntranceExam/Checklist1.svg`,
      ChoiceModification: `${mediaResources}/EntranceExam/ChoiceModification.svg`,
      Register: `${mediaResources}/EntranceExam/Register.svg`,
      Reservation: `${mediaResources}/EntranceExam/Reservation.svg`,
      FeeApplicable: `${mediaResources}/EntranceExam/FeeApplicable.svg`,
      Pdf: `${mediaResources}/EntranceExam/Pdf.svg`,
      CollegeDetails: `${mediaResources}/EntranceExam/CollegesImages.png`,
      Chat: `${mediaResources}/EntranceExam/chat (1).svg`,
      Clock: `${mediaResources}/EntranceExam/clock (2).svg`,
      HumanEyes: `${mediaResources}/EntranceExam/human-eye.svg`,
      ShareImage: `${mediaResources}/EntranceExam/share.svg`,
    },
    College: {
      Clock:`${mediaResources}/Colleges/clock.png`,
      Course2:`${mediaResources}/Colleges/course.svg`,
      Payment:`${mediaResources}/Colleges/Payment.png`,
      Cap3:`${mediaResources}/Colleges/Cap3.png`,
      Chair2:`${mediaResources}/Colleges/chair.png`,
      Thumb1:`${mediaResources}/Colleges/thumbs-up.svg`,
      SelectionProcedure: `${mediaResources}/Colleges/selectionProcedure.svg`,
      Chair: `${mediaResources}/Colleges/Chair.svg`,
      ExamPattern: `${mediaResources}/Colleges/examPattern.svg`,
      Procedure: `${mediaResources}/Colleges/Procedure.svg`,
      Benefits: `${mediaResources}/Colleges/benefits.svg`,
      Eligibility4: `${mediaResources}/Colleges/Eligibility4.svg`,
      LeftChevron: `${mediaResources}/Colleges/left-chevron.svg`,
      Calender: `${mediaResources}/Colleges/Calender.svg`,
      Phone: `${mediaResources}/Colleges/phone.svg`,
      Web: `${mediaResources}/Colleges/Web.svg`,
      Money: `${mediaResources}/Colleges/money.svg`,
      ApplicationFee: `${mediaResources}/Colleges/ApplicationFww.svg`,
      Merit: `${mediaResources}/Colleges/merit.svg`,
      Olymplad: `${mediaResources}/Colleges/olymplad.svg`,
      Arrow2: `${mediaResources}/Colleges/arrow2.svg`,
      UniversityCollege: `${mediaResources}/Colleges/universityImage.jpg`,
      RightIcon: `${mediaResources}/Colleges/RightIcon.svg`,
      ResearchIcon: `${mediaResources}/Colleges/ResearchsIcon.png`,
      Research1: `${mediaResources}/Colleges/Research2.png`,
      Research2: `${mediaResources}/Colleges/Research2.png`,
      Research3: `${mediaResources}/Colleges/Research3.png`,
      Research4: `${mediaResources}/Colleges/Research4.png`,
      ClubIcon: `${mediaResources}/Colleges/ClubIcon.png`,
      Club1: `${mediaResources}/Colleges/Club1.svg`,
      Club2: `${mediaResources}/Colleges/Club2.svg`,
      Club3: `${mediaResources}/Colleges/Club3.png`,
      Club4: `${mediaResources}/Colleges/club4.png`,
      Event1: `${mediaResources}/Colleges/Event1.png`,
      Event2: `${mediaResources}/Colleges/Event2.png`,
      Event3: `${mediaResources}/Colleges/Event3.png`,
      Event4: `${mediaResources}/Colleges/Event4.png`,
      EventIcon: `${mediaResources}/Colleges/EventIcon.png`,
      Generic: `${mediaResources}/Colleges/welfare.svg`,
      Opportunity: `${mediaResources}/Colleges/opportunity.svg`,
      Girls: `${mediaResources}/Colleges/GirlsHostel.svg`,
      Boys: `${mediaResources}/Colleges/BoysHostel.svg`,
      Accomodation: `${mediaResources}/Colleges/Accomodation.svg`,
      Medical: `${mediaResources}/Colleges/medical.svg`,
      Sports: `${mediaResources}/Colleges/sports.svg`,
      Parking: `${mediaResources}/Colleges/Parking.svg`,
      Cafeteria: `${mediaResources}/Colleges/Cafeteria.svg`,
      Library: `${mediaResources}/Colleges/Library.svg`,
      Gym: `${mediaResources}/Colleges/gym.svg`,
      Hostel: `${mediaResources}/Colleges/hostel.svg`,
      Auditorium: `${mediaResources}/Colleges/Auditorium.svg`,
      Campus4: `${mediaResources}/Colleges/Campus4.png`,
      Campus3: `${mediaResources}/Colleges/Campus3.png`,
      Campus2: `${mediaResources}/Colleges/Campus2.png`,
      Campus1: `${mediaResources}/Colleges/Campus1.png`,
      Campus: `${mediaResources}/Colleges/Campus.png`,
      Brief: `${mediaResources}/Colleges/breif.svg`,
      Briefcase1: `${mediaResources}/Colleges/briefcase1.svg`,
      Briefcase: `${mediaResources}/Colleges/briefcase.svg`,
      Salary: `${mediaResources}/Colleges/salary.svg`,
      image12: `${mediaResources}/Colleges/image12.png`,
      image13: `${mediaResources}/Colleges/image13.png`,
      CopyPen: `${mediaResources}/Colleges/CopyPen.svg`,
      Google: `${mediaResources}/Colleges/Google.png`,
      Infosys: `${mediaResources}/Colleges/Infosysis.png`,
      Accenture: `${mediaResources}/Colleges/accenture.png`,
      Amazon: `${mediaResources}/Colleges/Amazon.png`,
      Company: `${mediaResources}/Colleges/company.svg`,
      Placement: `${mediaResources}/Colleges/Placement.svg`,
      Bag: `${mediaResources}/Colleges/Bag1.svg`,
      Student: `${mediaResources}/Colleges/Student.svg`,
      HumanEye: `${mediaResources}/Colleges/human-eye.svg`,
      logo20: `${mediaResources}/Colleges/logo20.png`,
      Rectangle1: `${mediaResources}/Colleges/Rectangle1.svg`,
      Frame: `${mediaResources}/Colleges/Frame.svg`,
      Rectangle2: `${mediaResources}/Colleges/Rectangle2.png`,
      Icon: `${mediaResources}/Colleges/icon1.svg`,
      Calender3: `${mediaResources}/Colleges/calendar3.svg`,
      Pen1: `${mediaResources}/Colleges/Pen1.svg`,
      Image11: `${mediaResources}/Colleges/Image 115.png`,
      EngineerindCollegesBg: `${mediaResources}/Colleges/ECBg.png `,
      Calender2: `${mediaResources}/Colleges/calendar (2).svg`,
      Eligibility1: `${mediaResources}/Colleges/Eligibility1.svg`,
      Selection: `${mediaResources}/Colleges/Selection.svg`,
      Reservation: `${mediaResources}/Colleges/Reservation.svg`,
      Application: `${mediaResources}/Colleges/test.svg`,
      Up1: `${mediaResources}/Colleges/Up1.svg`,
      DropDown1: `${mediaResources}/Colleges/DropDown1.svg`,
      CoursesOffered: `${mediaResources}/Colleges/CoursesOffered.svg`,
      Logo11: `${mediaResources}/Colleges/logo11.png`,
      Network: `${mediaResources}/Colleges/network.svg`,
      Suitcase: `${mediaResources}/Colleges/suitcase.svg`,
      Infrastructur1e: `${mediaResources}/Colleges/apartment.svg`,
      Teacher: `${mediaResources}/Colleges/teacher.svg`,
      Accom: `${mediaResources}/Colleges/Accomo.svg`,
      Aca: `${mediaResources}/Colleges/Aca.svg`,
      Thumb: `${mediaResources}/Colleges/thumb.svg`,
      DreamColleges: `${mediaResources}/Colleges/DreamColleges.svg`,
      AiBased: `${mediaResources}/Colleges/AiBasedCourse.svg`,
      GetAnswer: `${mediaResources}/Colleges/GetAnswer.svg`,
      CourseFinder: `${mediaResources}/Colleges/CourseFinder.svg`,
      CrystalBall: `${mediaResources}/Colleges/crystal-ball.svg`,
      ExamAccepted: `${mediaResources}/Colleges/examaccepted.svg`,
      UnderGraduate: `${mediaResources}/Colleges/underGraduate.svg`,
      Seats: `${mediaResources}/Colleges/seats.svg`,
      Bachelors: `${mediaResources}/Colleges/Bachelors.svg`,
      FullTime: `${mediaResources}/Colleges/FullTIme.svg`,
      Degree: `${mediaResources}/Colleges/degree.svg`,
      Linkdein1: `${mediaResources}/Colleges/linkedin1.png`,
      BriefCase: `${mediaResources}/Colleges/briefcase.png`,
      Nutrition: `${mediaResources}/Colleges/Image 23.png`,
      CollegeLogo: `${mediaResources}/Colleges/CollegeLogo.png`,
      BackGroundIMage: `${mediaResources}/Colleges/BackgroundImage.png`,
      PlayButton: `${mediaResources}/Colleges/play-button.svg`,
      Pen: `${mediaResources}/Colleges/pen.svg`,
      Report: `${mediaResources}/Colleges/Report.svg`,
      Cons: `${mediaResources}/Colleges/Cons.svg`,
      Pros: `${mediaResources}/Colleges/Pros.svg`,
      Image10: `${mediaResources}/Colleges/image10.png`,
      DropDown: `${mediaResources}/Colleges/dropdown.svg`,
      SortBy: `${mediaResources}/Colleges/sort.svg`,
      Filters: `${mediaResources}/Colleges/Filters1.svg`,
      SocialLife: `${mediaResources}/Colleges/SocailLife.png`,
      Placements: `${mediaResources}/Colleges/Placements (2).png`,
      Ranking: `${mediaResources}/Colleges/Ranking.png`,
      Academics: `${mediaResources}/Colleges/Academics.png`,
      Courses1: `${mediaResources}/Colleges/Courses.png`,
      Accomodations1: `${mediaResources}/Colleges/Accomodation.png`,
      Admissions: `${mediaResources}/Colleges/Admissions.png`,
      Infrastructure: `${mediaResources}/Colleges/Infrastructure.png`,
      ImageLogo: `${mediaResources}/Colleges/imageLogo.png`,
      Reviews: `${mediaResources}/Colleges/reviewsbg.png`,
      Youtube: `${mediaResources}/Colleges/Youtube.svg`,
      Instagram: `${mediaResources}/Colleges/instagram.svg`,
      Facebook: `${mediaResources}/Colleges/facebook.svg`,
      Linkdein: `${mediaResources}/Colleges/linkdein.svg`,
      WorldWide: `${mediaResources}/Colleges/worldWide.svg`,
      Message: `${mediaResources}/Colleges/message.svg`,
      Telephone: `${mediaResources}/Colleges/Telephone.svg`,
      Logo5: `${mediaResources}/Colleges/logo5.png`,
      MapLocation: `${mediaResources}/Colleges/mapLocation.png`,
      Faq: `${mediaResources}/Colleges/Faq.svg`,
      Eligibility: `${mediaResources}/Colleges/Eligibility.svg`,
      Courses: `${mediaResources}/Colleges/courses.svg`,
      Find: `${mediaResources}/Colleges/find.svg`,
      getMoney: `${mediaResources}/Colleges/getmoney.svg`,
      Yes: `${mediaResources}/Colleges/yes.svg`,
      Girl: `${mediaResources}/Colleges/Girl.svg`,
      Boy: `${mediaResources}/Colleges/Boy.svg`,
      Contract: `${mediaResources}/Colleges/contract.svg`,
      CollegeFounded: `${mediaResources}/Colleges/Founded.svg`,
      Acrreditations: `${mediaResources}/Colleges/Accreditations.svg`,
      Affiliations: `${mediaResources}/Colleges/Affiliations.svg`,
      Associations: `${mediaResources}/Colleges/Associations.svg`,
      Accomodations: `${mediaResources}/Colleges/Accomodations.svg`,
      Approved: `${mediaResources}/Colleges/Approved.svg`,
      University: `${mediaResources}/Colleges/univer.svg`,
    },
    mentorship: {
      scienceTechnology: `${mediaResources}/mentorship/g1929.svg`,
      rightArrow: `${mediaResources}/mentorship/rightArrow.svg`,
      hand: `${mediaResources}/mentorship/hand.png`,
      run: `${mediaResources}/mentorship/runn.svg`,
      calender: `${mediaResources}/mentorship/calendar.svg`,
      clock: `${mediaResources}/mentorship/clock.svg`,
      google: `${mediaResources}/mentorship/googlenew.png`,
      netflix: `${mediaResources}/mentorship/netflix.png`,
      ibm: `${mediaResources}/mentorship/ibm.png`,
      meta: `${mediaResources}/mentorship/metanew.png`,

      amazon: `${mediaResources}/mentorship/amazon.png`,
      mentorTech: `${mediaResources}/mentorship/meta.png`,
      img1: `${mediaResources}/mentorship/1.png`,
      MentorCover: `${mediaResources}/mentorship/MentorCover.png`,

      Man: `${mediaResources}/mentorship/man.png`,

      career: {
        career1: `${mediaResources}/mentorship/carrer1.png`,
        career2: `${mediaResources}/mentorship/carrer2.png`,
        career3: `${mediaResources}/mentorship/carrer3.png`,
        career4: `${mediaResources}/mentorship/carrer4.png`,
      },
      Media: {
        Media1: `${mediaResources}/mentorship/media1.png`,
        Media2: `${mediaResources}/mentorship/media2.png`,
        Media3: `${mediaResources}/mentorship/media3.png`,
        Media4: `${mediaResources}/mentorship/media4.png`,
      },
    },

    graduate: {
      Face1Icon: `${mediaResources}/Graduate/hero.svg`,
      Face2: {
        CareerGuide1: `${mediaResources}/Graduate/1.svg`,
        CareerGuide2: `${mediaResources}/Graduate/2.svg`,
        CareerGuide3: `${mediaResources}/Graduate/3.svg`,
        CareerGuide4: `${mediaResources}/Graduate/4.svg`,
        CareerGuide5: `${mediaResources}/Graduate/5.svg`,
      },
    },
    eleven: {
      Face1Icon: `${mediaResources}/eleven/main.png`,
      Face2Icon: `${mediaResources}/eleven/1.png`,
      worker: `${mediaResources}/eleven/2.png`,
      phone: `${mediaResources}/eleven/phone.png`,
    },
    guidance: {
      GuidanceFace1: `${mediaResources}/Guidance/GuidanceFace1.svg`,
      Face1Icon: `${mediaResources}/Guidance/Face1Icon.svg`,
      unigole: `${mediaResources}/Guidance/unigole.png`,
      Face1TitleTopIcon: `${mediaResources}/Guidance/Face1TitleTopIcon.svg`,
      Face2: {
        CareerGuide1: `${mediaResources}/Guidance/CareerGuide1.svg`,
        CareerGuide2: `${mediaResources}/Guidance/CareerGuide2.svg`,
        CareerGuide3: `${mediaResources}/Guidance/CareerGuide3.svg`,
        CareerGuide4: `${mediaResources}/Guidance/CareerGuide4.svg`,
        CareerGuide5: `${mediaResources}/Guidance/CareerGuide5.svg`,
      },

      Face3: {
        face3main: `${mediaResources}/Guidance/Face3.svg`,
      },
      Face4: {
        face4Base: `${mediaResources}/Guidance/face4Base.png`,
        face4languageImages: `${mediaResources}/Guidance/face4languageImages.png`,
      },
      Face5: {
        face5roadMap: `${mediaResources}/Guidance/face5roadMap.png`,
        Plan1: `${mediaResources}/Guidance/Plan1.svg`,
        Plan2: `${mediaResources}/Guidance/Plan2.svg`,
        Plan3: `${mediaResources}/Guidance/Plan3.svg`,
      },
      Face6: {
        MetaMentor: `${mediaResources}/Guidance/MetaMentor.png`,
        metalogo: `${mediaResources}/Guidance/logo-Meta.png`,
        NetFlixMentor: `${mediaResources}/Guidance/NetFlixMentor.png`,
        netflixlogo: `${mediaResources}/Guidance/netflix-logo.png`,
      },
      Face7: {
        // Universities: `${mediaResources}/Guidance/Universities.png`,
        Universities: `${mediaResources}/Guidance/uni.png`,
        Person1: `${mediaResources}/Guidance/Person1.png`,
        Person2: `${mediaResources}/Guidance/Person2.png`,
        Person3: `${mediaResources}/Guidance/Person3.png`,
        Person4: `${mediaResources}/Guidance/Person4.png`,
        Person5: `${mediaResources}/Guidance/Person5.png`,
        Person6: `${mediaResources}/Guidance/Person6.png`,
        Person7: `${mediaResources}/Guidance/Person7.png`,
        Person8: `${mediaResources}/Guidance/Person8.png`,
        Person9: `${mediaResources}/Guidance/Person9.png`,
        Person10: `${mediaResources}/Guidance/Person10.png`,
      },
      Face8: {
        InvertedComa: `${mediaResources}/Guidance/InvertedComa.png`,
        Testimonial: `${mediaResources}/Guidance/Testimonial.png`,
        ThumbsUp: `${mediaResources}/Guidance/ThumbsUp.png`,
        pathBottom: `${mediaResources}/Guidance/pathBottom.png`,
        Person1Testimony: `${mediaResources}/Guidance/Person1Testimony.png`,
        Person2Testimony: `${mediaResources}/Guidance/Person2Testimony.png`,
      },
      Face9: {
        UnerlineCurve2: `${mediaResources}/Guidance/UnerlineCurve2.png`,
        bullet: `${mediaResources}/Guidance/bullet.png`,
        AppStore: `${mediaResources}/Guidance/AppStore.png`,
        GooglePlay: `${mediaResources}/Guidance/GooglePlay.png`,
        Mobiles: `${mediaResources}/Guidance/Mobiles.png`,
      },
      Face10: {
        LogoBetween: `${mediaResources}/Guidance/LogoBetween.png`,
        Perk1: `${mediaResources}/Guidance/Perk1.png`,
        Perk2: `${mediaResources}/Guidance/Perk2.png`,
        Perk3: `${mediaResources}/Guidance/Perk3.png`,
        Perk4: `${mediaResources}/Guidance/Perk4.png`,
        Perk5: `${mediaResources}/Guidance/Perk5.png`,
        Perk6: `${mediaResources}/Guidance/Perk6.png`,
      },
    },
    nineTenthPlan: {
      Face1: {
        Face1Logo: `${mediaResources}/nineTenthPlan/Face1Logo.svg`,
        Face1MainImage: `${mediaResources}/nineTenthPlan/Face1MainImage.svg`,
        Face1Background: `${mediaResources}/nineTenthPlan/Face1Background.png`,
      },
      Face3: {
        Face3mainImage: `${mediaResources}/nineTenthPlan/Face3mainImage.svg`,
      },
      Face2: {
        Face3Card1: `${mediaResources}/nineTenthPlan/Face3Card1.svg`,
        Face3Card2: `${mediaResources}/nineTenthPlan/Face3Card2.svg`,
        Face3Card3: `${mediaResources}/nineTenthPlan/Face3Card3.svg`,
      },
      Face4: {
        vsPanal: `${mediaResources}/nineTenthPlan/vsPanal.png`,
        divider: `${mediaResources}/nineTenthPlan/divider.png`,
      },
      Face5: {
        playicon: `${mediaResources}/nineTenthPlan/Playicon.png`,
      },
    },
    footer: {
      appStore: `${mediaResources}/Guidance/app-store.png`,
      googlePlay: `${mediaResources}/Guidance/google-play.png`,
      facebook: `${mediaResources}/Guidance/facebook.svg`,
      instagram: `${mediaResources}/Guidance/instagram.svg`,
      linkedin: `${mediaResources}/Guidance/linkedin.svg`,
      youtube: `${mediaResources}/Guidance/youtube.svg`,
      FooterLogo: `${mediaResources}/Guidance/FooterLogo.svg`,
    },
    loginSignup: {
      gridcircle: `${mediaResources}/LoginSignup/gridcircle.json`,
      LoginIcon1: `${mediaResources}/LoginSignup/LoginIcon1.svg`,
      LoginIcon2: `${mediaResources}/LoginSignup/LoginIcon2.svg`,
      LoginIcon3: `${mediaResources}/LoginSignup/LoginIcon3.svg`,
      LoginIcon4: `${mediaResources}/LoginSignup/LoginIcon4.svg`,
      LoginIcon5: `${mediaResources}/LoginSignup/LoginIcon5.svg`,
      LoginIcon6: `${mediaResources}/LoginSignup/LoginIcon6.svg`,
      LoginIcon7: `${mediaResources}/LoginSignup/LoginIcon7.svg`,
      LoginIcon8: `${mediaResources}/LoginSignup/LoginIcon8.svg`,
      LoginIcon9: `${mediaResources}/LoginSignup/LoginIcon9.svg`,
      LoginIcon10: `${mediaResources}/LoginSignup/LoginIcon10.svg`,
    },
    inputIcons: {
      EmailInputIcon: `${mediaResources}/InputIcons/EmailInputIcon.svg`,
      PasswordInputIcon: `${mediaResources}/InputIcons/PasswordInputIcon.svg`,
      FacebookLogo: `${mediaResources}/InputIcons/FacebookLogo.svg`,
      mobileLogo: `${mediaResources}/InputIcons/MobileLogo.svg`,

      GoogleLogo: `${mediaResources}/InputIcons/GoogleLogo.svg`,
      firstNameIcon: `${mediaResources}/InputIcons/firstNameIcon.svg`,
      cityIcon: `${mediaResources}/InputIcons/cityIcon.svg`,
      stateIcon: `${mediaResources}/InputIcons/stateIcon.svg`,
      lastNameIcon: `${mediaResources}/InputIcons/lastNameIcon.svg`,
      countryIcon: `${mediaResources}/InputIcons/countryIcon.svg`,
    },
    intrestImages: {
      globe: `${mediaResources}/IntrestQuestionair/globe.svg`,
      SelectorsDrops: `${mediaResources}/IntrestQuestionair/SelectorsDrops.svg`,
      volumeIcon: `${mediaResources}/IntrestQuestionair/volumeIcon.svg`,
      backmain: `${mediaResources}/IntrestQuestionair/backmain.svg`,
      coinIcon: `${mediaResources}/IntrestQuestionair/coinIcon.svg`,
      baseScreen: `${mediaResources}/IntrestQuestionair/baseScreen.webp`,
      leftbgStone: `${mediaResources}/IntrestQuestionair/leftbgStone.png`,
      stonebottom: `${mediaResources}/IntrestQuestionair/stonebottom.png`,
      questionirebg: `${mediaResources}/IntrestQuestionair/questionirebg.svg`,
      borderBackground: `${mediaResources}/IntrestQuestionair/borderBackground.svg`,
      timerBackgroung: `${mediaResources}/IntrestQuestionair/timerBackgroung.png`,
    },
    personalityImage: {
      timerbg: `${mediaResources}/PersonalityQuestion/timerbg.svg`,
    },
    socialBehaviour: {
      timerbg: `${mediaResources}/SocialBehaviour/timerbg.svg`,
      leftmainbg: `${mediaResources}/SocialBehaviour/leftmainbg.svg`,
      bottommainbg: `${mediaResources}/SocialBehaviour/bottommainbg.svg`,
    },
    aptitude: {
      timebg: `${mediaResources}/aptitude/timebg.png`,
    },
    getStarted: {
      bottommain: `${mediaResources}/getstarted/bottomMain.png`,
    },
    Home: {
      Menu: {
        Home: `${mediaResources}/HomeSection/MenuLayout/Home.svg`,
        MyCollection: `${mediaResources}/HomeSection/MenuLayout/MyCollections.svg`,
        MentorShip: `${mediaResources}/HomeSection/MenuLayout/MentorShip.svg`,
        Documents: `${mediaResources}/HomeSection/MenuLayout/Documents.svg`,
        CareerCounsellingSelected: `${mediaResources}/HomeSection/MenuLayout/CareerCounsellingSelected.svg`,
        bg: `${mediaResources}/HomeSection/MenuLayout/bg.png`,
        OrderPurchases: `${mediaResources}/HomeSection/MenuLayout/OrderPurchases.svg`,
        MyCollections: `${mediaResources}/HomeSection/MenuLayout/MyCollections.svg`,
        MyCourses: `${mediaResources}/HomeSection/MenuLayout/MyCourses.svg`,
        RewaredsBadges: `${mediaResources}/HomeSection/MenuLayout/RewaredsBadges.svg`,
      },
    },
    Profile: {
      Task: `${mediaResources}/Profile/profileBuilding/task.svg`,
      image5: `${mediaResources}/Profile/profileBuilding/image5.png`,
      Promotion: `${mediaResources}/Profile/profileBuilding/promotion.png`,
      Image6: `${mediaResources}/Profile/profileBuilding/image6.svg`,
      Stream: `${mediaResources}/Profile/profileBuilding/stream.svg`,
      line2: `${mediaResources}/Profile/profileBuilding/line2.svg`,
      line1: `${mediaResources}/Profile/profileBuilding/line1.svg`,
      logo: `${mediaResources}/Profile/profileBuilding/logo.png`,
      Colleges: `${mediaResources}/Profile/profileBuilding/Colleges.png`,
      Share: `${mediaResources}/Profile/profileBuilding/share.svg`,
      Heart: `${mediaResources}/Profile/profileBuilding/iconHeart.svg`,
      CrossButton: `${mediaResources}/Profile/profileBuilding/CrossButton.svg`,
      Background: `${mediaResources}/Profile/profileBuilding/backgroung.jpg`,
      ShareButton: `${mediaResources}/Profile/profileBuilding/shareButton.svg`,
      Google: `${mediaResources}/Profile/profileBuilding/google.png`,
      NoPath: `${mediaResources}/Profile/profileBuilding/NoPath.png`,
      Layer2: `${mediaResources}/Profile/profileBuilding/Layer2.svg`,
      Layer1: `${mediaResources}/Profile/profileBuilding/Layer 1.svg`,
      Skills: `${mediaResources}/Profile/profileBuilding/skills1.svg`,
      decision: `${mediaResources}/Profile/profileBuilding/decision.svg`,
      education: `${mediaResources}/Profile/profileBuilding/education.png`,
      healthCare: `${mediaResources}/Profile/profileBuilding/healthcare.svg`,
      correct1: `${mediaResources}/Profile/profileBuilding/correct1.svg`,
      hand: `${mediaResources}/Profile/profileBuilding/hand.svg`,
      cap: `${mediaResources}/Profile/profileBuilding/cap.svg`,
      image1: `${mediaResources}/Profile/profileBuilding/image.png`,
      growth: `${mediaResources}/Profile/profileBuilding/growth.svg`,
      company1: `${mediaResources}/Profile/profileBuilding/comp.svg`,
      category: `${mediaResources}/Profile/profileBuilding/main.svg`,
      job: `${mediaResources}/Profile/profileBuilding/job.svg`,
      settings2: `${mediaResources}/Profile/profileBuilding/settings2.svg`,
      bags: `${mediaResources}/Profile/profileBuilding/bags.svg`,
      message: `${mediaResources}/Profile/profileBuilding/message.svg`,
      location3: `${mediaResources}/Profile/profileBuilding/location3.svg`,
      Road1: `${mediaResources}/Profile/profileBuilding/Road.svg`,
      Daily5: `${mediaResources}/Profile/profileBuilding/Daily5.svg`,
      Sports10: `${mediaResources}/Profile/profileBuilding/sports10.svg`,
      Plus: `${mediaResources}/Profile/profileBuilding/plus.svg`,
      Cross: `${mediaResources}/Profile/profileBuilding/cross.svg`,
      Correct: `${mediaResources}/Profile/profileBuilding/correct.svg`,
      Others: `${mediaResources}/Profile/profileBuilding/Others.svg`,
      HouseHold: `${mediaResources}/Profile/profileBuilding/home.svg`,
      Meals: `${mediaResources}/Profile/profileBuilding/Meals.svg`,
      Sport1: `${mediaResources}/Profile/profileBuilding/Sports.svg`,
      Diet: `${mediaResources}/Profile/profileBuilding/Diet.svg`,
      Gym: `${mediaResources}/Profile/profileBuilding/Gym.svg`,
      Gym1: `${mediaResources}/Profile/profileBuilding/Gym1.svg`,
      Gym2: `${mediaResources}/Profile/profileBuilding/Gym2.svg`,
      Sleep: `${mediaResources}/Profile/profileBuilding/sleep.svg`,
      Durartion: `${mediaResources}/Profile/profileBuilding/Durartion.svg`,
      Anxiety: `${mediaResources}/Profile/profileBuilding/Anixiety.svg`,
      Stress: `${mediaResources}/Profile/profileBuilding/stress.svg`,
      Bp: `${mediaResources}/Profile/profileBuilding/Bp.svg`,
      Fit: `${mediaResources}/Profile/profileBuilding/fit.svg`,
      Bmi: `${mediaResources}/Profile/profileBuilding/Bmi.svg`,
      Date: `${mediaResources}/Profile/profileBuilding/date.svg`,
      Height: `${mediaResources}/Profile/profileBuilding/height.svg`,
      WeightLoass: `${mediaResources}/Profile/profileBuilding/Weight Loss.svg`,
      Location2: `${mediaResources}/Profile/profileBuilding/Location2.svg`,
      NoteBook1: `${mediaResources}/Profile/profileBuilding/notebook1.svg`,
      Calender2: `${mediaResources}/Profile/profileBuilding/calendar (2).svg`,
      Marker: `${mediaResources}/Profile/profileBuilding/marker.svg`,
      Proj5: `${mediaResources}/Profile/profileBuilding/proj5.png`,
      Proj4: `${mediaResources}/Profile/profileBuilding/proj4.png`,
      Proj3: `${mediaResources}/Profile/profileBuilding/proj3.png`,
      Proj2: `${mediaResources}/Profile/profileBuilding/proj2.png`,
      Proj1: `${mediaResources}/Profile/profileBuilding/proj1.png`,
      Milestone: `${mediaResources}/Profile/profileBuilding/milestone.svg`,
      Master: `${mediaResources}/Profile/profileBuilding/Master.png`,
      Video: `${mediaResources}/Profile/profileBuilding/video.svg`,
      Camera: `${mediaResources}/Profile/profileBuilding/camera.svg`,
      Study1: `${mediaResources}/Profile/profileBuilding/StudyModule1.svg`,
      Study: `${mediaResources}/Profile/profileBuilding/StudyModule.svg`,
      Sympathy: `${mediaResources}/Profile/profileBuilding/sympathy.svg`,
      LoveAffection: `${mediaResources}/Profile/profileBuilding/LoveAffection.svg`,
      WorkHard: `${mediaResources}/Profile/profileBuilding/WorkHard.svg`,
      Emotion: `${mediaResources}/Profile/profileBuilding/emotions.svg`,
      Problem: `${mediaResources}/Profile/profileBuilding/Problem.svg`,
      Flexibility: `${mediaResources}/Profile/profileBuilding/Flexibility.svg`,
      Team: `${mediaResources}/Profile/profileBuilding/Team.svg`,
      Critical: `${mediaResources}/Profile/profileBuilding/critical.svg`,
      Time1: `${mediaResources}/Profile/profileBuilding/Time1.svg`,
      NoteBook: `${mediaResources}/Profile/profileBuilding/notebook.svg`,
      Hotstar: `${mediaResources}/Profile/profileBuilding/Hotstar.png`,
      Boat: `${mediaResources}/Profile/profileBuilding/Boat.png`,
      Netflix: `${mediaResources}/Profile/profileBuilding/Netflix.png`,
      Apple: `${mediaResources}/Profile/profileBuilding/Apple.png`,
      Arts: `${mediaResources}/Profile/profileBuilding/Arts.svg`,
      Event: `${mediaResources}/Profile/profileBuilding/Event.svg`,
      Sports: `${mediaResources}/Profile/profileBuilding/Sports.svg`,
      Culinary: `${mediaResources}/Profile/profileBuilding/Culinary.svg`,
      Dance: `${mediaResources}/Profile/profileBuilding/dance.svg`,
      Spiritual: `${mediaResources}/Profile/profileBuilding/spiritual.svg`,
      Indoor: `${mediaResources}/Profile/profileBuilding/indoorOutoor.svg`,
      Social: `${mediaResources}/Profile/profileBuilding/SocialWork.svg`,
      Setting: `${mediaResources}/Profile/profileBuilding/setting.svg`,
      Edit: `${mediaResources}/Profile/profileBuilding/edit.svg`,
      image2: `${mediaResources}/Profile/profileBuilding/image2.svg`,
      Time: `${mediaResources}/Profile/profileBuilding/time.svg`,
      Completed: `${mediaResources}/Profile/profileBuilding/completed.svg`,
      Grade: `${mediaResources}/Profile/profileBuilding/grade.svg`,
      Location: `${mediaResources}/Profile/profileBuilding/location.svg`,
      Image1: `${mediaResources}/Profile/profileBuilding/img1.svg`,
      Road: `${mediaResources}/Profile/profileBuilding/RoadMap.svg`,
      Life: `${mediaResources}/Profile/profileBuilding/Life.svg`,
      Passion: `${mediaResources}/Profile/profileBuilding/passion.svg`,
      Health: `${mediaResources}/Profile/profileBuilding/Health.svg`,
      Academy: `${mediaResources}/Profile/profileBuilding/Academy.svg`,
      Extra: `${mediaResources}/Profile/profileBuilding/Extra.svg`,
      Bag: `${mediaResources}/Profile/profileBuilding/bag.svg`,
      Daily: `${mediaResources}/Profile/profileBuilding/daily.svg`,
      progress: `${mediaResources}/Profile/profileBuilding/progress.svg`,
      LearningStyle: `${mediaResources}/Profile/CareerCounselling/LearningStyle.svg`,
      Aptitude: `${mediaResources}/Profile/CareerCounselling/Aptitude.svg`,
      Intrest: `${mediaResources}/Profile/CareerCounselling/Intrest.svg`,
      Personality: `${mediaResources}/Profile/CareerCounselling/Personality.svg`,
      SocialBehaviour: `${mediaResources}/Profile/CareerCounselling/SocialBehaviour.svg`,
      Sessionschedule: `${mediaResources}/Profile/CareerCounselling/Sessionschedule.svg`,
      Report: `${mediaResources}/Profile/CareerCounselling/Report.svg`,
      Plans: `${mediaResources}/Profile/CareerCounselling/Plans.svg`,
      psychometricTest: `${mediaResources}/Profile/CareerCounselling/psychometricTest.svg`,
      Profilebuilding: `${mediaResources}/Profile/CareerCounselling/Profilebuilding.svg`,
      CareerMapping: `${mediaResources}/Profile/CareerCounselling/CareerMapping.svg`,
      PersonalityDevelopment: `${mediaResources}/Profile/CareerCounselling/PersonalityDevelopment.svg`,
      TestNow: `${mediaResources}/Profile/CareerCounselling/TestNow.svg`,
    },
    HomeMain: {
      ExploreMore: `${mediaResources}/Home/ExploreMore.png`,
      mainMentor: `${mediaResources}/Home/hero.png`,
      circleMentor: `${mediaResources}/Home/circle.png`,
      getStartedMentor: `${mediaResources}/Home/getStarted.svg`,

      IITBhu: `${mediaResources}/Home/IITBhu.png`,
      IIMDelhi: `${mediaResources}/Home/IIMDelhi.png`,
      IITBom: `${mediaResources}/Home/IITBom.png`,
      SSCC: `${mediaResources}/Home/SSCC.png`,
      IITDelhi: `${mediaResources}/Home/IITDelhi.png`,
      FindCollege: {
        Exams: `${mediaResources}/Home/Exams.png`,
        CollegeP: `${mediaResources}/Home/CollegeP.png`,
        Ranking: `${mediaResources}/Home/Ranking.png`,
        CourseFinder: `${mediaResources}/Home/CourseFinder.png`,
        CompareCollege: `${mediaResources}/Home/CompareCollege.png`,
        FindCollege: `${mediaResources}/Home/FindCollege.png`,
        Scholarship: `${mediaResources}/Home/Scholarship.png`,
        Careers: `${mediaResources}/Home/Careers.png`,
      },
      states: {
        indiagate: `${mediaResources}/Home/indiagate.svg`,
        hyderabadcharminar: `${mediaResources}/Home/hyderabadcharminar.svg`,
        mumbai: `${mediaResources}/Home/mumbai.svg`,
        chennai: `${mediaResources}/Home/chennai.svg`,
        pune: `${mediaResources}/Home/pune.svg`,
        bhopal: `${mediaResources}/Home/bhopal.svg`,
      },
      DocVerified: `${mediaResources}/DocVerified.svg`,
      ranking: `${mediaResources}/ranking.svg`,
      arrowHome: `${mediaResources}/Home/arrow.svg`,
      College: `${mediaResources}/Home/backgroundImage.png`,
    },
    getStartedPopup: `${mediaResources}/getStartedPopup.svg`,
    instructionBanner: `${mediaResources}/instructionBanner.svg`,
    congratulations: {
      Intrest: `${mediaResources}/Congratulations/Intrest.svg`,
      Aptitude: `${mediaResources}/Congratulations/Aptitude.svg`,
      AptitudeCheck: `${mediaResources}/Congratulations/AptitudeCheck.svg`,
      IntrestCheck: `${mediaResources}/Congratulations/IntrestCheck.svg`,
      Personality: `${mediaResources}/Congratulations/Personality.svg`,
      PersonalityCheck: `${mediaResources}/Congratulations/PersonalityCheck.svg`,
      SocialBehaviour: `${mediaResources}/Congratulations/SocialBehaviour.svg`,
      socialBehaviourCheck: `${mediaResources}/Congratulations/socialBehaviourCheck.svg`,
      TextBanner: `${mediaResources}/Congratulations/TextBanner.png`,
    },
    profilemainLogo: `${mediaResources}/Profile/unigoal.png`,

    roadmap: {
      top: `${mediaResources}/top.png`,
      wood1: `${mediaResources}/Roadmap/wood1.png`,
      wood2: `${mediaResources}/Roadmap/wood2.png`,
      wood3: `${mediaResources}/Roadmap/wood3.png`,
      wood4: `${mediaResources}/Roadmap/wood4.png`,
      wood5: `${mediaResources}/Roadmap/wood5.png`,
      wood6: `${mediaResources}/Roadmap/wood6.png`,
      wood7: `${mediaResources}/Roadmap/calendar.png`,
    },
  },
};
