import { medias } from "../../../../config/Resources";

/* eslint-disable jsx-a11y/img-redundant-alt */
function ScholarShipContactDetails({scholarshipDetails}) {
	return (
		<div>
			<div className="mt-5">
				<div className="ml-4 mr-4">
					<h6 className="mt-5 font-plusJakartaSans flex items-center gap-1 text-center font-extrabold text-[#000000] text-[13px]">
						<img className="w-5 h-5" src={medias.images.College.Phone} alt="phone" /> Contact
						Details
					</h6>
					<div className="relative overflow-hidden">
					{
						scholarshipDetails?.map((contactDetails, index) => {
							return (

						
						<div key={index}>
							<div
								dangerouslySetInnerHTML={{ __html: contactDetails?.contact_info }}
								className="font-plusJakartaSans font-medium text-[10.5px] text-[#000000] text-opacity-70 mt-3"
							></div>
						</div>
					)
				})
					}
					</div>
				</div>
			</div>
		</div>
	);
}

export default ScholarShipContactDetails;
