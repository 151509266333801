/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import { medias } from "../../config/Resources";
import axiosInstance from "../../config/Axios/axiosInstance";
import { useNavigate } from "react-router-dom";
import { MdBookOnline } from "react-icons/md";
import { Link } from "@mui/material";
import { API_URLS } from "../../config/API_URLS";

const ExternalIntershipRecommCards = ({ drop }) => {
	const [data, setData] = useState([]);

	const getInstershipData = async () => {
		try {
			const res = await axiosInstance.get(
				`${API_URLS.baseURL}/internship/getStdInternshipsSuggestionForRM`
			);
			setData(res.result.new_share_intern_data);
		} catch (error) {
			console.log(error);
		}
	};
	useEffect(() => {
		getInstershipData();
	}, []);
	console.log(data, "dataInstance");
	return (
		<>
			{drop === 2 && (
				<>
					<div className="flex justify-between items-center w-90 mt-8 ml-5 mr-8">
						<h6 className="text-lg font-bold font-plusJakartaSans">
							External Internship Recommendations
						</h6>
						<h6 className="text-lg font-bold text-[#239403] font-plusJakartaSans">
							View all
						</h6>
					</div>
					<div className="w-full flex flex-wrap gap-10 item-center mt-5 ml-5">
						{data?.map((internship, index) => (
							<div
								// onClick={() => navigate("/profile/internship-details")}
								key={index}
								className="bg-black rounded-xl shadow-md overflow-hidden border-[1px] border-[#000000] w-[30%] pb-3 mb-8 mt-3"
							>
								<div className="bg-white pl-2 pt-2 pr-2 pb-2 rounded-xl">
									<div className="flex items-center justify-between">
										<div className="flex items-center">
											<img
												className="w-12 h-12"
												src={medias.images.Profile.NoteBook}
												alt="image"
											/>
											<p className="text-base font-bold font-plusJakartaSans ml-1 pt-2">
												{internship.title}
											</p>
										</div>
									</div>
									<div className="mt-4 flex align-center items-center">
										<MdBookOnline />
										<p className="font-semibold font-plusJakartaSans text-sm">
											{internship.training_modes}
										</p>
									</div>

									<div className="flex items-center mt-1 mb-5 justify-between">
										<div>
											<p className="ml-1 mt-5 font-normal font-plusJakartaSans text-[13px]">
												Check internship
											</p>

											<Link
												href={internship.link}
												className="font-semibold font-plusJakartaSans font-xl ml-1 mt-1"
												target="_blank"
												rel="noopener noreferrer"
											>
												Link
											</Link>
										</div>
									</div>
								</div>
							</div>
						))}
					</div>
				</>
			)}
		</>
	);
};

export default ExternalIntershipRecommCards;
