import { medias } from "../../../../config/Resources";

/* eslint-disable jsx-a11y/img-redundant-alt */
function EntranceRegistration({ EntranceExam }) {
	return (
		<div>
			<div className="ml-4 mr-4">
				<h6 className="mt-3 font-plusJakartaSans flex items-center text-center gap-[6px] font-bold text-[#000000] text-[15px]">
					<img
						className="w-5 h-5"
						src={medias.images.Exam.Registration}
						alt="Registration"
					/>
					<span className="font-plusJakartaSans font-bold mt-[1px]">
						Registration
					</span>
				</h6>
				<img
					className="w-40 text-[#000000] pt-1"
					src={medias.images.Profile.line1}
					alt="lin1"
				/>
				<div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-4">
					<div className="bg-white flex items-center text-center gap-[6px] custom-shadow rounded-md pt-2 pb-2 pl-3">
						<img
							className="w-6 h-6 object-cover"
							src={medias.images.Exam.Education}
							alt="Engineering"
						/>
						<h2 className="font-medium font-plusJakartaSans mt-[px] text-[13px]">
							{EntranceExam?.registration}
						</h2>
					</div>
				</div>
			</div>
		</div>
	);
}

export default EntranceRegistration;
