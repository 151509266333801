import { medias } from "../../config/Resources";

const CareerCoach = () => {
  const arr = [
    {
      bgColor: " bg-[#FFF9F9] ",
      img: medias.images.mentorship.career.career1,
      title: "Find",
      desc: "        Indian Institute of Technology, Delhi or IIT Delhi is an Institute of Eminence offering about 102 undergraduate,",
    },
    {
      bgColor: " bg-[#F1FFF9] ",

      img: medias.images.mentorship.career.career2,

      title: "Discuss",
      desc: "        Indian Institute of Technology, Delhi or IIT Delhi is an Institute of Eminence offering about 102 undergraduate,",
    },
    {
      bgColor: " bg-[#FEFAF1] ",

      img: medias.images.mentorship.career.career3,

      title: "Level Your Skills",
      desc: "        Indian Institute of Technology, Delhi or IIT Delhi is an Institute of Eminence offering about 102 undergraduate,",
    },
    {
      bgColor: " bg-[#FFF2F7] ",

      img: medias.images.mentorship.career.career4,

      title: "Progress/Success",
      desc: "        Indian Institute of Technology, Delhi or IIT Delhi is an Institute of Eminence offering about 102 undergraduate,",
    },
  ];

  return (
    <div className="bg-[#CEF3D8] py-16 px-5 w-screen">
      <div className="flex w-full p-5">
        <div className="w-[53%]">
          <div className="flex mt-10">
            <div className="-mt-10">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="54.757"
                height="70.662"
                viewBox="0 0 54.757 70.662"
              >
                <g
                  id="Group_317818"
                  data-name="Group 317818"
                  transform="translate(-33.052 -346.135)"
                >
                  <line
                    id="Line_1559"
                    data-name="Line 1559"
                    x2="23.102"
                    y2="13.414"
                    transform="translate(34.587 402.223) rotate(-20)"
                    fill="none"
                    stroke="#69dd8c"
                    stroke-width="4"
                  />
                  <line
                    id="Line_1560"
                    data-name="Line 1560"
                    x2="22.009"
                    y2="18.833"
                    transform="translate(47.735 369.723)"
                    fill="none"
                    stroke="#69dd8c"
                    stroke-width="4"
                  />
                  <line
                    id="Line_1561"
                    data-name="Line 1561"
                    x1="2.981"
                    y2="35.026"
                    transform="translate(71.097 347.995) rotate(-20)"
                    fill="none"
                    stroke="#69dd8c"
                    stroke-width="4"
                  />
                </g>
              </svg>
            </div>

            <p className="text-[31px] font-bold">At Your One Click</p>
          </div>
          <div className="ml-10">
            <h4 className="text-[57px] font-bold">
              A Dedicated <br /> Personal{" "}
              <span className="PlusJakartaSans-ExtraBoldCustom bg-[#69DD8C] rounded-xl px-4 py-2">
                Career Coach
              </span>{" "}
            </h4>
          </div>
        </div>
        <div className="w-[47%] opacity-80 px-2 pt-6 font-semibold">
          Indian Institute of Technology, Delhi or IIT Delhi is an Institute of
          Eminence offering about 102 undergraduate, postgraduate, integrated
          and doctoral degree courses. Indian Institute of Technology, Delhi
          courses include B.Tech, M.Tech, M.Sc and Ph.D in various streams of
          Engineering, Physical Sciences, Management, Humanities, and Social
          Sciences. Students seeking admission for B.Tech in any stream must
          appear for JEE Advanced and JoSaa counselling. Students completing
          their degrees are offered IIT Delhi placements to prestigious
          organisations at a salary package ranging from Rs. 26 LPA to Rs. 40
          LPA (approximately). Established in 1961, IIT Delhi is one of the 23
          IITs created to be Centres of Excellence for training, research and
          development in science, engineering and technology in India. Being an
          Institution of National Importance under the 'Institutes of
          Technology’, the institute has bagged 4th rank in overall category as
          per NIRF 2022 data.
        </div>
      </div>

      <div className=" flex    overflow-x-scroll items-center justify-center gap-x-5 px-5 no-scrollbar py-10">
        {/* circle div */}

        {arr.map((ele, i) => {
          return (
            <div
              className={`min-w-[340px] shadow-sm max-w-[340px] h-[340px] ${ele?.bgColor} rounded-full `}
            >
              <img className="scale-95" src={ele?.img} alt="" />
              <div className="flex flex-col justify-center gap-y-5 items-center text-center px-12">
                <h3 className="text-[#022315] text-[30px] font-bold ">
                  {ele.title}
                </h3>
                <p className="opacity-60">{ele.desc}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CareerCoach;
