import { Search } from "@mui/icons-material";
import { IconButton, Input } from "@mui/material";
import React from "react";

const ExploreSearch = ({ handleSearch, isCollages }) => {
	console.log(handleSearch, "handleSearch");
	return (
		<div className=" rounded-[15px] flex 3xl:rounded-lg overflow-hidden  w-full !bg-[#F2F2F2] ">
			<div className="h-[35px] desktop:!h-[35px] desktop:px-[10px] 3xl:h-[35px] !bg-[#F2F2F2] px-6  miniDesk:px-[10px] flex items-center gap-x-2 ">
				<Search className="text-gray-400 desktop:text-[17px]" />
				<Input
					onChange={handleSearch}
					classes={{
						input:
							"w-full !border-none !outline-none !placeholder:text-black !bg-[#F2F2F2] ",
					}}
					className="h-[40px] desktop:text-[12px] before:!border-none after:!border-none w-full !border-none !bg-[#F2F2F2]"
					placeholder={isCollages ? "Search Colleges" : "Search Mentor"}
				/>
			</div>
		</div>
	);
};

export default ExploreSearch;
