import React, { useState } from "react";
import { FaPen } from "react-icons/fa";

function SamplePapersQuestions({ setPapersChips }) {
	const [activeTab, setActiveTab] = useState();

	const tabs = [
		{
			name: "Sample Papers",
			icon: <FaPen />,
		},
		{
			name: "Question Papers",
			icon: (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					className="h-5 w-5"
					viewBox="0 0 20 20"
					fill="currentColor"
				>
					<path
						fillRule="evenodd"
						d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
						clipRule="evenodd"
					/>
				</svg>
			),
		},
		{
			name: "Paper Analysis",
			icon: (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					className="h-5 w-5"
					viewBox="0 0 20 20"
					fill="currentColor"
				>
					<path
						fillRule="evenodd"
						d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z"
						clipRule="evenodd"
					/>
				</svg>
			),
		},
	];

	const handleTabClick = (tabName) => {
		setActiveTab(tabName);

		switch (tabName) {
			case "Sample Papers":
				setPapersChips("sample_papers");
				break;
			case "Question Papers":
				setPapersChips("question_papers");
				break;
			case "Paper Analysis":
				setPapersChips("paper_analysis");
				break;
			default:
				break;
		}
	};

	return (
		<div className="flex items-center border-b-2 mt-6 border-gray-200">
			{tabs.map((tab) => (
				<p
					key={tab.name}
					className={`font-plusJakartaSans ml-4 mr-4 ${
						activeTab === tab.name
							? "bg-[#2771E7] text-[#2771E7] bg-opacity-10"
							: "text-[#2771E7]"
					} flex items-center gap-[3px] text-center pt-2 pb-1 pl-4 pr-4 text-[13px] rounded-t-xl
					 cursor-pointer`}
					onClick={() => handleTabClick(tab.name)}
				>
					{React.cloneElement(tab.icon, {
						className: `${tab.icon.props.className} ${
							activeTab === tab.name ? "text-[#2771E7]" : "text-[#2771E7]"
						}`,
					})}
					{tab.name}
				</p>
			))}
		</div>
	);
}

export default SamplePapersQuestions;
