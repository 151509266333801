import React, { useState, useEffect } from "react";
import { medias } from "../../config/Resources";

const DietCharts = ({ setHealthType, healthType }) => {
  const AcdameyDetails = [
    {
      image: medias.images.Profile.Gym,
      label: "Physical",
    },
    { image: medias.images.Profile.Gym1, label: "Mental" },
  ];

  // Initialize state with the label of the first card
  const [defaultHealthType, setDefaultHealthType] = useState(
    AcdameyDetails.length > 0 ? AcdameyDetails[0].label : ""
  );

  useEffect(() => {
    setHealthType(defaultHealthType);
  }, []); // Empty dependency array ensures this effect runs only once, after initial render

  const handleCardClick = (label) => {
    setHealthType(label);
  };

  return (
    <>
      <div className="tracking-wide text-lg font-quitery font-normal pt-8 pl-4 pr-4">
        Diet Chart / Schedule to be Implemented
      </div>
      <div className="w-full flex">
        {AcdameyDetails?.map((detail, index) => (
          <div
            key={index}
            className={`flex justify-center items-center bg-white rounded-xl shadow-md overflow-hidden mt-4 ml-5 border cursor-pointer ${
              detail.label === healthType ? "border-blue-500" : "border-gray-300"
            } min-w-100 max-w-150`}
            onClick={() => handleCardClick(detail.label)}
          >
            <div className="p-4">
              <div className="flex items-center justify-center mb-2">
                <img
                  src={detail.image}
                  alt={detail.label}
                  className="h-13 w-13 object-cover"
                />
              </div>
              <div className="text-sm text-center">
                <p className="font-bold font-plusJakartaSans">{detail.label}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default DietCharts;
