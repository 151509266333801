/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { useEffect, useRef, useState } from "react";
import { medias } from "../../../config/Resources";
import React from "react";
import { Controls, Player } from "@lottiefiles/react-lottie-player";
import Notification from "../../../animations/Notification.json";
import AnimationBulb from "../../../animations/animation_lkm66f3e (1).json";
import Pagination from "@mui/material/Pagination";
import axiosInstance from "../../../config/Axios/axiosInstance";
import { API_URLS } from "../../../config/API_URLS";
import { useNavigate } from "react-router-dom";

const EntranceExamDetailsCards = () => {
	const cardsData = Array(10).fill(null);

	// Create an array of refs
	const scrollContainerRefs = useRef(cardsData.map(() => React.createRef()));

	const scrollRight = (index) => {
		if (scrollContainerRefs.current[index]) {
			scrollContainerRefs.current[index].current.scrollBy({
				left: 200,
				behavior: "smooth",
			});
		}
	};

	const Specializations = [
		{ id: 1, text: "Overview" },
		{ id: 2, text: "Important Dates" },
		{ id: 3, text: "Application" },
		{ id: 4, text: "Exam Pattern" },
		{ id: 5, text: "Scholarship" },
		{ id: 6, text: "Previous Papers" },
		{ id: 7, text: "Tips from Experts" },
		{ id: 8, text: "Result" },
		{ id: 9, text: "Counselling" },
		{ id: 10, text: "News" },
		{ id: 11, text: "Prediction" },
		{ id: 12, text: "Participating & Colleges" },
	];

	const [entranceExam, setEntranceExam] = useState([]);
	const [loading, setLoading] = useState(false);
	const [page, setPage] = useState(1);
	const [totalCount, setTotalCount] = useState(0);
	const dataLimit = 10;

	useEffect(() => {
		setLoading(true);
		(async () => {
			const res = await axiosInstance.get(`${API_URLS.getEntranceExams}`, {
				params: {
					page: page,
					limit: dataLimit,
				},
			});
			if (res?.statusCode === 200) {
				setEntranceExam(res?.result);
				setTotalCount(res?.total_count);
			}
			setLoading(false);
		})();
	}, [page]);

	const ChangePage = (event, value) => {
		setLoading(true);
		setPage(value);
	};

	const [isHovered, setIsHovered] = useState(false);
	const navigate = useNavigate();

	return (
		<>
			<div>
				<div className="flex flex-wrap -mx-2 mb-5">
					{entranceExam?.map((item, idx) => (
						<div
							key={idx}
							onClick={() =>
								navigate(`/entrance-exam-details/${item?.exam_id}`)
							}
							className="w-full md:w-[50%] lg:w-[50%] px-4 mt-5 cursor-pointer"
						>
							<div className="bg-white shadow-md rounded-lg overflow-hidden">
								<div className="bg-[#FEF1E5] pt-[11px] pb-[9px] pl-[11px] pr-[12px]">
									<div className="flex items-center justify-between">
										<div className="flex items-center gap-2">
											<img
												className="w-14 h-14"
												src={medias.images.Profile.logo}
												alt="courses"
											/>
											<div>
												<p className="font-semibold text-[#000000] opacity-50 font-plusJakartaSans text-[11px]">
													Entrance Name
												</p>
												<p className="font-extrabold text-[#000000] font-plusJakartaSans text-[20px] flex items-center gap-2">
													{item?.exam_name}
													<span className="text-[#239403] mt-[2px] text-[10px] rounded-[4px] font-semibold border border-[#239403] h-5 pt-[2px] pb-[2px] pl-2 pr-2">
														{item?.status}
													</span>
												</p>
											</div>
										</div>
										<div>
											<img
												className="w-8 h-8 mt-[8px]"
												src={medias.images.Exam.ShareButton}
												alt="ShareButton"
											/>
										</div>
									</div>
								</div>

								<div className="bg-white pt-4 pr-4 pl-4 flex justify-between">
									<div>
										<p className="font-plusJakartaSans text-[#707070] font-medium text-[11.5px]">
											Admit Card
										</p>
										<p className="font-plusJakartaSans text-[#000000] mt-[2.5px] font-bold text-[11.5px]">
											Starting of admit card
										</p>
										<p className="font-plusJakartaSans text-[#9B0000] mt-[2.5px] font-bold text-[11.5px]">
											<span className="text-black">&lt;</span>12 May 2023-25 May
											2023<span className="text-black">&gt;</span>
										</p>
										<div className="mt-[12px]">
											<p className="font-plusJakartaSans text-[#707070] font-medium text-[11.5px]">
												Level
											</p>
											<p className="font-plusJakartaSans text-[#000000] mt-[1px] font-semibold text-[11.5px]">
												{item?.level}
											</p>
										</div>
									</div>
									<div>
										<p className="font-plusJakartaSans text-[#707070] font-medium text-[11.5px]">
											Application Date
										</p>
										<p className="font-plusJakartaSans text-[#000000] mt-[2.5px] font-bold text-[11.5px]">
											Application initiation
										</p>
										<p className="font-plusJakartaSans text-[#9B0000] mt-[2.5px] font-bold ml-[px] text-[11.5px]">
											<span className="text-black">&lt;</span>12 May 2023-25 May
											2023<span className="text-black">&gt;</span>
										</p>
										<div className="mt-[12px]">
											<p className="font-plusJakartaSans text-[#707070] font-medium text-[11.5px]">
												Cluster
											</p>
											<p className="font-plusJakartaSans text-[#000000] mt-[1px] font-semibold text-[11.5px]">
												{item?.career_cluster?.split(",")[0]}
											</p>
										</div>
									</div>
								</div>
								<div className="pt-2 pl-4 pr-4">
									<p className="font-plusJakartaSans text-[#707070] font-medium text-[11.5px]">
										Quick Links
									</p>
									<div className="mt-[5px] relative flex items-center">
										<div
											ref={scrollContainerRefs.current[idx]}
											className="flex gap-2 items-center text-center overflow-x-auto scrollable-content"
										>
											{Specializations.map((specialization) => (
												<div key={specialization.id}>
													<p className="font-plusJakartaSans text-[#000000] border border-[#6C6C6C] rounded-full pt-1 pb-1 pl-3 pr-3 mb-1 font-medium text-[11.5px] whitespace-nowrap">
														{specialization.text}
													</p>
												</div>
											))}
										</div>
										<img
											src={medias.images.Exam.LeftArrow}
											alt="LeftArrow"
											className="absolute w-9 h-9 top-[32%] bg-[white] right-0 transform -translate-y-1/2 cursor-pointer"
											onClick={() => scrollRight(idx)}
										/>
									</div>
								</div>

								<div className="border-t border-gray-300"></div>

								<div className="flex ml-4 mr-4 gap-2 mt-4 mb-4">
									<button
										onMouseEnter={() => setIsHovered(true)}
										onMouseLeave={() => setIsHovered(false)}
										className="text-[#000000] pt-2 pb-2 pl-6 pr-6 border border-[#D2D2D2] py-2 px-4 rounded-lg hover:bg-[#000000] hover:text-white"
									>
										<p className="font-plusJakartaSans gap-1 text-[11px] flex items-center text-center font-medium">
											{isHovered ? (
												""
											) : (
												<img
													src={medias.images.Exam.CrystallBall}
													className="w-4 h-4"
													alt="crystallball"
												/>
											)}
											<span className="font-plusJakartaSans font-medium mt-[5px]">
												Predictor
											</span>
										</p>
									</button>
									<button className=" text-[#239403] border border-[#239403] pt-1 pb-1 pl-6 pr-6 rounded-lg">
										<p className="font-plusJakartaSans gap-1 text-[11px] font-medium flex items-center text-center">
											<Player
												autoplay
												speed={1.5}
												loop
												src={Notification}
												className="h-6 w-6"
											>
												<Controls
													visible={false}
													buttons={[
														"play",
														"repeat",
														"frame",
														"debug",
														"snapshot",
														"background",
													]}
												/>
											</Player>
											<span className="mt-[2px] font-plusJakartaSans font-medium">
												Get Alert
											</span>
										</p>
									</button>
									<button className="bg-[#239403] text-white pt-2 pb-2 pl-6 pr-6 rounded-lg hover:bg-[#135102]">
										<p className="font-plusJakartaSans gap-[5px] text-[11px] font-medium flex items-center text-center">
											<span className="mt-[4px] font-plusJakartaSans font-medium">
												Apply Now
											</span>
											<img
												className="w-3 h-3 mt-[2px]"
												src={medias.images.Exam.IconFeather}
												alt="IconFeather"
											/>
										</p>
									</button>
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
			<div className="mb-5">
				<Pagination
					onChange={ChangePage}
					count={Math.ceil(totalCount / dataLimit)}
					variant="outlined"
					color="primary"
				/>
			</div>
		</>
	);
};

export default EntranceExamDetailsCards;
