/* eslint-disable jsx-a11y/img-redundant-alt */
import { useState } from "react";
import { medias } from "../../../../config/Resources";
import { FaAngleUp } from "react-icons/fa";
import { RiArrowDropDownLine } from "react-icons/ri";
import { FaAnglesUp } from "react-icons/fa6";

function Researches({ campusLife }) {
	const [expandedCard, setExpandedCard] = useState(null);

	const toggleExpand = (id) => {
		setExpandedCard(expandedCard === id ? null : id);
	};

	const isExpanded = (id) => {
		return expandedCard === id;
	};

	const getFirstLines = (paragraph) => {
		const words = paragraph.split(" ");
		if (words.length <= 26) {
			return paragraph;
		}
		return words.slice(0, 15).join(" ") + "...";
	};

	return (
		<div className="ml-4 mr-4 mt-7">
			<img
				className="mt-[8px] w-[100%] mb-7"
				src={medias.images.College.Rectangle2}
				alt="Rectangle"
			/>
			<div className="flex items-center text-center gap-1">
				<img
					className="w-5 h-5"
					src={medias.images.College.ResearchIcon}
					alt="Opportunity"
				/>
				<h6 className="font-plusJakartaSans text-[#000000] mt-[5.5px] font-bold text-[13px]">
					Researches
				</h6>
			</div>
			<div>
				{campusLife?.map((data) =>
					data?.research?.map((item) => {
						const expanded = isExpanded(item?.research_id);
						const firstLines = getFirstLines(item?.research_description);

						return (
							<div key={item?.research_id}>
								<div className="flex items-center mt-6 text-center gap-2">
									{item?.files && item?.files?.length > 0 && (
										<img
											className="w-32 h-20 mb-2 rounded-lg"
											src={item?.files[0]?.file_url}
											alt="Research Image"
										/>
									)}
									<div className="text-left">
										<p className="font-plusJakartaSans text-[12px] font-bold text-[#252525]">
											{item?.club_title}
										</p>
										<div
											dangerouslySetInnerHTML={{
												__html: expanded
													? item?.research_description
													: firstLines,
											}}
											className="font-plusJakartaSans font-medium text-[#000000] text-[10.5px] mt-[2px] text-opacity-70"
										/>
										<button
											className="text-blue-500 font-plusJakartaSans flex items-center font-bold text-[11.5px]"
											onClick={() => toggleExpand(item?.research_id)}
										>
											{expanded ? (
												<>
													Read Less
													<FaAnglesUp className="w-4 h-4 ml-1" />
												</>
											) : (
												<>
													Read more
													<RiArrowDropDownLine className="w-6 h-6" />
												</>
											)}
										</button>
									</div>
									<div className="my-4 border-t-2 border-dashed border-gray-300"></div>
								</div>
							</div>
						);
					})
				)}
			</div>
		</div>
	);
}

export default Researches;
