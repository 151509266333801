import { medias } from "../../../config/Resources";

function NutritionAndDiet() {
	return (
		<div className="ml-5 mr-5">
			<img src={medias.images.College.Nutrition} alt="Nutrition" />
		</div>
	);
}
export default NutritionAndDiet;
