function BlogsChips({ setActiveChip, activeChip }) {
	const chips = [
		{ id: 1, name: "All" },
		{ id: 2, name: "Study Abroad" },
		{ id: 3, name: "Admissions" },
		{ id: 4, name: "Innovative Careers" },
		{ id: 5, name: "Career Guide" },
		{ id: 6, name: "Counselling as a Career" },
		{ id: 7, name: "Parental Perspective" },
		{ id: 8, name: "JEE Exams" },
		{ id: 9, name: "JEE Exams" },
	];

	const handleChipClick = (id) => {
		if (activeChip === id) {
			setActiveChip(null);
		} else {
			setActiveChip(id);
		}
	};

	return (
		<div className="flex relative pb-[13px] pt-[15px] border-[#707070] border-opacity-[38%] overflow-auto gap-[8px] no-scrollbar">
			{chips.map((item) => (
				<p
					key={item.id}
					onClick={() => handleChipClick(item.id)}
					className={`min-w-fit font-medium font-plusJak artaSans px-[22px] border text-[15px] py-[5px] rounded-full cursor-pointer ${
						activeChip === item.id
							? "bg-[#2771E7] border-[#2771E7] text-white"
							: "bg-white border-[#D1D1D1] text-[#000000]"
					}`}
				>
					{item.name}
				</p>
			))}
		</div>
	);
}

export default BlogsChips;
