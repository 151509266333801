import React, { useContext } from "react";
import { Typography, AppBar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import VideoPlayer from "./VideoPlayer";
import Notifications from "./Notifications";
import Sidebar from "./Sidebar";
import { SocketContext } from "../Context";
import { useUserInfoStore } from "../store/userinfo";
import Footer from "./Footer";

const useStyles = makeStyles((theme) => ({
  appBar: {
    borderRadius: 15,
    margin: "30px 100px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "600px",
    border: "2px solid black",

    [theme.breakpoints.down("xs")]: {
      width: "90%",
    },
  },
  image: {
    marginLeft: "15px",
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  text_progress: {
    color: "red",
  },
  text_success: {
    color: "green",
  },
}));

const Main = () => {
  const classes = useStyles();
  const { userInfo } = useUserInfoStore((state) => state);

  const { me } = useContext(SocketContext);

  console.log("ID", me);
  return (
    <div className="bg-black min-h-fit h-screen ">
      <h5 className={!!me ? "text-green-400" : "text-red-600"}>
        {/* {!!me ? "Online" : "Waiting for Connection"} */}
      </h5>
      <VideoPlayer userInfo={userInfo} />
      <Footer />
      <Sidebar userInfo={userInfo}>
        <Notifications userInfo={userInfo} />
      </Sidebar>
    </div>
  );
};

export default Main;
