import React from "react";

function BlogsDetilasIntroduction({ tab, blogsDetails }) {
	// Find the section that matches the current tab ID
	const matchingSection = blogsDetails?.sections?.find(
		(section) => section.id === tab
	);

	console.log(matchingSection, "matchingsection");

	return (
		<div className="ml-7 mr-7 mt-[10px] mb-10">
			<div className="mt-5">
				<h1 className="font-plusJakartaSans text-[22px] font-bold">
					Introduction
				</h1>
				{matchingSection ? (
					<div
						dangerouslySetInnerHTML={{
							__html: matchingSection.content,
						}}
						className="font-plusJakartaSans leading-[24px] font-medium text-[13px] text-[#000000] text-opacity-[79%] mt-1"
					></div>
				) : (
					<div className="font-plusJakartaSans leading-[24px] font-medium text-[13px] text-[#000000] text-opacity-[79%] mt-1">
						No data available
					</div>
				)}
				{/* <BlogsDetailsTables /> */}
			</div>
		</div>
	);
}

export default BlogsDetilasIntroduction;
