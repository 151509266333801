import { useLocation } from "react-router-dom";
import MileStoneData from "../../../Components/ProfileBuilding/MileStoneData";

const MileStoneDetails = () => {
	const location = useLocation();
	const receivedData = location.state?.mileStoneId || "No data received";
	const findActiveTab = location.state?.activeTab || "No activeTab found";
	const Maingoal = location.state?.maiGoal || "No data found";
	const MilestoneName = location.state?.milestoneName || "No data found";
	const DurationTime = location.state?.durationTime || "No data found";
	const MilestonePercentage =
		location.state?.milestonePercentage || "No data found";
		const ItemId =
		location.state?.AllIds || "No data found";

	return (
		<div>
			{/* {receivedData} */}
			<MileStoneData
			ItemId ={ItemId}
				receivedData={receivedData}
				activeTab={findActiveTab}
				Maingoal={Maingoal}
				MilestoneName={MilestoneName}
				DurationTime={DurationTime}
				MilestonePercentage={MilestonePercentage}
			/>
		</div>
	);
};

export default MileStoneDetails;
