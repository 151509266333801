/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import {
  NineTenthPlanPageContent,
  qualities,
} from "../../../config/pageContent";
import Image from "../../../Components/Image";
import Text from "../../../Components/Text";
import { Button } from "@mui/material";
import { medias } from "../../../config/Resources";
import { Height, Lock } from "@mui/icons-material";
import { mediaResources } from "../../../config/Constants";
import ReactECharts from "echarts-for-react";
import { useNavigate } from "react-router-dom";
import Tabs from "./Tabs";
import AccordianComp from "./AccordianComp";
import Personallity from "./Personallity";
import Interest from "./Interest";
import Apttitude from "./Apttitude";
import Social from "./Social";
import LearningStyle from "./LearningStyle";
import axiosAdminInstance from "../../../config/Axios/axiosAdminInstance";
import { API_URLS } from "../../../config/API_URLS";
import axiosInstance from "../../../config/Axios/axiosInstance";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import PlansCard from "../../../Components/Faces/Plans/PlansCard";
import Value from "./Value";
import { benefits } from "../../../helpers/benefits";
import { useUserInfoStore } from "../../../store/userinfo";

const tabArray = [
  { heading: "Extraversion", key: 1 },
  { heading: "Agreeableness", key: 2 },
  { heading: "Conscientiousness", key: 3 },
  { heading: "Openness to Experience", key: 4 },
  { heading: "Emotional Stability", key: 5 },
];

interface BackendBenefit {
  "Career Assessment Test of Dimensions"?: any;
  // Add other properties if needed
}

function removeDuplicates(array: any) {
  return Array.from(new Set(array));
}

const PsychomatricProfileTest = () => {
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(1);
  const [activeTabName, setActiveTabName] = useState<string>("");

  const [activeTest, setActiveTest] = useState(1);
  const [tabData, setTabData] = useState(qualities[0] || {});
  const [mainFactorName, setMainFactorName] = useState<string[]>([]);
  const [mainFactorValue, setMainFactorValue] = useState<string[]>([]);
  const [mainFactorColor, setMainFactorColor] = useState<string[]>([]);
  const [tabArrayDynamic, setTabArrayDynamic] = useState<Object[]>([]);

  const [factorData, setFactorData] = useState<any[]>([]);
  const [responseData, setresponseData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dataFind, setDataFind] = useState(false);
  const [activePlanData, setActivePlanData] = useState({});
  const [planLimit, setPlanLimit] = useState(1);
  const [backendBenefit, setBackendBenefit] = useState<BackendBenefit>({});
  const [checkUpgrade, setCheckUpgrade] = useState(false);
  const { userInfo } = useUserInfoStore((state: any) => state);

  const [dimensionValue, setDimensionValue] = useState(1);

  const colors = ["#FC74B5", "#E9C07E", "#FEAA8F", "#99D99F", "#7B80FD"];

  const array2 = [
    "Career Assessment Test of setDimensionData1 Dimension",
    "Career Assessment Test of 4 Dimensions",
  ];

  const categories = [
    "Extraversion",
    "Agreeableness",
    "Conscientiousness",
    "Emotional Stability",
    "Openness to Experience",
  ];
  const values = [20, 35, 30, 25, 30];
  const option = {
    grid: {
      top: 0, // Set top padding to 0
      bottom: 0, // Set bottom padding to 0
      left: 0, // Adjust left padding if needed
      right: 0, // Adjust right padding if needed
      // height:  mainFactorName?.length > 5 ? "420px" : "300px",
      containLabel: true, // Make sure labels are contained within the chart
    },
    xAxis: {
      type: "value",
      splitLine: {
        show: false, // Hide the grid lines
      },
      axisTick: {
        show: false, // Show the yAxis tick marks
      },
      axisLine: {
        show: false, // Show the yAxis line
      },
    },
    yAxis: {
      type: "category",
      data: mainFactorName || categories,
      axisLabel: {
        show: true,
        // align: 'left', // Align y-axis labels to the left
        textStyle: {
          color: "black", // Set text color
          fontSize: 15, // Set text font size
          fontWeight: "bold", // Set text font weight
          fontFamily: "Arial", // Set text font family
        },
      },
      axisLine: {
        show: true, // Show the yAxis line
      },
      axisTick: {
        show: false, // Show the yAxis tick marks
      },
      splitLine: {
        show: false, // Hide the grid lines
      },
    },
    series: [
      {
        type: "bar",
        data: mainFactorValue || values,
        barWidth: 25,
        barGap: 16,
        // label: {
        //   show: true,

        //   position: "left",
        //   formatter: "{c}",
        // },
        itemStyle: {
          borderRadius: [0, 50, 50, 0], // Set borderRadius to make the top rounded
          color: function (params: any) {
            // Dynamically assign color based on index
            return (
              mainFactorColor[params.dataIndex] || colors[params.dataIndex]
            );
          },
        },
      },
    ],
  };

  useEffect(() => {
    if (!checkUpgrade) {
      (async () => {
        try {
          setIsLoading(true);
          const res: any = await axiosInstance.get(
            `${
              activeTest === 1
                ? API_URLS.getSocialReport
                : activeTest === 2
                ? API_URLS.getPersonalityReport
                : activeTest === 3
                ? API_URLS.getAptitudeReport
                : activeTest === 4
                ? API_URLS.getInterestReport
                : activeTest === 5
                ? API_URLS.getLearningReport
                : activeTest === 6
                ? API_URLS.getValuesReport
                : ""
            }`
          );
          if (res?.statusCode === 200) {
            console.log(res);
            setDataFind(false);
            setIsLoading(false);

            setresponseData(res?.result);
            // for chart name
            setMainFactorName(Object.keys(res?.result?.data));
            // for chart value

            setMainFactorValue(Object.values(res?.result?.data));

            //For Dynamic Colors
            const repeatedColors = [];
            for (let i = 0; i < Object.keys(res?.result?.data)?.length; i++) {
              const colorIndex = i % colors.length; // Cycle through colors array
              repeatedColors.push(colors[colorIndex]);
            }
            setMainFactorColor(repeatedColors);

            //for tab names
            const mainFactorKeysArray = res?.result?.details.map(
              (detail: any) => detail?.Main_Factor || detail?.Factor
            );

            // console.log(mainFactorKeysArray);
            let removedDuplicateMainFactorKeys: any[] = [];

            removedDuplicateMainFactorKeys =
              removeDuplicates(mainFactorKeysArray);

            const tabArrayNew = removedDuplicateMainFactorKeys?.map(
              (heading, index) => ({
                heading,
                key: index + 1,
              })
            );

            setTabArrayDynamic(tabArrayNew || []);

            setActiveTabName(removedDuplicateMainFactorKeys[0]);
            setIsLoading(false);

            // console.log(tabArrayNew);
          } else if (res?.statusCode === 201) {
            setIsLoading(false);
            setDataFind(true);
          } else {
            setIsLoading(false);
            setDataFind(false);
          }
        } catch (error) {
          console.log("error", error);
          setIsLoading(false);
        }
      })();
    } else {
      setDataFind(true);
    }
  }, [activeTest]);

  useEffect(() => {
    if (activeTabName && responseData) {
      let data = (responseData as { details?: any[] })?.details?.filter(
        (ele: any) => ele?.Main_Factor === activeTabName
      );
      if (!data?.length) {
        data = (responseData as { details?: any[] })?.details?.filter(
          (ele: any) => ele?.Factor === activeTabName
        );
      }

      if (data) {
        setFactorData(data);
      }
    }
  }, [activeTabName, responseData]);

  useEffect(() => {
    (async () => {
      try {
        const res: any = await axiosInstance.get(
          API_URLS.getActivePlan + `?class=${userInfo?.class}`
        );
        if (res?.statusCode === 201) {
          // setActivePlan(false);
        } else if (res?.statusCode === 200) {
          setActivePlanData(res.result);
          setBackendBenefit(res?.result?.benefits);
          const benefitObj = res?.result?.benefits;
          setDimensionValue(benefitObj["Career Assessment Test of Dimensions"]);
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    })();
  }, []);

  console.log("dimensionValue", dimensionValue);

  console.log("checkUpgrade", checkUpgrade);

  return (
    <>
      {!!isLoading && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // Optional: Add a semi-transparent background
            backgroundColor: "rgba(255, 255, 255, 0.2)",
            zIndex: 9999,
            // Optional: Set a higher z-index to overlay on top of other content
          }}
        >
          <CircularProgress size={30} color="warning" />
        </Box>
      )}

      <div className="bg-white 3xl:mt-[12px]  shadow-[0px_5px_16px_rgba(0,0,0,0.06)] 3xl:pt-[30px] py-[28px] rounded-b-[20px] mt-[28px] pt-[60px] px-[37px] desktop:!mt-0">
        <div className="flex gap-[20px]">
          {qualities.map((items: any, index: any) => {
            return (
              <div
                className={`relative flex justify-center cursor-pointer ${
                  activeTest === index + 1
                    ? " border-[3px] border-solid border-blue-500 rounded-3xl "
                    : ""
                } `}
                onClick={() => {
                  console.log(
                    "dimensionValue condition",
                    dimensionValue >= index + 1
                  );
                  if (index == 0) {
                    setActiveTest(index + 1);
                    setTabData(items);
                    setCheckUpgrade(false);
                  } else if (!(dimensionValue >= index + 1)) {
                    setActiveTest(index + 1);
                    setCheckUpgrade(true);
                  } else {
                    setActiveTest(index + 1);
                    setTabData(items);
                    setCheckUpgrade(false);
                  }
                }}
              >
                <Image
                  className="desktop:!w-[130px] 3xl:w-[160px]"
                  src={items.icons}
                  alt={`${items.name}`}
                />
                <Text className="absolute bottom-[20px] desktop:text-[12px] font-[PlusJakartaSans-Bold] 3xl:bottom-[16px] font-bold">
                  {items.name}
                </Text>

                <div className="absolute desktop:bottom-[calc(0%-12px)] bottom-[calc(0%-27px)] ">
                  {index == 0 ? (
                    <Text className="bg-[#2771E7] font-[PlusJakartaSans-Bold] rounded-lg text-white px-[27px] desktop:text-[12px] 3xl:px-[17px] 3xl:py-1 py-[8px]">
                      Free
                    </Text>
                  ) : dimensionValue >= index + 1 ? (
                    <Text className="bg-[#2771E7] font-[PlusJakartaSans-Bold] rounded-lg text-white px-[27px] desktop:text-[12px] 3xl:px-[17px] 3xl:py-1 py-[8px]">
                      Available
                    </Text>
                  ) : (
                    <Text className=" bg-[#E4E4E4] desktop:text-[12px] font-[PlusJakartaSans-Bold] 3xl:py-1 3xl:px-[17px] px-[27px]  rounded-lg py-[8px] ">
                      <Lock className="desktop:!text-[12px]" /> Unlock
                    </Text>
                  )}
                </div>
              </div>
            );
          })}
        </div>

        {!dataFind ? (
          <>
            <div
              className={`mt-[77px] flex flex-col border border-[#70707034] 
             rounded-[22px] w-[70%] py-[20px] px-[20px]`}
            >
              <Text className="text-[21.4px] font-quitery pb-2">
                {tabData?.name}
              </Text>
              <ReactECharts option={option} opts={{ renderer: "svg" }} />
            </div>

            {activeTest === 1 && (
              <Personallity
                factorData={factorData}
                setActiveTabName={setActiveTabName}
                data={tabArrayDynamic || tabArray}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                responseData={responseData}
              />
            )}
            {activeTest === 2 && (
              <Apttitude
                setActiveTabName={setActiveTabName}
                factorData={factorData}
                data={tabArrayDynamic || tabArray}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                responseData={responseData}
              />
            )}
            {activeTest === 3 && (
              <Social
                setActiveTabName={setActiveTabName}
                isSocial={true}
                factorData={factorData}
                data={tabArrayDynamic || tabArray}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                responseData={responseData}
              />
            )}
            {activeTest === 4 && (
              <Interest
                setActiveTabName={setActiveTabName}
                factorData={factorData}
                data={tabArrayDynamic || tabArray}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                responseData={responseData}
              />
            )}
            {activeTest === 5 && (
              <LearningStyle
                factorData={factorData}
                setActiveTabName={setActiveTabName}
                data={tabArrayDynamic || tabArray}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                responseData={responseData}
              />
            )}
            {activeTest === 6 && (
              <Value
                factorData={factorData}
                setActiveTabName={setActiveTabName}
                data={tabArrayDynamic || tabArray}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                responseData={responseData}
              />
            )}
          </>
        ) : (
          <>
            {!checkUpgrade || activeTest == 1 ? (
              <div className="mt-[77px] 3xl:mt-[50px] flex border border-[#70707034] rounded-[22px] w-1/2 py-[20px] px-[23px]">
                <div>
                  <Text className="font-quitery 3xl:text-[24px] desktop:!text-[20px] text-[29px]">
                    Avail. your Test Now
                  </Text>
                  <Text className="text-[17px] 3xl:text-[14px] desktop:!text-[12px] text-[##252424] opacity-80">
                    The first project about the health of mobile applications,
                    The first project about the health of
                  </Text>
                  <Button
                    onClick={() =>
                      navigate(
                        activeTest == 1
                          ? "/psychomatric-test?category=socialBehaviour"
                          : activeTest == 2
                          ? "/psychomatric-test?category=personality"
                          : activeTest == 3
                          ? "/psychomatric-test?category=aptitude"
                          : activeTest == 4
                          ? "/psychomatric-test?category=intrest"
                          : "/get-started"
                      )
                    }
                    className="!bg-[#239403] 3xl:text-[12px] text-[16px] !capitalize rounded-full mt-2"
                    variant="contained"
                  >
                    Get started
                  </Button>
                </div>
                <div>
                  <Image src={medias.images.Profile.TestNow} />
                </div>
              </div>
            ) : (
              <div className="mt-[77px] 3xl:mt-[50px] flex border border-[#70707034] rounded-[22px] w-1/2 py-[20px] px-[23px]">
                <div>
                  <Text className="font-quitery 3xl:text-[24px] desktop:!text-[20px] text-[29px]">
                    Upgrade. your Test Now
                  </Text>
                  <Text className="text-[17px] 3xl:text-[14px] desktop:!text-[12px] text-[##252424] opacity-80">
                    The first project about the health of mobile applications,
                    The first project about the health of
                  </Text>
                  <Button
                    onClick={() =>
                      navigate("/profile/career-counselling?location=plans")
                    }
                    className="!bg-[#239403] 3xl:text-[12px] text-[16px] !capitalize rounded-full mt-2"
                    variant="contained"
                  >
                    Upgrade
                  </Button>
                </div>
                <div>
                  <Image src={medias.images.Profile.TestNow} />
                </div>
              </div>
            )}
            <div className="flex w-full">
              <div className="mt-[32px] border border-[#70707034] rounded-[22px] w-3/5 py-[20px] px-[23px]">
                <Text className="text-[23px] 3xl:text-[18px] desktop:!text-[15px] text-[#13110F] font-[PlusJakartaSans-Bold] font-bold ">
                  Tips of getting good answers
                </Text>
                <ul className="flex flex-col gap-[16px]  list-disc ml-[14px] text-[#000000] opacity-70    3xl:gap-[12px] 3xl:mt-[12px] mt-[26px]">
                  <li className="font-[PlusJakartaSans-SemiBold]  desktop:!text-[12px] 3xl:text-[14px]">
                    Chat with our counselor
                  </li>
                  <li className="font-[PlusJakartaSans-SemiBold] desktop:!text-[12px] 3xl:text-[14px]">
                    Get your personalised list of colleges & exams matching your
                    preferences
                  </li>
                  <li className="font-[PlusJakartaSans-SemiBold] desktop:!text-[12px] 3xl:text-[14px]">
                    Chat with our counselor
                  </li>
                  <li className="font-[PlusJakartaSans-SemiBold] desktop:!text-[12px] 3xl:text-[14px]">
                    Get your personalised list of colleges & exams matching your
                    preferences
                  </li>
                </ul>
              </div>
              <div className="w-1/3 mt-auto ml-auto">
                <Image
                  src={`${mediaResources}/Profile/CareerCounselling/Coupon.png`}
                  alt="Coupon"
                />
              </div>
            </div>
            <div>
              <PlansCard
                data={[]}
                plans={NineTenthPlanPageContent.Face7.plans}
                isTab={false}
                bodyContainer="mt-[10px]"
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default PsychomatricProfileTest;
