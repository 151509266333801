import { medias } from "../../../../config/Resources";

function AlmuniNetwork({placement}) {
	const recrutCompanies = [
		{
			id: 1,
			image: medias.images.College.Google,
			text: "Place at",
			name: "Vibhash Patel",
			course: "B.tech Computer Science",
			Profile: "Linkedin profile",
		},
		{
			id: 2,
			image: medias.images.College.Infosys,
			text: "Place at",
			name: "Shivam Kaushik",
			course: "B.tech Computer Science",
			Profile: "Linkedin profile",
		},
		{
			id: 3,
			image: medias.images.College.Accenture,
			text: "Place at",
			name: "Sumit Dhaii",
			course: "B.tech Computer Science",
			Profile: "Linkedin profile",
		},
		{
			id: 4,
			image: medias.images.College.Amazon,
			text: "Place at",
			name: "Sumit Dhaii",
			course: "B.tech Computer Science",
			Profile: "Linkedin profile",
		},
		{
			id: 1,
			image: medias.images.College.Google,
			text: "Place at",
			name: "Vibhash Patel",
			course: "B.tech Computer Science",
			Profile: "Linkedin profile",
		},
		{
			id: 2,
			image: medias.images.College.Infosys,
			text: "Place at",
			name: "Shivam Kaushik",
			course: "B.tech Computer Science",
			Profile: "Linkedin profile",
		},
		{
			id: 3,
			image: medias.images.College.Accenture,
			text: "Place at",
			name: "Sumit Dhaii",
			course: "B.tech Computer Science",
			Profile: "Linkedin profile",
		},
		{
			id: 4,
			image: medias.images.College.Amazon,
			text: "Place at",
			name: "Sumit Dhaii",
			course: "B.tech Computer Science",
			Profile: "Linkedin profile",
		},
	];
	return (
		<div className="ml-4 mr-4 mt-4">
			<div className="flex items-center text-center gap-1">
				<img
					className="bg-[#615260] bg-opacity-[15%] p-[6px] rounded-md w-9 h-9"
					src={medias.images.College.Student}
					alt="Placements"
				/>
				<h6 className="font-plusJakartaSans mt-[5.5px] font-bold text-[15px]">
					Alumni Network
				</h6>
			</div>
			<div className="flex overflow-x-auto overflow-y-hidden scrollable-content mt-1 space-x-4 py-2">
				{recrutCompanies.map((company) => (
					<div
						key={company.id}
						className=" flex-shrink-0 bg-white pl-4 pr-4 pt-3 pb-5 rounded-md custom-shadow w-auto h-auto"
					>
						<div>
							<p className="font-plusJakartaSans flex item-center text-center justify-center text-[10px] font-medium text-[#000000] text-opacity-[81%]">
								{company.text}
							</p>
							<img
								className="w-16 h-7 flex ml-auto mr-auto object-contain"
								src={company.image}
								alt={`Company ${company.id}`}
							/>
							<p className="font-plusJakartaSans flex item-center text-center justify-center mt-[8px] text-[14.5px] font-semibold text-[#000000] text-opacity-[81%]">
								{company.name}
							</p>
							<div className="flex gap-1 item-center text-center">
								<img
									className="h-3 w-3 mt-[1.3px]"
									src={medias.images.College.BriefCase}
									alt="Breifcase"
								/>
								<p className="font-plusJakartaSans flex item-center text-center justify-center mt-[1px] text-[10.5px] font-medium text-[#000000] text-opacity-[81%]">
									{company.course}
								</p>
							</div>
							<div className="flex mt-[2px] gap-1 justify-center item-center text-center">
								<img
									className="h-4 w-4 mt-[1.3px]"
									src={medias.images.College.Linkdein1}
									alt="Breifcase"
								/>
								<p className="font-plusJakartaSans text-[10.5px] font-medium mt-[1px] text-[#2771E7] border-b border-[#2771E7]">
									{company.Profile}
								</p>
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	);
}

export default AlmuniNetwork;
