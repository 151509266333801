import { medias } from "../../../../config/Resources";
import Benefits from "./Benefits";
import ScholarExam1Table from "./ScholarExam1Table";

function ExamDetails({ scholarshipDetails }) {
	return (
		<div className="ml-4 mr-4 mt-6 border border-[#707070] rounded-lg border-opacity-30">
			{/* <div className="pl-3 pr-3 pt-4">
				<p className="font-plusJakartaSans font-bold text-[14px] flex items-center text-center gap-1">
					<img
						src={medias.images.College.Eligibility4}
						alt="LeftChevron"
						className="w-6 h-6"
					/>{" "}
					<span className="font-plusJakartaSans font-bold text-[14px] mt-[3px]">
						Exams
					</span>
				</p>
			</div> */}
			
			{/* <div
				dangerouslySetInnerHTML={{ __html: staticText }}
				className="font-plusJakartaSans font-medium text-[10.5px] text-[#000000] text-opacity-70 pl-3 pr-3 pt-2 pb-4"
			></div> */}
			<Benefits scholarshipDetails={scholarshipDetails} />
			{/* <ScholarExam1Table /> */}
		</div>
	);
}

export default ExamDetails;
