import CircularProgress from "@mui/material-next/CircularProgress";
import { API_URLS } from "../../../config/API_URLS";
import axiosInstance from "../../../config/Axios/axiosInstance";
import { useEffect, useState } from "react";

const ViewReport = () => {
  const [data, setData] = useState("Generating Report...");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const res = await axiosInstance.get(
          `${API_URLS.backendDomainURL}/report/html`
        );
        setData(res.result || "Report Not Found");
        console.log(res, "responseData");
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <div>
      {loading && (
        <div className="flex flex-col  justify-center items-center mt-64">
          <CircularProgress
            color="tertiary"
            fourColor
            variant="indeterminate"
            size={30}
          />
          <h6 className="text-center">{data}</h6>
        </div>
      )}
      {!loading && (
        <div
          className="!overflow-y-scroll h-fit no-scrollbar mt-10 bg-white"
          dangerouslySetInnerHTML={{ __html: data }}
        ></div>
      )}
    </div>
  );
};

export default ViewReport;
