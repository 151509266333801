import { medias } from "../../../../config/Resources";
import BrachTable from "./BranchTable";

function BranchWisePlacement({ placement }) {
	return (
		<div className="ml-4 mr-4 mt-2">
			<div className="flex items-center text-center gap-1">
				<img
					className="bg-[#FF7C7A] bg-opacity-[16%] p-[6px] rounded-md w-9 h-9"
					src={medias.images.College.Degree}
					alt="Placements"
				/>
				<h6 className="font-plusJakartaSans mt-[5.5px] font-bold text-[15px]">
					Branch wise placement details
				</h6>
			</div>
			<div>
				<p
					dangerouslySetInnerHTML={{
						__html: placement?.branch_summary,
					}}
					className="font-plusJakartaSans font-medium text-[10.5px] pt-[7px] text-opacity-60 text-[#000000]"
				></p>
			</div>
			<BrachTable placement={placement} />
		</div>
	);
}

export default BranchWisePlacement;
