import { GoDotFill } from "react-icons/go";
import { useNavigate } from "react-router-dom";

function BlogDetailsHeader({ blogsDetails }) {
	const formatDate = (dateString) => {
		const options = { day: "numeric", month: "long", year: "numeric" };
		const date = new Date(dateString);
		return new Intl.DateTimeFormat("en-GB", options).format(date);
	};
	return (
		<div className="ml-7 mr-7 mt-[25px] mb-4">
			<h1 className="font-plusJakartaSans text-[25px] font-extrabold">
				{blogsDetails?.title}
			</h1>
			<div className="flex items-center text-center mt-2 gap-2">
				<div>
					<img
						className="rounded-full pictures"
						src={blogsDetails?.author_image}
						alt="AuthorImage"
					/>
				</div>
				<p className="font-plusJakartaSans text-[14px] font-semibold text-[#000000] text-opacity-70">
					{blogsDetails?.author_name}
				</p>
				<GoDotFill className="text-[#000000]  text-opacity-70" />
				<p className="font-plusJakartaSans text-[14px] font-semibold text-[#000000] text-opacity-70">
					{blogsDetails?.time_taken}
				</p>
				<GoDotFill className="text-[#000000] text-opacity-70" />
				<p className="font-plusJakartaSans text-[14px] font-semibold text-[#000000] text-opacity-70">
					{formatDate(blogsDetails?.created_at)}
				</p>
			</div>
			<div className="mt-5">
				<h1 className="font-plusJakartaSans text-[22px] font-bold">Summary</h1>
				<div
					dangerouslySetInnerHTML={{
						__html: blogsDetails?.summary,
					}}
					className="font-plusJakartaSans leading-[24px] font-medium text-[13px] text-[#000000] text-opacity-[79%] mt-1"
				></div>
			</div>
		</div>
	);
}

export default BlogDetailsHeader;
