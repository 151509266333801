/* eslint-disable jsx-a11y/img-redundant-alt */
import { useEffect, useState } from "react";
import { TfiArrowCircleRight } from "react-icons/tfi";
import { useNavigate } from "react-router-dom";
import { API_URLS } from "../../config/API_URLS";
import axiosInstance from "../../config/Axios/axiosInstance";
import Pagination from "@mui/material/Pagination";
import CircularProgress from "@mui/material/CircularProgress";

export function CareerTopColleges() {
	const navigate = useNavigate();

	const [CareerData, setCareerData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [page, setPage] = useState(1);
	const [totalCount, setTotalCount] = useState(0);
	const dataLimit = 10;

	useEffect(() => {
		setLoading(true);
		(async () => {
			const res = await axiosInstance.get(`${API_URLS.getCareersList}`, {
				params: {
					page: page,
					limit: dataLimit,
				},
			});
			if (res?.statusCode === 200) {
				setCareerData(res?.result);
				setTotalCount(res?.total_count);
			}
			setLoading(false);
		})();
	}, [page]);
	console.log(CareerData, "CareerData");

	const ChangePage = (event, value) => {
		setLoading(true);
		setPage(value);
	};

	return (
		<>
			<>
				<div className="grid grid-cols-2 gap-5 mb-20">
					{CareerData?.map((item, index) => {
						console.log(JSON.parse(item.specialisations), "data");
						return (
							<div
								key={index}
								onClick={() => navigate(`/career-details/${item?.career_id}`)}
								className="mt-6 cursor-pointer max-w-auto min-w-auto bg-white rounded-xl shadow-[0px_5px_16px_rgba(0,0,0,0.06)]"
							>
								<div className="flex p-2">
									<img
										src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&amp;ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTV8fGh1bWFufGVufDB8fDB8fA%3D%3D&amp;auto=format&amp;fit=crop&amp;w=800&amp;q=60"
										alt="image"
										className="w-[150px] h-[130px] rounded-md object-cover"
									/>
									<div className="pl-2 w-[100%]">
										<div>
											<p className=" w-[100%] text-center item-center justify-between mt-1  flex capitalize text-wrap">
												<p className="text-[13px] font-plusJakartaSans font-semibold text-[green] bg-[#abf7b1] rounded-full pl-3 pr-3 pt-1 pb-1">
													{item.status}
												</p>
												<p className="ml-2 text-[#808080] text-[13px] font-plusJakartaSans border rounded-full pl-3 pr-3 pt-1">
													{item.career_unique_id}
												</p>
												<svg
													className="h-6 w-6 "
													xmlns="http://www.w3.org/2000/svg"
													width="41.822"
													height="41.822"
													viewBox="0 0 41.822 41.822"
												>
													<g
														id="Group_294909"
														data-name="Group 294909"
														transform="translate(-1040 -426)"
													>
														<g
															id="Ellipse_3489"
															data-name="Ellipse 3489"
															transform="translate(1040 426)"
															fill="none"
															stroke="#707070"
															stroke-width="1"
														>
															<circle
																cx="20.911"
																cy="20.911"
																r="20.911"
																stroke="none"
															/>
															<circle
																cx="20.911"
																cy="20.911"
																r="20.411"
																fill="none"
															/>
														</g>
														<path
															id="share"
															d="M16.329,12.391a3.937,3.937,0,0,0-3.243,1.71l-5.5-2.483a3.864,3.864,0,0,0,0-2.947l5.491-2.5a3.937,3.937,0,1,0-.7-2.233,3.914,3.914,0,0,0,.068.664L6.613,7.26A3.944,3.944,0,1,0,6.6,13.027l5.853,2.643a4,4,0,0,0-.067.663,3.943,3.943,0,1,0,3.942-3.942Zm0-10.7a2.253,2.253,0,1,1-2.252,2.253A2.253,2.253,0,0,1,16.329,1.69Zm-12.39,10.7a2.253,2.253,0,1,1,2.252-2.253,2.253,2.253,0,0,1-2.252,2.253Zm12.39,6.2a2.253,2.253,0,1,1,2.253-2.253,2.253,2.253,0,0,1-2.253,2.253Z"
															transform="translate(1048.866 436.577)"
														/>
													</g>
												</svg>
											</p>
										</div>

										<h6 className="mt-3 font-bold font-plusJakartaSans text-[black] text-[18px]">
											{item.career_name.split(" ").slice(0, 3).join(" ")}
											{item.career_name.split(" ").length > 3 ? "..." : ""}
										</h6>
										<p className="mt-2 font-medium font-plusJakartaSans text-[#808080] text-xs">
											{item.exams_associated}
										</p>
										<p className="mt-2 flex item-center text-center">
											<span className="font-medium font-plusJakartaSans text-[black] text-xs">
												Cluster Name
											</span>
											<span className="font-medium font-plusJakartaSans text-[#808080] text-xs ml-2">
												{item.career_cluster}
											</span>
										</p>
									</div>
								</div>
								<div>
									<p className="font-plusJakartaSans text-normal text-[#707070] text-[11.5px] ml-3 mt-1">
										Specialization
									</p>
								</div>
								<div className="flex item-center text-center gap-2 mt-1 mb-2 ml-3 mr-3 overflow-x-auto scrollable-content">
									{JSON.parse(item.specialisations).map((data) => {
										return (
											<div
												key={data.id}
												className="border border-[#2771E7] gap-2 rounded-full pt-1 pb-1 pl-4 pr-4 whitespace-nowrap"
											>
												<p className="font-plusJakartaSans font-medium text-[12px] text-[#2771E7]">
													{data.specialisation_name}
												</p>
											</div>
										);
									})}
								</div>
							</div>
						);
					})}
				</div>
				<Pagination
					onChange={ChangePage}
					count={Math.ceil(totalCount / dataLimit)}
					variant="outlined"
					color="primary"
				/>
			</>
		</>
	);
}
