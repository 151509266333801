import React, { useState, useEffect } from "react";
import { medias } from "../../config/Resources";
import { useUserInfoStore } from "../../store/userinfo";
import { API_URLS } from "../../config/API_URLS";
import axiosInstance from "../../config/Axios/axiosInstance";

const AcademyDetailsCard = () => {
	const { userInfo } = useUserInfoStore((state) => state);
	console.log(userInfo.class, "userInfo");
	const [allClass, setClass] = useState([]);
	const [finalReport, setFinalReport] = useState(null);
	const [status, setStatus] = useState(false);

	useEffect(() => {
		if (userInfo?.class === "9-10") {
			axiosInstance
				.get(`${API_URLS.baseURL}/profile/get10or12Profile?type=1`)
				.then((response) => {
					setClass(response?.result);
				})
				.catch((error) => {
					console.error("There was an error fetching the data!", error);
				});
		} else if (userInfo?.class === "11-12") {
			axiosInstance
				.get(`${API_URLS.baseURL}/profile/get10or12Profile?type=2`)
				.then((response) => {
					setClass(response?.result);
				})
				.catch((error) => {
					console.error("There was an error fetching the data!", error);
				});
		} else if (userInfo?.class === "graduate and professional") {
			axiosInstance
				.get(`${API_URLS.baseURL}/profile/getGradDiplPhdProfile?type=1`)
				.then((response) => {
					setClass(response?.result);
				})
				.catch((error) => {
					console.error("There was an error fetching the data!", error);
				});
		}
		axiosInstance
			.get(`${API_URLS.baseURL}/getStudentFinalReport`)
			.then((response) => {
				setFinalReport(response);
				setStatus(response?.result && response.result.length > 0);
			})
			.catch((error) => {
				console.error("There was an error fetching the final report!", error);
				setStatus(false); // Set status to false if there's an error fetching the data
			});
	}, [userInfo?.class]);

	console.log(finalReport, "finalReport");
	console.log(status, "status");

	const AcdameyDetails = [
		{
			image: medias.images.Profile.Grade,
			label: `${userInfo?.class} Grade`,
			label1: "Group",
		},
    {
			image: medias.images.Profile.Completed,
			label: status ? "Completed" : "Incomplete",
			label1: "Test Status",
		}, 
		{
			image: medias.images.Profile.Time,
			label: `${allClass?.yearOfCompletion}`,
			label1: "Year of Passing",
		},
		{
			image: medias.images.Profile.Location,
			label: userInfo?.state,
			label1: "City",
		},
	];

	return (
		<div className="max-w-md flex bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl mt-10 ml-5 border border-gray-300">
			<div className="w-full">
				<div className="uppercase tracking-wide text-sm font-bold pt-4 pl-4 pr-4">
					<h6 className="font-plusJakartaSans">Academic Details</h6>
				</div>
				<div className="mt-4">
					<div className="h-px bg-gray-300"></div>
				</div>
				<div className="mt-2 pl-7 pb-12 pt-6 flex">
					{AcdameyDetails &&
						AcdameyDetails.map((detail, index) => (
							<div key={index} className="flex items-center gap-12">
								<div className="flex items-center">
									<img
										src={detail.image}
										alt={detail.label}
										className="h-9 w-9"
									/>
									<div className="text-sm ml-1">
										<p className="font-bold text-sm font-plusJakartaSans">
											{detail.label}
										</p>
										<p className="text-gray-600 font-plusJakartaSans text-xs">
											{detail.label1}
										</p>
									</div>
								</div>
								<div className="text-sm"></div>
							</div>
						))}
				</div>
			</div>
		</div>
	);
};

export default AcademyDetailsCard;
