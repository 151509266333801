import { medias } from "../../../config/Resources";

function Personality({ details }) {
	return (
		<>
			<div className="flex items-center mt-10 text-center">
				<img
					className="h-8 w-8"
					src={medias.images.Profile.growth}
					alt="growth"
				/>
				<h6 className="font-plusJakartaSans text-[#000000] font-bold text-[20px] ml-1">
					Personality
				</h6>
			</div>
			<div
				className="w-auto rounded-[20px] ml-auto mr-auto mt-2 overflow-hidden pb-6 pl-6 pr-6 shadow-lg border border-gray-300 bg-cover bg-center"
				style={{ backgroundImage: `url(${medias.images.Profile.Background})` }}
			>
				<div className="flex flex-wrap gap-10">
					{details?.personalities?.map((item, index) => {
						return (
							<div
								className="w-[22%] mt-16 bg-white shadow-xl rounded-lg text-gray-900"
								key={index}
							>
								<div className="rounded-t-lg h-35 overflow-hidden">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 304.17 195.931"
									>
										<path
											id="Intersection_1"
											data-name="Intersection 1"
											d="M198.117,261V93.938a16,16,0,0,1,16-16h272.17a16,16,0,0,1,16,16V261c-46,8.249-97.582,12.871-152.086,12.871S244.115,269.246,198.117,261Z"
											transform="translate(-198.117 -77.938)"
											fill="#011f53"
										/>
										{/* <text
											x="50%"
											y="50%"
											dominant-baseline="middle"
											text-anchor="middle"
											fill="white"
											font-size="24"
											font-family="Arial"
										>
											About Story
										</text> */}
									</svg>
								</div>
								<div className="mx-auto w-28 h-28 relative -mt-16 border-4 border-white rounded-full overflow-hidden">
									<img
										className="object-cover object-center h-32"
										src={item.image}
										alt="Woman looking front"
									/>
								</div>
								<div className="text-center pt-2 pl-5 pr-8 pb-4">
									<h2 className="font-semibold font-plusJakartaSans text-xs">
										{item.full_name}
									</h2>
									<p className="text-gray-500 font-semibold font-plusJakartaSans text-[10px] pt-2">
										Global Research Strategist
									</p>
									<p
										className="font-semibold font-plusJakartaSans text-[12px] line-clamp-4 leading-5 pt-2"
										dangerouslySetInnerHTML={{ __html: item.summary }}
									></p>
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</>
	);
}

export default Personality;
