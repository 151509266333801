import React from "react";
import Face1 from "../../Components/Faces/Face1";
import {
  NineTenthPlanPageContent,
  guidancePageContant,
} from "../../config/pageContent";
import { medias } from "../../config/Resources";
import Text from "../../Components/Text";
import Image from "../../Components/Image";
import Face3 from "../../Components/Faces/Face3";
import Face2 from "../../Components/Faces/Face2";
import { CancelRounded, VerifiedRounded } from "@mui/icons-material";
import Face7 from "../../Components/Faces/Face7";
import Face8 from "../../Components/Faces/Face8";
import Face9 from "../../Components/Faces/Face9";
import Face10 from "../../Components/Faces/Face10";
import Face11 from "../../Components/Faces/Face11";
import Face12 from "../../Components/Faces/Face12";
import Face13 from "../../Components/Faces/Face13";

const NineTenthPlan = () => {
  return (
    <div>
      <Face1
        isDemo={true}
        Title={
          <>
            <div className="relative">
              <div className="my-[5px] flex items-center font-semibold gap-x-[8px]">
                <div className="h-[2px] opacity-[36%] bg-[#000] w-[26px]" />
                <Text className="text-[24px]">EXPLORE</Text>
              </div>
              <Image
                className="w-[45%]"
                src={medias.images.nineTenthPlan.Face1.Face1Logo}
              ></Image>
              <Text className="text-[41px] font-medium ">
                {NineTenthPlanPageContent?.Face1?.titleText}
              </Text>
            </div>
          </>
        }
        mainContainerClass="pb-[150px] !nineTenthFace1Bg !bg-[#DCE6FE]  !bg-opacity-60    relative"
        btnClass="!bg-[#DC9F31] !shadow-[0px_10px_0px_0px_#894100]"
        mainImageClass="absolute bottom-[40px] w-[70%] right-[80px]"
        descriptionClass={"!text-[15px] -mt-[10px]"}
        // mainImageClass={" !w-[40%] -mt-20 ml-12 "}
        mainImage={medias.images.nineTenthPlan.Face1.Face1MainImage}
        description={guidancePageContant.Face1.indianInstituteOfTechnology}
      />
      <Face3
        MainImage={medias.images.nineTenthPlan.Face3.Face3mainImage}
        Description={
          <Text className="text-[18px] font-medium leading-[30px] tracking-wider">
            Indian Institute of Technology, Delhi or IIT Delhi is an Institute
            of Eminence offering about 102 undergraduate, postgraduate,
            integrated and doctoral degree courses. Indian Institute of
            Technology, Delhi courses include B.Tech, M.Tech, M.Sc and Ph.D in
            various streams of Engineering, Physical Sciences, Management,
            Humanities, and Social Sciences. Students seeking admission for
            B.Tech in any stream must appear for JEE Advanced and JoSaa
            counselling. Students completing their degrees are offered IIT Delhi
            placements to prestigious organisations at a salary package ranging
            from Rs. 26 LPA to Rs. 40 LPA (approximately).
            <br /> Established in 1961, IIT Delhi is one of the 23 IITs created
            to be Centres of
          </Text>
        }
        TitleClassname="w-full  flex justify-center !opacity-100 h-[170px]"
        Title={
          <Text className="text-[44px] opacity-100  PlusJakartaSans-ExtraBoldCustom  text-center w-4/5 justify-center">
            {NineTenthPlanPageContent?.Face2.Title}
          </Text>
        }
        dataContainerClassName="flex flex-col "
        btnClassName="!mt-7 !w-fit"
        wrapperClassName="flex-row-reverse justify-between w-full gap-x-[100px]"
        isTitle={false}
        mainwrapperClassName="pt-[106px] top-0 !bg-white"
        ButnText="Read More"
      />
      <Face2
        titleClassName="w-full"
        cardContainerClass="!grid-rows-1 pb-[73px] !h-full"
        containerClassName="nineTenthFace3bg"
        bottomClasses="!pt-[24px] !pb-[50px] !text-[21px]"
        cardContainerImageClass={`!h-[244px] pt-7 px-7`}
        backSideClass={`!h-full !w-full !p-[15px]`}
        containerCardClasses={`!h-[310px] !w-[310px]`}
        cardBackClass={`!p-0`}
        title={NineTenthPlanPageContent.Face3.Title}
        CardsList={NineTenthPlanPageContent?.Face3?.Cards}
      />

      <div className="nineTenthFace4Bg bg-contain bg-[#C3DFFE] pt-[139px] px-[81px] pb-[0px]">
        <div className="flex justify-center w-full">
          <Text className="text-[44px]  PlusJakartaSans-ExtraBoldCustom ">
            {NineTenthPlanPageContent.Face4.Title}
          </Text>
        </div>
        <div className="mt-[40px]">
          <Text className="text-black font-medium text-center text-[19px]">
            {NineTenthPlanPageContent.Face4.Description}
          </Text>
        </div>
        <div className="flex justify-center items-center w-full">
          <Image
            className="w-[90%]"
            src={medias.images.nineTenthPlan.Face4.vsPanal}
            alt="Vsplane"
          />
        </div>
        <div className="flex justify-between pl-[144px]">
          <div className="w-1/3">
            <ul className="flex flex-col justify-between gap-y-3.5">
              {NineTenthPlanPageContent.Face4.pointes.conventional.map(
                (item) => {
                  return (
                    <li className="text-[16px]  font-medium flex gap-x-1 text-center">
                      <Text className=" ">
                        {item?.isAvailable ? (
                          <VerifiedRounded className="!fill-[#239403]" />
                        ) : (
                          <CancelRounded className="!fill-[#F44336]" />
                        )}
                      </Text>
                      <p>{item.desc}</p>
                    </li>
                  );
                }
              )}
            </ul>
          </div>
          <div className="w-1/3 h-[35rem] ">
            <Image
              className="w-[83%]"
              src={medias.images.nineTenthPlan.Face4.divider}
              alt="divider"
            />
          </div>
          <div className="w-1/3 ">
            <ul className="flex flex-col justify-between gap-y-3.5">
              {NineTenthPlanPageContent.Face4.pointes.uniGoals.map((item) => {
                return (
                  <li className="text-[16px]  font-medium flex gap-x-1 text-center">
                    <Text className=" ">
                      {item?.isAvailable ? (
                        <VerifiedRounded className="!fill-[#239403]" />
                      ) : (
                        <CancelRounded className="!fill-[#F44336]" />
                      )}
                    </Text>
                    <p> {item.desc}</p>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
      <div className="nineTenthFace5Bg py-[80px] bg-contain bg-no-repeat ">
        <Text className="text-[44px] PlusJakartaSans-ExtraBoldCustom  w-full text-center">
          {NineTenthPlanPageContent.Face5.Title}
        </Text>
        <Text className="text-[24px] text-center px-[100px] mt-[55px] font-light">
          {NineTenthPlanPageContent.Face5.desc}
        </Text>
        <div className="relative mt-[200px] mb-[351px] ">
          <Image
            className=" relative left-[45%] top-[0rem] w-[7.5%]"
            src={medias.images.nineTenthPlan.Face5.playicon}
            alt="playicon"
          />
        </div>
      </div>
      <Face7
        plans={NineTenthPlanPageContent.Face7.plans}
        isTab={false}
        bodyContainer="mt-[60px]"
      />
      <Face8 />
      <Face9 />
      <Face10 />
      <Face11 />
      <Face12 />
      <Face13 />
    </div>
  );
};

export default NineTenthPlan;
