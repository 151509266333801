import CardsName from "../../Components/CareersDetail/CareerIndividualDetail/CardsName";
import Responsibilities from "../../Components/CareersDetail/CareerIndividualDetail/Responsibilities";
import SpecializationCareer from "../../Components/CareersDetail/CareerIndividualDetail/SpecializationCareer";
import EducationPathWay from "../../Components/CareersDetail/CareerIndividualDetail/EducationPathWay";
import Skills from "../../Components/CareersDetail/CareerIndividualDetail/Skills";
import EntranceExams from "../../Components/CareersDetail/CareerIndividualDetail/EntranceExams";
import Salaries from "../../Components/CareersDetail/CareerIndividualDetail/Salaries";
import Growth from "../../Components/CareersDetail/CareerIndividualDetail/Growth";
import FutureGrowth from "../../Components/CareersDetail/CareerIndividualDetail/FutureGrowth";
import AverageSalary from "../../Components/CareersDetail/CareerIndividualDetail/AvgSalary";
import TopRecruitingCompanies from "../../Components/CareersDetail/CareerIndividualDetail/TopRecruCompanies";
import Personality from "../../Components/CareersDetail/CareerIndividualDetail/Personality";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axiosInstance from "../../config/Axios/axiosInstance";
import { API_URLS } from "../../config/API_URLS";

function CareerIndividualDetails() {
	const { id } = useParams();
	const [details, setDetails] = useState();
	useEffect(() => {
		(async () => {
			const res = await axiosInstance.get(
				`${API_URLS.baseURL}/getCareerDetails?career_id=${id}`
			);
			if (res?.statusCode === 200) {
				setDetails(res.result);
			}
		})();
	}, [id]); 

	if (!details) return <div className="flex item-center text-center justify-center">Loading...</div>;

	return (
		<div className="bg-[#F3F6FF] h-auto">
			<div className="ml-auto mr-auto pt-10 w-[70%]">
				<CardsName details={details} />
			</div>
			<div className="bg-white w-[70%] rounded-lg ml-auto mr-auto mt-48 p-8">
				<Responsibilities details={details} />
				<SpecializationCareer details={details} />
				<EducationPathWay details={details} />
				<Skills details={details} />
				<EntranceExams />
				<Salaries />
				<Growth details={details} />
				<FutureGrowth />
				<Personality details={details} />
				<TopRecruitingCompanies />
				<AverageSalary details={details} />
			</div>
		</div>
	);
}

export default CareerIndividualDetails;
