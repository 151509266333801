export const API_URLS = {
  // adminbaseURL: process.env.REACT_APP_ADMIN_URL,
  // baseURL: process.env.REACT_APP_BASE_URL,

  // adminbaseURL: "https://techugoapps.com:7109/v2/admin",
  // baseURL: "https://techugoapps.com:7109/student",
  // backendDomainURL: "https://techugoapps.com:7109",

  adminbaseURL: "https://www.unigoal.com:7109/v2/admin",
  baseURL: "https://www.unigoal.com:7109/student",
  backendDomainURL: "https://www.unigoal.com:7109",

  checkEmail: "/checkEmail",
  login: "/login",
  signup: "/signup",
  getInterestQuestionPaper: "/getInterestQuestionPaper",
  getAllDropdowns: "/getAllDropdowns",
  getIndianCollegeOverview: "/colleges/getIndianCollegeOverview",
  getEntranceExamOverview: "/entranceExam/getEntranceExamOverview",
  getEntranceExamDates: "/entranceExam/getEntranceExamDates",
  getEntranceExamCourses: "/entranceExam/getEntranceExamCourses",
  getEntranceExamSubjects: "/entranceExam/getEntranceExamSubjects",
  getEntranceExamApplication: "/entranceExam/getEntranceExamApplication",
  getEntranceExamExpertTips: "/entranceExam/getEntranceExamExpertTips",
  getIndianCollegeAdmissionDetails: "colleges/getIndianCollegeAdmissionDetails",
  getIndianCollegeRankings: "colleges/getIndianCollegeRankings",
  getIndianCollegeAdmissionExamDetails:
    "colleges/getIndianCollegeAdmissionExamDetails",
  getEntranceExamResultCounselling:
    "/entranceExam/getEntranceExamResultCounselling",
  getEntranceExamPreviousPapers: "/entranceExam/getEntranceExamPreviousPapers",
  getEntranceExamPattern: "/entranceExam/getEntranceExamPattern",
  getIndianCollegeCourses: "colleges/getIndianCollegeCourses",
  getIndianCollegeAdmissions: "colleges/getIndianCollegeAdmission",
  getIndianCollegeCampusLife: "colleges/getIndianCollegeCampusLife",
  getIndianCollegeFaculty: "colleges/getIndianCollegeFaculty",
  getIndianCollegeScholarships: "colleges/getIndianCollegeScholarships",
  getIndianCollegeGallery: "colleges/getIndianCollegeGallery",
  getCutOffEntranceExam: "colleges/getCutOffEntranceExam",
  getIndianCollegeNoticeboard: "colleges/getIndianCollegeNoticeboard",
  getAllBlogs: "/blogs/getAllBlogs",
  getListById: "/blogs/getListById",
  getIndianCollegePlacements: "colleges/getIndianCollegePlacements",
  getIndianCollegeCourseDetails: "colleges/getIndianCollegeCourseDetails",
  getIndianCollegeScholarshipDetails:
    "colleges/getIndianCollegeScholarshipDetails",
  getStdInternshipsSuggestionForRM:
    "internship/getStdInternshipsSuggestionForRM",
  getIndianCollegeCutOff: "colleges/getIndianCollegeCutOff",
  getCareersList: "/getCareersList",
  getEntranceExams: "entranceExam/getEntranceExams",
  getPersonalityQuestionPaper: "/getPersonalityQuestionPaper",
  addInterestReport: "/addInterestReport",
  addAptitudeReport: "/addAptitudeReport",
  getAptitudeQuestionPaper: "/getAptitudeQuestionPaper",
  getSocialBehaviourReport: "/getSocialBehaviourReport",
  addSocialBehaviourReport: "/addSocialBehaviourReport",
  getSocialBehaviourQuestionPaper: "/getSocialBehaviourQuestionPaper",
  addStudentPersonalityReport: "/addStudentPersonalityReport",

  // colleges
  getIndianColleges: "colleges/getIndianColleges",

  // session schedule

  getMentorList: "/getMentorsList",
  getMentorAvailableDates: "/getAvailableDates",
  getMentorAvailableTime: "/getAvailableTimeSlot",
  getSessionList: "/getSessionList",
  requestSession: "/requestSession",

  getSpecilization: "/getAllSpecializations",

  //Career Guidance
  getPersonalityReport: "/getStudentPersonalityReport",
  getAptitudeReport: "/getAptitudeReport",
  getSocialReport: "/getSocialBehaviourReport",
  getInterestReport: "/getInterestReport",
  getLearningReport: "/getLearningStyleAssesDescReport",
  getValuesReport: "/getValuesAssesDescReport",

  //plans

  getCounsellingPlans: "/counsellingPlans",
  getActivePlan: "/getStudentPlanCategory",

  buyPlan: "/addPlansCategory",
  report: "/report/html",

  //career mapping
  getFinalReport: "/getStudentFinalReport",
  get9th10thStreamSelection: "/get9th10thStreamSelection",
  getCareerDetails: "/getCareerDetails",

  //roadmap

  getAcademicRoadmap: "/pb/getAcademicRoadmap",
};
