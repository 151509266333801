import React from "react";
import { IoSearchSharp } from "react-icons/io5";

const SearchBar = () => {
	return (
		<div className="flex w-full mt-6">
			<div className="relative w-full">
				<input
					type="text"
					className="pl-8 pr-4 py-2 font-plusJakartaSans font-medium text-[11.5px] border border-[#E2E2E2] rounded-lg text-[#787878] w-full bg-[#FAFAFA]"
					placeholder="Search Courses"
				/>
				<IoSearchSharp className="absolute left-3 top-1/2 transform -translate-y-1/2 text-[#635D5D]" />
			</div>
		</div>
	);
};

export default SearchBar;
