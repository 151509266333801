import React from "react";
import Image from "../../Image";
import { Tab, Tabs } from "@mui/material";
import { guidancePageContant } from "../../../config/pageContent";
import Text from "../../Text";
import { medias } from "../../../config/Resources";

const Face9 = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };
  return (
    <div className="pt-[10px] pb-[80px] flex justify-center flex-col items-center">
      <div>
        <Text className="text-[50px] font-black  PlusJakartaSans-ExtraBoldCustom ">
          {guidancePageContant.Face5.designRoadMap}
        </Text>
      </div>
      <div className="mt-[50px] mb-[20px]">
        <Tabs
          value={value}
          classes={{
            indicator: "!bg-none !bg-transparent",
          }}
          onChange={handleChange}
          className="border border-[#00000040]  rounded-[13px] px-[16px] py-[10px] "
          aria-label="basic tabs example"
        >
          {guidancePageContant.Face7.tabs.map((items) => {
            return (
              <Tab
                className="!h-[53px] !w-[250px] m-[4px]"
                classes={{
                  selected:
                    "!bg-[#2771E7] !text-white !rounded-[11px] !border-none shadow-md shadow-[#acc3ec]  ",
                }}
                label={
                  <>
                    <p className="text-[15px] font-medium capitalize">
                      {items.Title}
                    </p>
                  </>
                }
              />
            );
          })}
        </Tabs>
      </div>
      <Image
        src={medias.images.guidance.Face7.Universities}
        className="w-[90%]"
        alt="University"
      />
    </div>
  );
};

export default Face9;
