import React, { useState, useEffect } from "react";
import Image from "../../../Image";
import { medias } from "../../../../config/Resources";
import Text from "../../../Text";
import classNames from "classnames";
import { Modal } from "@mui/material";

const Timer = ({
  isApti = false,
  totalTime = 60,
  isStartTime = false,
  bgImage = "",
  mainClassName = "",
  textClassName = "",
  imageClass = "",
  timerPositionClass = "",
}: {
  imageClass?: string;
  timerPositionClass?: string;

  totalTime?: number;
  isApti?: boolean;
  isStartTime?: boolean;
  bgImage?: string;
  mainClassName?: string;
  textClassName?: string;
}) => {
  const initialTime = totalTime * 60; // 40 minutes in seconds
  const [timeRemaining, setTimeRemaining] = useState<number>(initialTime);

  useEffect(() => {
    let timer: any = null;
    if (isStartTime) {
      timer = setInterval(() => {
        setTimeRemaining((prevTime: number) => {
          if (prevTime > 0) {
            return prevTime - 1;
          } else {
            clearInterval(timer);
            // You can add any logic to handle timer completion here
            return 0; // Return a value to satisfy SetStateAction<number>
          }
        });
      }, 1000);
    }

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(timer);
  }, [isStartTime]); // Empty dependency array ensures that the effect runs only once on mount

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(
      remainingSeconds
    ).padStart(2, "0")}`;
  };

  return (
    <div
      className={classNames(
        mainClassName,
        "relative z-30 flex flex-col items-center justify-center w-full mt-auto"
      )}
    >
      {isApti && (
        <Text className="text-white font-digital desktop:text-[20px] text-[25px]">
          time Remaining
        </Text>
      )}
      <div className={classNames(timerPositionClass, "relative left-[13%]")}>
        <Image
          src={bgImage ? bgImage : medias.images.intrestImages.timerBackgroung}
          className={classNames(imageClass, "w-[120px]")}
        />
        <Text
          className={classNames(
            textClassName,
            "absolute text-[22px] w-[90px] desktop:text-[18px] text-white  top-[calc(50%-18px)] font-bold font-digital"
          )}
        >
          {formatTime(timeRemaining)}
        </Text>
      </div>
    </div>
  );
};

export default Timer;
