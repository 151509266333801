import { useState } from "react";
import { FaAngleUp } from "react-icons/fa";
import { RiArrowDropDownLine } from "react-icons/ri";
import LikeAndReportExam from "./LikeAndReportExam";
import { Verified } from "@mui/icons-material";
import { IoTimeOutline } from "react-icons/io5";

function ExamReviews() {
	const [isExpanded, setIsExpanded] = useState(false);

	const toggleReadMore = () => {
		setIsExpanded(!isExpanded);
	};

	const ReportData = [{}, {}, {}, {}, {}];

	const selectedData = [
		{
			id: 1,
			text: "Scholarship",
		},
		{
			id: 2,
			text: "Internship",
		},
		{
			id: 3,
			text: "Hostel Fees",
		},
	];
	return (
		<div>
			{ReportData?.map((_index) => {
				return (
					<div className="pt-8 pl-4 pr-4 pb-4 mb-4 bg-white rounded-lg shadow-custom1">
						<p className="font-plusJakartaSans text-[14px] font-semibold">
							Ques. Which is better IIT-D electrical or IIT Madras Electrical?
						</p>
						<div className="">
							<div className="flex flex-wrap gap-4 mt-2 mb-3">
								{selectedData.map((chip) => (
									<p
										key={chip.id}
										className="font-plusJakartaSans bg-[#F37400] bg-opacity-10 rounded-full border pt-1 pb-1 pl-4 pr-4 inline-flex text-[12px] text-center items-center gap-1 font-medium cursor-pointer"
									>
										{chip.text}
									</p>
								))}
							</div>
						</div>
						<div className="mt-4 flex items-center mb-2">
				<p className="font-plusJakartaSans w-11 h-11 font-bold text-[#2771E7] bg-[#2771E7] bg-opacity-10 p-2 rounded-xl text-[25px] flex justify-center items-center">
					V
				</p>
				<div className="ml-1">
					<p className="font-plusJakartaSans text-[14px] font-semibold flex items-center text-center gap-1">
						Jaypal Sharma
						<Verified className="text-[rgba(39,113,231,1)] ml-1 3xl:text-[18px]" />
					</p>
					<p className="font-plusJakartaSans mt-[2px] text-[#707070] text-[11px] font-medium flex items-center text-center gap-1">
						<IoTimeOutline />
						24 May 2023, 4:43 IST
					</p>
				</div>
			</div>
						<div className="">
							<div className="relative overflow-hidden">
								<div
									className={`font-plusJakartaSans font-medium text-[11.5px] text-[#000000] text-opacity-60 mt-1 ${
										!isExpanded ? "line-clamp-5" : ""
									}`}
								>
									<p>
										Lorem Ipsum is simply dummy text of the printing and
										typesetting industry. Lorem Ipsum has been the industry's
										standard dummy text ever since the 1500s, when an unknown
										printer took a galley of type and scrambled it to make a
										type specimen book. It has survived not only five centuries,
										but also the leap into electronic typesetting, remaining
										essentially unchanged. It was popularised in the 1960s with
										the release of Letraset sheets containing Lorem Ipsum
										passages, and more recently with desktop publishing software
										like Aldus PageMaker including versions of Lorem Ipsum.Lorem
										Ipsum is simply dummy text of the printing and typesetting
										industry. Lorem Ipsum has been the industry's standard dummy
										text ever since the 1500s, when an unknown printer took a
										galley of type and scrambled it to make a type specimen
										book. It has survived not only five centuries, but also the
										leap into electronic typesetting, remaining essentially
										unchanged. It was popularised in the 1960s with the release
										of Letraset sheets containing Lorem Ipsum passages, and more
										recently with desktop publishing software like Aldus
										PageMaker including versions of Lorem Ipsum.
									</p>
								</div>
								{!isExpanded && (
									<div className="absolute bottom-0 left-4 w-full h-8 bg-gradient-to-t from-white to-transparent"></div>
								)}
							</div>
							<button
								onClick={toggleReadMore}
								className="text-blue-500 font-plusJakartaSans font-bold text-[12px] flex items-center ml-[80%]"
							>
								{isExpanded ? (
									<>
										Read less
										<FaAngleUp className="w-4 h-4 ml-1" />
									</>
								) : (
									<>
										Read more
										<RiArrowDropDownLine className="w-6 h-6" />
									</>
								)}
							</button>
							<div className="border-t-2 border-dashed mb-5 border-gray-300 mt-2"></div>
							<LikeAndReportExam />
						</div>
					</div>
				);
			})}
		</div>
	);
}

export default ExamReviews;
