import { medias } from "../../../../config/Resources";

/* eslint-disable jsx-a11y/img-redundant-alt */
function TipsFromExpertsSubjectName({ TipsExperts }) {
	return (
		<div>
			{TipsExperts?.experts?.map((data, index) => {
				return (
					<div className="ml-4 mr-4" key={index}>
						<h6 className="mt-5 font-plusJakartaSans flex items-center text-center gap-[5px] font-bold text-[#000000] text-[15px]">
							<img
								className="w-5 h-5"
								src={medias.images.Exam.Registration}
								alt="Privilege"
							/>
							<span className="font-plusJakartaSans font-bold mt-[1px]">
								{data?.expert_name}
							</span>
						</h6>
						<img
							className="w-40 text-[#000000] pt-1"
							src={medias.images.Profile.line1}
							alt="lin1"
						/>
						<div>
							<div>
								<div
									dangerouslySetInnerHTML={{
										__html: data?.details,
									}}
									className="font-plusJakartaSans font-medium text-[11.5px] text-[#000000] text-opacity-70 mt-[8px]"
								></div>
							</div>
						</div>
					</div>
				);
			})}
		</div>
	);
}

export default TipsFromExpertsSubjectName;
