import { TbFlag3 } from "react-icons/tb";
import { GoThumbsdown, GoThumbsup } from "react-icons/go";
import { medias } from "../../../../config/Resources";
import { IoTimeOutline } from "react-icons/io5";
import { BsReply } from "react-icons/bs";

function LikeAndReport() {
	return (
		<div className="flex item-center text-center justify-between mr-5 mb-5">
			<div className="flex item-center text-center gap-4">
				<p className="font-plusJakartaSans ml-5 text-[13px] font-semibold flex item-center text-center space-2 text-[#000000]">
					<GoThumbsup className="w-5 h-5" />{" "}
					<span className="ml-2 mt-[2px]">1</span>
				</p>
				<p className="font-plusJakartaSans ml-5 text-[13px] font-semibold flex item-center text-center space-2 text-[#000000]">
					<GoThumbsdown className="w-5 h-5" />{" "}
					<span className="ml-2 mt-[2px]">0</span>
				</p>
				<p className="font-plusJakartaSans ml-5 text-[13px] font-semibold flex item-center text-center space-2 text-[#000000]">
					<BsReply className="w-5 h-5" />
					<span className="ml-2 mt-[2px]">Report</span>
				</p>
				<p className="font-plusJakartaSans ml-5 text-[12px] font-semibold flex item-center text-center space-2 text-[#000000]">
					<BsReply className="w-5 h-5" />{" "}
					<span className="ml-2 mt-[2px]">Reply</span>
				</p>
			</div>

			<p className="font-plusJakartaSans mt-[2px] text-[#707070] text-[12px] font-medium flex items-center text-center gap-1">
				<IoTimeOutline />
				24 May 2023, 4:43 IST
			</p>
		</div>
	);
}

export default LikeAndReport;
