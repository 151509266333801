/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { medias } from "../../config/Resources";

const TopTrainingRecommendations = () => {
	const academyDetails = [
		{
			image: medias.images.Profile.Boat,
			label: "Boat",
		},
		{
			image: medias.images.Profile.Apple,
			label: "Apple",
		},
		{ image: medias.images.Profile.Netflix, label: "Netflix" },
		{ image: medias.images.Profile.Hotstar, label: "Disney" },
		{ image: medias.images.Profile.Hotstar, label: "Disney" },
		{ image: medias.images.Profile.Hotstar, label: "Disney" },
		{ image: medias.images.Profile.Hotstar, label: "Disney" },
		{ image: medias.images.Profile.Hotstar, label: "Disney" },
		{ image: medias.images.Profile.Hotstar, label: "Disney" },
		{ image: medias.images.Profile.Hotstar, label: "Disney" },
		{ image: medias.images.Profile.Hotstar, label: "Disney" },
	];

	return (
		<>
			<div className="tracking-wide text-sm font-plusJakartaSans font-extrabold pt-12 pl-4 pr-4">
				Top Internship out of the platform Recommendations
			</div>
			<div className="max-w-screen-xl w-full flex gap-10 mb-8 ml-5 mt-5 overflow-x-auto scrollbar-hide">
				<div className="flex gap-10">
					{academyDetails?.map((detail, index) => (
						<div
							key={index}
							className="mb-5 w-[13%] rounded-lg shadow-lg flex-shrink-0 max-w-xs"
							style={{ border: "" }}
						>
							<div className="p-3">
								<div className="flex items-center justify-center mb-2">
									<img
										src={detail.image}
										alt={detail.label}
										className="h-[90%] w-[90%]"
									/>
								</div>
								<div className="text-sm text-center">
									<p className="font-bold font-plusJakartaSans">
										{detail.label}
									</p>
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
		</>
	);
};

export default TopTrainingRecommendations;
