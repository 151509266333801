/* eslint-disable array-callback-return */
import { IoChevronBackOutline } from "react-icons/io5";
import { medias } from "../../config/Resources";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { useEffect, useState } from "react";
import axiosInstance from "../../config/Axios/axiosInstance";
import TaskListAccordion from "./TaskListAccordion";
import { API_URLS } from "../../config/API_URLS";
import Objective from "./taskObjective";
import { useNavigate } from "react-router-dom";

function MileStoneData({
	receivedData,
	activeTab,
	Maingoal,
	MilestoneName,
	DurationTime,
	MilestonePercentage,
	ItemId,
}) {
	console.log(ItemId.academic_id, "ItemId");
	const [refresh, setRefresh] = useState(false);
	const [count, setCount] = useState("");
	const [data, setData] = useState([]);
	const api = {
		1: `${API_URLS.baseURL}/pb/getAcademicRoadmap?`,
		2: `${API_URLS.baseURL}/pb/getExtraCurricularRoadmap?`,
		3: "",
		4: "",
		5: `${API_URLS.baseURL}/getPassionRoadmap?`,
		6: `${API_URLS.baseURL}/pb/getHealthRoadmap?`,
	};
	const getTaskListData = async (index) => {
		try {
			let url = api[activeTab];

			url += `searchId=${receivedData}&type=activity`;

			const res = await axiosInstance.get(url);

			setData(res.result);
		} catch (error) {
			console.log(error);
		}
	};
	const getRoadmapTaskCount = async () => {
		try {
			const tabType = {
				1: "Academic",
				2: "Extra curricular",
				3: "",
				4: "",
				5: "Passion",
				6: "Health",
			};

			const tabId = {
				1: `${ItemId?.academic_id}`,
				2: `${ItemId?.extra_curricular_id}`,
				3: "",
				4: "another_id",
				5: `${ItemId?.passion_id}`,
				6: `${ItemId?.health_id}`,
			};

			const mainId = tabId[activeTab];
			const type = tabType[activeTab];

			if (mainId && type) {
				const url = `${API_URLS.baseURL}/pb/getRoadmapTaskCount?main_id=${mainId}&type=${type}`;
				const res = await axiosInstance.get(url);
				setCount(res?.result);
			}
		} catch (error) {
			console.log(error);
		}
	};
	useEffect(() => {
		getTaskListData();
		getRoadmapTaskCount();
	}, []);

	console.log(count, 'countValue')

	const percentage = 66;
	const navigate = useNavigate();
	return (
		<div>
			<div className="flex mt-8 ml-5 align-center">
				<p
					className="text-[#909090] font-plusJakartaSans text-sm font-medium cursor-pointer"
					onClick={() =>
						navigate("/profile/career-counselling?location=profile-building")
					}
				>
					Goals
				</p>
				<span className="pl-2 pt-[2px] text-[#909090]">
					<IoChevronBackOutline />
				</span>
				<p
					className="text-[#909090] font-plusJakartaSans text-sm font-medium pl-2 cursor-pointer"
					onClick={() =>
						navigate("/profile/career-counselling?location=profile-building")
					}
				>
					Milestones
				</p>
				<span className="pl-2 pt-[1px] text-[#909090]">
					<IoChevronBackOutline />
				</span>
				<p className="font-plusJakartaSans pl-2 text-sm font-medium">
					Activity
				</p>
			</div>
			<div className="relative text-center w-[18%] mt-2">
				<img
					className="w-[100%]"
					src={medias.images.Profile.Milestone}
					alt="milestone"
				/>
				<div className="absolute top-[50%] left-[50%] p-[5px] transform -translate-x-1/2 -translate-y-1/2">
					<p className="font-plusJakartaSans font-semibold m-0 text-[18px]">
						{MilestoneName}
					</p>
				</div>
			</div>
			<div className="flex items-center justify-between mt-5">
				<span className="ml-3 flex items-center">
					<p className="bg-[#2771E7] font-plusJakartaSans font-semibold text-[white] pt-1 pb-1 pl-2 pr-2 rounded-lg text-[14px]">
						{count} Task in list
					</p>
					<img
						className="w-5 h-5 mr-1 ml-3"
						src={medias.images.Profile.image2}
						alt="image2"
					/>
					<p className="font-bold font-plusJakartaSans">{Maingoal}</p>
				</span>
				<div className="flex items-center justify-between">
					<div>
						<p className="font-plusJakartaSans font-semibold text-[#909090] text-[13px]">
							Deadline
						</p>

						<p className="font-plusJakartaSans font-semibold text-[15px]">
							{DurationTime}
						</p>
					</div>
					<div className="w-[60px] ml-8">
						{" "}
						<CircularProgressbar
							value={MilestonePercentage}
							text={`${MilestonePercentage}%`}
							styles={buildStyles({
								rotation: 0.25,
								strokeLinecap: "butt",
								textSize: "16px",
								pathTransitionDuration: 0.5,
								pathColor: `rgba(62, 152, 199, ${percentage / 100})`,
								textColor: "#f88",
								trailColor: "#d6d6d6",
								backgroundColor: "#3e98c7",
							})}
						/>
					</div>
				</div>
			</div>
			<hr className="border-t border-gray-300 mt-4" />

			<Objective />
			<TaskListAccordion
				datas={data}
				setDatas={setData}
				setRefresh={setRefresh}
				refresh={refresh}
				activeTab={activeTab}
				getTaskListData={getTaskListData}
				receivedData={receivedData}
			/>
		</div>
	);
}

export default MileStoneData;
