import React, { useState } from "react";

const InternshipsDropDown = ({ setDrop }) => {
	const [active, setActive] = useState(1);
	const chipLabels = [
		{ label: "Platform Internship", id: 1 },
		{ label: "External Internship", id: 2 },
	];

	const handleDropdownChange = (event) => {
		const selectedId = parseInt(event.target.value);
		setActive(selectedId);
		setDrop(selectedId);
	};

	return (
		<div className="flex flex-col ml-5 mt-5">
			<select
				className="rounded-lg pt-2 pb-2 pl-2 pr-2 cursor-pointer font-bold text-sm mr-2 border border-gray-300"
				value={active}
				onChange={handleDropdownChange}
			>
				{chipLabels.map((chip) => (
					<option key={chip.id} value={chip.id}>
						<p className="font-plusJakartaSans"> {chip.label}</p>{" "}
					</option>
				))}
			</select>
		</div>
	);
};

export default InternshipsDropDown;
