import RatingStar from "@mui/material/Rating";

const RatingComp = ({
	ratingValue,
	setRatingValue = () => {},
	title,
	isHeader,
}) => {
	return (
		<div>
			{!!isHeader && (
				<div className="mb-3 flex items-center mt-1 gap-2">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="16"
						height="16"
						viewBox="0 0 20.429 19.589"
					>
						<path
							id="Icon_awesome-star"
							data-name="Icon awesome-star"
							d="M10.1.645l-2.368,4.8-5.3.772A1.161,1.161,0,0,0,1.793,8.2l3.833,3.735L4.72,17.211A1.16,1.16,0,0,0,6.4,18.433l4.74-2.491,4.74,2.491a1.161,1.161,0,0,0,1.683-1.222l-.907-5.276L20.491,8.2a1.161,1.161,0,0,0-.642-1.98l-5.3-.772L12.183.645A1.161,1.161,0,0,0,10.1.645Z"
							transform="translate(-0.928 0.501)"
							fill="#f0d41b"
							stroke="#000"
							stroke-width="1"
						/>
					</svg>

					<h5 className="miniDesk:text-[14px] font-plusJakartaSans  text-[14px] font-bold ">
						{title}
					</h5>
				</div>
			)}
			<RatingStar
				value={ratingValue}
				onChange={(event, newValue) => {
					setRatingValue(newValue);
				}}
			/>
		</div>
	);
};

export default RatingComp;
