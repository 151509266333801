// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAuth } from "firebase/auth";
// import { getFirestore } from "firebase/firestore";
import firebase from "firebase/app";
import "firebase/firestore";

const firebaseConfig = {
  // apiKey: "AIzaSyCRoVbPJrEocR551J2Vtiwsi_GCk3_erRQ",
  // authDomain: "sygnificstudent.firebaseapp.com",
  // projectId: "sygnificstudent",
  // storageBucket: "sygnificstudent.appspot.com",
  // messagingSenderId: "934522015532",
  // appId: "1:934522015532:web:3296fcf1c47566b39f6689",
  // measurementId: "G-5F5RECN7T4",

  apiKey: "AIzaSyA0UuV-ljfjfKKx48o1H-Mj3098GpZwQxg",
  authDomain: "sygnificmentor.firebaseapp.com",
  projectId: "sygnificmentor",
  storageBucket: "sygnificmentor.appspot.com",
  messagingSenderId: "98316910118",
  appId: "1:98316910118:web:2ed8e2936c0b2c75fcf0e7",
  measurementId: "G-RX0T896WPJ",
};
// import { getAuth } from "firebase/auth";
// import { getFirestore } from "firebase/firestore";
// const app = initializeApp(firebaseConfig);

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const firestore = firebase.firestore();

// export const auth = getAuth(app);
export const db = firestore;
