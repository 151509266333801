import React from "react";
import { CounsellingLists } from "../../../config/pageContent";
import Image from "../../../Components/Image";
import PsychomatricProfileTest from "../PsychomatricProfileTest";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import classNames from "classnames";
import Report from "../Report";
import SessionSchedule from "../SessionSchedule";
import Plans from "../Plans";
import CareerMapping from "../CareerMapping";
import ProfileBuilding from "../ProfileBuilding";

const Screens: any = {
  psychometricTest: PsychomatricProfileTest,
  report: Report,
  "session-schedule": SessionSchedule,
  "career-mapping": CareerMapping,
  "profile-building": ProfileBuilding,
  plans: Plans,
};

const CareerCounselling = () => {
  // const searchParams: any = useSearchParams();
  // const Screen: any = Screens[searchParams[0].get("location")];
  const location = useLocation();

  const [searchParams] = useSearchParams();
  const locationParam: any = searchParams.get("location");

  // Assuming Screens is an object that maps location values to components
  const Screen = Screens[locationParam];

  const currentURL = `${location.pathname}${location.search}${location.hash}`;

  const navigate = useNavigate();

  // console.log("locationParam", locationParam, currentURL);

  return (
    <div className="bg-[#EEEEEE] rounded-[20px]  mt-[30px]">
      <div className="flex gap-[12px] overflow-auto  pt-[20px] pl-[15px]  3xl:p-[20px] desktop:!p-[15px] px-[44px]  no-scrollbar ">
        {CounsellingLists?.map((items: any) => {
          return (
            <div
              onClick={() => {
                navigate(
                  items?.url
                    ? `/profile/career-counselling?location=${items?.url}`
                    : currentURL
                );
              }}
              className={classNames(
                items?.url === locationParam
                  ? "bg-[#2771E7] text-white hover:!bg-[#2771E7] "
                  : "bg-white",
                "flex desktop:text-[13px] hover:bg-[#93bcff] 1500:text-[17px] text-nowrap hover:text-white desktop:py-[10px] font-[PlusJakartaSans-SemiBold] min-w-fit items-center rounded-full cursor-pointer  px-[10px] py-[7px] gap-[10px]"
              )}
            >
              <Image
                src={items?.icons}
                className="w-[30px] desktop:!w-[20px] desktop:!h-[20px] 3xl:w-[25px] h-[30px]"
              />
              {items?.Name}
            </div>
          );
        })}
      </div>
      <Screen />
    </div>
  );
};

export default CareerCounselling;
