import React from "react";
import ExploreOtherSection from "./ExploreOtherSection";
import MentorSlider from "../../../Components/BookedMentor/MentorSlider";
import ReletedProjects from "../../../Components/BookedMentor/ReletedProjects";
import { BookedCard } from "./BookedCard";

const Upcoming = ({ mentorList, BookedMentorCount, BookedMentorList = [] }) => {
  return (
    <>
      <div className="w-full">
        <div className="w-[100%] my-8">
          <div className="flex gap-3 flex-wrap no-scrollbar pl-6 ">
            {!!BookedMentorList.length ? (
              BookedMentorList?.map((ele, i) => (
                <BookedCard key={i} data={ele} />
              ))
            ) : (
              <>Not Booked Data</>
            )}
          </div>
        </div>
        <ExploreOtherSection />
        <div className="overflow-x-hidden no-scrollbar h-[500px] w-full">
          <div className="flex justify-between py-6">
            <h4 className="font-bold text-[28px] font-quitery">
              Choose The Best Career Counsellor Now
            </h4>
            <h4 className="text-[#239403] font-semibold cursor-pointer">
              View All
            </h4>
          </div>
          <MentorSlider data={mentorList} />
        </div>
        <div className="flex justify-between pt-12 pb-6">
          <h4 className="font-bold text-[28px] font-quitery">
            Releted Projects{" "}
          </h4>
        </div>
        <div className="flex gap-3 overflow-x-scroll no-scrollbar pl-6 ">
          {Array.from({ length: 9 })?.map((ele, i) => (
            <ReletedProjects key={i} />
          ))}
        </div>
      </div>
    </>
  );
};

export default Upcoming;
