/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import { medias } from "../../config/Resources";
import axiosInstance from "../../config/Axios/axiosInstance";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { CiShare2 } from "react-icons/ci";
import { API_URLS } from "../../config/API_URLS";

const internshipData = [
	{
		title: "UI/UX Designer",
		category: "Category Designer",
		location: "Bhopal,India",
		startDate: "24 Jul 2024",
		endDate: "28 Jul 2024",
		package: "R 3lpa-5lpa",
		openings: 8,
	},
	{
		title: "UI/UX Designer",
		category: "Category Designer",
		location: "Bhopal,India",
		startDate: "24 Jul 2024",
		endDate: "28 Jul 2024",
		package: "R 3lpa-5lpa",
		openings: 8,
	},
	{
		title: "UI/UX Designer",
		category: "Category Designer",
		location: "Bhopal,India",
		startDate: "24 Jul 2024",
		endDate: "28 Jul 2024",
		package: "R 3lpa-5lpa",
		openings: 8,
	},
	{
		title: "UI/UX Designer",
		category: "Category Designer",
		location: "Bhopal,India",
		startDate: "24 Jul 2024",
		endDate: "28 Jul 2024",
		package: "R 3lpa-5lpa",
		openings: 8,
	},
	{
		title: "UI/UX Designer",
		category: "Category Designer",
		location: "Bhopal,India",
		startDate: "24 Jul 2024",
		endDate: "28 Jul 2024",
		package: "R 3lpa-5lpa",
		openings: 8,
	},
	{
		title: "UI/UX Designer",
		category: "Category Designer",
		location: "Bhopal,India",
		startDate: "24 Jul 2024",
		endDate: "28 Jul 2024",
		package: "R 3lpa-5lpa",
		openings: 8,
	},
];

const TopInternshipRecommCards = ({ drop }) => {
	const [data, setData] = useState([]);
	const navigate = useNavigate();

	const getInstershipData = async () => {
		try {
			const res = await axiosInstance.get(
				`${API_URLS.getStdInternshipsSuggestionForRM}`
			);
			setData(res.result.intenship_data);
		} catch (error) {
			console.log(error);
		}
	};
	useEffect(() => {
		getInstershipData();
	}, []);
	console.log(data, "dataInstance");
	const calculatePackage = (minSalary, maxSalary) => {
		const minPackage = ((minSalary * 12) / 100000).toFixed(0);
		const maxPackage = ((maxSalary * 12) / 100000).toFixed(0);
		return `${minPackage} LPA - ${maxPackage} LPA`;
	};

	const calculateDaysPassed = (endDate) => {
		const endDateMoment = moment(endDate);
		const currentDate = moment();
		const daysPassed = currentDate.diff(endDateMoment, "days");
		return daysPassed;
	};

	return (
		<>
			<>
				<div className="flex justify-between items-center w-90 mt-8 ml-5 mr-8">
					<h6 className="text-lg font-bold font-plusJakartaSans">
						Top Training Recommendations
					</h6>
					<h6 className="text-lg font-bold text-[#239403] font-plusJakartaSans">
						View all
					</h6>
				</div>
				<div className="w-full flex flex-wrap justify-center gap-10 item-center mt-5">
					{data?.map((internship, index) => (
						<div
							// onClick={() => navigate("/profile/internship-details")}
							key={index}
							className="bg-black rounded-xl shadow-md overflow-hidden border-[1px] border-[#000000] w-[30%] pb-3 mb-8 mt-3"
						>
							<div className="bg-white pl-2 pt-2 pr-2 pb-2 rounded-xl">
								<div className="flex items-center justify-between">
									<div className="flex items-center">
										<img
											className="w-12 h-12"
											src={medias.images.Profile.NoteBook}
											alt="image"
										/>
										<p className="text-base font-bold font-plusJakartaSans ml-1 pt-2">
											UI/UX Designer
										</p>
									</div>
									<div className="flex items-center">
										<CiShare2 className="h-6 w-6" />
										<img
											className="h-6 w-6 ml-1"
											src={medias.images.Profile.settings2}
											alt="settings"
										/>
									</div>
								</div>
								<p className="ml-14 font-bold font-plusJakartaSans text-sm">
									{internship.companyName}
								</p>

								<div className="mt-1 ml-14">
									<div className="flex align-center items-center">
										<img
											className="h-4 w-4"
											src={medias.images.Profile.location3}
											alt="location3"
										/>
										<p className="font-bold font-plusJakartaSans ml-1 text-[13px]">
											{internship.city}
										</p>
									</div>
									<div className="flex align-center items-center mt-[5px]">
										<img
											className="h-4 w-4"
											src={medias.images.Profile.bags}
											alt="location3"
										/>
										<p className="font-bold font-plusJakartaSans ml-1 text-[13px]">
											{internship.designation}
										</p>
									</div>
									<div className="flex align-center items-center mt-[5px]">
										<img
											className="h-4 w-4"
											src={medias.images.Profile.message}
											alt="location3"
										/>
										<p className="ml-1 font-bold font-plusJakartaSans text-[13px]">
											₹{" "}
											{calculatePackage(
												internship.minSalary,
												internship.maxSalary
											)}
										</p>
									</div>
								</div>

								<div className="flex items-center mt-1 justify-between">
									<div className="flex align-center items-center">
										<img
											className="h-4 w-4 mt-2"
											src={medias.images.Profile.job}
											alt="location3"
										/>
										<p className="ml-1 mt-2 font-bold font-plusJakartaSans text-[13px]">
											Job Closed{" "}
											{moment(internship.startDate).format("DD MMM YYYY")}
										</p>
									</div>
									<div>
										<p className="text-[12px] font-bold text-gray-500 font-plusJakartaSans">
											{calculateDaysPassed(internship.endDate)} days ago
										</p>
									</div>
								</div>
							</div>
						</div>
					))}
				</div>
			</>
		</>
	);
};

export default TopInternshipRecommCards;
