/* eslint-disable jsx-a11y/anchor-is-valid */
import { medias } from "../../../../config/Resources";
import {
	MdOutlineKeyboardArrowLeft,
	MdOutlineKeyboardArrowRight,
} from "react-icons/md";

function DownloadPractice() {
	return (
		<>
			<div>
				<h6 className="font-plusJakartaSans font-extrabold text-[14px] mb-3 text-[#000000] text-center">
					Download practice papers
				</h6>
			</div>

			<div className="relative w-max mx-auto">
				<MdOutlineKeyboardArrowLeft className="absolute left-[-6%] top-1/2 transform -translate-y-1/2 bg-[#ffffff] w-6 h-6 rounded-full p-1 border border-[#C2C2C2] cursor-pointer" />
				<div className="pt-2 pb-4 pl-2 pr-2 w-[180px] rounded-xl shadow-md dark:bg-gray-800 dark:border-gray-700">
					<div className="">
						<img
							className=" w-28 h-28 mt-2 ml-auto mr-auto p-5 bg-[#FBC343] bg-opacity-20 rounded-full"
							src={medias.images.Exam.Papers}
							alt="BackgroundImage"
						/>
					</div>
					<div className="pb-4 pl-2 pr-1 pt-4">
						<p className="font-p lusJakartaSans flex items-center text-center font-bold text-[13px] text-[#000000]">
							JEE Main 2023 Question Paper Jan 25 Shift 2
						</p>
					</div>
				</div>
				<MdOutlineKeyboardArrowRight className="absolute right-[-6%] top-1/2 transform -translate-y-1/2 bg-[#ffffff] w-6 h-6 rounded-full p-1 border border-[#C2C2C2] cursor-pointer" />
			</div>
		</>
	);
}

export default DownloadPractice;
