import React, { useRef } from "react";
import { medias } from "../../../../config/Resources";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";

/* eslint-disable jsx-a11y/img-redundant-alt */
function EntranceStudyMaterial({
	showTabs,
	TipsExperts,
	examPattern,
	previuosPapers,
}) {
	console.log(examPattern?.study_material, "examPattern");
	const data =
		showTabs === 7
			? TipsExperts?.recommended_books?.books
			: showTabs === 6
			? previuosPapers?.papers
			: showTabs === 4
			? examPattern?.study_material
			: [{}, {}, {}, {}, {}, {}, {}];
	const scrollContainerRef = useRef(null);

	const handleScroll = () => {
		if (scrollContainerRef.current) {
			scrollContainerRef.current.scrollBy({
				left: 200,
				behavior: "smooth",
			});
		}
	};

	const handleImageClick = (fileUrl) => {
		if (fileUrl) {
			window.open(fileUrl, "_blank");
		}
	};

	return (
		<div className="">
			{(showTabs === 4 || showTabs === 6) && (
				<div className="ml-4 mr-4">
					<h6 className="mt-5 font-plusJakartaSans flex items-center text-center gap-[5px] font-bold text-[#000000] text-[15px]">
						<img
							className="w-5 h-5"
							src={medias.images.Exam.Syllabus}
							alt="Privilege"
						/>
						<span className="font-plusJakartaSans font-bold mt-[1px]">
							Study material
						</span>
					</h6>
					<img
						className="w-40 text-[#000000] pt-1"
						src={medias.images.Profile.line1}
						alt="lin1"
					/>
				</div>
			)}
			{showTabs === 7 && (
				<div className="ml-4 mr-4 relative">
					<h6 className="mt-5 font-plusJakartaSans flex items-center text-center gap-[5px] font-extrabold text-[#000000] text-[16px]">
						Recommended Books
					</h6>
					<MdOutlineKeyboardArrowRight
						className="absolute right-[-5%] top-32 transform -translate-x-1/2 -translate-y-1/2 bg-[#ffffff] w-8 h-8 rounded-full p-1 border border-[#C2C2C2] cursor-pointer"
						onClick={handleScroll}
					/>
				</div>
			)}
			<div
				className="overflow-x-auto scrollable-content"
				ref={scrollContainerRef}
			>
				<div className="flex space-x-4 pb-4">
					{data?.map((item, index) => (
						<div
							key={index}
							className="flex-shrink-0 pt-2 pb-4 pl-2 pr-2 w-[180px] ml-4 mr-4 mt-4 rounded-xl shadow-md dark:bg-gray-800 dark:border-gray-700"
						>
							<div className="">
								<img
									className={`w-28 h-28 mt-2 ml-auto mr-auto p-5 bg-[#FBC343] bg-opacity-20 rounded-full ${
										showTabs === 4 || showTabs === 6 ? "cursor-pointer" : ""
									}`}
									src={medias.images.Exam.Papers}
									alt="BackgroundImage"
									onClick={() =>
										(showTabs === 4 || showTabs === 6) &&
										handleImageClick(item?.file)
									}
								/>
							</div>
							<div className="pb-4 pl-2 pr-1 pt-4">
								<p className="font-plusJakartaSans flex items-center justify-center text-center font-bold text-[13px] text-[#000000]">
									{showTabs === 7
										? item?.subject_name
										: showTabs === 6 || showTabs === 4
										? item?.subject_name
										: "This is the title of description"}
								</p>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
}

export default EntranceStudyMaterial;
