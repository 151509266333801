/* eslint-disable jsx-a11y/img-redundant-alt */
import { Carousel } from "react-responsive-carousel";
import { medias } from "../../../config/Resources";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { GoDotFill } from "react-icons/go";
import EditorPickCards from "./EditorPickCards";
import { useNavigate } from "react-router-dom";

function EditorsPicksCarousels({ blogsData }) {
	const formatDate = (dateString) => {
		const options = { day: "numeric", month: "long", year: "numeric" };
		const date = new Date(dateString);
		return new Intl.DateTimeFormat("en-GB", options).format(date);
	};
	const navigate = useNavigate();
	return (
		<div className=" ml-14 mt-5 mr-[5rem] pb-[30px]">
			<div className="w-[100%] flex gap-8">
				<div className="w-[50%]">
					<Carousel
						showArrows={false}
						showThumbs={false}
						showStatus={false}
						showIndicators={true}
						autoPlay
						infiniteLoop
						className="w-[100%] rounded-xl overflow-hidden"
					>
						{blogsData?.editorsPicks?.map((data, index) => {
							return (
								<div
									onClick={() => navigate(`/blog-details/${data?.id}`)}
									key={index}
									className="relative overflow-hidden rounded-xl"
								>
									<img
										src={data?.cover_image}
										alt={`carousel-image`}
										className="object-cover w-full h-[400px]"
									/>
									<div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 rounded-xl"></div>
									<div className="absolute bottom-0 left-0 w-full p-4 bg-gradient-to-t from-black via-transparent to-transparent text-white rounded-b-xl">
										<div className="flex items-center text-center mt-2 gap-2">
											<div>
												<img
													className="rounded-full pictures"
													src={data?.author_image}
													alt="Woman looking front"
												/>
											</div>
											<p className="font-plusJakartaSans text-[14px] font-medium">
												{data?.author_name}
											</p>
											<GoDotFill className="text-white text-opacity-40" />
											<p className="font-plusJakartaSans text-[14px] font-medium">
												{data?.time_taken}
											</p>
											<GoDotFill className="text-white text-opacity-40" />
											<p className="font-plusJakartaSans text-[14px] font-medium">
												{formatDate(data?.created_at)}
											</p>
										</div>
										<p className="font-plusJakartaSans font-semibold mt-2 line-clamp-2 text-start text-[23.5px]">
											{data?.title}
										</p>
										<div className="flex pb-[15px] mt-2 text-center gap-12">
											<div className="flex pb-2 gap-2 text-center">
												<img
													className="w-4 h-4"
													src={medias.images.Blogs.HumanEye}
													alt="HumanEye"
												/>
												<p className="font-plusJakartaSans font-semibold text-[14.5px] text-white text-opacity-80">
													12K
												</p>
											</div>
											<div className="flex gap-2 text-center">
												<img
													className="w-4 h-4"
													src={medias.images.Blogs.Chat1}
													alt="HumanEye"
												/>
												<p className="font-plusJakartaSans text-[14.5px] font-semibold">
													333
												</p>
											</div>
											<div className="flex pb-2 gap-2 text-center">
												<img
													className="w-4 h-4"
													src={medias.images.Blogs.Share}
													alt="HumanEye"
												/>
												<p className="font-plusJakartaSans text-[14.5px] font-semibold">
													1K
												</p>
											</div>
										</div>
									</div>
								</div>
							);
						})}
					</Carousel>
				</div>
				<div className="h-[400px] w-[50%] overflow-y-auto scrollable-content">
					<EditorPickCards blogsData={blogsData} />
				</div>
			</div>
		</div>
	);
}

export default EditorsPicksCarousels;
