import { medias } from "../../../config/Resources";
import EditorsPicksCarousels from "./EditorsPickCarousels";
import { AiOutlineRightCircle } from "react-icons/ai";
import { TfiArrowCircleLeft } from "react-icons/tfi";

function EditorsPicks({ blogsData }) {
	return (
		<>
			<div className="flex items-center border-b text-center justify-between mt-14 ml-5 mr-5 pb-3 pl-7 pr-9">
				<h1 className="font-plusJakartaSans font-bold text-[23px] flex items-center text-center gap-[5px]">
					<img
						className="w-11 h-11"
						src={medias.images.Blogs.Editors}
						alt="Fire"
					/>
					Editor’s Pick
				</h1>
				{/* <span className="font-plusJakartaSans pt-[11px] pb-[11px] pl-[21px] rounded-full pr-[21px] font-semibold text-[13.5px] text-[#C2C2C2] flex items-center text-center gap-2">
					<AiOutlineRightCircle className="w-8 h-8" />
					<TfiArrowCircleLeft className="w-8 h-8" />
				</span> */}
			</div>
			<EditorsPicksCarousels blogsData={blogsData} />
		</>
	);
}

export default EditorsPicks;
