/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import {
	CompareRounded,
	EastRounded,
	LocationOnRounded,
	PushPin,
	StarRounded,
	Verified,
} from "@mui/icons-material";
import ExploreOtherSection from "../../Pages/Profile/SessionSchedule/ExploreOtherSection";
import { medias } from "../../config/Resources";
import { Button } from "@mui/material";
import { mediaResources } from "../../config/Constants";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Requires a loader
import { useNavigate } from "react-router-dom";

const CollegeCardListView = ({ data }) => {
	const navigate = useNavigate();

	return (
		<>
			{data?.map((item, index) => {
				const {
					id,
					image,
					clg_logo,
					clg_name,
					clg_address,
					founded,
					approved_by,
					total_fee,
				} = item;

				return (
					<React.Fragment key={index}>
						<div
							onClick={() => navigate(`/college-details/${id}`)}
							className="relative cursor-pointer mt-8 overflow-hidden flex bg-clip-border rounded-xl bg-white text-gray-700 shadow-md w-full max-w-full flex-row"
						>
							<div className="relative m-0 p-3 overflow-hidden text-gray-700 bg-white rounded-r-none bg-clip-border rounded-xl shrink-0">
								<Carousel
									showArrows={false}
									showThumbs={false}
									showStatus={false}
									showIndicators={false}
									autoPlay
									infiniteLoop
									className="w-48 h-48 sm:w-40 sm:h-44 md:w-44 md:h-48 lg:w-48 lg:h-52 xl:w-48 xl:h-48 rounded-xl"
								>
									<img
										src={image || "N/A"}
										alt={`carousel-image-${index}`}
										className="object-cover w-48 h-48 sm:w-40 sm:h-44 md:w-44 md:h-48 lg:w-48 lg:h-52 xl:w-48 xl:h-48 rounded-xl"
									/>
								</Carousel>
							</div>

							<div>
								<div className="flex flex-col mt-1 sm:flex-row">
									<div className="bg-white flex shadow-custom1 h-[100%] pt-2 pb-2 pl-2 pr-2 mt-3 rounded-lg">
										<img
											className="w-10 h-10 rounded-full"
											src={clg_logo || "N/A"}
											alt="logo"
										/>
									</div>
									<div className="pl-3 pr-3 pb-2 pt-4 sm:pt-3 w-full sm:w-[90%]">
										<div className="flex flex-col sm:flex-row justify-between mr-20 ">
											<div>
												<p className="font-plusJakartaSans text-[#000000] text-[17px] font-bold">
													{clg_name || "N/A"}
													<Verified className="!text-[rgba(39,113,231,1)] 3xl:text-[18px]" />
												</p>
											</div>
											<div className="flex mt-2 sm:mt-0">
												<div className="bg-white shadow-custom1 rounded-full p-2">
													<img
														className="w-4 h-4"
														src={medias.images.Profile.Share}
														alt="share"
													/>
												</div>
												<div className="bg-white ml-5 shadow-custom1 p-2 rounded-full">
													<img
														className="w-4 h-4"
														src={medias.images.Profile.Heart}
														alt="heart"
													/>
												</div>
											</div>
										</div>
										<div className="flex flex-col sm:flex-row sm:items-center mt-[-3px]">
											<p className="text-[13px] font-plusJakartaSans font-medium flex items-center">
												<LocationOnRounded className="!text-[#FF5A5A] 3xl:text-[18px] stroke-black stroke-[1.5px]" />
												{clg_address || "N/A"}
											</p>
											<p className="bg-[#F3F3F3] sm:mt-0 sm:ml-2 p-2 3xl:p-1 text-[13px] w-fit py-[6px] pl-[9px] pr-[13px] rounded-[4px] font-semibold font-plusJakartaSans flex items-center">
												<StarRounded className="!text-[#FFC000] 3xl:text-[18px] w-4 h-4" />
												4.5
											</p>
											<p className="text-[#239403] font-plusJakartaSans font-semibold sm:ml-2 underline text-[13px] sm:mt-0">
												525 reviews
											</p>
										</div>
									</div>
								</div>

								<div className="flex mt-[3px] 3xl:-top-[20px] miniDesk:flex-col miniDesk:!gap-[4px] ml-[11px] 3xl:gap-[12px] gap-[20px]">
									<p className="font-normal font-plusJakartaSans text-[13px] desktop:!text-[12px] 3xl:text-[12px] items-start flex gap-[6px]">
										<PushPin className="rotate-[45deg] h-4 w-4 3xl:text-[14px]" />
										Estd. {founded || "N/A"}
									</p>
									<p className="flex items-start text-[13px] desktop:!text-[12px] font-normal font-plusJakartaSans ml-2 3xl:text-[12px] gap-[6px]">
										<img
											src={medias.images.HomeMain.DocVerified}
											className="3xl:w-[14px] h-4 w-4"
											alt="DocVerified"
										/>
										Approved by: {approved_by || "N/A"}
									</p>
									<p className="flex text-[12px] font-normal font-plusJakartaSans ml-2 gap-[6px] items-start">
										<img
											src={medias.images.HomeMain.ranking}
											className="3xl:w-[14px] h-4 w-4"
											alt="ranking"
										/>
										NIRF Ranking: 2nd
									</p>
								</div>
								<div className="flex relative w-[86%] mt-[13px] border-[#707070] border-opacity-[38%] overflow-auto gap-[8px] no-scrollbar">
									{[
										"Admissions 2023",
										"Placements",
										"Courses & Fees",
										"Reviews",
										"Scholarship",
										"Q&A",
										"Overview",
										"Notice board",
										"Gallery",
										"Alumni Speak",
									].map((item, idx) => (
										<p
											key={idx}
											className="min-w-fit font-medium font-plusJakartaSans desktop:!text-[10px] 3xl:py-[3px] 3xl:px-[12px] 3xl:text-[13px] px-[20px] border border-[#6C6C6C] rounded-full text-[13px] py-[5px]"
										>
											{item}
										</p>
									))}
								</div>
								<div className="mt-[10px] flex item-center 3xl:-top-[20px] ml-2 3xl:mx-[0] mb-[13px]">
									<div>
										<p className="text-[10px] mt-[1px] font-plusJakartaSans font-semibold text-black 3xl:ml-2 opacity-[62%]">
											Avg Fees
										</p>
										<p className="text-[17px] 3xl:text-[12px] desktop:!text-[10px] 3xl:ml-2 flex gap-[5px] font-medium">
											<span className="text-[22px] font-plusJakartaSans text-[#239403] font-bold">
												₹ {total_fee || "N/A"}
											</span>
											<span className="text-[15px] font-medium mt-[8px] font-plusJakartaSans">
												1st yr Fees
											</span>
										</p>
									</div>
									<Button
										variant="outlined"
										className="border mt-2 font-plusJakartaSans ml-16 py-[10px] !h-full flex gap-[6.2px] text-[13px] font-medium 3xl:py-[10px] 3xl:text-[13px] 3xl:h-fit 3xl:px-[22px] capitalize !text-black px-[22px] border-[#707070] rounded-[9px]"
									>
										<CompareRounded className="!text-black h-4 w-4 3xl:text-[18px]" />
										Compare
									</Button>
									<Button
										variant="outlined"
										className="border mt-2 font-plusJakartaSans ml-4 py-[10px] !h-full flex gap-[6.2px] text-[13px] font-medium 3xl:py-[10px] 3xl:text-[13px] 3xl:h-fit 3xl:px-[22px] capitalize !text-[#239403] px-[22px] border-[#239403] rounded-[9px]"
									>
										<img
											src={`${mediaResources}/Download.svg`}
											className="!text-black w-4 h-4 3xl:w-[14px]"
										/>
										Brochure
									</Button>
									<Button
										variant="contained"
										className="border mt-2 font-plusJakartaSans ml-5 py-[10px] !h-full flex gap-[6.2px] miniDesk:!px-[2px] text-[13px] font-medium capitalize !text-[#fff] !bg-[#F37400] 3xl:py-[10px] 3xl:px-[22px] 3xl:text-[13px] 3xl:h-fit !shadow-none px-[22px] border-[#239403] rounded-[9px]"
									>
										Apply Now{" "}
										<EastRounded className="3xl:text-[14px] w-4 h-4 miniDesk:hidden" />
									</Button>
								</div>
							</div>
						</div>
						{index === 4 && <ExploreOtherSection />}
					</React.Fragment>
				);
			})}
		</>
	);
};

export default CollegeCardListView;
