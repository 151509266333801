/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import Text from "../../Components/Text";
import { Link, useNavigate } from "react-router-dom";
import TextField1 from "../../Components/Textfield/TextField1";
import { medias } from "../../config/Resources";
import { Button, InputAdornment } from "@mui/material";
import Image from "../../Components/Image";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import SocialAuth from "../SocialAuth";
import { useFormik } from "formik";
import { LoginSchema } from "../../config/Schema/LoginSignup";
import axiosInstance from "../../config/Axios/axiosInstance";
import { API_URLS } from "../../config/API_URLS";
import { toast } from "react-toastify";
import { useUserInfoStore } from "../../store/userinfo";

const Login = () => {
  const navigate = useNavigate();
  const { setUserInfo, setIsLoggedin }: any = useUserInfoStore(
    (state) => state
  );
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: LoginSchema,
    validateOnMount: false,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: ({ email, password }) => {
      const body = {
        email,
        password,
      };
      axiosInstance
        .post(API_URLS.login, body)
        .then((res: any) => {
          if (res?.result?.token) {
            localStorage.setItem("token-student", res?.result?.token);
            toast("User LoggedIn successfully", {
              type: "success",
            });
            setIsLoggedin(true);
            navigate(
              window.location.origin === "http://localhost:3000" ||
                window.location.origin === "https://techugoapps.com" ||
                window.location.origin === "https://www.techugoapps.com"
                ? "/sygnific_dev"
                : "/"
            );
            formik.resetForm();
          } else {
            toast(
              res?.result?.statusMessage ||
                res?.statusMessage ||
                "Something Went Wrong",
              {
                type: "warning",
              }
            );
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
  });

  const [VisibilityPassword, setVisibilityPassword] = useState(false);
  return (
    <div>
      <div className="flex justify-between font-bold">
        <Text className="text-[24px] !font-[PlusJakartaSans-Bold] desktop:text-[18px]">
          Login Here
        </Text>
        <Link
          to="/login-signup/register"
          className="text-[24px] !font-[PlusJakartaSans-Bold] desktop:text-[18px] text-[#2771E7] "
        >
          Register Free
        </Link>
      </div>
      <form
        onSubmit={formik.handleSubmit}
        className="w-full flex flex-col gap-[25px] desktop:mt-[30px] mt-[58px]"
      >
        <div>
          <TextField1
            className="!w-full "
            id="email"
            name="email"
            onChange={formik.handleChange}
            value={formik.values.email}
            onBlur={formik.handleBlur}
            placeholder="Email ID"
            type="email"
            startAdornment={
              <InputAdornment position="start">
                <Image
                  className="mr-[20px]  miniDesk:w-[14px]"
                  src={medias.images.inputIcons.EmailInputIcon}
                />
              </InputAdornment>
            }
          />
          {formik.touched.email && (
            <Text className="text-[#dc143c]">{formik.errors.email}</Text>
          )}
        </div>
        <div>
          <TextField1
            className="!w-full"
            id="passwod"
            name="password"
            onChange={formik.handleChange}
            value={formik.values.password}
            onBlur={formik.handleBlur}
            placeholder="Password"
            type={VisibilityPassword ? "text" : "password"}
            startAdornment={
              <InputAdornment position="start">
                <Image
                  className="mr-[20px]  miniDesk:w-[14px]"
                  src={medias.images.inputIcons.PasswordInputIcon}
                />
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment position="end">
                {VisibilityPassword ? (
                  <Visibility
                    className="cursor-pointer"
                    onClick={() => setVisibilityPassword(false)}
                  />
                ) : (
                  <VisibilityOff
                    className="cursor-pointer"
                    onClick={() => setVisibilityPassword(true)}
                  />
                )}
              </InputAdornment>
            }
          />
          {formik.touched.password && (
            <Text className="text-[#dc143c]">{formik.errors.password}</Text>
          )}
        </div>
        <Button
          type="submit"
          className="!text-[white]  miniDesk:!py-[10px] !capitalize miniDesk:!text-[16px] !py-[18px] w-full !rounded-[13px] !text-[25px] !font-bold !bg-[#239403]"
        >
          Login
        </Button>
      </form>
      <div className="relative flex items-center mt-[20px] justify-center w-full text-center">
        <div className="h-[1px] absolute z-10 top-[50%] w-full bg-[#70707041]"></div>
        <Text className="relative z-20 px-2 text-[#00000048] font-semibold bg-[white] w-fit">
          OR
        </Text>
      </div>
      <div>
        <SocialAuth />
      </div>
    </div>
  );
};

export default Login;
