import { medias } from "../../../../config/Resources";

function InternshipOffered({ placement }) {
	return (
		<div className="ml-4 mr-4 mt-4">
			<div className="flex items-center ml-3 mr-3 text-center gap-1">
				<img
					className="bg-[#E8AE4D] bg-opacity-[15%] p-[6px] rounded-md w-9 h-9"
					src={medias.images.College.Brief}
					alt="Placements"
				/>
				<h6 className="font-plusJakartaSans mt-[5.5px] font-bold text-[15px]">
					Internships Offered
				</h6>
			</div>
			<p
				dangerouslySetInnerHTML={{
					__html: placement?.internship_offered,
				}}
				className="font-plusJakartaSans text-[10.5px] mt-[3px] ml-3 mr-3 text-[#000000] text-opacity-55"
			></p>
		</div>
	);
}

export default InternshipOffered;
