import { FaStar } from "react-icons/fa";
import ExploreCardChips from "./ExploreCardChips";
import Line from "./Line";
import { Button } from "@mui/material";
import { EastRounded } from "@mui/icons-material";
import ExploreOtherSection from "./ExploreOtherSection";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ScheduleIcon from "@mui/icons-material/Schedule";

export function ExploreCard({
  BookedMentorList,
  isAdCard,
  cardIndex,
  setModal,
  modal,
  mentor,
  setMentorData,
  isMentorPage,
  isSlider,
  isRequested,
}) {
  if (isAdCard) {
    // Render ad card content
    return <ExploreOtherSection />;
  }

  // const navigate = useNavigate();

  return (
    <div
      className={`mt-6  ${
        isMentorPage
          ? "max-w-[470px] min-w-[470px] bg-white rounded-lg "
          : isSlider
          ? "max-w-[80%] rounded-lg  "
          : "min-w-[48%] rounded-lg  "
      }    shadow-[0px_5px_16px_rgba(0,0,0,0.04)] border-gray-200 border-solid border-[0.4px]`}
    >
      <div className="flex p-2">
        <img
          src={
            mentor?.profileImage
              ? mentor?.profileImage
              : "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&amp;ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTV8fGh1bWFufGVufDB8fDB8fA%3D%3D&amp;auto=format&amp;fit=crop&amp;w=800&amp;q=60"
          }
          // https://png.pngitem.com/pimgs/s/35-350426_profile-icon-png-default-profile-picture-png-transparent.png
          alt={mentor?.firstName}
          className="w-[140px] h-[142px] rounded-md object-cover border-[0.1px] border-gray-100 border-solid"
        />
        <div className="pl-2">
          <h1 className="text-lg font-semibold flex capitalize text-nowrap text-ellipsis">
            Dr. {mentor?.firstName} {mentor?.middleName} {mentor?.lastName}
            <span className="text-sm h-6 w-6"></span>
          </h1>

          <h6 className="mt-1 font-medium text-[12px]">Designation</h6>
          <p className="mt-1 text-sm font-semibold">
            {mentor?.current_job || "N/A"}
          </p>
          {false ? (
            <div className="mt-4 flex flex-wrap text-center">
              <div className="flex justify-center items-center text-center gap-2 bg-[#F2F2F2] rounded-[6px] p-[6px] ">
                <FaStar className="text-[#F0D41B] text-[16px]" />
                <span className="text-[#000000] font-bold">4.5</span>
              </div>
              <h5 className="text-[#239403] underline underline-offset-4 m-2">
                35k+ Reviews
              </h5>
            </div>
          ) : isRequested ? (
            <div className="flex  mt-3 justify-between w-full items-center bg-opacity-25  text-center gap-2 bg-gradient-to-r from-[#ffffff] to-[#23940341] rounded-[8px] px-[10px] py-[3px] ">
              <div className="flex items-center justify-center">
                <CalendarMonthIcon className="text-black   text-[16px]" />
                <span className="text-[#000000] font-bold text-[15px]">
                  {moment(mentor?.schedule_date, "YYYY-MM-DD")?.format(
                    "DD MMM YY"
                  )}
                </span>
              </div>
              <div className="flex items-center justify-center">
                <ScheduleIcon className="text-black ck text-[16px]" />
                <span className="text-[#000000] font-bold text-[14px]">
                  {moment(mentor?.start_time, "HH:mm:ss").format("h:mmA")}-
                  {moment(mentor?.end_time, "HH:mm:ss").format("h:mmA")}
                </span>
              </div>
            </div>
          ) : (
            <>
              {" "}
              <div className="mt-4 flex flex-wrap text-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30.236"
                  height="30.236"
                  viewBox="0 0 34.236 34.236"
                >
                  <g id="XMLID_59_" transform="translate(0 0)">
                    <path
                      id="XMLID_1069_"
                      d="M32.468,192.878v10.827a2.878,2.878,0,0,1-2.878,2.878H17.89l-5.012,4.28v-4.28A2.878,2.878,0,0,1,10,203.705V192.878A2.878,2.878,0,0,1,12.878,190H29.59A2.878,2.878,0,0,1,32.468,192.878Z"
                      transform="translate(-9.331 -177.295)"
                      fill="#d5ea79"
                    />
                    <path
                      id="XMLID_1224_"
                      d="M166,12.878V23.705a2.878,2.878,0,0,0,2.878,2.878h11.7l5.012,4.28v-4.28a2.878,2.878,0,0,0,2.878-2.878V12.878A2.878,2.878,0,0,0,185.59,10H168.878A2.878,2.878,0,0,0,166,12.878Z"
                      transform="translate(-154.9 -9.331)"
                      fill="#ff9171"
                    />
                    <g id="XMLID_969_" transform="translate(0 0)">
                      <path
                        id="XMLID_1003_"
                        d="M257.669,53.907a.669.669,0,0,0,0,1.337h2.2a7.334,7.334,0,0,0,1.4,4.293A6,6,0,0,1,257.82,61.2a.669.669,0,0,0,.082,1.332.676.676,0,0,0,.084-.005,7.336,7.336,0,0,0,4.163-1.982,7.336,7.336,0,0,0,4.163,1.982.676.676,0,0,0,.084.005.669.669,0,0,0,.082-1.332,6,6,0,0,1-3.449-1.664,7.335,7.335,0,0,0,1.4-4.293h2.2a.669.669,0,0,0,0-1.337h-3.811V52.169a.669.669,0,1,0-1.337,0v1.739Zm4.48,4.547a6,6,0,0,1-.944-3.21h1.888A5.994,5.994,0,0,1,262.149,58.454Z"
                        transform="translate(-239.815 -48.056)"
                      />
                      <path
                        id="XMLID_1023_"
                        d="M30.69,0H25.343a.669.669,0,1,0,0,1.337H30.69A2.212,2.212,0,0,1,32.9,3.547V14.374a2.212,2.212,0,0,1-2.209,2.209.669.669,0,0,0-.669.669v2.829l-3.91-3.338a.668.668,0,0,0-.434-.16h-11.7a2.212,2.212,0,0,1-2.209-2.209V3.547a2.212,2.212,0,0,1,2.209-2.209h5.347a.669.669,0,1,0,0-1.337H13.978a3.551,3.551,0,0,0-3.547,3.547v8.49H3.547A3.551,3.551,0,0,0,0,15.583V26.41a3.553,3.553,0,0,0,2.878,3.483v3.674a.669.669,0,0,0,1.1.509l4.825-4.119H20.258a3.551,3.551,0,0,0,3.547-3.547v-8.49h1.626l4.825,4.119a.669.669,0,0,0,1.1-.509V17.857a3.553,3.553,0,0,0,2.878-3.483V3.547A3.551,3.551,0,0,0,30.69,0ZM11.913,18.69l1.325,3.51H10.577Zm10.554,7.72a2.212,2.212,0,0,1-2.209,2.209H8.559a.668.668,0,0,0-.434.16l-3.91,3.338V29.288a.669.669,0,0,0-.669-.669A2.212,2.212,0,0,1,1.337,26.41V15.583a2.212,2.212,0,0,1,2.209-2.209h6.885v1a3.531,3.531,0,0,0,.878,2.333.844.844,0,0,0-.173.266l-.006.015L8.268,24.5a.669.669,0,1,0,1.25.476l.549-1.441h3.676l.543,1.439a.669.669,0,1,0,1.251-.472L13,17.782a3.535,3.535,0,0,0,.978.138h8.49Z"
                        transform="translate(0 0)"
                      />
                      <path
                        id="XMLID_1025_"
                        d="M324.669,1.337a.669.669,0,0,0,.669-.669.669.669,0,1,0-1.141.473A.674.674,0,0,0,324.669,1.337Z"
                        transform="translate(-302.335 0)"
                      />
                    </g>
                  </g>
                </svg>

                <p>English, Hindi, Punjabi</p>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="flex justify-between px-2">
        <ExploreCardChips
          name="Experience"
          img={
            <>
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="19.304"
                height="17.59"
                viewBox="0 0 22.304 19.59"
              >
                <g
                  id="Group_411898"
                  data-name="Group 411898"
                  transform="translate(0 0)"
                >
                  <g
                    id="Group_411897"
                    data-name="Group 411897"
                    transform="translate(0.348 0.346)"
                  >
                    <path
                      id="Path_266958"
                      data-name="Path 266958"
                      d="M29.607,118.988v4.573a1.356,1.356,0,0,1-.5,1.052c-1.08.879-4.053,2.678-10.3,2.678s-9.223-1.8-10.3-2.678a1.356,1.356,0,0,1-.5-1.052v-4.573a1.356,1.356,0,0,1,1.356-1.356H28.251A1.356,1.356,0,0,1,29.607,118.988Z"
                      transform="translate(-8 -114.21)"
                      fill="#9c8f9a"
                    />
                    <path
                      id="Path_266959"
                      data-name="Path 266959"
                      d="M29.607,118.988v4.573a1.356,1.356,0,0,1-.5,1.052c-1.08.879-4.053,2.678-10.3,2.678s-9.223-1.8-10.3-2.678a1.356,1.356,0,0,1-.5-1.052v-4.573a1.356,1.356,0,0,1,1.356-1.356H28.251A1.356,1.356,0,0,1,29.607,118.988Z"
                      transform="translate(-8 -114.21)"
                      fill="#9c8f9a"
                    />
                    <path
                      id="Path_266960"
                      data-name="Path 266960"
                      d="M29.607,118.988v4.573a1.356,1.356,0,0,1-.5,1.052c-1.08.879-4.053,2.678-10.3,2.678s-9.223-1.8-10.3-2.678a1.356,1.356,0,0,1-.5-1.052v-4.573a1.356,1.356,0,0,1,1.356-1.356H28.251A1.356,1.356,0,0,1,29.607,118.988Z"
                      transform="translate(-8 -114.21)"
                      fill="#407093"
                    />
                    <path
                      id="Path_266961"
                      data-name="Path 266961"
                      d="M29.106,236.783c-1.08.879-4.053,2.678-10.3,2.678s-9.223-1.8-10.3-2.678a1.356,1.356,0,0,1-.5-1.052v.784a1.356,1.356,0,0,0,.5,1.052c1.08.879,4.053,2.678,10.3,2.678s9.223-1.8,10.3-2.678a1.356,1.356,0,0,0,.5-1.052v-.784A1.356,1.356,0,0,1,29.106,236.783Z"
                      transform="translate(-8 -227.165)"
                      fill="#365e7d"
                    />
                    <path
                      id="Path_266962"
                      data-name="Path 266962"
                      d="M31.077,281.595c-6.14,0-9.118-1.736-10.244-2.631v7.093a1.356,1.356,0,0,0,1.356,1.356H39.965a1.356,1.356,0,0,0,1.356-1.356v-7.093C40.195,279.858,37.217,281.595,31.077,281.595Z"
                      transform="translate(-20.274 -268.514)"
                      fill="#365e7d"
                    />
                    <path
                      id="Path_266963"
                      data-name="Path 266963"
                      d="M20.833,278.964v.784c1.127.895,4.1,2.631,10.244,2.631s9.118-1.736,10.244-2.631v-.784c-1.127.895-4.1,2.631-10.244,2.631S21.96,279.858,20.833,278.964Z"
                      transform="translate(-20.274 -268.514)"
                      fill="#2b4d66"
                    />
                    <path
                      id="Path_266964"
                      data-name="Path 266964"
                      d="M228.107,306.66a1.27,1.27,0,0,1-1.27-1.27v-1.554a.452.452,0,0,1,.452-.452h1.636a.452.452,0,0,1,.452.452v1.554A1.27,1.27,0,0,1,228.107,306.66Z"
                      transform="translate(-217.304 -291.87)"
                      fill="#ffe27a"
                    />
                    <path
                      id="Path_266965"
                      data-name="Path 266965"
                      d="M228.107,332.71a1.27,1.27,0,0,1-1.27-1.27v.784a1.27,1.27,0,0,0,2.541,0v-.784A1.27,1.27,0,0,1,228.107,332.71Z"
                      transform="translate(-217.304 -318.704)"
                      fill="#f9cf58"
                    />
                    <path
                      id="Path_266966"
                      data-name="Path 266966"
                      d="M172.687,42.507v-1.5a5.355,5.355,0,0,1,4.542,0v1.5h1.42v-1.9a.71.71,0,0,0-.28-.565,4.254,4.254,0,0,0-.938-.5,6.908,6.908,0,0,0-4.946,0,4.251,4.251,0,0,0-.938.5.71.71,0,0,0-.28.565v1.9h1.42Z"
                      transform="translate(-164.155 -39.085)"
                      fill="#e8834d"
                    />
                    <g
                      id="Group_411896"
                      data-name="Group 411896"
                      transform="translate(7.112 2.638)"
                    >
                      <path
                        id="Path_266967"
                        data-name="Path 266967"
                        d="M171.267,99.633h1.42v.784h-1.42Z"
                        transform="translate(-171.267 -99.633)"
                        fill="#d66e41"
                      />
                      <path
                        id="Path_266968"
                        data-name="Path 266968"
                        d="M308.124,99.633h1.42v.784h-1.42Z"
                        transform="translate(-302.162 -99.633)"
                        fill="#d66e41"
                      />
                    </g>
                  </g>
                  <path
                    id="Path_266969"
                    data-name="Path 266969"
                    d="M20.618,34.574h-5.44V33a1.045,1.045,0,0,0-.409-.826,4.545,4.545,0,0,0-1.017-.545,6.4,6.4,0,0,0-.808-.262.327.327,0,1,0-.161.635,5.749,5.749,0,0,1,.725.235,3.974,3.974,0,0,1,.864.458.386.386,0,0,1,.151.305v1.575h-.768V33.4a.327.327,0,0,0-.179-.292,5.375,5.375,0,0,0-2.424-.542,5.374,5.374,0,0,0-2.424.542.327.327,0,0,0-.179.292v1.172H7.781V33a.386.386,0,0,1,.151-.306,3.971,3.971,0,0,1,.864-.458,6.377,6.377,0,0,1,2.356-.435c.139,0,.28,0,.419.013a.327.327,0,1,0,.04-.654c-.152-.009-.307-.014-.459-.014a7.038,7.038,0,0,0-2.6.482,4.545,4.545,0,0,0-1.017.545A1.045,1.045,0,0,0,7.126,33v1.575H1.686A1.688,1.688,0,0,0,0,36.26v4.582A1.679,1.679,0,0,0,.56,42.1V49.05a1.688,1.688,0,0,0,1.686,1.686H15.18a.327.327,0,1,0,0-.655H2.246A1.033,1.033,0,0,1,1.215,49.05V42.583A10.952,10.952,0,0,0,3.181,43.6a.327.327,0,1,0,.244-.608,9.215,9.215,0,0,1-2.39-1.348,1.028,1.028,0,0,1-.38-.8V36.26a1.033,1.033,0,0,1,1.031-1.031H20.618a1.033,1.033,0,0,1,1.031,1.031v4.582a1.028,1.028,0,0,1-.38.8,9.61,9.61,0,0,1-2.784,1.5,19.9,19.9,0,0,1-5.733,1.069v-.747a.781.781,0,0,0-.78-.78h-1.64a.781.781,0,0,0-.78.78v.748a21.331,21.331,0,0,1-4.778-.758.327.327,0,0,0-.182.629,22.023,22.023,0,0,0,4.96.784v.154a1.6,1.6,0,0,0,3.2,0v-.154c4.427-.231,6.989-1.384,8.337-2.281V49.05a1.033,1.033,0,0,1-1.031,1.031H16.49a.327.327,0,0,0,0,.655h3.568a1.688,1.688,0,0,0,1.686-1.686V42.1a1.679,1.679,0,0,0,.56-1.255V36.26a1.688,1.688,0,0,0-1.686-1.686ZM9.2,33.61a5.109,5.109,0,0,1,3.9,0v.965H9.2V33.61ZM12.1,45.019a.945.945,0,0,1-1.891,0V43.462a.126.126,0,0,1,.126-.126h1.64a.126.126,0,0,1,.126.126Z"
                    transform="translate(0 -31.146)"
                  />
                </g>
              </svg>
            </>
          }
          data={`${mentor?.totalExperience || 0}  Years`}
        />
        {isRequested ? (
          <ExploreCardChips
            name="Request"
            img={
              <>
                {" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19.304"
                  height="17.59"
                  viewBox="0 0 22.304 19.59"
                >
                  <g
                    id="Group_411898"
                    data-name="Group 411898"
                    transform="translate(0 0)"
                  >
                    <g
                      id="Group_411897"
                      data-name="Group 411897"
                      transform="translate(0.348 0.346)"
                    >
                      <path
                        id="Path_266958"
                        data-name="Path 266958"
                        d="M29.607,118.988v4.573a1.356,1.356,0,0,1-.5,1.052c-1.08.879-4.053,2.678-10.3,2.678s-9.223-1.8-10.3-2.678a1.356,1.356,0,0,1-.5-1.052v-4.573a1.356,1.356,0,0,1,1.356-1.356H28.251A1.356,1.356,0,0,1,29.607,118.988Z"
                        transform="translate(-8 -114.21)"
                        fill="#9c8f9a"
                      />
                      <path
                        id="Path_266959"
                        data-name="Path 266959"
                        d="M29.607,118.988v4.573a1.356,1.356,0,0,1-.5,1.052c-1.08.879-4.053,2.678-10.3,2.678s-9.223-1.8-10.3-2.678a1.356,1.356,0,0,1-.5-1.052v-4.573a1.356,1.356,0,0,1,1.356-1.356H28.251A1.356,1.356,0,0,1,29.607,118.988Z"
                        transform="translate(-8 -114.21)"
                        fill="#9c8f9a"
                      />
                      <path
                        id="Path_266960"
                        data-name="Path 266960"
                        d="M29.607,118.988v4.573a1.356,1.356,0,0,1-.5,1.052c-1.08.879-4.053,2.678-10.3,2.678s-9.223-1.8-10.3-2.678a1.356,1.356,0,0,1-.5-1.052v-4.573a1.356,1.356,0,0,1,1.356-1.356H28.251A1.356,1.356,0,0,1,29.607,118.988Z"
                        transform="translate(-8 -114.21)"
                        fill="#407093"
                      />
                      <path
                        id="Path_266961"
                        data-name="Path 266961"
                        d="M29.106,236.783c-1.08.879-4.053,2.678-10.3,2.678s-9.223-1.8-10.3-2.678a1.356,1.356,0,0,1-.5-1.052v.784a1.356,1.356,0,0,0,.5,1.052c1.08.879,4.053,2.678,10.3,2.678s9.223-1.8,10.3-2.678a1.356,1.356,0,0,0,.5-1.052v-.784A1.356,1.356,0,0,1,29.106,236.783Z"
                        transform="translate(-8 -227.165)"
                        fill="#365e7d"
                      />
                      <path
                        id="Path_266962"
                        data-name="Path 266962"
                        d="M31.077,281.595c-6.14,0-9.118-1.736-10.244-2.631v7.093a1.356,1.356,0,0,0,1.356,1.356H39.965a1.356,1.356,0,0,0,1.356-1.356v-7.093C40.195,279.858,37.217,281.595,31.077,281.595Z"
                        transform="translate(-20.274 -268.514)"
                        fill="#365e7d"
                      />
                      <path
                        id="Path_266963"
                        data-name="Path 266963"
                        d="M20.833,278.964v.784c1.127.895,4.1,2.631,10.244,2.631s9.118-1.736,10.244-2.631v-.784c-1.127.895-4.1,2.631-10.244,2.631S21.96,279.858,20.833,278.964Z"
                        transform="translate(-20.274 -268.514)"
                        fill="#2b4d66"
                      />
                      <path
                        id="Path_266964"
                        data-name="Path 266964"
                        d="M228.107,306.66a1.27,1.27,0,0,1-1.27-1.27v-1.554a.452.452,0,0,1,.452-.452h1.636a.452.452,0,0,1,.452.452v1.554A1.27,1.27,0,0,1,228.107,306.66Z"
                        transform="translate(-217.304 -291.87)"
                        fill="#ffe27a"
                      />
                      <path
                        id="Path_266965"
                        data-name="Path 266965"
                        d="M228.107,332.71a1.27,1.27,0,0,1-1.27-1.27v.784a1.27,1.27,0,0,0,2.541,0v-.784A1.27,1.27,0,0,1,228.107,332.71Z"
                        transform="translate(-217.304 -318.704)"
                        fill="#f9cf58"
                      />
                      <path
                        id="Path_266966"
                        data-name="Path 266966"
                        d="M172.687,42.507v-1.5a5.355,5.355,0,0,1,4.542,0v1.5h1.42v-1.9a.71.71,0,0,0-.28-.565,4.254,4.254,0,0,0-.938-.5,6.908,6.908,0,0,0-4.946,0,4.251,4.251,0,0,0-.938.5.71.71,0,0,0-.28.565v1.9h1.42Z"
                        transform="translate(-164.155 -39.085)"
                        fill="#e8834d"
                      />
                      <g
                        id="Group_411896"
                        data-name="Group 411896"
                        transform="translate(7.112 2.638)"
                      >
                        <path
                          id="Path_266967"
                          data-name="Path 266967"
                          d="M171.267,99.633h1.42v.784h-1.42Z"
                          transform="translate(-171.267 -99.633)"
                          fill="#d66e41"
                        />
                        <path
                          id="Path_266968"
                          data-name="Path 266968"
                          d="M308.124,99.633h1.42v.784h-1.42Z"
                          transform="translate(-302.162 -99.633)"
                          fill="#d66e41"
                        />
                      </g>
                    </g>
                    <path
                      id="Path_266969"
                      data-name="Path 266969"
                      d="M20.618,34.574h-5.44V33a1.045,1.045,0,0,0-.409-.826,4.545,4.545,0,0,0-1.017-.545,6.4,6.4,0,0,0-.808-.262.327.327,0,1,0-.161.635,5.749,5.749,0,0,1,.725.235,3.974,3.974,0,0,1,.864.458.386.386,0,0,1,.151.305v1.575h-.768V33.4a.327.327,0,0,0-.179-.292,5.375,5.375,0,0,0-2.424-.542,5.374,5.374,0,0,0-2.424.542.327.327,0,0,0-.179.292v1.172H7.781V33a.386.386,0,0,1,.151-.306,3.971,3.971,0,0,1,.864-.458,6.377,6.377,0,0,1,2.356-.435c.139,0,.28,0,.419.013a.327.327,0,1,0,.04-.654c-.152-.009-.307-.014-.459-.014a7.038,7.038,0,0,0-2.6.482,4.545,4.545,0,0,0-1.017.545A1.045,1.045,0,0,0,7.126,33v1.575H1.686A1.688,1.688,0,0,0,0,36.26v4.582A1.679,1.679,0,0,0,.56,42.1V49.05a1.688,1.688,0,0,0,1.686,1.686H15.18a.327.327,0,1,0,0-.655H2.246A1.033,1.033,0,0,1,1.215,49.05V42.583A10.952,10.952,0,0,0,3.181,43.6a.327.327,0,1,0,.244-.608,9.215,9.215,0,0,1-2.39-1.348,1.028,1.028,0,0,1-.38-.8V36.26a1.033,1.033,0,0,1,1.031-1.031H20.618a1.033,1.033,0,0,1,1.031,1.031v4.582a1.028,1.028,0,0,1-.38.8,9.61,9.61,0,0,1-2.784,1.5,19.9,19.9,0,0,1-5.733,1.069v-.747a.781.781,0,0,0-.78-.78h-1.64a.781.781,0,0,0-.78.78v.748a21.331,21.331,0,0,1-4.778-.758.327.327,0,0,0-.182.629,22.023,22.023,0,0,0,4.96.784v.154a1.6,1.6,0,0,0,3.2,0v-.154c4.427-.231,6.989-1.384,8.337-2.281V49.05a1.033,1.033,0,0,1-1.031,1.031H16.49a.327.327,0,0,0,0,.655h3.568a1.688,1.688,0,0,0,1.686-1.686V42.1a1.679,1.679,0,0,0,.56-1.255V36.26a1.688,1.688,0,0,0-1.686-1.686ZM9.2,33.61a5.109,5.109,0,0,1,3.9,0v.965H9.2V33.61ZM12.1,45.019a.945.945,0,0,1-1.891,0V43.462a.126.126,0,0,1,.126-.126h1.64a.126.126,0,0,1,.126.126Z"
                      transform="translate(0 -31.146)"
                    />
                  </g>
                </svg>
              </>
            }
            status={mentor?.status}
            data={`${mentor?.status == "2" ? "Rejected" : "Pending"}`}
          />
        ) : (
          <ExploreCardChips
            name="Student Category"
            img={
              <>
                {" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16.092"
                  height="19.854"
                  viewBox="0 0 16.092 19.854"
                >
                  <g
                    id="Group_417838"
                    data-name="Group 417838"
                    transform="translate(-48.5)"
                  >
                    <path
                      id="Path_340067"
                      data-name="Path 340067"
                      d="M69.572,26.772H57.939A1.939,1.939,0,0,1,56,24.833V9.439A1.939,1.939,0,0,1,57.939,7.5H69.572a1.939,1.939,0,0,1,1.939,1.939V24.833A1.939,1.939,0,0,1,69.572,26.772Z"
                      transform="translate(-7.209 -7.209)"
                      fill="#f5f5fc"
                    />
                    <path
                      id="Path_340068"
                      data-name="Path 340068"
                      d="M57.551,24.833V9.439A1.939,1.939,0,0,1,59.49,7.5H57.939A1.939,1.939,0,0,0,56,9.439V24.833a1.939,1.939,0,0,0,1.939,1.939H59.49A1.939,1.939,0,0,1,57.551,24.833Z"
                      transform="translate(-7.209 -7.209)"
                      fill="#ebebf5"
                    />
                    <path
                      id="Path_340069"
                      data-name="Path 340069"
                      d="M99.792,77.727l-1.919,1.919-.548-.548a.776.776,0,0,0-1.1,1.1l1.1,1.1a.776.776,0,0,0,1.1,0l2.468-2.468a.776.776,0,1,0-1.1-1.1Z"
                      transform="translate(-45.659 -74.494)"
                      fill="#95e8c8"
                    />
                    <path
                      id="Path_340070"
                      data-name="Path 340070"
                      d="M97.2,113.87a.944.944,0,0,1,.632-.277l-.511-.511a.776.776,0,0,0-1.1,1.1l.813.813A.947.947,0,0,1,97.2,113.87Z"
                      transform="translate(-45.66 -108.479)"
                      fill="#82d9b6"
                    />
                    <path
                      id="Path_340071"
                      data-name="Path 340071"
                      d="M112.837,331.088l-.685.685-.685-.685a.776.776,0,0,0-1.1,1.1l.686.685-.686.685a.776.776,0,0,0,1.1,1.1l.685-.685.685.685a.776.776,0,0,0,1.1-1.1l-.685-.685.685-.685a.776.776,0,1,0-1.1-1.1Z"
                      transform="translate(-59.252 -318.031)"
                      fill="#ff9696"
                    />
                    <path
                      id="Path_340072"
                      data-name="Path 340072"
                      d="M111.055,383.778a.776.776,0,0,1,0-1.1l-.685.685a.776.776,0,1,0,1.1,1.1l.685-.685A.776.776,0,0,1,111.055,383.778Z"
                      transform="translate(-59.252 -367.842)"
                      fill="#ff73a5"
                    />
                    <path
                      id="Path_340073"
                      data-name="Path 340073"
                      d="M111.055,331.773a.775.775,0,0,1,1.1,0l-.685-.685a.776.776,0,0,0-1.1,1.1l.685.685A.775.775,0,0,1,111.055,331.773Z"
                      transform="translate(-59.252 -318.031)"
                      fill="#ff73a5"
                    />
                    <path
                      id="Path_340074"
                      data-name="Path 340074"
                      d="M99.792,204.408l-1.919,1.919-.548-.548a.776.776,0,1,0-1.1,1.1l1.1,1.1a.776.776,0,0,0,1.1,0l2.468-2.468a.776.776,0,1,0-1.1-1.1Z"
                      transform="translate(-45.659 -196.263)"
                      fill="#95e8c8"
                    />
                    <path
                      id="Path_340075"
                      data-name="Path 340075"
                      d="M97.2,240.55a.944.944,0,0,1,.632-.277l-.511-.511a.776.776,0,1,0-1.1,1.1l.813.813A.947.947,0,0,1,97.2,240.55Z"
                      transform="translate(-45.66 -230.247)"
                      fill="#82d9b6"
                    />
                    <path
                      id="Path_340076"
                      data-name="Path 340076"
                      d="M62.363,0H50.73A2.232,2.232,0,0,0,48.5,2.23V17.624a2.232,2.232,0,0,0,2.23,2.23H56a.291.291,0,1,0,0-.582H50.73a1.65,1.65,0,0,1-1.648-1.648V2.23A1.65,1.65,0,0,1,50.73.582H62.363A1.65,1.65,0,0,1,64.011,2.23V17.624a1.65,1.65,0,0,1-1.648,1.648H57.089a.291.291,0,0,0,0,.582h5.274a2.232,2.232,0,0,0,2.23-2.23V2.23A2.232,2.232,0,0,0,62.363,0Z"
                      transform="translate(0)"
                    />
                    <path
                      id="Path_340077"
                      data-name="Path 340077"
                      d="M93.9,70.312a1.066,1.066,0,0,0-1.508,0l-1.714,1.714-.343-.343a1.066,1.066,0,0,0-1.508,1.508l1.1,1.1a1.066,1.066,0,0,0,1.508,0L93.9,71.82a1.066,1.066,0,0,0,0-1.508Zm-.411,1.1L91.02,73.877a.485.485,0,0,1-.685,0l-1.1-1.1a.485.485,0,0,1,.685-.686l.548.548a.291.291,0,0,0,.411,0L92.8,70.724a.485.485,0,1,1,.685.685Z"
                      transform="translate(-38.463 -67.286)"
                    />
                    <path
                      id="Path_340078"
                      data-name="Path 340078"
                      d="M107.241,324.426a1.066,1.066,0,0,0-1.82-.754l-.48.48-.48-.48a1.066,1.066,0,1,0-1.508,1.508l.48.48-.48.48a1.066,1.066,0,1,0,1.508,1.508l.48-.48.48.48a1.066,1.066,0,1,0,1.508-1.508l-.48-.48.48-.48A1.059,1.059,0,0,0,107.241,324.426Zm-.724.343-.685.685a.291.291,0,0,0,0,.411l.685.685a.485.485,0,1,1-.685.685l-.686-.685a.291.291,0,0,0-.411,0l-.685.685a.485.485,0,0,1-.685-.685l.685-.685a.291.291,0,0,0,0-.411l-.685-.685a.485.485,0,0,1,.685-.686l.685.686a.291.291,0,0,0,.411,0l.685-.686a.485.485,0,0,1,.685.686Z"
                      transform="translate(-52.042 -310.821)"
                    />
                    <path
                      id="Path_340079"
                      data-name="Path 340079"
                      d="M93.9,196.992a1.066,1.066,0,0,0-1.508,0l-1.714,1.714-.343-.343a1.066,1.066,0,0,0-1.508,1.508l1.1,1.1a1.066,1.066,0,0,0,1.508,0L93.9,198.5A1.068,1.068,0,0,0,93.9,196.992Zm-.411,1.1-2.468,2.468a.485.485,0,0,1-.685,0l-1.1-1.1a.485.485,0,1,1,.685-.685l.548.548a.291.291,0,0,0,.411,0L92.8,197.4a.485.485,0,0,1,.685.685Z"
                      transform="translate(-38.463 -189.053)"
                    />
                    <path
                      id="Path_340080"
                      data-name="Path 340080"
                      d="M260.715,97.4h5.742a.291.291,0,1,0,0-.582h-5.742a.291.291,0,1,0,0,.582Z"
                      transform="translate(-203.706 -93.066)"
                    />
                    <path
                      id="Path_340081"
                      data-name="Path 340081"
                      d="M260.715,147.4h2.871a.291.291,0,0,0,0-.582h-2.871a.291.291,0,1,0,0,.582Z"
                      transform="translate(-203.706 -141.126)"
                    />
                    <path
                      id="Path_340082"
                      data-name="Path 340082"
                      d="M260.715,224.082h5.742a.291.291,0,1,0,0-.582h-5.742a.291.291,0,1,0,0,.582Z"
                      transform="translate(-203.706 -214.833)"
                    />
                    <path
                      id="Path_340083"
                      data-name="Path 340083"
                      d="M260.715,274.082h2.871a.291.291,0,1,0,0-.582h-2.871a.291.291,0,1,0,0,.582Z"
                      transform="translate(-203.706 -262.895)"
                    />
                    <path
                      id="Path_340084"
                      data-name="Path 340084"
                      d="M260.715,350.763h5.742a.291.291,0,1,0,0-.582h-5.742a.291.291,0,1,0,0,.582Z"
                      transform="translate(-203.706 -336.602)"
                    />
                    <path
                      id="Path_340085"
                      data-name="Path 340085"
                      d="M260.715,400.763h2.871a.291.291,0,1,0,0-.582h-2.871a.291.291,0,1,0,0,.582Z"
                      transform="translate(-203.706 -384.663)"
                    />
                  </g>
                </svg>
              </>
            }
            data={`${mentor?.class ? JSON.parse(mentor?.class)[0] : "NA"}`}
          />
        )}
        <ExploreCardChips
          name="Sessions"
          img={
            <>
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30.407"
                height="30.712"
                viewBox="0 0 30.407 30.712"
              >
                <g
                  id="Group_411907"
                  data-name="Group 411907"
                  transform="translate(0 0)"
                >
                  <g
                    id="Group_411906"
                    data-name="Group 411906"
                    transform="translate(0.991 0.991)"
                  >
                    <path
                      id="Path_266976"
                      data-name="Path 266976"
                      d="M71.524,284.341a4.14,4.14,0,0,1-4.234.01,3.88,3.88,0,0,0-2.12,3.189h8.457a3.8,3.8,0,0,0-2.1-3.2Z"
                      transform="translate(-62.831 -268.382)"
                      fill="#ffd062"
                    />
                    <path
                      id="Path_266977"
                      data-name="Path 266977"
                      d="M245.761,26.62H230.119a.121.121,0,0,0-.119.119V37.057a.121.121,0,0,0,.119.119h1.529a.5.5,0,0,1,.5.5v3.282l4.405-3.663a.5.5,0,0,1,.316-.115h8.9a.121.121,0,0,0,.119-.119V26.739a.121.121,0,0,0-.119-.119ZM235.71,35.072h-2.964a.5.5,0,1,1,0-.991h2.964a.5.5,0,0,1,0,.991Zm6.5-2.679h-9.466a.5.5,0,1,1,0-.991h9.466a.5.5,0,0,1,0,.991Zm0-2.679h-9.466a.5.5,0,1,1,0-.991h9.466a.5.5,0,0,1,0,.991Z"
                      transform="translate(-217.454 -26.62)"
                      fill="#ffd062"
                    />
                    <path
                      id="Path_266978"
                      data-name="Path 266978"
                      d="M108.637,200.419A1.635,1.635,0,1,0,107,198.785,1.638,1.638,0,0,0,108.637,200.419Z"
                      transform="translate(-102.071 -186.591)"
                      fill="#ffd062"
                    />
                    <path
                      id="Path_266979"
                      data-name="Path 266979"
                      d="M27.39,388.781a.555.555,0,0,0,.554.554H47.989a.555.555,0,0,0,.554-.554V387.32H27.39Zm9.594-.875h1.965a.5.5,0,1,1,0,.991H36.984a.5.5,0,1,1,0-.991Z"
                      transform="translate(-27.39 -364.985)"
                      fill="#ffd062"
                    />
                    <path
                      id="Path_266980"
                      data-name="Path 266980"
                      d="M68.71,474.83H84.747v.978H68.71Z"
                      transform="translate(-66.151 -447.077)"
                      fill="#ffd062"
                    />
                  </g>
                  <path
                    id="Path_266981"
                    data-name="Path 266981"
                    d="M93.628,186.4A2.625,2.625,0,1,0,91,183.775,2.63,2.63,0,0,0,93.628,186.4Zm0-4.261a1.635,1.635,0,1,1-1.637,1.635A1.638,1.638,0,0,1,93.628,182.14Z"
                    transform="translate(-86.071 -170.591)"
                  />
                  <path
                    id="Path_266982"
                    data-name="Path 266982"
                    d="M174.825,397.761h1.965a.5.5,0,1,0,0-.991h-1.965a.5.5,0,0,0,0,.991Z"
                    transform="translate(-164.241 -372.859)"
                  />
                  <path
                    id="Path_266983"
                    data-name="Path 266983"
                    d="M56.316,267.259a.5.5,0,0,0-.482.049,3.144,3.144,0,0,1-3.592.01.5.5,0,0,0-.481-.047,4.9,4.9,0,0,0-2.972,4.7.495.495,0,0,0,.5.5h9.492a.5.5,0,0,0,.5-.5,4.888,4.888,0,0,0-2.957-4.715Zm-6.512,4.22a3.879,3.879,0,0,1,2.12-3.189,4.139,4.139,0,0,0,4.234-.01,3.8,3.8,0,0,1,2.1,3.2H49.8Z"
                    transform="translate(-46.474 -251.329)"
                  />
                  <path
                    id="Path_266984"
                    data-name="Path 266984"
                    d="M269.79,147.12h-2.964a.5.5,0,0,0,0,.991h2.964a.5.5,0,0,0,0-.991Z"
                    transform="translate(-250.544 -138.668)"
                  />
                  <path
                    id="Path_266985"
                    data-name="Path 266985"
                    d="M276.3,103.85h-9.466a.5.5,0,0,0,0,.991H276.3a.5.5,0,0,0,0-.991Z"
                    transform="translate(-250.553 -98.077)"
                  />
                  <path
                    id="Path_266986"
                    data-name="Path 266986"
                    d="M276.3,60.59h-9.466a.5.5,0,0,0,0,.991H276.3a.5.5,0,0,0,0-.991Z"
                    transform="translate(-250.553 -57.496)"
                  />
                  <path
                    id="Path_266987"
                    data-name="Path 266987"
                    d="M40.687,10.62H25.045a1.111,1.111,0,0,0-1.11,1.11v7.35h-11a1.546,1.546,0,0,0-1.545,1.544V33.012h0V35.4a1.547,1.547,0,0,0,1.545,1.545h6.037v1.423h-4.1a.924.924,0,0,0-.923.923v1.113a.924.924,0,0,0,.923.923H31.043a.924.924,0,0,0,.923-.923V39.3a.924.924,0,0,0-.923-.923h-4.1V36.95H32.98A1.547,1.547,0,0,0,34.525,35.4V23.156h6.162a1.111,1.111,0,0,0,1.11-1.11V11.73A1.111,1.111,0,0,0,40.687,10.62ZM30.976,39.364v.978H14.939v-.978H30.975Zm-11.013-.991V36.95h5.99v1.423Zm13.571-2.967a.555.555,0,0,1-.554.554H12.935a.555.555,0,0,1-.554-.554V33.945H33.533v1.461ZM12.381,32.955V20.624a.554.554,0,0,1,.554-.554h11v1.977a1.111,1.111,0,0,0,1.11,1.11h1.034V27a.5.5,0,0,0,.812.381l5.079-4.224h1.563v9.8H12.381ZM40.806,22.047a.121.121,0,0,1-.119.119h-8.9a.492.492,0,0,0-.316.115L27.07,25.943V22.662a.5.5,0,0,0-.5-.5H25.045a.121.121,0,0,1-.119-.119V11.73a.121.121,0,0,1,.119-.119H40.687a.121.121,0,0,1,.119.119Z"
                    transform="translate(-11.39 -10.62)"
                  />
                </g>
              </svg>
            </>
          }
          data={`${mentor?.total_sessions || 0}  Completed`}
        />
      </div>
      <div className="my-3">
        <Line />
      </div>

      {isMentorPage ? (
        <div className="flex justify-between text-center mb-2  px-1">
          <Link
            to={`/mentorship/${mentor?.firstName || "unknown"}/${
              mentor?.id
            }/mentor-details`}
            className="w-full"
          >
            <Button
              // onClick={() => {
              //   setModal(true);
              //   setMentorData(mentor);
              // }}
              variant="contained"
              className="border py-[19px] w-full flex gap-[6.2px] miniDesk:!px-[2px] text-[19px] font-medium capitalize !text-[#000000] !bg-[#F0BC5E] 3xl:py-[10px]  3xl:px-3 3xl:text-[13px] 3xl:h-fit !shadow-none px-[28px] border-[#239403] rounded-[9px]"
            >
              View Details{" "}
              <EastRounded className="3xl:text-[16px] miniDesk:hidden" />
            </Button>
          </Link>
        </div>
      ) : (
        <div
          className={`flex justify-between  ${
            isRequested ? " gap-x-4 " : "  gap-x-4 "
          } text-center mb-2  px-1.5`}
        >
          {/* {!isRequested && (
            <h4 className="text-[#000000] text-[23px] font-extrabold">
              $2000/
              <span className="text-[11px] font-normal">per month</span>
            </h4>
          )} */}
          {/* <Link to={"/mentorship/mentor/call"}> */}
          <Button
            onClick={() => {
              setModal(true);
              setMentorData(mentor);
            }}
            variant="contained"
            className={`border !h-[45px] ${
              isRequested ? " w-full " : " w-full "
            }   flex gap-[6.2px] miniDesk:!px-[2px] font-medium capitalize !text-[#000000] !bg-[#F0BC5E52]   3xl:px-3 text-[13px]  !shadow-none px-[28px] border-[#239403] rounded-[14px]`}
          >
            {!isRequested ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22.188"
                height="19.5"
                viewBox="0 0 26.188 21.5"
              >
                <path
                  id="Path_340065"
                  data-name="Path 340065"
                  d="M3.347,13.314a10.77,10.77,0,0,1,10.487-7.98,10.77,10.77,0,0,1,10.488,7.98.763.763,0,0,1,0,.373,10.77,10.77,0,0,1-10.488,7.98,10.77,10.77,0,0,1-10.487-7.98.764.764,0,0,1,0-.373ZM13.835,3a13.1,13.1,0,0,0-12.75,9.744,3.1,3.1,0,0,0,0,1.512A13.1,13.1,0,0,0,13.835,24a13.1,13.1,0,0,0,12.75-9.744,3.1,3.1,0,0,0,0-1.512A13.1,13.1,0,0,0,13.835,3ZM8,13.5a5.833,5.833,0,1,1,5.833,5.833A5.833,5.833,0,0,1,8,13.5Zm9.333,0a3.5,3.5,0,1,1-3.5-3.5A3.5,3.5,0,0,1,17.335,13.5Z"
                  transform="translate(-0.741 -2.75)"
                  stroke="#fae9cb"
                  stroke-width="0.5"
                  fill-rule="evenodd"
                />
              </svg>
            ) : mentor?.status == "2" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21.5"
                height="21.5"
                viewBox="0 0 21.5 21.5"
              >
                <g
                  id="Group_417876"
                  data-name="Group 417876"
                  transform="translate(-1.25 -1.25)"
                >
                  <path
                    id="Path_340094"
                    data-name="Path 340094"
                    d="M12,2.75A9.25,9.25,0,1,0,21.25,12,9.25,9.25,0,0,0,12,2.75ZM1.25,12A10.75,10.75,0,1,1,12,22.75,10.75,10.75,0,0,1,1.25,12Z"
                    fill-rule="evenodd"
                  />
                  <path
                    id="Path_340095"
                    data-name="Path 340095"
                    d="M16.03,8.97a.75.75,0,0,1,0,1.061l-5,5a.75.75,0,0,1-1.061,0l-2-2A.75.75,0,0,1,9.03,11.97l1.47,1.47,4.47-4.47a.75.75,0,0,1,1.061,0Z"
                    fill-rule="evenodd"
                  />
                </g>
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14.012"
                height="14"
                viewBox="0 0 14.012 14"
              >
                <path
                  id="close"
                  d="M5.293,5.293a1,1,0,0,1,1.414,0L12,10.586l5.293-5.293a1,1,0,1,1,1.414,1.414L13.414,12l5.293,5.293a1,1,0,0,1-1.414,1.414L12,13.414,6.707,18.707a1,1,0,0,1-1.414-1.414L10.586,12,5.293,6.707a1,1,0,0,1,0-1.414Z"
                  transform="translate(-5 -4.988)"
                  fill-rule="evenodd"
                />
              </svg>
            )}
            {!isRequested
              ? "View Detail"
              : mentor?.status == "2"
              ? "Re-apply"
              : "Cancel Request"}

            {/* <EastRounded className="3xl:text-[16px] miniDesk:hidden" /> */}
          </Button>
          {/* </Link> */}
          <Button
            onClick={() => {
              setModal(true);
              setMentorData(mentor);
            }}
            variant="contained"
            className={`border  !h-[45px] ${
              isRequested ? " w-full " : " w-full "
            }  text-center flex gap-x-[2.2px] leading-snug miniDesk:!px-[2px]  font-medium capitalize !text-[#000000] !bg-[#F0BC5E]   3xl:px-3 text-[13px]  !shadow-none px-[28px] border-[#239403] rounded-[14px]`}
          >
            {!isRequested ? " Book Your Session " : "View Detail"}
            <EastRounded className="3xl:text-[16px] miniDesk:hidden" />
          </Button>
        </div>
      )}
    </div>
  );
}
