import React, { useRef } from "react";
import { medias } from "../../../../config/Resources";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
/* eslint-disable jsx-a11y/img-redundant-alt */
function AgencyPdfsCards({ councelling, councellingChips }) {
	const scrollContainerRef = useRef(null);

	const handleScroll = () => {
		if (scrollContainerRef.current) {
			scrollContainerRef.current.scrollBy({
				left: 200,
				behavior: "smooth",
			});
		}
	};

	const handleImageClick = (fileUrl) => {
		if (fileUrl) {
			window.open(fileUrl, "_blank");
		}
	};

	return (
		<div className="">
			<div className="ml-4 mr-4 relative">
				<MdOutlineKeyboardArrowRight
					className="absolute right-[-5.5%] top-28 transform -translate-x-1/2 -translate-y-1/2 bg-[#ffffff] w-8 h-8 rounded-full p-1 border border-[#C2C2C2] cursor-pointer"
					onClick={handleScroll}
				/>
			</div>

			<div
				className="overflow-x-auto scrollable-content"
				ref={scrollContainerRef}
			>
				<div className="flex space-x-4 pb-4">
					{councelling?.counselling_agencies?.map((data, index) => {
						return data?.agency_name === councellingChips
							? data?.cut_off_pdfs?.map((chipsData, index) => {
									return (
										<div
											key={index}
											className="flex-shrink-0 pt-2 pb-4 pl-2 pr-2 w-[180px] ml-4 mr-4 mt-4 rounded-xl shadow-md dark:bg-gray-800 dark:border-gray-700"
										>
											<div className="">
												<img
													className="w-28 h-28 mt-2 ml-auto mr-auto p-5 bg-[#FBC343] bg-opacity-20 rounded-full"
													src={medias.images.Exam.Papers}
													alt="BackgroundImage"
													onClick={() => handleImageClick(chipsData?.media)}
												/>
											</div>
											<div className="pb-4 pl-2 pr-1 pt-4">
												<p className="font-plusJakartaSans flex items-center justify-center text-center font-bold text-[13px] text-[#000000]">
													{chipsData?.title}
												</p>
											</div>
										</div>
									);
							  })
							: "";
					})}
				</div>
			</div>
		</div>
	);
}

export default AgencyPdfsCards;
