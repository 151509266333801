/* eslint-disable no-unused-vars */
import React, { useState, useRef } from "react";
import { FaRegCircleRight } from "react-icons/fa6";

const Tabs = ({
	data = [],
	setActiveTab,
	activeTab,
	setActiveTabName,
	isSocial,
}) => {
	const [active, setActive] = useState(1);
	const tabsContainerRef = useRef();

	// const scrollTabsRight = () => {
	// 	if (tabsContainerRef.current) {
	// 		tabsContainerRef.current.scrollBy({
	// 			left: 200,
	// 			behavior: "smooth",
	// 		});
	// 	}
	// };

	return (
		<div className="flex items-center my-8 w-full gap-7 overflow-x-hidden border-solid border-gray-200 border-b-[2px]">
			<div
				className="flex gap-7 overflow-x-scroll no-scrollbar border-b-[2px]"
				ref={tabsContainerRef}
			>
				{data?.map((tab, i) => {
					return (
						<div
							className={`${
								active === tab?.key
									? "font-bold text-black border-solid border-[#2771E7] border-b-[4px]"
									: ""
							} cursor-pointer text-nowrap pb-4 px-5 text-gray-400 relative z-50 hover:font-bold ml-1`}
							key={i}
							onClick={() => {
								setActive(tab?.key);
								setActiveTabName(tab?.heading);
								setActiveTab(tab?.key);
							}}
						>
							{tab?.heading}
						</div>
					);
				})}
			</div>
			{data?.length >= 7 &&<button className="ml-2 focus:outline-none text-gray-400 w-12 mt-[-15px]">
				<FaRegCircleRight />
			</button>}
		</div>
	);
};

export default Tabs;
