import React, { useEffect, useState } from "react";
import Text from "../../../Text";
import { Controls, Player } from "@lottiefiles/react-lottie-player";
import Preview from "../../../Preview";
import { useMediaQuery } from "@mui/material";

const Questions = ({
  questionInfo,
  currentQuestion,
  imageClassOverride = "",
  lottieHeight = "",
  lottieWidth = "",
  height,
  width,
}: {
  lottieHeight?: string;
  lottieWidth?: string;
  height?: string;
  imageClassOverride?: string;
  currentQuestion?: number;
  width?: string;
  questionInfo: any;
}) => {
  const [files, setFiles] = useState([]);
  const Minidesktop = useMediaQuery("(max-width : 1220px)");
  const Desktop = useMediaQuery("(max-width : 1440px)");

  useEffect(() => {
    if (questionInfo?.questionFiles) {
      setFiles(JSON.parse(questionInfo?.questionFiles));
    }
  }, [questionInfo?.questionFiles]);

  return (
    <div className="w-full mt-4 mr-5">
      <Text className="font-[PlusJakartaSans-Bold] w-[93%] tab:!text-[16px] 3xlDeskBig:mt-[50px]  desktop:!text-[14px] 3xlDeskBig:text-[30px] 3xlDesk:text-[22px] 3xl:text-[20px] text-[28px] text-center flex justify-center text-white">
        {questionInfo?.id}
        {/* {questionInfo?.question} */}
        <div dangerouslySetInnerHTML={{ __html: questionInfo?.question }} />
      </Text>
      {questionInfo?.file && (
        <Player
          className="mr-5"
          autoplay
          speed={1.5}
          loop
          src={questionInfo.file}
          style={{
            height: lottieHeight
              ? lottieHeight
              : height
              ? height
              : Minidesktop
              ? "100px"
              : "120px",
            padding: 0,
            width: lottieWidth
              ? lottieWidth
              : width
              ? width
              : Minidesktop
              ? "100px"
              : "120px",
          }}
        >
          <Controls
            visible={false}
            buttons={[
              "play",
              "repeat",
              "frame",
              "debug",
              "snapshot",
              "background",
            ]}
          />
        </Player>
      )}
      <div className="flex justify-center w-full gap-2 mt-2">
        {/* <>
        {
          console.log(files)
        }
        </> */}
        {files?.map((items: any) => {
          return (
            <Preview
              imageClassOverride={imageClassOverride}
              height={
                lottieHeight && items?.file?.includes(".json")
                  ? Number(lottieHeight?.replace("px", ""))
                  : Number(height?.replace("px", ""))
              }
              width={
                lottieWidth && items?.file?.includes(".json")
                  ? Number(lottieWidth?.replace("px", ""))
                  : Number(width?.replace("px", ""))
              }
              src={items?.file}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Questions;
