import React from 'react'

const WebRTCFirebase = () => {















  
  return (
    <div>WebRTCFirebase</div>
  )
}

export default WebRTCFirebase