import { useParams } from "react-router-dom";
import BlogsDetailsChips from "../../Components/Blogs/BlogsDetails/BlogsDetailsChips";
import BlogsDetailsImages from "../../Components/Blogs/BlogsDetails/BlogsDetailsImages";
import BlogsDetilasIntroduction from "../../Components/Blogs/BlogsDetails/BlogsDetailsIntroduction";
import BlogsDetailsChips1 from "../../Components/Blogs/BlogsDetails/BlogsDetaisChips1";
import PopularBlogs1 from "../../Components/Blogs/BlogsDetails/PopularBlogs1";
import EditorsPick1 from "../../Components/Blogs/BlogsListing/StudyAbroad.jsx/EditorsPick1";
import NutritionAndDiet from "../../Components/College/CollegeDetailsView/NutritionAndDietMicroBiology";
import { medias } from "../../config/Resources";
import { useEffect, useState } from "react";
import axiosInstance from "../../config/Axios/axiosInstance";
import { API_URLS } from "../../config/API_URLS";
import BlogDetailsHeader from "../../Components/Blogs/BlogsDetails/BlogsDetailsHead";

function BlogsDetailsPage() {
	const { id } = useParams();
	const [blogsDetails, setBlogDetails] = useState(null);
	const [tab, setTab] = useState();
	const [blogsData, setBlogsData] = useState();
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		(async () => {
			try {
				const res = await axiosInstance.get(`${API_URLS.getAllBlogs}`);
				if (res?.success === true) {
					setBlogsData(res?.data);
				}
			} catch (error) {
				console.error("Error fetching blogs data:", error);
			}
		})();
	}, []);

	useEffect(() => {
		(async () => {
			setLoading(true); // Start loading
			try {
				const res = await axiosInstance.get(`${API_URLS.getListById}/${id}`);
				if (res?.data) {
					setBlogDetails(res?.data);
				}
			} catch (error) {
				console.error("Error fetching blog details:", error);
			} finally {
				setLoading(false); // Stop loading
			}
		})();
	}, [id]);

	if (loading) {
		return <div className="text-center mt-10">Loading...</div>;
	}

	return (
		<>
			<div className="w-[86%] ml-auto mr-auto pt-10">
				<img
					src={medias.images.Blogs.HeaderImage}
					className="w-full"
					alt="Header Image"
				/>
				<div className="">
					<div className="h-[100%] pt-5 pb-5 ml-auto mr-auto flex gap-2">
						<div className="bg-white w-[67%] rounded-[15px] shadow-custom1">
							<BlogsDetailsImages blogsDetails={blogsDetails} />
							<BlogDetailsHeader blogsDetails={blogsDetails} />
							<BlogsDetailsChips1 blogsDetails={blogsDetails} />
							<BlogsDetailsChips blogsDetails={blogsDetails} setTab={setTab} />
							<BlogsDetilasIntroduction tab={tab} blogsDetails={blogsDetails} />
						</div>
						<div className="w-[33%]">
							<div className="bg-white h-[500px] overflow-y-scroll scrollable-content rounded-[10px] shadow-custom1">
								<EditorsPick1 blogsData={blogsData} />
							</div>
							<div className="bg-white mt-4 h-[500px] overflow-y-scroll scrollable-content rounded-[10px] shadow-custom1">
								<PopularBlogs1 />
							</div>
							<div className="mt-4 flex items-center text-center justify-center">
								<NutritionAndDiet />
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default BlogsDetailsPage;
