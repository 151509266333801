import React from "react";
import Image from "../../Image";
import { East, PlayCircleFilledRounded } from "@mui/icons-material";
import { Button } from "@mui/material";
import Text from "../../Text";
import classNames from "classnames";

const Face1 = ({
  Title,
  mainImageClass,
  isDemo,
  description,
  mainContainerClass,
  btnClass,
  mainImage,
  descriptionClass,
}: {
  Title?: string | JSX.Element | JSX.Element[];
  description?: string | JSX.Element | JSX.Element[];
  btnClass?: string;
  isDemo?: boolean;
  mainContainerClass?: string;
  mainImage?: string;
  mainImageClass?: string;
  descriptionClass?: string;
}) => {
  return (
    <div
      className={`${
        mainContainerClass || ""
      } flex Face1background pt-[105.58px] gap-x-[21.79px] pl-[118px] pr-[85px] bg-[#F0BC5E] bg-opacity-10 `}
    >
      <div className="w-1/2  ">
        {Title}
        <div className="my-[20px]  opacity-[80%]">
          <Text
            className={classNames(
              descriptionClass,
              " text-[16px] opacity-80 font-normal"
            )}
          >
            {description}
          </Text>
        </div>
        <div className="flex gap-[15px] items-center">
          <Button
            variant="contained"
            className={classNames(
              btnClass,
              " bg-[#239405] hover:bg-[#239405] !gap-3 !w-[230.1px] !capitalize !font-light !text-[18px] !h-[60.77px] !rounded-[11px] shadow-[0px_10px_0px_0px_rgba(18,87,0,1)] "
            )}
          >
            Get Started
            <East />
          </Button>
          {/* 
          <a
            href="#_"
            className="  px-5 py-2.5 overflow-hidden !gap-3 !w-[230.1px] !capitalize !font-light !text-[18px] !h-[60.77px] !rounded-[11px] group bg-[#239405]  relative hover:bg-gradient-to-r hover:from-[#239405] hover:to-[#239405] text-white  hover:ring-[#239405] transition-all ease-out duration-300 shadow-[0px_10px_0px_0px_rgba(18,87,0,1)]"
          >
            <span className="absolute right-0 w-8 h-32 -mt-10 transition-all duration-1000 transform translate-x-12 bg-white opacity-30 rotate-12 group-hover:-translate-x-40 ease"></span>
            <span className="relative">
              Button Text <East />
            </span>
          </a> */}
          {isDemo && (
            <div className="flex gap-[13px] items-center">
              <PlayCircleFilledRounded className="!fill-[#DC9F31] !text-[60px]" />
              <Text className="text-[#000000] underline  opacity-[64%] text-[24px]">
                Watch Demo
              </Text>
            </div>
          )}
        </div>
      </div>
      <div className={classNames(mainImageClass, "w-1/2")}>
        <Image className="" src={mainImage} />
      </div>
    </div>
  );
};

export default Face1;
