import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { medias } from "../../config/Resources";

const data = ["", "", "", "", "", "", "", "", ""];

const MediaSlider = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 5000,
    cssEase: "linear",
    slidesToShow: 6,
    slidesToScroll: 1,
    initialSlide: 0,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="mx-[3%]  overflow-hidden ">
      <div className="bg-[#CEF3D8] rounded-3xl">
        <div className="w-full text-center ">
          <h5 className="text-[#022315] tracking-normal text-[56px] font-plusJakartaSans font-bold pt-10">
            In The Media
          </h5>
        </div>
        <div className="slider-container pt-10 pb-14 items-center justify-center ">
          <Slider {...settings}>
            <div className="!overflow-visible ml-4 flex justify-center items-center ">
              <img
                src={medias.images.mentorship.Media.Media1}
                className="w-[120px] h-[90px]"
                alt=""
              />
            </div>

            <div className="!overflow-visible ml-4 flex justify-center items-center ">
              <img
                src={medias.images.mentorship.Media.Media2}
                alt=""
                className="w-[120px] h-[60px]"
              />
            </div>
            <div className="!overflow-visible ml-4 flex justify-center items-center ">
              <img
                className="w-[120px] h-[60px]"
                src={medias.images.mentorship.Media.Media3}
                alt=""
              />
            </div>
            <div className="!overflow-visible ml-4 flex justify-center items-center ">
              <img
                className="w-[120px] h-[60px]"
                src={medias.images.mentorship.Media.Media4}
                alt=""
              />
            </div>
            <div className="!overflow-visible ml-4 flex justify-center items-center ">
              <img
                className="w-[120px] h-[60px]"
                src={medias.images.mentorship.Media.Media3}
                alt=""
              />
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default MediaSlider;
