import { create } from 'zustand'



export const useIntrestStore = create((set) => ({
    intrestquestions: [],
    addQuestion : (questionInformation : any) => set((state : any) => {
      const index = state.intrestquestions.findIndex((items: any)  => items?.question_id === questionInformation?.question_id)
      delete questionInformation["file"]
      delete questionInformation["options"]
      if(index >= 0){
        let currentQuestionList = [...state.intrestquestions]
        currentQuestionList[index] = questionInformation
        return ({intrestquestions : [...currentQuestionList]})
      }
      else {
        return ({intrestquestions : [...state.intrestquestions, questionInformation]})
      }  
    }),
    removeQuestion : (id : any) => set((state : any) => ({intrestquestions : state.intrestquestions.filter((item:any) => item.id !== id)})),
  }))

  export const usePersonality = create((set) => ({
    personalityquestions: [],
    addPersonalityQuestion : (questionInformation : any) => set((state : any) => {
      const index = state.personalityquestions.findIndex((items: any)  => items?.question_id === questionInformation?.question_id)
      delete questionInformation["file"]
      delete questionInformation["options"]
      if(index >= 0){
        let currentQuestionList = [...state.personalityquestions]
        currentQuestionList[index] = questionInformation
        return ({personalityquestions : [...currentQuestionList]})
      }
      else {
        return ({personalityquestions : [...state.personalityquestions, questionInformation]})
      }
    }),
    removeQuestion : (id : any) => set((state : any) => ({personalityquestions : state.personalityquestions.filter((item:any) => item.id !== id)})),
  }))


  export const useSocialBehaviour = create((set) => ({
    socialBehaviour: [],
    addSocialBehaviour : (questionInfo : any) => set((state : any) => {
      const index = state.socialBehaviour.findIndex((items: any)  => items?.question_id === questionInfo?.question_id)
      delete questionInfo["file"]
      delete questionInfo["options"]
      if(index >= 0){
        let currentQuestionList = [...state.socialBehaviour]
        currentQuestionList[index] = questionInfo
        return ({socialBehaviour : [...currentQuestionList]})
      }
      else {
        return ({socialBehaviour : [...state.socialBehaviour, questionInfo]})
      }
    }),
    removeQuestion : (id : any) => set((state : any) => ({personalityquestions : state.personalityquestions.filter((item:any) => item.id !== id)})),
  }))

  export const useAptitude = create((set) => ({
    apptitude: [],
    submites: [],
    pending : [],
    addApptitude : (questionInfo : any) => set((state : any) => {
      let index
      if(questionInfo?.question_id){
        index = state.apptitude.findIndex((items: any)  => (items?.question_id === questionInfo?.question_id ))
      }else if(questionInfo?.id){
        index = state.apptitude.findIndex((items: any)  => (items?.id === questionInfo?.id ))
      }
      delete questionInfo["file"]
      delete questionInfo["options"]
      delete questionInfo["questionFiles"]
      delete questionInfo["question"]
      delete questionInfo["passage_type"]
      delete questionInfo["passage"]

      const answer = questionInfo?.value
      delete questionInfo["value"]
      if(index >= 0){
        let currentQuestionList = [...state.apptitude]
        currentQuestionList[index] = {...questionInfo, answer}
        return ({apptitude : [...currentQuestionList]})
      }
      else {
        return ({apptitude : [...state.apptitude,  {...questionInfo, answer}]})
      }
    }),
    removeQuestion : (id : any) => set((state : any) => ({personalityquestions : state.personalityquestions.filter((item:any) => item.id !== id)})),
  }))


  
  


