import { medias } from "../../../../config/Resources";

function Benefits({ scholarshipDetails }) {
	return (
		<>
			<div className="pl-3 pr-3 pt-4">
				<p className="font-plusJakartaSans font-bold text-[14px] flex items-center text-center gap-1">
					<img
						src={medias.images.College.Benefits}
						alt="LeftChevron"
						className="w-6 h-6"
					/>{" "}
					<span className="font-plusJakartaSans font-bold text-[14px] mt-[3px]">
						Benefits
					</span>
				</p>
			</div>
			{scholarshipDetails?.map((data) => {
				return (
					<div
						dangerouslySetInnerHTML={{ __html: data?.benefits }}
						className="font-plusJakartaSans font-medium text-[10.5px] text-[#000000] text-opacity-70 pl-3 pr-3 pt-2 pb-4"
					></div>
				);
			})}
		</>
	);
}

export default Benefits;
