/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import axiosInstance from "../../../config/Axios/axiosInstance";
import { API_URLS } from "../../../config/API_URLS";
import Image from "../../Image";
import { medias } from "../../../config/Resources";
import { Controls, Player } from "@lottiefiles/react-lottie-player";
import astronot from "../../../animations/astronot.json";
import planet from "../../../animations/planet.json";
import Questionsirheader from "../questionsircomponents/questionairheader";
import Questions from "../questionsircomponents/questions";
import Timer from "../questionsircomponents/timer";
import Options from "../questionsircomponents/options";
import { CircularProgress, useMediaQuery } from "@mui/material";
import classNames from "classnames";
import { usePersonality } from "../../../store";
import { useUserInfoStore } from "../../../store/userinfo";
import fishAqueriam from "../../../animations/underwater-ocean-fish-and-turtle.json";
import { useNavigate } from "react-router-dom";

const Personality = ({ setOpenNext, next, setNext }: any) => {
  const [page, setPage] = useState(1);
  const limit = 10;
  const desktop = useMediaQuery("(max-width : 1440px)");
  const minBigScreen = useMediaQuery("(min-width : 1440px)");
  const screen1850 = useMediaQuery("(min-width : 1850px)");

  const minScreen = useMediaQuery("(min-width : 1200px)");
  const maxScreen = useMediaQuery("(max-width : 1200px)");

  const navigate = useNavigate();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { personalityquestions, addPersonalityQuestion }: any = usePersonality(
    (state) => state
  );
  const { userInfo, setIsloading }: any = useUserInfoStore((state) => state);

  const [questionLists, setQuestionsList] = useState<any>(null);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const handleFetchQuestion = async (queryKey: any) => {
    return await axiosInstance
      .get(`${API_URLS.getPersonalityQuestionPaper}`)
      .then((res: any) => {
        const currentQuestions: any = questionLists?.result
          ? [...questionLists?.result, ...res?.result]
          : [...res?.result];
        const newValue = { ...res, result: [...currentQuestions] };
        setQuestionsList({ ...newValue });
        return newValue;
      });
  };

  const {
    mutate: submitQuestion,
    isPending,
    isError,
    data: mutationData,
  } = useMutation({
    mutationFn: async () =>
      await axiosInstance
        .post(API_URLS.addStudentPersonalityReport, {
          data: personalityquestions,
          student_id: userInfo?.id,
        })
        .then(async (res) => {
          // await axiosInstance.post("/addParticularCareerReport", {
          //     type:"personality"
          // }).then(res => {
          setNext({
            current: "Personality",
            Next: "Social Behaviour",
            NoOfQuestion: 20,
            Time: 10,
            image: medias.images.congratulations.Personality,
            nextpath: "/psychomatric-test?category=socialBehaviour",
          });
          setOpenNext(true);
          // })
          return res;
        }),
  });
  console.log("isError", isError);

  useEffect(() => {
    setIsloading(isPending);
  }, [isPending]);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      const message =
        "Are you sure? You'll loose all the progress that you have achieved";
      event.returnValue = message; // Standard for most browsers
      return message; // For some older browsers
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const { isLoading: IsIntrestLoading }: { data: any; isLoading: boolean } =
    useQuery({
      queryKey: ["getInterestQuestionPaper", page],
      queryFn: ({ queryKey }) => handleFetchQuestion(queryKey),
      refetchOnMount: true,
      retry: false,
      refetchInterval: false,
    });

  useEffect(() => {
    if (
      questionLists?.total_count - currentQuestion + 1 > 10 &&
      (currentQuestion + 1) % 10 === 2
    ) {
      setPage(page + 1);
    }
  }, [currentQuestion]);

  console.log(
    "minBigScreen",
    minBigScreen,
    "maxScreen",
    maxScreen,
    "screen1850",
    screen1850,
    "minScreen",
    minScreen,
    !minScreen ? "!left-[-100%]" : "!left-[0%]"
  );

  return (
    <div className="flex desktop:items-center  !bg-[#12213B] items-center justify-center h-screen bg-no-repeat bg-cover tab:items-center tab:!h-full">
      <div className="absolute z-0 w-screen h-screen overflow-hidden tab:fixed tab:top-0">
        <Player
          autoplay
          speed={1.5}
          loop
          src={fishAqueriam}
          style={{
            height: desktop ? "100vh" : "119vh",
            padding: 0,
            width: "100vw",
          }}
        >
          <Controls
            visible={false}
            buttons={[
              "play",
              "repeat",
              "frame",
              "debug",
              "snapshot",
              "background",
            ]}
          />
        </Player>
      </div>
      <div className="personalityFrame desktop:w-[72%] desktop:h-80% desktop:!mt-[20px]  desktop:pb-0 desktop:bg-contain z-50 miniDesk:w-[95%] tab:px-[10px] relative bg-contain bg-no-repeat flex tab:pt-[5%] tab:h-auto   pt-[75px] pb-[70px] px-[13px] items-center justify-center w-[72%] h-[90%]">
        {/* <div className='absolute w-[calc(100%-30px)] h-[calc(100%-135px)]  tab:top-[10%]   bg-black bg-opacity-50  top-[calc(0%+85px)] gridBackGround-intrest  backdrop-blur-lg' /> */}
        <div
          className={` w-full  z-50  h-full ${
            maxScreen ? "py-[0px]" : "py-[19px]"
          }  px-[13px]`}
        >
          {/* <Image src={medias.images.intrestImages.SelectorsDrops} alt="SelectorsDrops" className='absolute tab:hidden top-[calc(0%+30px)] left-[calc(0%+15px)]' /> */}

          <div
            className={classNames(
              "flex flex-col justify-between   z-50 ml-[30px]",
              !(!IsIntrestLoading || questionLists?.result?.length > 0) &&
                "h-full"
            )}
          >
            {!IsIntrestLoading || questionLists?.result?.length > 0 ? (
              <>
                <div className={screen1850 ? "mt-3" : ""}>
                  <Questionsirheader
                    questionsInfo={{
                      currentQuestion: `${currentQuestion + 1}`,
                      totalQuestion: `${questionLists?.total_count}`,
                      type: "intrest",
                    }}
                  />
                </div>
                <></>
                <div className="w-full h-full">
                  <Questions
                    questionInfo={questionLists?.result[currentQuestion]}
                    lottieHeight={maxScreen ? "150px" : "185px"}
                    lottieWidth={maxScreen ? "150px" : "200px"}
                  />
                </div>
                <Timer
                  mainClassName="desktop:!mt-auto desktop:!absolute desktop:left-[70%] desktop:!w-fit"
                  imageClass="desktop:!hidden"
                  textClassName="desktop:!static  w-full -right-[33%]  desktop:!px-3 desktop:!py-2 desktop:!text-white desktop:!bg-white desktop:!rounded-full desktop:!bg-opacity-10 desktop:!backdrop-blur-md"
                  isStartTime={true}
                  totalTime={30}
                  bgImage={medias.images.personalityImage.timerbg}
                  timerPositionClass={
                    !minScreen ? "!left-[-100%]" : "!left-[0%]"
                  }
                />
                <div
                  className={`flex justify-center ${
                    maxScreen ? " !mt-[12px] " : ""
                  } desktop:mt-[45px]`}
                >
                  <Options
                    addQestion={addPersonalityQuestion}
                    isSubmitted={isSubmitted}
                    setIsSubmitted={(value: boolean) => {
                      setIsSubmitted(value);
                    }}
                    optionContainerClass="desktop:!w-full miniDesk:w-1/5"
                    optionClass="personalityBtnOption desktop:min-h-fit desktop:max-h-[33px] desktop:!h-fit  miniDesk:min-w-full   !text-[#000] bg-transparent !shadow-none"
                    submitQuestion={submitQuestion}
                    total_question={questionLists?.total_count}
                    currentQuestion={currentQuestion}
                    setCurrentQuestion={setCurrentQuestion}
                    questionInfo={questionLists?.result[currentQuestion]}
                  />
                </div>
              </>
            ) : (
              <div className="flex items-center justify-center w-full h-full">
                <CircularProgress
                  classes={{
                    root: "!text-[#fff]",
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Personality;
