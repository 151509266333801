import React, { useState } from "react";
import CurrentSenerios from "./CurrentSenerios";
import MentalHealth from "./MentalHealth";
import DietCharts from "./DietChart";
import Accordion from "./Accordion";
import Notes from "./Notes";
import FutureGoalCards from "./FutureGoalCards";
import FutureGoals from "./FitureGoals";

function Health({ activeTab }) {
	const [healthType, setHealthType] = useState("Physical");
	const [datas, setData] = useState([]);
	return (
		<div>
			<CurrentSenerios />
			<div className="border-b border-gray-300 mt-16"> </div>
			<MentalHealth />
			<DietCharts setHealthType={setHealthType} healthType={healthType} />
			<div className="w-[100%] flex">
				<div className="w-[70%]">
					<Accordion activeTab={activeTab} healthType={healthType} setData={setData} datas={datas}/>
				</div>
				<div className="w-[30%] mt-10">
					<FutureGoalCards />
					<FutureGoals />
				</div>
			</div>
			<div className=" flex justify-between mt-5 mb-1 ml-2 mr-5">
				<h1 className="ml-8 font-quitery font-normal text-2xl">Notes</h1>
				<h1 className="text-green-500 text-xl font-plusJakartaSans font-semibold">
					Add Notes
				</h1>
			</div>
			<Notes />
		</div>
	);
}

export default Health;
