/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from "react";
import { RiArrowDropDownLine } from "react-icons/ri";
import { FaAngleUp } from "react-icons/fa";

function CoursesAndFeeDetailsOverview({ course }) {
	const [isExpanded, setIsExpanded] = useState(false);

	const toggleReadMore = () => {
		setIsExpanded(!isExpanded);
	};

	return (
		<div>
			<h6 className="mt-5 font-plusJakartaSans font-bold text-[#000000] ml-4 text-[18px]">
				Overview
			</h6>
			<div className="ml-4 mr-4">
				<div className="relative overflow-hidden">
					<div>
						<div
							dangerouslySetInnerHTML={{ __html: course?.course_overview }}
							className={`font-plusJakartaSans font-medium text-[13px] leading-[24px]  text-[#000000] text-opacity-70 mt-1 ${
								!isExpanded ? "line-clamp-5" : ""
							}`}
						></div>

						{!isExpanded && (
							<div className="absolute bottom-0 left-0 w-full h-8 bg-gradient-to-t from-white to-transparent"></div>
						)}
					</div>
				</div>
				<button
					onClick={toggleReadMore}
					className="mt-1 text-blue-500 font-plusJakartaSans font-bold text-[13px] flex items-center"
				>
					{isExpanded ? (
						<>
							Read less
							<FaAngleUp className="w-4 h-4 ml-1" />
						</>
					) : (
						<>
							Read more
							<RiArrowDropDownLine className="w-6 h-6" />
						</>
					)}
				</button>
			</div>
		</div>
	);
}

export default CoursesAndFeeDetailsOverview;
