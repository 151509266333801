/* eslint-disable no-unused-vars */
import React from "react";
import ReactECharts from "echarts-for-react";
const colors = [
  "#E5FC74",
  "#FC7474",
  "#74EAFC",
  "#E9C07E",
  "#FEAA8F",
  "#99D99F",
];

const CareerAnalysisNine = ({ data = {}, dataStream, category }) => {
  //   const [tabData, setTabData] = useState(qualities[0] || {});

  const categories = [
    "Interest",
    "Personality",
    "Social Behaviour",
    "Aptitude ",
  ];
  const values = [
    data?.interestScorePerc,
    data?.personalityScorePerc,
    data?.socialBehScorePerc,
    data?.aptitudeScorePerc,
    data?.socialBehScorePerc,
    data?.aptitudeScorePerc,
  ];

  const option = {
    grid: {
      top: 0, // Set top padding to 0
      bottom: 0, // Set bottom padding to 0
      left: 0, // Adjust left padding if needed
      right: 0, // Adjust right padding if needed
      containLabel: true, // Make sure labels are contained within the chart
    },
    xAxis: {
      type: "value",
      splitLine: {
        show: false, // Hide the grid lines
      },
      axisTick: {
        show: false, // Show the yAxis tick marks
      },
      axisLine: {
        show: false, // Show the yAxis line
      },
    },
    yAxis: {
      type: "category",
      data: category,
      axisLabel: {
        show: true,
        // align: 'left', // Align y-axis labels to the left
        textStyle: {
          color: "black", // Set text color
          fontSize: 15, // Set text font size
          fontWeight: "bold", // Set text font weight
          fontFamily: "Arial", // Set text font family
        },
      },
      axisLine: {
        show: true, // Show the yAxis line
      },
      axisTick: {
        show: false, // Show the yAxis tick marks
      },
      splitLine: {
        show: false, // Hide the grid lines
      },
    },
    series: [
      {
        type: "bar",
        data: dataStream,
        barWidth: 20,
        barGap: 8,
        // label: {
        //   show: true,

        //   position: "left",
        //   formatter: "{c}",
        // },
        itemStyle: {
          borderRadius: [0, 50, 50, 0], // Set borderRadius to make the top rounded
          color: function (params) {
            // Dynamically assign color based on index
            return colors[params.dataIndex];
          },
        },
      },
    ],
  };
  return (
    <div className=" flex mt-[45px] w-[90%] rounded-3xl border-[0px] border-gray-200 shadow-sm mx-[5%] ">
      <div className="flex flex-col   w-[90%] pb-[10px] px-[20px]">
        {/* <Text className="text-[21.4px] font-quitery">Applied Engineering</Text> */}
        <ReactECharts option={option} />
      </div>
    </div>
  );
};

export default CareerAnalysisNine;
