/* eslint-disable jsx-a11y/anchor-is-valid */
import { medias } from "../../config/Resources";

const MentorCard = () => {
  return (
    <div className="mentor_bg_class  bg-cover rounded-3xl pl-4 p-4">
      <h3 className="text-[#241700] tracking-wide PlusJakartaSans-ExtraBoldCustom text-[46px] pt-32   font-bold w-[70%] px-6">
        The UNIGOAL Solution is lot more than Career Counselling & Mentorship
      </h3>
      <a class="relative p-0.1 cursor-pointer  inline-flex items-center justify-center font-bold overflow-hidden group  rounded-2xl m-4 mb-10 ">
        <span class="w-full h-full bg-[#077948]   absolute"></span>
        <span class="relative pl-6  pt-1 transition-all ease-out bg-[#077948] duration-400 justify-center text-center items-center flex ">
          <span class="relative text-white -mt-3 text-[20px] font-normal">
            Get Started
          </span>
          <span>
            <img
              className="w-[70px]"
              src={medias.images.HomeMain.getStartedMentor}
              alt=""
            />
          </span>
        </span>
      </a>
    </div>
  );
};

export default MentorCard;
