/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { medias } from "../../config/Resources";
import CreateNewActivityModal from "./CreateNewActivityModal";

function ActivitiesTable({ activeTab }) {
	const [showModal, setShowModal] = React.useState(false);
	const toggleModal = (item) => {
		setShowModal(!showModal);
	};
	const tableData = [
		{
			Activities: "SAT Studies",
			Time: "6-8AM",
			Status: "Completed",
		},
		{
			Activities: "Academics Schools",
			Time: "7-10AM",
			Status: "Incomplete",
		},
		{
			Activities: "Craft Work",
			Time: "3.5AM",
			Status: "Incomplete",
		},
		{
			Activities: "Recreational Activities",
			Time: "3.5AM",
			Status: "Incomplete",
		},
		{
			Activities: "Craft",
			Time: "3.5AM",
			Status: "Incomplete",
		},
		{
			Activities: "Mining",
			Time: "3.5AM",
			Status: "Incomplete",
		},
	];

	return (
		<>
			<div className="mt-10 flex justify-end items-center">
				<button
					class="border flex items-center gap-2 justify-center border-blue-600 text-blue-600 px-4 py-2 rounded-lg text-xl font-normal font-quitery"
					onClick={() => toggleModal()}
				>
					<img
						className="w-5 h-5 mt-[-2px]"
						src={medias.images.Profile.Plus}
						alt="plus"
					/>
					Add New
				</button>
			</div>
			<div className="relative overflow-x-auto mt-8 border-[#CF3515] border rounded-[10px]">
				<table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
					<thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
						<tr>
							<th
								scope="col"
								className="px-6 py-3 text-xs font-semibold font-plusJakartaSans"
							>
								Activities
							</th>
							<th
								scope="col"
								className="px-6 py-3 text-xs font-semibold font-plusJakartaSans"
							>
								Time
							</th>
							<th
								scope="col"
								className="px-6 py-3 text-xs font-semibold font-plusJakartaSans"
							>
								Status
							</th>
							<th
								scope="col"
								className="px-6 py-3 text-xs font-semibold font-plusJakartaSans"
							>
								<span className="flex align-center items-center justify-center">
									Action
								</span>
							</th>
							<th
								scope="col"
								className="px-6 py-3 text-xs font-semibold font-plusJakartaSans"
							>
								<span className="flex align-center items-center justify-center">
									Comments
								</span>
							</th>
						</tr>
					</thead>
					<tbody>
						{tableData?.map((item, index) => (
							<tr
								key={index}
								className={
									index % 2 === 0
										? "bg-[#FFF2F1] dark:bg-gray-800"
										: "bg-[#FFECE5]"
								}
							>
								<td className="px-6 py-4 item-[#000000] text-xs font-semibold font-plusJakartaSans">
									{item.Activities}
								</td>
								<td className="px-6 py-4 text-xs font-semibold font-plusJakartaSans">
									{item.Time}
								</td>
								<td>
									<div
										className={`flex text-xs font-semibold font-plusJakartaSans align-center justify-center items-center h-8 rounded-[10px] ${
											item.Status === "Completed"
												? "bg-green-200"
												: "bg-red-200"
										}`}
									>
										{item.Status}
									</div>
								</td>
								<td>
									<div className="flex gap-3 align-center justify-center items-center">
										<img
											className="w-5 h-5"
											src={medias.images.Profile.Correct}
											alt="correct"
										/>
										<img
											className="w-5 h-5"
											src={medias.images.Profile.Cross}
											alt="cross"
										/>
									</div>
								</td>
								<td>
									<span className="text-xs font-semibold font-plusJakartaSans flex align-center items-center justify-center">
										<a className="border-b border-[black]">View</a>
									</span>
								</td>
							</tr>
						))}
						<CreateNewActivityModal
							showModal={showModal}
							toggleModal={toggleModal}
							setShowModal={setShowModal}
							activeTab={activeTab}
						/>
					</tbody>
				</table>
			</div>
		</>
	);
}

export default ActivitiesTable;
