import React, { useState } from "react";
import { medias } from "../../../config/Resources";

const BlogCareerClusterSelect = () => {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<div className="relative inline-block text-left">
			<div>
				<button
					type="button"
					className="inline-flex justify-between w-full px-4 py-[7px] text-[14px] font-medium text-gray-700 bg-white border border-opacity-[33%] border-[#707070] rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
					onClick={() => setIsOpen(!isOpen)}
				>
					<p className="flex items-center font-plusJakartaSans text-[#000000] gap-1 font-medium">
						<img
							className="w-6 h-6 mt-[1px]"
							src={medias.images.Blogs.Cap}
							alt="Bachelors"
						/>
						<span className="font-plusJakartaSans text-[#000000] gap-1 font-medium ml-[3px]">
							Career cluster
						</span>
					</p>
					<svg
						className="w-6 h-6 ml-[2px] mt-[1px] text-[#000000] text-opacity-50 -mr-1"
						viewBox="0 0 20 20"
						fill="currentColor"
					>
						<path
							fillRule="evenodd"
							d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
							clipRule="evenodd"
						/>
					</svg>
				</button>
			</div>

			{isOpen && (
				<div className="absolute right-0 w-56 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5">
					{/* Add your dropdown menu items here */}
				</div>
			)}
		</div>
	);
};

export default BlogCareerClusterSelect;
