/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import Lottie from "lottie-react";
import groovyWalkAnimation from "../../../../animations/roadmap/Animation - 1720078862954.json";
import support from "../../../../animations/roadmap/Button Press.json";
import { useEffect, useState } from "react";
import { API_URLS } from "../../../../config/API_URLS";
import axiosInstance from "../../../../config/Axios/axiosInstance";
import { useNavigate } from "react-router-dom";
import { useUserInfoStore } from "../../../../store/userinfo";

const arrDaignol = ["", "", "", "", "", "", "", "", "", "", "", "", ""];

const ViewPage = () => {
  // eslint-disable-next-line no-unused-vars
  const [translateValue, setTranslateValue] = useState("0px");
  const [mileIndex, setMileIndex] = useState(0);
  const [taskIndex, setTaskIndex] = useState(0);

  const [mileData, setMileData] = useState([]);
  const [taskData, setTaskData] = useState([]);
  const [subTaskData, setSubTaskData] = useState([]);

  const [goalData, setGoalData] = useState([]);
  const [selectedGoal, setSelectedGoal] = useState("1");
  const [selectedMap, setSelectedMap] = useState("Academic Roadmap");
  const { userInfo } = useUserInfoStore((state) => state);

  const navigate = useNavigate();
  console.log("selectedGoal", selectedGoal);

  useEffect(() => {
    if (mileData?.length) {
      setTranslateValue("1100px");
    }
  }, [mileData]);

  useEffect(() => {
    (async () => {
      let url =
        selectedMap == dropData[0]
          ? "/pb/getAcademicRoadmap"
          : selectedMap == dropData[1]
          ? "/pb/getExtraCurricularRoadmap"
          : selectedMap == dropData[2]
          ? "/getPassionRoadmap"
          : selectedMap == dropData[3]
          ? "/pb/getHealthRoadmap"
          : "/pb/getAcademicRoadmap";
      const res = await axiosInstance.get(
        API_URLS.baseURL + url + `?type=${"milestone"}&searchId=${selectedGoal}`
      );

      if (res.statusCode === 200) {
        setMileData(res?.result || []);
      } else {
        setMileData([]);
      }
      console.log("res mile", res);
    })();
  }, [selectedGoal, selectedMap]);

  useEffect(() => {
    getGoal();
  }, [selectedMap]);

  const getGoal = async (searchId) => {
    setGoalData([]);
    let url =
      selectedMap == dropData[0]
        ? "/pb/getAcademicRoadmap"
        : selectedMap == dropData[1]
        ? "/pb/getExtraCurricularRoadmap"
        : selectedMap == dropData[2]
        ? "/getPassionRoadmap"
        : selectedMap == dropData[3]
        ? "/pb/getHealthRoadmap"
        : "/pb/getAcademicRoadmap";

    const res = await axiosInstance.get(
      API_URLS.baseURL + url + `?type=${""}&searchId=${""}`
    );
    if (res.statusCode === 200) {
      setGoalData(res?.result || []);
      setSelectedGoal(
        res?.result && selectedMap == dropData[0]
          ? res?.result[0]?.academic_id
          : selectedMap == dropData[1]
          ? res?.result[0]?.extra_curricular_id
          : selectedMap == dropData[2]
          ? res?.result[0]?.passion_id
          : selectedMap == dropData[3]
          ? res?.result[0]?.health_id
          : res?.result[0]?.academic_id
      );
    } else {
      setGoalData([]);
    }
    console.log(res);
  };

  const getActivity = async (searchId) => {
    setTaskData([]);
    let url =
      selectedMap == dropData[0]
        ? "/pb/getAcademicRoadmap"
        : selectedMap == dropData[1]
        ? "/pb/getExtraCurricularRoadmap"
        : selectedMap == dropData[2]
        ? "/getPassionRoadmap"
        : selectedMap == dropData[3]
        ? "/pb/getHealthRoadmap"
        : "/pb/getAcademicRoadmap";
    const res = await axiosInstance.get(
      API_URLS.baseURL + url + `?type=${"activity"}&searchId=${searchId}`
    );
    if (res.statusCode === 200) {
      setTaskData(res?.result || []);
    } else {
      setTaskData([]);
    }
    console.log(res);
  };

  const getSubTask = async (searchId) => {
    setSubTaskData([]);
    let url =
      selectedMap == dropData[0]
        ? "/pb/getAcademicRoadmap"
        : selectedMap == dropData[1]
        ? "/pb/getExtraCurricularRoadmap"
        : selectedMap == dropData[2]
        ? "/getPassionRoadmap"
        : selectedMap == dropData[3]
        ? "/pb/getHealthRoadmap"
        : "/pb/getAcademicRoadmap";
    const res = await axiosInstance.get(
      API_URLS.baseURL + url + `?type=${"subTasks"}&searchId=${searchId}`
    );
    if (res.statusCode === 200) {
      setSubTaskData(res?.result || []);
    } else {
      setSubTaskData([]);
    }
    console.log(res);
  };

  const dropData = [
    "Academic Roadmap",
    "Extra Curricular",
    "Passion",
    "Health",
  ];

  return (
    <div className="academic_bg_2 bg-cover  h-auto flex flex-col justify-between w-screen pt-10">
      {/* topbar */}
      <div className="h-[5%]">
        <div className=" 1240:h-[82px] 1500:h-[100px] academic_wood_1 bg-cover w-[100%] pt-10">
          <div className="flex justify-center items-center  mt-[-70px] ">
            <div className="academic_side_top 1240:ml-[100px] 1500:ml-0 1240:mt-[4px] 1500:mt-0 1240:w-[438px] 1240:h-[63px] 1500:w-[600px] 1500:h-[80px] bg-cover">
              <div className="text-white cursor-pointer mt-5 1240:mt-3 1440:mt-4 1500:mt-5 flex justify-center gap-x-2 1240:text-[18px]  1440:text-[24px] 1500:text-[30px]  font-bold ">
                {" "}
                <select
                  className="w-[45%] bg-transparent text-center rounded-lg focus-visible:border-none focus-visible:outline-none !border-none"
                  onChange={(e) => {
                    setSelectedMap(e.target.value);
                  }}
                >
                  {dropData?.map((drop, i) => {
                    return (
                      <option
                        key={i}
                        className="bg-[#DE8E39] text-[20px]"
                        value={drop}
                      >
                        {drop}
                      </option>
                    );
                  })}
                </select>
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/unigoal-15ff4.appspot.com/o/Path%20334423.svg?alt=media&token=11e31103-485f-406b-8175-d20b06002b39"
                  alt=""
                />
              </div>
            </div>
          </div>

          <div className="flex mt-[-38px]">
            <div className="flex justify-between px-20 1240:px-10 1366:px-12  mt-14 1500:px-20 1240:mt-4 1500:mt-2 w-full">
              <div>
                <div className="flex gap-x-3 ml-4">
                  <div className="w-12 h-12 rounded-full border-white border-solid border-[1px] bg-cyan-600">
                    <img src="" alt="" />
                  </div>
                  <div className="1500:w-72 1500:h-12 1366:w-60 1366:h-10 rounded-full  !shadow-[0px_4px_0px_0px_rgba(0,0,0,1)] bg-[#f4ee90]  uppercase 1366:text-[18px] 1500:text-[23px] font-bold text-center my-auto flex flex-col justify-center items-center">
                    <h6 className=""> Daily Activity Planer</h6>
                  </div>
                  <div className="px-4 h-12 rounded-full border-white border-solid border-[1px] bg-white flex justify-evenly">
                    <img
                      className="scale-75"
                      src="https://firebasestorage.googleapis.com/v0/b/unigoal-15ff4.appspot.com/o/Group%20413414.svg?alt=media&token=4bf39622-6436-4d62-b7af-7cdaa6fc0dcf"
                      alt=""
                    />
                    <img
                      className="scale-75"
                      src="https://firebasestorage.googleapis.com/v0/b/unigoal-15ff4.appspot.com/o/Group%20414170.svg?alt=media&token=b67a62fb-40e8-4c0c-b9e8-1c487d137fee"
                      alt=""
                    />
                    <img
                      className="scale-75"
                      src="https://firebasestorage.googleapis.com/v0/b/unigoal-15ff4.appspot.com/o/Group%20413410.svg?alt=media&token=80e27a45-c984-4f44-9ad7-c86994cb61ef"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="w-[33%] ">
                <div className="flex items-end justify-end ">
                  <div className="w-12 h-12 rounded-full ">
                    <Lottie
                      className="cursor-pointer "
                      animationData={groovyWalkAnimation}
                      loop={true}
                    />
                  </div>
                  <div className="w-12 h-12 rounded-full ml-8  ">
                    <img
                      className="cursor-pointer"
                      src="https://firebasestorage.googleapis.com/v0/b/unigoal-15ff4.appspot.com/o/Group%20413503%402x.png?alt=media&token=764ef9f0-dcac-4551-8fd6-af7bb4cfd9e6"
                      alt=""
                    />
                  </div>

                  <div className="w-64  h-10  rounded-full  relative uppercase text-[23px] font-bold text-center  flex flex-col justify-center items-center">
                    <Lottie
                      className="cursor-pointer absolute"
                      animationData={support}
                      loop={true}
                    />

                    <h5 className="text-white text-2xl relative top-0 cursor-pointer">
                      Support
                    </h5>
                  </div>

                  <div className="w-12 h-12 rounded-full">
                    <img
                      onClick={() => {
                        navigate(-1);
                      }}
                      className="scale-75 cursor-pointer"
                      src="https://firebasestorage.googleapis.com/v0/b/unigoal-15ff4.appspot.com/o/Group%20413517.svg?alt=media&token=57a1c0d8-a313-4757-9dd7-cb75234f9f2e"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* upper center */}

      <div className="w-ful flex justify-around items-center mt-[-24px]">
        <div className="flex gap-x-10 1500:gap-x-10 1240:gap-x-7 justify-between items-center">
          <div className=" p-5 flex flex-col items-center  justify-center  bg-cover 1500:w-[370px] 1500:h-[130px] 1366:w-[300px] 1366:h-[103px] cloud_bg  ">
            <div className="flex gap-x-2">
              <h5 className="text-[29px] 1500:text-[29px] 1366:text-[24px] font-bold">
                Watch{" "}
              </h5>
              <img
                className="w-8 h-8 cursor-pointer"
                src="https://firebasestorage.googleapis.com/v0/b/unigoal-15ff4.appspot.com/o/Academic%2FGroup%20414201.png?alt=media&token=bfad8f37-ec1f-4d51-92d7-3788d1450c6e"
                alt=""
              />
              <h5 className="text-[29px] 1500:text-[29px] 1366:text-[24px] font-bold">
                {" "}
                Roadmap
              </h5>
            </div>
            <h5 className="text-[21px] 1500:text-[21px] 1240:text-[19px]: font-semibold text-gray-700">
              Explanation / Video
            </h5>
          </div>
          <img
            className=" 1500:h-20 1366:h-16 cursor-pointer"
            src="https://firebasestorage.googleapis.com/v0/b/unigoal-15ff4.appspot.com/o/Academic%2FGroup%20414200.png?alt=media&token=7645c8ae-db90-4ee8-918d-297e72bcc19a"
            alt=""
          />
          <div className=" text-start bg-cover w-[708px] h-[60px]  1500:w-[708px] 1500:h-[60px]  1240:w-[639px] 1240:h-[57px] centerGoalStreap  ">
            <div className="text-white text-[25px] mt-[17px] mx-auto text-center font-bold">
              {" "}
              Goal: Get 92 % in class 12th
            </div>
          </div>
        </div>{" "}
        <div className="flex justify-between items-center">
          <div className="flex justify-end  bg-cover w-[298px] h-[244px] academic_side_wood  ">
            <div className="mt-[60px] w-full mx-4">
              <h6 className="text-white text-[20px] font-bold">Goal:</h6>
              <div className="w-full mt-1.5  h-9 rounded-lg   border-white border-solid border-[1px] bg-white mb-4  font-bold text-center flex flex-col justify-center items-center">
                <select
                  className="w-full bg-white focus-visible:border-none focus-visible:outline-none"
                  onChange={(e) => {
                    setSelectedGoal(e.target.value);
                  }}
                >
                  {goalData?.map((goal, i) => {
                    return (
                      <option
                        className="text-center"
                        key={i}
                        value={
                          goal?.academic_id ||
                          goal?.passion_id ||
                          goal?.extra_curricular_id ||
                          goal?.health_id
                        }
                      >
                        {goal?.main_goal ||
                          goal?.category ||
                          goal?.category_type}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="flex justify-between ">
                <div>
                  <h6 className="text-white text-[17px] ">Total 4 Milestone</h6>
                  <h6 className="text-white text-[17px] ">17 Tasks</h6>
                  <h6 className="text-white text-[17px] ">17 Sub Tasks</h6>
                </div>
                <div className="flex flex-col justify-end items-end">
                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/unigoal-15ff4.appspot.com/o/Layer%201.svg?alt=media&token=8003ddf9-894d-443f-863d-fd967fe6e0e6"
                    alt=""
                  />
                  <h6 className="text-white text-[16px]  cursor-pointer">
                    View
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* center */}
      <div>
        <div className="flex flex-col m-auto p-auto">
          <div className="flex flex-col overflow-x-scroll pt-11 hide-scroll-bar">
            <div className="flex flex-nowrap lg:ml-10 md:ml-8 ml-5 ">
              {mileData?.map((mile, i) => {
                return (
                  <div key={i} className=" flex items-center  ">
                    <div className="w-[450px] h-[450px] 1240:w-[350px] 1240:h-[350px] 1500:w-[450px] 1500:h-[450px] flex  rounded-lg ">
                      <div
                        className="bg-white w-[90%]  cursor-pointer flex flex-col justify-between rounded-3xl  p-2 h-full"
                        onClick={() => {
                          setMileIndex(i + 1);
                          getActivity(mile?.acad_milestone_id);
                        }}
                      >
                        <div>
                          <div className="flex justify-between h-32 mt-[-40px] z-[9999]   ">
                            <img
                              className=""
                              src="https://firebasestorage.googleapis.com/v0/b/unigoal-15ff4.appspot.com/o/Academic%2FGroup%20414301.png?alt=media&token=9f4cfdf2-46f2-42bd-b2f3-c27d1c0524fa"
                              alt=""
                            />

                            <div className="flex w-full justify-between items-center gap-x-1">
                              <div className="flex gap-x-3">
                                <img
                                  className="w-7"
                                  src="https://firebasestorage.googleapis.com/v0/b/unigoal-15ff4.appspot.com/o/Academic%2FGroup%20414178.png?alt=media&token=9e5415f5-9fe5-479e-8803-4053c03b4f93"
                                  alt=""
                                />
                                <img
                                  className="w-7"
                                  src="https://firebasestorage.googleapis.com/v0/b/unigoal-15ff4.appspot.com/o/Academic%2FLayer%202.png?alt=media&token=fa6631dd-20bf-42d3-aef7-dc1c6ee13650"
                                  alt=""
                                />
                              </div>
                              <div className="flex gap-x-3">
                                <img
                                  className="w-7"
                                  src="https://firebasestorage.googleapis.com/v0/b/unigoal-15ff4.appspot.com/o/Academic%2FGroup%20414177.png?alt=media&token=3c5820a4-46e7-402a-a491-f086376b5dcb"
                                  alt=""
                                />
                                <img
                                  className="w-6 h-8"
                                  src="https://firebasestorage.googleapis.com/v0/b/unigoal-15ff4.appspot.com/o/Academic%2FLayer_2_00000025421879485180258910000003013464530197665164_.png?alt=media&token=944e68af-5c92-4c57-9958-05c66d8f74e6"
                                  alt=""
                                />
                              </div>
                            </div>
                            <img
                              className=""
                              src="https://firebasestorage.googleapis.com/v0/b/unigoal-15ff4.appspot.com/o/Academic%2F%D0%A1%D0%BB%D0%BE%D0%B9_1.png?alt=media&token=f51f454e-bca2-47d2-8bd5-e3052807a53f"
                              alt=""
                            />
                          </div>

                          <div>
                            <div>
                              <div>
                                <a className="relative  items-center justify-start inline-block px-5 w-full text-center  py-3  1240:py-2 1500:py-3 overflow-hidden border-[2px] border-[#DE8E39] font-medium transition-all bg-[#FFEFDE] rounded-2xl hover:bg-white group">
                                  <span className="absolute inset-0 border-0  group-hover:border-[25px] ease-linear duration-100 transition-all border-white rounded-full"></span>
                                  <span className="relative w-full text-left text-[28px] 1500:text-[28px] 1240:text-[24px] font-bold text-nowrap text-[#754a1d] transition-colors duration-200 ease-in-out group-hover:text-black">
                                    Score {mile?.percentage || 0}% in Unit Test
                                  </span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* {console.log(
                          mile.countObj &&
                            JSON.parse(mile?.countObj)?.activity_count
                        )} */}
                        {/* home icons sub  */}
                        <div className="flex gap-x-7  overflow-x-scroll  hide-scroll-bar px-3 ">
                          {mile?.arrayCount &&
                            JSON.parse(mile?.arrayCount).map((item, i) => {
                              return (
                                <div key={i} className="flex flex-col w-20">
                                  <div className="text-[#814e18] text-[18px] 1500:text-[20px] 1240:text-[18px]  font-bold text-center">
                                    T{i + 1}
                                  </div>
                                  <img
                                    className="w-[75%] 1240:w-[72%] 1500:w-[75%]"
                                    src="https://firebasestorage.googleapis.com/v0/b/unigoal-15ff4.appspot.com/o/Academic%2FGroup%20414141.png?alt=media&token=6b3bf650-a977-434d-a084-8ded2b9d92c0"
                                    alt=""
                                  />

                                  {/* {i < 2 && ( */}
                                  <div>
                                    <div className="w-[2.7px] h-[17px] 1240:h-[15px] 1500:h-[17px] bg-[#3b240b] ml-5"></div>
                                    <a className="relative  items-center justify-start inline-block px-2 w-24 text-center   overflow-hidden border-[2px] border-[#DE8E39] font-medium transition-all bg-[#FFEFDE] rounded-lg  group">
                                      <span className="absolute inset-0 border-0  ease-linear duration-100 transition-all border-white rounded-full"></span>
                                      <span className="relative w-full font-bold text-left text-nowrap text-[#DE8E39] transition-colors duration-200 ease-in-out ">
                                        {item?.sub_tasks} Sub 1
                                      </span>
                                    </a>
                                  </div>
                                  {/* )} */}
                                </div>
                              );
                            })}
                        </div>
                        {/* notes */}
                        <div className="flex justify-center items-center">
                          <div>
                            <a className="relative  items-center justify-start inline-block px-5  w-28 1240:w-28 1500:w-32 text-center  py-2 1240:py-1.5 1500:py-2 overflow-hidden border-[2px] border-black font-medium transition-all bg-[#EFEFEF] rounded-2xl hover:bg-white group">
                              <span className="absolute inset-0 border-0  group-hover:border-[25px] ease-linear duration-300 transition-all border-white rounded-full"></span>
                              <span className="relative w-full text-left text-black transition-colors duration-500 ease-in-out group-hover:text-blue-600">
                                Notes
                              </span>
                            </a>
                          </div>
                        </div>

                        {/* bottom buttons */}
                        <div className="flex justify-between ">
                          <div>
                            <a
                              href=""
                              className="relative  items-center justify-start inline-block px-5  1500:w-32 text-center  py-2 overflow-hidden border-[2px] border-[#00178E] font-medium transition-all bg-[#96A7FF] rounded-full hover:bg-white group"
                            >
                              <span className="absolute inset-0 border-0  group-hover:border-[25px] ease-linear duration-300 transition-all border-white rounded-full"></span>
                              <span className="relative w-full text-left text-white transition-colors duration-500 ease-in-out group-hover:text-blue-600">
                                View
                              </span>
                            </a>
                          </div>
                          <div>
                            <a className="relative  items-center justify-start   1500:w-32 text-center inline-block px-5 py-2 overflow-hidden border-[2px] border-[#00178E] font-medium transition-all bg-[#96A7FF] rounded-full hover:bg-white group">
                              <span className="absolute inset-0 border-0 group-hover:border-[25px] ease-linear duration-300 transition-all border-white rounded-full"></span>
                              <span className="relative w-full text-left text-white transition-colors duration-500 ease-in-out group-hover:text-blue-600">
                                Status
                              </span>
                            </a>
                          </div>{" "}
                          <div>
                            <a className="relative  items-center justify-start  1500:w-32 text-center inline-block px-5 py-2 overflow-hidden border-[2px] border-[#00178E] font-medium transition-all bg-[#96A7FF] rounded-full hover:bg-white group">
                              <span className="absolute inset-0 border-0 group-hover:border-[25px] ease-linear duration-300 transition-all border-white rounded-full"></span>
                              <span className="relative w-full text-left text-white transition-colors duration-500 ease-in-out group-hover:text-blue-600">
                                Comment
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="  flex justify-center items-center w-[10%] h-full">
                        <img
                          src="https://firebasestorage.googleapis.com/v0/b/unigoal-15ff4.appspot.com/o/Academic%2FGroup%20414197.png?alt=media&token=adbd3c92-e894-4972-9304-7a3f1f66726e"
                          alt=""
                        />
                      </div>
                    </div>

                    {mileIndex == i + 1 &&
                      taskData?.map((task, i) => {
                        return (
                          <div key={i} className="flex  ">
                            <div className="w-[270px] h-[230px] flex  rounded-lg ">
                              <div
                                className="bg-white cursor-pointer border-blue-500 border-[2px] border-solid w-[90%] flex flex-col justify-between rounded-3xl  p-2 h-full"
                                onClick={() => {
                                  getSubTask(task?.acad_activity_id);
                                  setTaskIndex(i + 1);
                                }}
                              >
                                <div>
                                  <div className="flex   justify-between h-28 mt-[-60px] z-[9999]   ">
                                    <div></div>
                                    <img
                                      className="scale-x-110"
                                      src="https://firebasestorage.googleapis.com/v0/b/unigoal-15ff4.appspot.com/o/leaf.png?alt=media&token=45467aad-e1bc-4ba8-9317-cbf4da3754cf"
                                      alt=""
                                    />
                                    <div className="flex flex-col justify-center">
                                      {" "}
                                      <img
                                        src="https://firebasestorage.googleapis.com/v0/b/unigoal-15ff4.appspot.com/o/Layer%201.svg?alt=media&token=8003ddf9-894d-443f-863d-fd967fe6e0e6"
                                        alt=""
                                      />{" "}
                                    </div>
                                  </div>
                                  <div className="text-[#814e18] text-[20px] font-bold text-center">
                                    {task?.main_task}
                                  </div>
                                </div>
                                {/* home icons sub  */}
                                <div className="flex justify-between font-bold ">
                                  <div>
                                    <h6 className="text-black text-[17px] ">
                                      Time Start
                                    </h6>
                                    <h6 className="text-black text-[17px] ">
                                      Duration End
                                    </h6>
                                  </div>
                                  <div className="flex flex-col justify-end items-end">
                                    <h6 className="text-black text-[16px]  cursor-pointer">
                                      {task?.start_date}
                                    </h6>
                                    <h6 className="text-black text-[16px]  cursor-pointer">
                                      {task?.end_date}
                                    </h6>
                                  </div>
                                </div>
                                {/* bottom buttons */}
                                <div className="w-full ">
                                  <div>
                                    <a className="relative  items-center justify-start  w-full text-center inline-block px-5 py-2 overflow-hidden border-[2px] border-[#00178E] font-medium transition-all bg-[#96A7FF] rounded-full hover:bg-white group">
                                      <span className="absolute inset-0 border-0 group-hover:border-[25px] ease-linear duration-300 transition-all border-white rounded-full"></span>
                                      <span className="relative w-full text-left text-white transition-colors duration-500 ease-in-out group-hover:text-blue-600">
                                        SubTask
                                      </span>
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div className="  flex justify-center items-center w-[10%] h-full">
                                <img
                                  src="https://firebasestorage.googleapis.com/v0/b/unigoal-15ff4.appspot.com/o/Academic%2FGroup%20414197.png?alt=media&token=adbd3c92-e894-4972-9304-7a3f1f66726e"
                                  alt=""
                                />
                              </div>
                            </div>
                            {taskIndex == i + 1 &&
                              subTaskData?.map((task, i) => {
                                return (
                                  <div key={i} className="inline-block  ">
                                    <div className="w-[270px] h-[230px] flex  rounded-lg ">
                                      <div className="bg-white cursor-pointer border-blue-500 border-[2px] border-solid w-[90%] flex flex-col justify-between rounded-3xl  p-2 h-full">
                                        <div>
                                          <div className="flex justify-between h-20 mt-[-30px] z-[9999]   ">
                                            <img
                                              className=""
                                              src="https://firebasestorage.googleapis.com/v0/b/unigoal-15ff4.appspot.com/o/Academic%2FGroup%20414301.png?alt=media&token=9f4cfdf2-46f2-42bd-b2f3-c27d1c0524fa"
                                              alt=""
                                            />

                                            <div className="flex w-[80%] justify-evenly items-center mx-1">
                                              <div className="flex ">
                                                <img
                                                  className="scale-50"
                                                  src="https://firebasestorage.googleapis.com/v0/b/unigoal-15ff4.appspot.com/o/Academic%2FGroup%20414178.png?alt=media&token=9e5415f5-9fe5-479e-8803-4053c03b4f93"
                                                  alt=""
                                                />
                                                <img
                                                  className="scale-50"
                                                  src="https://firebasestorage.googleapis.com/v0/b/unigoal-15ff4.appspot.com/o/Academic%2FLayer%202.png?alt=media&token=fa6631dd-20bf-42d3-aef7-dc1c6ee13650"
                                                  alt=""
                                                />
                                              </div>
                                              <div className="flex ">
                                                <img
                                                  className="scale-50"
                                                  src="https://firebasestorage.googleapis.com/v0/b/unigoal-15ff4.appspot.com/o/Academic%2FGroup%20414177.png?alt=media&token=3c5820a4-46e7-402a-a491-f086376b5dcb"
                                                  alt=""
                                                />
                                                <img
                                                  className="scale-50 -ml-2"
                                                  src="https://firebasestorage.googleapis.com/v0/b/unigoal-15ff4.appspot.com/o/Academic%2FLayer_2_00000025421879485180258910000003013464530197665164_.png?alt=media&token=944e68af-5c92-4c57-9958-05c66d8f74e6"
                                                  alt=""
                                                />
                                              </div>
                                            </div>
                                            <img
                                              className=""
                                              src="https://firebasestorage.googleapis.com/v0/b/unigoal-15ff4.appspot.com/o/Academic%2F%D0%A1%D0%BB%D0%BE%D0%B9_1.png?alt=media&token=f51f454e-bca2-47d2-8bd5-e3052807a53f"
                                              alt=""
                                            />
                                          </div>
                                          <div className="text-[#814e18] text-[20px] font-bold text-center">
                                            {task?.sub_task_name}
                                          </div>
                                        </div>
                                        {/* notes */}
                                        <div className="flex justify-center items-center">
                                          <div>
                                            <a className="relative  items-center justify-start inline-block px-5 w-32 text-center  py-2 overflow-hidden border-[2px] border-black font-medium transition-all bg-[#EFEFEF] rounded-2xl hover:bg-white group">
                                              <span className="absolute inset-0 border-0  group-hover:border-[25px] ease-linear duration-300 transition-all border-white rounded-full"></span>
                                              <span className="relative w-full text-left text-black transition-colors duration-500 ease-in-out group-hover:text-blue-600">
                                                Notes
                                              </span>
                                            </a>
                                          </div>
                                        </div>
                                        {/* bottom buttons */}
                                        <div className="w-full flex justify-around gap-x-2 ">
                                          <div>
                                            <a className="relative  items-center justify-start  w-full text-center inline-block px-5 py-2 overflow-hidden border-[2px] border-[#00178E] font-medium transition-all bg-[#96A7FF] rounded-full hover:bg-white group">
                                              <span className="absolute inset-0 border-0 group-hover:border-[25px] ease-linear duration-300 transition-all border-white rounded-full"></span>
                                              <span className="relative w-full text-left text-white transition-colors duration-500 ease-in-out group-hover:text-blue-600">
                                                Status
                                              </span>
                                            </a>
                                          </div>
                                          <div>
                                            <a className="relative  items-center justify-start  w-full text-center inline-block px-5 py-2 overflow-hidden border-[2px] border-[#00178E] font-medium transition-all bg-[#96A7FF] rounded-full hover:bg-white group">
                                              <span className="absolute inset-0 border-0 group-hover:border-[25px] ease-linear duration-300 transition-all border-white rounded-full"></span>
                                              <span className="relative w-full text-left text-white transition-colors duration-500 ease-in-out group-hover:text-blue-600">
                                                Comment
                                              </span>
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="  flex justify-center items-center w-[10%] h-full">
                                        <img
                                          src="https://firebasestorage.googleapis.com/v0/b/unigoal-15ff4.appspot.com/o/Academic%2FGroup%20414197.png?alt=media&token=adbd3c92-e894-4972-9304-7a3f1f66726e"
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        );
                      })}
                  </div>
                );
              })}
            </div>

            <div className="flex flex-col w-full ">
              {/* train image */}
              <img
                className="w-[370px] h-32 animate-slowTranslate"
                src="https://firebasestorage.googleapis.com/v0/b/unigoal-15ff4.appspot.com/o/Academic%2Ftrain.png?alt=media&token=e842623c-81ee-4e7a-a350-6f3b0764a7f5"
                alt="train"
                style={{ "--translate-x-end": translateValue }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="w-full mt-[-15px] ">
        {/* track */}
        <img
          src="https://firebasestorage.googleapis.com/v0/b/unigoal-15ff4.appspot.com/o/Academic%2Ftrack.png?alt=media&token=4a558d63-53f2-4ae1-b124-aee3bf8bf1b9"
          alt="track"
        />
      </div>
      {/* bottom */}
      <div className="h-[20%]">
        <div className="mb-3">
          <div className="flex justify-between  px-[5%]">
            <div className="w-[20%]">
              <div className="h-20"></div>

              <div className="w-full h-20 rounded-full   bg-[#96A7FF]  border-[#00178E] border-solid border-[2px] text-white   text-[23px] font-bold text-center my-auto flex flex-col justify-center items-center">
                <h6 className="cursor-pointer">Progress Tracker</h6>
              </div>
            </div>
            <div className="w-[50%]">
              <div className="w-56 h-14 rounded-full  border-white border-solid border-[1px] bg-white mb-4  font-bold text-center my-auto flex flex-col justify-center items-center">
                {" "}
                <h6 className="text-[22px] font-bold "> Progress 3/9</h6>
              </div>
              <div className="w-full h-20 rounded-full   bg-[#00178E] bg-opacity-40 px-2   text-white   text-[23px] font-bold text-center my-auto flex flex-col justify-center items-start">
                <div className="bg-gradient-to-r from-indigo-700 to-indigo-500  rounded-3xl w-[70%] h-[52px] flex justify-around">
                  {arrDaignol.map((ele, i) => {
                    return (
                      <div
                        key={i}
                        className="flex justify-center items-center gap-x-2 "
                      >
                        <div className="h-full w-[1.5px] bg-white rotate-[26deg] bg-opacity-75"></div>
                        {arrDaignol.length - 1 == i && (
                          <div className="bg-transparent rounded-3xl px-10  border-white border-solid border-[4px]">
                            <h6>70%</h6>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>{" "}
            </div>

            <div className="w-[20%]">
              <div className="h-20"></div>
              <div className="w-full h-20 cursor-pointer rounded-full   bg-[#96A7FF]  border-[#000000] border-solid border-[2px] text-white   text-[23px] font-bold text-center my-auto flex flex-col justify-center items-center">
                <h6 className="">Preview Roadmap</h6>
              </div>{" "}
            </div>

            <div className="w-[6%]">
              <div className="h-20"></div>

              <div className="w-full flex justify-center ">
                <div className="h-20 w-20 rounded-full   bg-[#F78E24]  border-[#000000] border-solid border-[2px] text-white   text-[23px] font-bold text-center my-auto flex flex-col justify-center items-center">
                  <img
                    className=" w-16 h-16 p-2  cursor-pointer"
                    src="https://firebasestorage.googleapis.com/v0/b/unigoal-15ff4.appspot.com/o/Group%20413554%402x.png?alt=media&token=db5aa3f0-db16-43f2-af10-c631c0f282bc"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewPage;
