const Chip = ({ date, selected, onClick }) => {
	return (
		<button
			className={`rounded-full py-1 px-3 ml-1 border border-blue-500 ${
				selected ? "bg-blue-500 text-white" : "bg-white text-blue-500"
			}`}
			onClick={onClick}
		>
			<span className="text-xs flex ">{date}</span>
		</button>
	);
};

export default Chip;
