import React, { useContext } from "react";
import { SocketContext } from "../Context";

const Footer = () => {
  const {
    me,
    callAccepted,
    name,
    setName,
    callEnded,
    beginShare,
    leaveCall,
    callUser,
  } = useContext(SocketContext);
  return (
    <div className="text-white mt-2 px-20">
      <div className="flex justify-between">
        <div className="flex justify-between space-x-4 ">
          <div className="bg-[#1B1B1B] h-28 w-44 rounded-md flex  flex-col justify-center items-center">
            <div className="cursor-pointer">
              <svg
                id="Group_417986"
                data-name="Group 417986"
                xmlns="http://www.w3.org/2000/svg"
                width="25.708"
                height="38.559"
                viewBox="0 0 25.708 38.559"
              >
                <path
                  id="Path_340626"
                  data-name="Path 340626"
                  d="M13.382.793A8.336,8.336,0,0,0,5.027,9.148v9a8.355,8.355,0,1,0,16.71,0v-9A8.336,8.336,0,0,0,13.382.793Z"
                  transform="translate(-0.529 -0.149)"
                  fill="#ffbc42"
                />
                <path
                  id="Path_340627"
                  data-name="Path 340627"
                  d="M10.508,12.964H7.938v5.142h2.57Z"
                  transform="translate(3.63 17.24)"
                  fill="#2fa9da"
                />
                <path
                  id="Path_340628"
                  data-name="Path 340628"
                  d="M21.469,15.08a1.282,1.282,0,0,1,1.287,1.287v1.287H4.762V16.367A1.282,1.282,0,0,1,6.049,15.08Z"
                  transform="translate(-0.905 20.263)"
                  fill="#33b9ef"
                />
                <path
                  id="Path_340629"
                  data-name="Path 340629"
                  d="M16.671,1.169V5.031a.644.644,0,1,1-1.287,0V1.942A7.617,7.617,0,0,0,8.316,9.527h3.191a.644.644,0,0,1,0,1.287H8.318v2.567h3.189a.644.644,0,0,1,0,1.287H8.318v2.57h3.189a.642.642,0,1,1,0,1.28H8.318a7.689,7.689,0,0,0,5.319,7.34.641.641,0,1,1-.391,1.222,8.989,8.989,0,0,1-6.215-8.561V9.527a8.991,8.991,0,0,1,8.994-9,.633.633,0,0,1,.646.641Zm8.355,8.357v.641a.643.643,0,0,1-.641.644H20.528a.643.643,0,1,1,0-1.285h3.211a7.688,7.688,0,0,0-5.321-7.34A.642.642,0,1,1,18.81.965a8.989,8.989,0,0,1,6.215,8.561Zm-.631,2.562a.641.641,0,0,1,.631.651v5.783a8.991,8.991,0,0,1-9,9,.644.644,0,0,1-.641-.641V23.021a.642.642,0,1,1,1.285,0v3.085a7.613,7.613,0,0,0,7.068-7.583H20.528a.643.643,0,1,1,0-1.282h3.211V14.668H20.528a.642.642,0,1,1,0-1.285h3.211V12.74a.644.644,0,0,1,.656-.651Zm-18,26.355V37.159A1.909,1.909,0,0,1,8.316,35.23H14.1v-4A12.841,12.841,0,0,1,3.174,18.52V14.668a.644.644,0,0,1,1.287,0V18.52a11.567,11.567,0,1,0,23.134,0V14.668a.644.644,0,0,1,1.287,0V18.52a12.846,12.846,0,0,1-13.5,12.838v3.874h1.285V33.3a.644.644,0,0,1,1.287,0v1.936h5.78a1.938,1.938,0,0,1,1.928,1.926v1.287a.641.641,0,0,1-.641.641H7a.648.648,0,0,1-.61-.644Zm17.349-1.928H8.316a.629.629,0,0,0-.641.644V37.8H24.382v-.644a.631.631,0,0,0-.644-.644Z"
                  transform="translate(-3.174 -0.528)"
                  fill="#fff"
                />
              </svg>
            </div>
            <h6 className="text-[#ACACAC] ">Mute</h6>
          </div>
          <div className="bg-[#1B1B1B] h-28 w-44 rounded-md flex flex-col justify-center items-center">
            <div className="cursor-pointer">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="42.313"
                height="38.254"
                viewBox="0 0 42.313 38.254"
              >
                <g
                  id="Group_417995"
                  data-name="Group 417995"
                  transform="translate(0 1.061)"
                >
                  <g
                    id="Group_417994"
                    data-name="Group 417994"
                    transform="translate(0 2.954)"
                  >
                    <path
                      id="Path_340640"
                      data-name="Path 340640"
                      d="M43.661,9.252a1.511,1.511,0,0,0-1.393-.171l-10.045,3.79V10.533A4.533,4.533,0,0,0,27.69,6H6.534A4.533,4.533,0,0,0,2,10.533V31.69a4.533,4.533,0,0,0,4.534,4.533H27.69a4.533,4.533,0,0,0,4.534-4.533v-2.3L42.292,33a1.511,1.511,0,0,0,2.02-1.422V10.5A1.511,1.511,0,0,0,43.661,9.252Z"
                      transform="translate(-2 -6)"
                      fill="#38a2e2"
                    />
                    <path
                      id="Path_340641"
                      data-name="Path 340641"
                      d="M43.661,9.252a1.511,1.511,0,0,0-1.393-.171l-10.045,3.79V10.533A4.533,4.533,0,0,0,27.69,6H6.534A4.533,4.533,0,0,0,2,10.533V31.69a4.533,4.533,0,0,0,4.534,4.533H27.69a4.533,4.533,0,0,0,4.534-4.533v-2.3L42.292,33a1.511,1.511,0,0,0,2.02-1.422V10.5A1.511,1.511,0,0,0,43.661,9.252ZM29.2,31.69A1.511,1.511,0,0,1,27.69,33.2H6.534A1.511,1.511,0,0,1,5.022,31.69V10.533A1.511,1.511,0,0,1,6.534,9.022H27.69A1.511,1.511,0,0,1,29.2,10.533ZM41.29,29.423l-9.067-3.247V16.1l9.067-3.421Z"
                      transform="translate(-2 -6)"
                      fill="#fff"
                    />
                  </g>
                  <path
                    id="Path_340547"
                    data-name="Path 340547"
                    d="M146.132,6,110,42.132"
                    transform="translate(-106.91 -6)"
                    fill="none"
                    stroke="#fff"
                    stroke-linecap="round"
                    stroke-width="1.5"
                  />
                </g>
              </svg>
            </div>
            <h6 className="text-[#ACACAC] ">Start Video</h6>
          </div>{" "}
        </div>
        <div className="flex justify-between space-x-4 ">
          <div className="bg-[#1B1B1B] h-28 w-48 rounded-md flex  flex-col justify-center items-center">
            <div className="cursor-pointer">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="45.516"
                height="37.6"
                viewBox="0 0 45.516 37.6"
              >
                <g
                  id="Group_417988"
                  data-name="Group 417988"
                  transform="translate(0 0)"
                >
                  <path
                    id="Path_340632"
                    data-name="Path 340632"
                    d="M45.826,35.985a9.9,9.9,0,0,0-9.9-9.9H28.01a9.9,9.9,0,0,0-9.9,9.9v3.96a1.98,1.98,0,0,0,1.979,1.98H43.847a1.98,1.98,0,0,0,1.979-1.98Z"
                    transform="translate(-1.18 -5.221)"
                    fill="#64b5f6"
                    fill-rule="evenodd"
                  />
                  <ellipse
                    id="Ellipse_5276"
                    data-name="Ellipse 5276"
                    cx="7.954"
                    cy="8.677"
                    rx="7.954"
                    ry="8.677"
                    transform="translate(22.758 0)"
                    fill="#64b5f6"
                  />
                  <path
                    id="Path_340633"
                    data-name="Path 340633"
                    d="M29.826,35.985a9.9,9.9,0,0,0-9.9-9.9H12.01a9.9,9.9,0,0,0-9.9,9.9v3.96a1.98,1.98,0,0,0,1.979,1.98H27.847a1.98,1.98,0,0,0,1.979-1.98Z"
                    transform="translate(-1.012 -5.221)"
                    fill="#80c783"
                    fill-rule="evenodd"
                  />
                  <ellipse
                    id="Ellipse_5277"
                    data-name="Ellipse 5277"
                    cx="7.954"
                    cy="8.677"
                    rx="7.954"
                    ry="8.677"
                    transform="translate(6.85 0)"
                    fill="#80c783"
                  />
                  <path
                    id="Path_340634"
                    data-name="Path 340634"
                    d="M23.758,9.825a8.905,8.905,0,1,0,0,8.163,8.907,8.907,0,1,0,0-8.164Zm7.916-2.846a6.926,6.926,0,1,1-6.926,6.926A6.93,6.93,0,0,1,31.674,6.979Zm-15.832,0a6.926,6.926,0,1,1-6.926,6.926A6.93,6.93,0,0,1,15.842,6.979Zm7.916,18.552a10.862,10.862,0,0,1,3.958-.742h7.916A10.884,10.884,0,0,1,46.516,35.673v3.958A2.968,2.968,0,0,1,43.547,42.6H3.968A2.968,2.968,0,0,1,1,39.631V35.673A10.884,10.884,0,0,1,11.884,24.789H19.8A10.862,10.862,0,0,1,23.758,25.531Zm4.947,10.142v3.958a.989.989,0,0,1-.989.989H3.968a.989.989,0,0,1-.989-.989V35.673a8.9,8.9,0,0,1,8.905-8.905H19.8A8.9,8.9,0,0,1,28.705,35.673Zm1.81,4.947H43.547a.989.989,0,0,0,.989-.989V35.673a8.9,8.9,0,0,0-8.905-8.905H27.716a8.963,8.963,0,0,0-1.485.124,10.867,10.867,0,0,1,4.454,8.781v3.958A2.952,2.952,0,0,1,30.515,40.62Z"
                    transform="translate(-1 -4.999)"
                    fill="#fff"
                    fill-rule="evenodd"
                  />
                </g>
              </svg>
            </div>
            <h6 className="text-[#ACACAC] ">Participants</h6>
          </div>
          <div className="bg-[#1B1B1B] h-28 w-48 rounded-md flex flex-col justify-center items-center">
            <div className="cursor-pointer">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="42.769"
                height="38.425"
                viewBox="0 0 42.769 38.425"
              >
                <g
                  id="Group_417989"
                  data-name="Group 417989"
                  transform="translate(0 0)"
                >
                  <path
                    id="Path_340635"
                    data-name="Path 340635"
                    d="M200.057,29.262a10.826,10.826,0,0,0,3.175-7.567c0-6.459-6.485-11.695-13.866-11.695S176,15.236,176,21.695l13.365,11.695a17.72,17.72,0,0,0,6.032-1.045,8.33,8.33,0,0,0,6.164,2.716A5.906,5.906,0,0,1,200.057,29.262Zm0,0"
                    transform="translate(-161.298 -9.164)"
                    fill="#e87288"
                  />
                  <path
                    id="Path_340636"
                    data-name="Path 340636"
                    d="M30.754,151.671a11.483,11.483,0,0,1,6.478,10.024c0,6.459-5.983,11.694-13.365,11.694a17.72,17.72,0,0,1-6.032-1.045,8.331,8.331,0,0,1-6.164,2.716,5.906,5.906,0,0,0,1.5-5.8A10.826,10.826,0,0,1,10,161.695C10,155.236,16.485,150,23.866,150A14.754,14.754,0,0,1,30.754,151.671Zm0,0"
                    transform="translate(-9.165 -137.47)"
                    fill="#cae9fc"
                  />
                  <path
                    id="Path_340637"
                    data-name="Path 340637"
                    d="M94.7,100a.835.835,0,1,0,.835.835A.836.836,0,0,0,94.7,100ZM80.835,115.036A.835.835,0,1,0,80,114.2.836.836,0,0,0,80.835,115.036Zm0,0"
                    transform="translate(-73.317 -91.647)"
                  />
                  <path
                    id="Path_340638"
                    data-name="Path 340638"
                    d="M28.067,0C20.547,0,14.375,5.185,13.9,11.716,6.735,12.075,0,17.239,0,24.224a11.672,11.672,0,0,0,3.258,7.988A5.08,5.08,0,0,1,1.915,37a.835.835,0,0,0,.591,1.426,9.215,9.215,0,0,0,6.384-2.58,19.039,19.039,0,0,0,5.812.91c7.519,0,13.691-5.184,14.17-11.713a18.785,18.785,0,0,0,5.007-.891,9.214,9.214,0,0,0,6.384,2.58.835.835,0,0,0,.591-1.426,5.08,5.08,0,0,1-1.342-4.786,11.672,11.672,0,0,0,3.258-7.988C42.769,5.272,35.515,0,28.067,0ZM14.7,35.084A16.889,16.889,0,0,1,8.979,34.1a.835.835,0,0,0-.925.212A7.536,7.536,0,0,1,4.3,36.535a6.755,6.755,0,0,0,.509-4.972.834.834,0,0,0-.2-.353,10.038,10.038,0,0,1-2.939-6.985c0-5.886,5.967-10.859,13.031-10.859,6.67,0,12.53,4.638,12.53,10.859C27.232,30.212,21.611,35.084,14.7,35.084ZM38.159,19.515a.838.838,0,0,0-.2.353,6.755,6.755,0,0,0,.509,4.972,7.536,7.536,0,0,1-3.749-2.225.836.836,0,0,0-.925-.212,16.456,16.456,0,0,1-4.921.965,11.957,11.957,0,0,0-4.514-8.332h10.9a.835.835,0,1,0,0-1.671H21.789a15.579,15.579,0,0,0-6.217-1.646c.48-5.61,5.9-10.048,12.5-10.048C35.131,1.671,41.1,6.643,41.1,12.53A10.038,10.038,0,0,1,38.159,19.515Zm0,0"
                    transform="translate(0 0)"
                    fill="#fff"
                  />
                  <path
                    id="Path_340639"
                    data-name="Path 340639"
                    d="M94.7,113.365H84.177a.835.835,0,1,0,0,1.671H94.7a.835.835,0,1,0,0-1.671Zm0,5.012H80.835a.835.835,0,1,0,0,1.671H94.7a.835.835,0,1,0,0-1.671ZM108.568,100H98.043a.835.835,0,1,0,0,1.671h10.525a.835.835,0,1,0,0-1.671Zm0,0"
                    transform="translate(-73.317 -91.647)"
                  />
                </g>
              </svg>
            </div>
            <h6 className="text-[#ACACAC] ">Chats</h6>
          </div>{" "}
          <div
            onClick={() => beginShare()}
            className="bg-[#1B1B1B] cursor-pointer h-28 w-48 rounded-md flex flex-col justify-center items-center"
          >
            <div className="cursor-pointer">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40.769"
                height="29.923"
                viewBox="0 0 40.769 29.923"
              >
                <path
                  id="Path_340557"
                  data-name="Path 340557"
                  d="M524.615,8A3.615,3.615,0,0,0,521,11.615V33.308a3.615,3.615,0,0,0,3.615,3.615h32.538a3.615,3.615,0,0,0,3.615-3.615V11.615A3.615,3.615,0,0,0,557.154,8Zm17.227,6.272a1.354,1.354,0,0,0-1.916,0L534.5,19.7a1.355,1.355,0,0,0,1.916,1.917l3.109-3.109v9.381a1.356,1.356,0,0,0,2.712,0V18.5l3.109,3.109a1.355,1.355,0,0,0,1.916-1.917Z"
                  transform="translate(-520.5 -7.5)"
                  fill="#69d569"
                  stroke="#fff"
                  stroke-width="1"
                  fill-rule="evenodd"
                />
              </svg>
            </div>
            <h6 className="text-[#ACACAC]  ">Share Screen</h6>
          </div>
        </div>
        <div>
          <h6
            onClick={() => leaveCall()}
            className="bg-[#FF1F14] mt-10 rounded-2xl  px-7 py-1.5 text-sm cursor-pointer"
          >
            End
          </h6>
        </div>
      </div>
    </div>
  );
};

export default Footer;
