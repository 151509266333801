import ReletedProjects from "../../../Components/BookedMentor/ReletedProjects";

const Completed = () => {
  return (
    <>
      <div>
        <div className="flex justify-between pt-12 pb-6">
          <h4 className="font-bold text-[28px] font-quitery">
            Releted Projects{" "}
          </h4>
        </div>
        <div className="flex gap-3 overflow-x-scroll no-scrollbar pl-6 ">
          {Array.from({ length: 9 })?.map((ele, i) => (
            <ReletedProjects key={i} />
          ))}
        </div>
      </div>
    </>
  );
};

export default Completed;
