/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/img-redundant-alt */
import { EastRounded } from "@mui/icons-material";
import { medias } from "../../../config/Resources";
import { Button } from "@mui/material";
import { FaRegHeart } from "react-icons/fa6";
import { Controls, Player } from "@lottiefiles/react-lottie-player";
import { useState } from "react";
import { IoShareSocialOutline } from "react-icons/io5";
import Notification from "../../../animations/Notification.json";

function EntranceHeadPage({ ExamOverview }) {
	const [liked, setLiked] = useState(false);
	const handleLikeClick = () => {
		setLiked(!liked);
	};

	return (
		<div className="relative">
			<img
				className="w-full h-[500px] rounded-3xl"
				src={ExamOverview?.image}
				alt="image"
			/>

			<img
				src={medias.images.Profile.logo}
				alt="College Logo"
				className="absolute top-[60%] left-[6.5%] transform -translate-x-1/2 -translate-y-1/2 w-[100px] h-[100px] z-10"
			/>
			<div className="absolute top-4 right-4">
				<div className="flex gap-3">
					<button className="text-white flex text-center items-center gap-1 font-plusJakartaSans font-medium text-sm  border border-white rounded-full pl-5 pr-5 pt-2 pb-2">
						<IoShareSocialOutline /> Share Now
					</button>
					<button className="text-[red] flex text-center items-center gap-1 font-plusJakartaSans font-bold text-sm  border border-white bg-white rounded-full pl-5 pr-5 pt-2 pb-2">
						<FaRegHeart /> Add to list
					</button>
				</div>
			</div>
			<div className="absolute w-[100%] bottom-0">
				<div className=" bg-white rounded-[20px]  rounded-tr-[20px] overflow-hidden shadow-lg">
					<div className="flex justify-end mt-2 mr-4">
						<Button
							variant="outlined"
							className="border ml-20 mt-2 font-medium font-plusJakartaSans py-[10px] !h-full 3xl:text-[13px] 3xl:h-fit flex gap-[6.2px] text-[16px] 3xl:px-3 capitalize !text-black px-[10px] border-[#707070] rounded-[9px]"
						>
							<img
								src={medias.images.Exam.CrystallBall}
								className="w-5 h-5"
								alt="crystallball"
							/>
							Predictor
						</Button>
						<Button
							variant="outlined"
							className="border text-[#239403] border-[#239403] mt-2 font-plusJakartaSans ml-4 py-[10px] !h-full flex gap-[6.2px] text-[16px] font-medium 3xl:py-[10px] 3xl:text-[13px] 3xl:h-fit 3xl:px-3 capitalize px-[28px] rounded-[9px]"
						>
							<Player
								autoplay
								speed={1.5}
								loop
								src={Notification}
								className="h-6 w-6"
							>
								<Controls
									visible={false}
									buttons={[
										"play",
										"repeat",
										"frame",
										"debug",
										"snapshot",
										"background",
									]}
								/>
							</Player>
							Get Alert
						</Button>
						<Button
							variant="contained"
							className="border mt-2 font-plusJakartaSans ml-5 py-[10px] !h-full flex gap-[6.2px] miniDesk:!px-[2px] text-[16px] font-medium capitalize !text-[#fff] !bg-[#239403] 3xl:py-[10px] 3xl:px-3 3xl:text-[13px] 3xl:h-fit !shadow-none px-[28px] border-[#239403] rounded-[9px]"
						>
							Apply Now{" "}
							<EastRounded className="3xl:text-[14px] w-4 h-4 miniDesk:hidden" />
						</Button>
					</div>
					<div className="flex text-center items-center pl-3">
						<h6 className="font-plusJakartaSans ml-[10px] font-bold text-2xl text-black">
							{ExamOverview?.exam_name}
						</h6>
					</div>
					<div className="ml-[17px] pb-6">
						<p className="font-plusJakartaSans font-medium text-sm flex text-center items-center mt-2">
							<img
								className="h-6 w-6"
								src={medias.images.Profile.cap}
								alt="Cap"
							/>
							<span className="ml-1">{ExamOverview?.career_cluster_name}</span>
						</p>
						<p className="font-plusJakartaSans font-medium text-sm flex text-center items-center mt-2">
							<img
								className="h-6 w-6"
								src={medias.images.Profile.hand}
								alt="Hand"
							/>
							<span className="ml-1">{ExamOverview?.exam_unique_id}</span>
						</p>
						<p className="font-plusJakartaSans font-medium text-sm flex text-center items-center mt-2">
							<img
								className="h-6 w-6"
								src={medias.images.Profile.correct1}
								alt="Correct"
							/>
							<span className="ml-1">{ExamOverview?.applicable_for}</span>
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}

export default EntranceHeadPage;
