import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import { useNavigate } from "react-router-dom";

export default function ProfileMenu({
  userInfo,
  children,
  isDashboard = false,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();

  const handleLogout = () => {
    // Ask for user consent
    handleClose();

    const consent = window.confirm("Are you sure you want to log out?");

    // If user consents, remove token from local storage and redirect
    if (consent) {
      localStorage.removeItem("token-student");
      // Redirect to the login page (replace with your login page route)
      if (
        window.location.origin === "http://localhost:3000" ||
        window.location.origin === "https://techugoapps.com" ||
        window.location.origin === "https://www.techugoapps.com"
      ) {
        window.location.href = "/sygnific_dev";
      } else {
        window.location.href = "/";
      }
    }
  };

  const GoToHomepage = () => {
    handleClose();
    if (
      window.location.origin === "http://localhost:3000" ||
      window.location.origin === "https://techugoapps.com" ||
      window.location.origin === "https://www.techugoapps.com"
    ) {
      window.location.href = "/sygnific_dev";
    } else {
      window.location.href = "/";
    }
  };

  const GoToDashboard = () => {
    handleClose();
    navigate("/profile/career-counselling?location=plans");
    // if (
    //   window.location.origin === "http://localhost:3000" ||
    //   "https://techugoapps.com"
    // ) {
    //   window.location.href = "/sygnific_dev";
    // } else {
    //   window.location.href = "/";
    // }
  };

  return (
    <div>
      <Button
        id="fade-button"
        aria-controls={open ? "fade-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        {children}
      </Button>
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {!!isDashboard && (
          <MenuItem onClick={() => GoToHomepage()}>Go To Home</MenuItem>
        )}
        {!isDashboard && (
          <MenuItem onClick={() => GoToDashboard()}>Dashboard</MenuItem>
        )}
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </div>
  );
}
