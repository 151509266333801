/* eslint-disable @typescript-eslint/no-unused-vars */
import axios, { AxiosInstance } from "axios";
import { API_URLS } from "../../config/API_URLS";

const axiosInstance = axios.create({
  baseURL: API_URLS.baseURL,
});

axiosInstance.interceptors.request.use(
  (config: any) => {
    const token = localStorage.getItem("token-student");
    config.headers = {
      accesstoken: `${token !== null ? token : ""}`,
      ...config.headers,
    };
    return config;
  },
  (err) => Promise.reject(err)
);

axiosInstance.interceptors.response.use(
  (response: any) => {
    return response.data;
  },
  (error) => {
    return Promise.reject(error?.response?.data);
  }
);

export default axiosInstance;
