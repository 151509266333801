import React from "react";
import { medias } from "../../config/Resources";

const MentalHealth = () => {
	const AcdameyDetails = [
		{
			image: medias.images.Profile.Sleep,
			label: "Sleep Tpye",
			label1: "75kg",
		},
		{ image: medias.images.Profile.Durartion, label: "Duration", label1: "123cm" },
		{ image: medias.images.Profile.Anxiety, label: "Anxiety Level", label1: "22 Nov 2023" },
		{ image: medias.images.Profile.Stress, label: "Stress Type/Level", label1: "N/A" },
        { image: medias.images.Profile.Bp, label: "Bp", label1: "N/A" },
        { image: medias.images.Profile.Fit, label: "Fits", label1: "N/A" },
	];

	return (
		<div className="max-w-md bg-white rounded-xl shadow-md overflow-hidden md:max-w-4xl mt-16 ml-5 border border-gray-300">
			<div className="w-full">
				<div className="uppercase tracking-wide text-sm font-bold pt-4 pl-4 pr-4 flex justify-between">
					<h6 className="font-plusJakartaSans">Mental Health Stats</h6>
                    <h6 className="font-plusJakartaSans text-[#2771E7]">Edit Details</h6>
				</div>
				<div className="mt-4">
					<div className="h-px bg-gray-300"></div>
				</div>
				<div className="mt-2 pl-7 pt-6 flex items-center gap-16">
					{AcdameyDetails &&
						AcdameyDetails.map((detail, index) => (
							<div key={index} className="flex items-center gap-24">
								<div className="items-center">
									<img
										src={detail.image}
										alt={detail.label}
										className="h-10 w-10"
									/>
									<div className="text-sm mt-1">
										<p className="text-gray-600 font-plusJakartaSans text-xs">
											{detail.label}
										</p>
										<p className="font-bold text-sm font-plusJakartaSans">
											{detail.label1}
										</p>
									</div>
								</div>
							</div>
						))}
				</div>
			</div>
			<div className="p-5">
				<p className="font-plusJakartaSans text-sm font-extrabold">Body Weight</p>
				<p className="font-plusJakartaSans text-xs font-semibold mt-2 text-[#707070]">
					when an unknown printer took a galley of type and scrambled it to make
					a type specimen book. It has survived not only five centuries, but
					also the leap into electronic typesetting, remaining essentially
					unchanged. It was popularised in the 1960s with the release of
					Letraset sheets containing Lorem Ipsum passages, and more recently
					with desktop publishing software like Aldus PageMaker including
					versions of Lorem Ipsum.
				</p>
			</div>
		</div>
	);
};

export default MentalHealth;
