/* eslint-disable jsx-a11y/img-redundant-alt */
import { medias } from "../../../../config/Resources";

function ScholarShipCards({ scholarshipDetails }) {
	const data = [
		{
			name: scholarshipDetails?.map((data) => (data?.criteria)),
			Based: "Merit Based",
			image: medias.images.College.Merit,
		},
		{
			name: scholarshipDetails?.map((data) => (data?.funding_type)),
			Based: "Olympiad",
			image: medias.images.College.Olymplad,
		},
	];
	return (
		<div className="ml-4 mr-4 mt-6 flex flex-wrap gap-5 justify-between">
			{data?.map((item, index) => {
				return (
					<div
						key={index}
						className="flex-1 min-w-[45%] border border-[#707070] rounded-lg border-opacity-30 pt-2 pl-4 pr-4 pb-5"
					>
						<div className="flex items-center text-center">
							<span className="pt-[5px] pb-[5px] pl-[3px] pr-[3px]">
								<img className="h-[30px] w-8" src={item?.image} alt="image" />
							</span>
							<h6 className="font-plusJakartaSans ml-[3px] mt-[2px] font-medium text-[#000000] text-opacity-70 text-[11px]">
								{item?.name}
							</h6>
						</div>
						<div className="mt-1">
							<p className="font-plusJakartaSans ml-[5px] font-semibold text-[14px]">
								{item?.Based}
							</p>
						</div>
					</div>
				);
			})}
		</div>
	);
}

export default ScholarShipCards;
