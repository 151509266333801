import React from "react";
import Text from "../../Text";
import { guidancePageContant } from "../../../config/pageContent";
import { medias } from "../../../config/Resources";
import Image from "../../Image";

const Face6 = () => {
  return (
    <>
      <div className="flex flex-col rounded-br-[71px] rounded-bl-[113px] items-center pt-[70.75px] pb-[60px] px-[208px] justify-center bg-[#F3F6FF]">
        <div>
          <Text className="text-[44px] font-bold">
            {guidancePageContant.Face5.designRoadMap}
          </Text>
        </div>
        <div className=" mt-[70px] w-[75%]">
          <Image
            src={medias.images.guidance.Face5.face5roadMap}
            alt="face5roadMap"
          />
        </div>
      </div>
    </>
  );
};

export default Face6;
