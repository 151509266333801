/* eslint-disable eqeqeq */
import ExploreSearch from "../../../Components/ExploreSearch/ExploreSearch";
import SelectDropdown from "../../../Components/DropDown/Select/SelectDropdown";
import { BsFilterLeft } from "react-icons/bs";
import { ExploreCard } from "./ExploreCard";
import BookSessionPopup from "../../../Components/BookSessionPopup/BookSessionPopup";
import { useState } from "react";
import CircularProgress from "@mui/material-next/CircularProgress";
import ListIcon from "@mui/icons-material/List";
import { BiCategory } from "react-icons/bi";
import CollageCard from "../../../Components/CareerMapping/CollageCard";
import CollegeCardListView from "../../../Components/College/CollegeCardListView";
import { useLocation } from "react-router-dom";

const Explore = ({
	View = 1,
	setView = () => {},
	BookedMentorList,
	handleSearch,
	MentorCount,
	MentorList,
	setMentorData,
	mentorData,
	loading,
	setActive,
	isMentorPage,
	isCollages,
	isRequested = false,
}) => {
	const [modal, setModal] = useState(false);
	const [slide, setSlide] = useState(1);

	const options = [
		{ name: "Relevence", value: "#" },
		{ name: "Pricing Low to High", value: "1" },
		{ name: "Pricing High to Low", value: "2" },
		{ name: "User Rating", value: "3" },
	];

	const { pathname } = useLocation();

	return (
		<>
			<div className=" py-5  pl-4">
				{/* search */}
				<div className="shadow-[0px_5px_16px_rgba(0,0,0,0.06)] rounded-[20px] p-4 overflow-hidden bg-white ">
					<h2 className="miniDesk:text-[14px]  text-[20px] flex items-center text-center gap-1 font-bold font-plusJakartaSans">
						{/* <Player
							autoplay
							speed={1.5}
							loop
							src={Animation}
							style={{ height: "80px", padding: 0, width: "80px" }}
						>
							<Controls
								visible={false}
								buttons={[
									"play",
									"repeat",
									"frame",
									"debug",
									"snapshot",
									"background",
								]}
							/>
						</Player> */}
						{pathname == "/mentorship/find-the-best-mentor-of-your-choice"
							? "Find the best mentor of your choice"
							: pathname == "/Colleges-list"
							? "Get Search for from top colleges"
							: "Find the best Career Counsellor!!"}
					</h2>
					<div className="flex justify-between w-full  items-center mt-4 ">
						<ExploreSearch handleSearch={handleSearch} isCollages={isCollages}/>

						<div className="flex  items-center  ml-5 miniDesk:ml-1">
							<div className="flex  items-center w-fit">
								<BsFilterLeft className=" miniDesk:text-[16px] text-[25px]" />

								<p className="miniDesk:hidden w-[100px] font-plusJakartaSans text-[16px] font-medium">
									Sort By
								</p>
							</div>
							<SelectDropdown classNames={""} options={options} />
							{!!isCollages && (
								<div className="flex items-center p-1 gap-x-3 ml-2 text-center !border-[2px] !border-gray-50 !border-solid rounded-lg  ">
									<div
										title="List View"
										onClick={() => setView(1)}
										className={` ${
											View === 1
												? "text-blue-400  bg-blue-400 bg-opacity-10"
												: ""
										}  hover:text-blue-400 cursor-pointer  hover:bg-blue-400 hover:bg-opacity-10 px-2 py-[2px] `}
									>
										<ListIcon />
									</div>

									<div
										title="Grid View"
										onClick={() => setView(2)}
										className={` ${
											View === 2
												? "text-blue-400  bg-blue-400 bg-opacity-10"
												: ""
										}  hover:text-blue-400 cursor-pointer  hover:bg-blue-400 hover:bg-opacity-10  px-[9.2px] py-[5px]  `}
									>
										<BiCategory />
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
				{/* explore card */}

				<div>
					{loading ? (
						<div className="flex flex-col justify-center items-center mt-20">
							<CircularProgress
								color="tertiary"
								fourColor
								variant="indeterminate"
								size={30}
							/>
						</div>
					) : MentorList.length <= 0 ? (
						<div className="flex flex-col justify-center items-center mt-20">
							No {!isCollages ? "Mentor" : "Collages"} available.
						</div>
					) : (
						<div className="mx-auto max-w-full  lg:px-0">
							<div className="mt-1 flex justify-between gap-2  items-center flex-wrap">
								{!isCollages ? (
									MentorList?.map((ele, i) => (
										<ExploreCard
											isRequested={isRequested}
											BookedMentorList={BookedMentorList}
											isMentorPage={isMentorPage}
											key={i}
											setMentorData={setMentorData}
											mentor={ele}
											isAdCard={i === 4}
											cardIndex={i}
											modal={modal}
											setModal={setModal}
										/>
									))
								) : View === 2 ? (
									<CollageCard data={MentorList} isCollageListing={true} />
								) : (
									<CollegeCardListView data={MentorList} />
								)}
							</div>
						</div>
					)}
				</div>
			</div>

			<BookSessionPopup
				modal={modal}
				setModal={setModal}
				mentorData={mentorData}
				setSlide={setSlide}
				slide={slide}
				setActive={setActive}
			/>
		</>
	);
};

export default Explore;
