import { medias } from "../../../../config/Resources";

function EntranceFeeApplicationTable({EntranceExam}) {
	// const feeData = [
	// 	{ category: "General", fee: "Rs 1700" },
	// 	{ category: "General-EWS / OBC-NCL", fee: "Rs 1600" },
	// 	{ category: "SC / ST/ PwBD / Third Gender", fee: "Rs 1000" },
	// 	{ category: "Foreign Nationals / OCI / NRI", fee: "Rs 9500" },
	// ];

	return (
		<div className="ml-4 mr-4 mt-5">
			<h6 className="mt-3 font-plusJakartaSans flex items-center text-center gap-[6px] font-bold text-[#000000] text-[15px]">
				<img
					className="w-5 h-5"
					src={medias.images.Exam.Phone}
					alt="Registration"
				/>
				<span className="font-plusJakartaSans font-bold mt-[1px]">
					Application Fees
				</span>
			</h6>
			<img
				className="w-40 text-[#000000] pt-1"
				src={medias.images.Profile.line1}
				alt="lin1"
			/>
			{/* <div className="bg-white mt-3 overflow-hidden border-t border-b border-gray-200 border-l-2 border-l-gray-400 border-r-gray-400 border-r-2">
				<div className="bg-sky-200 text-center py-3 text-[12px] px-4 font-extrabold font-plusJakartaSans uppercase">
					FEE PAYABLE BY THE CATEGORY OF THE CANDIDATE
				</div>
				<div className="divide-y divide-gray-200">
					{feeData.map((item, index) => (
						<div key={index} className="flex">
							<div className="flex-1 font-bold font-plusJakartaSans text-[11px] py-3 px-4">
								{item.category}
							</div>
							<div className="w-px bg-gray-200"></div>
							<div className="w-1/3 py-3 pl-4 text-[11px] font-plusJakartaSans font-medium">
								{item.fee}
							</div>
						</div>
					))}
				</div>
			</div> */}
			<div>
					<div>
						<div
							dangerouslySetInnerHTML={{
								__html: EntranceExam?.application_fee,
							}}
							className="font-plusJakartaSans font-medium text-[11.5px] text-[#000000] text-opacity-70 mt-[8px]"
						></div>
					</div>
				</div>
		</div>
	);
}

export default EntranceFeeApplicationTable;
