import { medias } from "../../../config/Resources";

function EntranceExams() {
	const allSkills = [
		{
			title: "Loreum Ipsumn",
            images: medias.images.Profile.NoPath
		},
        {
			title: "Loreum Ipsumn",
            images: medias.images.Profile.NoPath
		},

        {
			title: "Loreum Ipsumn",
            images: medias.images.Profile.NoPath
		},
        {
			title: "Loreum Ipsumn",
            images: medias.images.Profile.NoPath
		},
        {
			title: "Loreum Ipsumn",
            images: medias.images.Profile.NoPath
		},
        {
			title: "Loreum Ipsumn",
            images: medias.images.Profile.NoPath
		},
        {
			title: "Loreum Ipsumn",
            images: medias.images.Profile.NoPath
		},
        {
			title: "Loreum Ipsumn",
            images: medias.images.Profile.NoPath
		},
        {
			title: "Loreum Ipsumn",
            images: medias.images.Profile.NoPath
		},
        {
			title: "Loreum Ipsumn",
            images: medias.images.Profile.NoPath
		},
        {
			title: "Loreum Ipsumn",
            images: medias.images.Profile.NoPath
		},
        {
			title: "Loreum Ipsumn",
            images: medias.images.Profile.NoPath
		},
	];
	return (
		<div className="w-auto rounded-[20px] ml-auto mr-auto overflow-hidden p-6 shadow-lg border border-gray-300 mt-10">
			<div className="flex items-center text-center">
				<img
					className="h-8 w-8"
					src={medias.images.Profile.Layer2}
					alt="cap"
				/>
				<h6 className="font-plusJakartaSans font-bold text-lg ml-1">
					Entrance Exams
				</h6>
			</div>
            <div className="grid grid-cols-6 gap-4 mt-4">
                {allSkills.map((skill, index) => (
                    <div
                        key={index}
                        className="bg-white rounded-lg p-4 shadow-custom1 flex align-center items-center text-center"
                    >
                     <img className="h-4 w-4" src={skill.images}  alt="layer"/>
                        <p className="font-plusJakartaSans font-normal text-xs ml-[5px]">{skill.title}</p>
                    </div>
                ))}
            </div>
		</div>
	);
}

export default EntranceExams;
