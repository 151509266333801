import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import axiosInstance from "../../../config/Axios/axiosInstance";
import { API_URLS } from "../../../config/API_URLS";
import Image from "../../Image";
import { medias } from "../../../config/Resources";
import { Controls, Player } from "@lottiefiles/react-lottie-player";
import Questionsirheader from "../questionsircomponents/questionairheader";
import Questions from "../questionsircomponents/questions";
import Timer from "../questionsircomponents/timer";
import Options from "../questionsircomponents/options";
import {
  Button,
  CircularProgress,
  IconButton,
  Modal,
  useMediaQuery,
} from "@mui/material";
import classNames from "classnames";
import { useAptitude, useIntrestStore } from "../../../store";
import { useUserInfoStore } from "../../../store/userinfo";
import { toast } from "react-toastify";
import { mediaResources } from "../../../config/Constants";
import {
  Close,
  KeyboardArrowLeftRounded,
  KeyboardArrowRightRounded,
} from "@mui/icons-material";
import Preview from "../../Preview";
import goldflare from "../../../animations/Congratulations/goldflare.json";
import warnn from "../../../animations/Congratulations/warn.json";

import blast from "../../../animations/Congratulations/blast.json";
import trophie from "../../../animations/Congratulations/trophie.json";

import Text from "../../Text";
import { Grading } from "@mui/icons-material";

import { useNavigate } from "react-router-dom";

const Aptitude = () => {
  const [page, setPage] = useState(1);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [submitted, setSubmitted] = useState<any>([]);
  const [later, setLater] = useState<any>([]);
  const [open, setOpen] = React.useState(true);
  const [due, setDue] = React.useState(false);

  const handleClose = () => setOpen(false);
  const [passage, setPassage] = useState("");
  const miniDesk = useMediaQuery("(max-width : 1220px)");
  const Desktop = useMediaQuery("(max-width : 1440px)");
  const xl = useMediaQuery("(max-width : 1920px)");
  const [isReachedLast, setIsReachedLast] = useState(false);
  const { userInfo, setIsloading }: any = useUserInfoStore((state) => state);
  const { apptitude, addApptitude }: any = useAptitude((state) => state);
  const [questionLists, setQuestionsList] = useState<any>(null);
  const [currentQuestion, setCurrentQuestion] = useState(0); //graduate %26 professional
  //   const [TotalQuestions, setTotalQuestions] = useState(0); //graduate %26 professional
  const navigate = useNavigate();
  function removeDuplicates(array: number[]): number[] {
    const uniqueSet = new Set(array);
    return [...uniqueSet];
  }
  // const { userInfo }: any = useUserInfoStore((state) => state);

  // const arrayWithoutDuplicates: number[] = removeDuplicates(submitted);

  const [openCongrats, setOpenCongrats] = useState<boolean>(false);
  const handleCongrats = () => {
    setOpenCongrats(false);
    navigate("/profile/career-counselling?location=report");
  };

  const handleFetchQuestion = async (queryKey: any) => {
    return await axiosInstance
      .get(`${API_URLS.getAptitudeQuestionPaper}?class=${userInfo?.class}`)
      .then((res: any) => {
        // console.log("res", res);
        let newValue: any = { total_count: res?.total_count, result: [] };
        for (let i in res?.result) {
          newValue.result = [...newValue.result, ...res.result[i]];
        }
        setQuestionsList({ ...newValue });
        return newValue;
      });
  };

  // console.log("questionLists", questionLists);
  console.log();

  useEffect(() => {
    if (currentQuestion + 1 === questionLists?.total_count) {
      // console.log("currentQuestion", currentQuestion);
      // console.log("questionLists total_count", questionLists?.total_count);
      setIsReachedLast(true);
    }
  }, [currentQuestion]);

  useEffect(() => {
    if (currentQuestion && questionLists) {
      if (passage !== questionLists?.result[currentQuestion]?.passage) {
        setPassage(questionLists?.result[currentQuestion]?.passage);
        setOpen(true);
      }
    }
  }, [currentQuestion, questionLists]);

  const { mutate: submitQuestion, isPending } = useMutation({
    mutationFn: async () => {
      if (removeDuplicates(submitted).length >= 79) {
        await axiosInstance
          .post(API_URLS.addAptitudeReport, {
            data: apptitude,
            class: "graduate and professional",
            student_id: userInfo?.id,
          })
          .then(async (res) => {
            setOpenCongrats(true);
            toast("Test completed Successfully", {
              type: "success",
            });
            // await axiosInstance.post("/addParticularCareerReport", {
            //     type:"aptitude"
            // }).then(res => {}).catch(err => console.log(err))
            await axiosInstance
              .post("/callCareerMapping")
              .then((resp) => {})
              .catch((err) => console.log(err));

            return res;
          });
      } else {
        setDue(true);
      }
    },
  });
  const { isLoading: IsIntrestLoading }: { data: any; isLoading: boolean } =
    useQuery({
      queryKey: ["getInterestQuestionPaper"],
      queryFn: ({ queryKey }) => handleFetchQuestion(queryKey),
      refetchOnMount: true,
      retry: false,
      refetchInterval: false,
    });

  useEffect(() => {
    setIsloading(isPending || IsIntrestLoading);
  }, [isPending, IsIntrestLoading]);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      const message =
        "Are you sure? You'll loose all the progress that you have achieved";
      event.returnValue = message; // Standard for most browsers
      return message; // For some older browsers
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);
  // console.log("later global", later);

  useEffect(() => {
    if (
      questionLists?.total_count - currentQuestion + 1 > 10 &&
      (currentQuestion + 1) % 10 === 2
    ) {
      setPage(page + 1);
    }
  }, [currentQuestion]);

  return (
    <>
      <Modal
        onClose={handleCongrats}
        open={openCongrats}
        className="flex items-center !bg-[#000000CC] !cursor-pointer opacity-100 justify-center !border-none "
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onClick={() => {
          handleCongrats();
        }}
        title="Close"
      >
        <>
          <div className="absolute z-[100] top-0 cursor-pointer	">
            <Player
              autoplay
              speed={15}
              src={blast}
              style={{ height: "70vh", padding: 0, width: "100vw" }}
            >
              <Controls
                visible={false}
                buttons={[
                  "play",
                  "repeat",
                  "frame",
                  "debug",
                  "snapshot",
                  "background",
                ]}
              />
            </Player>
          </div>
          <div
            className={`absolute z-[1] ${
              openCongrats
                ? " top-[70px] 3xl:top-[0px] miniDesk:!top-[70px]"
                : " top-0 "
            } 	`}
          >
            <Player
              autoplay
              speed={4}
              src={trophie}
              style={{
                height: Desktop ? "130px" : "200px",
                padding: 0,
                width: Desktop ? "130px" : "200px",
              }}
            >
              <Controls
                visible={false}
                buttons={[
                  "play",
                  "repeat",
                  "frame",
                  "debug",
                  "snapshot",
                  "background",
                ]}
              />
            </Player>
          </div>

          <div className="absolute top-0 	">
            <Player
              autoplay
              speed={1.5}
              loop
              src={goldflare}
              style={{
                height: Desktop ? "500px" : "700px",
                padding: 0,
                width: Desktop ? "500px" : "700px",
              }}
            >
              <Controls
                visible={false}
                buttons={[
                  "play",
                  "repeat",
                  "frame",
                  "debug",
                  "snapshot",
                  "background",
                ]}
              />
            </Player>
          </div>

          <div className="p-[3px] rounded-3xl absolute top-[26%] desktop:top-[25%] w-[50%] gradient-bg-1 !border-none !z-50">
            <div className="w-full h-full bg-[#151819] !cursor-default	   3xl:!py-[20px]  pt-[47px] flex flex-col items-center pb-[33px] pr-[20px] rounded-3xl">
              {/* <h4 className="text-white text-end items-end">X</h4> */}
              <Image
                src={`${mediaResources}/Congratulations/congratulationBanner.png`}
                className="absolute z-[3] -top-[20%]  desktopMin:-top-[29%] cursor-default	"
                alt="congratulationBanner"
              />
              {/* <Text
                className={`font-[MachineGunk] text-white ${
                  userInfo?.firstName?.length + userInfo?.lastName?.length >= 12
                    ? "mt-4  desktop:text-[31px] text-[48px]"
                    : "  desktop:text-[35px] text-[51px] "
                }`}
              >
                {userInfo?.firstName} {userInfo?.lastName}
              </Text> */}

              <Text
                className={`font-[MachineGunk] relative z-50 text-white ${
                  userInfo?.firstName?.length + userInfo?.lastName?.length >= 12
                    ? "mt-4  desktop:text-[31px] text-[48px]"
                    : "  desktop:text-[35px] text-[51px] "
                }`}
              >
                {userInfo?.firstName} {userInfo?.lastName}
              </Text>
              <Text className="font-ibmPlexMonoLight text-white w-full text-center text-[20px] desktop:text-[14px] !cursor-default	">
                You have successfully completed your Psychometric Test
              </Text>
              <div className="grid grid-cols-2 grid-rows-2 gap-x-[20px] !cursor-default	">
                <div className="bg-[#000] mt-[13px] py-[7px] pl-[10px] rounded-full flex pr-[27px] items-center">
                  <div className="bg-white bg-opacity-[7%] rounded-full px-[17px] py-[5px]">
                    <Image
                      className="desktop:w-[50px]"
                      src={medias.images.congratulations.Personality}
                      alt="NextIcons"
                    />
                  </div>
                  <Text className="text-white desktop:text-[14px] ml-[8px]">
                    Personality
                  </Text>
                </div>
                <div className="bg-[#000] mt-[13px] py-[7px] pl-[10px] rounded-full flex pr-[27px] items-center !cursor-default	">
                  <div className="bg-white bg-opacity-[7%] rounded-full px-[17px] py-[5px]">
                    <Image
                      className="desktop:w-[50px]"
                      src={medias.images.congratulations.Aptitude}
                      alt="NextIcons"
                    />
                  </div>
                  <Text className="desktop:text-[14px] text-white ml-[8px]">
                    Aptitude
                  </Text>
                </div>
                <div className="bg-[#000] mt-[13px] py-[7px] pl-[10px] rounded-full flex pr-[27px] items-center">
                  <div className="bg-white bg-opacity-[7%] rounded-full px-[17px] py-[5px]">
                    <Image
                      className="desktop:w-[50px]"
                      src={medias.images.congratulations.SocialBehaviour}
                      alt="NextIcons"
                    />
                  </div>
                  <Text className="text-white desktop:text-[14px] ml-[8px]">
                    Social Behaviour
                  </Text>
                </div>
                <div className="bg-[#000] mt-[13px] py-[7px] pl-[10px] rounded-full flex pr-[27px] items-center">
                  <div className="bg-white bg-opacity-[7%] rounded-full px-[17px] py-[5px]">
                    <Image
                      className="desktop:w-[30px]"
                      src={medias.images.congratulations.Intrest}
                      alt="NextIcons"
                    />
                  </div>
                  <Text className="text-white desktop:text-[14px] ml-[8px]">
                    Interest
                  </Text>
                </div>
              </div>

              <div className="relative flex flex-col mt-[30px] items-center w-full !cursor-default	">
                <Image
                  src={medias.images.congratulations.TextBanner}
                  className="3xl:w-3/4 desktop:!w-[65%]"
                  alt="instructionBanner"
                />
                <Text className="absolute top-[calc(50%-24px)]  desktop:text-[10px] desktop:mt-[2px] desktop:!w-[60%] text-center w-[70%] text-[17px] font-ibmPlexMonoRegular font-bold">
                  This test Result will open endless opportunities to scale your
                  life to next level
                </Text>
              </div>
              <Button
                className="!bg-[#367BEF] mt-[36px] mb-[20px] desktop:!mt-[10px] desktop:!text-[14px] !capitalize !flex !items-center !px-[19px] !font-ibmPlexMonoRegular !py-[9px] !rounded-xl !text-[19px]  !shadow-[0px_8px_0px_0px_rgba(25,82,179,1)]"
                variant="contained"
              >
                <Image
                  src={medias.images.intrestImages.coinIcon}
                  className="w-[25px] mr-[20px]"
                  alt="coinIcon"
                />
                4552
              </Button>
            </div>
          </div>
        </>
      </Modal>

      <Modal
        open={due}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="flex items-center !bg-[#000000CC]   justify-center !border-none "
      >
        <div className="flex items-center justify-center w-screen h-screen">
          <div className=" flex flex-col  object-cover bg-cover w-[615px] h-[420px] aptitude_bg_class bg-no-repeat   ">
            <div className="flex items-center justify-between     ">
              <div className="mx-20">
                <div className="">
                  <Player
                    autoplay
                    speed={1.5}
                    loop
                    src={warnn}
                    style={{
                      height: Desktop ? "160px" : "160px",
                      padding: 0,
                      width: Desktop ? "180px" : "190px",
                    }}
                  >
                    <Controls
                      visible={false}
                      buttons={[
                        "play",
                        "repeat",
                        "frame",
                        "debug",
                        "snapshot",
                        "background",
                      ]}
                    />
                  </Player>
                </div>
                <div className="text-[20px] font-semibold bg-[#F6906B] face_bg_class   rounded-3xl opacity-100 py-[23px] text-center px-[36px] w-full">
                  You still have{" "}
                  {questionLists?.total_count -
                    apptitude?.length +
                    removeDuplicates(later).length >=
                  1
                    ? questionLists?.total_count -
                      apptitude?.length +
                      removeDuplicates(later).length
                    : 0}{" "}
                  questions unattempted. Complete the pending questions to
                  submit the test.
                </div>
                <div className="flex items-center justify-center mt-4">
                  <div
                    role="button"
                    onClick={() => setDue(false)}
                    className=" w-fit min-w-[180px] min-h-[60px]  cursor-pointer flex focused:!shadow-none overflow-auto text-ellipsis max-h-[150px] !items-center  !bg-[#3F7CDA] !shadow-[0px_10px_0px_0px_rgba(1,62,158,1)]  active:relative  active:top-[10px] active:!shadow-none justify-center text-white   desktop:!min-w-[150px] font-semibold rounded-[8px]  text-start  px-[27px] py-[4px]"
                  >
                    <p className="font-ibmPlexMonoRegular text-[14px] miniDesk:text-[10px] desktop:!text-[12px]">
                      {" "}
                      Ok
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        open={open && Boolean(passage)}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="flex items-center justify-center w-screen h-screen">
          <div className="p-4 flex flex-col gap-3 max-w-[500px]  bg-white rounded-lg w-fit">
            <div className="flex items-center justify-between w-full ml-auto">
              <Text className="text-[20px] font-semibold">
                Read the passage and answer the questions
              </Text>
              <IconButton onClick={handleClose}>
                <Close />
              </IconButton>
            </div>
            <div className="px-1 text-justify max-h-[300px] overflow-auto">
              {passage?.includes("https://") ? (
                <Preview
                  imageClassOverride={"!max-w-full !w-full !max-h-full"}
                  src={passage}
                />
              ) : (
                passage
              )}
            </div>
          </div>
        </div>
      </Modal>
      <div className="flex items-center pl-[30px] pr-[10px]  pb-[30px] h-screen bg-no-repeat bg-cover aptitudeMainBg">
        <div className="aptitudeQuestionBg z-50 relative bg-no-repeat flex  pt-[90px] pb-[70px] pl-[13px] pr-[21px] items-center justify-center w-full h-full bg-cover">
          {/* <div className='absolute miniDesk:h-[81%] miniDesk:top-[12%] miniDesk:w-[97%] w-[calc(100%-30px)] h-[calc(100%-135px)] bg-black bg-opacity-50 top-[calc(0%+85px)] gridBackGround-intrest  backdrop-blur-lg' /> */}
          <div className="relative z-50 w-full h-full py-[19px] 3xl:pt-0 px-[13px]">
            {/* <Image src={medias.images.intrestImages.SelectorsDrops} alt="SelectorsDrops" className='absolute top-[calc(0%+30px)] miniDesk:w-[6px] left-[calc(0%+15px)]' /> */}
            {/* <div className='absolute bottom-[calc(0%-40px)] left-[calc(0%-220px)]'>
                            <Player
                                autoplay
                                speed={1.5}
                                loop
                                src={astronot}
                                style={{ height: "250px", padding: 0, width: "250px" }}
                            >
                                <Controls
                                    visible={false}
                                    buttons={[
                                        "play",
                                        "repeat",
                                        "frame",
                                        "debug",
                                        "snapshot",
                                        "background"
                                    ]}
                                />
                            </Player>
                        </div> */}

            <div
              className={classNames(
                "w-[97%] flex flex-col justify-between h-full miniDesk:pt-[0px] pt-4  relative z-50 ml-auto",
                !(!IsIntrestLoading || questionLists?.result?.length > 0) &&
                  "h-full"
              )}
            >
              {!IsIntrestLoading ||
              questionLists?.result?.length > 0 ||
              !isPending ? (
                <>
                  <div className="">
                    <Questionsirheader
                      mainContainerClass={"!w-full !items-end  mt-3 "}
                      open={open}
                      setOpen={setOpen}
                      isPassage={
                        questionLists?.result[currentQuestion]?.passage
                      }
                      questionsInfo={{
                        currentQuestion: `${currentQuestion + 1}`,
                        totalQuestion: `${questionLists?.total_count}`,
                        type: "intrest",
                      }}
                    />
                  </div>

                  <div className="w-full h-full mt-[20px]">
                    <Questions
                      currentQuestion={currentQuestion}
                      imageClassOverride={`!w-full  ${
                        Desktop
                          ? `${
                              questionLists?.result[currentQuestion]?.id === 163
                                ? "!max-w-full "
                                : "!max-w-full  "
                            } !max-h-[140px]`
                          : " 3xl:!max-w-full  !max-w-full 3xl:!max-h-[200px] 4k:!max-h-[270px]"
                      }`}
                      lottieHeight={xl ? "120px" : "190px"}
                      lottieWidth={xl ? "130px" : "100px"}
                      height={miniDesk ? "100px" : Desktop ? "180px" : "220px"}
                      width={miniDesk ? "100px" : Desktop ? "180px" : "220px"}
                      questionInfo={questionLists?.result[currentQuestion]}
                    />

                    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 items-center flex justify-center text-center">
                      {questionLists?.result[currentQuestion]?.passage && (
                        <div className="flex items-center ml-auto cursor-pointer font-quitery">
                          <Text
                            onClick={() => setOpen(true)}
                            className="px-3 desktop:text-[12px] py-2 font-extrabold text-white bg-white rounded-full blinkAnimation bg-opacity-10 backdrop-blur-md"
                          >
                            Read Passage{" "}
                            <Grading className="desktop:!text-[14px]" />
                          </Text>
                        </div>
                      )}
                    </div>
                  </div>

                  <Timer
                    isApti={true}
                    isStartTime={true}
                    totalTime={80}
                    mainClassName="mt-auto miniDesk:top-[10px]  top-[18px] !absolute  left-[calc(50%-41px)] !w-fit "
                    imageClass="absolute min-w-[100px]  right-[3%]"
                    textClassName=" !px-3  !py-2 !text-white !relative !z-10 !rounded-full "
                    bgImage={medias.images.aptitude.timebg}
                  />

                  <div className="flex justify-center">
                    <Options
                      mainClass="max-w-[80%]"
                      addQestion={addApptitude}
                      isApti={true}
                      optionClass={` max-h-[230px] !h-full !px-[20px] desktop:!min-w-[100px]  miniDesk:px-[20px] no-scrollbar ${
                        Desktop ? "!min-w-[140px]" : "!min-w-[150px]"
                      }  !min-h-[50px]  `}
                      selected={apptitude}
                      isSubmitted={isSubmitted}
                      setIsSubmitted={setIsSubmitted}
                      submitQuestion={submitQuestion}
                      total_question={questionLists?.total_count}
                      currentQuestion={currentQuestion}
                      setCurrentQuestion={setCurrentQuestion}
                      questionInfo={questionLists?.result[currentQuestion]}
                    />
                  </div>
                </>
              ) : (
                <div className="flex items-center justify-center w-full h-full">
                  <CircularProgress
                    classes={{
                      root: "!text-[#fff]",
                    }}
                  />
                </div>
              )}
            </div>
            <div className="flex gap-[28px] mt-[30px] desktop:mt-[50px] w-full items-center justify-center">
              <div
                onClick={() => {
                  if (currentQuestion > 0) {
                    setCurrentQuestion(currentQuestion - 1);
                    setIsReachedLast(false);
                  }
                }}
                className={`cursor-pointer desktop:h-[50px] desktop:px-[15px] ${
                  currentQuestion == 0
                    ? "bg-gray-400 cursor-not-allowed "
                    : "bg-[#E12CB6]"
                } desktop:h-[50px] desktop:px-[15px] h-[84px] ${
                  currentQuestion == 0
                    ? "shadow-[0px_10px_0px_0px_rgba(248,249,245)]"
                    : "shadow-[0px_10px_0px_0px_rgba(161,13,127,1)]"
                } h-[84px] py-[21px] px-[23px]  flex items-center justify-center rounded-[24px]`}
              >
                <KeyboardArrowLeftRounded className="w-[35px] desktop:h-[20px] desktop:w-[20px] h-[35px] bg-[#fff] bg-opacity-[19%] rounded-full text-white" />
              </div>
              <div
                onClick={() => {
                  if (
                    apptitude?.find((data: any) => {
                      return (
                        questionLists?.result[currentQuestion]?.id ===
                          data?.id && data?.answer
                      );
                    })
                  ) {
                    const currentSubmitted: any = [...submitted];
                    const valueIndex =
                      currentSubmitted.indexOf(currentQuestion);
                    if (valueIndex > -1) {
                      currentSubmitted.splice(valueIndex, 1);
                    }
                    setSubmitted([...currentSubmitted]);

                    if (!later?.includes(currentQuestion)) {
                      console.log("currentQuestion", currentQuestion);
                      console.log("later", later);
                      setLater([...later, currentQuestion]);
                    }
                    if (currentQuestion < 79) {
                      setCurrentQuestion(currentQuestion + 1);
                    }
                  } else {
                    toast.info("Please select an answer");
                  }
                }}
                className="h-[84px] px-5  cursor-pointer text-[22px] desktop:h-[50px] desktop:px-[15px] desktop:text-[14px] text-center max-w-[210px] bg-[#E12CB6] font-ibmPlexMonoRegular text-white shadow-[0px_10px_0px_0px_rgba(161,13,127,1)] flex items-center justify-center rounded-[24px]"
              >
                Review later
              </div>
              {currentQuestion < 80 && (
                <div
                  onClick={() => {
                    if (
                      apptitude?.find((data: any) => {
                        return (
                          questionLists?.result[currentQuestion]?.id ===
                            data?.id && data?.answer
                        );
                      })
                    ) {
                      const currentlater: any = [...later];
                      console.log(
                        "currentlater",
                        currentlater,
                        "current Ques",
                        currentQuestion
                      );
                      const valueIndex = currentlater.indexOf(currentQuestion);
                      if (valueIndex > -1) {
                        currentlater.splice(valueIndex, 1);
                      }

                      if (!currentlater?.includes(currentQuestion)) {
                        setLater([...currentlater]);
                      }
                      setSubmitted([...submitted, currentQuestion]);
                      if (currentQuestion < 79) {
                        setCurrentQuestion(currentQuestion + 1);
                      }

                      // const currentlater: any = [...later];
                      // const valueIndex = currentlater.indexOf(currentQuestion);
                      // if (valueIndex > -1) {
                      //   currentlater.splice(valueIndex, 1);
                      // }

                      // setLater([...currentlater]);

                      // if (!currentlater?.includes(currentQuestion)) {
                      //   setSubmitted([...submitted, currentQuestion]);
                      // }

                      // setCurrentQuestion(currentQuestion + 1);
                    } else {
                      toast.info("Please select an answer");
                    }
                  }}
                  className="py-[21px] cursor-pointer h-[84px] px-[18px]  desktop:h-[50px] desktop:px-[15px] desktop:text-[14px] bg-[#E12CB6] max-w-[210px] text-[22px] font-ibmPlexMonoRegular text-white shadow-[0px_10px_0px_0px_rgba(161,13,127,1)] flex items-center justify-center rounded-[24px]"
                >
                  Submit Answer
                </div>
              )}
              {isReachedLast && (
                <div
                  onClick={() => {
                    submitQuestion();
                  }}
                  className="py-[21px] cursor-pointer h-[84px] px-[18px] bg-[#E12CB6]  desktop:h-[50px] desktop:px-[15px] desktop:text-[14px] max-w-[210px] text-[22px] font-ibmPlexMonoRegular text-white shadow-[0px_10px_0px_0px_rgba(161,13,127,1)] flex items-center justify-center rounded-[24px]"
                >
                  Submit Test
                </div>
              )}
              <div
                onClick={() => {
                  if (currentQuestion < 80) {
                    const questionInfo = questionLists?.result[currentQuestion];
                    if (
                      apptitude.find(
                        (item: any) => item?.id === questionInfo?.id
                      ) &&
                      submitted.indexOf(currentQuestion) === -1 &&
                      later.indexOf(currentQuestion) === -1
                    ) {
                      setSubmitted([...submitted, currentQuestion]);
                    }
                    setCurrentQuestion(
                      isReachedLast ? currentQuestion : currentQuestion + 1
                    );
                  }
                }}
                className={`py-[21px] cursor-pointer px-[23px] ${
                  isReachedLast
                    ? "bg-gray-400 cursor-not-allowed "
                    : "bg-[#E12CB6]"
                } desktop:h-[50px] desktop:px-[15px] h-[84px] ${
                  isReachedLast
                    ? "shadow-[0px_10px_0px_0px_rgba(248,249,245)]"
                    : "shadow-[0px_10px_0px_0px_rgba(161,13,127,1)]"
                } flex items-center justify-center rounded-[24px]`}
              >
                <KeyboardArrowRightRounded className="w-[35px] h-[35px] desktop:h-[20px] desktop:w-[20px] bg-[#fff] bg-opacity-[19%] rounded-full text-white" />
              </div>
            </div>
          </div>
        </div>
        <div className="w-[24%]  h-[88%] mt-auto overflow-auto rounded-[10px] bg-[#021D47] no-scrollbar pb-[22px] px-[20px] miniDesk:!pl-[5px]">
          <div className="flex mr-[2px] flex-col sticky desktop:gap-[15px] bg-[#021D47] py-[22px] top-0 miniDesk:w-[107%] w-full  desktop:py-[10px] gap-[27px]">
            <Text className="text-white sticky top-0 text-[28px] desktop:text-[18px] font-quitery">
              Test Summary
            </Text>
            <div className="p-[6px] font-ibmPlexMonoRegular flex desktop:text-[12px] items-center  !bg-[#F2E559] !font-extrabold rounded-[12px]">
              <div className="px-[16px] pt-[11px] bg-[#0C264F] pb-[14px] text-white rounded-[11px] desktop:py-[4px] mr-[12px]">
                {removeDuplicates(later).length || 0}
              </div>{" "}
              Review later
            </div>
            <div className="p-[6px] flex items-center desktop:text-[12px] font-ibmPlexMonoRegular bg-[#1CD83C] rounded-[12px] !font-medium">
              <div className="px-[16px] pt-[11px] pb-[14px] bg-[#0C264F] text-white rounded-[11px] desktop:py-[4px]  mr-[12px]">
                {/* {submitted.length || 0} */}

                {removeDuplicates(submitted).length || 0}
              </div>{" "}
              Attempted
            </div>
            <div className="p-[6px] flex bg-[#FF5A5A]  desktop:text-[12px] items-center font-ibmPlexMonoRegular rounded-[12px] !font-medium">
              <div className="px-[16px] pt-[11px] pb-[14px] bg-[#0C264F] text-white rounded-[11px] desktop:py-[4px] mr-[12px]">
                {questionLists?.total_count - apptitude?.length >= 1
                  ? questionLists?.total_count - apptitude?.length
                  : 0}
              </div>{" "}
              Not Attempted
            </div>
          </div>
          <div className="  items-center  justify-center mt-[24px]  grid grid-cols-5 gap-[6px] desktop:mt-[10px]">
            {[...Array(questionLists?.total_count)].map((_, index) => {
              return (
                <>
                  <div
                    onClick={() => setCurrentQuestion(index)}
                    className={classNames(
                      " min-w-[35px] min-h-[35px] 3xlDesk:!min-w-[50px] 3xlDesk:!min-h-[50px] cursor-pointer flex justify-center rounded-[10px] text-white items-center  max-w-fit ",
                      later.includes(index)
                        ? "bg-[#f2e55960] border border-[#F2E559]"
                        : submitted.includes(index)
                        ? "bg-[#1CD83C30] border !border-[#1CD83C]"
                        : currentQuestion === index
                        ? "bg-[white] bg-opacity-20 border-white border"
                        : "bg-[#FF5A5A1F] border border-[#FF5A5A]"
                    )}
                  >
                    {index + 1}
                  </div>
                </>
              );
            })}
          </div>
        </div>

        {/* <div className='absolute top-0 right-[calc(0%+50px)] z-0'>
                    <Player
                        autoplay
                        speed={1.5}
                        loop
                        src={planet}
                        style={{ height: "180px", padding: 0, width: "180px" }}
                    >
                        <Controls
                            visible={false}
                            buttons={[
                                "play",
                                "repeat",
                                "frame",
                                "debug",
                                "snapshot",
                                "background"
                            ]}
                        />
                    </Player>
                </div> */}
      </div>
    </>
  );
};

export default Aptitude;
