import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";

const FutureGrowthGraph = () => {
	const chartRef = useRef(null);

	useEffect(() => {
		const xValues = ["0-1years", "2-5years", "5-10years"];
		const yValues = [10, 2, 10];
		const barColors = ["#F0BC5E", "#F0BC5E", "#F0BC5E"];

		const maxDataValue = Math.max(...yValues);

		if (chartRef.current !== null) {
			chartRef.current.destroy();
		}

		const ctx = document.getElementById("myChart").getContext("2d");
		const newChart = new Chart(ctx, {
			type: "bar",
			data: {
				labels: xValues,
				datasets: [
					{
						backgroundColor: barColors,
						data: yValues,
					},
				],
			},
			options: {
				plugins: {
					legend: { display: false },
					title: {
						display: true,
						text: "Growth",
					},
				},
				scales: {
					y: {
						beginAtZero: true,
						ticks: {
							stepSize: 2,
							max: Math.ceil(maxDataValue / 2) * 2,
						},
					},
				},
			},
		});

		chartRef.current = newChart;

		return () => {
			if (chartRef.current !== null) {
				chartRef.current.destroy();
			}
		};
	}, []);

	return <canvas id="myChart" className="w-full rounded max-w-[600px]" />;
};

export default FutureGrowthGraph;
