import { Search } from "@mui/icons-material";
import { Input } from "@mui/material";

const FilterSearch = ({ setSearchSpecialisation }) => {
  return (
    <div className=" rounded-[15px] flex 3xl:rounded-lg overflow-hidden w-full !bg-[#F2F2F2] ">
      <div className="h-[30px] desktop:!h-[38px] desktop:px-[10px] 3xl:h-[36px] !bg-[#F2F2F2] px-6  miniDesk:px-[10px] flex items-center gap-x-4 ">
        <Search className="text-Charcoal-1 desktop:text-[20px]" />
        <Input
          onChange={(e) => {
            setSearchSpecialisation(e.target.value);
          }}
          classes={{
            input:
              "w-full  !border-none !outline-none !placeholder:text-black !bg-[#F2F2F2] ",
          }}
          className="h-[62px] desktop:text-[12px] before:!border-none after:!border-none w-full !border-none    !bg-[#F2F2F2]"
          placeholder="Search"
        />
      </div>
    </div>
  );
};

export default FilterSearch;
