import React from "react";
import Image from "../../Components/Image";
import Text from "../../Components/Text";
import { guidancePageContant } from "../../config/pageContent";
import { medias } from "../../config/Resources";
import Face1 from "../../Components/Faces/Face1";
import Face2 from "../../Components/Faces/Face2";
import Face3 from "../../Components/Faces/Face3";
import Face4 from "../../Components/Faces/Face4";
import Face5 from "../../Components/Faces/Face5";
import Face6 from "../../Components/Faces/Face6";
import Face7 from "../../Components/Faces/Face7";
import Face8 from "../../Components/Faces/Face8";
import Face9 from "../../Components/Faces/Face9";
import Face10 from "../../Components/Faces/Face10";
import Face11 from "../../Components/Faces/Face11";
import Face12 from "../../Components/Faces/Face12";
import Face13 from "../../Components/Faces/Face13";

// const Face1 = React.lazy(() => import("../../Components/Faces/Face1"));
// const Face2 = React.lazy(() => import("../../Components/Faces/Face2"));
// const Face3 = React.lazy(() => import("../../Components/Faces/Face3"));
// const Face4 = React.lazy(() => import("../../Components/Faces/Face4"));
// const Face5 = React.lazy(() => import("../../Components/Faces/Face5"));
// const Face6 = React.lazy(() => import("../../Components/Faces/Face6"));
// const Face7 = React.lazy(() => import("../../Components/Faces/Face7"));
// const Face8 = React.lazy(() => import("../../Components/Faces/Face8"));
// const Face9 = React.lazy(() => import("../../Components/Faces/Face9"));
// const Face10 = React.lazy(() => import("../../Components/Faces/Face10"));
// const Face11 = React.lazy(() => import("../../Components/Faces/Face11"));
// const Face12 = React.lazy(() => import("../../Components/Faces/Face12"));
// const Face13 = React.lazy(() => import("../../Components/Faces/Face13"));

type Props = {};

const Guidance = (props: Props) => {
  return (
    <div>
      <Face1
        Title={
          <>
            <div className="relative">
              <Image
                className="absolute -top-7 -left-11"
                src={medias.images.guidance.Face1TitleTopIcon}
              ></Image>
              <Text className="text-[64px] font-black PlusJakartaSans-ExtraBoldCustom ">
                {guidancePageContant.Face1.confusedAboutYourCareer}
              </Text>
            </div>
          </>
        }
        mainImage={medias.images.guidance.Face1Icon}
        description={guidancePageContant.Face1.indianInstituteOfTechnology}
      />
      <Face2
        cardContainerClass="!grid-rows-1 pb-[73px] !h-full"
        // containerClassName="nineTenthFace3bg"
        bottomClasses="!pt-[24px] !pb-[35px]"
        cardContainerImageClass={`!h-[274px]`}
        backSideClass={`!h-full !w-full !p-[30px]`}
        containerCardClasses={`!h-[370px] !w-[300px]`}
        cardBackClass={`!p-0`}
      />
      <Face3 />
      {/* <Face4 /> */}
      <Face5 />
      <Face6 />
      <Face7 />
      <Face8 />
      <Face9 />
      <Face10 />
      <Face11 />
      <Face12 />
      <Face13 />
    </div>
  );
};

export default Guidance;
