/* eslint-disable jsx-a11y/img-redundant-alt */
import { useEffect, useState } from "react";
import { medias } from "../../config/Resources";
// import { Controls, Player } from "@lottiefiles/react-lottie-player";

const BrightFutureCards = () => {
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const fetchData = async () => {
			// Simulate async data fetching with setTimeout
			await new Promise((resolve) => setTimeout(resolve, 2000));
			setIsLoading(false);
		};

		fetchData();
	}, []);
	return (
		<>
			{isLoading ? (
				<p className="flex items-center text-center justify-center">
					Loading ...
				</p>
			) : (
				<div className="flex justify-center">
					<div className="max-w-md bg-[#FFEBDC] md:max-w-[70%]  rounded-xl overflow-hidden shadow-md mt-16 mb-10">
						<div className="w-full">
							<div className="flex w-[100%]">
								<div className="item-center text-center justify-center p-10 w-[44%]">
									<p className="font-plusJakartaSans flex item-center justify-center text-center font-semibold text-[#0A0A9B] text-lg">
										Choose a
									</p>
									<p className="font-plusJakartaSans ml-auto mr-auto font-extrabold text-[#0A0A9B] text-[30px]">
										Right Career for your
									</p>
									<div className="rounded-lg mt-1 flex item-center text-center ml-auto mr-auto justify-center pt-2 pb-2 w-[50%] bg-[#0A0A9B] ">
										<p className="font-plusJakartaSans text-white font-black text-[20px]">
											Bright future
										</p>
									</div>
									<p className="flex item-center gap-2 font-plusJakartaSans font-semibold justify-center mt-1 text-center">
										<img
											className="w-32 text-[#000000]"
											src={medias.images.Profile.line2}
											alt="lin2"
										/>
										with
										<img
											className="w-32 text-[#000000]"
											src={medias.images.Profile.line1}
											alt="lin1"
										/>
									</p>
									<img
										className="flex justify-center ml-auto mr-auto items-center mt-1"
										src={medias.images.header.pagelogo}
										alt="logo"
									/>
								</div>
								<div className="relative w-[56%] ">
									<img
										src={medias.images.Profile.image5}
										alt="image 2"
										className="relative z-10 h-[100%]"
									/>
									<img
										src={medias.images.Profile.CrossButton}
										alt="crossButton"
										className="absolute h-7 w-7 top-3 right-4 z-20"
									/>
								</div>
							</div>
							<div></div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default BrightFutureCards;
