/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import Text from "../../Components/Text";
import { Link, useNavigate } from "react-router-dom";
import TextField1 from "../../Components/Textfield/TextField1";
import { medias } from "../../config/Resources";
import { Autocomplete, Button, InputAdornment, TextField } from "@mui/material";
import Image from "../../Components/Image";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import SocialAuth from "../SocialAuth";
import { useMutation, useQuery } from "@tanstack/react-query";
import axiosAdminInstance from "../../config/Axios/axiosAdminInstance";
import { API_URLS } from "../../config/API_URLS";
import { useFormik } from "formik";
import { RegisterSchema } from "../../config/Schema/LoginSignup";
import axiosInstance from "../../config/Axios/axiosInstance";
import { toast } from "react-toastify";
import { useUserInfoStore } from "../../store/userinfo";

type Props = {};

interface ResponseType {
  statusMessage?: string;
  // Add other properties based on the actual type of 'res'
}

const Register = (props: Props) => {
  const { setUserInfo, setIsLoggedin }: any = useUserInfoStore(
    (state) => state
  );
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email: "",
      countryCode: { code: "IN", label: "India", phone: "91" },
      mobile: "",
      password: "",
      firstName: "",
      lastName: "",
      middleName: "",
      country: null,
      state: null,
      city: null,
      class: "",
    },
    validationSchema: RegisterSchema,
    validateOnMount: false,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: (value: any) => {
      (async () => {
        try {
          const res: any = await axiosInstance.post(API_URLS?.checkEmail, {
            email: value?.email,
          });

          console.log(res);

          if (res?.statusCode === 200) {
            axiosInstance
              .post(API_URLS.signup, {
                ...value,
                state: value.state.state,
                city: value.city.city,
                country: value.country.name,
                class: value.class.name,
                countryCode: "+91",
                mobile: "1234567890",
              })
              .then((res: any) => {
                if (res.statusCode === 200) {
                  toast("User registered successfully", {
                    type: "success",
                  });
                  const body = {
                    email: value.email,
                    password: value.password,
                  };
                  axiosInstance
                    .post(API_URLS.login, body)
                    .then((res: any) => {
                      if (res.result.token) {
                        localStorage.setItem(
                          "token-student",
                          res?.result?.token
                        );
                        toast("User LoggedIn successfully", {
                          type: "success",
                        });
                        setIsLoggedin(true);
                        navigate(
                          window.location.origin === "http://localhost:3000" ||
                            window.location.origin ===
                              "https://techugoapps.com" ||
                            window.location.origin ===
                              "https://www.techugoapps.com"
                            ? "/sygnific_dev"
                            : "/"
                        );
                        formik.resetForm();
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                  formik.resetForm();
                }
              });
          } else {
            // console.log(res);
            toast(res?.statusMessage, {
              type: "error",
            });
          }
        } catch (error) {
          console.log(error);
          toast("SomeThing Went Wrong", {
            type: "error",
          });
        }
      })();
    },
  });

  const [VisibilityPassword, setVisibilityPassword] = useState(false);

  const {
    data,
    isLoading,
  }: {
    data: any;
    isLoading: boolean;
  } = useQuery({
    queryKey: ["fetch-state-county"],
    queryFn: () =>
      axiosAdminInstance
        .get(`${API_URLS.getAllDropdowns}?type=origin_country,state`)
        .then((res: any) => res?.result),
  });

  const { data: cityData, mutate: getCity } = useMutation({
    mutationFn: (state_id: string) =>
      axiosAdminInstance
        .get(`${API_URLS.getAllDropdowns}?type=city&state_id=${state_id}`)
        .then((res: any) => {
          return res?.result;
        }),
  });

  console.log("data", data);

  const classData = [
    { name: "9-10", id: "9-10" },
    { name: "11-12", id: "11-12" },
    { name: "graduate and professional", id: "graduate and professional" },
    { name: "school and college dropouts", id: "school and college dropouts" },
  ];
  return (
    <div>
      <div className="flex justify-between ">
        <Text className="text-[24px] !font-[PlusJakartaSans-Bold] desktop:text-[18px]">
          Enter Register Details
        </Text>
        <Link
          to="/login-signup/login"
          className="text-[24px] !font-[PlusJakartaSans-Bold] desktop:text-[18px] text-[#2771E7] "
        >
          Login Here
        </Link>
      </div>
      <form
        autoComplete="false"
        onSubmit={formik.handleSubmit}
        className="w-full grid grid-cols-6 gap-[18px] miniDesk:gap-[15px] miniDesk:mt-[20px] mt-[32px]"
      >
        <div className="!w-full mob:!col-span-6 col-span-3 ">
          <TextField1
            id="firstName"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            name="firstName"
            className="!w-full"
            value={formik.values.firstName}
            placeholder="First name"
            startAdornment={
              <InputAdornment position="start">
                <Image
                  className="mr-[20px] miniDesk:w-[14px]"
                  src={medias.images.inputIcons.firstNameIcon}
                />
              </InputAdornment>
            }
          />
          {formik.touched.firstName && (
            <Text className="text-[#dc143c]">{formik.errors.firstName}</Text>
          )}
        </div>
        <div className="!w-full mob:col-span-6 col-span-3">
          <TextField1
            id="middleName"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.middleName}
            className="!w-full"
            name="middleName"
            placeholder="Middle name"
            startAdornment={
              <InputAdornment position="start">
                <Image
                  className="mr-[20px]  miniDesk:w-[14px]"
                  src={medias.images.inputIcons.firstNameIcon}
                />
              </InputAdornment>
            }
          />
          {formik.touched.middleName && (
            <Text className="text-[#dc143c]">{formik.errors.middleName}</Text>
          )}
        </div>
        <div className="!w-full miniDesk:col-span-6 col-span-6">
          <TextField1
            id="lastName"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.lastName}
            className="!w-full"
            name="lastName"
            placeholder="Last name"
            startAdornment={
              <InputAdornment position="start">
                <Image
                  className="mr-[20px]  miniDesk:w-[14px]"
                  src={medias.images.inputIcons.firstNameIcon}
                />
              </InputAdornment>
            }
          />
          {formik.touched.lastName && (
            <Text className="text-[#dc143c]">{formik.errors.lastName}</Text>
          )}
        </div>
        <div className="!w-full mob:col-span-6 col-span-6">
          <TextField1
            placeholder="Email ID"
            type="email"
            value={formik.values.email}
            className="!w-full"
            // id="email"
            autoComplete="off"
            role="presentation"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            name="email"
            startAdornment={
              <InputAdornment position="start">
                <Image
                  className="mr-[20px]  miniDesk:w-[14px]"
                  src={medias.images.inputIcons.EmailInputIcon}
                />
              </InputAdornment>
            }
          />

          {formik.touched.email && (
            <Text className="text-[#dc143c]">{formik.errors.email}</Text>
          )}
        </div>
        {/* <div className="!w-full mob:col-span-6 col-span-3">
          <TextField1
            className="!w-full"
            placeholder="Password"
            role="presentation"
            autoComplete="new-passward"
            type={VisibilityPassword ? "text" : "password"}
            // id="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            name="password"
            startAdornment={
              <InputAdornment position="start">
                <Image
                  className="mr-[20px] miniDesk:w-[14px]"
                  src={medias.images.inputIcons.PasswordInputIcon}
                />
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment position="end">
                {VisibilityPassword ? (
                  <Visibility
                    className="cursor-pointer"
                    onClick={() => setVisibilityPassword(false)}
                  />
                ) : (
                  <VisibilityOff
                    className="cursor-pointer"
                    onClick={() => setVisibilityPassword(true)}
                  />
                )}
              </InputAdornment>
            }
          />
          {formik.touched.password && (
            <Text className="text-[#dc143c]">{formik.errors.password}</Text>
          )}
        </div> */}
        <div className="!w-full miniDesk:col-span-6 !gap-x-4 col-span-6 flex">
          <div className="!w-[30%]">
            <Autocomplete
              getOptionLabel={(option: any) =>
                `+${option.phone} ${option.label} `
              }
              options={countries}
              className="!w-full !border rounded-xl"
              onBlur={formik.handleBlur}
              value={formik?.values?.countryCode}
              onChange={(event: any, newValue: any | null) => {
                formik.setFieldValue("countryCode", newValue);
              }}
              loading={isLoading}
              renderInput={(params) => (
                <TextField
                  name="countryCode"
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    classes: {
                      input: "miniDesk:!text-[14px] miniDesk:!py-0",
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <Image
                          className=" miniDesk:w-[14px]"
                          src={`https://flagcdn.com/w20/${formik?.values?.countryCode?.code?.toLowerCase()}.png`}
                        />
                      </InputAdornment>
                    ),
                  }}
                  placeholder={"+91"}
                />
              )}
            />
            {formik.touched?.countryCode && (
              <Text className="text-[#dc143c]">
                {formik.errors.countryCode}
              </Text>
            )}
          </div>
          <div className="!w-[70%]">
            <TextField1
              id="mobile"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.mobile}
              className="!w-full"
              name="mobile"
              placeholder="Mobile Number"
              startAdornment={
                <InputAdornment position="start">
                  <Image
                    className="mr-[20px]  miniDesk:w-[14px]"
                    src={medias.images.inputIcons.mobileLogo}
                  />
                </InputAdornment>
              }
            />
            {formik.touched.mobile && (
              <Text className="text-[#dc143c]">{formik.errors.mobile}</Text>
            )}
          </div>
        </div>
        <div className="!col-span-2 desktop:!col-span-6 w-full">
          <Autocomplete
            getOptionLabel={(option: any) => option?.name}
            options={data?.origin_country}
            disablePortal
            disableClearable
            className="!w-full !border rounded-xl"
            onBlur={formik.handleBlur}
            clearIcon={false}
            // value={formik.values.country}
            onChange={(event: any, newValue: string | null) => {
              formik.setFieldValue("country", newValue);
            }}
            // value={formik?.values?.country  ||  data?.origin_country ? data?.origin_country[0] : {}}

            loading={isLoading}
            renderInput={(params) => (
              <TextField
                name="country"
                {...params}
                InputProps={{
                  ...params.InputProps,
                  classes: {
                    input: "miniDesk:!text-[14px] miniDesk:!py-0",
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      <Image
                        className="mr-[1px]  miniDesk:w-[14px]"
                        src={medias.images.inputIcons.countryIcon}
                      />
                    </InputAdornment>
                  ),
                }}
                placeholder={"County"}
              />
            )}
          />
          {/* <Select
                        displayEmpty
                        className='!w-full'
                        placeholder='County'
                        id="country"
                        value={formik.values.country}

                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="country"
                        startAdornment={
                            <InputAdornment position="start">
                                <Image src={medias.images.inputIcons.countryIcon} alt="country" />
                            </InputAdornment>
                        }
                        defaultValue={""}
                        classes={{
                            root: "!border !rounded-[14px] !border-[#70707037]",
                        }}
                        inputProps={{ 'aria-label': 'Without label', className: "w-full !outline-none !text-[17px] !placeholder:opacity-50 !placeholder:text-black" }}
                    >
                        <MenuItem value="" className='!hidden' selected hidden>
                            <em>County</em>
                        </MenuItem>
                        {
                            data?.origin_country?.map((items: any) => {
                                return <MenuItem key={items.id} value={items}>
                                    {items.name}
                                </MenuItem>

                            })
                        }

                    </Select> */}
          {formik.touched.country && (
            <Text className="text-[#dc143c]">{formik.errors.country}</Text>
          )}
        </div>
        <div className="!col-span-2 desktop:!col-span-3 mob:!col-span-6 w-full">
          <Autocomplete
            getOptionLabel={(option: any) => option?.state}
            options={data?.state}
            disablePortal
            className="!w-full !border rounded-xl"
            onBlur={formik.handleBlur}
            // value={formik.values.country}
            disableClearable
            value={formik?.values?.state}
            onChange={(event: any, newValue: any | null) => {
              formik.setFieldValue("state", null);
              getCity(newValue.state_id);
              formik.setFieldValue("state", newValue);
            }}
            loading={isLoading}
            renderInput={(params) => (
              <TextField
                name="state"
                {...params}
                InputProps={{
                  ...params.InputProps,
                  classes: {
                    input: "miniDesk:!text-[14px] miniDesk:!py-0",
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      <Image
                        className="mr-[1px]  miniDesk:w-[14px]"
                        src={medias.images.inputIcons.stateIcon}
                      />
                    </InputAdornment>
                  ),
                }}
                placeholder={"State"}
              />
            )}
          />
          {/* <Select
                        displayEmpty
                        className='!w-full'
                        placeholder='State'
                        id="state"
                        value={formik.values.state}
                        onChange={(e: any) => { formik.handleChange(e) }}
                        onBlur={formik.handleBlur}
                        name="state"
                        startAdornment={
                            <InputAdornment position="start">
                                <Image src={medias.images.inputIcons.countryIcon} alt="country" />
                            </InputAdornment>
                        }
                        defaultValue={""}
                        classes={{
                            root: "!border !rounded-[14px] !border-[#70707037]",
                        }}
                        inputProps={{ 'aria-label': 'Without label', className: "w-full !outline-none !text-[17px] !placeholder:opacity-50 !placeholder:text-black" }}
                    >
                        <MenuItem value="" className='!hidden' selected hidden>
                            <em>State</em>
                        </MenuItem>
                        {
                            data?.state?.map((items: any) => {
                                return <MenuItem key={items.id} value={items}>
                                    {items.state}
                                </MenuItem>

                            })
                        }

                    </Select> */}
          {formik.touched.state && (
            <Text className="text-[#dc143c]">{formik.errors.state}</Text>
          )}
        </div>
        <div className="!col-span-2 desktop:!col-span-3 mob:!col-span-6 w-full">
          <Autocomplete
            getOptionLabel={(option: any) => option?.city}
            options={cityData?.city || []}
            disablePortal
            disableClearable
            disabled={!Boolean(formik.values.state)}
            className="!w-full !border rounded-xl"
            onBlur={formik.handleBlur}
            value={formik?.values?.city}
            // value={formik.values.country}
            onChange={(event: any, newValue: any | null) => {
              formik.setFieldValue("city", newValue);
            }}
            loading={isLoading}
            renderInput={(params) => (
              <TextField
                name="city"
                {...params}
                InputProps={{
                  ...params.InputProps,
                  classes: {
                    input: "miniDesk:!text-[14px] miniDesk:!py-0",
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      <Image
                        className="mr-[1px]  miniDesk:w-[14px]"
                        src={medias.images.inputIcons.cityIcon}
                      />
                    </InputAdornment>
                  ),
                }}
                placeholder={"City"}
              />
            )}
          />
          {formik.touched.city && (
            <Text className="text-[#dc143c]">{formik.errors.city}</Text>
          )}
        </div>

        {/* class */}

        <div className="!col-span-6 desktop:!col-span-6 w-full">
          <Autocomplete
            getOptionLabel={(option: any) => option?.name}
            options={classData}
            disablePortal
            disableClearable
            className="!w-full !border rounded-xl"
            onBlur={formik.handleBlur}
            clearIcon={false}
            // value={formik.values.country}
            onChange={(event: any, newValue: any | null) => {
              formik.setFieldValue("class", newValue);
              // console.log("data", newValue?.name);
            }}
            // value={formik?.values?.country  ||  data?.origin_country ? data?.origin_country[0] : {}}

            loading={isLoading}
            renderInput={(params) => (
              <TextField
                name="class"
                {...params}
                InputProps={{
                  ...params.InputProps,
                  classes: {
                    input: "miniDesk:!text-[14px] miniDesk:!py-0",
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      <Image
                        className="mr-[20px]  miniDesk:w-[14px]"
                        src={medias.images.inputIcons.firstNameIcon}
                      />
                    </InputAdornment>
                  ),
                }}
                placeholder={"Select your Class"}
              />
            )}
          />

          {formik.touched.class && (
            <Text className="text-[#dc143c]">{formik.errors.class}</Text>
          )}
        </div>
        <Button
          type="submit"
          className="!text-[white] miniDesk:!py-[10px] !capitalize !text-[24px] miniDesk:!text-[16px] !col-span-6 !py-[18px] w-full !rounded-[13px]  !font-[600] !bg-[#239403]"
        >
          Register
        </Button>
      </form>
      <div className="relative flex items-center mt-[20px] justify-center w-full text-center">
        <div className="h-[1px] absolute z-10 top-[50%] w-full bg-[#70707041]"></div>
        <Text className="relative z-20 px-2 text-[#00000048] font-semibold bg-[white] w-fit">
          OR
        </Text>
      </div>
      <div>
        <SocialAuth />
      </div>
    </div>
  );
};

export default Register;

interface CountryType {
  code: string;
  label: string;
  phone: string;
  suggested?: boolean;
}

const countries: readonly CountryType[] = [
  { code: "AD", label: "Andorra", phone: "376" },
  {
    code: "AE",
    label: "United Arab Emirates",
    phone: "971",
  },
  { code: "AF", label: "Afghanistan", phone: "93" },
  {
    code: "AG",
    label: "Antigua and Barbuda",
    phone: "1-268",
  },
  { code: "AI", label: "Anguilla", phone: "1-264" },
  { code: "AL", label: "Albania", phone: "355" },
  { code: "AM", label: "Armenia", phone: "374" },
  { code: "AO", label: "Angola", phone: "244" },
  { code: "AQ", label: "Antarctica", phone: "672" },
  { code: "AR", label: "Argentina", phone: "54" },
  { code: "AS", label: "American Samoa", phone: "1-684" },
  { code: "AT", label: "Austria", phone: "43" },
  {
    code: "AU",
    label: "Australia",
    phone: "61",
    suggested: true,
  },
  { code: "AW", label: "Aruba", phone: "297" },
  { code: "AX", label: "Alland Islands", phone: "358" },
  { code: "AZ", label: "Azerbaijan", phone: "994" },
  {
    code: "BA",
    label: "Bosnia and Herzegovina",
    phone: "387",
  },
  { code: "BB", label: "Barbados", phone: "1-246" },
  { code: "BD", label: "Bangladesh", phone: "880" },
  { code: "BE", label: "Belgium", phone: "32" },
  { code: "BF", label: "Burkina Faso", phone: "226" },
  { code: "BG", label: "Bulgaria", phone: "359" },
  { code: "BH", label: "Bahrain", phone: "973" },
  { code: "BI", label: "Burundi", phone: "257" },
  { code: "BJ", label: "Benin", phone: "229" },
  { code: "BL", label: "Saint Barthelemy", phone: "590" },
  { code: "BM", label: "Bermuda", phone: "1-441" },
  { code: "BN", label: "Brunei Darussalam", phone: "673" },
  { code: "BO", label: "Bolivia", phone: "591" },
  { code: "BR", label: "Brazil", phone: "55" },
  { code: "BS", label: "Bahamas", phone: "1-242" },
  { code: "BT", label: "Bhutan", phone: "975" },
  { code: "BV", label: "Bouvet Island", phone: "47" },
  { code: "BW", label: "Botswana", phone: "267" },
  { code: "BY", label: "Belarus", phone: "375" },
  { code: "BZ", label: "Belize", phone: "501" },
  {
    code: "CA",
    label: "Canada",
    phone: "1",
    suggested: true,
  },
  {
    code: "CC",
    label: "Cocos (Keeling) Islands",
    phone: "61",
  },
  {
    code: "CD",
    label: "Congo, Democratic Republic of the",
    phone: "243",
  },
  {
    code: "CF",
    label: "Central African Republic",
    phone: "236",
  },
  {
    code: "CG",
    label: "Congo, Republic of the",
    phone: "242",
  },
  { code: "CH", label: "Switzerland", phone: "41" },
  { code: "CI", label: "Cote d'Ivoire", phone: "225" },
  { code: "CK", label: "Cook Islands", phone: "682" },
  { code: "CL", label: "Chile", phone: "56" },
  { code: "CM", label: "Cameroon", phone: "237" },
  { code: "CN", label: "China", phone: "86" },
  { code: "CO", label: "Colombia", phone: "57" },
  { code: "CR", label: "Costa Rica", phone: "506" },
  { code: "CU", label: "Cuba", phone: "53" },
  { code: "CV", label: "Cape Verde", phone: "238" },
  { code: "CW", label: "Curacao", phone: "599" },
  { code: "CX", label: "Christmas Island", phone: "61" },
  { code: "CY", label: "Cyprus", phone: "357" },
  { code: "CZ", label: "Czech Republic", phone: "420" },
  {
    code: "DE",
    label: "Germany",
    phone: "49",
    suggested: true,
  },
  { code: "DJ", label: "Djibouti", phone: "253" },
  { code: "DK", label: "Denmark", phone: "45" },
  { code: "DM", label: "Dominica", phone: "1-767" },
  {
    code: "DO",
    label: "Dominican Republic",
    phone: "1-809",
  },
  { code: "DZ", label: "Algeria", phone: "213" },
  { code: "EC", label: "Ecuador", phone: "593" },
  { code: "EE", label: "Estonia", phone: "372" },
  { code: "EG", label: "Egypt", phone: "20" },
  { code: "EH", label: "Western Sahara", phone: "212" },
  { code: "ER", label: "Eritrea", phone: "291" },
  { code: "ES", label: "Spain", phone: "34" },
  { code: "ET", label: "Ethiopia", phone: "251" },
  { code: "FI", label: "Finland", phone: "358" },
  { code: "FJ", label: "Fiji", phone: "679" },
  {
    code: "FK",
    label: "Falkland Islands (Malvinas)",
    phone: "500",
  },
  {
    code: "FM",
    label: "Micronesia, Federated States of",
    phone: "691",
  },
  { code: "FO", label: "Faroe Islands", phone: "298" },
  {
    code: "FR",
    label: "France",
    phone: "33",
    suggested: true,
  },
  { code: "GA", label: "Gabon", phone: "241" },
  { code: "GB", label: "United Kingdom", phone: "44" },
  { code: "GD", label: "Grenada", phone: "1-473" },
  { code: "GE", label: "Georgia", phone: "995" },
  { code: "GF", label: "French Guiana", phone: "594" },
  { code: "GG", label: "Guernsey", phone: "44" },
  { code: "GH", label: "Ghana", phone: "233" },
  { code: "GI", label: "Gibraltar", phone: "350" },
  { code: "GL", label: "Greenland", phone: "299" },
  { code: "GM", label: "Gambia", phone: "220" },
  { code: "GN", label: "Guinea", phone: "224" },
  { code: "GP", label: "Guadeloupe", phone: "590" },
  { code: "GQ", label: "Equatorial Guinea", phone: "240" },
  { code: "GR", label: "Greece", phone: "30" },
  {
    code: "GS",
    label: "South Georgia and the South Sandwich Islands",
    phone: "500",
  },
  { code: "GT", label: "Guatemala", phone: "502" },
  { code: "GU", label: "Guam", phone: "1-671" },
  { code: "GW", label: "Guinea-Bissau", phone: "245" },
  { code: "GY", label: "Guyana", phone: "592" },
  { code: "HK", label: "Hong Kong", phone: "852" },
  {
    code: "HM",
    label: "Heard Island and McDonald Islands",
    phone: "672",
  },
  { code: "HN", label: "Honduras", phone: "504" },
  { code: "HR", label: "Croatia", phone: "385" },
  { code: "HT", label: "Haiti", phone: "509" },
  { code: "HU", label: "Hungary", phone: "36" },
  { code: "ID", label: "Indonesia", phone: "62" },
  { code: "IE", label: "Ireland", phone: "353" },
  { code: "IL", label: "Israel", phone: "972" },
  { code: "IM", label: "Isle of Man", phone: "44" },
  { code: "IN", label: "India", phone: "91" },
  {
    code: "IO",
    label: "British Indian Ocean Territory",
    phone: "246",
  },
  { code: "IQ", label: "Iraq", phone: "964" },
  {
    code: "IR",
    label: "Iran, Islamic Republic of",
    phone: "98",
  },
  { code: "IS", label: "Iceland", phone: "354" },
  { code: "IT", label: "Italy", phone: "39" },
  { code: "JE", label: "Jersey", phone: "44" },
  { code: "JM", label: "Jamaica", phone: "1-876" },
  { code: "JO", label: "Jordan", phone: "962" },
  {
    code: "JP",
    label: "Japan",
    phone: "81",
    suggested: true,
  },
  { code: "KE", label: "Kenya", phone: "254" },
  { code: "KG", label: "Kyrgyzstan", phone: "996" },
  { code: "KH", label: "Cambodia", phone: "855" },
  { code: "KI", label: "Kiribati", phone: "686" },
  { code: "KM", label: "Comoros", phone: "269" },
  {
    code: "KN",
    label: "Saint Kitts and Nevis",
    phone: "1-869",
  },
  {
    code: "KP",
    label: "Korea, Democratic People's Republic of",
    phone: "850",
  },
  { code: "KR", label: "Korea, Republic of", phone: "82" },
  { code: "KW", label: "Kuwait", phone: "965" },
  { code: "KY", label: "Cayman Islands", phone: "1-345" },
  { code: "KZ", label: "Kazakhstan", phone: "7" },
  {
    code: "LA",
    label: "Lao People's Democratic Republic",
    phone: "856",
  },
  { code: "LB", label: "Lebanon", phone: "961" },
  { code: "LC", label: "Saint Lucia", phone: "1-758" },
  { code: "LI", label: "Liechtenstein", phone: "423" },
  { code: "LK", label: "Sri Lanka", phone: "94" },
  { code: "LR", label: "Liberia", phone: "231" },
  { code: "LS", label: "Lesotho", phone: "266" },
  { code: "LT", label: "Lithuania", phone: "370" },
  { code: "LU", label: "Luxembourg", phone: "352" },
  { code: "LV", label: "Latvia", phone: "371" },
  { code: "LY", label: "Libya", phone: "218" },
  { code: "MA", label: "Morocco", phone: "212" },
  { code: "MC", label: "Monaco", phone: "377" },
  {
    code: "MD",
    label: "Moldova, Republic of",
    phone: "373",
  },
  { code: "ME", label: "Montenegro", phone: "382" },
  {
    code: "MF",
    label: "Saint Martin (French part)",
    phone: "590",
  },
  { code: "MG", label: "Madagascar", phone: "261" },
  { code: "MH", label: "Marshall Islands", phone: "692" },
  {
    code: "MK",
    label: "Macedonia, the Former Yugoslav Republic of",
    phone: "389",
  },
  { code: "ML", label: "Mali", phone: "223" },
  { code: "MM", label: "Myanmar", phone: "95" },
  { code: "MN", label: "Mongolia", phone: "976" },
  { code: "MO", label: "Macao", phone: "853" },
  {
    code: "MP",
    label: "Northern Mariana Islands",
    phone: "1-670",
  },
  { code: "MQ", label: "Martinique", phone: "596" },
  { code: "MR", label: "Mauritania", phone: "222" },
  { code: "MS", label: "Montserrat", phone: "1-664" },
  { code: "MT", label: "Malta", phone: "356" },
  { code: "MU", label: "Mauritius", phone: "230" },
  { code: "MV", label: "Maldives", phone: "960" },
  { code: "MW", label: "Malawi", phone: "265" },
  { code: "MX", label: "Mexico", phone: "52" },
  { code: "MY", label: "Malaysia", phone: "60" },
  { code: "MZ", label: "Mozambique", phone: "258" },
  { code: "NA", label: "Namibia", phone: "264" },
  { code: "NC", label: "New Caledonia", phone: "687" },
  { code: "NE", label: "Niger", phone: "227" },
  { code: "NF", label: "Norfolk Island", phone: "672" },
  { code: "NG", label: "Nigeria", phone: "234" },
  { code: "NI", label: "Nicaragua", phone: "505" },
  { code: "NL", label: "Netherlands", phone: "31" },
  { code: "NO", label: "Norway", phone: "47" },
  { code: "NP", label: "Nepal", phone: "977" },
  { code: "NR", label: "Nauru", phone: "674" },
  { code: "NU", label: "Niue", phone: "683" },
  { code: "NZ", label: "New Zealand", phone: "64" },
  { code: "OM", label: "Oman", phone: "968" },
  { code: "PA", label: "Panama", phone: "507" },
  { code: "PE", label: "Peru", phone: "51" },
  { code: "PF", label: "French Polynesia", phone: "689" },
  { code: "PG", label: "Papua New Guinea", phone: "675" },
  { code: "PH", label: "Philippines", phone: "63" },
  { code: "PK", label: "Pakistan", phone: "92" },
  { code: "PL", label: "Poland", phone: "48" },
  {
    code: "PM",
    label: "Saint Pierre and Miquelon",
    phone: "508",
  },
  { code: "PN", label: "Pitcairn", phone: "870" },
  { code: "PR", label: "Puerto Rico", phone: "1" },
  {
    code: "PS",
    label: "Palestine, State of",
    phone: "970",
  },
  { code: "PT", label: "Portugal", phone: "351" },
  { code: "PW", label: "Palau", phone: "680" },
  { code: "PY", label: "Paraguay", phone: "595" },
  { code: "QA", label: "Qatar", phone: "974" },
  { code: "RE", label: "Reunion", phone: "262" },
  { code: "RO", label: "Romania", phone: "40" },
  { code: "RS", label: "Serbia", phone: "381" },
  { code: "RU", label: "Russian Federation", phone: "7" },
  { code: "RW", label: "Rwanda", phone: "250" },
  { code: "SA", label: "Saudi Arabia", phone: "966" },
  { code: "SB", label: "Solomon Islands", phone: "677" },
  { code: "SC", label: "Seychelles", phone: "248" },
  { code: "SD", label: "Sudan", phone: "249" },
  { code: "SE", label: "Sweden", phone: "46" },
  { code: "SG", label: "Singapore", phone: "65" },
  { code: "SH", label: "Saint Helena", phone: "290" },
  { code: "SI", label: "Slovenia", phone: "386" },
  {
    code: "SJ",
    label: "Svalbard and Jan Mayen",
    phone: "47",
  },
  { code: "SK", label: "Slovakia", phone: "421" },
  { code: "SL", label: "Sierra Leone", phone: "232" },
  { code: "SM", label: "San Marino", phone: "378" },
  { code: "SN", label: "Senegal", phone: "221" },
  { code: "SO", label: "Somalia", phone: "252" },
  { code: "SR", label: "Suriname", phone: "597" },
  { code: "SS", label: "South Sudan", phone: "211" },
  {
    code: "ST",
    label: "Sao Tome and Principe",
    phone: "239",
  },
  { code: "SV", label: "El Salvador", phone: "503" },
  {
    code: "SX",
    label: "Sint Maarten (Dutch part)",
    phone: "1-721",
  },
  {
    code: "SY",
    label: "Syrian Arab Republic",
    phone: "963",
  },
  { code: "SZ", label: "Swaziland", phone: "268" },
  {
    code: "TC",
    label: "Turks and Caicos Islands",
    phone: "1-649",
  },
  { code: "TD", label: "Chad", phone: "235" },
  {
    code: "TF",
    label: "French Southern Territories",
    phone: "262",
  },
  { code: "TG", label: "Togo", phone: "228" },
  { code: "TH", label: "Thailand", phone: "66" },
  { code: "TJ", label: "Tajikistan", phone: "992" },
  { code: "TK", label: "Tokelau", phone: "690" },
  { code: "TL", label: "Timor-Leste", phone: "670" },
  { code: "TM", label: "Turkmenistan", phone: "993" },
  { code: "TN", label: "Tunisia", phone: "216" },
  { code: "TO", label: "Tonga", phone: "676" },
  { code: "TR", label: "Turkey", phone: "90" },
  {
    code: "TT",
    label: "Trinidad and Tobago",
    phone: "1-868",
  },
  { code: "TV", label: "Tuvalu", phone: "688" },
  {
    code: "TW",
    label: "Taiwan",
    phone: "886",
  },
  {
    code: "TZ",
    label: "United Republic of Tanzania",
    phone: "255",
  },
  { code: "UA", label: "Ukraine", phone: "380" },
  { code: "UG", label: "Uganda", phone: "256" },
  {
    code: "US",
    label: "United States",
    phone: "1",
    suggested: true,
  },
  { code: "UY", label: "Uruguay", phone: "598" },
  { code: "UZ", label: "Uzbekistan", phone: "998" },
  {
    code: "VA",
    label: "Holy See (Vatican City State)",
    phone: "379",
  },
  {
    code: "VC",
    label: "Saint Vincent and the Grenadines",
    phone: "1-784",
  },
  { code: "VE", label: "Venezuela", phone: "58" },
  {
    code: "VG",
    label: "British Virgin Islands",
    phone: "1-284",
  },
  {
    code: "VI",
    label: "US Virgin Islands",
    phone: "1-340",
  },
  { code: "VN", label: "Vietnam", phone: "84" },
  { code: "VU", label: "Vanuatu", phone: "678" },
  { code: "WF", label: "Wallis and Futuna", phone: "681" },
  { code: "WS", label: "Samoa", phone: "685" },
  { code: "XK", label: "Kosovo", phone: "383" },
  { code: "YE", label: "Yemen", phone: "967" },
  { code: "YT", label: "Mayotte", phone: "262" },
  { code: "ZA", label: "South Africa", phone: "27" },
  { code: "ZM", label: "Zambia", phone: "260" },
  { code: "ZW", label: "Zimbabwe", phone: "263" },
];
