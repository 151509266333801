import React, { useState } from "react";
import InternshipsChips from "./internshipsChips";
import TopInternshipRecommendations from "./TopInternship";
import TopInternshipRecommCards from "./TopInternshipRecommCard";
import InternshipsDropDown from "./InternshipDropDown";
import ExternalIntershipRecommCards from "./ExternalIntership";
import TopTrainingRecommCards from "./TopTraining";
import TopCoursesRecommCards from "./TopCoursesRecomm";

function TrainingAndInternship() {
	const [drop, setDrop] = useState(1);
	const [activeTab, setActiveTab] = useState(1);
	return (
		<div>
			<div className="flex align-center text-center justify-between">
				<InternshipsChips setActiveTab={setActiveTab} />
				{activeTab === 1 && <InternshipsDropDown setDrop={setDrop} />}
			</div>
			{activeTab === 1 && (
				<>
					<TopInternshipRecommCards drop={drop} />
					<ExternalIntershipRecommCards drop={drop} />
				</>
			)}
			{activeTab === 2 && <TopTrainingRecommCards />}
			{activeTab === 3 && <TopCoursesRecommCards />}
			<TopInternshipRecommendations />
		</div>
	);
}

export default TrainingAndInternship;
