import React from "react";
import Image from "../../Image";
import { medias } from "../../../config/Resources";
import Text from "../../Text";

const Face12 = () => {
  return (
    <>
      <div className=" mx-[70px]">
        <div className="CareerbackGround flex relative mt-[165px] bg-cover px-[76px] pt-[60px] pb-[68px] ">
          <div className="w-1/2">
            <Text className="text-white text-[40px] relative">
              <span className="bg-[#F0BC5E] py-[4px] px-2 text-[#000448] mr-3 rounded-[10px] font-semibold ">
                Career Guidance{" "}
              </span>{" "}
              is now <br />
              on your Finger tips!
              <Image
                src={medias.images.guidance.Face9.UnerlineCurve2}
                className="absolute bottom-[calc(0%-45px)]  -rotate-3"
                alt="UnerlineCurve2"
              />
            </Text>
            <div className="mt-[30px] flex flex-col gap-[20px] ">
              <div className="flex gap-2">
                <Image src={medias.images.guidance.Face9.bullet} alt="bullet" />
                <Text className="text-[#FFFFFF] opacity-80 text-[19px] font-light">
                  Ask your queries directly to experts
                </Text>
              </div>
              <div className="flex gap-2">
                <Image src={medias.images.guidance.Face9.bullet} alt="bullet" />
                <Text className="text-[#FFFFFF] opacity-80 text-[19px] font-light">
                  Explore 550+ Careers
                </Text>
              </div>
              <div className="flex gap-2">
                <Image src={medias.images.guidance.Face9.bullet} alt="bullet" />
                <Text className="text-[#FFFFFF] opacity-80 text-[19px] font-light">
                  Get Inforamtion on colleges, Entrances Exams and Scholarships
                </Text>
              </div>
            </div>
            <div className="flex gap-[16px] mt-[40px]">
              <Image
                className="h-[50px]"
                src={medias.images.guidance.Face9.GooglePlay}
                alt="GooglePlayStore"
              />
              <Image
                className="h-[50px]"
                src={medias.images.guidance.Face9.AppStore}
                alt="AppStore"
              />
            </div>
          </div>
          <div className="absolute top-[calc(0%-60px)] right-[-90px]">
            <Image
              src={medias.images.guidance.Face9.Mobiles}
              className="w-[80%]"
              alt="Mobiles"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Face12;
