/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from "react";
import { MdChevronLeft } from "react-icons/md";
import { medias } from "../../../config/Resources";
import { IoTimeOutline } from "react-icons/io5";
import { Verified } from "@mui/icons-material";
import { AiOutlineQuestion } from "react-icons/ai";
import { IoIosSearch } from "react-icons/io";
import { Controls, Player } from "@lottiefiles/react-lottie-player";
import ProfileAnimation from "../../../animations/Profileanimation.json";
import { GoDownload } from "react-icons/go";

function EntranceExamOverview({ showTabs }) {
	return (
		<div>
			<div className="mt-5">
				<div className="flex items-center text-center gap-1 ml-4 pt-5">
					<p className="font-plusJakartaSans font-medium text-[#909090] text-[12px]">
						All Entrance Exam
					</p>
					<p className="font-plusJakartaSans font-medium text-[#909090] text-[12px] mt-[3px]">
						<MdChevronLeft />
					</p>
					<p className="font-plusJakartaSans font-medium text-[#909090] text-[12px]">
						New Delhi
					</p>
					<p className="font-plusJakartaSans font-medium text-[#909090] text-[12px] mt-[3px]">
						<MdChevronLeft />
					</p>
					<p className="font-plusJakartaSans font-medium text-[#000000] text-[12px]">
						IIT Delhi
					</p>
				</div>
				<div className="relative mt-2 w-40 -left-2">
					<img
						className="w-full"
						src={medias.images.Profile.Image6}
						alt="image 6"
					/>
					<div className="absolute inset-0 flex items-center justify-center text-[17px] text-black font-plusJakartaSans font-semibold">
						{showTabs === 2
							? "Important Dates"
							: showTabs === 3
							? "Application"
							: showTabs === 4
							? "Exam Pattern"
							: showTabs === 6
							? "Previous Papers"
							: showTabs === 7
							? "Tips from Experts"
							: showTabs === 8
							? "Result"
							: showTabs === 9
							? "Counselling"
							:showTabs === 10
							? "News & Articles"
							: "Overview"}
					</div>
				</div>
				<div className="ml-1 mt-2 flex items-center">
					<div className="relative h-20 w-20">
						<Player
							autoplay
							speed={1.5}
							loop
							src={ProfileAnimation}
							className="h-20 w-20 p-0"
						>
							<Controls
								visible={false}
								buttons={[
									"play",
									"repeat",
									"frame",
									"debug",
									"snapshot",
									"background",
								]}
							/>
						</Player>
						<img
							className="absolute inset-0 h-12 w-[60%] rounded-full m-auto top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
							src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=400&fit=max&ixid=eyJhcHBfaWQiOjE0NTg5fQ"
							alt="Woman looking front"
						/>
					</div>
					<div className="mt-1">
						<p className="font-plusJakartaSans text-[12.5px] font-semibold flex items-center text-center gap-1">
							Jaypal Sharma
							<Verified className="text-[rgba(39,113,231,1)] ml-[1px] 3xl:text-[18px]" />
						</p>
						<p className="font-plusJakartaSans mt-[2px] text-[#707070] text-[10px] font-medium flex items-center text-center gap-1">
							<IoTimeOutline />
							Last updated on 28 Jun 23
						</p>
					</div>
					<div className="ml-4 mt-3">
						<p className="border rounded-full py-2 px-3 flex items-center text-center font-plusJakartaSans font-medium">
							<AiOutlineQuestion />
							<span className="text-[10px] text-[#000000]">246q&All</span>
						</p>
					</div>
					<div className="ml-3 mt-3">
						<p className="border rounded-full py-2 px-3 flex items-center text-center font-plusJakartaSans font-medium">
							<IoIosSearch />
							<span className="text-[10px] text-[#000000]">
								Will you get in
							</span>
							<AiOutlineQuestion className="w-[15px] pt-[2px] h-[15px]" />
						</p>
					</div>
					<button
						variant="outlined"
						className="font-semibold flex mt-[10px] ml-3 text-center gap-[2px] pt-2 pb-2 pl-3 pr-3 bg-[#239403] text-white rounded-full text-[8.5px] font-plusJakartaSans"
					>
						<GoDownload className="text-white h-4 w-4" />
						<span className="mt-[1px]">Download brochure</span>
					</button>
				</div>
			</div>
		</div>
	);
}

export default EntranceExamOverview;
