/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { medias } from "../../config/Resources";
import axiosInstance from "../../config/Axios/axiosInstance";
import { useNavigate } from "react-router-dom";
import { API_URLS } from "../../config/API_URLS";
import CreateTask from "./createTask";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import EditModal from "./EditModal";
import DeleteModal from "./DeleteModal";
import DeleteMileStoneModal from "./DeleteMilestoneModal";
import EditMileStones from "./EditMileStones";

function Accordion({
	activeTab,
	selectedCardLabel,
	healthType,
	setData,
	datas,
}) {
	const [milstones, setMilestones] = useState("");
	const [goalId, setGoalId] = useState("");
	const [selectedItemId, setSelectedItemId] = useState("");
	const [selectedItemId1, setSelectedItemId1] = useState("");
	const [selectedItemId2, setSelectedItemId2] = useState("");
	const [selectedItemId3, setSelectedItemId3] = useState("");
	const [selectedMilestoneId, setSelectedMilestoneId] = useState("");
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isModalOpen1, setIsModalOpen1] = useState(false);
	const [isModalOpen2, setIsModalOpen2] = useState(false);
	const [isModalOpen3, setIsModalOpen3] = useState(false);
	const [isModalOpen4, setIsModalOpen4] = useState(false);
	const [count, setCount] = useState("");

	const navigate = useNavigate();

	const toggleModal = () => {
		setIsModalOpen(!isModalOpen);
	};

	const toggleModal1 = () => {
		setIsModalOpen1(!isModalOpen1);
	};

	const toggleModal2 = () => {
		setIsModalOpen2(!isModalOpen2);
	};

	const toggleModal3 = () => {
		setIsModalOpen3(!isModalOpen3);
	};

	const toggleModal4 = () => {
		setIsModalOpen4(!isModalOpen4);
	};

	const api = {
		1: `${API_URLS.baseURL}/pb/getAcademicRoadmap?`,
		2: `${API_URLS.baseURL}/pb/getExtraCurricularRoadmap?`,
		3: "",
		4: "",
		5: `${API_URLS.baseURL}/getPassionRoadmap?`,
		6: `${API_URLS.baseURL}/pb/getHealthRoadmap?`,
	};

	const getAcademicRoadMapData = async (searchId, index) => {
		try {
			let url = api[activeTab];
			if (searchId) {
				url += `searchId=${searchId}&type=milestone`;
			} else if (selectedCardLabel) {
				url += `filter=${selectedCardLabel}`;
			} else if (healthType) {
				url += `filter=${healthType}`;
			} else {
				url += `type=`;
			}
			const res = await axiosInstance.get(url);
			if (searchId) {
				const newData = datas?.map((item, i) => {
					if (i === index) {
						return { ...item, mileStoneData: res.result };
					}
					return item;
				});
				setData(newData);
			} else {
				setData(res.result);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const tabId = {
		1: "academic_id",
		2: "extra_curricular_id",
		3: "",
		4: "another_id",
		5: "passion_id",
		6: "health_id",
	};


	useEffect(() => {
		getAcademicRoadMapData();
	}, [selectedCardLabel, healthType, activeTab]);

	const [isOpen, setIsOpen] = useState(datas?.map((_, index) => index === 0));

	const toggleDetails = (index, searchId) => {
		const newIsOpen = [...isOpen];
		newIsOpen[index] = !newIsOpen[index];
		setIsOpen(newIsOpen);
		getAcademicRoadMapData(searchId, index);
	};

	const tabId2 = {
		1: "acad_milestone_id",
		2: "extra_curr_milestone_id",
		3: "",
		4: "",
		5: "passion_milestone_id",
		6: "health_milestone_id",
	};

	return (
		<div className="mt-10 w-[90%] ">
			<EditModal
				setIsModalOpen1={setIsModalOpen1}
				toggleModal1={toggleModal1}
				isModalOpen1={isModalOpen1}
				selectedItemId={selectedItemId}
				setData={setData}
			/>
			<CreateTask
				setIsModalOpen={setIsModalOpen}
				toggleModal={toggleModal}
				isModalOpen={isModalOpen}
				milestones={milstones}
				goalId={goalId}
			/>

			<DeleteModal
				setIsModalOpen2={setIsModalOpen2}
				toggleModal2={toggleModal2}
				isModalOpen2={isModalOpen2}
				selectedItemId1={selectedItemId1}
				setData={setData}
			/>
			<DeleteMileStoneModal
				setIsModalOpen3={setIsModalOpen3}
				toggleModal3={toggleModal3}
				isModalOpen3={isModalOpen3}
				selectedItemId2={selectedItemId2}
				setData={setData}
			/>
			<EditMileStones
				setIsModalOpen4={setIsModalOpen4}
				toggleModal4={toggleModal4}
				isModalOpen4={isModalOpen4}
				selectedItemId3={selectedItemId3}
				selectedMilestoneId={selectedMilestoneId}
				setData={setData}
			/>
			{activeTab === 5 ? (
				<div className="flex justify-between mb-5 w-[100%] ml-10">
					<p className="text-sm font-plusJakartaSans font-bold w-[10%]">Goal</p>
					<p className="text-sm font-plusJakartaSans font-bold p-width3 pl-12">
						Completed
					</p>
					<p className="text-sm font-plusJakartaSans font-bold p-width2 ">
						Deadline
					</p>
					<p className="text-sm font-plusJakartaSans font-bold p-width1">
						Action
					</p>
				</div>
			) : (
				<div className="flex justify-between mb-5 w-[100%] ml-10">
					<p className="text-sm font-plusJakartaSans font-bold w-[10%]">Goal</p>
					<p className="text-sm font-plusJakartaSans font-bold pl-12">
						Completed
					</p>
					<p className="text-sm font-plusJakartaSans font-bold">Mentor</p>
					<p className="text-sm font-plusJakartaSans font-bold p-width">
						Deadline
					</p>
				</div>
			)}
			{datas &&
				datas.map((item, index) => (
					<>
						<div className="items-center bg-white shadow-md rounded-xl w-[100%] ml-5 mt-5 border-lg">
							<div className="mx-auto">
								<div className="overflow-x-auto">
									<table className="w-[100%] table-auto">
										<thead>
											<tr
												key={index}
												className="text-sm font-normal text-gray-600 border-t border-b text-left bg-gray-50"
											>
												<th className="px-4 py-3 w-[35%]">
													<div className="flex items-center">
														<img
															className="w-5 h-5 mr-2"
															src={medias.images.Profile.image2}
															alt="image2"
														/>

														<p className="text-green-600 font-bold font-plusJakartaSans mt-1">
															{activeTab === 6
																? item.health_type
																: activeTab === 5
																? item.category
																: selectedCardLabel
																? null
																: item.main_goal}
														</p>
													</div>
												</th>
												<th className="px-4 py-3 w-[20%]">
													<p className="bg-yellow-200 font-plusJakartaSans font-bold rounded w-fit p-1 text-sm">
														{item?.percentage}%
													</p>
												</th>
												{activeTab !== 5 && (
													<th className="px-4 py-3 w-[25%]">
														<div className="flex items-center">
															<div className="w-10 h-10 mr-4">
																<a href="#" className="">
																	<img
																		alt="profile"
																		src={item.mentorProfileImage}
																		className="object-cover w-10 h-10 mx-auto rounded-full"
																	/>
																</a>
															</div>
															<div>
																<div className="text-sm font-bold font-plusJakartaSans">
																	{item.mentorFirstName}
																</div>
															</div>
														</div>
													</th>
												)}
												<th className="px-4 py-3 w-[20%]">
													<div className="pl-1 font-bold text-sm font-plusJakartaSans">
														{item.duration}
													</div>
												</th>
												<th className="px-4 py-3 flex item-center gap-2">
													<div
														id={`${item.id}Toggle`}
														className={`w-4 h-4 transition-transform duration-300 transform ${
															isOpen[index] ? "rotate-180" : ""
														}`}
														onClick={() =>
															toggleDetails(index, item[tabId[activeTab]])
														}
													>
														<svg
															className="w-4 h-4"
															xmlns="http://www.w3.org/2000/svg"
															viewBox="0 0 24 24"
														>
															<path d="M11.9997 13.1714L16.9495 8.22168L18.3637 9.63589L11.9997 15.9999L5.63574 9.63589L7.04996 8.22168L11.9997 13.1714Z" />
														</svg>
													</div>
													{activeTab === 5 && (
														<>
															<FaRegEdit
																className="w-4 h-4 text-blue-500 cursor-pointer"
																onClick={(e) => {
																	setSelectedItemId(item);
																	toggleModal1();
																}}
															/>
															<MdDelete
																onClick={() => {
																	setSelectedItemId1(item.passion_id);
																	toggleModal2();
																}}
																className="w-4 h-4 text-red-500 cursor-pointer"
															/>
														</>
													)}
												</th>
											</tr>
										</thead>
										<tbody className="w-full table-auto">
											{isOpen[index] &&
												item.mileStoneData?.map((milestone, idx) => (
													<>
														<tr
															className="border-b border-gray-300 hover:bg-[#0000000F]"
															key={idx}
														>
															<td className="px-4 py-3">
																<div className="flex items-center gap-1 cursor-pointer">
																	<p className="font-bold font-plusJakartaSans text-xs">
																		{milestone.name}
																	</p>
																	<img
																		className="w-8 h-8 ml-3"
																		src={medias.images.Profile.Edit}
																		alt="image2"
																		onClick={() =>
																			navigate("/profile/milestone-details", {
																				state: {
																					AllIds: item,
																					mileStoneId:
																						milestone[tabId2[activeTab]],
																					activeTab: activeTab,
																					maiGoal: item.main_goal,
																					milestoneName: milestone.name,
																					durationTime:
																						milestone?.duration_type,
																					milestonePercentage: item?.percentage,
																				},
																			})
																		}
																	/>
																	{activeTab === 5 && (
																		<>
																			<FaRegEdit
																				className="w-4 h-4 text-blue-500 cursor-pointer"
																				onClick={() => {
																					setSelectedItemId3(item);
																					setSelectedMilestoneId(milestone);
																					toggleModal4();
																				}}
																			/>
																			<MdDelete
																				onClick={() => {
																					setSelectedItemId2(
																						milestone.passion_milestone_id
																					);
																					toggleModal3();
																				}}
																				className="w-4 h-4 text-red-500 cursor-pointer"
																			/>
																		</>
																	)}
																</div>
															</td>
															<td>
																<p className="bg-[#B4FDAC] font-bold rounded w-fit p-1 font-plusJakartaSans text-xs ml-4">
																	{milestone?.percentage}%
																</p>
															</td>
															{activeTab !== 5 && (
																<td className="px-4 py-3"></td>
															)}
															<td className="px-4 py-3 text-sm font-plusJakartaSans font-bold text-gray-500">
																{milestone?.duration_type}
															</td>
															{activeTab === 5 && (
																<td className="px-5 py-3">
																	<button
																		className="bg-[#FFC269] font-bold py-2 px-2 rounded"
																		onClick={() => {
																			setMilestones(
																				milestone.passion_milestone_id
																			);
																			setGoalId(item?.passion_id);
																			toggleModal();
																		}}
																	>
																		<p className="text-black w-[60px] font-plusJakartaSans font-bold text-xs">
																			Add Task
																		</p>
																	</button>
																</td>
															)}
														</tr>
													</>
												))}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</>
				))}
		</div>
	);
}

export default Accordion;
