import { medias } from "../../../../config/Resources";

function EntranceExamCentersCards({ examPattern }) {
	const courses = [
		{
			id: 1,
			State: "Haryana",
			District: "Gurgaon",
			City: "Gurgaon",
			CityCode: "1109282",
		},
		{
			id: 2,
			State: "Haryana",
			District: "Gurgaon",
			City: "Gurgaon",
			CityCode: "1109282",
		},
		{
			id: 3,
			State: "Haryana",
			District: "Gurgaon",
			City: "Gurgaon",
			CityCode: "1109282",
		},
		{
			id: 4,
			State: "Haryana",
			District: "Gurgaon",
			City: "Gurgaon",
			CityCode: "1109282",
		},
		{
			id: 5,
			State: "Haryana",
			District: "Gurgaon",
			City: "Gurgaon",
			CityCode: "1109282",
		},
		{
			id: 6,
			State: "Haryana",
			District: "Gurgaon",
			City: "Gurgaon",
			CityCode: "1109282",
		},
	];

	return (
		<>
			<div className="ml-4">
				<h6 className="mt-5 font-plusJakartaSans flex items-center text-center gap-[5px] font-bold text-[#000000] text-[15px]">
					<img
						className="w-5 h-5"
						src={medias.images.Profile.Location}
						alt="Privilege"
					/>
					<span className="font-plusJakartaSans font-bold mt-[1px]">
						Exam Centres
					</span>
				</h6>

				<img
					className="w-40 text-[#000000] pt-1"
					src={medias.images.Profile.line1}
					alt="lin1"
				/>
			</div>

			{/* <div className="container mx-auto p-4 rounded-lg">
				<div className="bg-[#789DC9] bg-opacity-15 pt-3 pb-3 flex items-center text-center justify-between mb-2 rounded-md">
					<div className="w-1/3 font-medium text-[#707070] font-plusJakartaSans text-[12px]">
						State
					</div>
					<div className="w-1/3 font-medium text-[#707070] font-plusJakartaSans text-[12px]">
						District
					</div>
					<div className="w-1/3 font-medium text-[#707070] font-plusJakartaSans text-[12px]">
						City
					</div>
					<div className="w-1/3 font-medium text-[#707070] font-plusJakartaSans text-[12px]">
						City Code
					</div>
				</div>
				<div className="space-y-3">
					{courses.map((course, index) => (
						<div
							key={index}
							className="flex items-center text-center pt-2 pb-2 bg-white custom-shadow rounded-md"
						>
							<div className="w-1/3 font-plusJakartaSans text-[12px] text-[#000000]">
								{course.State}
							</div>
							<div className="w-1/3 font-plusJakartaSans text-[12px] text-[#000000]">
								{course.District}
							</div>
							<div className="w-1/3 font-plusJakartaSans text-[12px] text-[#000000]">
								{course.City}
							</div>
							<div className="w-1/3 font-plusJakartaSans text-[12px] text-[#000000]">
								{course.CityCode}
							</div>
						</div>
					))}
				</div>
			</div> */}
			<div>
				<div className="ml-4 mr-4">
					<div
						dangerouslySetInnerHTML={{
							__html: examPattern?.exam_centres,
						}}
						className="font-plusJakartaSans font-medium text-[11.5px] text-[#000000] text-opacity-70 mt-[8px]"
					></div>
				</div>
			</div>
		</>
	);
}

export default EntranceExamCentersCards;
