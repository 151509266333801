import React, { useState } from "react";

const Tab = ({ setActiveTab }) => {
  const [active, setActive] = useState(1);
  const tabs = [
    { name: "Booked Session", id: 1 },
    { name: "Upcoming Session", id: 2 },
    { name: "Completed Session", id: 3 },
  ];
console.log(active, 'activeTabsData');
  const handleTabClick = (id) => {
    setActive(id);
    setActiveTab(id);
  };

  return (
    <div className={`${active === 2 ? "w-full" : "w-[80%]"} mt-5`}>
      <div className="flex gap-x-10 pr-[20%] font-bold border-b-[1px] border-gray-200 border-solid">
        {tabs.map((tab) => (
          <h3
            onClick={() => handleTabClick(tab.id)}
            key={tab.id}
            className={`${
              active === tab.id ? "text-[#239403] border-b-4 rounded-sm border-[#239403]" : ""
            } pb-4 miniDesk:text-[11px] px-4 text-[17px] cursor-pointer`}
          >
            <span className="text-sm">{tab.name}</span>
          </h3>
        ))}
      </div>
    </div>
  );
};

export default Tab;
