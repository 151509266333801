import { Modal, Button } from "@mui/material";

function ViewDataModal({ isOpen, onClose, data }) {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        className="!overflow-y-scroll bg-white "
        style={
          {
            // backgroundColor: 'white',
            // width: '20%',
            // height: '25%',
            // paddingLeft: '30px',
            // paddingTop:'20px',
            // paddingRight:'20px',
            // paddingBottom:"20px",
            // borderRadius: '8px',
            // fontWeight:'bold',
          }
        }
      >
        <div
          className="!overflow-y-scroll h-fit"
          dangerouslySetInnerHTML={{ __html: data }}
        ></div>
        <Button
          style={{ marginTop: "20px", color: "white", backgroundColor: "blue" }}
          onClick={onClose}
        >
          Close
        </Button>
      </div>
    </Modal>
  );
}

export default ViewDataModal;
