import React, { useEffect, useRef, useState } from "react";
import Text from "../../../Text";
import { LinearProgress } from "@mui/material";
import Image from "../../../Image";
import { medias } from "../../../../config/Resources";
import AudioPlayer from "react-h5-audio-player";
import { Grading, VolumeOff, VolumeUp } from "@mui/icons-material";
import LanguageIcon from "@mui/icons-material/Language";
import classNames from "classnames";
import { VscGlobe } from "react-icons/vsc";

const Questionsirheader = ({
  open,
  setOpen,
  isPassage,
  mainContainerClass,
  questionsInfo = {
    currentQuestion: "",
    totalQuestion: "",
    type: "",
  },
  progressClass = "",
  coins = null,
}: any) => {
  const [isMuted, setIsMuted] = useState(false);

  const handleMuteUnmute = () => {
    const element: any = document.getElementById("audioplayer");
    element.muted = !isMuted;
    setIsMuted(!isMuted);
  };

  console.log("main container ", mainContainerClass);

  return (
    <div
      className={`flex  ${mainContainerClass}  miniDesk:w-[98%] items-end   justify-between`}
    >
      <div className="flex justify-between items-center">
        <div className={"flex flex-col gap-2 "}>
          <Text className="font-bold tab:!text-[16px] desktop:text-[14px]  text-[22px] font-quitery text-white">
            Question{" "}
            {questionsInfo?.type == "aptitude"
              ? questionsInfo.currentQuestion <= 80
                ? questionsInfo.currentQuestion
                : 80
              : questionsInfo.currentQuestion}
            /{questionsInfo.totalQuestion}
          </Text>
          <LinearProgress
            variant="determinate"
            classes={{
              root: "!rounded-full !bg-white !h-[10px]",
              bar: "!bg-[#00C507] !rounded-full",
            }}
            value={
              (Number(questionsInfo?.currentQuestion) /
                Number(questionsInfo?.totalQuestion)) *
              100
            }
          />
        </div>
        <div className="relative flex ml-[20px] items-center">
          <Image
            src={medias.images.intrestImages.coinIcon}
            className="absolute z-10 desktop:w-[20px]"
            alt="coinIcon"
          />
          <Text className="pl-10 pr-2 text-white desktop:text-[12px] desktop:py-[2px] desktop:pl-[25px] bg-white rounded-full bg-opacity-10 backdrop-blur-md">
            355
          </Text>
        </div>
      </div>

      <div className=" flex items-center justify-between gap-2">
        {/* {isPassage && (
          <div className="relative flex items-center ml-auto cursor-pointer font-quitery">
            <Text
              onClick={() => setOpen(true)}
              className="px-3  desktop:text-[12px] py-2 font-extrabold text-white bg-white rounded-full blinkAnimation bg-opacity-10 backdrop-blur-md"
            >
              Read Passage <Grading className="desktop:!text-[14px]" />
            </Text>
          </div>
        )} */}
        <div className="relative flex items-center ml-auto cursor-pointer font-quitery">
          <Text className=" font-quitery desktop:text-[12px]  tab:!text-[16px] font-extrabold px-3 py-2 text-white bg-white rounded-full bg-opacity-10 backdrop-blur-md">
            <LanguageIcon className="desktop:!text-[14px]" /> English
          </Text>
        </div>

        <div
          className={classNames(
            isPassage ? "" : "ml-auto",
            "relative flex items-center mr-[15px] font-quitery"
          )}
        >
          <Text
            onClick={handleMuteUnmute}
            className=" font-quitery desktop:text-[12px] cursor-pointer  tab:!text-[16px] font-extrabold px-3 py-2 text-white bg-white rounded-full bg-opacity-10 backdrop-blur-md"
          >
            Music{" "}
            {isMuted ? (
              <VolumeOff className={classNames("desktop:!text-[14px]")} />
            ) : (
              <VolumeUp className={classNames("desktop:!text-[14px]")} />
            )}
          </Text>
        </div>
      </div>
    </div>
  );
};

export default Questionsirheader;
