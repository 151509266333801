import React, { useEffect, useRef, useState } from "react";
import { medias } from "../../config/Resources";
import axiosInstance from "../../config/Axios/axiosInstance";
import { toast } from "react-toastify";
import { FaPlus } from "react-icons/fa";
import { IoIosArrowRoundForward } from "react-icons/io";
import { VscClose } from "react-icons/vsc";
import { API_URLS } from "../../config/API_URLS";

function EditTask({
	isModalOpen6,
	setIsModalOpen6,
	setRefresh,
	refresh,
	receivedData,
	activityId1,
	getTaskListData,
}) {
	const [task, setTask] = useState([
		{
			main_task: "",
			start_date: "",
			end_date: "",
			priority: "",
			description: "",
			files: [],
			reminder: "",
			notes: "",
			active_reminder: "0",
			follow_up: "No",
			follow_up_notes: "",
			passion_activity_id: activityId1?.passion_activity_id,
		},
	]);

	useEffect(() => {
		if (activityId1) {
			setTask([
				{
					main_task: activityId1.main_task || "",
					start_date: activityId1.start_date || "",
					end_date: activityId1.end_date || "",
					priority: activityId1.priority || "",
					description: activityId1.description || "",
					files: activityId1.files || [],
					reminder: activityId1.reminder || "",
					notes: activityId1.notes || "",
					active_reminder: activityId1.active_reminder || "0",
					follow_up: activityId1.follow_up || "No",
					follow_up_notes: activityId1.follow_up_notes || "",
					passion_activity_id: activityId1.passion_activity_id || "",
				},
			]);
		}
	}, [activityId1]);

	const [editingIndex, setEditingIndex] = useState(0);

	const handleMilestoneChange = (index, key, value) => {
		const updatedMilestones = [...task];
		updatedMilestones[index][key] = value;
		setTask(updatedMilestones);
	};

	const handleFileUpload = async (file, idx) => {
		try {
			const formData = new FormData();
			formData.append("media", file);
			formData.append("media_type", "pdf");
			const response = await axiosInstance.post(
				`${API_URLS.adminbaseURL}/uploadFile`,
				formData,
				{
					headers: {
						"Content-Type": "multipart/form-data",
					},
				}
			);

			const mediaUrl = response?.result?.media_url;
			console.log(mediaUrl, "mediaUrl");
			let finalTask = [...task];
			console.log(finalTask, "finaltask");
			const NewVar = finalTask[idx];

			NewVar.files = JSON.parse(NewVar.files);
			console.log(NewVar, "NewVar");
			finalTask[idx].files.push(mediaUrl);
			setTask(finalTask);
			toast("PDF file uploaded successfully", { type: "success" });
			return;
		} catch (error) {
			console.log(error);
			toast("Failed to upload PDF file", { type: "error" });
		}
	};

	const constructPayload = () => {
		return {
			passion_milestone_id: receivedData,
			activity: task.map((t) => ({
				...t,
				passion_activity_id: activityId1?.passion_activity_id,
			})),
		};
	};

	const createTask = async () => {
		try {
			const payload = await constructPayload();
			await axiosInstance.post(
				`${API_URLS.baseURL}/updatePassionActivityRoadmap`,
				payload
			);

			toast("Activity is edited successfully", { type: "success" });
			getTaskListData();
			setRefresh(!refresh);
			setIsModalOpen6(false);

			// Reset only if no new files were uploaded
			const resetTask = task.map((item) => ({
				...item,
				files: item.files.length > 0 ? item.files : activityId1.files || [], // Preserve existing files if no new files were uploaded
			}));
			setTask(resetTask);
		} catch (error) {
			console.log(error, "errors");
		}
	};

	const fileInputRef = useRef(null);

	const addFileInput = () => {
		if (fileInputRef.current) {
			fileInputRef.current.value = "";
		}
	};

	console.log(task, "AllTask");

	return (
		<div>
			{isModalOpen6 && (
				<div
					id="default-modal"
					tabIndex="-1"
					aria-hidden="true"
					className="fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full h-screen bg-black bg-opacity-10"
				>
					<div className="relative p-4 w-full max-w-lg">
						<div className="relative bg-[#E4F6FF] rounded-lg shadow dark:bg-gray-700">
							<div className="p-4 md:p-5 space-y-4 max-h-96 overflow-y-auto scrollbar-hide">
								{task &&
									task.map((item, index) => (
										<>
											{index === editingIndex && (
												<>
													<input
														type="text"
														value={item.main_task}
														onChange={(e) =>
															handleMilestoneChange(
																index,
																"main_task",
																e.target.value
															)
														}
														className="block w-full p-2 rounded-lg mt-1.5"
														placeholder="Name"
													/>
													<div>
														<p className="font-plusJakartaSans text-sm font-semibold">
															Priority
														</p>
														<select
															type="text"
															value={item.priority}
															onChange={(e) =>
																handleMilestoneChange(
																	index,
																	"priority",
																	e.target.value
																)
															}
															className="block w-full p-2 rounded-lg mt-1"
															placeholder="priority"
														>
															<option value="Low">Low</option>
															<option value="Medium">Medium</option>
															<option value="High">High</option>
														</select>
													</div>
													<div className="flex justify-between text-center mt-2">
														<div>
															<p className="font-plusJakartaSans text-sm font-semibold">
																Start Date
															</p>
															<input
																type="date"
																value={item.start_date}
																onChange={(e) =>
																	handleMilestoneChange(
																		index,
																		"start_date",
																		e.target.value
																	)
																}
																className="block w-full p-2 rounded-lg mt-1"
															/>
														</div>
														<div>
															<p className="font-plusJakartaSans text-sm font-semibold">
																End Date
															</p>
															<input
																type="date"
																value={item.end_date}
																onChange={(e) =>
																	handleMilestoneChange(
																		index,
																		"end_date",
																		e.target.value
																	)
																}
																className="block w-full p-2 rounded-lg mt-1"
															/>
														</div>
													</div>

													<div>
														<p className="font-plusJakartaSans text-sm font-semibold">
															Description
														</p>
														<textarea
															type="text"
															value={item.description}
															onChange={(e) =>
																handleMilestoneChange(
																	index,
																	"description",
																	e.target.value
																)
															}
															className="block w-full p-2 rounded-lg mt-1"
															placeholder="description"
															rows="4"
														></textarea>
													</div>
													<div>
														<div className="flex item-center justify-between">
															<p className="font-plusJakartaSans text-sm font-semibold">
																Upload Documents
															</p>
															<button
																onClick={addFileInput}
																className=" block w-[20%] text-black bg-[#FFA733] font-medium rounded-full text-sm px-1 py-1 text-center"
															>
																<p className="font-plusJakartaSans flex align-center items-center justify-center text-sm font-bold">
																	<FaPlus className="mr-2" /> Add
																</p>
															</button>
														</div>
														<input
															type="file"
															ref={fileInputRef}
															onChange={(e) =>
																handleFileUpload(e.target.files[0], index)
															}
															className="block w-full p-2 bg-white rounded-lg mt-1"
															placeholder="Description"
															rows="4"
														/>
													</div>
													<div>
														<p className="font-plusJakartaSans text-sm font-semibold">
															All Reminders
														</p>
														<select
															type="text"
															value={item.reminder}
															onChange={(e) =>
																handleMilestoneChange(
																	index,
																	"reminder",
																	e.target.value
																)
															}
															className="block w-full p-2 rounded-lg mt-1"
															placeholder="reminder"
															rows="4"
														>
															<option value="Weekly">Weekly</option>
															<option value="Monthly">Monthly</option>
															<option value="Yearly">Yearly</option>
														</select>
													</div>
													<div>
														<p className="font-plusJakartaSans text-sm font-semibold">
															Notes
														</p>
														<textarea
															value={item.notes}
															onChange={(e) =>
																handleMilestoneChange(
																	index,
																	"notes",
																	e.target.value
																)
															}
															className="block w-full p-2 rounded-lg mt-1"
															placeholder="Notes"
															rows="4"
														></textarea>
													</div>
												</>
											)}
										</>
									))}

								<div className="flex items-end justify-end">
									<button
										onClick={() => setIsModalOpen6(false)}
										className="flex align-center items-center justify-center  w-[] mt-2 bg-[#FFDDCE]  text-black font-medium rounded-lg text-sm px-5 py-2.5 text-center"
									>
										<p className="font-plusJakartaSans text-sm font-semibold">
											cancel
										</p>
										<VscClose className="ml-1" />
									</button>
									<button
										onClick={createTask}
										className="flex align-center items-center justify-center ml-2 w-[] mt-2 bg-[#F8D865]  text-black font-medium rounded-lg text-sm px-5 py-2.5 text-center"
									>
										<p className="font-plusJakartaSans text-sm font-semibold">
											edit{" "}
										</p>
										<IoIosArrowRoundForward className="ml-1" />
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
}

export default EditTask;
