import React, { useState } from "react";

const SelectEntranceExamCardChips = ({ setShowtabs }) => {
	const [active, setActive] = useState(1);
	const tabs = [
		{ name: "Overview", id: 1 },
		{ name: "Important Dates", id: 2 },
		{ name: "Application", id: 3 },
		{ name: "Exam Pattern", id: 4 },
		{ name: "Previous Papers", id: 6 },
		{ name: "Tips from Experts", id: 7 },
		{ name: "Result", id: 8 },
		{ name: "Counselling", id: 9 },
		{ name: "News", id: 10 },
		{ name: "Prediction", id: 11 },
		{ name: "Participating & Colleges", id: 12 },
	];

	const handleTabClick = (id) => {
		setActive(id);
		setShowtabs(id);
	};

	return (
		<>
			<div className="flex flex-col">
				<div className={`${active === 2 ? "w-full" : "w-[100%]"}`}>
					<div className="flex flex-col font-bold">
						{tabs?.map((tab) => (
							<div
								key={tab.id}
								onClick={() => handleTabClick(tab.id)}
								className={`relative cursor-pointer flex items-center ${
									active === tab.id
										? "bg-[#FFEEDB] rounded-r-lg border-l-4 border-[#04043B]"
										: ""
								}`}
							>
								<div className="flex pl-2">
									<h3
										className={`text-[13px] ${
											active === tab.id
												? "font-extrabold text-[#04043B]"
												: "font-medium text-[#707070]"
										} my-3`}
									>
										<span className="font-plusJakartaSans">{tab.name}</span>
									</h3>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		</>
	);
};

export default SelectEntranceExamCardChips;
