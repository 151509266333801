/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import AccordionCard from "./AccordionCard";
import { useUserInfoStore } from "../../../store/userinfo";

export default function AccordianComp({ array = [], responseData }) {
  const [expanded, setExpanded] = React.useState("panel1");
  const { userInfo }= useUserInfoStore((state) => state);


  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  // array = ["", "", "", ""];

  const replaceHashWithUserName = (str) => {
    if (typeof str === 'undefined' || str === null) {
      return '';
    }
    
    return str.replace(/#/g,  (userInfo?.firstName +userInfo?.middleName +" "+
      userInfo?.lastName));
  }

  // const capitalizeFirstLetter = (string) => {
  //   if (!string) return '';
  //   return string.charAt(0).toUpperCase() + string.slice(1);
  // };
  
  // const replaceHashWithUserName = (str, userInfo) => {
  //   if (typeof str === 'undefined' || str === null) {
  //     return '';
  //   }
  
  //   const fullName = `${userInfo?.firstName || ''}${userInfo?.middleName || ''} ${userInfo?.lastName || ''}`.trim();
  //   const capitalizedFullName = capitalizeFirstLetter(fullName);
  
  //   return str.replace(/#/g, capitalizedFullName);
  // };

  return (
    <div className="flex flex-col gap-10 px-1" >
      {array?.map((ele, i) => {
        return (
          <Accordion
            key={i}
            className={`!rounded-2xl  !shadow-none !opacity-100  !bg-opacity-100 !bg-white !border-solid ${
              expanded === `panel${i + 1}` && !!ele?.Main_Factor
                ? " !border-gray-400 "
                : !ele?.Main_Factor
                ? " border-[0px] !border-white  "
                : " !border-gray-200 "
            }  border-[2px] px-2 `}
            expanded={expanded === `panel${i + 1}`}
            onChange={handleChange(`panel${i + 1}`)}
          >
            {true && (
              <AccordionSummary
                className={`  ${
                  expanded === `panel${i + 1}` && !!ele?.Main_Factor
                    ? " !border-gray-200  !border-solid  border-b-[2px] "
                    : expanded != `panel${i + 1}`
                    ? ""
                    : " mt-[-80px] "
                } `}
                expandIcon={
                  !!responseData?.factorData?.[ele?.Factor] ? (
                    <ExpandMoreIcon
                      className=" bg-gray-200 w-7 h-7 !text-black rounded-full"
                      color="black"
                    />
                  ) : (
                    <></>
                  )
                }
                aria-controls={`panel${i}bh-content`}
                id={`panel${i}bh-header`}
              >
                <div className="flex justify-between w-full ">
                  {!!ele?.Main_Factor && (
                    <Typography
                      sx={{ width: "33%", flexShrink: 0 }}
                      className="font-bold text-[19px] text-nowrap "
                    >
                      {ele?.Factor}
                    </Typography>
                  )}
                  {!!responseData?.factorData?.[ele?.Factor] ? (
                    <div className="mx-4 items-center  flex gap-3 ">
                      <div style={{ width: 30, height: 30 }}>
                        <CircularProgressbar
                          className="!text-yellow-500"
                          styles={{
                            pathColor: `rgba(62, 152, 199,0.8)`,
                            textColor: "#f88",
                            trailColor: "#d6d6d6",
                            backgroundColor: "#3e98c7",
                          }}
                          value={responseData?.factorData?.[
                            ele?.Factor
                          ]?.toFixed()}
                        />
                      </div>
                      <div className="font-extrabold text-[18.6px]">
                        {responseData?.factorData?.[ele?.Factor]?.toFixed()}/100
                      </div>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
              </AccordionSummary>
            )}
            <AccordionDetails className="space-y-8">
              {!!ele?.Description && (
                <AccordionCard
                  img={
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="28.07"
                        height="28.263"
                        viewBox="0 0 36.07 37.263"
                      >
                        <g id="writing" transform="translate(0 -0.001)">
                          <path
                            id="Path_268297"
                            data-name="Path 268297"
                            d="M240.3,197.1l3.171-.182,11.006-9.63-2.524-2.888-11.006,9.639Zm0,0"
                            transform="translate(-218.408 -167.598)"
                          />
                          <path
                            id="Path_268298"
                            data-name="Path 268298"
                            d="M222.131,20.852h4.027L219.4,14.1v4.018A2.741,2.741,0,0,0,222.131,20.852Zm0,0"
                            transform="translate(-199.409 -12.816)"
                            fill="#2771e7"
                          />
                          <path
                            id="Path_268299"
                            data-name="Path 268299"
                            d="M25.482,31.123l-4.683.264h-.055a.92.92,0,0,1-.692-.319.936.936,0,0,1-.2-.784l.957-4.519a.941.941,0,0,1,.292-.5l6.915-6.05V9.859h-5.3A4.557,4.557,0,0,1,18.158,5.3V0H2.733A2.741,2.741,0,0,0,0,2.734v31.8a2.741,2.741,0,0,0,2.733,2.733H25.291a2.742,2.742,0,0,0,2.733-2.733V29.155l-2,1.74a.947.947,0,0,1-.547.228Zm-19.552-19H14.5a.911.911,0,1,1,0,1.822H5.931a.911.911,0,0,1,0-1.822Zm0,5.995H23.059a.911.911,0,1,1,0,1.822H5.931a.911.911,0,1,1,0-1.822ZM17.92,31.934H5.931a.911.911,0,0,1,0-1.822H17.92a.911.911,0,0,1,0,1.822Zm0-5.995H5.931a.911.911,0,0,1,0-1.822H17.92a.911.911,0,0,1,0,1.822Zm0,0"
                            fill="#2771e7"
                          />
                        </g>
                      </svg>
                    </>
                  }
                  title="Description"
                  desc={replaceHashWithUserName(ele?.Description)}
                />
              )}

              {!!ele?.Developing_Moderate && (
                <AccordionCard
                  img={
                    <>
                      <svg
                        id="Group_413492"
                        data-name="Group 413492"
                        xmlns="http://www.w3.org/2000/svg"
                        width="28.854"
                        height="28.855"
                        viewBox="0 0 28.854 28.855"
                      >
                        <path
                          id="Path_268300"
                          data-name="Path 268300"
                          d="M3.461,12.351,6.426,9.387,4.805,7.767A2.1,2.1,0,0,1,7.769,4.8l7.153,7.153,2.964-2.964L10.733,1.838A6.288,6.288,0,1,0,1.841,10.73Z"
                          transform="translate(-0.002 0)"
                        />
                        <path
                          id="Path_268301"
                          data-name="Path 268301"
                          d="M143.692,194.465l-2.964,2.964,1.62,1.62a2.1,2.1,0,0,1-2.964,2.964l-7.154-7.153-2.964,2.964,7.153,7.153a6.288,6.288,0,0,0,8.892-8.892Z"
                          transform="translate(-118.297 -177.961)"
                        />
                        <path
                          id="Path_268302"
                          data-name="Path 268302"
                          d="M199.05,4.807a2.1,2.1,0,0,1,2.964,2.964l-7.153,7.153,2.964,2.964,7.153-7.153a6.288,6.288,0,0,0-8.892-8.892l-1.62,1.621,2.964,2.964Z"
                          transform="translate(-177.963 -0.004)"
                          fill="#46a662"
                        />
                        <path
                          id="Path_268303"
                          data-name="Path 268303"
                          d="M7.768,142.349A2.1,2.1,0,0,1,4.8,139.385l7.153-7.153-2.964-2.964L1.84,136.422a6.288,6.288,0,0,0,8.892,8.892l1.62-1.621-2.964-2.964Z"
                          transform="translate(-0.002 -118.297)"
                          fill="#46a662"
                        />
                      </svg>
                    </>
                  }
                  title="How it relates to you"
                  desc={replaceHashWithUserName(ele?.Developing_Moderate)}
                />
              )}
              {!!ele?.Area_of_Development && (
                <AccordionCard
                  img={
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="28.148"
                        height="28.147"
                        viewBox="0 0 37.148 37.147"
                      >
                        <g
                          id="Group_417819"
                          data-name="Group 417819"
                          transform="translate(-419.872 -1545.979)"
                        >
                          <g
                            id="Group_413494"
                            data-name="Group 413494"
                            transform="translate(419.873 1545.979)"
                          >
                            <g id="Group_413493" data-name="Group 413493">
                              <path
                                id="Path_268304"
                                data-name="Path 268304"
                                d="M37.09.8A1.073,1.073,0,0,0,36.06,0H31.707a1.088,1.088,0,0,0,0,2.177h2.545l-5.2,5.868a33.352,33.352,0,0,1-27.872,11.2A1.088,1.088,0,0,0,1,21.412c1.018.088,1.877.132,2.892.132a35.887,35.887,0,0,0,26.8-12.058l4.286-4.838v.794a1.088,1.088,0,0,0,2.177,0V2.162Z"
                                transform="translate(-0.001)"
                              />
                            </g>
                          </g>
                          <g
                            id="Group_413495"
                            data-name="Group 413495"
                            transform="translate(419.872 1555.395)"
                          >
                            <path
                              id="Path_268305"
                              data-name="Path 268305"
                              d="M36.059,155.329H34.9V130.86a1.088,1.088,0,0,0-1.92-.7l-.669.794a37.639,37.639,0,0,1-3.581,3.7,1.087,1.087,0,0,0-.36.809v19.864H26.192V138.86a1.088,1.088,0,0,0-1.7-.9,38.427,38.427,0,0,1-4.233,2.466,1.088,1.088,0,0,0-.6.974v13.933H17.486V143.252a1.088,1.088,0,0,0-1.454-1.025,38.292,38.292,0,0,1-4.233,1.239,1.089,1.089,0,0,0-.843,1.061v10.8H8.779V145.277a1.09,1.09,0,0,0-.363-.812,1.074,1.074,0,0,0-.848-.269,37.953,37.953,0,0,1-4.231.243,1.089,1.089,0,0,0-1.087,1.088v9.8H1.088a1.088,1.088,0,1,0,0,2.177H36.059a1.088,1.088,0,1,0,0-2.177Z"
                              transform="translate(0 -129.773)"
                              fill="#f37400"
                            />
                          </g>
                        </g>
                      </svg>
                    </>
                  }
                  title="Area of Developement"
                  desc={replaceHashWithUserName(ele?.Area_of_Development)}
                />
              )}
              {!!ele?.Area_of_Strength && (
                <AccordionCard
                  img={
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="28.148"
                        height="28.147"
                        viewBox="0 0 37.148 37.147"
                      >
                        <g
                          id="Group_417819"
                          data-name="Group 417819"
                          transform="translate(-419.872 -1545.979)"
                        >
                          <g
                            id="Group_413494"
                            data-name="Group 413494"
                            transform="translate(419.873 1545.979)"
                          >
                            <g id="Group_413493" data-name="Group 413493">
                              <path
                                id="Path_268304"
                                data-name="Path 268304"
                                d="M37.09.8A1.073,1.073,0,0,0,36.06,0H31.707a1.088,1.088,0,0,0,0,2.177h2.545l-5.2,5.868a33.352,33.352,0,0,1-27.872,11.2A1.088,1.088,0,0,0,1,21.412c1.018.088,1.877.132,2.892.132a35.887,35.887,0,0,0,26.8-12.058l4.286-4.838v.794a1.088,1.088,0,0,0,2.177,0V2.162Z"
                                transform="translate(-0.001)"
                              />
                            </g>
                          </g>
                          <g
                            id="Group_413495"
                            data-name="Group 413495"
                            transform="translate(419.872 1555.395)"
                          >
                            <path
                              id="Path_268305"
                              data-name="Path 268305"
                              d="M36.059,155.329H34.9V130.86a1.088,1.088,0,0,0-1.92-.7l-.669.794a37.639,37.639,0,0,1-3.581,3.7,1.087,1.087,0,0,0-.36.809v19.864H26.192V138.86a1.088,1.088,0,0,0-1.7-.9,38.427,38.427,0,0,1-4.233,2.466,1.088,1.088,0,0,0-.6.974v13.933H17.486V143.252a1.088,1.088,0,0,0-1.454-1.025,38.292,38.292,0,0,1-4.233,1.239,1.089,1.089,0,0,0-.843,1.061v10.8H8.779V145.277a1.09,1.09,0,0,0-.363-.812,1.074,1.074,0,0,0-.848-.269,37.953,37.953,0,0,1-4.231.243,1.089,1.089,0,0,0-1.087,1.088v9.8H1.088a1.088,1.088,0,1,0,0,2.177H36.059a1.088,1.088,0,1,0,0-2.177Z"
                              transform="translate(0 -129.773)"
                              fill="#f37400"
                            />
                          </g>
                        </g>
                      </svg>
                    </>
                  }
                  title="Area of Strength"
                  desc={replaceHashWithUserName(ele?.Area_of_Strength)}
                />
              )}
              {!!ele?.Tips && (
                <AccordionCard
                  img={
                    <>
                      <svg
                        id="Group_417296"
                        data-name="Group 417296"
                        xmlns="http://www.w3.org/2000/svg"
                        width="28.438"
                        height="28.438"
                        viewBox="0 0 35.438 35.438"
                      >
                        <path
                          id="Path_339900"
                          data-name="Path 339900"
                          d="M12.357,5.1A1.108,1.108,0,0,1,11.25,4V1.107a1.107,1.107,0,0,1,2.215,0V4A1.108,1.108,0,0,1,12.357,5.1Z"
                          transform="translate(5.361)"
                        />
                        <path
                          id="Path_339901"
                          data-name="Path 339901"
                          d="M18.928,7.554a1.108,1.108,0,0,1-.783-1.891l2.044-2.044a1.108,1.108,0,0,1,1.567,1.567L19.712,7.229A1.111,1.111,0,0,1,18.928,7.554Z"
                          transform="translate(8.493 1.57)"
                        />
                        <path
                          id="Path_339902"
                          data-name="Path 339902"
                          d="M24.54,13.465H21.65a1.107,1.107,0,0,1,0-2.215h2.89a1.107,1.107,0,1,1,0,2.215Z"
                          transform="translate(9.79 5.361)"
                        />
                        <path
                          id="Path_339903"
                          data-name="Path 339903"
                          d="M20.972,22.08a1.1,1.1,0,0,1-.783-.325l-2.044-2.044a1.108,1.108,0,0,1,1.567-1.567l2.044,2.044a1.108,1.108,0,0,1-.784,1.891Z"
                          transform="translate(8.493 8.492)"
                        />
                        <path
                          id="Path_339904"
                          data-name="Path 339904"
                          d="M4.4,22.08a1.108,1.108,0,0,1-.783-1.891l2.044-2.044A1.108,1.108,0,0,1,7.23,19.711L5.186,21.755A1.1,1.1,0,0,1,4.4,22.08Z"
                          transform="translate(1.57 8.492)"
                        />
                        <path
                          id="Path_339905"
                          data-name="Path 339905"
                          d="M4,13.465H1.107a1.107,1.107,0,0,1,0-2.215H4a1.107,1.107,0,0,1,0,2.215Z"
                          transform="translate(0 5.361)"
                        />
                        <path
                          id="Path_339906"
                          data-name="Path 339906"
                          d="M6.446,7.554a1.1,1.1,0,0,1-.783-.325L3.62,5.185A1.108,1.108,0,0,1,5.186,3.619L7.23,5.662a1.108,1.108,0,0,1-.784,1.891Z"
                          transform="translate(1.57 1.57)"
                        />
                        <path
                          id="Path_339907"
                          data-name="Path 339907"
                          d="M17.859,21v1.846a2.6,2.6,0,0,1-2.584,2.584H11.584A2.692,2.692,0,0,1,9,22.417V21Z"
                          transform="translate(4.289 10.008)"
                        />
                        <path
                          id="Path_339908"
                          data-name="Path 339908"
                          d="M21.848,7.3A10.337,10.337,0,1,0,9.12,23.589,4.459,4.459,0,0,1,10.8,26.41v.015a.251.251,0,0,1,.1-.015h8.859a.133.133,0,0,1,.074.015V26.41a4.925,4.925,0,0,1,1.89-2.953A10.342,10.342,0,0,0,21.848,7.3Zm-.975,8.771a1.115,1.115,0,0,1-1.107-1.107,4.058,4.058,0,0,0-4.061-4.061,1.107,1.107,0,0,1,0-2.215,6.289,6.289,0,0,1,6.275,6.275A1.115,1.115,0,0,1,20.874,16.074Z"
                          transform="translate(2.382 2.383)"
                          fill="#f0bc5e"
                        />
                        <path
                          id="Path_339909"
                          data-name="Path 339909"
                          d="M8.93,19.5h.1a.251.251,0,0,0-.1.015Z"
                          transform="translate(4.256 9.293)"
                        />
                        <path
                          id="Path_339910"
                          data-name="Path 339910"
                          d="M15.074,19.5v.015A.133.133,0,0,0,15,19.5Z"
                          transform="translate(7.148 9.293)"
                        />
                      </svg>
                    </>
                  }
                  title="Tips"
                  desc={replaceHashWithUserName(ele?.Tips)}
                />
              )}
              {!!ele?.Proficient_High && (
                <AccordionCard
                  img={
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="28.07"
                        height="28.263"
                        viewBox="0 0 36.07 37.263"
                      >
                        <g id="writing" transform="translate(0 -0.001)">
                          <path
                            id="Path_268297"
                            data-name="Path 268297"
                            d="M240.3,197.1l3.171-.182,11.006-9.63-2.524-2.888-11.006,9.639Zm0,0"
                            transform="translate(-218.408 -167.598)"
                          />
                          <path
                            id="Path_268298"
                            data-name="Path 268298"
                            d="M222.131,20.852h4.027L219.4,14.1v4.018A2.741,2.741,0,0,0,222.131,20.852Zm0,0"
                            transform="translate(-199.409 -12.816)"
                            fill="#2771e7"
                          />
                          <path
                            id="Path_268299"
                            data-name="Path 268299"
                            d="M25.482,31.123l-4.683.264h-.055a.92.92,0,0,1-.692-.319.936.936,0,0,1-.2-.784l.957-4.519a.941.941,0,0,1,.292-.5l6.915-6.05V9.859h-5.3A4.557,4.557,0,0,1,18.158,5.3V0H2.733A2.741,2.741,0,0,0,0,2.734v31.8a2.741,2.741,0,0,0,2.733,2.733H25.291a2.742,2.742,0,0,0,2.733-2.733V29.155l-2,1.74a.947.947,0,0,1-.547.228Zm-19.552-19H14.5a.911.911,0,1,1,0,1.822H5.931a.911.911,0,0,1,0-1.822Zm0,5.995H23.059a.911.911,0,1,1,0,1.822H5.931a.911.911,0,1,1,0-1.822ZM17.92,31.934H5.931a.911.911,0,0,1,0-1.822H17.92a.911.911,0,0,1,0,1.822Zm0-5.995H5.931a.911.911,0,0,1,0-1.822H17.92a.911.911,0,0,1,0,1.822Zm0,0"
                            fill="#2771e7"
                          />
                        </g>
                      </svg>
                    </>
                  }
                  title="Proficient High"
                  desc={replaceHashWithUserName(ele?.Proficient_High)}
                />
              )}
              {!!ele?.Exemplary_Very_High && (
                <AccordionCard
                  img={
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="28.07"
                        height="28.263"
                        viewBox="0 0 36.07 37.263"
                      >
                        <g id="writing" transform="translate(0 -0.001)">
                          <path
                            id="Path_268297"
                            data-name="Path 268297"
                            d="M240.3,197.1l3.171-.182,11.006-9.63-2.524-2.888-11.006,9.639Zm0,0"
                            transform="translate(-218.408 -167.598)"
                          />
                          <path
                            id="Path_268298"
                            data-name="Path 268298"
                            d="M222.131,20.852h4.027L219.4,14.1v4.018A2.741,2.741,0,0,0,222.131,20.852Zm0,0"
                            transform="translate(-199.409 -12.816)"
                            fill="#2771e7"
                          />
                          <path
                            id="Path_268299"
                            data-name="Path 268299"
                            d="M25.482,31.123l-4.683.264h-.055a.92.92,0,0,1-.692-.319.936.936,0,0,1-.2-.784l.957-4.519a.941.941,0,0,1,.292-.5l6.915-6.05V9.859h-5.3A4.557,4.557,0,0,1,18.158,5.3V0H2.733A2.741,2.741,0,0,0,0,2.734v31.8a2.741,2.741,0,0,0,2.733,2.733H25.291a2.742,2.742,0,0,0,2.733-2.733V29.155l-2,1.74a.947.947,0,0,1-.547.228Zm-19.552-19H14.5a.911.911,0,1,1,0,1.822H5.931a.911.911,0,0,1,0-1.822Zm0,5.995H23.059a.911.911,0,1,1,0,1.822H5.931a.911.911,0,1,1,0-1.822ZM17.92,31.934H5.931a.911.911,0,0,1,0-1.822H17.92a.911.911,0,0,1,0,1.822Zm0-5.995H5.931a.911.911,0,0,1,0-1.822H17.92a.911.911,0,0,1,0,1.822Zm0,0"
                            fill="#2771e7"
                          />
                        </g>
                      </svg>
                    </>
                  }
                  title="Exemplary Very High"
                  desc={replaceHashWithUserName(ele?.Exemplary_Very_High)}
                />
              )}

              {!!ele?.Careers_corelating_to_your_Interests && (
                <AccordionCard
                  img={
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="28.07"
                        height="28.263"
                        viewBox="0 0 36.07 37.263"
                      >
                        <g id="writing" transform="translate(0 -0.001)">
                          <path
                            id="Path_268297"
                            data-name="Path 268297"
                            d="M240.3,197.1l3.171-.182,11.006-9.63-2.524-2.888-11.006,9.639Zm0,0"
                            transform="translate(-218.408 -167.598)"
                          />
                          <path
                            id="Path_268298"
                            data-name="Path 268298"
                            d="M222.131,20.852h4.027L219.4,14.1v4.018A2.741,2.741,0,0,0,222.131,20.852Zm0,0"
                            transform="translate(-199.409 -12.816)"
                            fill="#2771e7"
                          />
                          <path
                            id="Path_268299"
                            data-name="Path 268299"
                            d="M25.482,31.123l-4.683.264h-.055a.92.92,0,0,1-.692-.319.936.936,0,0,1-.2-.784l.957-4.519a.941.941,0,0,1,.292-.5l6.915-6.05V9.859h-5.3A4.557,4.557,0,0,1,18.158,5.3V0H2.733A2.741,2.741,0,0,0,0,2.734v31.8a2.741,2.741,0,0,0,2.733,2.733H25.291a2.742,2.742,0,0,0,2.733-2.733V29.155l-2,1.74a.947.947,0,0,1-.547.228Zm-19.552-19H14.5a.911.911,0,1,1,0,1.822H5.931a.911.911,0,0,1,0-1.822Zm0,5.995H23.059a.911.911,0,1,1,0,1.822H5.931a.911.911,0,1,1,0-1.822ZM17.92,31.934H5.931a.911.911,0,0,1,0-1.822H17.92a.911.911,0,0,1,0,1.822Zm0-5.995H5.931a.911.911,0,0,1,0-1.822H17.92a.911.911,0,0,1,0,1.822Zm0,0"
                            fill="#2771e7"
                          />
                        </g>
                      </svg>
                    </>
                  }
                  title="Careers corelating to your Interests
                  "
                  desc={replaceHashWithUserName(ele?.Careers_corelating_to_your_Interests)}
                />
              )}

              {!!ele?.High_Scores && (
                <AccordionCard
                  img={
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="28.07"
                        height="28.263"
                        viewBox="0 0 36.07 37.263"
                      >
                        <g id="writing" transform="translate(0 -0.001)">
                          <path
                            id="Path_268297"
                            data-name="Path 268297"
                            d="M240.3,197.1l3.171-.182,11.006-9.63-2.524-2.888-11.006,9.639Zm0,0"
                            transform="translate(-218.408 -167.598)"
                          />
                          <path
                            id="Path_268298"
                            data-name="Path 268298"
                            d="M222.131,20.852h4.027L219.4,14.1v4.018A2.741,2.741,0,0,0,222.131,20.852Zm0,0"
                            transform="translate(-199.409 -12.816)"
                            fill="#2771e7"
                          />
                          <path
                            id="Path_268299"
                            data-name="Path 268299"
                            d="M25.482,31.123l-4.683.264h-.055a.92.92,0,0,1-.692-.319.936.936,0,0,1-.2-.784l.957-4.519a.941.941,0,0,1,.292-.5l6.915-6.05V9.859h-5.3A4.557,4.557,0,0,1,18.158,5.3V0H2.733A2.741,2.741,0,0,0,0,2.734v31.8a2.741,2.741,0,0,0,2.733,2.733H25.291a2.742,2.742,0,0,0,2.733-2.733V29.155l-2,1.74a.947.947,0,0,1-.547.228Zm-19.552-19H14.5a.911.911,0,1,1,0,1.822H5.931a.911.911,0,0,1,0-1.822Zm0,5.995H23.059a.911.911,0,1,1,0,1.822H5.931a.911.911,0,1,1,0-1.822ZM17.92,31.934H5.931a.911.911,0,0,1,0-1.822H17.92a.911.911,0,0,1,0,1.822Zm0-5.995H5.931a.911.911,0,0,1,0-1.822H17.92a.911.911,0,0,1,0,1.822Zm0,0"
                            fill="#2771e7"
                          />
                        </g>
                      </svg>
                    </>
                  }
                  title="High Scores
                  "
                  desc={replaceHashWithUserName(ele?.High_Scores)}
                />
              )}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
}
