/* eslint-disable jsx-a11y/anchor-is-valid */
import { medias } from "../../../../config/Resources";
import CareerSuccess from "../../../../animations/career-success.json";
import { Controls, Player } from "@lottiefiles/react-lottie-player";
import { RiQuestionMark } from "react-icons/ri";

function ConfusionCard() {
	return (
		<div className="ml-3 mr-3">
			<div className="pt-2 pb-4 pl-2 pr-2 bg-[#FFFBF1] shadow-lg w-[100%] rounded-xl dark:bg-gray-800 dark:border-gray-700">
				<div>
					<Player
						autoplay
						speed={1.5}
						loop
						src={CareerSuccess}
						className="h-36 w-40"
					>
						<Controls
							visible={false}
							buttons={[
								"play",
								"repeat",
								"frame",
								"debug",
								"snapshot",
								"background",
							]}
						/>
					</Player>
				</div>
				<div className="pb-4 pl-2 pr-1">
					<p className="font-plusJakartaSans flex items-center text-center font-bold text-[13.5px] text-[#000000]">
						Confusion about admissions Connect with Counsellor
					</p>
				</div>
				<div className="flex items-center justify-center">
					<button className=" text-[11px] text-[#102048] border border-[#2F2D3A] font-plusJakartaSans font-medium flex items-center text-center gap-[7px] rounded-full pt-2 pb-2 pl-5 pr-5">
						<span className="font-plusJakartaSans font-medium mt-[1px]">
							Start Now
						</span>
						<span> 
							<img
								className="w-3 h-2"
								src={medias.images.Exam.Icon}
								alt="Icon"
							/>
						</span>
					</button>
				</div>
			</div>
		</div>
	);
}

export default ConfusionCard;
