/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable eqeqeq */
import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import axiosInstance from "../../../config/Axios/axiosInstance";
import { API_URLS } from "../../../config/API_URLS";
import Image from "../../Image";
import { medias } from "../../../config/Resources";
import { Controls, Player } from "@lottiefiles/react-lottie-player";
import astronot from "../../../animations/astronot.json";
import planet from "../../../animations/planet.json";
import Questionsirheader from "../questionsircomponents/questionairheader";
import Questions from "../questionsircomponents/questions";
import Timer from "../questionsircomponents/timer";
import Options from "../questionsircomponents/options";
import { CircularProgress } from "@mui/material";
import classNames from "classnames";
import { usePersonality, useSocialBehaviour } from "../../../store";
import { useUserInfoStore } from "../../../store/userinfo";
import mainsocialbehaviour from "../../../animations/mainsocialbehaviour.json";
import transparentBird from "../../../animations/transparent-birds.json";
import { useNavigate } from "react-router-dom";

const Socialbehaviour = ({
  setOpenNext,
  next,
  setNext,
  dimensionValue,
}: any) => {
  const [page, setPage] = useState(1);
  const limit = 10;
  const { socialBehaviour, addSocialBehaviour }: any = useSocialBehaviour(
    (state) => state
  );
  const { userInfo, setIsloading }: any = useUserInfoStore((state) => state);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const navigate = useNavigate();
  const [questionLists, setQuestionsList] = useState<any>(null);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const handleFetchQuestion = async (queryKey: any) => {
    return await axiosInstance
      .get(`${API_URLS.getSocialBehaviourQuestionPaper}`)
      .then((res: any) => {
        const currentQuestions: any = questionLists?.result
          ? [...questionLists?.result, ...res?.result]
          : [...res?.result];
        const newValue = { ...res, result: [...currentQuestions] };
        setQuestionsList({ ...newValue });
        return newValue;
      });
  };

  const {
    mutate: submitQuestion,
    data: mutationData,
    isPending,
    isError,
  } = useMutation({
    mutationFn: async () =>
      await axiosInstance
        .post(API_URLS.addSocialBehaviourReport, {
          data: socialBehaviour,
          student_id: userInfo?.id,
        })
        .then(async (res) => {
          setNext({
            current: "Social Behaviour",
            Next: "Aptitude",
            NoOfQuestion: 80,
            Time: 80,
            image: medias.images.congratulations.Aptitude,

            nextpath:
              dimensionValue == 1
                ? "/profile/career-counselling?location=psychometricTest"
                : "/psychomatric-test?category=aptitude",
          });
          setOpenNext(true);
          setIsloading(false);

          navigate(
            dimensionValue == 1
              ? "/profile/career-counselling?location=psychometricTest"
              : "/psychomatric-test?category=aptitude"
          );
          return res;
        }),
  });

  console.log("isError", isError);

  useEffect(() => {
    setIsloading(isPending);
  }, [isPending]);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      const message =
        "Are you sure? You'll loose all the progress that you have achieved";
      event.returnValue = message; // Standard for most browsers
      return message; // For some older browsers
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const { isLoading: IsIntrestLoading }: { data: any; isLoading: boolean } =
    useQuery({
      queryKey: ["getInterestQuestionPaper", page],
      queryFn: ({ queryKey }) => handleFetchQuestion(queryKey),
      refetchOnMount: true,
      retry: false,
      refetchInterval: false,
    });

  useEffect(() => {
    if (
      questionLists?.total_count - currentQuestion + 1 > 10 &&
      (currentQuestion + 1) % 10 === 2
    ) {
      setPage(page + 1);
    }
  }, [currentQuestion]);

  console.log("dimensionValue", dimensionValue);

  return (
    <div className="flex items-center justify-center h-screen overflow-hidden bg-no-repeat bg-cover ">
      <div className="absolute w-screen h-screen z-[1] opacity-[87%] !bg-[#2C472C]"></div>
      <div className="absolute z-0 w-screen h-screen overflow-hidden">
        <Player
          autoplay
          speed={1.5}
          loop
          src={mainsocialbehaviour}
          style={{ height: "100%", padding: 0, width: "100%" }}
        >
          <Controls
            visible={false}
            buttons={[
              "play",
              "repeat",
              "frame",
              "debug",
              "snapshot",
              "background",
            ]}
          />
        </Player>
      </div>
      <div className="absolute z-[100] w-screen h-screen overflow-hidden">
        <Player
          autoplay
          speed={1.5}
          loop
          src={transparentBird}
          style={{ height: "100%", padding: 0, width: "100%" }}
        >
          <Controls
            visible={false}
            buttons={[
              "play",
              "repeat",
              "frame",
              "debug",
              "snapshot",
              "background",
            ]}
          />
        </Player>
      </div>
      <div className="absolute z-[100] overflow-hidden h-screen  left-0 ">
        <Image
          src={medias.images.socialBehaviour.leftmainbg}
          alt="leftmainbg"
          className="relative h-[calc(100vh+120px)] right-[35px]"
        />
      </div>
      <div className="absolute z-[100]  overflow-hidden  bottom-0">
        <Image
          src={medias.images.socialBehaviour.bottommainbg}
          alt="leftmainbg"
          className="relative right-[35px] -bottom-[90px]"
        />
      </div>
      <div className="socialBehaviourbg z-[110]  bg-contain bg-no-repeat flex  pt-[40px] pb-[70px] px-[13px] items-center justify-center w-full h-[90%]">
        <div className=" z-50 w-3/5  h-full py-[19px] px-[13px]">
          <div className="w-full text-center font-quitery text-white relative bottom-[50px] text-[40px] font-bold">
            Social Behaviour
          </div>
          <div
            className={classNames(
              "w-[97%] flex items-center flex-col justify-between  relative z-50 ml-auto",
              !(!IsIntrestLoading || questionLists?.result?.length > 0) &&
                "h-full"
            )}
          >
            {!IsIntrestLoading || questionLists?.result?.length > 0 ? (
              <>
                <div className="relative bottom-[50px] !w-full right-[60px] mt-6">
                  <Questionsirheader
                    mainContainerClass=" miniDesk:!w-[120%] w-[115%] "
                    questionsInfo={{
                      currentQuestion: `${currentQuestion + 1}`,
                      totalQuestion: `${questionLists?.total_count}`,
                      type: "intrest",
                    }}
                  />
                </div>
                <></>
                <div className="w-full h-full mb-8 desktop:mb-4">
                  <Questions
                    questionInfo={questionLists?.result[currentQuestion]}
                  />
                </div>

                <Timer
                  isStartTime={true}
                  totalTime={10}
                  imageClass="!w-full  desktop:!w-full"
                  mainClassName="!w-[80%]  mt-[4%] "
                  textClassName="left-[calc(50%-33px)] desktop:!text-[18px] desktop:left-[calc(50%-25px)]"
                  bgImage={medias.images.socialBehaviour.timerbg}
                  timerPositionClass="!left-[0%]"
                />

                <div className="flex justify-center relative z-[110]  mt-[120px]">
                  <Options
                    optionContainerClass={`!m-0 !col-start-auto !row-start-auto !block !w-full !col-span-1`}
                    mainClass="grid gap-[20px] !max-w-full !grid-cols-2"
                    addQestion={addSocialBehaviour}
                    isSubmitted={isSubmitted}
                    setIsSubmitted={(value: boolean) => {
                      setIsSubmitted(value);
                    }}
                    optionClass=" !bg-[#D57406] px-[27px] py-[9px] !min-h-[60px] !h-fit desktop:py-[4px] desktop:px-[20px] !justify-start !text-start !w-full !shadow-[0px_10px_0px_0px_rgba(135,58,8,1)]"
                    submitQuestion={submitQuestion}
                    total_question={questionLists?.total_count}
                    currentQuestion={currentQuestion}
                    setCurrentQuestion={setCurrentQuestion}
                    questionInfo={questionLists?.result[currentQuestion]}
                  />
                </div>
              </>
            ) : (
              <div className="flex items-center justify-center w-full h-full">
                <CircularProgress
                  classes={{
                    root: "!text-[#fff]",
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Socialbehaviour;
