import Search from "./Search";
import CareerCluster from "./CareerCluster";
import Filters from "./Filters";
import FilterSearch from "./AllFilterSearch";
import FilterCourses from "./FilterCourses";
import StreamFilter from "./StreamFilter";
import { CareerTopColleges } from "./CareerTopCollegesCards";
import { useState } from "react";
import BrightFutureCards from "./BrightFutureCards";

function CareerDetails() {
	const [filterData, setFilterData] = useState([]);
	return (
		<div>
		<BrightFutureCards />
			<CareerCluster />
			<div className="w-[80%] flex  ml-auto mr-auto">
				<div className="w-[20%] h-auto bg-white rounded-lg mb-20">
					<Filters setFilterData={setFilterData} filterData={filterData} />
					<FilterSearch filterData={filterData} setFilterData={setFilterData} />
					<div className="border border-dotted border-b mt-10 mb-10"></div>
					<FilterCourses />
					<div className="border border-dotted border-b mt-10"></div>
					<StreamFilter />
				</div>
				<div className="w-[80%] ml-5">
					<Search />
					<CareerTopColleges />
				</div>
			</div>
		</div>
	);
}

export default CareerDetails;
