/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from "react";
import { medias } from "../../../config/Resources";
import { Verified } from "@mui/icons-material";
import { IoTimeOutline } from "react-icons/io5";
import { RiArrowDropDownLine } from "react-icons/ri";
import { FaAngleUp } from "react-icons/fa";
import classNames from "classnames";
import { PiStarFourFill } from "react-icons/pi";
import { GoThumbsup } from "react-icons/go";
import { GoThumbsdown } from "react-icons/go";
import { FaRegCommentDots } from "react-icons/fa6";

const Review = () => {
	const [showAllImages, setShowAllImages] = useState(false);

	const [isExpanded, setIsExpanded] = useState(false);

	const toggleReadMore = () => {
		setIsExpanded(!isExpanded);
	};

	const chunkArray = (array, chunkSize) => {
		const result = [];
		for (let i = 0; i < array.length; i += chunkSize) {
			result.push(array.slice(i, i + chunkSize));
		}
		return result;
	};

	const selectedData = [
		{
			id: 1,
			text: "Scholarship",
		},
		{
			id: 2,
			text: "Internship",
		},
		{
			id: 3,
			text: "Hostel Fees",
		},
	];

	const data = [
		{
			id: 1,
			text: "Infrastructure 3",
		},
		{
			id: 2,
			text: "Campus life 3",
		},
		{
			id: 3,
			text: "Admissions 3",
		},
		{
			id: 4,
			text: "Value for money 3",
		},
	];

	const rating = 3;
	const images = new Array(15).fill(medias.images.College.Image10);

	const displayedImages = showAllImages ? images : images.slice(0, 6);
	const imageChunks = chunkArray(displayedImages, 10);

	const handleShowMore = () => {
		setShowAllImages(true);
	};

	const Review = [{}, {}, {}];

	return (
        <>	
        {
            Review.map((_, index) => {
                return(
        <div className="w-[100%] rounded-xl overflow-hidden shadow-lg mt-5 pb-5 bg-[#FFFFFF]">
			<div className="pl-5 pr-5 pt-5">
				<div className="flex flex-wrap gap-4 mt-2 mb-3">
					{selectedData.map((chip) => (
						<p
							key={chip.id}
							className="font-plusJakartaSans bg-[#F37400] bg-opacity-10 rounded-full border pt-1 pb-1 pl-4 pr-4 inline-flex text-[12px] text-center items-center gap-1 font-medium cursor-pointer"
						>
							{chip.text}
						</p>
					))}
				</div>
			</div>
			<div className="ml-5 mt-5 flex items-center mb-2">
				<p className="font-plusJakartaSans w-11 h-11 font-bold text-[#2771E7] bg-[#2771E7] bg-opacity-10 p-2 rounded-xl text-[25px] flex justify-center items-center">
					V
				</p>
				<div className="ml-1">
					<p className="font-plusJakartaSans text-[14px] font-semibold flex items-center text-center gap-1">
						Jaypal Sharma
						<Verified className="text-[rgba(39,113,231,1)] ml-1 3xl:text-[18px]" />
					</p>
					<p className="font-plusJakartaSans mt-[2px] text-[#707070] text-[11px] font-medium flex items-center text-center gap-1">
						<IoTimeOutline />
						24 May 2023, 4:43 IST
					</p>
				</div>
			</div>
			<div className="ml-5 mt-4 flex items-center mb-2">
				<p className="font-plusJakartaSans mt-[1px] font-extrabold text-[13px]">
					3.0
				</p>
				<div className="flex ml-2">
					{[1, 2, 3, 4, 5].map((star) => (
						<svg
							key={star}
							xmlns="http://www.w3.org/2000/svg"
							className="h-4 w-4"
							viewBox="0 0 20 20"
							fill={star <= rating ? "#FFC000" : "#DFDFDF"}
						>
							<path d="M9.049 2.927a1 1 0 011.902 0l1.286 4.145a1 1 0 00.95.69h4.282a1 1 0 01.618 1.82l-3.467 2.52a1 1 0 00-.364 1.118l1.286 4.145a1 1 0 01-1.54 1.118L10 15.347l-3.467 2.52a1 1 0 01-1.54-1.118l1.286-4.145a1 1 0 00-.364-1.118L2.449 9.582a1 1 0 01.618-1.82h4.282a1 1 0 00.95-.69L9.049 2.927z" />
						</svg>
					))}
				</div>
				<div className="flex flex-wrap gap-4 ml-8">
					{data.map((chip) => (
						<p
							key={chip.id}
							className="font-plusJakartaSans rounded-full border border-[#D1D1D1] pt-1 pb-1 pl-4 pr-4 inline-flex text-[12px] text-center items-center item-center gap-1 font-medium cursor-pointer"
						>
							{chip.text}
							<PiStarFourFill className="text-[#F37400]" />
						</p>
					))}
				</div>
			</div>
			<div className="ml-5 mt-4 mb-2">
				<p className="font-plusJakartaSans text-[#000000] font-extrabold text-[14px]">
					15 Photos From Students
				</p>
				{imageChunks.map((chunk, chunkIndex) => (
					<div key={chunkIndex} className="flex items-center gap-4 mt-4">
						{chunk.map((src, index) => (
							<div key={index} className="relative">
								<img
									className="rounded-lg w-20 h-20"
									src={src}
									alt={`image${index + 1}`}
									onClick={
										index === chunk.length - 1 && !showAllImages
											? handleShowMore
											: null
									}
								/>
								{index === chunk.length - 1 &&
									!showAllImages &&
									chunkIndex === imageChunks.length - 1 && (
										<div
											className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center rounded-lg cursor-pointer"
											onClick={handleShowMore}
										>
											<p className="text-white font-plusJakartaSans text-[12.5px] font-bold">
												+{images.length - displayedImages.length} more
											</p>
										</div>
									)}
							</div>
						))}
					</div>
				))}
			</div>
			<div className="ml-5 mt-2 mb-2 mr-3">
				<div className="relative overflow-hidden">
					<p
						className={classNames(
							"font-plusJakartaSans font-medium opacity-70 text-[12px] text-[#000000] mt-1",
							{ "line-clamp-6": !isExpanded }
						)}
					>
						Lorem Ipsum is simply dummy text of the printing and typesetting
						industry. Lorem Ipsum has been the industry's standard dummy text
						ever since the 1500s, when an unknown printer took a galley of type
						and scrambled it to make a type specimen book. It has survived not
						only five centuries, but also the leap into electronic typesetting,
						remaining essentially unchanged. It was popularised in the 1960s
						with the release of Letraset sheets containing Lorem Ipsum passages,
						and more recently with desktop publishing software like Aldus
						PageMaker including versions of Lorem Ipsum.Lorem Ipsum is simply
						dummy text of the printing and typesetting industry. Lorem Ipsum has
						been the industry's standard dummy text ever since the 1500s, when
						an unknown printer took a galley of type and scrambled it to make a
						type specimen book. It has survived not only five centuries, but
						also the leap into electronic typesetting, remaining essentially
						unchanged. It was popularised in the 1960s with the release of
						Letraset sheets containing Lorem Ipsum passages, and more recently
						with desktop publishing software like Aldus PageMaker including
						versions of Lorem Ipsum.
					</p>
					{!isExpanded && (
						<div className="absolute bottom-0 left-0 w-full h-8 bg-gradient-to-t from-white to-transparent"></div>
					)}
				</div>
				<button
					onClick={toggleReadMore}
					className="mt-2 text-blue-500 font-plusJakartaSans font-bold ml-[90%] text-[13px] flex items-center"
				>
					{isExpanded ? (
						<>
							Read less
							<FaAngleUp className="w-4 h-4 ml-1" />
						</>
					) : (
						<>
							Read more
							<RiArrowDropDownLine className="w-6 h-6" />
						</>
					)}
				</button>
			</div>
			<div className="border-b ml-5 mr-3 mb-3 mt-5 border-dashed border-opacity-20 border-black"></div>
			<div className="flex item-center text-center justify-between mr-5">
				<div className="flex item-center text-center gap-4">
					<p className="font-plusJakartaSans ml-5 text-[13px] font-semibold flex item-center text-center space-2 text-[#239403]">
						<GoThumbsup className="w-5 h-5" />{" "}
						<span className="ml-2 mt-[2px]">1</span>
					</p>
					<p className="font-plusJakartaSans ml-5 text-[13px] font-semibold flex item-center text-center space-2 text-[#D91F1F]">
						<GoThumbsdown className="w-5 h-5" />{" "}
						<span className="ml-2 mt-[2px]">0</span>
					</p>
					<p className="font-plusJakartaSans ml-5 text-[12px] font-semibold flex item-center text-center space-2 text-[#2771E7]">
						<FaRegCommentDots className="w-5 h-5" />{" "}
						<span className="ml-2 mt-[2px]">Comments</span>
					</p>
				</div>
				<p className="font-plusJakartaSans ml-5 text-[12px] font-semibold flex item-center text-center space-2 text-[#D91F1F]">
					<img
						src={medias.images.College.Report}
						alt="Report"
						className="w-5 h-5"
					/>{" "}
					<span className="ml-2 mt-[2px]">Report</span>
				</p>
			</div>
		</div>
    )
            })
        }	
        </>
	);
};

export default Review;
