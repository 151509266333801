import { medias } from "../../../config/Resources";

function Growth({details}) {
	return (
		<div className="w-auto rounded-[20px] ml-auto mr-auto overflow-hidden p-6 shadow-lg border border-gray-300 mt-10">
			<div className="flex items-center text-center">
				<img
					className="h-7 w-7"
					src={medias.images.Profile.growth}
					alt="growth"
				/>
				<h6 className="font-plusJakartaSans text-[#000000] font-bold text-lg ml-1">Growth</h6>
			</div>
			<div className="mt-2">
				<p className="font-plusJakartaSans font-medium text-[12px] opacity-70" dangerouslySetInnerHTML={{__html: details?.growth}}>
				</p>
			</div>
		</div>
	);
}

export default Growth;
