import React from "react";
import { Link } from "react-router-dom";

const Mentorship = () => {
  return (
    <div className="p-2">
      <Link to={"/mentorship"} className="cursor-pointer">
        Mentor list
      </Link>
    </div>
  );
};

export default Mentorship;
