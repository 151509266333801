import { medias } from "../../../../config/Resources";
import CourseTable from "./CourseTable";

function CourseWisePlacements({ placement }) {
	return (
		<div className="ml-4 mr-4 mt-4">
			<div className="flex items-center text-center gap-1">
				<img
					className="bg-[#414785] bg-opacity-[15%] p-[6px] rounded-md w-9 h-9"
					src={medias.images.College.Bachelors}
					alt="Placements"
				/>
				<h6 className="font-plusJakartaSans mt-[5.5px] font-bold text-[15px]">
					Courses wise placements
				</h6>
			</div>
			{/* <div>
				<p className="font-plusJakartaSans font-medium text-[10.5px] pt-[7px] text-opacity-60 text-[#000000]">
					Indian Institute of Technology, Delhi or IIT Delhi is an Institute of
					Eminence offering about 102 undergraduate, postgraduate, integrated
					and doctoral degree courses. Indian Institute of Technology, Delhi
					courses include B.Tech, M.Tech, M.Sc and Ph.D in various streams of
					Engineering, Physical Sciences, Management, Humanities, and Social
					Sciences. Students seeking admission for B.Tech in any stream must
					appear for JEE Advanced and JoSaa counselling. Students completing
					their degrees are offered IIT Delhi placements to prestigious
					organisations at a salary package ranging from Rs. 26 LPA to Rs. 40
					LPA (approximately). Established in 1961, IIT Delhi is one of the 23
					IITs created to be Centres of Excellence for training, research and
					development in science, engineering and technology in India. Being an
					Institution of National Importance under the 'Institutes of
					Technology’, the institute has bagged 4th rank in overall category as
					per NIRF 2022 data. The Indian Institute of Technology Delhi covers an
					area of about 320 acres equipped with all basic infrastructural
					facilities. The institute also has world class and experienced
					faculties to ensure extraordinary learning.
				</p>
			</div> */}
			<CourseTable placement={placement} />
		</div>
	);
}

export default CourseWisePlacements;
