import React from "react";
import Image from "../Image";
import Text from "../Text";
import {
	CompareRounded,
	EastRounded,
	PushPin,
	Verified,
} from "@mui/icons-material";
import { LocationOnRounded, StarRounded } from "@material-ui/icons";
import { mediaResources } from "../../config/Constants";
import { medias } from "../../config/Resources";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { MdOutlineShare } from "react-icons/md";
import { IoChatbubbleEllipsesSharp } from "react-icons/io5";
import { LuMoveRight } from "react-icons/lu";
import { MdCompare } from "react-icons/md";

const CollageCard = ({ data, isCollageListing }) => {
	const navigate = useNavigate();
	return (
		<div>
			<div
				className={` ${
					!!isCollageListing
						? "flex justify-around items-center flex-wrap "
						: "grid grid-cols-3 grid-rows-2"
				}`}
			>
				{data?.map((item, index) => (
					<div
						onClick={() => navigate(`/college-details/${item?.id}`)}
						key={index}
						className="bg-white cursor-pointer 3xl:h-[90%] max-w-[450px] miniDesk:!w-[95%] h-[95%] w-[84%] 3xl:w-[90%] shadow-[0px_3px_6px_rgba(0,0,0,0.16)] mt-[31px]  overflow-hidden rounded-[14px] "
					>
						<div className="relative">
							<Image
								src={medias.images.HomeMain.College}
								className="object-contain w-full"
								alt="IITDelhi"
							/>
							<div className="rounded-[13px] p-[11px] bg-white w-fit mx-[20px] 3xl:m-[10px] shadow-[0px_3px_6px_rgba(0,0,0,0.16)] -translate-y-[50%]">
								<img
									className="desktop:!w-[25px] w-10 h-10"
									src={item.clg_logo}
									alt="logo"
								/>
							</div>
							<div className="px-[11px] relative -top-[30px]">
								<Text className="text-[25px] desktop:!text-[13px] 3xl:text-[18px] font-semibold ">
									{item.clg_name || "N/A"}
									<Verified className="!text-[rgba(39,113,231,1)] 3xl:text-[18px]" />
								</Text>
								<div className="mt-[9.9px]   flex gap-[18px] items-center">
									<Text className="text-[15px] desktop:!text-[8px] 3xl:text-[12px] font-medium">
										<LocationOnRounded className="!text-[#FF5A5A] 3xl:text-[18px] stroke-black stroke-[1.5px]" />{" "}
										{item.clg_address
											? item.clg_address.split(" ").slice(0, 3).join(" ")
											: "N/A"}
									</Text>
									<Text className="bg-[#F3F3F3] 3xl:p-1 text-[15px] desktop:!text-[8px] w-fit py-[6px] 3xl:text-[12px] items-center pl-[9px] pr-[13px] rounded-[4px] font-medium">
										<StarRounded className="!text-[#FFC000]  3xl:text-[18px]" />{" "}
										4.5
									</Text>
									<Text className="text-[#239403]  desktop:!text-[8px] 3xl:text-[12px] underline text-[15px]">
										525 reviews
									</Text>
								</div>
							</div>
							<div className="flex relative 3xl:-top-[20px] miniDesk:flex-col miniDesk:!gap-[4px]  ml-[11px] 3xl:gap-[12px] gap-[20px]">
								<Text className="font-light  desktop:!text-[8px] 3xl:text-[12px] items-start flex gap-[6px] ">
									<PushPin className="rotate-[45deg] 3xl:text-[14px]" />
									Estd. {item.founded || "N/A"}
								</Text>
								<Text className="flex items-start  desktop:!text-[8px] font-light 3xl:text-[12px]  gap-[6px]">
									<Image
										src={medias.images.HomeMain.DocVerified}
										className="3xl:w-[14px]"
										alt="DocVerified"
									/>
									{item.approved_by
										? item.approved_by.split(" ").slice(0, 3).join(" ")
										: "N/A"}
								</Text>
								<Text className="flex font-light  desktop:!text-[8px] gap-[6px] items-start 3xl:text-[12px]">
									<Image
										src={medias.images.HomeMain.ranking}
										className="3xl:w-[14px]"
										alt="ranking"
									/>
									NIRF Ranking : 2nd
								</Text>
							</div>
							<Text className="flex relative 3xl:-top-[20px] 3xl:mt-[10px]  ml-[11px]  desktop:!text-[10px] mt-[21px] text-[13px] leading-[18px] font-light gap-[6px]">
								Quick Links
							</Text>
							<div className="flex pb-[25px] 3xl:pb-[10px] 3xl:my-[10px] relative 3xl:-top-[20px] border-b border-dashed mt-[16px] border-[#707070] border-opacity-[38%] overflow-auto gap-[8px] pl-[20px] no-scrollbar">
								{[
									"Admissions 2023",
									"Placements",
									"Courses & Fees",
									"Reviews",
									"Scholarship",
									"Q&A",
									"Overview",
									"Notice board",
									"Gallery",
									"Alumni Speak",
								].map((items) => {
									return (
										<>
											<Text className="min-w-fit desktop:!text-[10px] 3xl:py-[3px] 3xl:px-[12px] 3xl:text-[13px] px-[30px] border border-[#6C6C6C] rounded-full  py-[8px]">
												{items}
											</Text>
										</>
									);
								})}
							</div>
							<div className="mt-[8px] relative 3xl:-top-[20px] mx-[23px]  3xl:mx-[0] mb-[20px]">
								<Text className="text-[14px]  desktop:!text-[10px] text-black 3xl:ml-2 opacity-[62%]">
									Avg Fees
								</Text>
								<Text className="text-[17px] 3xl:text-[12px]  desktop:!text-[10px]  3xl:ml-2 flex gap-[11px] items-baseline mt-[5px] font-medium ">
									<span className="text-[27px] 3xl:text-[20px]  desktop:!text-[15px] text-[#239403] font-extrabold">
										₹ {item.total_fee || "N/A"}
									</span>
									1st yr Fees
								</Text>
								{/* <div className="mt-[19px] flex gap-[10px] 3xl:ml-2">
                                    <Button
                                        variant="outlined"
                                        className="border py-[19px] 3xl:py-[10px] 3xl:text-[10px] 3xl:h-fit flex gap-[6.2px] text-[17px] font-medium 3xl:px-3 capitalize !text-black px-[28px] border-[#707070] rounded-[9px]"
                                    >
                                        <CompareRounded className="!text-black 3xl:text-[14px]" />{" "}
                                        Compare
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        className="border py-[19px] 3xl:py-[10px] 3xl:text-[10px] 3xl:h-fit flex gap-[6.2px] text-[17px] font-medium 3xl:px-3 capitalize !text-black px-[28px] border-[#707070] rounded-[9px]"
                                    >
                                        <Image
                                            src={`${mediaResources}/Download.svg`}
                                            className="!text-black 3xl:w-[14px]"
                                        />{" "}
                                        Brochure
                                    </Button>
                                    <Button
                                        variant="contained"
                                        className="border py-[19px] flex gap-[6.2px] miniDesk:!px-[2px] text-[17px] font-medium capitalize !text-[#fff] !bg-[#F37400] 3xl:py-[10px]  3xl:px-3 3xl:text-[10px] 3xl:h-fit !shadow-none px-[28px] border-[#239403] rounded-[9px]"
                                    >
                                        Apply Now{" "}
                                        <EastRounded className="3xl:text-[14px] miniDesk:hidden" />
                                    </Button>
                                </div> */}
								<div className="flex mt-2 mr-4 ml-4">
									<Button
										variant="outlined"
										className="border mt-2 font-medium font-plusJakartaSans py-[12px] !h-full 3xl:h-fit flex gap-[6.2px] text-[13px] 3xl:px-[20px] capitalize !text-black px-[10px] border-[#707070] rounded-[9px]"
									>
										<MdCompare className="text-black h-4 w-4" />
										Compare
									</Button>
									<Button
										variant="outlined"
										className="border mt-2 font-plusJakartaSans ml-4 !h-full flex gap-[6.2px] text-[14px] font-medium 3xl:py-[12px] 3xl:text-[13px] 3xl:h-fit 3xl:px-[20px] capitalize !text-[#239403] px-[28px] border-[#239403] rounded-[9px]"
									>
										<Image
											src={`${mediaResources}/Download.svg`}
											className="!text-black h-4 w-4"
										/>
										Brochure
									</Button>
									<Button
										variant="outlined"
										className="border mt-2 font-plusJakartaSans ml-4 py-[12px] !h-full flex gap-[6.2px] font-medium 3xl:py-[12px] 3xl:text-[13px] 3xl:h-fit 3xl:px-[20px] capitalize !text-white px-[20px] border-[#F37400] !bg-[#F37400] rounded-[9px]"
									>
										Apply Now
										<LuMoveRight className="text-white w-4 h-4" />
									</Button>
								</div>
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default CollageCard;
