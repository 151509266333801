/* eslint-disable jsx-a11y/anchor-is-valid */
import { IoLocationOutline } from "react-icons/io5";
import { medias } from "../../../../config/Resources";

function LastestEntranceExamNews() {
	const SimilarColleges = [{}, {}, {}, {}, {}];
	const selectedData = [
		{
			id: 1,
			text: "Exam",
		},
		{
			id: 2,
			text: "Admissions",
		},
	];
	return (
		<>
			<div className="ml-4 mr-4">
				<h6 className="font-plusJakartaSans font-bold text-[20px] pt-5 mb-3 text-[#000000]">
					Get latest news on entrance exam
				</h6>
			</div>
			<div className="overflow-x-auto scrollable-content ml-4">
				<div className="flex items-center gap-6 w-max">
					{SimilarColleges.map((_, index) => {
						return (
							<div className="pt-2 pb-4 pl-2 pr-2 w-[200px] bg-white border border-gray-200 rounded-xl shadow dark:bg-gray-800 dark:border-gray-700">
								<div className="">
									<div className="flex flex-wrap gap-2 mt-2 mb-3">
										{selectedData.map((chip) => (
											<p
												key={chip.id}
												className="font-plusJakartaSans bg-[#F37400] bg-opacity-10 rounded-full border pt-1 pb-1 pl-4 pr-4 inline-flex text-[10px] text-center items-center gap-1 font-medium cursor-pointer"
											>
												{chip.text}
											</p>
										))}
									</div>
								</div>

								<div>
									<img
										className="rounded-lg"
										src={medias.images.College.BackGroundIMage}
										alt="BackgroundImage"
									/>
								</div>
								<div className="pb-2 pl-2 pr-1 pt-2">
									<p className="font-plusJakartaSans font-semibold text-[13.5px] text-[#000000]">
										If you choose not to provide any of the information
										requested, we may be unable to send you information.
									</p>
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</>
	);
}

export default LastestEntranceExamNews;
