/* eslint-disable jsx-a11y/img-redundant-alt */
import { Button } from "@mui/material";
import { medias } from "../../../config/Resources";
import CardsChips from "./CardsChips";
import { IoShareSocialOutline } from "react-icons/io5";
import { FaRegHeart } from "react-icons/fa";
import { MdChevronLeft } from "react-icons/md";

function CardsName({ details }) {
	return (
		<>
		<div className="flex item-center text-center gap-1 mb-5">
          <p className="font-plusJakartaSans font-medium text-[#909090] text-[12px]">All Colleges</p> 
		  <p className="font-plusJakartaSans font-medium text-[#909090] text-[12px] mt-[3px]"><MdChevronLeft /></p> 
		  <p className="font-plusJakartaSans font-medium text-[#909090] text-[12px]">All Careers</p> 
		  <p className="font-plusJakartaSans font-medium text-[#909090] text-[12px] mt-[3px]"><MdChevronLeft /></p> 
		  <p className="font-plusJakartaSans font-medium text-[#909090] text-[12px]">Career Cluster</p>
		  <p className="font-plusJakartaSans font-medium text-[#909090] text-[12px] mt-[3px]"><MdChevronLeft /></p>
		  <p className="font-plusJakartaSans font-medium text-[#000000] text-[12px]">Career Details</p>   
		</div>
		<div className="mb-12">
				<div className="relative">
					<img
						src={medias.images.Profile.image1}
						alt="Profile image"
						className="w-full h-auto object-cover" />
						<div className="absolute top-4 right-4">
						<div className="flex gap-3">
							<button className="text-white flex text-center items-center gap-1 font-plusJakartaSans font-medium text-sm  border border-white rounded-full pl-5 pr-5 pt-2 pb-2">
								<IoShareSocialOutline /> Share Now
							</button>
							<button className="text-[red] flex text-center items-center gap-1 font-plusJakartaSans font-bold text-sm  border border-white bg-white rounded-full pl-5 pr-5 pt-2 pb-2">
								<FaRegHeart /> Add to list
							</button>
						</div>
					</div>
					<div className="absolute w-full bottom-0 transform translate-y-1/2">
						<div className="bg-white mt-10 rounded-tl-2xl rounded-tr-2xl overflow-hidden shadow-lg">
							<div className="flex text-center items-center pt-8 pl-3 pb-1">
								<h6 className="font-plusJakartaSans ml-2 font-bold text-2xl text-black">
									{details?.career_cluster_name}
								</h6>
							</div>
							<div className="pl-4 pb-2">
								<p className="font-plusJakartaSans font-medium text-sm flex text-center items-center mt-2">
									<img
										className="h-6 w-6"
										src={medias.images.Profile.cap}
										alt="Cap" />
									<span className="ml-1">{details?.exam_associated_name}</span>
								</p>
								<p className="font-plusJakartaSans font-medium text-sm flex text-center items-center mt-2">
									<img
										className="h-6 w-6"
										src={medias.images.Profile.hand}
										alt="Hand" />
									<span className="ml-1">{details?.career_unique_id}</span>
								</p>
								<p className="font-plusJakartaSans font-medium text-sm flex text-center items-center mt-2">
									<img
										className="h-6 w-6"
										src={medias.images.Profile.correct1}
										alt="Correct" />
									<span className="ml-1">{details?.career_name}</span>
								</p>
							</div>
							<CardsChips />
						</div>
					</div>
				</div>
			</div></>
	);
}

export default CardsName;
