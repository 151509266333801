import React, { useState } from "react";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import FilterSearch from "./FilterSearch";

const SearchWithCheckbox = ({
	isSearch = true,
	img,
	title,
	options = [],
	setSearchSpecialisation,
	setFilterCareerCluster,
	selectedCheckboxes = [],
	setSelectedCheckboxes = () => {},
	setCourseFilter,
	setApprovedFilter,
	chipData,
}) => {
	const [searchQuery, setSearchQuery] = useState("");

	const handleCheckboxChange = (value) => {
		console.log(value, "Value");
		const updatedCheckboxes = [...selectedCheckboxes];
		if (updatedCheckboxes.includes(value)) {
			updatedCheckboxes.splice(updatedCheckboxes.indexOf(value), 1);
		} else {
			updatedCheckboxes.push(value);
		}
		if (setFilterCareerCluster) setFilterCareerCluster(updatedCheckboxes);
		if (setCourseFilter) setCourseFilter(updatedCheckboxes);
		if (setApprovedFilter) setApprovedFilter(updatedCheckboxes);
		setSelectedCheckboxes(updatedCheckboxes);
	};

	return (
		<div className="my-4">
			<div className="mb-3 flex items-center gap-2">
				{!!img ? (
					img
				) : (
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="23"
						height="23"
						viewBox="0 0 28.297 23.828"
					>
						{/* SVG content here */}
					</svg>
				)}
				<h5 className="miniDesk:text-[14px] font-plusJakartaSans  text-[14px] font-bold ">
					{title}
				</h5>
			</div>

			{!!isSearch && (
				<div className="mr-5 mb-3">
					<FilterSearch setSearchSpecialisation={setSearchSpecialisation} />
				</div>
			)}
			<FormGroup className="h-[300px] overflow-y-scroll flex flex-col flex-nowrap no-scrollbar">
				{options.map((option) => (
					<div
						className="flex text-center items-center justify-between !p-0 !pr-5 !m-0"
						key={option.id}
					>
						<FormControlLabel
							className="miniDesk:!text-[10px] !font-plusJakartaSans !text-nowrap !overflow-ellipsis !text-[13px]"
							control={
								<div className="flex text-center items-center">
									<Checkbox
										color="success"
										checked={selectedCheckboxes.includes(option.id)}
										onChange={() => handleCheckboxChange(option.id)}
									/>
									<p className="ml-[-6px] normal-case font-plusJakartaSans text-[#000000] text-opacity-80 font-semibold text-[12px]">
										{option.label}
									</p>
								</div>
							}
						/>
					</div>
				))}
			</FormGroup>
		</div>
	);
};

export default SearchWithCheckbox;
