import React, { useState, useEffect } from "react";

const BlogsDetailsChips = ({ blogsDetails, setTab }) => {
	const [active, setActive] = useState(blogsDetails?.sections?.[0]?.id || 1);

	useEffect(() => {
		if (blogsDetails?.sections?.length > 0) {
			const firstTabId = blogsDetails.sections[0].id;
			setActive(firstTabId);
			setTab(firstTabId);
		}
	}, [blogsDetails, setTab]);

	const handleTabClick = (id) => {
		setActive(id);
		setTab(id);
	};

	return (
		<div className="flex flex-col mt-10">
			<div className={`${active === 2 ? "w-full" : "w-[100%]"}`}>
				<div className="flex font-bold border-b border-gray-200">
					{blogsDetails?.sections?.map((tab) => (
						<div
							key={tab.id}
							onClick={() => handleTabClick(tab.id)}
							className={`relative cursor-pointer ml-7 flex items-center ${
								active === tab.id
									? "bg-[#F37400] bg-opacity-[22%] text-[#000000] border-b-4 border-[#04043B] rounded-t-lg"
									: "text-[#707070]"
							}`}
						>
							<div className="flex items-center">
								<h3
									className={`pb-2 pt-2 px-1 text-[15px] font-plusJakartaSans pl-3 pr-3 ${
										active === tab.id ? "font-extrabold" : "font-medium"
									}`}
								>
									<span className="font-plusJakartaSans">
										{tab?.section_name}
									</span>
								</h3>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default BlogsDetailsChips;
