// Search.jsx
import { BsFilterLeft } from "react-icons/bs";
import ExploreSearch from "../ExploreSearch/ExploreSearch";
import SelectDropdown from "../DropDown/Select/SelectDropdown";
import { Controls, Player } from "@lottiefiles/react-lottie-player";
import Animation from "../../animations/animation.json";
import ExamTime from "../../animations/exam-time.json";

const options = [
	{ name: "Relevence", value: "#" },
	{ name: "Pricing Low to High", value: "1" },
	{ name: "Pricing High to Low", value: "2" },
	{ name: "User Rating", value: "3" },
];

function Search({ context }) {
	const searchText =
		context === "entrance-exam"
			? "Get Search for Entrance Exam from top colleges"
			: "Get Search for Career from top colleges";

	const animationSrc = context === "entrance-exam" ? ExamTime : Animation;

	return (
		<div className="shadow-[0px_5px_16px_rgba(0,0,0,0.06)] rounded-[20px] p-4 overflow-hidden bg-white">
			<h2 className="miniDesk:text-[17px] text-[18px] flex items-center font-plusJakartaSans text-center font-bold">
				<Player  
					autoplay
					speed={1.5}
					loop
					src={animationSrc}
					style={{ height: "50px", padding: 0, width: "50px" }}
				>
					<Controls
						visible={false}
						buttons={[
							"play",
							"repeat",
							"frame",
							"debug",
							"snapshot", 
							"background",
						]}
					/>
				</Player>
				{searchText}
			</h2>
			<div className="flex justify-between w-full items-center mt-2">
				<ExploreSearch />
				<div className="flex items-center miniDesk:w-[20%] w-[30%] ml-5 miniDesk:ml-1">
					<div className="flex items-center w-fit">
						<BsFilterLeft className="miniDesk:text-[18px] text-[25px] font-plusJakartaSans font-medium" />
						<p className="miniDesk:hidden w-[100px] font-plusJakartaSans text-[13px] font-medium">Sort By</p>
					</div>
					<SelectDropdown options={options} />
				</div>
			</div>
		</div>
	);
}

export default Search;
