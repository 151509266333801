import React from "react";
import { medias } from "../../../config/Resources";

const ContactDetails = () => {
	return (
		<div className="w-[100%] rounded-xl overflow-hidden shadow-lg mt-7  bg-[#FFFFFF]">
			<div className="flex">
				<div className="pl-6 pt-1 pb-2 w-[55%]">
					<div className="flex items-center gap-2 mb-[2px] mt-5">
						<img
							className="w-24 h-auto"
							src={medias.images.College.Logo5}
							alt="courses"
						/>
						<div>
							<p className="font-semibold text-[#000000] font-plusJakartaSans text-[17.5px]">
								Contact Detail
							</p>
							<p className="font-bold text-[#000000] font-plusJakartaSans text-[22px]">
								IIT Delhi: Indian Institute of Technology Delhi
							</p>
						</div>
					</div>
					<p className="font-plusJakartaSans text-[#000000] ml-[10px] mt-[5px] flex item-center text-center gap-[1%] font-medium opacity-80 mb-2 text-[14.5px]">
						<img
							className="w-5 h-5"
							src={medias.images.Profile.Location}
							alt="location"
						/>
						IIT Delhi Main Rd, Hauz Khas, Delhi 110016
					</p>
					<p className="text-[#000000] ml-[10px] flex item-center text-center gap-[1%] font-plusJakartaSans text-[14.5px] opacity-80 font-medium mb-3">
						<img
							className="w-5 h-5"
							src={medias.images.College.WorldWide}
							alt="location"
						/>
						www.iitdelhi.com
					</p>
					<p className="text-[#000000] ml-[10px] flex items-center text-center gap-[1%] font-plusJakartaSans text-[14.5px] opacity-80 font-medium mb-2">
						<img
							className="w-5 h-5"
							src={medias.images.College.Message}
							alt="location"
						/>
						info@iitdelhi.com
					</p>
					<p className="text-[#000000] ml-[10px] flex item-center text-center gap-[1%] font-plusJakartaSans text-[14.5px] opacity-80 font-medium mb-2">
						<img
							className="w-5 h-5"
							src={medias.images.College.Telephone}
							alt="location"
						/>{" "}
						+91-800-245-068
					</p>
					<p className="font-plusJakartaSans ml-[10px] text-[14px] font-semibold text-[#000000] opacity-100">
						Available on
					</p>
					<div className="flex items-center ml-[1px]">
						<img
							className="w-14 h-14"
							src={medias.images.College.Facebook}
							alt="Facebook"
						/>
						<img
							className="w-14 h-14"
							src={medias.images.College.Instagram}
							alt="Instagram"
						/>
						<img
							className="w-14 h-14"
							src={medias.images.College.Youtube}
							alt="YouTube"
						/>
						<img
							className="w-14 h-14"
							src={medias.images.College.Linkdein}
							alt="Linkdein"
						/>
					</div>
				</div>
				<div className="w-[45%] pt-8 pb-8 pr-6">
					<img
						className="w-full h-full"
						src={medias.images.College.MapLocation}
						alt="find"
					/>
				</div>
			</div>
		</div>
	);
};

export default ContactDetails;
