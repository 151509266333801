import React from "react";
import { Header1 } from "../../Widgets/Header";
import Footer from "../Footer";

const Layout = ({
  isHeader,
  isFooter,
  children,
}: {
  isHeader: boolean;
  isFooter: boolean;
  children: JSX.Element | JSX.Element[];
}) => {
  return (
    <>
      {isHeader && <Header1 />}
      {children}
      {isFooter && <Footer />}
    </>
  );
};

export default Layout;
