import { GoThumbsdown, GoThumbsup } from "react-icons/go";
import { LiaEditSolid } from "react-icons/lia";
import { PiShareFatThin } from "react-icons/pi";
import { RiFlag2Line } from "react-icons/ri";

function LikeAndReportExam() {
	return (
		<div className="flex item-center text-center justify-between mr-5 mb-5">
			<div className="flex item-center text-center gap-4">
				<p className="font-plusJakartaSans ml-5 text-[13px] font-semibold flex item-center text-center space-2 text-[#000000]">
					<GoThumbsup className="w-5 h-5 text-[#239403]" />
					<span className="ml-2 mt-[2px] text-[#239403]">1</span>
				</p>
				<p className="font-plusJakartaSans ml-5 text-[13px] font-semibold flex item-center text-center space-2 text-[#000000]">
					<GoThumbsdown className="w-5 h-5 text-[#D91F1F]" />{" "}
					<span className="ml-2 mt-[2px] text-[#D91F1F]">0</span>
				</p>

				<p className="font-plusJakartaSans ml-5 text-[12px] font-semibold flex item-center text-center space-2 text-[#000000]">
					<LiaEditSolid className="w-5 h-5 text-[#2771E7]" />{" "}
					<span className="ml-2 mt-[2px] font-semibold">Answers 533</span>
				</p>
			</div>
			<div className="flex items-center text-center">
				<p className="font-plusJakartaSans ml-5 text-[13px] font-semibold flex item-center text-center space-2 text-[#000000]">
					<RiFlag2Line className="w-5 h-5 text-[#D91F1F]" />
					<span className="ml-2 mt-[2px] text-[#D91F1F]">Report</span>
				</p>
				<p className="font-plusJakartaSans ml-5 text-[13px] font-semibold flex item-center text-center space-2 text-[#000000]">
					<PiShareFatThin className="w-5 h-5 text-[#239403]" />
					<span className="ml-2 mt-[2px] text-[#239403]">Share</span>
				</p>
			</div>
		</div>
	);
}

export default LikeAndReportExam;
