import React from "react";
import Image from "../../Image";
import { medias } from "../../../config/Resources";
import { Button } from "@mui/material";
import { guidancePageContant } from "../../../config/pageContent";
import { East } from "@mui/icons-material";
import Text from "../../Text";
import classNames from "classnames";

interface Props {
  Title?: string | JSX.Element | JSX.Element[];
  Description?: string | JSX.Element | JSX.Element[];
  ButnText?: string;
  MainImage?: string;
  isTitle?: boolean;
  DescTopText?: string | JSX.Element | JSX.Element[];
  wrapperClassName?: string;
  TitleClassname?: string;
  dataContainerClassName?: string;
  btnClassName?: string;
  mainwrapperClassName?: string;
  containerClasses?: string;
  MainImageClass?: string;
}

const Face3 = ({
  btnClassName,
  containerClasses,
  Title = guidancePageContant.Face3.whatCounselling,
  Description = (
    <>
      <Text className="mt-[24.05px] leading-6 opacity-[69%] text-[15px]">
        {guidancePageContant.Face3.indianInstitute}
      </Text>
      <Text className="mt-[20.05px] leading-6 opacity-[69%] text-[15px]">
        {guidancePageContant.Face3.establishin}
      </Text>
    </>
  ),
  mainwrapperClassName,
  ButnText = guidancePageContant.Face3.chatWithCounsellerNow,
  MainImage,
  DescTopText = guidancePageContant.Face3.howCareerGuidance,
  isTitle = true,
  TitleClassname,
  wrapperClassName,
  dataContainerClassName,
  MainImageClass,
}: Props) => {
  return (
    <div className={classNames(containerClasses, "bg-[#F3F6FF]")}>
      <div
        className={classNames(
          mainwrapperClassName,
          "bg-[#F3F6FF]  pb-[20px] relative -top-[7.3rem] pl-[155px] pr-[155px]  pt-[120.99px] "
        )}
      >
        <Text
          className={classNames(
            TitleClassname,
            "text-[18px] font-bold opacity-60 ml-auto w-[25%] mr-24 text-center"
          )}
        >
          {Title}
        </Text>
        <div className={classNames("flex mt-[23px]", wrapperClassName)}>
          <div
            className={classNames(
              dataContainerClassName,
              "w-[53%] font-medium"
            )}
          >
            {isTitle && (
              <Text className="text-[53px] leading-snug font-extrabold PlusJakartaSans-ExtraBoldCustom z-10  ">
                {DescTopText}
              </Text>
            )}

            {Description}
            <Button
              variant="contained"
              className={classNames(
                "bg-[#2771E6] !rounded-[10px] shadow-lg shadow-[#acc3ec] !text-[16px] gap-4 !mt-4 !capitalize  !px-6 !py-4",
                btnClassName
              )}
            >
              {ButnText}
              <East />
            </Button>
          </div>
          <div className="flex flex-col items-center w-[45%] gap-[23px] ">
            <div className="flex justify-end items-end w-full">
              <Image
                className={classNames(MainImageClass, " w-[90%]")}
                src={MainImage || medias.images.guidance.Face3.face3main}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Face3;
