import React, { useEffect, useState } from "react";
import { Controls, Player } from "@lottiefiles/react-lottie-player";
import Image from "../Image";
import axios from "axios";
import PdfViewer from "../Pdfviewer";
import classNames from "classnames";

const Preview = ({
  src,
  height = 120,
  width = 120,
  svgOption = "",
  imageClassOverride,
}: {
  src: string;
  height?: number;
  svgOption?: string;
  width?: number;
  imageClassOverride?: string;
}) => {
  const [SvgContent, setSvgContent] = useState<any>("");

  useEffect(() => {
    const fetchSvg = async () => {
      try {
        const response = await axios.get(src);
        const svgText = await response?.data;
        setSvgContent(svgText);
      } catch (error) {
        console.error("Error fetching SVG:", error);
      }
    };
    if (src?.includes(".svg")) {
      fetchSvg();
    }
  }, [src]);
  return (
    <div>
      {src?.includes(".json") ? (
        <Player
          autoplay
          speed={1.5}
          loop
          src={src}
          style={{ height: `${height}px`, padding: 0, width: `${width}px` }}
        >
          <Controls
            visible={false}
            buttons={[
              "play",
              "repeat",
              "frame",
              "debug",
              "snapshot",
              "background",
            ]}
          />
        </Player>
      ) : src?.includes(".pdf") ? (
        <PdfViewer height={height - 10} width={width - 10} pdfUrl={src} />
      ) : src?.includes(".svg") ? (
        <div
          className={`${svgOption} svg-container max-w-[${width}px] max-h-[${height}px] bg-white rounded-md p-2 !text-[120px]`}
          dangerouslySetInnerHTML={{ __html: SvgContent }}
        />
      ) : (
        <Image
          className={classNames(
            `max-w-[${width}px] w-[150px] max-h-[${height}px]`,
            imageClassOverride
          )}
          src={src}
          alt={`option`}
        />
      )}
    </div>
  );
};

export default Preview;
