/* eslint-disable jsx-a11y/alt-text */
import { CompareRounded, EastRounded, StarRounded } from "@mui/icons-material";
import { IoIosArrowDropright } from "react-icons/io";
import { Button } from "@mui/material";
import React, { useRef } from "react";
import { medias } from "../../../../config/Resources";
import { GoDownload } from "react-icons/go";

const CoursesLevelCards = ({
	CourseDetails,
	setIsCoursesDetails = () => {},
	setCourseId,
}) => {
	const scrollContainerRef = useRef(null);

	const scrollRight = () => {
		if (scrollContainerRef.current) {
			scrollContainerRef.current.scrollBy({
				left: 200,
				behavior: "smooth",
			});
		}
	};

	return (
		<>
			{CourseDetails?.courses?.map((data, index) => {
				return (
					<div
						className="ml-4 mr-4 mt-5 mb-5 rounded-xl cursor-pointer overflow-hidden shadow-md bg-white"
						onClick={() => {
							setIsCoursesDetails(true);
							setCourseId(data?.id);
						}}
					>
						<div className="pt-4 pl-4 pr-4 flex items-center text-center justify-between">
							<h6 className="font-plusJakartaSans font-bold text-[15px]">
								{data?.course_name}
							</h6>
							<Button
								variant="contained"
								className="border font-plusJakartaSans pt-[7px] pb-[7px] pl-3 pr-3 gap-1 !h-full flex item-center text-center font-medium !text-[#fff] capitalize text-[9.5px] !bg-[#F37400] rounded-full"
							>
								Apply Now
								<EastRounded className="3xl:text-[10px] w-3 h-3 miniDesk:hidden" />
							</Button>
						</div>
						<div className="flex pl-4">
							<p className="bg-[#F3F3F3] w-fit items-center flex pl-1 pr-1 pt-[1.5px] pb-[1.5px] text-[10px] gap-1 text-center rounded-[4px] font-semibold font-plusJakartaSans">
								<StarRounded className="!text-[#FFC000] w-4 h-4" />
								<span className="mt-[1px]">4.5</span>
							</p>
							<p className="font-plusJakartaSans font-normal ml-4 underline text-[12px]">
								525 reviews
							</p>
						</div>
						<div className="pt-3 pl-4 flex items-center text-center gap-[10px]">
							<p className="font-plusJakartaSans gap-[2px] font-medium text-[11px] flex item-center text-center">
								<img
									className="w-5 h-5"
									src={medias.images.College.Bachelors}
									alt="Bachelors"
								/>
								<span className="mt-[1.5px] font-plusJakartaSans font-medium">
									{data?.degree_name}
								</span>
							</p>
							<p className="font-plusJakartaSans gap-[2px] font-medium text-[11px] flex items-center text-center">
								<img
									className="w-5 h-5"
									src={medias.images.College.Seats}
									alt="Seats"
								/>
								<span className="mt-[1.5px] font-plusJakartaSans font-medium">
									{data?.no_of_seat} Seats
								</span>
							</p>
							<p className="font-plusJakartaSans gap-[2px] font-medium text-[11px] flex item-center text-center">
								<img
									className="w-5 h-5"
									src={medias.images.College.FullTime}
									alt="Fulltime"
								/>
								<span className="mt-[1.5px] font-plusJakartaSans font-medium">
									Fulltime
								</span>
							</p>
							<p className="font-plusJakartaSans gap-[2px] font-medium text-[11px] flex items-center text-center">
								<img
									className="w-5 h-5"
									src={medias.images.College.UnderGraduate}
									alt="Undergraduate"
								/>
								<span className="mt-[1.5px] font-plusJakartaSans font-medium">
									{data?.study_level}
								</span>
							</p>
						</div>
						<div className="pl-4 pt-5">
							<h6 className="font-plusJakartaSans font-bold text-[13px] gap-[3px] flex">
								<img
									className="w-5 h-5"
									src={medias.images.College.FullTime}
									alt="Specializations"
								/>
								Specializations:
							</h6>
						</div>
						<div className="pl-5 pr-4 pt-3 pb relative flex items-center">
							<div
								ref={scrollContainerRef}
								className="flex gap-2 items-center text-center overflow-x-auto scrollable-content"
							>
								<div>
									<p className="font-plusJakartaSans bg-[#ffffff]  shadow-md rounded-sm pt-1 pb-1 pl-3 pr-3 mb-1 font-medium text-[12px] whitespace-nowrap border-l-4 border-[#FBC47F]">
										{data?.specialisations}
									</p>
								</div>
							</div>
							<IoIosArrowDropright
								className="absolute w-7 h-7 bg-[white] right-0 transform -translate-y-1/2 text-[#000000] cursor-pointer"
								onClick={scrollRight}
							/>
						</div>
						<div className="pl-4 pt-4">
							<h6 className="font-plusJakartaSans font-bold text-[13px] gap-[3px] flex">
								<img
									className="w-5 h-5"
									src={medias.images.College.ExamAccepted}
									alt="Specializations"
								/>
								Exam Accepted:
							</h6>
						</div>
						<div className="pl-5 pr-4 pt-3 pb-2 relative flex items-center">
							<div className="flex gap-2 items-center text-center overflow-x-auto scrollable-content">
								<div>
									<p className="font-plusJakartaSans bg-[#ffffff] shadow-md rounded-sm pt-1 pb-1 pl-3 pr-3 mb-2 font-medium text-[12px] whitespace-nowrap border-l-4 border-[#ABA7DF]">
										{data?.exams_accepted}
									</p>
								</div>
							</div>
						</div>
						<div className="border border-b mt-1 mb-2"></div>
						<div className="mt-[10px] ml-4 flex gap-4 item-center text-center mb-[13px]">
							<div>
								<p className="text-[12px] desktop:!text-[10px] flex gap-[3px] font-extrabold">
									<span className="text-[15px] font-plusJakartaSans text-[#239403] font-bold">
										₹ {data?.average_fee}
									</span>
									<span className="text-[11px] opacity-60 font-medium mt-[4px] font-plusJakartaSans">
										1st yr Fees
									</span>
								</p>
								<p className="font-plusJakartaSans text-[10px] flex item-center text-center font-medium">
									View complete fees Structure
								</p>
							</div>
							<div className="flex items-center text-center gap-2 ml-6">
								<p className="font-plusJakartaSans font-medium text-[10px] flex gap-1 item-center text-center">
									<img
										src={medias.images.College.CrystalBall}
										alt="CrystallBall"
										className="w-3 h-3"
									/>
									Admission Predicator
								</p>
								<button
									variant="outlined"
									className="font-semibold border border-[#707070] flex ml-3 text-center gap-[2px] pt-1 pb-1 pl-3 pr-3  rounded-full text-[10px] font-plusJakartaSans"
								>
									<CompareRounded className="text-[#000000] h-4 w-4" />
									<span className="mt-[1px]">Compare</span>
								</button>
								<button
									variant="outlined"
									className="font-semibold flex ml-3 text-center gap-[2px] pt-1 pb-1 pl-3 pr-3 bg-[#239403] bg-opacity-10 text-[#239403] rounded-full text-[10px] font-plusJakartaSans"
								>
									<GoDownload className="text-[#000000] h-4 w-4" />
									<span className="mt-[1px]">Download brochure</span>
								</button>
							</div>
						</div>
					</div>
				);
			})}
		</>
	);
};

export default CoursesLevelCards;
