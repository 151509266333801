import VerticalTab from "./VerticalTab";
import ActivitiesTable from "./ActivitiesTable";
import Calendar from "../../Pages/Profile/Mentorship/calender";
import CircularBar from "./CircularProgressBar";
import { useState } from "react";

function DailyActivities() {
	const [activeTab, setActiveTab] = useState(1);
	return (
		<div className="w-[100%] flex">
			<div className="w-[15%]">
				<VerticalTab setActiveTab={setActiveTab}/>
			</div>
            <div className="w-[55%]">
<ActivitiesTable activeTab={activeTab}/>
<CircularBar />
            </div>
            <div className="w-[30%]">
                <Calendar />
            </div>
		</div>
	);
}

export default DailyActivities;
