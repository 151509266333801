import { create } from "zustand";
import { persist, createJSONStorage } from 'zustand/middleware'


export const useUserInfoStore = create(
    persist(
      (set, get) => ({
        isLoading : false,
        setIsloading: (isLoading : any) => set((state : any) => {return {...state, isLoading}}),
        userInfo : null,
        isloggedIn : false,
        setIsLoggedin : (isloggedIn : any) => set((state : any) => {return {...state, isloggedIn}}),
        setUserInfo : (userInfo : any) => set((state : any) => {return {...state, userInfo}})
      }),
      {
        name: 'userinfo', // name of the item in the storage (must be unique)
        storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
      },
    ),
  )
