/* eslint-disable eqeqeq */
import { Check } from "@mui/icons-material";
import { Button, Tab, Tabs } from "@mui/material";
import React from "react";
import Image from "../../Image";
import { guidancePageContant } from "../../../config/pageContent";
import Text from "../../Text";
import arrows from "../../../animations/plans/VfnPWEd3Mx.json";

import classNames from "classnames";
import { Controls, Player } from "@lottiefiles/react-lottie-player";

const PlansCard = ({
  isTab = true,
  data,
  bodyContainer,
  plans = guidancePageContant.Face5.plans,
  setDue = () => {},
  setSelectedPlan = () => {},
  BuyNowPlans = () => {},
  ActivePlanData = {},
}) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  console.log("plans old", plans);

  return (
    <>
      <div className="pt-[40px] pb-[99px] flex justify-center flex-col items-center">
        {/* <div>
          <Text className="text-[47px] font-bold">
            {guidancePageContant.Face5.designRoadMap}
          </Text>
        </div> */}
        {isTab && (
          <div className="mt-[71px] mb-[46px]">
            <Tabs
              value={value}
              classes={{
                indicator: "!bg-none !bg-transparent",
              }}
              onChange={handleChange}
              className="border border-[#00000040]  rounded-[13px] px-[16px] py-[10px] "
              aria-label="basic tabs example"
            >
              {guidancePageContant.Face5.tabs.map((items, i) => {
                return (
                  <Tab
                    key={i}
                    className="!h-[55px] !w-[220px] "
                    classes={{
                      selected:
                        "!bg-[#2771E7] !text-white !rounded-[11px] !border-none shadow-lg shadow-[#acc3ec] m-[4px]",
                    }}
                    label={
                      <>
                        <Text className="text-[18.5px] font-bold">
                          {items.Title}
                        </Text>
                        <Text className="text-[13px] font-medium">
                          {items.description}
                        </Text>
                      </>
                    }
                  />
                );
              })}
            </Tabs>
          </div>
        )}

        <div
          className={classNames(
            bodyContainer,
            "flex overflow-x-scroll w-full no-scrollbar gap-[60.2px] px-[0%]"
          )}
        >
          {data?.map((plan, i) => {
            return (
              <>
                <div
                  className={` bg-[${plans[i]?.color}]  flex flex-col min-w-[280px] max-w-[280px]   border-t-[12px] border-[${plans[i]?.border}] bg-opacity-[10%] rounded-lg `}
                >
                  <div className="text-[#000000] px-[30.67px] py-2 text-center text-[22px] font-black w-full flex justify-center">
                    {plan?.category}
                  </div>
                  <div className="flex justify-center w-full mt-[10px] px-[40.67px]">
                    <Image src={plans[i]?.image} alt="Plan1" />
                  </div>
                  <div className="mt-[15.66px] px-[8.67px]">
                    <div
                      className={`text-[36px] mt-[14px] justify-center flex font-semibold ${plans[i]?.priceColor} items-center `}
                    >
                      {plan?.categoryAmount === 0 ? (
                        <p>Free</p>
                      ) : (
                        <>
                          ₹ {plan?.categoryAmount}
                          <span className="text-[15px] text-[#1B1D21] font-bold">
                            /year
                          </span>
                        </>
                      )}

                      {ActivePlanData?.categoryId === plan?.categoryId && (
                        <div className="bg-[#46A662] ml-[2px] text-nowrap py-1 text-[8px] rounded-md px-2 text-white">
                          Active Plan
                        </div>
                      )}
                    </div>
                    {!plan?.categoryAmount == 0 && (
                      <div className="text-[16px] text-center  -mt-1">
                        +18% GST
                      </div>
                    )}
                  </div>

                  <div className="w-[88%] mt-2 mx-auto flex border-b-[1.7px] border-solid border-gray-200  "></div>

                  <div className="mt-[20px] flex flex-col justify-between h-full px-[30.67px]">
                    <ul className="flex text-[#1B1D21] font-bold flex-col gap-[14px]">
                      {JSON.parse(plan?.benefits)
                        ?.slice(0, 4)
                        .map(({ name, showOrNot, value }) => {
                          return (
                            <li
                              key={name}
                              className="flex gap-[5px] text-[15px]  items-center font-semibold "
                            >
                              <Check className="bg-[#fcfefd] p-1 !stroke-2 rounded-full text-[#5AC587]" />{" "}
                              {name + " " + (showOrNot == 1 ? value : "")}
                              {/* {console.log("benefit", name)} */}
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                  <div
                    onClick={() => {
                      setSelectedPlan({ ...plan, index: i });
                      setDue(true);
                    }}
                    className="w-full flex items-center justify-center text-center mt-[0px] cursor-pointer text-[16.3px] font-bold"
                  >
                    <h6>More Info</h6>
                    <div className="-rotate-90 -ml-2 mt-[2px]">
                      <Player
                        autoplay
                        speed={1.5}
                        loop
                        src={arrows}
                        style={{
                          height: "50px",
                          padding: 0,
                          width: "40px",
                        }}
                      >
                        <Controls
                          visible={false}
                          buttons={[
                            "play",
                            "repeat",
                            "frame",
                            "debug",
                            "snapshot",
                            "background",
                          ]}
                        />
                      </Player>
                    </div>
                  </div>
                  <div className=" mb-[10px] flex justify-around gap-x-3 px-3 w-full ">
                    <Button
                      variant="outlined"
                      className={`hover:!bg-opacity-100 !bg-[${plans[i]?.color}]   !text-[${plans[i]?.color}] !border !border-[${plans[i]?.btnBorder}] !w-full rounded-[6px] !text-lg gap-4 !mt-4 !capitalize  !px-6 !py-3 !font-bold  `}
                    >
                      Compare
                    </Button>
                    <Button
                      onClick={() => {
                        setSelectedPlan({ ...plan, index: i });
                        BuyNowPlans();
                      }}
                      variant="outlined"
                      className={` ${plans[i]?.bgBtn}   !text-white text-nowrap !border border-[${plans[i]?.btnBorder}]  !w-full rounded-[6px] !text-lg gap-4 !mt-4 !capitalize  !px-6 !py-1 !font-bold `}
                    >
                      {ActivePlanData?.categoryId === plan?.categoryId
                        ? "Upgrade"
                        : "Buy Now"}
                    </Button>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default PlansCard;
