import {
	Checkbox,
	FormControlLabel,
	TextField,
	FormGroup,
} from "@mui/material";
import { medias } from "../../config/Resources";
import { RiArrowDropDownLine } from "react-icons/ri";
import CommonSearch from "./AllSearch";

function StreamFilter() {
	const options = [
		{
			id: 1,
			lable: "IIT Bhubaneswar",
			number: "446",
		},
		{
			id: 1,
			lable: "IIT Bhubaneswar",
			number: "446",
		},
		{
			id: 1,
			lable: "IIT Bhubaneswar",
			number: "446",
		},
		{
			id: 1,
			lable: "IIT Bhubaneswar",
			number: "446",
		},
		{
			id: 1,
			lable: "IIT Bhubaneswar",
			number: "446",
		},
		{
			id: 1,
			lable: "IIT Bhubaneswar",
			number: "446",
		},
		{
			id: 1,
			lable: "IIT Bhubaneswar",
			number: "446",
		},
		{
			id: 1,
			lable: "IIT Bhubaneswar",
			number: "446",
		},
	];

	return (
		<>
			<div className="flex item-center text-center justify-between ml-5 mt-5">
				<div className="flex item-center text-center">
					<img
						className="h-6 w-6"
						src={medias.images.Profile.Stream}
						alt="company 1"
					/>
					<p className="font-plusJakartaSans ml-2 mt-1 text-[15px] font-semibold">Stream</p>
				</div>
				{/* <img
						className="h-6 w-6"
						src={medias.images.Profile.Stream}
						alt="company 1"
					/> */}

			</div>
			<div>
				<div className="mt-5 ml-5 mr-5 mb-1">
					<CommonSearch />
				</div>
			</div>
			<FormGroup className="h-[300px] overflow-y-scroll flex pt-4 pb-6 pl-5 flex-col flex-nowrap no-scrollbar">
				{options?.map((option) => (
					<div className="flex text-center items-center justify-between !p-0 !pr-5 !m-0  ">
						<FormControlLabel
							className="miniDesk:!text-[10px] !font-plusJakartaSans !text-nowrap !overflow-ellipsis !text-[13px]"
							key={option.id}
							control={
								<div className="flex text-center items-center">
									<Checkbox color="success" />
									<p className="ml-[-6px] normal-case font-plusJakartaSans font-semibold">{option?.lable}</p>
								</div>
							}
						/>
						<p className="miniDesk:!hidden !font-plusJakartaSans !overflow-ellipsis !text-[13px] font-semibold">
							{option?.number}
						</p>
					</div>
				))}
			</FormGroup>
		</>
	);
}

export default StreamFilter;
