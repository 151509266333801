import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import axiosInstance from "../../../config/Axios/axiosInstance";
import { API_URLS } from "../../../config/API_URLS";
import Image from "../../Image";
import { medias } from "../../../config/Resources";
import { Controls, Player } from "@lottiefiles/react-lottie-player";
import astronot from "../../../animations/astronot.json";
import planet from "../../../animations/planet.json";
import Questionsirheader from "../questionsircomponents/questionairheader";
import Questions from "../questionsircomponents/questions";
import Timer from "../questionsircomponents/timer";
import Options from "../questionsircomponents/options";
import { Button, CircularProgress, Modal, useMediaQuery } from "@mui/material";
import classNames from "classnames";
import { useIntrestStore } from "../../../store";
import { useUserInfoStore } from "../../../store/userinfo";
import { useNavigate } from "react-router-dom";
import Text from "../../Text";
import { Check, CheckBox, ChevronRight } from "@mui/icons-material";

const Intreset = ({ setOpenNext, next, setNext }: any) => {
  const [page, setPage] = useState(1);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const limit = 10;
  const [isChecked, setIsChecked] = useState(false);

  const desktop = useMediaQuery("(max-width: 1441px)");

  const miniDesk = useMediaQuery("(min-width: 1350px)");

  console.log("minidesk", miniDesk, "desktop", desktop);

  const navigate = useNavigate();
  const { userInfo, setIsloading }: any = useUserInfoStore((state) => state);
  const { intrestquestions, addQuestion }: any = useIntrestStore(
    (state) => state
  );
  const [questionLists, setQuestionsList] = useState<any>(null);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [isStartTime, setIsStartTime] = useState(false);
  const [open, setOpen] = React.useState("");
  const handleOpen = (modalName: string) => setOpen(modalName);
  const handleClose = () => setOpen("");

  const handleFetchQuestion = async (queryKey: any) => {
    return await axiosInstance
      .get(`${API_URLS.getInterestQuestionPaper}`)
      .then((res: any) => {
        const currentQuestions: any = questionLists?.result
          ? [...questionLists?.result, ...res?.result]
          : [...res?.result];
        const newValue = { ...res, result: [...currentQuestions] };
        setQuestionsList({ ...newValue });
        return newValue;
      });
  };

  useEffect(() => {
    handleOpen("instruction");
  }, []);

  const { mutate: submitQuestion, isPending } = useMutation({
    mutationFn: async () =>
      await axiosInstance
        .post(API_URLS.addInterestReport, {
          data: intrestquestions,
          student_id: userInfo?.id,
        })
        .then(async (res) => {
          // await axiosInstance.post("/addParticularCareerReport", {
          //     type:"interest"
          // }).then(res => {
          setNext({
            current: "Interest",
            Next: "Personality",
            image: medias.images.congratulations.Intrest,
            NoOfQuestion: 90,
            Time: 30,
            nextpath: "/psychomatric-test?category=personality",
          });
          setOpenNext(true);
          // })
          return res;
        }),
  });

  useEffect(() => {
    setIsloading(isPending);
  }, [isPending]);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      const message =
        "Are you sure? You'll loose all the progress that you have achieved";
      event.returnValue = message; // Standard for most browsers
      return message; // For some older browsers
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const { isLoading: IsIntrestLoading }: { data: any; isLoading: boolean } =
    useQuery({
      queryKey: ["getInterestQuestionPaper"],
      queryFn: ({ queryKey }) => handleFetchQuestion(queryKey),
      refetchOnMount: true,
      retry: false,
      refetchInterval: false,
    });

  useEffect(() => {
    if (
      questionLists?.total_count - currentQuestion + 1 > 10 &&
      (currentQuestion + 1) % 10 === 2
    ) {
      setPage(page + 1);
    }
  }, [currentQuestion]);

  return (
    <>
      <Modal
        open={open === "instruction"}
        // onClose={handleClose}
        className="flex items-center !bg-[#000000CC] opacity-100 justify-center !border-none "
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="p-[3px] rounded-3xl absolute w-[50%]  gradient-bg-1 !border-none ">
          <div className="w-full h-full bg-[#151819]   3xl:!py-[20px]  pt-[48px] flex flex-col items-center pb-[33px] pr-[20px] rounded-3xl">
            <div className="relative flex flex-col items-center w-full">
              <Image
                src={medias.images.instructionBanner}
                className="3xl:w-1/2"
                alt="instructionBanner"
              />
              <Text className="absolute top-[calc(50%-17px)] desktop:text-[17px]  text-[21px] font-ibmPlexMonoRegular font-bold">
                Instructions to Follow
              </Text>
            </div>
            <ul className=" text-[16px] desktop:text-[12px] miniDesk:!max-h-[100px] desktop:max-h-[200px] text-[#FFFFFF] flex flex-col gap-[12px] max-h-[320px] overflow-y-scroll ml-[20px] mt-[38px] ">
              <li className="ml-[20px] list-style-check">
                <Text className="font-ibmPlexMonoLight ml-[9px]">
                  {" "}
                  Sit somewhere quiet with no distractions and try to stay calm{" "}
                </Text>{" "}
              </li>
              <li className="ml-[20px] list-style-check">
                <Text className="font-ibmPlexMonoLight ml-[9px]">
                  {" "}
                  Have a pen, paper and calculator to hand{" "}
                </Text>{" "}
              </li>
              <li className="ml-[20px] list-style-check">
                <Text className="font-ibmPlexMonoLight ml-[9px]">
                  {" "}
                  Do the tests on a laptop or PC and use headphones if you have
                  them{" "}
                </Text>{" "}
              </li>
              <li className="ml-[20px] list-style-check">
                <Text className="font-ibmPlexMonoLight ml-[9px]">
                  {" "}
                  Make sure you have a reliable internet connection{" "}
                </Text>{" "}
              </li>
              <li className="ml-[20px] list-style-check">
                <Text className="font-ibmPlexMonoLight ml-[9px]">
                  {" "}
                  Log on with plenty of time ahead, close down all other windows
                  and maximise the test window{" "}
                </Text>{" "}
              </li>
              <li className="ml-[20px] list-style-check">
                <Text className="font-ibmPlexMonoLight ml-[9px]">
                  {" "}
                  Check the timings – A timer is provided which will actually
                  tell you how long you have for each section.{" "}
                </Text>{" "}
              </li>
              <li className="ml-[20px] list-style-check">
                <Text className="font-ibmPlexMonoLight ml-[9px]">
                  {" "}
                  Do not use the back button in your browser as it may end the
                  test without saving your answers{" "}
                </Text>{" "}
              </li>
              <li className="ml-[20px] list-style-check">
                <Text className="font-ibmPlexMonoLight ml-[9px]">
                  {" "}
                  If you can’t answer a question don’t stick on it and lose
                  time– take a guess and move on{" "}
                </Text>{" "}
              </li>
              <li className="ml-[20px] list-style-check">
                <Text className="font-ibmPlexMonoLight ml-[9px]">
                  {" "}
                  If you answer a question and have time before the next one
                  appears, use the time to calm yourself with a couple of deep
                  breaths{" "}
                </Text>{" "}
              </li>
            </ul>
            <Text className="text-[#ffffff] ml-[20px] text-[12px] mt-[14px] font-light font-ibmPlexMonoLight text-opacity-45">
              Note* : Read all the instructions carefully, attempt all the
              questions & utilize the time provided judiciously.
            </Text>
            <div className="text-[#ffffff] ml-[20px] flex items-start text-[12px] mt-[14px] font-light font-ibmPlexMonoLight text-opacity-45">
              <input
                type="checkbox"
                onChange={(e) => setIsChecked(e?.target?.checked)}
                className="mt-1 h-[16px] w-[16px] bg-[#ECCF3A17] hidden"
                id="agreement"
              />
              <label className="flex" htmlFor="agreement">
                <div
                  className={classNames(
                    isChecked ? "bg-[#A7650B]" : "bg-[#ECCF3A17]",
                    " min-w-[20px] h-[18px] rounded-[5px] border border-[#A7650B]"
                  )}
                >
                  {isChecked && (
                    <Check className="!text-[17px] relative bottom-[2px] stroke-[20px]  !text-[#000]  !rounded-lg" />
                  )}
                </div>
                <Text className="ml-[10px] desktop:text-[10px] font-ibmPlexMonoLight text-white text-[14px]">
                  By Clicking, I confirm that i have read all the instructions
                  carefully & agree to provide personal information & answer all
                  the questions in the psychometric test.
                </Text>
              </label>
            </div>
            <Button
              disabled={!isChecked}
              onClick={() => {
                setIsStartTime(true);
                handleClose();
              }}
              className="!bg-[#367BEF] mt-[36px] mb-[20px] !capitalize  desktop:!text-[13px] !flex !items-center !px-[19px] !font-ibmPlexMonoRegular !py-[9px] !rounded-xl !text-[19px]  !shadow-[0px_8px_0px_0px_rgba(25,82,179,1)]"
              variant="contained"
            >
              Get Started{" "}
              <ChevronRight className="  bg-[#000000] bg-opacity-35 ml-4 !w-[30px] desktop:!w-[25px] desktop:!h-[25px] !h-[30px] !rounded-full " />
            </Button>
          </div>
        </div>
      </Modal>
      <div className="flex items-center mob:static justify-center tab:h-full h-screen tab:items-start bg-no-repeat bg-cover IntrestMainBackground mob:!rotate-[270deg]  tabToMobile:!rotate-90 ">
        <div className="questionairbackground-intrest desktop:pb-0 z-50 miniDesk:!h-auto tab:mt-[20px] relative bg-contain bg-no-repeat 3xl:h-[85%] 3xl:!w-[70%]  flex miniDesk:w-[95%] miniDesk:relative miniDesk:pt-[7%] pt-[75px] pb-[70px] px-[13px] items-center justify-center w-[70%] h-[90%]">
          {/* <div className='absolute w-[calc(100%-30px)] h-[calc(100%-135px)] 3xl:h-[calc(100%-126px)]  bg-black bg-opacity-50 tab:!top-[6%] miniDesk:top-[10%] 3xl:top-[calc(0%+73px)] top-[calc(0%+85px)] gridBackGround-intrest  backdrop-blur-lg' /> */}
          <div className="relative z-50 miniDesk:pb-[50px] w-full h-full py-[19px] px-[13px] desktop:!pt-0">
            <div className="absolute miniDesk:hidden bottom-[calc(0%-60px)] left-[calc(0%-20px)]">
              <Player
                autoplay
                speed={1.5}
                loop
                src={astronot}
                style={{ height: "200px", padding: 0, width: "200px" }}
              >
                <Controls
                  visible={false}
                  buttons={[
                    "play",
                    "repeat",
                    "frame",
                    "debug",
                    "snapshot",
                    "background",
                  ]}
                />
              </Player>
            </div>
            <div
              className={classNames(
                "w-[90%] tab:!w-[88%] flex flex-col justify-between  relative z-50 ml-[50px]",
                !(!IsIntrestLoading || questionLists?.result?.length > 0) &&
                  "h-full"
              )}
            >
              {!IsIntrestLoading ||
              questionLists?.result?.length > 0 ||
              !isPending ? (
                <>
                  <div className="3xlDeskBig:mt-[16px] 3xlDesk:mt-[12px]">
                    <Questionsirheader
                      questionsInfo={{
                        currentQuestion: `${currentQuestion + 1}`,
                        totalQuestion: `${questionLists?.total_count}`,
                        type: "intrest",
                      }}
                    />
                  </div>
                  <>
                    {" "}
                    {console.log(
                      miniDesk && desktop
                        ? "200px"
                        : miniDesk
                        ? "150px"
                        : "110px"
                    )}
                  </>
                  <div className="w-full mt-[20px] 3xlDeskBig:mt-[32px] 3xlDesk:mt-[24px]  h-full">
                    <Questions
                      lottieHeight={
                        miniDesk && desktop
                          ? "200px"
                          : miniDesk
                          ? "165px"
                          : "120px"
                      }
                      lottieWidth={
                        miniDesk && desktop
                          ? "200px"
                          : miniDesk
                          ? "165px"
                          : "120px"
                      }
                      questionInfo={questionLists?.result[currentQuestion]}
                    />
                  </div>
                  <Timer
                    isApti={true}
                    isStartTime={true}
                    totalTime={30}
                    mainClassName="mt-auto -top-[4px] 3xlDeskBig:top-[24px] 3xlDesk:top-[16px]  !absolute 3xlDeskBig:left-[47%] 3xlDesk:left-[44%]  left-[42%] !w-fit "
                    imageClass="absolute min-w-[100px] desktop:min-w-[80px] right-[16%]"
                    textClassName=" !px-3  !py-2 !text-white !relative !z-10 !rounded-full right-[1%] desktopMin:right-[8%] 3xlDesk:right-[9%] 3xlDeskBig:right-[9%] "
                    bgImage={medias.images.intrestImages.timerBackgroung}
                  />
                  <div className="flex justify-center 3xl:mt-1">
                    <Options
                      mainClass={"desktop:!max-w-[600px] "}
                      optionContainerClass="!w-full miniDesk:w-1/5"
                      optionClass={`miniDesk:min-w-full ${
                        miniDesk && desktop
                          ? " !min-h-[44px]  "
                          : miniDesk
                          ? " !min-h-[55px] mt-6"
                          : " !min-h-[34px] "
                      } relative z-[999]   desktop:!h-fit `}
                      addQestion={addQuestion}
                      isSubmitted={isSubmitted}
                      setIsSubmitted={setIsSubmitted}
                      submitQuestion={submitQuestion}
                      total_question={questionLists?.total_count}
                      currentQuestion={currentQuestion}
                      setCurrentQuestion={setCurrentQuestion}
                      questionInfo={questionLists?.result[currentQuestion]}
                    />
                  </div>
                </>
              ) : (
                <div className="flex items-center justify-center w-full h-full">
                  <CircularProgress
                    classes={{
                      root: "!text-[#fff]",
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <Image
          src={medias.images.intrestImages.leftbgStone}
          alt="baseScreen"
          className="absolute bottom-0 right-0 object-contain w-1/4 z-[60] "
        />
        <Image
          src={medias.images.intrestImages.stonebottom}
          alt="baseScreen"
          className="absolute bottom-0 left-0 z-[20] object-contain w-1/2"
        />
        <div className="absolute top-[calc(0%-100px)] right-0 z-0">
          <Player
            autoplay
            speed={1.5}
            loop
            src={planet}
            style={{ height: "500px", padding: 0, width: "500px" }}
          >
            <Controls
              visible={false}
              buttons={[
                "play",
                "repeat",
                "frame",
                "debug",
                "snapshot",
                "background",
              ]}
            />
          </Player>
        </div>
      </div>
    </>
  );
};

export default Intreset;
