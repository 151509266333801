/* eslint-disable eqeqeq */
import React from "react";
import HeaderTopSection1 from "../../HedaerTopSection/HeaderTopSection1";
import HeaderBottomSection1 from "../../HeaderBottomSection/HeaderBottomSection1";
import HeaderMiddleSection1 from "../../HeaderMiddleSection/HeaderMiddleSection1";
import classNames from "classnames";
import { useLocation } from "react-router-dom";

const Header1 = () => {
  const location = useLocation();
  const { pathname } = useLocation();

  const checkLocation = pathname.includes("mentorship");
  return (
    <header className="relative z-50">
      <div
        className={classNames(
          "w-full",
          location.pathname === "/"
            ? "bg-transparent"
            : checkLocation
            ? "bg-[#01150D]"
            : pathname == "/guidance"
            ? "bg-[#080838]"
            : "bg-midNightExpress-1"
        )}
      >
        <HeaderTopSection1 />
      </div>
      <div>
        <HeaderMiddleSection1 />
      </div>
      {!checkLocation && (
        <div>
          <HeaderBottomSection1 />
        </div>
      )}
    </header>
  );
};

export default Header1;
