/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import { medias } from "../../config/Resources";
import { VscClose } from "react-icons/vsc";
import { IoIosArrowRoundForward } from "react-icons/io";
import { API_URLS } from "../../config/API_URLS";
import axiosInstance from "../../config/Axios/axiosInstance";
import { toast } from "react-toastify";

function EditMileStones({
	isModalOpen4,
	setIsModalOpen4,
	toggleModal4,
	selectedItemId3,
	selectedMilestoneId,
	setData,
}) {
	console.log(selectedMilestoneId, "allSelectedItem");


	const [milestone, setMilestone] = useState({
		name: "",
		duration_type: "",
		start_date: "",
		end_date: "",
		notes: "",
		status: "1",
	});

	useEffect(() => {
		if (selectedMilestoneId) {
			setMilestone(selectedMilestoneId);
		}
	}, [selectedItemId3, selectedMilestoneId]);

	const handleMilestoneChange = (key, value) => {
		setMilestone((prevMilestone) => ({
			...prevMilestone,
			[key]: value,
		}));
	};

	const getAcademicRoadMapData = async () => {
		try {
			const res = await axiosInstance.get(
				`${API_URLS.baseURL}/getPassionRoadmap?type=`
			);
			setData(res.result);
			console.log(res, "responseData");
		} catch (error) {
			console.log(error);
		}
	};

	const handleSave = async () => {
		const payload = {
			passionData: {
				passion_id: selectedItemId3?.passion_id,
				category: selectedItemId3?.category,
				milestones: [
					{
						passion_milestone_id: selectedMilestoneId?.passion_milestone_id,
						...milestone,
					},
				],
			},
		};

		try {
			const response = await axiosInstance.post(
				`${API_URLS.baseURL}/updatePassionMilestoneRoadmap`,
				payload
			);

			if (response) {
				toast("Milestone Updated Successfully", { type: "success" });
				getAcademicRoadMapData();
				setIsModalOpen4(false);
			} else {
				console.error("Error:", response.statusText);
			}
		} catch (error) {
			console.error("Error:", error);
		}
	};

	return (
		<div>
			<div
				id="default-modal"
				tabIndex="-1"
				aria-hidden="true"
				className={`fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full h-screen bg-black bg-opacity-10 ${
					isModalOpen4 ? "" : "hidden"
				}`}
			>
				<div className="relative p-4 w-full max-w-lg">
					<div className="relative bg-[#E4F6FF] rounded-lg shadow dark:bg-gray-700">
						<div className="flex text-center ml-5">
							<img
								className="w-8 h-8 mt-5"
								src={medias.images.Profile.category}
								alt="image"
							/>
							<h6 className="ml-1 mt-7">Edit Milestone</h6>
						</div>
						<div className="p-5">
							<div>
								<p className="font-plusJakartaSans text-sm font-semibold">
									Milestone Name
								</p>
								<input
									type="text"
									value={milestone.name}
									onChange={(e) =>
										handleMilestoneChange("name", e.target.value)
									}
									className="block mt-1 w-full p-2 rounded-lg"
									placeholder="Name"
								/>
							</div>
							<div className="mt-4">
								<p className="font-plusJakartaSans text-sm font-semibold">
									Duration
								</p>
								<div className="border rounded-lg mt-2">
									<select
										value={milestone.duration_type}
										onChange={(e) =>
											handleMilestoneChange("duration_type", e.target.value)
										}
										className="block w-full p-2 mt-1 rounded-lg"
									>
										<option value="2 weeks">2 weeks</option>
										<option value="3 months">3 months</option>
										<option value="6 months">6 months</option>
									</select>
								</div>
							</div>
							<div className="flex justify-between text-center mt-4">
								<div>
									<p className="font-plusJakartaSans text-sm font-semibold">
										Start Date
									</p>
									<input
										type="date"
										value={milestone.start_date}
										onChange={(e) =>
											handleMilestoneChange("start_date", e.target.value)
										}
										className="block w-full p-2 rounded-lg"
									/>
								</div>
								<div>
									<p className="font-plusJakartaSans text-sm font-semibold">
										End Date
									</p>
									<input
										type="date"
										value={milestone.end_date}
										onChange={(e) =>
											handleMilestoneChange("end_date", e.target.value)
										}
										className="block w-full p-2 ml-2 rounded-lg"
									/>
								</div>
							</div>
							<div className="mt-4">
								<p className="font-plusJakartaSans text-sm font-semibold">
									Notes
								</p>
								<textarea
									value={milestone.notes}
									onChange={(e) =>
										handleMilestoneChange("notes", e.target.value)
									}
									className="block w-full p-2 rounded-lg"
									placeholder="Notes"
									rows="4"
								></textarea>
							</div>
							<div className="flex items-end justify-end">
								<button
									className="flex align-center items-center justify-center  w-[] mt-2 bg-[#FFDDCE]  text-black font-medium rounded-lg text-sm px-5 py-2.5 text-center"
									onClick={() => toggleModal4()}
								>
									<p className="font-plusJakartaSans text-sm font-semibold">
										Cancel
									</p>
									<VscClose className="ml-1" />
								</button>
								<button
									className="flex align-center items-center justify-center ml-2 w-[] mt-2 bg-[#F8D865]  text-black font-medium rounded-lg text-sm px-5 py-2.5 text-center"
									onClick={handleSave}
								>
									<p className="font-plusJakartaSans text-sm font-semibold">
										edit
									</p>
									<IoIosArrowRoundForward className="ml-1" />
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default EditMileStones;
