/* eslint-disable jsx-a11y/img-redundant-alt */
import { useState } from "react";
import { medias } from "../../../../config/Resources";

function ExamsTable({ tableData, selectedChip, staticChip }) {
	const [selectedCategory, setSelectedCategory] = useState("");

	const handleCategoryChange = (event) => {
		setSelectedCategory(event.target.value);
	};
	const filteredData = selectedCategory
		? tableData.filter((category) => category.category === selectedCategory)
		: tableData;
	return (
		<div>
			<p className="flex items-center mt-5 mb-5 ml-5 font-plusJakartaSans font-extrabold text-opacity-70 text-[11px] text-center gap-2">
				<span className="rounded-lg bg-opacity-10">
					<img
						className="h-6 w-6"
						src={medias.images.College.Calender2}
						alt="image"
					/>
				</span>
				<span className="mt-[2px]">Important Dates</span>
			</p>
			{selectedChip !== staticChip ? (
				<div className="ml-5 mb-5">
					<label
						htmlFor="course"
						className="font-plusJakartaSans font-bold text-[13px] mr-2"
					>
						Select Course:
					</label>
					<select
						id="course"
						value={selectedCategory}
						onChange={handleCategoryChange}
						className="font-plusJakartaSans font-medium text-[11px] border border-gray-300 rounded-md p-1 focus:outline-none focus:ring-2 focus:ring-blue-500"
					>
						{tableData?.map((category, index) => (
							<option key={index} value={category?.category}>
								{category?.category}
							</option>
						))}
					</select>
				</div>
			) : (
				""
			)}
			<p className="font-plusJakartaSans font-bold text-[14px] ml-5">Exams</p>
			<div className="ml-4 mr-4 mt-3 mb-5">
				<div className="overflow-x-auto">
					<table className="min-w-full border-collapse">
						<thead>
							<tr>
								<th className="px-4 py-2 bg-[#FFEDC0] text-center font-plusJakartaSans font-bold text-[12px] rounded-l-sm border-r-2 border-white">
									S.No
								</th>
								<th className="px-4 py-2 bg-[#FFEDC0] text-center font-plusJakartaSans font-bold text-[12px] border-r-2 border-white">
									Event Name
								</th>
								<th className="px-4 py-2 bg-[#FFEDC0] text-center font-plusJakartaSans font-bold text-[12px] border-r-2 border-white">
									Start Date
								</th>
								<th className="px-4 py-2 bg-[#FFEDC0] text-center font-plusJakartaSans font-bold text-[12px] rounded-r-sm">
									End Date
								</th>
							</tr>
						</thead>
						{staticChip === selectedChip ? (
							<tbody className="bg-[#ffffff]">
								{tableData?.map((detail, index) => (
									<tr
										key={index}
										className="border-b border-dotted last:border-0"
									>
										<td className="px-4 py-2 text-center font-plusJakartaSans font-medium text-[11px]">
											{index + 1}
										</td>
										<td className="px-4 py-2 text-center font-plusJakartaSans font-medium text-[11px]">
											{detail?.event_name || "--"}
										</td>
										<td className="px-4 py-2 text-center font-plusJakartaSans font-medium text-[11px]">
											{detail?.start_date || "--"}
										</td>
										<td className="px-4 py-2 text-center font-plusJakartaSans font-medium text-[11px]">
											{detail?.end_date || "--"}
										</td>
									</tr>
								))}
							</tbody>
						) : (
							<tbody className="bg-[#ffffff]">
								{filteredData?.map((detail, index) =>
									detail?.dates?.map((data) => {
										return (
											<tr
												key={index}
												className="border-b border-dotted last:border-0"
											>
												<td className="px-4 py-2 text-center font-plusJakartaSans font-medium text-[11px]">
													{index + 1}
												</td>
												<td className="px-4 py-2 text-center font-plusJakartaSans font-medium text-[11px]">
													{data?.event_name || "--"}
												</td>
												<td className="px-4 py-2 text-center font-plusJakartaSans font-medium text-[11px]">
													{data?.from_date || "--"}
												</td>
												<td className="px-4 py-2 text-center font-plusJakartaSans font-medium text-[11px]">
													{data?.to_date || "--"}
												</td>
											</tr>
										);
									})
								)}
							</tbody>
						)}
					</table>
				</div>
			</div>
		</div>
	);
}

export default ExamsTable;
