const ProgressTrackerAchieved = () => {
	const AcdameyDetails = [
		{
			number: "8",
			label: "Milestones",
			label1: "Achieved",
		},
		{
			number: "65%",
			label: "% of roadmap",
			label1: "completed",
		},
		{
			number: "4",
			label: "Deviations",
			label1: "Observed",
		},
	];

	return (
		<>
			<div className="flex gap-3 mt-5 ml-5">
				{AcdameyDetails?.map((detail, index) => (
					<div
						key={index}
						className="bg-white rounded-xl shadow-md border w-[220px] border-gray-300 p-6"
					>
						<div className="flex items-center justify-center mb-2">
							<p className="font-medium text-4xlfont-plusJakartaSan">{detail.number}</p>
						</div>
						<div className="text-sm text-center">
							<p className="font-semibold font-plusJakartaSan">{detail.label}</p>
							<p className="font-semibold font-plusJakartaSan">{detail.label1}</p>
						</div>
					</div>
				))}
			</div>
		</>
	);
};

export default ProgressTrackerAchieved;
