import React, { useState } from "react";
import { medias } from "../../config/Resources";

const VerticalTab = ({setActiveTab}) => {
	const [active, setActive] = useState(1);
	const tabs = [
		{ name: "Academics", id: 1, imageUrl: medias.images.Profile.Academy },
		{ name: "Sports", id: 2, imageUrl: medias.images.Profile.Sport1 },
		{ name: "Meals", id: 3, imageUrl: medias.images.Profile.Meals },
		{ name: "Household", id: 4, imageUrl: medias.images.Profile.HouseHold },
		{ name: "Others", id: 5, imageUrl: medias.images.Profile.Others },
	];

	const handleTabClick = (id) => {
		setActive(id);
		setActiveTab(id)
	};

	return (
        <>
        <div className="mt-12 flex items-center justify-center">
            <h1 className="text-xl font-normal font-quitery">Activity Type</h1>
        </div>
        <div className="flex flex-col mt-16">

                <div className={`${active === 2 ? "w-full" : "w-[100%]"}`}>
                    <div className="flex flex-col ml-5 font-bold">
                        {tabs?.map((tab) => (
                            <div
                                key={tab.id}
                                onClick={() => handleTabClick(tab.id)}
                                className={`relative  cursor-pointer flex items-center mx-2 ${active === tab.id ? "bg-[#DFDFDF] rounded-t-lg" : ""}`}
                            >
                                <div className="flex items-center p-2">
                                    <img src={tab.imageUrl} alt={tab.name} className="w-6 h-6" />
                                    <h3
                                        className={`pb-3 px-2 text-sm ${active === tab.id ? "font-bold" : "font-light"}`}
                                    >
                                        <span className="font-plusJakartaSans">{tab.name}</span>
                                    </h3>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div></>
	);
};

export default VerticalTab;
