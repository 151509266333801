import { medias } from "../../../config/Resources";
import RecentlyAddedCards from "./RecentlyAddedCards";

function RecentlyAdded({ blogsData, ChangePage, dataLimit, totalCount }) {
	return (
		<div>
			<div className="flex items-center text-center justify-between mt-14 ml-5 mr-5 mb-4 pb-3 pl-7 pr-9">
				<h1 className="font-plusJakartaSans font-bold text-[23px] flex items-center text-center gap-[5px]">
					<img
						className="w-11 h-11"
						src={medias.images.Blogs.RecentlyAdded}
						alt="Fire"
					/>
					Recently Added
				</h1>
			</div>
			<div className="ml-7 mr-5 pb-8">
				<RecentlyAddedCards
					ChangePage={ChangePage}
					dataLimit={dataLimit}
					totalCount={totalCount}
					blogsData={blogsData}
				/>
			</div>
		</div>
	);
}

export default RecentlyAdded;
