import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Intreset from "../../Components/questions/intrest";
import Personality from "../../Components/questions/personality";
import Socialbehaviour from "../../Components/questions/socialbehaviour";
import Aptitude from "../../Components/questions/aptitude";
import { Button, Modal, useMediaQuery } from "@mui/material";
import Image from "../../Components/Image";
import { medias } from "../../config/Resources";
import Text from "../../Components/Text";
import { Controls, Player } from "@lottiefiles/react-lottie-player";
import Verified from "../../animations/Verified.json";
import goldflare from "../../animations/goldflare.json";

import { ChevronRight } from "@mui/icons-material";
import axiosInstance from "../../config/Axios/axiosInstance";
import { API_URLS } from "../../config/API_URLS";
import { useUserInfoStore } from "../../store/userinfo";

const PsychomatricTest = () => {
  const searchParams = useSearchParams();
  const [open, setOpen] = useState("");
  const [next, setNext] = useState("");
  const desktop = useMediaQuery("(max-width : 1440px)");
  const [dimensionValue, setDimensionValue] = useState(1);
  const { userInfo } = useUserInfoStore((state) => state);

  const navigate = useNavigate();
  const handleClose = () => setOpen("");
  const category = searchParams[0].get("category");

  useEffect(() => {
    (async () => {
      try {
        const res = await axiosInstance.get(
          API_URLS.getActivePlan + `?class=${userInfo?.class}`
        );
        if (res?.statusCode === 201) {
          // setActivePlan(false);
        } else if (res?.statusCode === 200) {
          const benefitObj = res?.result?.benefits;
          setDimensionValue(benefitObj["Career Assessment Test of Dimensions"]);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [open]);

  // console.log("dimensionValue", dimensionValue);
  return (
    <>
      <Modal
        open={open}
        // onClose={handleClose}
        className="flex items-center !bg-[#000000CC] opacity-100 justify-center !border-none "
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <div className="absolute top-0">
            <Player
              autoplay
              speed={1.5}
              loop
              src={goldflare}
              style={{
                height: desktop ? "500px" : "700px",
                padding: 0,
                width: desktop ? "500px" : "700px",
              }}
            >
              <Controls
                visible={false}
                buttons={[
                  "play",
                  "repeat",
                  "frame",
                  "debug",
                  "snapshot",
                  "background",
                ]}
              />
            </Player>
          </div>

          <div className="p-[3px] rounded-3xl absolute desktop:bottom-[80px] top-[18%] w-[50%] gradient-bg-2 !border-none ">
            <div className="w-full h-full bg-[#151819]   3xl:!py-[20px]  pt-[48px] flex flex-col items-center pb-[33px] pr-[20px] rounded-3xl">
              <div className="relative flex flex-col items-center w-full mt-5">
                <Image
                  src={medias.images.getStartedPopup}
                  className="3xl:w-3/4 desktop:!w-[88%]"
                  alt="instructionBanner"
                />
                <Text className="absolute top-[calc(50%-17px)] text-[21px] desktop:!text-[16px] font-ibmPlexMonoRegular font-bold">
                  You Have Completed {next?.current} Section
                </Text>
              </div>
              <div className="flex flex-col items-center">
                <Player
                  autoplay
                  speed={1.5}
                  loop
                  src={Verified}
                  style={{
                    height: desktop ? "120px" : "200px",
                    padding: 0,
                    width: desktop ? "120px" : "200px",
                  }}
                >
                  <Controls
                    visible={false}
                    buttons={[
                      "play",
                      "repeat",
                      "frame",
                      "debug",
                      "snapshot",
                      "background",
                    ]}
                  />
                </Player>
                <Text className="text-white text-[19px] desktop:text-[14px] space-x-[4px] font-ibmPlexMonoLight">
                  NEXT SECTION
                </Text>
                <div className="bg-[#000] mt-[13px] py-[7px] pl-[10px] rounded-full flex pr-[27px] items-center">
                  <div className="bg-white bg-opacity-[7%] rounded-full px-[17px] py-[5px]">
                    <Image
                      src={next?.image}
                      className="desktop:w-[40px]"
                      alt="NextIcons"
                    />
                  </div>
                  <Text className="text-white desktop:text-[12px] ml-[8px]">
                    {next?.Next}
                  </Text>
                </div>
                <div className="flex justify-between gap-[80px] mt-[20px] w-full">
                  <Text className="text-white desktop:text-[12px] font-ibmPlexMonoLight">
                    Total no. of question : {next?.NoOfQuestion}
                  </Text>
                  <Text className="text-white desktop:text-[12px] font-ibmPlexMonoLight">
                    Time Duration : {next?.Time} MIN
                  </Text>
                </div>
              </div>
              <Button
                onClick={() => {
                  navigate(next?.nextpath);
                  handleClose();
                }}
                className="!bg-[#367BEF] mt-[36px] mb-[20px] !capitalize  desktop:!text-[12px] !flex !items-center !px-[19px] !font-ibmPlexMonoRegular !py-[9px] !rounded-xl !text-[19px]  !shadow-[0px_8px_0px_0px_rgba(25,82,179,1)]"
                variant="contained"
              >
                Get Started{" "}
                <ChevronRight className="  bg-[#000000] bg-opacity-35 ml-4 !w-[30px] desktop:!w-[25px] desktop:!h-[25px] !h-[30px] !rounded-full " />
              </Button>
            </div>
          </div>
        </>
      </Modal>

      {(category === "intrest" || category === "interest") && (
        <Intreset
          setOpenNext={setOpen}
          next={next}
          setNext={setNext}
          dimensionValue={dimensionValue}
        />
      )}
      {category === "personality" && (
        <Personality
          setOpenNext={setOpen}
          next={next}
          setNext={setNext}
          dimensionValue={dimensionValue}
        />
      )}
      {category === "socialBehaviour" && (
        <Socialbehaviour
          setOpenNext={setOpen}
          next={next}
          setNext={setNext}
          dimensionValue={dimensionValue}
        />
      )}
      {category === "aptitude" && (
        <Aptitude
          setOpenNext={setOpen}
          next={next}
          setNext={setNext}
          dimensionValue={dimensionValue}
        />
      )}
    </>
  );
};

export default PsychomatricTest;
