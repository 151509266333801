import * as yup from "yup";

export const LoginSchema = yup.object().shape({
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Please enter email address")
    .matches(
      /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/,
      "Invalid email format"
    ),
  password: yup.string().required("please enter you password"),
});

export const RegisterSchema = yup.object().shape({
  email: yup
    .string()
    .required("Email is a required field")
    .email("Enter a valid email ")
    .matches(
      /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/,
      "Invalid email format"
    ),
  countryCode: yup.mixed().nullable(),
  mobile: yup
    .string()
    .required("Mobile number is a required field")
    .matches(
      /^[0-9]{6,17}$/,
      "Invalid mobile number format ( only numeric digits)"
    )
    .min(6, "Mobile number must be at least 6 digits")
    .max(17, "Mobile number must be at most 17 digits"),
  // password: yup
  //   .string()
  //   .required("Password is a required field")
  //   .matches(
  //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})/,
  //     "6 characters, 1 uppercase, 1 lowercase, 1 number, 1 special character."
  //   ),
  firstName: yup
    .string()
    .required("First Name is a required field")
    .matches(/^[A-Za-z]+$/, "Invalid first name"),

  lastName: yup
    .string()
    .required("Last Name is a required field")
    .matches(/^[A-Za-z]+(?: [A-Za-z]+)?$/, "Invalid last name"),
  middleName: yup.string().matches(/^[A-Za-z]*$/, "Invalid middle name"),
  country: yup.mixed().required("Country is a required field"),
  state: yup.mixed().required("State is a required field"),
  city: yup.mixed().required("City is a required field"),
  class: yup.mixed().required("Class is a required field"),
});
