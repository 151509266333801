import { useEffect, useState } from "react";
import { medias } from "../../../../config/Resources";
import axiosInstance from "../../../../config/Axios/axiosInstance";
import { API_URLS } from "../../../../config/API_URLS";
import ExamsTable from "./ExamsTable";
import Application from "./Application";
import CouncellingAgencies from "./CouncellingAgencies";

function ExamContent({ admissions, data, setExamsData, examsData }) {
	const staticChip = { id: "static", exam_name: "Direct Admission" };
	const [selectedChip, setSelectedChip] = useState(staticChip.id);
	const [examId, setExamId] = useState();
	const [details, setDetails] = useState([]);
	const [selectedChipName, setSelectedChipName] = useState();

	useEffect(() => {
		const fetchExamDetails = async () => {
			const res = await axiosInstance.get(
				`${API_URLS.getIndianCollegeAdmissionDetails}?college_id=${admissions?.college_id}&course_id=${data}`
			);
			if (res) {
				setExamsData(res?.result);
			}
		};

		fetchExamDetails();
	}, []);

	useEffect(() => {
		const fetchDetails = async () => {
			const res = await axiosInstance.get(
				`${API_URLS.getIndianCollegeAdmissionExamDetails}?exam_id=${examId}&college_id=${admissions?.college_id}`
			);
			if (res) {
				setDetails(res?.result);
			}
		};

		fetchDetails();
	}, [examId]);

	// Set the default selectedChipName when examsData is loaded
	useEffect(() => {
		if (examsData?.exams?.length > 0) {
			const firstChip = examsData.exams[0];
			const firstAgency = firstChip?.counselling_agencies_name
				.split(";")[0]
				.trim();
			setSelectedChipName(firstAgency);
		}
	}, [examsData]);

	const handleChipClick = (id, examId) => {
		setSelectedChip(id);
		setExamId(examId);
	};

	const handleChipCLick1 = (chipName) => {
		console.log(chipName, "chipName");
		setSelectedChipName(chipName);
	};

	const allChips = examsData?.exams
		? [staticChip, ...examsData.exams]
		: [staticChip];

	return (
		<>
			<div className="ml-4 mr-4">
				<h1 className="font-plusJakartaSans font-bold text-[15px]">Exams</h1>
				<div className="flex justify-between items-center text-center">
					<div className="flex gap-3 pt-2">
						{allChips.map((chip) => (
							<p
								key={chip.id}
								onClick={() => handleChipClick(chip.id, chip.exam_id)}
								className={`font-plusJakartaSans rounded-full border pt-1 pb-1 pl-4 pr-4 flex text-[11px] text-center items-center gap-1 font-medium cursor-pointer ${
									selectedChip === chip.id
										? "bg-[#2771E7] text-[#FFFFFF]"
										: "border-[#D1D1D1] text-[#000000]"
								}`}
							>
								{chip?.exam_name}
							</p>
						))}
					</div>
				</div>
				<ExamsTable
					selectedChip={selectedChip}
					staticChip={staticChip.id}
					tableData={
						selectedChip === staticChip.id
							? examsData?.direct_admission?.dates
							: details?.dates_to_remember
					}
				/>
				{selectedChip === staticChip.id ? (
					<div className="pt-5">
						<h6 className="font-plusJakartaSans font-bold text-[12px] flex items-center text-center">
							{selectedChip === staticChip.id ? (
								<img
									className="w-6 h-6"
									src={medias.images.College.Application}
									alt="Application"
								/>
							) : (
								""
							)}
							{selectedChip === staticChip.id ? "Application Process" : ""}
						</h6>
						<div
							dangerouslySetInnerHTML={{
								__html:
									selectedChip === staticChip.id
										? examsData?.direct_admission?.application_process
										: "",
							}}
							className="font-plusJakartaSans font-medium pt-2 text-[10.5px] text-[#000000] text-opacity-70"
						></div>
					</div>
				) : (
					""
				)}
				{selectedChip === staticChip.id ? (
					<div className="pt-5">
						<div className="flex item-center tex-center">
							{selectedChip === staticChip.id ? (
								<img
									className="w-8 h-8"
									src={medias.images.College.Reservation}
									alt="Application"
								/>
							) : (
								""
							)}
							<h6 className="font-plusJakartaSans font-bold mt-[5px] text-[12px] ">
								{selectedChip === staticChip.id ? "Reservation Category" : ""}
							</h6>
						</div>
						<div
							dangerouslySetInnerHTML={{
								__html:
									selectedChip === staticChip.id
										? examsData?.direct_admission?.reservation_category
										: "",
							}}
							className="font-plusJakartaSans font-medium pt-2 text-[10.5px] text-[#000000] text-opacity-70"
						></div>
					</div>
				) : (
					""
				)}
				{selectedChip === staticChip.id ? (
					<div className="pt-5">
						<h6 className="font-plusJakartaSans font-bold text-[12px] flex items-center text-center">
							{selectedChip === staticChip.id ? (
								<img
									className="w-6 h-6"
									src={medias.images.College.Selection}
									alt="Application"
								/>
							) : (
								""
							)}
							{selectedChip === staticChip.id ? "Selection Process" : ""}
						</h6>
						<div
							dangerouslySetInnerHTML={{
								__html:
									selectedChip === staticChip.id
										? examsData?.direct_admission?.selection_process
										: "",
							}}
							className="font-plusJakartaSans font-medium pt-2 text-[10.5px] text-[#000000] text-opacity-70"
						></div>
					</div>
				) : (
					""
				)}
				<div className="pt-5">
					<div className="flex items-center text-center">
						<img
							className="w-7 h-7"
							src={medias.images.College.Eligibility1}
							alt="Application"
						/>
						<h6 className="font-plusJakartaSans font-bold mt-[2px] text-[12px]">
							Eligibility
						</h6>
					</div>
					<div
						dangerouslySetInnerHTML={{
							__html:
								selectedChip === staticChip.id
									? examsData?.direct_admission?.eligibility
									: details?.application?.eligibility,
						}}
						className="font-plusJakartaSans font-medium pt-2 text-[10.5px] text-[#000000] text-opacity-70"
					></div>
				</div>
				<div className="pt-5">
					<div className="flex items-center text-center">
						<img
							className="w-7 h-7"
							src={medias.images.College.Eligibility1}
							alt="Application"
						/>
						<h6 className="font-plusJakartaSans font-bold mt-[2px] text-[12px]">
							Post Selection
						</h6>
					</div>
					<div
						dangerouslySetInnerHTML={{
							__html:
								selectedChip === staticChip.id
									? examsData?.direct_admission?.eligibility
									: "",
						}}
						className="font-plusJakartaSans font-medium pt-2 text-[10.5px] text-[#000000] text-opacity-70"
					></div>
				</div>
			</div>
			{selectedChip !== staticChip.id && <Application details={details} />}
			{selectedChip !== staticChip.id && (
				<div className="flex gap-3 items-center text-center mt-2 ml-4 mr-5">
					{examsData?.exams?.map((chip) => {
						const counsellingAgencies =
							chip?.counselling_agencies_name.split(";");
						return counsellingAgencies.map((agency, index) => (
							<div
								className="flex pt-2"
								onClick={() => handleChipCLick1(agency.trim())}
							>
								<p
									key={index}
									className={`font-plusJakartaSans rounded-full border pt-1 pb-1 pl-4 pr-4 flex text-[11px] text-center items-center gap-1 font-medium cursor-pointer ${
										selectedChipName === agency.trim()
											? "bg-[#2771E7] text-[#FFFFFF]"
											: "border-[#D1D1D1] text-[#000000]"
									}`}
								>
									{agency.trim()}
								</p>
							</div>
						));
					})}
				</div>
			)}
			{selectedChip !== staticChip.id && (
				<CouncellingAgencies
					details={details}
					selectedChipName={selectedChipName}
				/>
			)}
		</>
	);
}

export default ExamContent;
