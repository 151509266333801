import * as React from "react";
import moment from "moment";
import Badge from "@mui/material/Badge";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { DayCalendarSkeleton } from "@mui/x-date-pickers/DayCalendarSkeleton";

function ServerDay(props) {
  const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

  const isSelected =
    !props.outsideCurrentMonth &&
    highlightedDays.indexOf(props.day.date()) >= 0;

  return (
    <Badge
      className={isSelected ? "bg-[#d6e0ff] rounded-full " : ""}
      key={props.day.toString()}
      overlap="circular"
      badgeContent={isSelected ? "" : undefined}
    >
      <PickersDay
        {...other}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
      />
    </Badge>
  );
}

export default function CalendarSession({
  setHighlightedDays,
  highlightedDays,
  setCurrentMonth,
  setCurrentDate,
  setCurrentyear,
  currentDate,
}) {
  const requestAbortController = React.useRef(null);
  const [isLoading, setIsLoading] = React.useState(false);

  console.log(highlightedDays);

  const handleMonthChange = (date) => {
    console.log("date year", date.format("yyyy"));
    if (requestAbortController.current) {
      requestAbortController.current.abort();
    }
    setCurrentMonth(date.format("M"));
    setCurrentyear(date.format("yyyy"));
  };

  const handleDateClick = (event, clickedDate) => {
    console.log("Clicked Date:", clickedDate.format("YYYY-MM-DD"));
    setCurrentDate(clickedDate);
    console.log("Event Details:", {
      clientX: event.clientX,
      clientY: event.clientY,
      button: event.button,
      altKey: event.altKey,
      ctrlKey: event.ctrlKey,
    });
  };
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DateCalendar
        defaultValue={currentDate}
        loading={isLoading}
        onMonthChange={handleMonthChange}
        renderLoading={() => <DayCalendarSkeleton />}
        slots={{
          day: (props) => (
            <ServerDay
              {...props}
              onClick={(event) => handleDateClick(event, props.day)}
            />
          ),
        }}
        slotProps={{
          day: {
            highlightedDays,
          },
        }}
      />
    </LocalizationProvider>
  );
}
