/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import Image from "../Image";
import { medias } from "../../config/Resources";
import { Avatar } from "@mui/material";
import Text from "../Text";
import {
  //   ArrowDropDown,
  Chat,
  Notifications,
  Verified,
} from "@mui/icons-material";
import ExitToAppIcon from "@mui/icons-material/Logout";
import { HomeLayout } from "../../config/pageContent";
import classNames from "classnames";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { Controls, Player } from "@lottiefiles/react-lottie-player";
import { useUserInfoStore } from "../../store/userinfo";
import WelcomeChat from "../../animations/WelcomeChat.json";
import badge from "../../animations/badge.json";
import { mediaResources } from "../../config/Constants";
import ProfileMenu from "./ProfileMenu";
// import Text from "../Text";
import { ArrowDropDown } from "@mui/icons-material";

const Homelayout = ({ children }: any) => {
  const { userInfo }: any = useUserInfoStore((state) => state);
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div className="fixed flex w-screen h-screen overflow-auto bg-contain profileBackGround ">
      <div
        id="SideSection"
        className=" h-screen overflow-auto no-scrollbar min-w-[202px] w-[17%] flex justify-start items-center flex-col "
      >
        <div className="sticky top-0 z-[100] ">
          <Image
            src={medias.images.profilemainLogo}
            className="px-[45px]  top-0 desktop:pt-[60px] pt-[60px]"
            alt="PageLogo"
          />
          <div className="flex items-center gap-x-[10px]  py-[20px]   w-full  top-0  justify-center">
            <Avatar
              src={`${mediaResources}/Profile/DefaultAvatar.svg`}
              className="w-[60px] desktop:w-[40px] desktop:h-[40px] h-[60px]"
            />
            <div id="profile" className="sticky top-0">
              <Text className="text-[16px] desktop:text-[12px] font-[PlusJakartaSans-SemiBold] font-bold">
                {userInfo?.firstName} {userInfo?.middleName}{" "}
                {userInfo?.lastName} <Verified className="text-[#2771E7]" />
              </Text>
              <Text className="text-[13px] desktop:text-[10px]  font-medium ">
                Class {userInfo?.class}
              </Text>
            </div>
          </div>
          <Text className="w-full px-[10%] !font-[PlusJakartaSans-Bold] text-[13px] desktop:text-[10px]  pb-[20px]  font-semibold ">
            Profile Completed{" "}
            <span className="px-2 ml-2 py-1 rounded-md text-white bg-[#2DB706] ">
              {" "}
              50%{" "}
            </span>
          </Text>
        </div>
        <div className="w-full ">
          {HomeLayout.map((items: any, index: number) => {
            return (
              <div
                onClick={() => navigate(items?.path)}
                className="relative px-[15px] cursor-pointer "
              >
                <div
                  className={classNames(
                    location?.pathname.includes(items?.path?.split("?")[0]) ||
                      ((location?.pathname.includes("milestone-details") ||
                        location?.pathname.includes("view-report")) &&
                        index === 2)
                      ? " bg-[#2771E7] before:bg-[#2771E7] text-white "
                      : "text-black",
                    "font-bold flex px-[16px] gap-[10px]  items-center 3xl:py-[12px] py-[14px]  before:absolute before:h-full before:rounded-r-[16px] before  before:w-[7.8px] before:left-0  rounded-[16px] text-[18px]"
                  )}
                >
                  <Image
                    src={items?.Icons}
                    className="desktop:w-[19px]"
                    alt={`${items?.Icons}`}
                  />
                  <Text className="text-[15px] desktop:text-[12px] !font-[PlusJakartaSans-Bold]">
                    {items?.title}
                  </Text>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="w-[90%] py-[33px] overflow-auto no-scrollbar px-[28px] rounded-[41px] bg-white my-[15px] mr-[15px] ">
        <div className="flex items-center  gap-x-[10px] justify-between">
          <div
            id="introHeader "
            className="rounded-[20px] w-[73%] desktop:!px-[10px] desktop:!pl-[15px]  shadow-[0px_5px_16px_rgba(0,0,0,0.06)] 3xl:px-[18px] px-[25px] flex items-center"
          >
            <Player
              autoplay
              speed={1.5}
              loop
              src={WelcomeChat}
              style={{ height: "80px", padding: 0, width: "80px" }}
            >
              <Controls
                visible={false}
                buttons={[
                  "play",
                  "repeat",
                  "frame",
                  "debug",
                  "snapshot",
                  "background",
                ]}
              />
            </Player>
            <Text className="text-[26px] desktop:!text-[17px] 3xl:text-[26px] font-quitery !capitalize">
              Welcome, {userInfo?.firstName} {userInfo?.middleName}{" "}
              {userInfo?.lastName}
            </Text>
            <div className="flex item-center text-center bg-[#FFFABE] py-[12px] pr-[15px] desktop:py-[7px] desktop:pl-[30px] pl-[67px]  desktop:!text-[17px] text-[22px] font-quitery ml-auto rounded-[16px] w-fit">
              <Player
                autoplay
                speed={1.5}
                loop
                src={badge}
                style={{ height: "80px", padding: 0, width: "80px" }}
              >
                <Controls
                  visible={false}
                  buttons={[
                    "play",
                    "repeat",
                    "frame",
                    "debug",
                    "snapshot",
                    "background",
                  ]}
                />
              </Player>
              <Text className="py-[12px] pr-[15px] desktop:py-[7px] desktop:pl-[30px] pl-[67px]  desktop:!text-[17px] text-[22px] font-quitery ml-auto rounded-[16px] w-fit">
                5000 UNICoins
              </Text>
            </div>
          </div>
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="26.235"
              height="26.248"
              viewBox="0 0 26.235 26.248"
            >
              <path
                d="M22.518,3.845A13.118,13.118,0,0,0,1.5,18.977L.153,24.92A1.078,1.078,0,0,0,1.442,26.21l5.943-1.351A13.118,13.118,0,0,0,22.518,3.845ZM15.979,16.386H7.771a1.077,1.077,0,1,1,0-2.155h8.209a1.077,1.077,0,1,1,0,2.155Zm2.736-4.378H7.771a1.077,1.077,0,0,1,0-2.155H18.716a1.077,1.077,0,0,1,0,2.155Z"
                transform="translate(-0.125 0)"
                fill="#252424"
              />
            </svg>
            {/* <Chat className="!text-[27px] 3xl:text-[32px]  desktop:!text-[22px] cursor-pointer" /> */}
          </div>
          <div>
            {/* <Notifications className="!text-[27px] 3xl:text-[32px] desktop:!text-[22px] cursor-pointer" /> */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="26.861"
              height="28.127"
              viewBox="0 0 29.861 34.127"
            >
              <path
                d="M28.069,24.013a1.058,1.058,0,0,0-.163-.222L27,22.839a7.965,7.965,0,0,1-1.517-2.274,7.986,7.986,0,0,0,1.4,2.153Zm-26.276,0,1.187-1.295a8,8,0,0,0,1.438-2.232,7.98,7.98,0,0,1-1.551,2.353l-.91.951-1.182-1.2.91-.951a7.124,7.124,0,0,0,1.829-5.134C3.369,11.042,4.384,0,14.93,0S26.492,11.042,26.348,16.509a7.124,7.124,0,0,0,1.829,5.134l.91.951a2.808,2.808,0,0,1,.773,1.941,2.729,2.729,0,0,1-2.69,2.767H2.69A2.729,2.729,0,0,1,0,24.535a2.808,2.808,0,0,1,.773-1.941l1.182,1.2A1.058,1.058,0,0,0,1.792,24.013Zm3.639-1.83a11.067,11.067,0,0,0,1.4-5.766A21.184,21.184,0,0,1,8.222,7.641a7.25,7.25,0,0,1,2.341-3.023A7.27,7.27,0,0,1,14.93,3.413,7.27,7.27,0,0,1,19.3,4.618a7.25,7.25,0,0,1,2.341,3.023,21.184,21.184,0,0,1,1.393,8.775,11.067,11.067,0,0,0,1.4,5.766,9.544,9.544,0,0,0,1.245,1.706H4.186A9.544,9.544,0,0,0,5.431,22.182ZM11.613,32.42a1.683,1.683,0,0,1,1.659-1.706h3.318a1.707,1.707,0,0,1,0,3.413H13.272A1.683,1.683,0,0,1,11.613,32.42Z"
                fill="#252424"
              />
            </svg>
          </div>
          <div className="flex items-center cursor-pointer">
            <Avatar
              src={`${mediaResources}/Profile/DefaultAvatar.svg`}
              className="!rounded-lg desktop:w-[30px] desktop:h-[30px]"
            />
            {/* <div className="mr-[10px] ml-[10px]">
              <Text className="font-bold desktop:!text-[12px] 3xl:text-[14px] font-[PlusJakartaSans-Bold]">
                {userInfo?.firstName} {userInfo?.lastName}
              </Text>
              <Text className="text-[14px] 3xl:text-[10px] desktop:!text-[10px]">
                Student
              </Text>
            </div> */}

            <ProfileMenu
              isDashboard={true}
              userInfo={userInfo}
              children={
                <>
                  <div className="mr-[10px] ml-[10px] !text-black !capitalize">
                    <Text className="font-bold  desktop:!text-[12px] 3xl:text-[14px] font-[PlusJakartaSans-Bold]">
                      {userInfo?.firstName}
                    </Text>
                    <Text className="text-[14px] 3xl:text-[10px] desktop:!text-[10px]">
                      (Student)
                    </Text>
                  </div>
                  <ArrowDropDown className=" !text-black" />
                </>
              }
            />
            {/* <ArrowDropDown /> */}
            {/* <ExitToAppIcon
              className="cursor-pointer"
              titleAccess="Logout"
              onClick={handleLogout}
            /> */}
          </div>
        </div>
        {children}
      </div>
    </div>
  );
};

export default Homelayout;
