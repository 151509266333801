import React, { useState } from "react";

const InternshipsChips = ({ setActiveTab }) => {
	const [active, setActive] = useState(1);
	const chipLabels = [
		{ label: "Internships", id: 1 },
		{ label: "Training", id: 2 },
		{ label: "Courses", id: 3 },
	];

	const handleTabClick = (id) => {
		setActive(id);
        setActiveTab(id)
	};

	return (
		<div className="flex flex-col ml-5 mt-5">
			<div className="flex">
				{chipLabels?.map((chip) => (
					<div
						key={chip.label}
						className={`rounded-full pt-2 pb-2 pl-5 pr-5 cursor-pointer font-bold text-sm mr-2 ${
							active === chip.id
								? "bg-[#2771E7] text-white"
								: "text-[#02020278]"
						}`}
						style={{ border: `1px solid #909090` }} // Apply border style here
						onClick={() => handleTabClick(chip.id)}
					>
						<span className="font-plusJakartaSans">{chip.label}</span>
					</div>
				))}
			</div>
		</div>
	);
};

export default InternshipsChips;
