/* eslint-disable jsx-a11y/anchor-is-valid */
const Notes = () => {
    return (
        <div className="max-w-screen-xl w-full flex gap-10 mb-8 ml-5 overflow-x-auto scrollbar-hide">
            <div className="flex gap-10">
                {[...Array(5)].map((_, index) => (
                    <div key={index} className="bg-white rounded-lg shadow-lg flex-shrink-0 max-w-xs">
                        <div className="p-4 pt-2">
                            <div className="mb-2">
                                <p className="text-sm font-bold flex items-center font-[Poppins] bg-[#FFC4E9] p-1 rounded-xl mt-5 pl-2 pr-2">
                                    5th January
                                </p>
                                <a
                                    href="#"
                                    className="text-gray-900 font-bold mb-1 mt-2 font-plusJakartaSans hover:text-indigo-600 inline-block text-sm"
                                >
                                    Can coffee make you a better developer?
                                </a>
                                <p className="text-gray-700 text-xs font-plusJakartaSans">
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                                    Voluptatibus quia, nulla! Maiores et perferendis eaque,
                                    exercitationem praesentium nihil.
                                </p>
                            </div>
                            <div className="flex items-center">
                                <a href="#">
                                    <img
                                        className="w-10 h-10 rounded-full mr-4"
                                        src="https://tailwindcss.com/img/jonathan.jpg"
                                        alt="Avatar of Jonathan Reinink"
                                    />
                                </a>
                                <div className="text-sm">
                                    <a
                                        href="#"
                                        className="text-gray-900 font-bold font-plusJakartaSans leading-none hover:text-indigo-600"
                                    >
                                        Jonathan Reinink
                                    </a>
                                    <p className="text-gray-600 font-plusJakartaSans text-[10px]">Aug 18</p>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Notes;
