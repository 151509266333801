/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/img-redundant-alt */
import {
	CompareRounded,
	EastRounded,
	LocationOnRounded,
	PushPin,
	StarRounded,
	Verified,
} from "@mui/icons-material";
import { medias } from "../../../config/Resources";
import { RiGovernmentLine } from "react-icons/ri";
import { Button } from "@mui/material";
import { MdOutlineShare } from "react-icons/md";
import { FaRegHeart } from "react-icons/fa6";
import { Controls, Player } from "@lottiefiles/react-lottie-player";
import NewScene from "../../../animations/newScene (1).json";
import { IoChatbubbleEllipsesSharp } from "react-icons/io5";
import { FaHeart } from "react-icons/fa";
import { useState } from "react";

function ColegeDetailsHeadPage({ CollegeDetails }) {
	const [liked, setLiked] = useState(false);
	const handleLikeClick = () => {
		setLiked(!liked);
	};

	const getFirstFiveWords = (text) => {
		return (
			text?.split(" ").slice(0, 7).join(" ") +
			(text?.split(" ").length > 10 ? "..." : "")
		);
	};

	return (
		<div className="relative">
			<img
				className="w-full h-[580px] rounded-3xl"
				src={CollegeDetails?.image}
				alt="image"
			/>
			<div className="absolute top-[4%] left-[87%] w-full h-full">
				<button
					onClick={handleLikeClick}
					className="font-plusJakartaSans gap-[8px] flex item-center text-center text-black text-[15px] bg-white pt-[7px] pb-[7px] pl-[17px] pr-[17px] rounded-full font-bold"
				>
					<span className="pt-[]">
						{liked ? (
							<FaHeart className="text-red-500 w-5 h-5" />
						) : (
							<FaRegHeart className="w-5 h-5" />
						)}
					</span>
					<span className=" font-bold font-plusJakartaSans">Add to list</span>
				</button>
			</div>
			<div className="absolute left-[86.5%] top-[54%] w-full h-full">
				<button className="font-plusJakartaSans flex item-center text-center text-black text-[13px] bg-[#FFF6C8] pt-1 pb-1 pl-4 pr-4 rounded-full font-semibold">
					<span></span>
					<span className="pl-[2px] font-semibold font-plusJakartaSans">
						Claim this college
					</span>
				</button>
			</div>
			<img
				src={medias.images.Profile.logo}
				alt="College Logo"
				className="absolute top-[65%] left-[7%] transform -translate-x-1/2 -translate-y-1/2 w-[130px] h-[125px] z-10"
			/>

			<div className="absolute w-[100%] bottom-0">
				<div className=" bg-white rounded-[20px]  rounded-tr-[20px] overflow-hidden shadow-md">
					<div className="flex justify-end mt-2 mr-4">
						<Button
							variant="outlined"
							className="border ml-20 mt-2 font-medium font-plusJakartaSans py-[12px] !h-full 3xl:text-[14px] 3xl:h-fit flex gap-[6.2px] text-[14px] 3xl:px-[20px] capitalize !text-black px-[10px] border-[#707070] rounded-[9px]"
						>
							<MdOutlineShare className="!text-black h-5 w-5 3xl:text-[14px]" />
							Share Now
						</Button>
						<Button
							variant="outlined"
							className="border mt-2 font-plusJakartaSans ml-4 py-[12px] !h-full flex gap-[6.2px] text-[14px] font-medium 3xl:py-[12px] 3xl:text-[14px] 3xl:h-fit 3xl:px-[20px] capitalize !text-[#2771E7] px-[28px] border-[#2771E7] rounded-[9px]"
						>
							<IoChatbubbleEllipsesSharp className="w-5 h-5 text-[#2771E7]" />
							Chat Now
						</Button>
						<Button
							variant="outlined"
							className="border mt-2 font-plusJakartaSans ml-4 py-[12px] !h-full flex gap-[6.2px] text-[14px] font-medium 3xl:py-[12px] 3xl:text-[14px] 3xl:h-fit 3xl:px-[20px] capitalize !text-white px-[20px] border-[#F37400] !bg-[#F37400] rounded-[9px]"
						>
							Apply Now
							<EastRounded className="3xl:text-[14px] w-4 h-4 miniDesk:hidden" />
						</Button>
					</div>
					<div className="flex justify-between ml-8">
						<div>
							<p className="font-plusJakartaSans flex item-center text-center space-x-1 text-[#000000] mt-[8%] text-[24px] font-bold">
								{CollegeDetails?.clg_name}
								<Player
									autoplay
									speed={1.5}
									loop
									src={NewScene}
									style={{ height: "33px", paddingTop: "5px", width: "33px" }}
								>
									<Controls
										visible={false}
										buttons={[
											"play",
											"repeat",
											"frame",
											"debug",
											"snapshot",
											"background",
										]}
									/>
								</Player>
							</p>
						</div>
					</div>
					<div className="flex mt-1 ml-8">
						<p className="text-[14px] text-[#000000] text-opacity-80 miniDesk:text-[14px] font-plusJakartaSans font-medium">
							<LocationOnRounded className="!text-[#FF5A5A] w-5 h-6 stroke-black stroke-[1.5px]" />{" "}
							{CollegeDetails?.clg_address}
						</p>
						<p className="bg-[#F3F3F3] mt-[-2px] p-2 text-[14px] ml-2 miniDesk:text-[14px] w-fit py-[4px] 3xl:text-[12px] items-center pl-[9px] pr-[13px] rounded-[4px] font-semibold font-plusJakartaSans">
							<StarRounded className="!text-[#FFC000] mt-[-3px] w-5 h-5" />
							4.5
						</p>
						<p className="text-[#239403] font-plusJakartaSans font-medium ml-2 miniDesk:text-[14px]  underline text-[14px]">
							525 reviews
						</p>
					</div>
					<div className="flex 3xl:-top-[18px] miniDesk:flex-col mb-10 miniDesk:!gap-[4px] ml-8 mt-3 3xl:gap-[12px] gap-[20px]">
						<p className="font-normal mt-[-3px] font-plusJakartaSans text-white rounded text-center item-center bg-[#04043B] p-1 text-[12px] desktop:!text-[12px] 3xl:text-[12px] items-start flex gap-[6px]">
							<RiGovernmentLine className=" text-white h-4 w-4 3xl:text-[14px]" />
							{CollegeDetails?.college_type_name}
						</p>
						<p className="font-normal font-plusJakartaSans text-[13.5px] miniDesk:text-[14px] items-start flex gap-[6px]">
							<PushPin className="rotate-[45deg] h-5 w-5" />
							Estd.{CollegeDetails?.founded}
						</p>
						<p className="flex items-start text-[13.5px] miniDesk:text-[14px] font-normal font-plusJakartaSans ml-2 gap-[6px]">
							<img
								src={medias.images.HomeMain.DocVerified}
								className="3xl:w-[14px] h-5 w-5"
								alt="DocVerified"
							/>
							Approved by: {getFirstFiveWords(CollegeDetails?.approved_by_name)}
						</p>
						<p className="flex text-[13.5px] miniDesk:text-[14px] font-normal font-plusJakartaSans ml-2 gap-[6px] items-start">
							<img
								src={medias.images.HomeMain.ranking}
								className=" h-5 w-5"
								alt="ranking"
							/>
							NIRF Ranking: 2nd
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ColegeDetailsHeadPage;
