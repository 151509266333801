import Image from "../../Components/Image";
import { medias } from "../../config/Resources";
import { Controls, Player } from "@lottiefiles/react-lottie-player";

import heroBg from "../../animations/mentorhero.json";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <div className="relative h-[112vh] bg-[#01150D]  ">
      {/* Lottie Animation */}
      <Player
        autoplay
        speed={0.6}
        loop
        // style={{ width: "100vw", height: "100vh" }}
        src={heroBg}
        className="absolute !inset-50 !opacity-10   !h-[144vh] !top-[-230px] !bottom-[-400px] !right-[-500px] !left-[-500px] "
      >
        <Controls
          visible={false}
          buttons={[
            "play",
            "repeat",
            "frame",
            "debug",
            "snapshot",
            "background",
          ]}
        />
      </Player>

      {/* Text on the Background */}
      <div className="absolute   text-white ">
        <div className="flex  w-screen">
          <div className="w-1/2 font-plusJakartaSans font-bold pl-[4%] items-center flex flex-col justify-center">
            <div>
              <img
                className="w-12 h-12"
                src={medias.images.HomeMain.circleMentor}
                alt=""
              />
              <h6 className="tab-gradient font-light text-[25px]">
                Find your Perfect
              </h6>
              <h5 className="tab-gradient text-[52px] tracking-wide">
                Mentor, Learn a new skill
              </h5>
              <h5 className="tab-gradient text-[52px]">
                land your Dream career
              </h5>
              <p className="font-normal opacity-40 w-[80%]">
                Indian Institute of Technology, Delhi or IIT Delhi is an
                Institute of Eminence offering about 102 undergraduate,
                postgraduate, integrated and doctoral degree courses. Indian
                Institute of Technology, Delhi courses
              </p>
              <div className="my-5 flex items-center gap-x-4">
                <Link to={"/mentorship/find-the-best-mentor-of-your-choice"}>
                  <a class="relative p-0.5 cursor-pointer  inline-flex items-center justify-center font-bold overflow-hidden group rounded-tl-[40px] rounded-bl-[40px] rounded-tr-[40px]">
                    <span class="w-full h-full bg-gradient-to-br from-[#ffffff] via-[#306c42] to-[#40714f] rounded-tl-[40px] rounded-bl-[40px] rounded-tr-[40px]  absolute"></span>
                    <span class="relative px-4  pt-1 transition-all ease-out bg-[#4dae6a] rounded-tl-[40px] rounded-bl-[40px] rounded-tr-[40px]  duration-400 justify-center text-center items-center flex ">
                      <span class="relative text-white -mt-3 text-[18.5px] font-normal">
                        Get Started
                      </span>
                      <span>
                        <img
                          className="w-[60px]"
                          src={medias.images.HomeMain.getStartedMentor}
                          alt=""
                        />
                      </span>
                    </span>
                  </a>
                </Link>
                <div className="cursor-pointer">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="100.264"
                    height="100.264"
                    viewBox="0 0 127.264 127.264"
                  >
                    <defs>
                      <linearGradient
                        id="linear-gradient"
                        x1="0.5"
                        x2="0.278"
                        y2="1.365"
                        gradientUnits="objectBoundingBox"
                      >
                        <stop offset="0" stop-color="#373d39" />
                        <stop offset="0" stop-color="#413e3e" />
                        <stop offset="0.247" stop-color="#302e2e" />
                        <stop offset="0.573" stop-color="#2a2a2a" />
                        <stop offset="1" stop-color="#6b6b6b" />
                      </linearGradient>
                      <filter
                        id="Ellipse_4148"
                        x="20.633"
                        y="25.633"
                        width="86"
                        height="87"
                        filterUnits="userSpaceOnUse"
                      >
                        <feOffset dy="8" input="SourceAlpha" />
                        <feGaussianBlur stdDeviation="8" result="blur" />
                        <feFlood flood-opacity="0.129" />
                        <feComposite operator="in" in2="blur" />
                        <feComposite in="SourceGraphic" />
                      </filter>
                    </defs>
                    <g
                      id="Group_416927"
                      data-name="Group 416927"
                      transform="translate(-494.367 -2415.367)"
                    >
                      <g
                        id="Ellipse_4147"
                        data-name="Ellipse 4147"
                        transform="matrix(-0.839, 0.545, -0.545, -0.839, 621.632, 2492.525)"
                        stroke="#303030"
                        stroke-width="4"
                        fill="url(#linear-gradient)"
                      >
                        <circle cx="46" cy="46" r="46" stroke="none" />
                        <circle cx="46" cy="46" r="44" fill="none" />
                      </g>
                      <g
                        id="Group_317788"
                        data-name="Group 317788"
                        transform="translate(538.537 2457.375)"
                      >
                        <g
                          transform="matrix(1, 0, 0, 1, -44.17, -42.01)"
                          filter="url(#Ellipse_4148)"
                        >
                          <ellipse
                            id="Ellipse_4148-2"
                            data-name="Ellipse 4148"
                            cx="19"
                            cy="19.5"
                            rx="19"
                            ry="19.5"
                            transform="translate(44.63 41.63)"
                            fill="#ff9e33"
                          />
                        </g>
                        <path
                          id="Polygon_37"
                          data-name="Polygon 37"
                          d="M8.336,2.5a2,2,0,0,1,3.328,0l6.263,9.394A2,2,0,0,1,16.263,15H3.737a2,2,0,0,1-1.664-3.109Z"
                          transform="translate(29.463 9.625) rotate(90)"
                          fill="#fff"
                        />
                      </g>
                    </g>
                  </svg>
                </div>
                <div className="text-white opacity-40 underline underline-offset-2 cursor-pointer hover:opacity-100 ">
                  Watch Video
                </div>
              </div>
            </div>
          </div>
          <div className="w-1/2 items-end flex justify-end ">
            <img
              className="w-[80%] items-end inline-flex pt-2 relative z-50"
              src={medias.images.HomeMain.mainMentor}
              alt=""
            />
          </div>
        </div>
      </div>
      <div className="absolute bottom-20 flex items-center   justify-center w-full gradient-border-1 mentor_explore h-[150px] bg-cover   scale-110 bg-no-repeat  ">
        <div className="relative -translate-y-[50%] desktop:!h-[100px] h-[120px] desktop:!w-[100px] w-[120px] mt-[-60px] ">
          <Image
            src={medias.images.HomeMain.ExploreMore}
            className="border-[1px]  z-50 logoImageCont mt-[100px] bg-[#69DD8C] absolute w-full 3xl:border-[1px] spin  gradient-border-1 rounded-full"
          />
          <Image
            src={medias.images.HomeMain.arrowHome}
            className="relative border z-50 mt-[100px]  border-black rounded-full p-2 desktop:left-[calc(50%-20px)]  left-[calc(50%-30px)] desktop:w-[40px] desktop:h-[40px] h-[60px] desktop:top-[calc(50%-20px)] top-[calc(50%-30px)]  w-[60px]"
          />
        </div>
      </div>
    </div>
  );
};

export default Hero;
