import RoadMapAndDailyCards from "./RoadMapAndDailyPlaneer";
import SliderPercent from "./Slider";
import ProgressTrackerAchieved from "./ProgressTrackerAchieved";
import CouncellorAndMentor from "./CouncellorAndMentor";
import CircularBar from "./CircularProgressBar";
import CalendarContent from "../../Pages/Profile/Mentorship/calenderContent";
import GoalDropdown from "./GoalDropDown";
import TaskCompleted from "./TaskCompleted";

function ProgressTracker() {
	return (
		<div className="w-[100%] flex">
			<div className="w-[70%]">
				<RoadMapAndDailyCards />
				<SliderPercent />
				<ProgressTrackerAchieved />
				<CouncellorAndMentor />
			</div>
			<div className="w-[30%]">
				<div className="flex items-center mb-5 mt-5 ml-5">
					<p className="font-bold font-plusJakartaSan text-sm">
						Milestones Completions
					</p>
					<GoalDropdown />
				</div>
				<CircularBar />
				<TaskCompleted />
			</div>
		</div>
	);
}

export default ProgressTracker;
