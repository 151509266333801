import { StarRounded, Verified } from "@mui/icons-material";
import { useState } from "react";
import { FaAngleUp } from "react-icons/fa";
import { PiStarFourFill } from "react-icons/pi";
import { RiArrowDropDownLine } from "react-icons/ri";
import { medias } from "../../../../config/Resources";

function AllReviews() {
	const [isExpanded, setIsExpanded] = useState(false);

	const toggleReadMore = () => {
		setIsExpanded(!isExpanded);
	};

    const images =[
        {}, {}, {}, {}, {}, {},
    ]

	return (
		<>
			<div className="ml-5 mt-5 flex items-center mb-2">
				<p className="font-plusJakartaSans w-11 h-11 font-bold text-[#AC4E0F] bg-[#F8D0B9] p-2 rounded-xl text-[25px] flex justify-center items-center">
					H
				</p>
				<div className="ml-1">
					<p className="font-plusJakartaSans text-[13px] font-semibold flex mt-[6px] items-center text-center gap-[2px]">
						Hemali Priyadashi
						<Verified className="text-[rgba(39,113,231,1)] ml-1 h-3 w-3" />
						<p className="bg-[#F3F3F3] w-fit items-center flex pl-1 pr-1 pt-[1.5px] pb-[1.5px] text-[9.5px] ml-[1px] gap-1 text-center rounded-[4px] font-semibold font-plusJakartaSans">
							<StarRounded className="!text-[#000000] w-4 h-4" />
							<span className="mt-[1px]">4.8</span>
						</p>
					</p>
					<div className="flex item-center gap-72 text-center">
						<p className="font-plusJakartaSans mt-[2px] text-[#000000] text-opacity-60 text-[10px] font-medium">
							B.tech in Electrical Engineering (Power and Automation)- Batch of
							2024
						</p>
						<div className="flex gap-10 mt-[2px]  text-[#000000] ">
							<p className="font-plusJakartaSans text-[11px] font-semibold">
								Infrastructure: 9/10
							</p>
							<p className="font-plusJakartaSans ml-3 text-[11px] font-semibold">
								Academics: 9/10
							</p>
							<div className="flex item-center text-center ml-3 mt-[-5px] gap-1 text-black">
								<PiStarFourFill className="text-[#FFC000] h-4 w-4" />
								<span className="text-[18px] font-plusJakartaSans font-bold">
									8.7
								</span>
								<span className="text-[10px] mt-[8px] font-plusJakartaSans font-bold">
									/10
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="ml-6 mr-6">
				<div className="relative overflow-hidden">
					<div
						className={`font-plusJakartaSans font-medium text-[11.5px] text-[#000000] text-opacity-60 mt-1 ${
							!isExpanded ? "line-clamp-5" : ""
						}`}
					>
						<p>
							Lorem Ipsum is simply dummy text of the printing and typesetting
							industry. Lorem Ipsum has been the industry's standard dummy text
							ever since the 1500s, when an unknown printer took a galley of
							type and scrambled it to make a type specimen book. It has
							survived not only five centuries, but also the leap into
							electronic typesetting, remaining essentially unchanged. It was
							popularised in the 1960s with the release of Letraset sheets
							containing Lorem Ipsum passages, and more recently with desktop
							publishing software like Aldus PageMaker including versions of
							Lorem Ipsum.Lorem Ipsum is simply dummy text of the printing and
							typesetting industry. Lorem Ipsum has been the industry's standard
							dummy text ever since the 1500s, when an unknown printer took a
							galley of type and scrambled it to make a type specimen book. It
							has survived not only five centuries, but also the leap into
							electronic typesetting, remaining essentially unchanged. It was
							popularised in the 1960s with the release of Letraset sheets
							containing Lorem Ipsum passages, and more recently with desktop
							publishing software like Aldus PageMaker including versions of
							Lorem Ipsum.
						</p>
					</div>
					{!isExpanded && (
						<div className="absolute bottom-0 left-0 w-full h-8 bg-gradient-to-t from-white to-transparent"></div>
					)}
				</div>
				<button
					onClick={toggleReadMore}
					className="text-blue-500 font-plusJakartaSans font-bold text-[12px] flex items-center ml-[90%]"
				>
					{isExpanded ? (
						<>
							Read less
							<FaAngleUp className="w-4 h-4 ml-1" />
						</>
					) : (
						<>
							Read more
							<RiArrowDropDownLine className="w-6 h-6" />
						</>
					)}
				</button>
			</div>
            <div className="flex items-center ml-6 gap-2">
                {
                    images.map(() => {
                        return (
                            <img className="w-12 h-12 rounded-lg" src={medias.images.College.Image10} alt="images" />
                        )
                    })
                }
            </div>
		</>
	);
}

export default AllReviews;
