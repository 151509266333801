import React from "react";
import Tabs from "./Tabs";
import AccordianComp from "./AccordianComp";

const Value = ({
  data = [],
  setActiveTab,
  activeTab,
  setActiveTabName,
  factorData,
  responseData,
}) => {
  return (
    <div>
      <div>
        <Tabs
          data={data}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          setActiveTabName={setActiveTabName}
        />
      </div>
      <AccordianComp array={factorData} />
    </div>
  );
};

export default Value;
