/* eslint-disable no-lone-blocks */
import { medias } from "../../../config/Resources";

function EducationPathWay({details}) {
	const cardsData = [
		{
			title: "Cardio-thoracic Anesthesia",
			text: "Indian Institute of Technology, Delhi or IIT Delhi is an Institute of Eminence offering about 102 undergraduate, postgraduate, integrated and doctoral degree courses. Indian Institute of Technology, Delhi courses include B.Tech, M.Tech, M.Sc and Ph.D in various streams of Engineering, Physical Sciences, Management, Humanities, and Social Sciences. Students seeking admission for B.Tech in any stream must appear for JEE Advanced and JoSaa counselling. Students completing their degrees are offered IIT Delhi placements to prestigious organisations at a salary package ranging from Rs. 26 LPA to Rs. 40 LPA (approximately). Established in 1961, IIT Delhi is one of the 23 IITs created to be Centres of Excellence for training, research and development in science, engineering and technology in India. Being an Institution of National Importance under the Institutes of Technology, the institute has bagged 4th rank in overall category as per NIRF 2022 data. The Indian Institute of Technology Delhi covers an area of about 320 acres equipped with all basic infrastructural facilities. The institute also has world class and experienced faculties to ensure extraordinary learning",
		},
		{
			title: "Cardio-thoracic Anesthesia",
			text: "Indian Institute of Technology, Delhi or IIT Delhi is an Institute of Eminence offering about 102 undergraduate, postgraduate, integrated and doctoral degree courses. Indian Institute of Technology, Delhi courses include B.Tech, M.Tech, M.Sc and Ph.D in various streams of Engineering, Physical Sciences, Management, Humanities, and Social Sciences. Students seeking admission for B.Tech in any stream must appear for JEE Advanced and JoSaa counselling. Students completing their degrees are offered IIT Delhi placements to prestigious organisations at a salary package ranging from Rs. 26 LPA to Rs. 40 LPA (approximately). Established in 1961, IIT Delhi is one of the 23 IITs created to be Centres of Excellence for training, research and development in science, engineering and technology in India. Being an Institution of National Importance under the Institutes of Technology, the institute has bagged 4th rank in overall category as per NIRF 2022 data. The Indian Institute of Technology Delhi covers an area of about 320 acres equipped with all basic infrastructural facilities. The institute also has world class and experienced faculties to ensure extraordinary learning",
		},
		{
			title: "Cardio-thoracic Anesthesia",
			text: "Indian Institute of Technology, Delhi or IIT Delhi is an Institute of Eminence offering about 102 undergraduate, postgraduate, integrated and doctoral degree courses. Indian Institute of Technology, Delhi courses include B.Tech, M.Tech, M.Sc and Ph.D in various streams of Engineering, Physical Sciences, Management, Humanities, and Social Sciences. Students seeking admission for B.Tech in any stream must appear for JEE Advanced and JoSaa counselling. Students completing their degrees are offered IIT Delhi placements to prestigious organisations at a salary package ranging from Rs. 26 LPA to Rs. 40 LPA (approximately). Established in 1961, IIT Delhi is one of the 23 IITs created to be Centres of Excellence for training, research and development in science, engineering and technology in India. Being an Institution of National Importance under the Institutes of Technology, the institute has bagged 4th rank in overall category as per NIRF 2022 data. The Indian Institute of Technology Delhi covers an area of about 320 acres equipped with all basic infrastructural facilities. The institute also has world class and experienced faculties to ensure extraordinary learning",
		},
		{
			title: "Cardio-thoracic Anesthesia",
			text: "Indian Institute of Technology, Delhi or IIT Delhi is an Institute of Eminence offering about 102 undergraduate, postgraduate, integrated and doctoral degree courses. Indian Institute of Technology, Delhi courses include B.Tech, M.Tech, M.Sc and Ph.D in various streams of Engineering, Physical Sciences, Management, Humanities, and Social Sciences. Students seeking admission for B.Tech in any stream must appear for JEE Advanced and JoSaa counselling. Students completing their degrees are offered IIT Delhi placements to prestigious organisations at a salary package ranging from Rs. 26 LPA to Rs. 40 LPA (approximately). Established in 1961, IIT Delhi is one of the 23 IITs created to be Centres of Excellence for training, research and development in science, engineering and technology in India. Being an Institution of National Importance under the Institutes of Technology, the institute has bagged 4th rank in overall category as per NIRF 2022 data. The Indian Institute of Technology Delhi covers an area of about 320 acres equipped with all basic infrastructural facilities. The institute also has world class and experienced faculties to ensure extraordinary learning",
		},
	];
	return (
		<div className="w-auto rounded-[20px] ml-auto mr-auto overflow-hidden p-6 shadow-lg border border-gray-300 mt-10">
		{
			details?.education_pathway?.map((item, index) => {
				return(
		<div key={index}>
			<div className="flex items-center text-center">
				<img
					className="h-8 w-8"
					src={medias.images.Profile.decision}
					alt="cap"
				/>
				<h6 className="font-plusJakartaSans font-bold text-lg ml-1">
					{item.pathway_title
}
				</h6>
			</div>
			<div className="mt-2">
				<p className="font-plusJakartaSans font-medium text-[12px] text-[#000000] opacity-70" dangerouslySetInnerHTML={{__html: item.summary}}>
				</p>
                <img className="w-[45%] mt-2" src={medias.images.Profile.education} alt="Education pathway"/>
			</div>
			</div>
		)
			})
		}
		</div>
	);
}

export default EducationPathWay;

{/* <div className="flex items-center mt-2 text-center">
	<img
		className="h-8 w-8"
		src={medias.images.Profile.decision}
		alt="cap"
	/>
	<h6 className="font-plusJakartaSans font-bold text-lg ml-1">
		Education Pathway 2
	</h6>
</div>
<div className="mt-2">
	<p className="font-plusJakartaSans font-medium text-sm">
		Indian Institute of Technology, Delhi or IIT Delhi is an Institute of
		Eminence offering about 102 undergraduate, postgraduate, integrated
		and doctoral degree courses. Indian Institute of Technology, Delhi
		courses include B.Tech, M.Tech, M.Sc and Ph.D in various streams of
		Engineering, Physical Sciences, Management, Humanities, and Social
		Sciences. Students seeking admission for B.Tech in any stream must
		appear for JEE Advanced and JoSaa counselling. Students completing
		their degrees are offered IIT Delhi placements to prestigious
		organisations at a salary package ranging from Rs. 26 LPA to Rs. 40
		LPA (approximately). Established in 1961, IIT Delhi is one of the 23
		IITs created to be Centres of Excellence for training, research and
		development in science, engineering and technology in India. Being an
		Institution of National Importance under the 'Institutes of
		Technology’, the institute has bagged 4th rank in overall category as
		per NIRF 2022 data. The Indian Institute of Technology Delhi covers an
		area of about 320 acres equipped with all basic infrastructural
		facilities. The institute also has world class and experienced
		faculties to ensure extraordinary learning.
	</p>
	<img className="w-[45%] mt-2" src={medias.images.Profile.education} alt="Education pathway"/>
</div> */}