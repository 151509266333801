import React from "react";
import Tabs from "./Tabs";
import AccordianComp from "./AccordianComp";

const Social = ({
  data = [],
  setActiveTab,
  activeTab,
  factorData,
  responseData,
  isSocial,
  setActiveTabName,
}) => {
  return (
    <div className="mt-10 py-2 rounded-xl !border-gray-400  !border-solid  border-[2px] ">
      {" "}
      <div>
        <Tabs
          data={data}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          isSocial={isSocial}
          setActiveTabName={setActiveTabName}
        />
      </div>
      <AccordianComp array={factorData} />
    </div>
  );
};

export default Social;
