import React from "react";
import Image from "../../Components/Image";
import Text from "../../Components/Text";
import {
  graduatePageContant,
  guidancePageContant,
} from "../../config/pageContent";
import { medias } from "../../config/Resources";
import Planing from "../../Components/Graduate/Planing";
import Plans from "../../Components/Graduate/Plans";
import Face1 from "../../Components/Faces/Face1";
import Face2 from "../../Components/Faces/Face2";
import Face7 from "../../Components/Faces/Face7";
import Face8 from "../../Components/Faces/Face8";
import Face9 from "../../Components/Faces/Face9";
import Face10 from "../../Components/Faces/Face10";
import Face11 from "../../Components/Faces/Face11";
import Face12 from "../../Components/Faces/Face12";
import Face13 from "../../Components/Faces/Face13";

// const Face1 = React.lazy(() => import("../../Components/Faces/Face1"));
// const Face2 = React.lazy(() => import("../../Components/Faces/Face2"));

// const Face7 = React.lazy(() => import("../../Components/Faces/Face7"));
// const Face8 = React.lazy(() => import("../../Components/Faces/Face8"));
// const Face9 = React.lazy(() => import("../../Components/Faces/Face9"));
// const Face10 = React.lazy(() => import("../../Components/Faces/Face10"));
// const Face11 = React.lazy(() => import("../../Components/Faces/Face11"));
// const Face12 = React.lazy(() => import("../../Components/Faces/Face12"));
// const Face13 = React.lazy(() => import("../../Components/Faces/Face13"));

const Graduate = () => {
  return (
    <div>
      <Face1
        isDemo
        btnClass=" !bg-[#E023FF] !shadow-[0px_10px_0px_0px_rgba(94,7,108,1)]"
        Title={
          <>
            <div className="relative">
              <Image
                className="absolute -top-10 -left-12"
                src={medias.images.guidance.Face1TitleTopIcon}
              ></Image>
              <Text className="text-[47px] font-extrabold  PlusJakartaSans-ExtraBoldCustom ">
                {graduatePageContant.Face1.confusedAboutYourCareer}
              </Text>
            </div>
          </>
        }
        mainImage={medias.images.graduate.Face1Icon}
        description={graduatePageContant.Face1.indianInstituteOfTechnology}
        mainImageClass={" !w-[40%] -mt-20 ml-12 "}
        mainContainerClass={` !bg-[#D5A4DE] !bg-opacity-15 `}
        descriptionClass={" w-[85%]"}
      />

      <Face2
        page="graduate"
        cardContainerClass="!grid-rows-1 pb-[73px] !h-full"
        bottomClasses="!pt-[24px] !pb-[35px]"
        cardContainerImageClass={`!h-[274px] pt-7 px-7`}
        backSideClass={`!h-full !w-full !p-[30px]`}
        containerCardClasses={`!h-[370px] !w-[300px]`}
        cardBackClass={`!p-0`}
      />

      <Planing />
      {/* <Plans /> */}
      <Face7
        isTab={false}
        plans={graduatePageContant.Face5.plans}
        bodyContainer="pt-16
        "
      />
      <Face8 />
      <Face9 />
      <Face10 />
      <Face11 />
      <Face12 />
      <Face13 />
    </div>
  );
};

export default Graduate;
