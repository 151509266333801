import React from "react";
import { useState } from "react";
import AgoraUIKit from "agora-react-uikit";

const rtcProps = {
  appId: "e011fb2b67bf4fe69c5b9eb42fb93a8d",
  channel: "Channel1",
  token:
    "007eJxTYDjM4qvI8mYdW5k3L6/Zw00Kv/R5HVPub3g7g7VDa7fkMX8FhlQDQ8O0JKMkM/OkNJO0VDPLZNMky9QkE6O0JEvjRIuUrETjtIZARgbVugfMjAwQCOJzMDhnJOblpeYYMjAAAACaHng=",
};

const AgoraWebRtc = () => {
  const [videoCall, setVideoCall] = useState(true);
  const callbacks = {
    EndCall: () => setVideoCall(false),
  };

  return videoCall ? (
    <div style={{ display: "flex", width: "100vw", height: "100vh" }}>
      <AgoraUIKit rtcProps={rtcProps} callbacks={callbacks} />
    </div>
  ) : (
    <h3 onClick={() => setVideoCall(true)}>Join</h3>
  );
};

export default AgoraWebRtc;

// import React from "react";
// import { useState } from "react";
// import AgoraUIKit from "agora-react-uikit";

// const rtcProps = {
//   appId: "e011fb2b67bf4fe69c5b9eb42fb93a8d",
//   channel: "Channel1",
//   token:
//     "007eJxTYDjM4qvI8mYdW5k3L6/Zw00Kv/R5HVPub3g7g7VDa7fkMX8FhlQDQ8O0JKMkM/OkNJO0VDPLZNMky9QkE6O0JEvjRIuUrETjtIZARgbVugfMjAwQCOJzMDhnJOblpeYYMjAAAACaHng=",
// };

// const AgoraWebRtc = () => {
//   const [videoCall, setVideoCall] = useState(true);
//   const callbacks = {
//     EndCall: () => setVideoCall(false),
//   };

//   return videoCall ? (
//     <div style={{ display: "flex", width: "100vw", height: "100vh" }}>
//       <AgoraUIKit rtcProps={rtcProps} callbacks={callbacks} />
//     </div>
//   ) : (
//     <h3 onClick={() => setVideoCall(true)}>Join</h3>
//   );
// };

// export default AgoraWebRtc;
