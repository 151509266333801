import React from "react";
import Image from "../../Image";
import { East, PlayCircleFilledRounded } from "@mui/icons-material";
import { Button } from "@mui/material";
import Text from "../../Text";
import classNames from "classnames";
import { HiChevronDown, HiChevronLeft, HiChevronRight } from "react-icons/hi";

const Face1Eleven = ({
  Title,
  mainImageClass,
  isDemo,
  description,
  mainContainerClass,
  btnClass,
  mainImage,
  descriptionClass,
}: {
  Title?: string | JSX.Element | JSX.Element[];
  description?: string | JSX.Element | JSX.Element[];
  btnClass?: string;
  isDemo?: boolean;
  mainContainerClass?: string;
  mainImage?: string;
  mainImageClass?: string;
  descriptionClass?: string;
}) => {
  return (
    <>
      <div
        className={`${
          mainContainerClass || ""
        } flex Face1background pt-[105.58px] gap-x-[21.79px] pl-[118px] pr-[85px] bg-[#F0BC5E] bg-opacity-10 `}
      >
        <div className="w-1/2  ">
          {Title}
          <div className="my-[20px]  opacity-[80%]">
            <Text
              className={classNames(
                descriptionClass,
                " text-[16px] opacity-80 font-normal"
              )}
            >
              {description}
            </Text>
          </div>
          <div className="flex gap-[15px] items-center">
            <Button
              variant="contained"
              className={classNames(
                btnClass,
                " bg-[#239405] hover:bg-[#239405] !gap-3 !w-[230.1px] !capitalize !font-light !text-[18px] !h-[60.77px] !rounded-[11px] shadow-[0px_10px_0px_0px_rgba(18,87,0,1)] "
              )}
            >
              Get Started
              <East />
            </Button>
            {/* 
          <a
            href="#_"
            className="  px-5 py-2.5 overflow-hidden !gap-3 !w-[230.1px] !capitalize !font-light !text-[18px] !h-[60.77px] !rounded-[11px] group bg-[#239405]  relative hover:bg-gradient-to-r hover:from-[#239405] hover:to-[#239405] text-white  hover:ring-[#239405] transition-all ease-out duration-300 shadow-[0px_10px_0px_0px_rgba(18,87,0,1)]"
          >
            <span className="absolute right-0 w-8 h-32 -mt-10 transition-all duration-1000 transform translate-x-12 bg-white opacity-30 rotate-12 group-hover:-translate-x-40 ease"></span>
            <span className="relative">
              Button Text <East />
            </span>
          </a> */}
            {isDemo && (
              <div className="flex gap-[13px] items-center">
                <PlayCircleFilledRounded className="!fill-[#626160] !text-[60px]" />
                <Text className="text-[#000000] underline  opacity-[64%] text-[24px]">
                  Watch Demo
                </Text>
              </div>
            )}
          </div>
        </div>
        <div
          className={classNames(
            mainImageClass,
            "w-1/2 flex justify-end items-end"
          )}
        >
          <div className="w-full flex flex-col justify-center items-center mt-5">
            <Image className="w-[50%] -mb-[71px]" src={mainImage} />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="504"
              height="100"
              viewBox="0 0 834 170"
            >
              <g
                id="Group_417239"
                data-name="Group 417239"
                transform="translate(-995 -910)"
              >
                <path
                  id="Rectangle_17129"
                  data-name="Rectangle 17129"
                  d="M32,0H802a32,32,0,0,1,32,32v97a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V32A32,32,0,0,1,32,0Z"
                  transform="translate(995 951)"
                  fill="#a5c8fd"
                />
                <ellipse
                  id="Ellipse_4032"
                  data-name="Ellipse 4032"
                  cx="409.5"
                  cy="59.5"
                  rx="409.5"
                  ry="59.5"
                  transform="translate(1003 910)"
                  fill="#c5dcff"
                />
              </g>
            </svg>
          </div>
        </div>
      </div>
      <div className="bg-white w-64 h-36 absolute shadow-lg left-[47.5%] bottom-10 z-50 p-3 rounded-lg">
        <div className="flex flex-col h-full justify-between ">
          <div className="flex gap-x-4 items-center ">
            <span className=" w-12 h-12 shadow-sm flex justify-center items-center border-[0.5px] text-center rounded-full border-solid border-gray-300">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32.341"
                height="30.799"
                viewBox="0 0 47.341 40.799"
              >
                <g id="engineer" transform="translate(-0.958 -3.037)">
                  <path
                    id="Path_177610"
                    data-name="Path 177610"
                    d="M19.368,14.012c-.043.252,0,18.111-.014,18.346H3.075A18.5,18.5,0,0,1,19.369,14.012Z"
                    transform="translate(1.214 6.296)"
                    fill="#ffd149"
                  />
                  <path
                    id="Path_177611"
                    data-name="Path 177611"
                    d="M16.218,32.354V14.168a.433.433,0,0,0-.019-.157A18.551,18.551,0,0,1,32.505,32.354Z"
                    transform="translate(8.743 6.296)"
                    fill="#ffd149"
                  />
                  <path
                    id="Path_177612"
                    data-name="Path 177612"
                    d="M10.05,15.491v7.6a1.489,1.489,0,1,1-2.978,0V17.631a17.485,17.485,0,0,1,2.978-2.14Z"
                    transform="translate(3.507 7.145)"
                    fill="#ffa845"
                  />
                  <path
                    id="Path_177613"
                    data-name="Path 177613"
                    d="M43.5,27.868a2.2,2.2,0,0,1-2.2,2.2H3.656a2.2,2.2,0,1,1,0-4.4H41.3a2.2,2.2,0,0,1,2.2,2.2Z"
                    transform="translate(0.287 12.984)"
                    fill="#ffa845"
                  />
                  <path
                    id="Path_177614"
                    data-name="Path 177614"
                    d="M8.848,25.635a2.213,2.213,0,0,1-2.276-2.241V17.916c.305-1.153,2.421-2.154,3.377-2.824a.793.793,0,0,1,1.175.685v7.6a2.216,2.216,0,0,1-2.276,2.255Zm-.7-7.361v5.107a.7.7,0,1,0,1.4-.013V17.2a16.988,16.988,0,0,0-1.4,1.077Z"
                    transform="translate(3.22 6.859)"
                  />
                  <path
                    id="Path_177615"
                    data-name="Path 177615"
                    d="M37.58,20.294l3.152,2.369L37.8,27.753l-3.641-1.547a11.119,11.119,0,0,1-2.839,1.86A18.482,18.482,0,0,0,18.161,20.02,2.182,2.182,0,0,0,15.675,18a11.15,11.15,0,0,1,.235-2.33l-3.132-2.369,2.936-5.09,3.641,1.547a10.941,10.941,0,0,1,3.974-2.31l.489-3.915h5.873l.47,3.915a10.861,10.861,0,0,1,3.993,2.31L37.8,8.216l2.936,5.09-3.152,2.369a11.475,11.475,0,0,1,0,4.62Z"
                    transform="translate(6.78 0.287)"
                    fill="#a4afc1"
                  />
                  <path
                    id="Path_177616"
                    data-name="Path 177616"
                    d="M31.6,29.14a.786.786,0,0,1-.655-.35,17.793,17.793,0,0,0-12.592-7.7.787.787,0,0,1-.695-.748,1.406,1.406,0,0,0-1.584-1.271.785.785,0,0,1-.629-.182C14.87,18.2,15.3,17.1,15.334,16.3L12.589,14.22a.787.787,0,0,1-.207-1.021l2.937-5.09a.787.787,0,0,1,.989-.33L19.5,9.133a11.625,11.625,0,0,1,3.4-1.974l.43-3.432a.787.787,0,0,1,.781-.689h5.872a.786.786,0,0,1,.781.693l.412,3.428a11.532,11.532,0,0,1,3.416,1.974l3.189-1.354a.791.791,0,0,1,.99.331L41.7,13.2a.787.787,0,0,1-.209,1.022L38.732,16.3a12.333,12.333,0,0,1,0,3.949l2.76,2.076a.787.787,0,0,1,.209,1.022l-2.935,5.09a.787.787,0,0,1-.99.331l-3.195-1.357A8.633,8.633,0,0,1,31.6,29.14ZM19.122,19.6A19.416,19.416,0,0,1,31.864,27.35,10.779,10.779,0,0,0,33.92,25.9a.791.791,0,0,1,.828-.135l3,1.275,2.239-3.883L37.395,21.21a.785.785,0,0,1-.3-.791,10.8,10.8,0,0,0,0-4.295.787.787,0,0,1,.3-.791l2.594-1.951L37.75,9.5l-3,1.275a.788.788,0,0,1-.839-.144,10.007,10.007,0,0,0-3.7-2.141.784.784,0,0,1-.538-.655L29.28,4.611H24.8l-.4,3.227a.788.788,0,0,1-.535.65,10.1,10.1,0,0,0-3.69,2.143.786.786,0,0,1-.838.143l-3-1.274-2.242,3.885,2.579,1.949c.654.554.039,1.465.1,2.191A3.006,3.006,0,0,1,19.122,19.6Z"
                    transform="translate(6.493 0)"
                  />
                  <path
                    id="Path_177617"
                    data-name="Path 177617"
                    d="M29.945,14.937a6.064,6.064,0,0,1-5.266,6.01,17.145,17.145,0,0,0-6.245-3.308,6.075,6.075,0,1,1,11.511-2.7Z"
                    transform="translate(9.659 3.334)"
                    fill="#e8e6eb"
                  />
                  <path
                    id="Path_177618"
                    data-name="Path 177618"
                    d="M24.978,22.025c-2.171-1.183-3.266-2.461-6.475-3.342a.788.788,0,0,1-.466-.384C15.6,14.1,19.353,8.046,24.195,8.353a6.863,6.863,0,0,1,.784,13.671Zm-5.688-4.746A17.991,17.991,0,0,1,25.206,20.4,5.3,5.3,0,0,0,24.05,9.925a5.367,5.367,0,0,0-4.759,7.354Z"
                    transform="translate(9.36 3.043)"
                  />
                  <path
                    id="Path_177619"
                    data-name="Path 177619"
                    d="M41.589,31.139H3.943a2.985,2.985,0,1,1,0-5.969H41.589a2.985,2.985,0,1,1,0,5.969Zm-37.646-4.4a1.411,1.411,0,1,0,0,2.822H41.589a1.411,1.411,0,1,0,0-2.822Z"
                    transform="translate(0 12.697)"
                  />
                  <path
                    id="Path_177620"
                    data-name="Path 177620"
                    d="M17.809,14.9V33.087H13.424V14.744a2.2,2.2,0,0,1,4.385.157Z"
                    transform="translate(7.151 5.563)"
                    fill="#ffa845"
                  />
                  <path
                    id="Path_177621"
                    data-name="Path 177621"
                    d="M18.1,34.16H13.711a.786.786,0,0,1-.787-.787V15.03a2.983,2.983,0,0,1,5.959.157V33.373a.786.786,0,0,1-.787.787Zm-3.6-1.574H17.31V15.194a1.344,1.344,0,0,0-1.368-1.386A1.324,1.324,0,0,0,14.495,15.1Z"
                    transform="translate(6.865 5.277)"
                  />
                  <path
                    id="Path_177622"
                    data-name="Path 177622"
                    d="M19.641,33.431H3.362a.787.787,0,0,1-.787-.785A19.4,19.4,0,0,1,19.566,13.517a.791.791,0,0,1,.873.854c-.023.509-.016,7.733-.011,12.524,0,4.836,0,5.7,0,5.8a.787.787,0,0,1-.785.738ZM4.165,31.857H18.858c0-.979,0-2.825,0-4.961-.008-7.3-.006-10.37,0-11.691A17.844,17.844,0,0,0,4.165,31.857Z"
                    transform="translate(0.928 6.01)"
                  />
                  <path
                    id="Path_177623"
                    data-name="Path 177623"
                    d="M32.8,33.431H16.512a.786.786,0,0,1-.787-.787V14.458a.7.7,0,0,1,.143-.7.776.776,0,0,1,.709-.242A19.242,19.242,0,0,1,33.585,32.652a.787.787,0,0,1-.787.779ZM17.3,31.857H32A17.682,17.682,0,0,0,17.3,15.2Z"
                    transform="translate(8.45 6.006)"
                  />
                  <path
                    id="Path_177624"
                    data-name="Path 177624"
                    d="M23.7,17.678v5.407a1.5,1.5,0,1,1-2.993,0V15.509A18.343,18.343,0,0,1,23.7,17.678Z"
                    transform="translate(11.328 7.155)"
                    fill="#ffa845"
                  />
                  <path
                    id="Path_177625"
                    data-name="Path 177625"
                    d="M22.487,25.627a2.216,2.216,0,0,1-2.282-2.244V15.794a.792.792,0,0,1,1.182-.68c.976.7,3.077,1.7,3.384,2.848V23.37a2.22,2.22,0,0,1-2.284,2.257Zm-.708-8.4V23.37a.71.71,0,0,0,1.418-.013V18.32A17.762,17.762,0,0,0,21.779,17.227Z"
                    transform="translate(11.041 6.87)"
                  />
                </g>
              </svg>
            </span>
            <h6 className="font-bold text-[18px]">Engineer</h6>
          </div>

          <div className="bg-white px-5 py-3 rounded-3xl w-32  flex justify-between items-center">
            <button className=" w-8 h-8 cursor-pointer flex justify-center items-center  border-[0.5px] text-center rounded-full border-solid border-gray-300">
              <HiChevronLeft className="w-6 h-6" />
            </button>
            <button className=" w-8 h-8 cursor-pointer flex justify-center items-center  border-[0.5px] text-center rounded-full border-solid border-gray-300">
              <HiChevronRight className="w-6 h-6" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Face1Eleven;
