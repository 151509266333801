import {
	Checkbox,
	FormControlLabel,
	TextField,
	FormGroup,
} from "@mui/material";
import { medias } from "../../config/Resources";
import { RiArrowDropDownLine } from "react-icons/ri";
import CommonSearch from "./AllSearch";

function FilterCourses() {
	const options = [
		{
			id: 1,
			lable: "IIT Bhubaneswar",
			number: "446",
		},
		{
			id: 1,
			lable: "IIT Bhubaneswar",
			number: "446",
		},
		{
			id: 1,
			lable: "IIT Bhubaneswar",
			number: "446",
		},
		{
			id: 1,
			lable: "IIT Bhubaneswar",
			number: "446",
		},
		{
			id: 1,
			lable: "IIT Bhubaneswar",
			number: "446",
		},
		{
			id: 1,
			lable: "IIT Bhubaneswar",
			number: "446",
		},
		{
			id: 1,
			lable: "IIT Bhubaneswar",
			number: "446",
		},
		{
			id: 1,
			lable: "IIT Bhubaneswar",
			number: "446",
		},
	];

	return (
		<>
			<div className="flex item-center text-center justify-between ml-5">
				<div className="flex item-center text-center">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="28.297"
					height="23.828"
					viewBox="0 0 28.297 23.828"
				>
					<g
						id="Group_276611"
						data-name="Group 276611"
						transform="translate(0 -0.001)"
					>
						<path
							id="Path_103444"
							data-name="Path 103444"
							d="M19.283,34.372a22.193,22.193,0,0,1-2.632.153c-.51,0-1.035-.02-1.557-.057.137,1.458-6.781.211-6.031-1.608A4.46,4.46,0,0,1,6.891,28.9v-4.65L4.387,23.2A3.726,3.726,0,0,1,2.5,19.689a3.725,3.725,0,0,1,1.887-3.506l10.971-4.636a3.25,3.25,0,0,1,2.583,0l10.97,4.635A3.727,3.727,0,0,1,30.8,19.69,3.726,3.726,0,0,1,28.911,23.2l-2.506,1.059v1.111Z"
							transform="translate(-2.5 -11.279)"
							fill="#f6f0e0"
						/>
						<path
							id="Path_103445"
							data-name="Path 103445"
							d="M27.9,23.156v7.6c0,1.459-3.3,2.646-7.355,2.646s-7.361-1.187-7.361-2.646v-7.6Z"
							transform="translate(-6.398 -13.101)"
							fill="#2f325d"
							stroke="#30100b"
							stroke-width="1"
						/>
						<path
							id="Path_103446"
							data-name="Path 103446"
							d="M17.282,25.51,6.383,20.9a.806.806,0,0,1,0-1.344l10.9-4.614a1.642,1.642,0,0,1,1.3,0l10.9,4.614a.806.806,0,0,1,0,1.344l-10.9,4.614a1.642,1.642,0,0,1-1.3,0Z"
							transform="translate(-3.785 -11.853)"
							fill="#414785"
							stroke="#30100b"
							stroke-width="1"
						/>
					</g>
				</svg>
					<p className="font-plusJakartaSans ml-2 text-[15px] mt-1 font-semibold">
						Courses
					</p>
				</div>
				{/* <svg
					xmlns="http://www.w3.org/2000/svg"
					width="28.297"
					height="23.828"
					viewBox="0 0 28.297 23.828"
				>
					<g
						id="Group_276611"
						data-name="Group 276611"
						transform="translate(0 -0.001)"
					>
						<path
							id="Path_103444"
							data-name="Path 103444"
							d="M19.283,34.372a22.193,22.193,0,0,1-2.632.153c-.51,0-1.035-.02-1.557-.057.137,1.458-6.781.211-6.031-1.608A4.46,4.46,0,0,1,6.891,28.9v-4.65L4.387,23.2A3.726,3.726,0,0,1,2.5,19.689a3.725,3.725,0,0,1,1.887-3.506l10.971-4.636a3.25,3.25,0,0,1,2.583,0l10.97,4.635A3.727,3.727,0,0,1,30.8,19.69,3.726,3.726,0,0,1,28.911,23.2l-2.506,1.059v1.111Z"
							transform="translate(-2.5 -11.279)"
							fill="#f6f0e0"
						/>
						<path
							id="Path_103445"
							data-name="Path 103445"
							d="M27.9,23.156v7.6c0,1.459-3.3,2.646-7.355,2.646s-7.361-1.187-7.361-2.646v-7.6Z"
							transform="translate(-6.398 -13.101)"
							fill="#2f325d"
							stroke="#30100b"
							stroke-width="1"
						/>
						<path
							id="Path_103446"
							data-name="Path 103446"
							d="M17.282,25.51,6.383,20.9a.806.806,0,0,1,0-1.344l10.9-4.614a1.642,1.642,0,0,1,1.3,0l10.9,4.614a.806.806,0,0,1,0,1.344l-10.9,4.614a1.642,1.642,0,0,1-1.3,0Z"
							transform="translate(-3.785 -11.853)"
							fill="#414785"
							stroke="#30100b"
							stroke-width="1"
						/>
					</g>
				</svg> */}
			</div>
			<div>
				<div className="mt-5 ml-5 mr-5 mb-1">
					<CommonSearch />
				</div>
			</div>
			<FormGroup className="h-[300px] overflow-y-scroll flex pt-4 pb-6 pl-5 flex-col flex-nowrap no-scrollbar">
				{options?.map((option) => (
					<div className="flex text-center items-center justify-between !p-0 !pr-5 !m-0  ">
						<FormControlLabel
							className="miniDesk:!text-[10px] !font-plusJakartaSans !text-nowrap !overflow-ellipsis !text-[13px]"
							key={option.id}
							control={
								<div className="flex text-center items-center">
									<Checkbox color="success" />
									<p className="ml-[-6px] normal-case font-plusJakartaSans font-semibold">
										{option?.lable}
									</p>
								</div>
							}
						/>
						<p className="miniDesk:!hidden !font-plusJakartaSans !overflow-ellipsis !text-[13px] font-semibold">
							{option?.number}
						</p>
					</div>
				))}
			</FormGroup>
		</>
	);
}

export default FilterCourses;
