import FilterChips from "./FilterChips";

function Filters({ filterData, setFilterData }) {
	return (
		<div>
			<div className="flex items-center text-center mt-5 ml-5">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="21.228"
					height="19.259"
					viewBox="0 0 21.228 19.259"
				>
					<g
						id="Group_280584"
						data-name="Group 280584"
						transform="translate(0 0.001)"
					>
						<path
							id="Path_150506"
							data-name="Path 150506"
							d="M21.666,7.717H15.738a.744.744,0,0,1,0-1.487h5.928a.744.744,0,0,1,0,1.487Z"
							transform="translate(-1.176 -2.775)"
							fill="#292d32"
							opacity="0.4"
						/>
						<path
							id="Path_150507"
							data-name="Path 150507"
							d="M6.766,7.717H2.818a.738.738,0,1,1,0-1.477H6.766a.738.738,0,0,1,0,1.477Z"
							transform="translate(-2.08 -2.774)"
							fill="#292d32"
							opacity="0.4"
						/>
						<path
							id="Path_150508"
							data-name="Path 150508"
							d="M10.425,11.388A4.194,4.194,0,1,0,6.23,7.194,4.194,4.194,0,0,0,10.425,11.388Z"
							transform="translate(-1.79 -3.001)"
							fill="#292d32"
						/>
						<path
							id="Path_150509"
							data-name="Path 150509"
							d="M21.536,17.867H17.588a.738.738,0,1,1,0-1.477h3.948a.738.738,0,0,1,0,1.477Z"
							transform="translate(-1.047 -2.064)"
							fill="#292d32"
							opacity="0.4"
						/>
						<path
							id="Path_150510"
							data-name="Path 150510"
							d="M8.746,17.867H2.818a.738.738,0,1,1,0-1.477H8.746a.738.738,0,1,1,0,1.477Z"
							transform="translate(-2.08 -2.064)"
							fill="#292d32"
							opacity="0.4"
						/>
						<path
							id="Path_150511"
							data-name="Path 150511"
							d="M14.124,21.549A4.194,4.194,0,1,0,9.93,17.354,4.194,4.194,0,0,0,14.124,21.549Z"
							transform="translate(-1.531 -2.29)"
							fill="#292d32"
						/>
					</g>
				</svg>
				<p className="ml-3 font-plusJakartaSans font-semibold">Filters </p>
			</div>
			{filterData && filterData.length > 0 && (
				<>
					<div className="border border-t mt-8"></div>
					<div className="ml-5 mt-5 mb-5 h-[250px] overflow-y-auto scrollable-content">
						<p className="font-plusJakartaSans font-semibold">
							Applied Filters
						</p>
						<FilterChips
							setFilterData={setFilterData}
							filterData={filterData}
						/>
					</div>
					<div className="border border-b"></div>
				</>
			)}
		</div>
	);
}

export default Filters;
