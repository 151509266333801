import { MdOutlineDateRange } from "react-icons/md";
import { IoIosArrowDown } from "react-icons/io";

function SelectEntranceDates({ setSelectedYear, selectedYear }) {
	const currentYear = new Date().getFullYear();
	const startYear = 2015;
	const years = [];

	for (let year = startYear; year <= currentYear; year++) {
		years.push(year);
	}

	const handleChange = (event) => {
		setSelectedYear(event.target.value);
	};

	return (
		<div className="mt-4 ml-4 mb-2 mr-5 relative">
			<p className="font-plusJakartaSans text-[15px] font-semibold">
				Select Year
			</p>
			<div className="relative inline-block ">
				<MdOutlineDateRange className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-500" />
				<select
					className="appearance-none mt-[2px] w-[100px] bg-white border border-gray-300 rounded-md pl-8 pr-8 py-1 font-plusJakartaSans text-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
					value={selectedYear}
					onChange={handleChange}
				>
					{years.map((year) => (
						<option
							className="font-plusJakartaSans text-sm"
							key={year}
							value={year}
						>
							{year}
						</option>
					))}
				</select>
				<IoIosArrowDown className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-500 pointer-events-none" />
			</div>
		</div>
	);
}

export default SelectEntranceDates;
