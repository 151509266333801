import { Search } from "@mui/icons-material";
import {Input } from "@mui/material";

const CommonSearch = () => {
  return (
    <div className=" rounded-[15px] flex 3xl:rounded-lg overflow-hidden  w-full !bg-[#F2F2F2]">
      <div className="h-[35px] desktop:!h-[35px] desktop:px-[10px]  3xl:h-[35px] !bg-[#F2F2F2] px-4  miniDesk:px-[10px] flex items-center gap-x-4 ">
        <Search className="text-Charcoal-1 desktop:text-[20px] " />
        <Input
        //   onChange={handleSearch}
          classes={{
            input:
              "w-full   !border-none !outline-none !placeholder:text-black !bg-[#F2F2F2] ",
          }}
          className="h-[5px] desktop:text-[12px] before:!border-none after:!border-none w-full !border-none    !bg-[#F2F2F2]"
          placeholder="Search Mentor"
        />
      </div>
    </div>
  );
};

export default CommonSearch;
