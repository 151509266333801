import { GoDotFill } from "react-icons/go";
import { IoEyeOutline } from "react-icons/io5";
import { medias } from "../../../config/Resources";

function PopularBlogsCards1() {
	const cardData = [{}, {}, {}, {}, {}, {}];

	return (
		<div className="grid grid-cols-1 gap-x-10">
			{cardData.map((_item, index) => (
				<div
					key={index}
					className={`pb-4 mb-4 ${
						index < cardData.length - 1
							? "border-b-2 border-dashed border-[#707070] border-opacity-20"
							: ""
					}`}
				>
					<div className="flex items-center text-center gap-[6px]">
						<img
							className="w-[7.7rem] h-[7rem] mb-2 rounded-lg scale-95"
							src={medias.images.Blogs.CollegeImage}
							alt="CollegeImage"
						/>
						<div className="text-left mt-[-10px]">
							<div className="flex flex-wrap gap-[10px] mb-2">
								<p className="font-plusJakartaSans rounded-full bg-[#F37400] bg-opacity-10 pt-[5px] pb-[5px] pl-4 pr-4 inline-flex text-[11.5px] text-center gap-1 font-semibold cursor-pointer">
									Scholarship
								</p>
							</div>
							<p className="font-plusJakartaSans text-[16px] font-bold text-[#000000] mb-1">
								IIT-Bombay students who can't finish 4-yr B-Tech can exit in 3
							</p>
							<p className="flex items-center text-center gap-1">
								<IoEyeOutline className="w-5 h-5 mt-[-3px] text-[#000000] text-opacity-80" />
								<span className="font-plusJakartaSans font-medium text-[12.5px] text-[#000000] text-opacity-80">
									44k views
								</span>
								<GoDotFill className="text-[#000000] text-opacity-40 w-2 h-2" />
								<span className="font-plusJakartaSans font-medium text-[12.5px] text-[#000000] text-opacity-80">
									2 min read
								</span>
								<GoDotFill className="text-[#000000] text-opacity-40 w-2 h-2" />
								<span className="font-plusJakartaSans font-medium text-[12.5px] text-[#000000] text-opacity-80">
									12 May 2023
								</span>
							</p>
						</div>
					</div>
				</div>
			))}
		</div>
	);
}

export default PopularBlogsCards1;
