import React from "react";
import Text from "../../Text";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Menu } from "@mui/material";

type Props = {
  title?: string | JSX.Element | JSX.Element[];
  menu?: JSX.Element | JSX.Element[];
  isMenuOpen?: boolean;
  isDark?: boolean;
};

const DrowDown1 = ({ isDark, title, menu }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event?: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event?.currentTarget || null);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div className="cursor-pointer">
      <Text
        onClick={handleClick}
        className="flex items-center md:text-[11.5px] lg:text-[15px] text-white text-textFont-primary"
      >
        {title}
        <KeyboardArrowDownIcon
          className={`${isDark ? "text-black" : "text-white"}`}
        />
      </Text>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className="!p-0 !pt-0 !pb-0 mt-2 !rounded-2xl"
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {menu}
      </Menu>
    </div>
  );
};

export default DrowDown1;
