/* eslint-disable jsx-a11y/anchor-is-valid */
import { IoEyeOutline } from "react-icons/io5";
import { GoDotFill } from "react-icons/go";
import { medias } from "../../../config/Resources";

function EveryOneReadingCards() {
	const data = [{}, {}, {}, {}, {}, {}];
	const ChipName = [
		{
			id: 1,
			name: "Scholarship",
		},
		{
			id: 2,
			name: "Study Abroad",
		},
	];
	return (
		<div className="overflow-x-auto scrollable-content">
			<div className="flex items-center gap-6 ml-5 mb-1">
				{data.map((item, index) => (
					<div
						key={index}
						className="w-[25.5%] flex-shrink-0 bg-white shadow-lg rounded-xl dark:bg-gray-800 dark:border-gray-700"
					>
						<div>
							<img
								className="rounded-t-lg w-full h-[12.5rem]"
								src={medias.images.College.BackGroundIMage}
								alt="BackgroundImage"
							/>
						</div>
						<div>
							<div className="flex flex-wrap gap-[10px] mb-2 pl-[16px] pt-[16px] ">
								{ChipName?.map((chip) => (
									<p
										key={chip.id}
										className="font-plusJakartaSans rounded-full bg-[#F37400] bg-opacity-10 pt-[7px] pb-[7px] pl-4 pr-4 inline-flex text-[13px] text-center items-center gap-1 font-medium cursor-pointer"
									>
										{chip?.name}
									</p>
								))}
							</div>
							<p className="font-plusJakartaSans font-bold text-[17px] pl-[16px] pt-[4px] pr-[10px]">
								IIT-Bombay students who can't finish 4-yr B-Tech can exit in 3
								yrs with BSc degree
							</p>
							<p className="flex items-center text-center gap-1 pt-[12px] pb-[16px] pl-[14px] pr-[8px]">
								<IoEyeOutline className="w-5 h-5 text-[#000000] text-opacity-80" />
								<span className="font-plusJakartaSans font-medium text-[13.5px] miniDesk:text-[12px] text-[#000000] text-opacity-80">
									44k views
								</span>
								<GoDotFill className="text-[#000000] text-opacity-40 w-2 h-2" />
								<span className="font-plusJakartaSans font-medium text-[13.5px] miniDesk:text-[12px] text-[#000000] text-opacity-80">
									2 min read
								</span>
								<GoDotFill className="text-[#000000] text-opacity-40 w-2 h-2" />
								<span className="font-plusJakartaSans font-medium text-[13.5px] miniDesk:text-[12px] text-[#000000] text-opacity-80">
									12 May 2023
								</span>
							</p>
						</div>
					</div>
				))}
			</div>
		</div>
	);
}

export default EveryOneReadingCards;
