import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const data = ["", "", "", "", "", ""];

const CardMentor = ({ medias }) => {
  return (
    <div className="bg-[#03321F] px-10 py-10 rounded-2xl flex flex-col justify-center gap-y-6 items-center mx-8 ">
      <img
        src="https://encrypted-tbn1.gstatic.com/licensed-image?q=tbn:ANd9GcSb5YTP_Zfb9Aj9h3n79iDjofIAWbIRCn2mbRxjP04h8I7nDF1tj5DP_oCVy4xqRAyd5fxaiA9eZGJ0W4I"
        alt="img"
        className="w-[120px] h-[120px] rounded-full object-cover -mt-24 z-50  "
      />
      <div className="flex flex-col justify-center text-start gap-y-2 ">
        <h4 className="text-white text-[32px] font-normal font-plusJakartaSans opacity-80">
          Rithik Roshan
        </h4>
        <h5 className="text-white text-[22px] italic font-normal font-plusJakartaSans opacity-50">
          -Software Engineer
        </h5>
      </div>
      <div className="flex gap-1 w-full mb-5 text-start  items-center ml-1">
        <img
          src={"https://1000logos.net/wp-content/uploads/2021/10/logo-Meta.png"}
          alt=""
          className="w-8 h-4"
        />
        <h6 className="text-white text-[18px]  font-medium font-plusJakartaSans opacity-50">
          Meta
        </h6>
      </div>
    </div>
  );
};

const TopGlobalSlider = ({ medias }) => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 5000,
    cssEase: "linear",
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className="w-full text-center">
        <h5 className="text-[#F0BC5E] text-[64px] font-plusJakartaSans font-medium mt-10">
          Our Top Global Mentors
        </h5>
      </div>
      <div className="slider-container mt-20 px-10">
        <Slider {...settings}>
          {data?.map((mentor, i) => {
            return (
              <div key={i} className="!overflow-visible">
                <CardMentor medias={medias} />
              </div>
            );
          })}
        </Slider>
      </div>
    </>
  );
};

export default TopGlobalSlider;
