import React from "react";
import Text from "../../Components/Text";
import {
  CompareRounded,
  EastRounded,
  LocationOnRounded,
  NavigateNext,
  PushPin,
  Search,
  StarRounded,
  Verified,
} from "@mui/icons-material";
import { Button } from "@mui/material";
import Image from "../../Components/Image";
import { medias } from "../../config/Resources";
import { States, collegeData, findOver } from "../../config/pageContent";
import classNames from "classnames";
import { mediaResources } from "../../config/Constants";

const Home = () => {
  return (
    <div className="">
      <div
        id="Screeen1"
        className=" relative -top-[269px] flex justify-center bg-[F3F6FF] items-center flex-col  w-screen h-[calc(100vh+269px)] "
      >
        <div className="absolute top-0 mainFace1bg bg-contain bg-no-repeat  bg-[#010320] h-full w-full -z-10" />
        <Text className="text-[65px] font-[PlusJakartaSans-ExtraBold] desktop:!text-[30px] 3xl:text-[45px]   text-[#F3F6FF] ">
          Find Over 60000+ Colleges in India
        </Text>
        <Text className="text-[#F3F6FF] desktop:!text-[10px] 3xl:text-[16px] opacity-50 text-[25px]">
          Turn ideas into outstanding designs with high-quality vectors,photos,
          video
        </Text>
        <div className="bg-[#fff] relative z-30 p-[6px] pl-[30px] flex justify-between items-center 3xl:w-[46%] w-[50%] bg-opacity-20 rounded-[14px] backdrop-blur-[50px] mt-[26px]">
          <Search className="text-[#7F7F7F]" />
          <input
            placeholder="Search Colleges, Courses, Exams"
            className="bg-transparent text-[22px] 3xl:text-[16px] desktop:!my-2 desktop:text-[14px] pl-[17px]  ml-[17px] h-fit w-3/4 my-[29px] 3xl:my-[12px] outline-none border-l placeholder:text-white placeholder:opacity-50"
          />
          <Button className=" text-[22px] text-white desktop:!text-[10px] capitalize px-5 3xl:text-[14px] font-bold !h-full !ml-auto !bg-[#239403] rounded-[14px]">
            Search Now
          </Button>
        </div>
        <div className="flex w-[48%] overflow-auto no-scrollbar mt-[56px] 3xl:gap-[50px] gap-[90px]">
          {[...collegeData, ...collegeData, ...collegeData].map((items) => {
            return (
              <>
                <div className="flex flex-col items-center justify-center min-w-[100px]">
                  <Image
                    className={classNames(
                      "bg-[#ffffff] desktop:w-[60px] backdrop-blur-[39px] bg-opacity-20 rounded-t-[12px] px-[10px] pt-[9px]"
                    )}
                    src={items.Icons}
                    alt={items.Name}
                  />
                  <Text className="py-[7px] text-white w-[100px] desktop:w-[90px] text-center desktop:text-[12px] bg-[#ffffff] backdrop-blur-[39px] bg-opacity-20  rounded-[12px]   px-[17px] ">
                    {items.Name}
                  </Text>
                </div>
              </>
            );
          })}
        </div>
        <div className="absolute bottom-0 flex items-center justify-center w-full gradient-border-1 bg-[#F3F6FF] ">
          <div className="relative -translate-y-[50%] desktop:!h-[150px] h-[180px] desktop:!w-[150px] w-[180px]">
            <Image
              src={medias.images.HomeMain.ExploreMore}
              className="border-[25px] logoImageCont bg-[rgb(240,188,94)] absolute w-full 3xl:border-[18px] spin  gradient-border-1 rounded-full"
            />
            <Image
              src={medias.images.HomeMain.arrowHome}
              className="relative border   border-black rounded-full p-2 desktop:left-[calc(50%-20px)]  left-[calc(50%-30px)] desktop:w-[40px] desktop:h-[40px] h-[60px] desktop:top-[calc(50%-20px)] top-[calc(50%-30px)]  w-[60px]"
            />
          </div>
        </div>
      </div>
      <div className=" bg-[#F3F6FF] relative -top-[269px] 3xl:py-0 py-[64px] flex flex-col justify-between items-center px-[170px]">
        <Text className="font-quitery  text-black 3xl:text-[30px] text-[46px] ">
          Find Over 60000+ Colleges in India
        </Text>
        <div className="grid mt-auto grid-cols-3 py-[36px] miniDesk:!gap-[20px] gap-x-[54px] gap-y-[60px] w-full  grid-rows-3">
          {findOver.map((item) => {
            return (
              <div>
                <div
                  className={classNames(
                    "h-full shadow-[0px_3px_6px_rgba(0,0,0,0.16)]  bg-white w-[90%] mx-auto flex flex-col justify-between miniDesk:justify-start overflow-hidden rounded-[26px] "
                  )}
                >
                  <div
                    className={classNames(
                      item.tileColor,
                      "flex w-full desktop:p-[15px] p-[27px]"
                    )}
                  >
                    <Image src={item.Icons} className="w-[40px] h-[40px]" />
                    <div className="ml-[26px]">
                      <Text className="   miniDesk:!text-[10px] desktop:text-[14px] 3xl:text-[20px] text-[26px] font-semibold ">
                        {item.Title}
                      </Text>
                      <Text className="text-black opacity-60  miniDesk:!text-[8px]  desktop:text-[10px] 3xl:text-[14px]  text-[18px] font-semibold">
                        {item.description}
                      </Text>
                    </div>
                  </div>

                  <div className="flex flex-col justify-between h-full bg-white">
                    <div className="flex flex-wrap w-full p-[27px] desktop:p-[10px] desktop:gap-[10px] gap-x-[12px] gap-y-[25px]">
                      {item.keyWords.map((items) => {
                        return (
                          <div className=" text-center miniDesk:!text-[8px] 3xl:px-[13px] 3xl:py-[6px] 3xl:text-[12px]  z-20 rounded-full border px-[29px] py-[10px] text-[#2771E7] border-[#2771E7]">
                            {items}
                          </div>
                        );
                      })}
                    </div>
                    <div className="border_spacing"></div>
                    <div className="   border-opacity-[47%] px-[27px] py-[20px]">
                      <Text className="text-[22px] 3xl:text-[18px] desktop:!text-[12px] items-center flex font-bold w-full">
                        {item.navText}{" "}
                        <span className="ml-auto w-[40px] h-[40px] 3xl:w-[25px] 3xl:h-[25px] miniDesk:!w-[15px] miniDesk:!h-[15px]  bg-black  bg-opacity-[10%] rounded-full flex items-center justify-center ">
                          {" "}
                          <NavigateNext className="!w-[40px] miniDesk:text-[12px]" />{" "}
                        </span>
                      </Text>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="relative bg-[#F3F6FF] -top-[269px] px-[119px]">
        <Text className="font-quitery desktop:!text-[30px] text-black text-[46px] ">
          Top 5 Engineering Colleges
        </Text>
        <div className="grid grid-cols-3 grid-rows-2">
          {[...Array(6)].map((_) => (
            <div className="bg-white  3xl:h-[90%] miniDesk:!w-[95%] h-[95%] w-[84%] 3xl:w-[90%] shadow-[0px_3px_6px_rgba(0,0,0,0.16)] mt-[31px] max-w-full overflow-hidden rounded-[14px] ">
              <div className="relative">
                <Image
                  src={medias.images.HomeMain.College}
                  className="object-contain w-full"
                  alt="IITDelhi"
                />
                <div className="rounded-[13px] p-[11px] bg-white w-fit mx-[20px] 3xl:m-[10px] shadow-[0px_3px_6px_rgba(0,0,0,0.16)] -translate-y-[50%]">
                  <Image
                    src={medias.images.HomeMain.IITDelhi}
                    className="desktop:!w-[25px]"
                    alt="IITDelhi"
                  />
                </div>
                <div className="px-[11px] relative -top-[30px]">
                  <Text className="text-[25px] desktop:!text-[13px] 3xl:text-[18px] font-semibold ">
                    IIT Delhi{" "}
                    <Verified className="!text-[rgba(39,113,231,1)] 3xl:text-[18px]" />
                  </Text>
                  <div className="mt-[9.9px]   flex gap-[18px] items-center">
                    <Text className="text-[15px] desktop:!text-[8px] 3xl:text-[12px] font-medium">
                      <LocationOnRounded className="!text-[#FF5A5A] 3xl:text-[18px] stroke-black stroke-[1.5px]" />{" "}
                      IIT Delhi Main Rd, Hauz K..
                    </Text>
                    <Text className="bg-[#F3F3F3] 3xl:p-1 text-[15px] desktop:!text-[8px] w-fit py-[6px] 3xl:text-[12px] items-center pl-[9px] pr-[13px] rounded-[4px] font-medium">
                      <StarRounded className="!text-[#FFC000]  3xl:text-[18px]" />{" "}
                      4.5
                    </Text>
                    <Text className="text-[#239403]  desktop:!text-[8px] 3xl:text-[12px] underline text-[15px]">
                      525 reviews
                    </Text>
                  </div>
                </div>
                <div className="flex relative 3xl:-top-[20px] miniDesk:flex-col miniDesk:!gap-[4px]  ml-[11px] 3xl:gap-[12px] gap-[20px]">
                  <Text className="font-light  desktop:!text-[8px] 3xl:text-[12px] items-start flex gap-[6px] ">
                    <PushPin className="rotate-[45deg] 3xl:text-[14px]" />
                    Estd. 1961
                  </Text>
                  <Text className="flex items-start  desktop:!text-[8px] font-light 3xl:text-[12px]  gap-[6px]">
                    <Image
                      src={medias.images.HomeMain.DocVerified}
                      className="3xl:w-[14px]"
                      alt="DocVerified"
                    />
                    Approved by:UGC AICTE
                  </Text>
                  <Text className="flex font-light  desktop:!text-[8px] gap-[6px] items-start 3xl:text-[12px]">
                    <Image
                      src={medias.images.HomeMain.ranking}
                      className="3xl:w-[14px]"
                      alt="ranking"
                    />
                    NIRF Ranking : 2nd
                  </Text>
                </div>
                <Text className="flex relative 3xl:-top-[20px] 3xl:mt-[10px]  ml-[11px]  desktop:!text-[10px] mt-[21px] text-[13px] leading-[18px] font-light gap-[6px]">
                  Quick Links
                </Text>
                <div className="flex pb-[25px] 3xl:pb-[10px] 3xl:my-[10px] relative 3xl:-top-[20px] border-b border-dashed mt-[16px] border-[#707070] border-opacity-[38%] overflow-auto gap-[8px] pl-[20px] no-scrollbar">
                  {[
                    "Admissions 2023",
                    "Placements",
                    "Courses & Fees",
                    "Reviews",
                    "Scholarship",
                    "Q&A",
                    "Overview",
                    "Notice board",
                    "Gallery",
                    "Alumni Speak",
                  ].map((items) => {
                    return (
                      <>
                        <Text className="min-w-fit desktop:!text-[10px] 3xl:py-[3px] 3xl:px-[12px] 3xl:text-[13px] px-[30px] border border-[#6C6C6C] rounded-full  py-[8px]">
                          {items}
                        </Text>
                      </>
                    );
                  })}
                </div>
                <div className="mt-[8px] relative 3xl:-top-[20px] mx-[23px]  3xl:mx-[0] mb-[20px]">
                  <Text className="text-[14px]  desktop:!text-[10px] text-black 3xl:ml-2 opacity-[62%]">
                    Avg Fees
                  </Text>
                  <Text className="text-[17px] 3xl:text-[12px]  desktop:!text-[10px]  3xl:ml-2 flex gap-[11px] items-baseline mt-[5px] font-medium ">
                    <span className="text-[27px] 3xl:text-[20px]  desktop:!text-[15px] text-[#239403] font-extrabold">
                      ₹ 235,700
                    </span>
                    1st yr Fees
                  </Text>
                  <div className="mt-[19px] flex gap-[10px] 3xl:ml-2">
                    <Button
                      variant="outlined"
                      className="border py-[19px] 3xl:py-[10px] 3xl:text-[10px] 3xl:h-fit flex gap-[6.2px] text-[17px] font-medium 3xl:px-3 capitalize !text-black px-[28px] border-[#707070] rounded-[9px]"
                    >
                      <CompareRounded className="!text-black 3xl:text-[14px]" />{" "}
                      Compare
                    </Button>
                    <Button
                      variant="outlined"
                      className="border py-[19px] !h-full flex gap-[6.2px] text-[17px] font-medium  3xl:py-[10px] 3xl:text-[10px] 3xl:h-fit 3xl:px-3 capitalize !text-[#239403] px-[28px] border-[#239403] rounded-[9px]"
                    >
                      <Image
                        src={`${mediaResources}/Download.svg`}
                        className="!text-black 3xl:w-[14px]"
                      />{" "}
                      Brochure
                    </Button>
                    <Button
                      variant="contained"
                      className="border py-[19px] flex gap-[6.2px] miniDesk:!px-[2px] text-[17px] font-medium capitalize !text-[#fff] !bg-[#F37400] 3xl:py-[10px]  3xl:px-3 3xl:text-[10px] 3xl:h-fit !shadow-none px-[28px] border-[#239403] rounded-[9px]"
                    >
                      Apply Now{" "}
                      <EastRounded className="3xl:text-[14px] miniDesk:hidden" />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="relative bg-[#F3F6FF] py-[98px] -top-[269px] px-[119px]">
        <Text className="font-quitery desktop:!text-[30px] text-black text-center   text-[46px] ">
          Top 5 Engineering Colleges
        </Text>
        <div className="mt-[42px] justify-center flex gap-[47px] ">
          {States.map((items) => {
            return (
              <div className="w-[238px]  text-center bg-white flex flex-col items-center rounded-[21px] miniDesk:py-[20px] py-[31px] ">
                <Image
                  src={items.icons}
                  className="3xl:w-[50px] miniDesk:!w-[30px]"
                  alt={items.name}
                />
                <Text className="text-[23px] font-bold 3xl:text-[14px] mt-[17px] ">
                  {items.name}
                </Text>
              </div>
            );
          })}
        </div>
      </div>
      <div className="mt-[108px]"></div>
    </div>
  );
};

export default Home;
