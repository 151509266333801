import React from "react";
import { medias } from "../../../../config/Resources";

function PlacementsStatistics({ placement }) {
	const data = [
		{
			id: 1,
			image: medias.images.College.Student,
			number: placement?.total_student_participated,
			text: "Participated Students",
		},
		// {
		// 	id: 2,
		// 	image: medias.images.College.Student,
		// 	number: "3500",
		// 	text: "Placed Students",
		// },
		{
			id: 3,
			image: medias.images.College.Student,
			number: placement?.students_placed_percent,
			text: "Placed % Average",
		},
		{
			id: 4,
			image: medias.images.College.Student,
			number: placement?.total_offers_made,
			text: "Total Offers made",
		},
		{
			id: 5,
			image: medias.images.College.Bag,
			number: placement?.average_package,
			text: "Average Package",
		},
		{
			id: 6,
			image: medias.images.College.Bag,
			number: placement?.international_offers,
			text: "International Offers",
		},
		{
			id: 7,
			image: medias.images.College.Bag,
			number: placement?.highest_package,
			text: "Highest Package",
		},
		{
			id: 8,
			image: medias.images.College.Bag,
			number: placement?.lowest_package,
			text: "Lowest Package",
		},
	];

	return (
		<div className="ml-4 mr-4 mt-5">
			<div className="flex items-center text-center gap-1">
				<img
					className="bg-[#F0BC5E] bg-opacity-15 p-[6px] rounded-md w-9 h-9"
					src={medias.images.College.Placement}
					alt="Placements"
				/>
				<h6 className="font-plusJakartaSans mt-[5.5px] font-bold text-[15px]">
					Placements Statistics
				</h6>
			</div>
			<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mt-4">
				{data.map((item) => (
					<div
						key={item.id}
						className="max-w-sm rounded overflow-hidden custom-shadow  pt-[10px] pb-[10px] pl-1 pr-1"
					>
						<img
							className="w-10 h-10 flex ml-auto mr-auto"
							src={item.image}
							alt={item.text}
						/>

						<p className="font-semibold flex justify-center text-center items-center font-plusJakartaSans mt-[4px] text-[13px]">
							{item.number}
						</p>
						<p className="font-plusJakartaSans flex justify-center text-center items-center text-[10.5px] font-medium text-[#AFAFAF]">
							{item.text}
						</p>
					</div>
				))}
			</div>
		</div>
	);
}

export default PlacementsStatistics;
