import { medias } from "../../../config/Resources";
import PopularBlogsCards1 from "./PopularBlogsCards1";

function PopularBlogs1() {
	return (
		<div className="pb-3 pl-4 pr-3 pt-5">
			<div className="flex text-center justify-between">
				<h1 className="font-plusJakartaSans font-bold text-[17px] flex items-center text-center gap-[5px]">
					<img
						className="w-10 h-10"
						src={medias.images.Blogs.Books}
						alt="Fire"
					/>
					Popular Blogs
				</h1>
			</div>
			<div className="pt-2">
				<PopularBlogsCards1 />
			</div>
		</div>
	);
}

export default PopularBlogs1;
