import { Controls, Player } from "@lottiefiles/react-lottie-player";
import Chat from "../../animations/Chat.json";
import Verification from "../../animations/Verification.json";

const Card = ({ src, title, desc }) => {
  return (
    <>
      <div className="bg-[#12271E] rounded-[15px] mr-6 ">
        <div className="flex gap-4 px-4 py-6">
          <div className="p-2 bg-[#1D3F30] rounded-tl-[40px] rounded-bl-[40px] rounded-tr-[40px]">
            <Player
              autoplay
              speed={0.4}
              loop
              style={{ width: "90px", height: "90px" }}
              src={src}
            >
              <Controls
                visible={false}
                buttons={[
                  "play",
                  "repeat",
                  "frame",
                  "debug",
                  "snapshot",
                  "background",
                ]}
              />
            </Player>
          </div>

          <div className="text-white font-plusJakartaSans">
            <h4 className="opacity-80 text-[30px]">{title} </h4>
            <h6 className="opacity-40 text-[18px]">{desc}</h6>
          </div>
        </div>
      </div>
    </>
  );
};

const ReletableMentorship = ({ medias }) => {
  return (
    <div className="flex  justify-center w-full pt-5">
      <div className="bg-[#01180F] w-[90%] flex    p-10 rounded-[42px] ">
        <div className="w-[70%] flex flex-col justify-between ">
          <h2 className="text-[#F0BC5E] font-plusJakartaSans text-[62px] w-[90%]">
            {" "}
            India Most Relatable <br /> Mentorship Platform
          </h2>
          <div className="space-y-6 ">
            <Card
              src={Chat}
              desc={
                "  Indian Institute of Technology, Delhi or IIT Delhi is an Institute of Eminence offering about 102 undergraduate, postgraduate,"
              }
              title={"Ring message anytime to your mentor"}
            />
            <Card
              src={Verification}
              desc={
                "  Indian Institute of Technology, Delhi or IIT Delhi is an Institute of Eminence offering about 102 undergraduate, postgraduate,"
              }
              title={"Ring message anytime to your mentor"}
            />
          </div>
        </div>
        <div className="flex items-end mt-28">
          <img
            src={medias.images?.mentorship?.img1}
            alt=""
            className="w-[90%]"
          />
        </div>
      </div>
    </div>
  );
};

export default ReletableMentorship;
