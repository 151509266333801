import { medias } from "../../../../config/Resources";
import { GoArrowRight } from "react-icons/go";

const AnswerDreamsAndCourses = () => {
	return (
		<div className="w-[100%] flex gap-3 mt-5">
			<div className="w-[32%]">
				<div className="rounded-xl w-[100%] overflow-hidden pt-6 pl-7 pr-7 pb-5 shadow-lg bg-[#FFFBF1]">
					<img src={medias.images.College.GetAnswer} alt="GetAnswer" />
					<p className="font-plusJakartaSans flex item-center text-center text-[15.5px] mt-4 font-bold text-[#000000]">
						Get Answer on Test Preparation, Admission, & Campus Life
					</p>
                   
					<button className="font-plusJakartaSans ml-auto mr-auto mt-3 font-medium gap-3 border border-[#2F2D3A] rounded-full text-[#102048] text-[11px] flex item-center text-center pt-2 pb-2 pl-4 pr-4">
						Start Now <GoArrowRight className="w-4 h-4" />
					</button>
                    </div>
			</div>
			<div className="w-[68%]">
            <div className="rounded-xl w-[100%] flex overflow-hidden pt-5 pl-4 pb shadow-lg bg-[#E3FFEF]">
            <div className="w-[70%]">
             <p className="font-plusJakartaSans leading-none font-bold text-[18px] text-[#000000]">
             Find Your Dream Colleges in India
             </p>
             <button className="font-plusJakartaSans mt-4 font-medium gap-3 border border-[#2F2D3A] rounded-full text-[#102048] text-[11px] flex item-center text-center pt-2 pb-2 pl-4 pr-4">
						Explore Now <GoArrowRight className="w-4 h-4" />
					</button>
            </div>
            <div>
                <img className="w-[93%] h-[93%]" src={medias.images.College.DreamColleges}  alt="DreamColleges"/>
            </div>
            </div>
            <div className="rounded-xl w-[100%] mt-6 flex overflow-hidden pt-5 pl-4 pb-4 shadow-lg bg-[#FFFFFF]">
            <div className="w-[70%]">
             <p className="font-plusJakartaSans leading-none font-bold text-[17px] text-[#000000]">
             Find Courses with Help of AI Based Course Finder
             </p>
             <button className="font-plusJakartaSans mt-4 font-medium gap-3 border border-[#2F2D3A] rounded-full text-[#102048] text-[11px] flex item-center text-center pt-2 pb-2 pl-4 pr-4">
						Find Now <GoArrowRight className="w-4 h-4" />
					</button>
            </div>
            <div>
                <img className="w-[93%] h-[93%]" src={medias.images.College.AiBased}  alt="DreamColleges"/>
            </div>
            </div>
            </div>
		</div>
	);
};

export default AnswerDreamsAndCourses;
