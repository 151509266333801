import { useState } from "react";

function NewsArticlesChips() {
	const [selectedId, setSelectedId] = useState();

	const handleChipClick = (id) => {
		const newSelectedId = selectedId === id ? null : id;
		setSelectedId(newSelectedId);
	};

	const ChipName = [
		{
			id: 1,
			name: "Latest (355)",
		},
		{
			id: 2,
			name: "Popular (350)",
		},
		{
			id: 3,
			name: "Most Viewed (35)",
		},
	];
	return (
		<>
			<div className="flex flex-wrap gap-[10px] mt-5 mb-2 ml-4 mr-4">
				{ChipName?.map((chip) => (
					<p
						key={chip.id}
						onClick={() => handleChipClick(chip?.id)}
						className={`font-plusJakartaSans rounded-full border pt-1 pb-1 pl-4 pr-4 inline-flex text-[12px] text-center items-center gap-1 font-medium cursor-pointer ${
							selectedId === chip.id
								? "bg-[#2771E7] border-[#2771E7] text-white"
								: "border-[#D1D1D1] text-[#000000]"
						}`}
					>
						{chip?.name}
					</p>
				))}
			</div>
		</>
	);
}

export default NewsArticlesChips;
