import { medias } from "../../config/Resources";
import React, { useState, useEffect } from "react";

const SuggestCurricularActivites = ({
  setSelectedCardLabel,
  selectedCardLabel,
  datas
}) => {

  const AcdameyDetails = [
    {
      image: medias.images.Profile.Arts,
      label: "Performing Arts",
    },
    {
      image: medias.images.Profile.Event,
      label: "Event",
    },
    { image: medias.images.Profile.Sports10, label: "Sports" },
  ];

  // Initialize state with the label of the first card
  const [defaultSelectedCardLabel, setDefaultSelectedCardLabel] = useState(
    AcdameyDetails.length > 0 ? AcdameyDetails[0].label : ""
  );

  useEffect(() => {
    setSelectedCardLabel(defaultSelectedCardLabel);
  }, []);

  const handleCardClick = (label) => {
    setSelectedCardLabel(label);
  };

  return (
    <>
      <div className="tracking-wide text-sm font-plusJakartaSans font-bold pt-8 pl-4 pr-4">
        Suggest Curricular Activities
      </div>
      <div className="w-full flex">
        {AcdameyDetails?.map((detail, index) => (
          <div
            key={index}
            className={`flex justify-center items-center bg-white rounded-xl shadow-md overflow-hidden mt-4 ml-5 border cursor-pointer ${
              detail.label === selectedCardLabel ? "border-blue-500" : "border-gray-300"
            } min-w-100 max-w-150`}
            onClick={() => handleCardClick(detail.label)}
          >
            <div className="p-4">
              <div className="flex items-center justify-center mb-2">
                <img
                  src={detail.image}
                  alt={detail.label}
                  className="h-13 w-13 object-cover"
                />
              </div>
              <div className="text-sm text-center">
                <p className="font-bold font-plusJakartaSans">
                  {detail.label}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default SuggestCurricularActivites;
