function SelectedDate({ setSelectedYear, selectedYear }) {
	const currentYear = new Date().getFullYear();
	const startYear = 2015;
	const years = [];

	for (let year = startYear; year <= currentYear; year++) {
		years.push(year);
	}
	
	const handleChange = (event) => {
		setSelectedYear(event.target.value);
	};

	return (
		<div className="mt-4 ml-4 mb-2 mr-5">
			<select
				className="block w-[30%] border font-plusJakartaSans text-[14px] pt-1 pb-1 pl-1 pr-3 border-black rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
				value={selectedYear}
				onChange={handleChange}
			>
				{years.map((year) => (
					<option
						className="font-plusJakartaSans text-[10px]"
						key={year}
						value={year}
					>
						{year}
					</option>
				))}
			</select>
		</div>
	);
}

export default SelectedDate;
