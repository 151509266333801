/* eslint-disable jsx-a11y/img-redundant-alt */
import { useEffect, useState } from "react";
import { medias } from "../../../config/Resources";

const DifferentCollege = () => {
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const fetchData = async () => {
			await new Promise((resolve) => setTimeout(resolve, 2000));
			setIsLoading(false);
		};

		fetchData();
	}, []);
	return (
		<>
			{isLoading ? (
				<p className="flex items-center text-center justify-center">
					Loading ...
				</p>
			) : (
				<div className="flex justify-center">
					<div className="max-w-md bg-[#E7FFE7] md:max-w-[64%]  rounded-xl overflow-hidden shadow-md mt-16 mb-10">
						<div className="w-full">
							<div className="flex w-[100%]">
								<div className="item-center text-center justify-center p-10 w-[44%]">
									<p className="font-plusJakartaSans flex item-center justify-center text-center font-semibold text-[#000000] text-[15px]">
										Find multiple
									</p>
									<p className="font-plusJakartaSans ml-auto mr-auto font-extrabold text-[#000000] text-[25px]">
										Entrance Exams from
									</p>
									<div className="rounded-lg mt-[8px] flex item-center text-center ml-auto mr-auto justify-center pt-2 pb-2 w-[86%] bg-[#1D7404] ">
										<p className="font-plusJakartaSans text-[#FFFFFF] font-black text-[25px]">
											Different Colleges
										</p>
									</div>
									<p className="flex item-center gap-2 text-[#000000] font-plusJakartaSans font-semibold justify-center mt-[10px] text-center">
										<img
											className="w-32 text-[#000000]"
											src={medias.images.Profile.line2}
											alt="lin2"
										/>
										using
										<img
											className="w-32 text-[#000000]"
											src={medias.images.Profile.line1}
											alt="lin1"
										/>
									</p>
									<img
										className="flex justify-center ml-auto mr-auto items-center mt-[]"
										src={medias.images.header.pagelogo}
										alt="logo"
									/>
								</div>
								<div className="relative w-[60%] ">
									<img
										src={medias.images.Exam.BackgroundImage}
										alt="image 2"
										className="relative z-10 h-[100%]"
									/>
								</div>
							</div>
							<div></div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default DifferentCollege;
