/* eslint-disable jsx-a11y/img-redundant-alt */
import { medias } from "../../../config/Resources";

function AllPhotos() {
	const Photos = [{}, {}, {}, {}, {}, {}];
	return (
		<div className="max-w-sm p-4 w-full bg-white rounded-md shadow dark:bg-gray-800 dark:border-gray-700">
			<p className="font-plusJakartaSans font-extrabold text-[13px] mb-2">
				Photos
			</p>
			<div className="flex flex-wrap">
				{Photos.map((_, index) => {
					return (
						<div key={index} className="w-1/2 p-1 rounded-md">
							<img
								src={medias.images.Profile.Colleges}
								alt="image"
								className="w-full h-auto"
							/>
						</div>
					);
				})}
			</div>
		</div>
	);
}

export default AllPhotos;
