import { BsDownload } from "react-icons/bs";
import { medias } from "../../../../config/Resources";

function EntranceDocuments({ examPattern }) {
	return (
		<div className="relative">
			<div className="mx-4 mt-8">
				<h6 className="mt-4 font-plusJakartaSans flex items-center text-center font-bold text-[#000000] text-opacity-50 text-[13px]">
					Documents
				</h6>
				<div className="flex mt-2">
					<div className="flex flex-nowrap scrollable-content overflow-x-auto gap-4 pb-2 relative">
						{examPattern?.syllabus?.map((data, index) =>
							data?.subjects?.map((datas) => {
								return (
									<div
										key={index}
										className="bg-white flex flex-col mt-[44%] rounded-lg shadow-md pt-[7%] px-6 relative flex-shrink-0"
									>
										<div className="absolute -top-16 left-1/2 transform -translate-x-1/2">
											<div className="bg-[#FFF9EC] rounded-full p-4 shadow-lg">
												<img
													className="w-10 h-10"
													src={medias.images.Exam.Papers}
													alt="Papers"
												/>
											</div>
										</div>
										<a
											href={datas?.file}
											target="_blank"
											rel="noopener noreferrer"
											className="flex items-center pb-2 text-[15px] font-plusJakartaSans gap-1 text-[#239403] font-medium"
										>
											<BsDownload className="w-4 h-4" />
											<span className="border-b-2 border-[#239403]">
												Download
											</span>
										</a>
									</div>
								);
							})
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

export default EntranceDocuments;
