/* eslint-disable jsx-a11y/img-redundant-alt */
import { Button } from "@mui/material";
import React from "react";
import { guidancePageContant } from "../../../config/pageContent";
import Image from "../../Image";
import { medias } from "../../../config/Resources";
import classNames from "classnames";

const Face10 = () => {
  const images = [
    ...guidancePageContant.Face7.Images,
    ...guidancePageContant.Face7.Images,
  ];

  return (
    <div className="bg-white py-[30px] relative">
      <div className="absolute z-0 bottom-0 w-full h-full rounded-[50%/80px_80px_0_0] bg-[#001D52] scale-110 " />
      <div className="relative z-10 text-[#FFFFFF] text-[50px] tracking-wider font-black  flex justify-center">
        Invest in your{" "}
        <span className="bg-[#F0BC5E] ml-2 rounded-[10px] PlusJakartaSans-ExtraBoldCustom px-3 text-[#001D52] font-bold">
          {" "}
          Career Now!
        </span>
      </div>
      <div className="relative italic text-[#fff] text-[27px] font-light  flex justify-center mt-[15px]">
        <span className="opacity-85">
          "Why there is nothing quite like Synific Careers"
        </span>
        <Image
          src={medias.images.guidance.Face8.pathBottom}
          alt="pathBottom"
          className="absolute bottom-[calc(0%-25px)] "
        />
      </div>
      <div className="relative flex">
        <div className="slider-sidebg absolute  z-40  h-full w-[20%]" />
        <div
          className={`no-scrollbar px-[90px] grid-flow-col gap-4 overflow-auto mt-[40px] grid-rows-12  relative grid `}
        >
          {images.map((path, index) => (
            <div
              key={index}
              className={classNames(path.classes, "row-span-5  min-w-[210px]")}
            >
              <img
                key={index}
                src={path.src}
                alt={`Image ${index + 1}`}
                className={`  `}
              />
            </div>
          ))}
        </div>
        <div className="slider-sidebg right-0 absolute rotate-180  z-40  h-full w-[20%]" />

        <div />
      </div>
      <div className="flex justify-center w-full mt-[13px]">
        <Button
          className="!border-[#F0BC5E] normal-case	 text-[17px] !py-[15px] !px-[30px] font-bold !rounded-[9px] !text-[#F0BC5E]"
          variant="outlined"
        >
          Explore all careers
        </Button>
      </div>
    </div>
  );
};

export default Face10;
