import React from "react";
import axios from "axios";
import { VscClose } from "react-icons/vsc";
import { IoIosArrowRoundForward } from "react-icons/io";
import axiosInstance from "../../config/Axios/axiosInstance";
import { API_URLS } from "../../config/API_URLS";
import { toast } from "react-toastify";

function DeleteMileStoneModal({
	isModalOpen3,
	setIsModalOpen3,
	toggleModal3,
	selectedItemId2,
	setData,
}) {
	const getAcademicRoadMapData = async () => {
		try {
			const res = await axiosInstance.get(
				`${API_URLS.baseURL}/getPassionRoadmap?type=`
			);
			setData(res.result);
			console.log(res, "responseData");
		} catch (error) {
			console.log(error);
		}
	};
	const handleDelete = async () => {
		const payload = {
			data: {
				passionData: {
					type: "milestone",
					passion_id: selectedItemId2,
				},
			},
		};

		try {
			const response = await axiosInstance.delete(
				`${API_URLS.baseURL}/deleteParticularPassion`,
				payload
			);
			if (response) {
				toast("Milestone is Deleted Successfully", { type: "success" });
				getAcademicRoadMapData();
				setIsModalOpen3(false);
			} else {
				console.error("Error:", response.statusText);
			}
		} catch (error) {
			console.error("Error:", error);
		}
	};
	return (
		<div>
			<div
				id="default-modal"
				tabIndex="-1"
				aria-hidden="true"
				className={`fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full h-screen bg-black bg-opacity-10 ${
					isModalOpen3 ? "" : "hidden"
				}`}
			>
				<div className="relative p-4 w-full max-w-lg">
					<div className="relative bg-[#E4F6FF] rounded-lg shadow dark:bg-gray-700">
						<div className="p-4 md:p-5 space-y-4 max-h-96 overflow-y-auto scrollbar-hide">
							<p className="text-lg font-bold font-plusJakartaSans">
								Are you sure you want to delete? If you delete your All
								Activities will also be deleted
							</p>
							<div className="flex items-end justify-end">
								<button
									className="flex align-center items-center justify-center w-[] mt-2 bg-[#FFDDCE] text-black font-medium rounded-lg text-sm px-5 py-2.5 text-center"
									onClick={toggleModal3}
								>
									<p className="font-plusJakartaSans text-sm font-semibold">
										No
									</p>
									<VscClose className="ml-1" />
								</button>
								<button
									className="flex align-center items-center justify-center ml-2 w-[] mt-2 bg-[#F8D865] text-black font-medium rounded-lg text-sm px-5 py-2.5 text-center"
									onClick={handleDelete}
								>
									<p className="font-plusJakartaSans text-sm font-semibold">
										Yes
									</p>
									<IoIosArrowRoundForward className="ml-1" />
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default DeleteMileStoneModal;
