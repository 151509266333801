import React from 'react'
import Text from '../../Text'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Menu } from '@mui/material';

type Props = {
    title?: string | JSX.Element | JSX.Element[],
    menu?: JSX.Element | JSX.Element[],
    isMenuOpen?: boolean,
    dropIconClass?: string,
}

const DrowDown2 = ({title, menu, dropIconClass}: Props) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event?: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event?.currentTarget || null);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    return (
        <div>
            <Text onClick={handleClick} className='flex items-end text-white text-textFont-primary'>
                {title}
                <ArrowDropDownIcon className={dropIconClass} />
            </Text>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                'aria-labelledby': 'basic-button',
                }}
            >
                {menu}
            </Menu>
        </div>
    )
}

export default DrowDown2