import { useState } from "react";
import CalendarContent from "./calenderContent";
import Chip from "./chip";

const CompleteSessionRight = () => {
    const [selectedChip, setSelectedChip] = useState(null);

    const handleChipClick = (date) => {
      setSelectedChip(date);
    };
  
	return (
		<div className="leading-6">
			<h1 className="text-xl font-black text-2xl">Problem</h1>
			<p
				className="mt-3 text-sm font-semibold"
				style={{ color: "#181818BC", width: "85%" }}
			>
				Indian Institute of Technology, Delhi or IIT Delhi is an Institute of
				Eminence offering about 102 undergraduate, postgraduate, integrated, and
				doctoral degree courses. Indian Institute of Technology, Delhi courses
				include B.Tech,M.Tech,M.Sc and Ph.d in various streams of Engineering
			</p>
			<h1 className="text-xl font-black text-2xl mt-5">Solution</h1>
			<p
				className="mt-3 text-sm font-semibold"
				style={{ color: "#181818BC", width: "85%" }}
			>
				Indian Institute of Technology, Delhi or IIT Delhi is an Institute of
				Eminence offering about 102 undergraduate, postgraduate, integrated, and
				doctoral degree courses. Indian Institute of Technology, Delhi courses
				include B.Tech,M.Tech,M.Sc and Ph.d in various streams of Engineering
			</p>
			<h1 className="text-xl font-black text-2xl mt-5">Suggestion By Mentor</h1>
			<div className="mt-3 3">
      <Chip
        date="25 Nov 2023"
        selected={selectedChip === '25 Nov 2023'}
        onClick={() => handleChipClick('25 Nov 2023')}
      />
      <Chip
        date="24 Nov 2024"
        selected={selectedChip === '24 Nov 2024'}
        onClick={() => handleChipClick('24 Nov 2024')}
      />
    </div>
			<CalendarContent />
		</div>
	);
};

export default CompleteSessionRight;
