import { medias } from "../../../config/Resources";

const NewsletterSubscribe = () => {
	return (
		<div
			className="pl-6 pr-6 pb-8 pt-14 rounded-[20px] shadow-md mx-auto"
			style={{
				backgroundImage: `url(${medias.images.Blogs.Bg1})`,
				backgroundPosition: "center",
			}}
		>
			<h2 className="text-[29px] font-extrabold font-plusJakartaSans mb-4 text-black">
				Subscribe To Our News Letter
			</h2>
			<form className="flex flex-wrap gap-6">
				<div className="relative min-w-[256px]">
					<input
						type="email"
						placeholder="Email"
						className="w-full p-[20px] pl-[45px] text-[18px] rounded-xl border font-plusJakartaSans font-regular text-[#000000] text-opacity-50 border-gray-300 bg-white"
					/>
					<img
						src={medias.images.Blogs.Email}
						alt="Email"
						className="absolute top-1/2 left-[15px] transform -translate-y-1/2 w-[22px] h-[22px]"
					/>
				</div>
				<div className="relative min-w-[256px]">
					<input
						type="tel"
						placeholder="mobile number"
						className="w-full p-[20px] pl-[43px] text-[#000000] text-opacity-50 text-[18px] font-plusJakartaSans font-regular rounded-xl border border-gray-300 bg-white"
					/>
					<img
						src={medias.images.Blogs.Phone}
						alt="Email"
						className="absolute top-1/2 left-[15px] transform -translate-y-1/2 w-[22px] h-[22px]"
					/>
				</div>
				<div className="min-w-[256px]">
					<div className="relative inline-block w-full text-gray-700">
						<select className="w-full text-[18px] h-[68px] font-plusJakartaSans font-regular pl-[45px] pr-6 text-base placeholder-gray-600 border rounded-xl appearance-none focus:shadow-outline">
							<option>Option 1</option>
							<option>Option 2</option>
							<option>Option 3</option>
						</select>
						<div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
							<svg className="w-4 h-4 fill-current" viewBox="0 0 20 20">
								<path d="M7 10l5 5 5-5H7z" />
							</svg>
						</div>
						<img
							src={medias.images.Blogs.Course}
							alt="Email"
							className="absolute top-1/2 left-[15px] transform -translate-y-1/2 w-[22px] h-[22px]"
						/>
					</div>
				</div>
				<div>
					<button
						type="submit"
						className="bg-[#239403] text-[18px] pt-[20px] pl-[20px] pb-[20px] pr-[20px] w-[170px] font-plusJakartaSans font-semibold text-white rounded-xl hover:bg-green-600 transition duration-300"
					>
						Submit
					</button>
				</div>
			</form>
		</div>
	);
};

export default NewsletterSubscribe;
