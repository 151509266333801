/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { Modal } from "@mui/material";
import moment from "moment";
import { toast } from "react-toastify";

import { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { EastRounded, Close } from "@mui/icons-material";
import CalendarSession from "./CalendarSession";
import { FaStar } from "react-icons/fa";
import Line from "../../Pages/Profile/SessionSchedule/Line";
import { API_URLS } from "../../config/API_URLS";
import axiosInstance from "../../config/Axios/axiosInstance";
import { useUserInfoStore } from "../../store/userinfo";
import { useNavigate } from "react-router-dom";

const Style = {
  slot: " my-1 flex flex-wrap justify-start gap-x-4 w-full",
};

const BookingNote = [
  "Connect via phone,chat or Video call.",
  "Free Follow-up session within 5 days",
  "Included chat support for 5 days",
  "Unigoal Support Manager",
];

const ProfileCard = ({ mentorData }) => {
  return (
    <>
      <div className="flex px-4 py-6">
        <img
          src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&amp;ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTV8fGh1bWFufGVufDB8fDB8fA%3D%3D&amp;auto=format&amp;fit=crop&amp;w=800&amp;q=60"
          alt="Laptop"
          className="w-[154px] h-[157px] rounded-md object-cover"
        />
        <div className="pl-2">
          <h1 className="text-lg font-semibold flex capitalize">
            Dr. {mentorData?.firstName} {mentorData?.lastName}{" "}
            <span className="text-sm h-6 w-6"></span>
          </h1>

          <h6 className="mt-1 font-medium text-[12px]">Designation</h6>
          <p className="mt-1 text-sm font-semibold">
            {mentorData?.current_job || "N/A"}
          </p>
          <div className="mt-4 flex flex-wrap text-center">
            <div className="flex justify-center items-center text-center gap-2 bg-[#F2F2F2] rounded-[6px] p-[6px] ">
              <FaStar className="text-[#F0D41B] text-[16px]" />
              <span className="text-[#000000] font-bold">4.5</span>
            </div>
            <h5 className="text-[#239403] underline underline-offset-4 m-2">
              35k+ Reviews
            </h5>
          </div>
        </div>
      </div>
    </>
  );
};

const SlotNotAvailable = () => {
  return (
    <div className="text-center w-full flex justify-center items-center my-5">
      {" "}
      <h1>No Time Slot Available.</h1>
    </div>
  );
};

const SlotTime = ({
  slotSelected,
  slotTimeData,
  setSelectedSlot,
  slotSelectedTime,
  setSelectedTime,
  id,
}) => {
  function displayFormattedTime(time) {
    const [hours, minutes] = time.split(":");
    const formattedHours = parseInt(hours, 10) % 12 || 12;
    const period = parseInt(hours, 10) >= 12 ? "PM" : "AM";

    return `${formattedHours}:${minutes} ${period}`;
  }

  return (
    <>
      <p
        title={
          slotSelected === slotTimeData?.schedule_id
            ? "Selected Slot"
            : slotTimeData?.availability == "0"
            ? "Booked Slot"
            : slotTimeData?.session_status == "Requested"
            ? " Requested Slot"
            : "Available Slot"
        }
        onClick={() => {
          setSelectedSlot(slotTimeData?.schedule_id);
          setSelectedTime(displayFormattedTime(slotTimeData?.start_time));
        }}
        className={`  my-4 ${
          slotSelected === slotTimeData?.schedule_id
            ? "bg-gray-200 text-green-600 border-green-600"
            : slotTimeData?.availability == "0"
            ? " border-[white]  bg-[#2DBBE9] text-[white]"
            : slotTimeData?.session_status == "Requested"
            ? " border-[#ff000033]  bg-[#ff000033] text-white "
            : " border-[#2771E7]  bg-white text-[#2771E7]"
        }   px-8 py-2 rounded-[10px] w-fit cursor-pointer border-[1px] border-solid`}
      >
        {displayFormattedTime(slotTimeData?.start_time)}
      </p>
      {/* {slotSelected === slotTimeData?.schedule_id && (
        <p
          className={` -ml-6  my-4 border-[#2771E7] border-[1px] border-solid bg-[#2771E7] text-white  text-center px-5 py-2 rounded-[10px] w-fit cursor-pointer`}
        >
          Confirm
        </p>
      )} */}
    </>
  );
};

const BookSessionPopup = ({
  modal,
  setModal,
  mentorData,
  slide,
  setSlide,
  setActive,
}) => {
  const [slotSelected, setSelectedSlot] = useState(null);
  const [slotSelectedTime, setSelectedTime] = useState("");

  const [highlightedDays, setHighlightedDays] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(moment().month() + 1);
  const [currentYear, setCurrentyear] = useState(moment().format("yyyy"));
  const { userInfo } = useUserInfoStore((state) => state);
  const [sessionValue, setSessionValue] = useState(0);

  const [currentDate, setCurrentDate] = useState(moment());
  const [slotData, setSlotData] = useState({
    morning: [],
    afternoon: [],
    evening: [],
  });

  const navigate = useNavigate();

  const BookSession = async () => {
    if (sessionValue != 0) {
      try {
        const res = await axiosInstance.post(API_URLS?.requestSession, {
          mentor_id: JSON.stringify(mentorData?.id),
          schedule_id: JSON.stringify(slotSelected),
        });

        if (res.statusCode === 200) {
          toast(res?.statusMessage || "Slot Booked Successfully", {
            type: "success",
          });
          closeSession(2);
        } else if (res.statusCode === 400) {
          console.log("error", res);
          toast.info(res?.statusMessage || "Some thing went wrong");
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      toast.error(
        "Your current plan doesn't have enough quota to book this session"
      );
      setTimeout(() => {
        toast.info(" Please upgrade to continue.");
        navigate("/profile/career-counselling?location=plans");
      }, 3000);
    }
  };

  const closeSession = (tab = 1) => {
    setActive(tab);
    setModal(false);
    setSlide(1);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    (async () => {
      if (mentorData?.id) {
        try {
          const res = await axiosInstance.get(
            API_URLS?.getMentorAvailableDates +
              `?mentor_id=${mentorData?.id}&month=${currentMonth}&year=${currentYear}`
          );

          // console.log(res);
          if (res.statusCode === 200) {
            const dayArray = res?.result.map((item) => {
              const date = new Date(item?.schedule_date);
              return date.getDate();
            });

            console.log(dayArray);

            setHighlightedDays(dayArray);

            // setMentorList(res?.result);
          } else if (res.statusCode === 400) {
            setHighlightedDays([]);
          }
          // console.log(res);
          // setLoading(false);
        } catch (error) {
          console.log(error);
          // setLoading(false);
        }
      }
    })();
  }, [mentorData?.id, currentMonth]);

  function categorizeScheduleData(data) {
    const categorizedData = {
      morning: [],
      afternoon: [],
      evening: [],
    };

    data.forEach((schedule) => {
      const startTime = parseInt(schedule?.start_time.split(":")[0]);

      if (startTime >= 0 && startTime < 12) {
        categorizedData.morning.push(schedule);
      } else if (startTime >= 12 && startTime < 18) {
        categorizedData.afternoon.push(schedule);
      } else if (startTime >= 18 && startTime < 24) {
        categorizedData.evening.push(schedule);
      }
    });

    return categorizedData;
  }

  useEffect(() => {
    (async () => {
      try {
        if (mentorData?.id) {
          const res = await axiosInstance.get(
            API_URLS?.getMentorAvailableTime +
              `?mentor_id=${mentorData?.id}&schedule_date=${currentDate.format(
                "YYYY-MM-DD"
              )}`
          );
          if (res.statusCode === 200) {
            console.log(res);
            const data = categorizeScheduleData(res?.result);
            console.log(data);
            setSlotData(data);
          } else if (res.statusCode === 400) {
            setSlotData({
              morning: [],
              afternoon: [],
              evening: [],
            });

            console.log(res);
          }
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [currentDate, mentorData?.id]);

  useEffect(() => {
    (async () => {
      try {
        const res = await axiosInstance.get(
          API_URLS.getActivePlan + `?class=${userInfo?.class}`
        );
        if (res?.statusCode === 201) {
          // setActivePlan(false);
        } else if (res?.statusCode === 200) {
          const benefitObj = res?.result?.benefits;
          setSessionValue(benefitObj["One to One Counselling"]);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  // console.log("One to One Counselling", sessionValue);

  return (
    <div>
      {" "}
      <Modal
        open={modal}
        // onClose={handleClose}
        className="flex items-center !bg-[#000000CC] opacity-100 justify-center !border-none  "
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className=" rounded-3xl     bg-[#F3F6FF]  w-[80%]  !border-none overflow-hidden">
          {slide === 1 && (
            <div
              className="flex  h-fit  "
              data-aos="fade-left"
              data-aos-anchor="#example-anchor"
              data-aos-offset="500"
              data-aos-duration="500"
            >
              <div className="w-[35%]  h-full  border-r border-solid border-[#DDE0E8] mr-10 ">
                <div className="flex flex-col  h-[100%]">
                  <div className="h-[30%]">
                    <ProfileCard mentorData={mentorData} />
                  </div>
                  <div className="h-[30%] px-4 py-2 border-t  border-b border-solid border-[#DDE0E8]">
                    <p className="text-[12px] text-[#1B1B1B]">
                      Session Duration
                    </p>
                    <p
                      className={` bg-opacity-20 text-[18px]  my-2  bg-[#FDACDF]   text-center px-5 py-2 rounded-[10px] w-fit`}
                    >
                      60 Minutes
                    </p>
                  </div>

                  <div className="h-[40%] px-4 py-2">
                    <div className="text-[13px] text-[#1B1B1B]">
                      Select time and Date
                    </div>
                    <div className="flex flex-col !justify-start">
                      <CalendarSession
                        setHighlightedDays={setHighlightedDays}
                        highlightedDays={highlightedDays}
                        setCurrentMonth={setCurrentMonth}
                        setCurrentyear={setCurrentyear}
                        setCurrentDate={setCurrentDate}
                        currentDate={currentDate}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-[65%] h-full  ">
                <div>
                  <div className="flex justify-between items-center ">
                    <p className="text-[20px] py-6 font-semibold">
                      {" "}
                      {currentDate?.format("dddd, D MMM")}
                      <div className="text-[16px] font-normal flex items-center gap-x-4">
                        <div className="flex  gap-x-2 items-center text-center">
                          <div className="bg-[#2DBBE9] w-4 h-4"></div>{" "}
                          <div>Booked Slot</div>
                        </div>
                        <div className="flex  gap-x-2 items-center text-center">
                          <div className="bg-[#ff000033] w-4 h-4"></div>{" "}
                          <div>Requested Slot</div>
                        </div>{" "}
                        <div className="flex  gap-x-2 items-center text-center">
                          <div className="bg-gray-200 w-4 h-4"></div>{" "}
                          <div>Selected Slot</div>
                        </div>{" "}
                        <div className="flex  gap-x-2 items-center text-center">
                          <div className="bg-white  w-4 h-4"></div>{" "}
                          <div>Available Slot</div>
                        </div>
                      </div>
                    </p>
                    <Close
                      className="text-[#DDE0E8] -mt-2 text-[40px]  pr-2 cursor-pointer "
                      onClick={() => {
                        setModal(false);
                      }}
                    />
                  </div>

                  <>
                    <div>
                      <div className="flex items-center text-center gap-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22.052"
                          height="22.052"
                          viewBox="0 0 22.052 22.052"
                        >
                          <g
                            id="sun_1_"
                            data-name="sun (1)"
                            transform="translate(1 1)"
                          >
                            <circle
                              id="Ellipse_768"
                              data-name="Ellipse 768"
                              cx="4.557"
                              cy="4.557"
                              r="4.557"
                              transform="translate(5.469 5.469)"
                              fill="none"
                              stroke="#e2cd0e"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                            />
                            <path
                              id="Path_31763"
                              data-name="Path 31763"
                              d="M10.026,0V1.823m0,16.406v1.823M2.935,2.935,4.229,4.229M15.823,15.823l1.294,1.294M0,10.026H1.823m16.406,0h1.823M2.935,17.117l1.294-1.294M15.823,4.229l1.294-1.294"
                              transform="translate(0)"
                              fill="none"
                              stroke="#e2cd0e"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              fill-rule="evenodd"
                            />
                          </g>
                        </svg>
                        <p>Morning</p>
                      </div>
                      <div className={Style?.slot}>
                        {slotData?.morning?.length >= 1 ? (
                          slotData?.morning?.map((slotTimeData, i) => (
                            <SlotTime
                              slotTimeData={slotTimeData}
                              slotSelected={slotSelected}
                              setSelectedSlot={setSelectedSlot}
                              slotSelectedTime={slotSelectedTime}
                              setSelectedTime={setSelectedTime}
                              id={i}
                            />
                          ))
                        ) : (
                          <>
                            <SlotNotAvailable />
                          </>
                        )}
                      </div>
                    </div>
                  </>

                  <>
                    <div>
                      <div className="flex items-center text-center gap-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="29.167"
                          height="29.167"
                          viewBox="0 0 29.167 29.167"
                        >
                          <g
                            id="Sunrise_2"
                            data-name="Sunrise 2"
                            transform="translate(0)"
                          >
                            <rect
                              id="Rectangle_4454"
                              data-name="Rectangle 4454"
                              width="29.167"
                              height="29.167"
                              transform="translate(0)"
                              fill="#ec9605"
                              opacity="0"
                            />
                            <path
                              id="Path_31764"
                              data-name="Path 31764"
                              d="M3,23.646h7.9L14.849,20,18.8,23.646h7.9"
                              transform="translate(-0.266 -1.771)"
                              fill="none"
                              stroke="#ec9605"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                            />
                            <path
                              id="Path_31765"
                              data-name="Path 31765"
                              d="M10,18.469a5.469,5.469,0,1,1,10.938,0"
                              transform="translate(-0.886 -1.151)"
                              fill="none"
                              stroke="#ec9605"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                            />
                            <line
                              id="Line_371"
                              data-name="Line 371"
                              x1="1.932"
                              y2="1.932"
                              transform="translate(21.027 8.941)"
                              fill="none"
                              stroke="#ec9605"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                            />
                            <line
                              id="Line_372"
                              data-name="Line 372"
                              y2="2.734"
                              transform="translate(14.583 5.469)"
                              fill="none"
                              stroke="#ec9605"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                            />
                            <line
                              id="Line_373"
                              data-name="Line 373"
                              x2="1.932"
                              y2="1.932"
                              transform="translate(6.207 8.941)"
                              fill="none"
                              stroke="#ec9605"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                            />
                            <line
                              id="Line_374"
                              data-name="Line 374"
                              x1="2.734"
                              transform="translate(23.698 17.318)"
                              fill="none"
                              stroke="#ec9605"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                            />
                            <line
                              id="Line_375"
                              data-name="Line 375"
                              x1="2.734"
                              transform="translate(2.734 17.318)"
                              fill="none"
                              stroke="#ec9605"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                            />
                          </g>
                        </svg>

                        <p>Afternoon</p>
                      </div>
                      <div className={Style?.slot}>
                        {slotData?.afternoon?.length >= 1 ? (
                          slotData?.afternoon?.map((slotTimeData, i) => (
                            <SlotTime
                              slotTimeData={slotTimeData}
                              slotSelected={slotSelected}
                              setSelectedSlot={setSelectedSlot}
                              slotSelectedTime={slotSelectedTime}
                              setSelectedTime={setSelectedTime}
                              id={i}
                            />
                          ))
                        ) : (
                          <>
                            <SlotNotAvailable />
                          </>
                        )}
                      </div>{" "}
                    </div>
                  </>
                  <>
                    <div>
                      <div className="flex items-center text-center gap-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22.94"
                          height="22.779"
                          viewBox="0 0 22.94 22.779"
                        >
                          <g
                            id="Layer_2"
                            data-name="Layer 2"
                            transform="translate(0)"
                          >
                            <g
                              id="Layer_1"
                              data-name="Layer 1"
                              transform="translate(0)"
                            >
                              <path
                                id="Subtraction_2"
                                data-name="Subtraction 2"
                                d="M3.755,4.818A11.862,11.862,0,0,0,3.544,7,11.724,11.724,0,0,0,19.151,18.084,10.821,10.821,0,0,1,18,19.168,10.182,10.182,0,0,1,1.66,13.349a10.328,10.328,0,0,1,.276-5.135,10.182,10.182,0,0,1,1.818-3.36M7.391.491A11.637,11.637,0,0,0,.235,13.6a12.03,12.03,0,0,0,.676,2.182,11.636,11.636,0,0,0,8.364,6.88,11.52,11.52,0,0,0,2.342.233h0a11.585,11.585,0,0,0,7.273-2.575,11.818,11.818,0,0,0,2.567-2.895,11.535,11.535,0,0,0,1.491-3.593A10.262,10.262,0,1,1,7.4.44Z"
                                transform="translate(-0.008 -0.12)"
                                fill="#0081c0"
                              />
                              <path
                                id="Path_31766"
                                data-name="Path 31766"
                                d="M20.161,3.92,21.434,6.5l2.858.415L22.226,8.931l.487,2.844-2.553-1.345-2.553,1.345L18.1,8.931,16.03,6.916,18.881,6.5Z"
                                transform="translate(-4.377 -1.069)"
                                fill="#0081c0"
                              />
                              <path
                                id="Path_31767"
                                data-name="Path 31767"
                                d="M28.669,2.08l.473.967,1.069.153-.771.749.182,1.062-.953-.5-.96.5.182-1.062L27.12,3.2l1.069-.153Z"
                                transform="translate(-7.402 -0.567)"
                                fill="#0081c0"
                              />
                              <path
                                id="Path_31768"
                                data-name="Path 31768"
                                d="M16.652,0l.48.967,1.062.153-.771.756L17.6,2.938l-.953-.5-.953.5.182-1.062L15.11,1.12,16.179.967Z"
                                transform="translate(-4.126)"
                                fill="#0081c0"
                              />
                            </g>
                          </g>
                        </svg>
                        <p>Evening</p>
                      </div>
                      <div className={Style?.slot}>
                        {slotData?.evening?.length >= 1 ? (
                          slotData?.evening?.map((slotTimeData, i) => (
                            <SlotTime
                              slotTimeData={slotTimeData}
                              slotSelected={slotSelected}
                              setSelectedSlot={setSelectedSlot}
                              slotSelectedTime={slotSelectedTime}
                              setSelectedTime={setSelectedTime}
                              id={i}
                            />
                          ))
                        ) : (
                          <>
                            <SlotNotAvailable />
                          </>
                        )}
                      </div>{" "}
                    </div>
                  </>
                  <Button
                    onClick={() => setSlide(2)}
                    disabled={!slotSelected}
                    variant="contained"
                    className="border disabled:cursor-not-allowed mb-5 py-[19px] flex gap-[6.2px] miniDesk:!px-[2px] text-[19px] font-medium capitalize !text-[#000000] !bg-[#F0BC5E] 3xl:py-[10px]  3xl:px-3 3xl:text-[13px] 3xl:h-fit !shadow-none px-[28px] border-[#239403] rounded-[9px]"
                  >
                    Request For Session{" "}
                    <EastRounded className="3xl:text-[16px] miniDesk:hidden" />
                  </Button>
                </div>
              </div>
            </div>
          )}
          {slide === 2 && (
            <div
              className="flex w-full  h-fit  "
              data-aos="fade-left"
              data-aos-anchor="#example-anchor"
              data-aos-offset="500"
              data-aos-duration="500"
            >
              <div>
                <div className="flex w-full justify-between p-4 ">
                  <h1 className="font-bold text-[22px]">
                    Confirm Booking Session
                  </h1>
                  <Close
                    className="text-[#DDE0E8] -mt-2 text-[40px]  pr-2 cursor-pointer "
                    onClick={() => {
                      closeSession();
                    }}
                  />{" "}
                </div>
                <div className=" w-[100%] flex mb-16">
                  <div className="w-[60%]  ">
                    <div className="bg-white  rounded-xl m-4 w-[90%]">
                      <div className=" px-10 py-6 text-[#5bcafa] ">
                        <div className="flex justify-between">
                          <h4>
                            {" "}
                            <span className="text-black">On </span>{" "}
                            {currentDate?.format("dddd, D MMM")}
                          </h4>
                          <h4>
                            {" "}
                            <span className="text-black">At</span>{" "}
                            {slotSelectedTime}
                          </h4>
                        </div>
                        <h6
                          onClick={() => setSlide(1)}
                          className="text-[13px] text-[#f7b880] mt-1 cursor-pointer"
                        >
                          Change Date & Time
                        </h6>
                      </div>

                      <Line />
                      <div className="px-6">
                        {" "}
                        <ProfileCard mentorData={mentorData} />
                      </div>
                    </div>
                  </div>
                  <div className="w-[40%] ">
                    <div className="bg-white p-6 rounded-xl m-4 w-[90%]">
                      <div className="flex justify-between items-center text-center text-[#B1CCF7] ">
                        <p
                          className={` bg-opacity-20 text-[18px]  my-2 text-black  bg-[#FDACDF]   text-center px-5 py-2 rounded-[10px] w-fit`}
                        >
                          60 Minutes
                        </p>{" "}
                        <h4 className="text-[#239403] font-bold text-[20px]">
                          {/* $ 130 */}
                        </h4>
                      </div>

                      <div className="my-2">
                        {BookingNote?.map((ele, i) => {
                          return (
                            <div key={i} className="flex my-3 text-center">
                              {" "}
                              <span className="mr-2">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="21.304"
                                  height="21.129"
                                  viewBox="0 0 21.304 21.129"
                                >
                                  <path
                                    id="Path_1553"
                                    data-name="Path 1553"
                                    d="M20.712,10.627a2.065,2.065,0,0,1-.349-2.421A2.065,2.065,0,0,0,19,5.22a2.063,2.063,0,0,1-1.6-1.847A2.065,2.065,0,0,0,14.636,1.6,2.064,2.064,0,0,1,12.291.91a2.065,2.065,0,0,0-3.283,0,2.058,2.058,0,0,1-2.342.69A2.064,2.064,0,0,0,3.9,3.374,2.063,2.063,0,0,1,2.3,5.221,2.063,2.063,0,0,0,.94,8.206a2.067,2.067,0,0,1-.348,2.421,2.062,2.062,0,0,0,.467,3.249A2.063,2.063,0,0,1,2.074,16.1a2.064,2.064,0,0,0,2.149,2.48A2.065,2.065,0,0,1,6.28,19.9a2.064,2.064,0,0,0,3.149.925,2.061,2.061,0,0,1,2.445,0,2.065,2.065,0,0,0,3.15-.925,2.062,2.062,0,0,1,2.056-1.322,2.064,2.064,0,0,0,2.149-2.48,2.064,2.064,0,0,1,1.016-2.223,2.064,2.064,0,0,0,.467-3.249Zm-11.17,4.82L5.548,11.573,7.2,9.925l2.346,2.224,5.009-5.134L16.2,8.664Z"
                                    transform="translate(0.001 -0.098)"
                                    fill="#239403"
                                  />
                                </svg>
                              </span>
                              {ele}
                            </div>
                          );
                        })}
                      </div>
                      <div className="w-full flex justify-center items-center text-center my-2">
                        <Button
                          onClick={() => BookSession()}
                          variant="contained"
                          className="border  mb-5 py-[19px] flex gap-[6.2px] miniDesk:!px-[2px] text-[19px] font-semibold capitalize !text-[#000000] !bg-[#F0BC5E] 3xl:py-[10px]  3xl:px-3 3xl:text-[13px] 3xl:h-fit !shadow-none px-[28px] border-[#239403] rounded-[9px]"
                        >
                          Proceed Your Booking{" "}
                          <EastRounded className="3xl:text-[16px] miniDesk:hidden" />
                        </Button>
                      </div>
                      <h3 className="text-[13px]">
                        {" "}
                        We understand that counselling is a recurring process,
                        that is why we have recommended you session packages
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default BookSessionPopup;
