/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import Lottie from "lottie-react";
import groovyWalkAnimation from "../../../../animations/roadmap/Animation - 1720078862954.json";
import support from "../../../../animations/roadmap/Button Press.json";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axiosInstance from "../../../../config/Axios/axiosInstance";
import { API_URLS } from "../../../../config/API_URLS";
import { useUserInfoStore } from "../../../../store/userinfo";

const arrDaignol = ["", "", "", "", "", "", "", "", "", "", "", "", ""];
const dropData = ["Academic Roadmap", "Extra Curricular", "Passion", "Health"];
const Academic = () => {
  const { userInfo } = useUserInfoStore((state) => state);
  const [selectedMap, setSelectedMap] = useState("Academic Roadmap");
  const [goalData, setGoalData] = useState([]);
  const [selectedGoal, setSelectedGoal] = useState("1");

  const navigate = useNavigate();

  useEffect(() => {
    getGoal();
  }, [selectedMap]);

  const getGoal = async (searchId) => {
    setGoalData([]);
    let url =
      selectedMap == dropData[0]
        ? "/pb/getAcademicRoadmap"
        : selectedMap == dropData[1]
        ? "/pb/getExtraCurricularRoadmap"
        : selectedMap == dropData[2]
        ? "/getPassionRoadmap"
        : selectedMap == dropData[3]
        ? "/pb/getHealthRoadmap"
        : "/pb/getAcademicRoadmap";

    const res = await axiosInstance.get(
      API_URLS.baseURL + url + `?type=${""}&searchId=${""}`
    );
    if (res.statusCode === 200) {
      setGoalData(res?.result || []);
      setSelectedGoal(
        res?.result && selectedMap == dropData[0]
          ? res?.result[0]?.academic_id
          : selectedMap == dropData[1]
          ? res?.result[0]?.extra_curricular_id
          : selectedMap == dropData[2]
          ? res?.result[0]?.passion_id
          : selectedMap == dropData[3]
          ? res?.result[0]?.health_id
          : res?.result[0]?.academic_id
      );
    } else {
      setGoalData([]);
    }
    console.log(res);
  };

  return (
    <div className="academic_bg_1 bg-cover 1500:h-screen 1240:h-[calc(100vh+150px)] h-screen flex flex-col justify-between w-screen pt-10">
      {/* topbar */}
      <div className="h-[40%]">
        <div className=" 1240:h-[82px] 1500:h-[100px] academic_wood_1 bg-cover w-[100%] pt-10">
          <div className="flex justify-center items-center  mt-[-70px] ">
            <div className="academic_side_top 1240:ml-[100px] 1500:ml-0 1240:mt-[4px] 1500:mt-0 1240:w-[438px] 1240:h-[63px] 1500:w-[600px] 1500:h-[80px] bg-cover">
              <div className="text-white cursor-pointer mt-5 1240:mt-3 1440:mt-4 1500:mt-5 flex justify-center gap-x-2 1240:text-[18px]  1440:text-[24px] 1500:text-[30px]  font-bold ">
                {" "}
                <select
                  className="w-[45%] bg-transparent text-center rounded-lg focus-visible:border-none focus-visible:outline-none !border-none"
                  onChange={(e) => {
                    setSelectedMap(e.target.value);
                  }}
                >
                  {dropData?.map((drop, i) => {
                    return (
                      <option
                        key={i}
                        className="bg-[#DE8E39] text-[20px]"
                        value={drop}
                      >
                        {drop}
                      </option>
                    );
                  })}
                </select>
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/unigoal-15ff4.appspot.com/o/Path%20334423.svg?alt=media&token=11e31103-485f-406b-8175-d20b06002b39"
                  alt=""
                />
              </div>
            </div>
          </div>

          <div className="flex mt-[-38px]">
            <div className="flex justify-between px-20 1240:px-10 1366:px-12  mt-14 1500:px-20 1240:mt-4 1500:mt-2 w-full">
              <div>
                <div className="flex gap-x-3 ml-4">
                  <div className="w-12 h-12 rounded-full border-white border-solid border-[1px] bg-cyan-600">
                    <img src="" alt="" />
                  </div>
                  <div className="w-72 h-12 rounded-full  !shadow-[0px_4px_0px_0px_rgba(0,0,0,1)] bg-[#f4ee90]  uppercase text-[23px] font-bold text-center my-auto flex flex-col justify-center items-center">
                    <h6 className=""> Daily Activity Planer</h6>
                  </div>
                  <div className="px-4 h-12 rounded-full border-white border-solid border-[1px] bg-white flex justify-evenly">
                    <img
                      className="scale-75"
                      src="https://firebasestorage.googleapis.com/v0/b/unigoal-15ff4.appspot.com/o/Group%20413414.svg?alt=media&token=4bf39622-6436-4d62-b7af-7cdaa6fc0dcf"
                      alt=""
                    />
                    <img
                      className="scale-75"
                      src="https://firebasestorage.googleapis.com/v0/b/unigoal-15ff4.appspot.com/o/Group%20414170.svg?alt=media&token=b67a62fb-40e8-4c0c-b9e8-1c487d137fee"
                      alt=""
                    />
                    <img
                      className="scale-75"
                      src="https://firebasestorage.googleapis.com/v0/b/unigoal-15ff4.appspot.com/o/Group%20413410.svg?alt=media&token=80e27a45-c984-4f44-9ad7-c86994cb61ef"
                      alt=""
                    />
                  </div>
                </div>
                <div className="flex h-32 w-[50%] gap-x-8">
                  <div className="flex justify-end items-end">
                    <div className="flex justify-end  bg-cover w-[82px] h-[120px] wood1  ">
                      <div className="mt-[60px] w-full mx-4">
                        <img
                          src="https://firebasestorage.googleapis.com/v0/b/unigoal-15ff4.appspot.com/o/Group%20413401%402x.png?alt=media&token=a58b0126-95e1-4b7b-b5d1-508f3d0691fc"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-end items-end">
                    <div className="flex justify-end  bg-cover w-[170px] h-[120px] wood3  ">
                      <div className="mt-[60px] w-full flex justify-center">
                        <div className="w-[65%] mt-1.5  h-9 rounded-3xl  text-[#775436]  border-white border-solid border-[1px] bg-white mb-4  font-bold text-center flex flex-col justify-center items-center">
                          {" "}
                          01:15PM
                        </div>
                      </div>
                    </div>
                  </div>{" "}
                  <div className="flex justify-end items-end">
                    <div className="flex justify-end  bg-cover w-[170px] h-[120px] wood3  ">
                      <div className="mt-[60px] w-full mx-4">
                        <div className="w-full mt-1.5  h-9 rounded-3xl text-[#775436]   border-white border-solid border-[1px] bg-white mb-4  font-bold text-center flex flex-col justify-center items-center">
                          01/03/2024
                        </div>
                      </div>
                    </div>
                  </div>{" "}
                  <div className="flex justify-end items-end">
                    <div className="flex justify-end  bg-cover w-[82px] h-[120px] wood1  ">
                      <div className="mt-[60px] w-full mx-4">
                        <img
                          src="https://firebasestorage.googleapis.com/v0/b/unigoal-15ff4.appspot.com/o/Group%20413408%402x.png?alt=media&token=db29d3a8-3c81-4346-ad38-f4a55ca4fc68"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  {/* <img className="w-[120px]" src={wood3} alt="" />
                  <img className="w-[180px]" src={wood4} alt="" />
                  <img className="w-[80px]" src={wood5} alt="" /> */}
                </div>
              </div>
              <div className="w-[33%] ">
                <div className="flex items-end justify-end ">
                  <div className="w-12 h-12 rounded-full ">
                    <Lottie
                      className="cursor-pointer "
                      animationData={groovyWalkAnimation}
                      loop={true}
                    />
                  </div>
                  <div className="w-12 h-12 rounded-full ml-8  ">
                    <img
                      className="cursor-pointer"
                      src="https://firebasestorage.googleapis.com/v0/b/unigoal-15ff4.appspot.com/o/Group%20413503%402x.png?alt=media&token=764ef9f0-dcac-4551-8fd6-af7bb4cfd9e6"
                      alt=""
                    />
                  </div>

                  <div className="w-64  h-10  rounded-full  relative uppercase text-[23px] font-bold text-center  flex flex-col justify-center items-center">
                    <Lottie
                      className="cursor-pointer absolute"
                      animationData={support}
                      loop={true}
                    />

                    <h5 className="text-white text-2xl relative top-0 cursor-pointer">
                      Support
                    </h5>
                  </div>

                  <div className="w-12 h-12 rounded-full">
                    <img
                      onClick={() => navigate(-1)}
                      className="scale-75 cursor-pointer"
                      src="https://firebasestorage.googleapis.com/v0/b/unigoal-15ff4.appspot.com/o/Group%20413517.svg?alt=media&token=57a1c0d8-a313-4757-9dd7-cb75234f9f2e"
                      alt=""
                    />
                  </div>
                </div>

                <div className="flex justify-end items-end">
                  <div className="flex justify-end  bg-cover w-[298px] h-[244px] academic_side_wood  ">
                    <div className="mt-[60px] w-full mx-4">
                      <h6 className="text-white text-[20px] font-bold">
                        Goal:
                      </h6>
                      <div className="w-full mt-1.5  h-9 rounded-lg   border-white border-solid border-[1px] bg-white mb-4  font-bold text-center flex flex-col justify-center items-center">
                        <select
                          className="w-full bg-white focus-visible:border-none focus-visible:outline-none"
                          onChange={(e) => {
                            setSelectedGoal(e.target.value);
                          }}
                        >
                          {goalData?.map((goal, i) => {
                            return (
                              <option
                                className="text-center"
                                key={i}
                                value={
                                  goal?.academic_id ||
                                  goal?.passion_id ||
                                  goal?.extra_curricular_id ||
                                  goal?.health_id
                                }
                              >
                                {goal?.main_goal ||
                                  goal?.category ||
                                  goal?.category_type}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="flex justify-between ">
                        <div>
                          <h6 className="text-white text-[17px] ">
                            Total 4 Milestone
                          </h6>
                          <h6 className="text-white text-[17px] ">17 Tasks</h6>
                          <h6 className="text-white text-[17px] ">
                            17 Sub Tasks
                          </h6>
                        </div>
                        <div className="flex flex-col justify-end items-end">
                          <img
                            src="https://firebasestorage.googleapis.com/v0/b/unigoal-15ff4.appspot.com/o/Layer%201.svg?alt=media&token=8003ddf9-894d-443f-863d-fd967fe6e0e6"
                            alt=""
                          />
                          <h6 className="text-white text-[16px]  cursor-pointer">
                            View
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* center */}
      <div className="h-[60%] flex flex-col justify-end">
        <div className="flex justify-end items-center  pr-20">
          <div className=" h-[320px] w-[40%] bg-white rounded-3xl pt-3 border-gray-500 border-solid border-[1px]">
            <div className="flex justify-around items-center w-full overflow-hidden">
              <img
                className="w-[8%] cursor-pointer"
                src="https://firebasestorage.googleapis.com/v0/b/unigoal-15ff4.appspot.com/o/Layer%202.svg?alt=media&token=77efb1cc-1a0e-470a-bab0-d3acf924ead3"
                alt=""
              />
              <img
                className="w-[70%]"
                src="https://firebasestorage.googleapis.com/v0/b/unigoal-15ff4.appspot.com/o/Group%20413516.svg?alt=media&token=4749ebb2-7c9c-454c-bc59-a61f6a5b8f30"
                alt=""
              />
              <img
                className="w-[8%] cursor-pointer"
                src="https://firebasestorage.googleapis.com/v0/b/unigoal-15ff4.appspot.com/o/Group%20413517.svg?alt=media&token=57a1c0d8-a313-4757-9dd7-cb75234f9f2e"
                alt=""
              />
            </div>
            <div className="w-full h-full mt-3 flex justify-evenly overflow-hidden">
              <div className="w-[45%] p-2 h-[72%] bg-[#D5EDFF] rounded-2xl">
                <div>
                  <h3 className="text-[22px] font-bold">Total Milestone 4</h3>
                </div>
              </div>
              <div className="w-[45%] px-6 h-[72%] bg-[#D5EDFF] rounded-2xl">
                <div className=" mt-2 h-12 rounded-full  border-white border-solid border-[1px] bg-white mb-3  font-bold text-center my-auto flex flex-col justify-center items-center">
                  {" "}
                  <h6 className="1500:text-[20px]  1240:text-[15px]   font-bold ">
                    Creation: 14 March 2024
                  </h6>
                </div>
                <div className="1500:text-[20px]  1240:text-[15px]  font-bold  flex justify-between">
                  <h4>Mentor:</h4>
                  <h4>@SachinRasel</h4>
                </div>
                <div className="1500:text-[20px]  1240:text-[15px] font-bold  flex justify-between">
                  <h4>Co-Mentor::</h4>
                  <h4>@Dipika.f</h4>
                </div>
                <div className="1500:text-[20px]  1240:text-[15px] font-bold  flex justify-between">
                  <h4>Time Start:</h4>
                  <h4>11:00 AM</h4>
                </div>
                <div className="1500:text-[20px]  1240:text-[15px] font-bold  flex justify-between">
                  <h4>Duration End:</h4>
                  <h4>31 Dec 2024</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* bottom */}
      <div className="h-[20%]">
        <div className="mb-3">
          <div className="flex justify-between  px-[5%]">
            <div className="w-[20%]">
              <div className="h-20"></div>

              <div className="w-full h-20 rounded-full   bg-[#96A7FF]  border-[#00178E] border-solid border-[2px] text-white   text-[23px] font-bold text-center my-auto flex flex-col justify-center items-center">
                <h6 className="cursor-pointer">Progress Tracker</h6>
              </div>
            </div>
            <div className="w-[50%]">
              <div className="w-56 h-14 rounded-full  border-white border-solid border-[1px] bg-white mb-4  font-bold text-center my-auto flex flex-col justify-center items-center">
                {" "}
                <h6 className="text-[22px] font-bold "> Progress 3/9</h6>
              </div>
              <div className="w-full h-20 rounded-full   bg-[#00178E] bg-opacity-40 px-2   text-white   text-[23px] font-bold text-center my-auto flex flex-col justify-center items-start">
                <div className="bg-gradient-to-r from-indigo-700 to-indigo-500  rounded-3xl w-[70%] h-[52px] flex justify-around">
                  {arrDaignol.map((ele, i) => {
                    return (
                      <div
                        key={i}
                        className="flex justify-center items-center gap-x-2 "
                      >
                        <div className="h-full w-[1.5px] bg-white rotate-[26deg] bg-opacity-75"></div>
                        {arrDaignol.length - 1 == i && (
                          <div className="bg-transparent rounded-3xl px-10  border-white border-solid border-[4px]">
                            <h6>70%</h6>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>{" "}
            </div>

            <div className="w-[20%]">
              <div className="h-20"></div>
              <div className="w-full h-20 cursor-pointer rounded-full   bg-[#96A7FF]  border-[#000000] border-solid border-[2px] text-white   text-[23px] font-bold text-center my-auto flex flex-col justify-center items-center">
                <Link to={`/roadmap/${selectedMap}/view/${selectedGoal}`}>
                  {" "}
                  <h6 className="">Preview Roadmap</h6>
                </Link>
              </div>{" "}
            </div>

            <div className="w-[6%]">
              <div className="h-20"></div>

              <div className="w-full flex justify-center ">
                <div className="h-20 w-20 rounded-full   bg-[#F78E24]  border-[#000000] border-solid border-[2px] text-white   text-[23px] font-bold text-center my-auto flex flex-col justify-center items-center">
                  <img
                    className=" w-16 h-16 p-2  cursor-pointer"
                    src="https://firebasestorage.googleapis.com/v0/b/unigoal-15ff4.appspot.com/o/Group%20413554%402x.png?alt=media&token=db5aa3f0-db16-43f2-af10-c631c0f282bc"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Academic;
