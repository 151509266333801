import React from "react";
import { medias } from "../../../../config/Resources";

function RankingYear({ selectedYear, setSelectedYear }) {
	const currentYear = new Date().getFullYear();
	const startYear = 2018;
	const years = [];

	for (let year = startYear; year <= currentYear; year++) {
		years.push(year);
	}

	const handleChange = (year) => {
		setSelectedYear(year);
	};

	return (
		<div>
			<div className="pl-4 pr-4">
				<div className="tracking-wide text-[12px] font-bold pt-4 flex justify-between">
					<h6 className="font-plusJakartaSans flex text-center items-center gap-2">
						<img
							className="w-10 h-10"
							src={medias.images.College.Eligibility}
							alt="courses"
						/>
						Year
					</h6>
				</div>
				<div className="flex flex-wrap gap-1 mt-2 mb-3">
					{years.map((chip) => (
						<p
							key={chip}
							onClick={() => handleChange(chip)}
							className={`font-plusJakartaSans rounded-full border pt-1 pb-1 pl-4 pr-4 inline-flex text-[12px] text-center items-center gap-1 font-medium cursor-pointer ${
								selectedYear === chip
									? "bg-[#2771E7] border-[#2771E7] text-white"
									: "border-[#D1D1D1] text-[#000000]"
							}`}
						>
							{chip}
						</p>
					))}
				</div>
			</div>
		</div>
	);
}

export default RankingYear;
