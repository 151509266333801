import { RxCross1 } from "react-icons/rx";
import axiosInstance from "../../config/Axios/axiosInstance";
import { API_URLS } from "../../config/API_URLS";
import { useState } from "react";
import { toast } from "react-toastify";

export default function CreateNewActivityModal({
	showModal,
	toggleModal,
	setShowModal,
	activeTab,
}) {
	const [data, setData] = useState({
		activityName: "",
		date: "",
		startTime: "",
		endTime: "",
		status: "0",
		comment: "comment",
	});

	const handelInput = (e) => {
		const { name, value } = e.target;
		setData({
			...data,
			[name]: value,
		});
	};

	const api = {
		1: `${API_URLS.baseURL}/pb/addDailyActSchAcademic`,
		2: `${API_URLS.baseURL}/pb/addDailyActSchSports`,
		3: `${API_URLS.baseURL}/pb/addDailyActSchMeals`,
		4: `${API_URLS.baseURL}/pb/addDailyActSchHousehold`,
		5: `${API_URLS.baseURL}/pb/addDailyActSchOthers`,
	};

	const createActivity = async (event) => {
		event.preventDefault();

		try {
			let url = api[activeTab];
			const res = await axiosInstance.post(`${url}`, data);
			console.log(res, "allresponse");
			if (res) {
				toast("activity is created successfully", {
					type: "success",
				});
				setShowModal(false);
				setData("");
			}
			console.log(res, "response");
		} catch (error) {
			console.log(error);
		}
	};

	console.log(data, "allData");

	return (
		<>
			{showModal ? (
				<>
					<div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
						<div className="relative my-6 w-[35%] ">
							<div className="border-0 rounded-[20px] shadow-lg relative flex flex-col w-full bg-[#EEF1FA] outline-none focus:outline-none">
								<div className="relative pl-6 pr-6 pb-6 pt-3 flex-auto">
									<div>
										<p className="font-plusJakartaSans text-[black] font-bold text-lg mt-5 mb-4">
											Create New Activity
										</p>
									</div>
									<div>
										<input
											type="text"
											name="activityName"
											value={data.activityName}
											onChange={handelInput}
											placeholder="Activity List Name"
											className="text-sm font-medium font-plusJakartaSans w-full rounded-md pt-3 pb-3 pl-5 pr-2 mt-1 focus:outline-none focus:ring focus:ring-blue-200"
										/>
									</div>
									<div>
										<input
											type="date"
											name="date"
											value={data.date}
											onChange={handelInput}
											placeholder="date"
											className="text-sm font-medium font-plusJakartaSans rounded-md pt-3 pb-3 pl-5 pr-2 mt-3 focus:outline-none focus:ring focus:ring-blue-200"
										/>
									</div>
									<div className="flex justify-between items-center mt-2 w-[100%]">
										<input
											type="time"
											name="startTime"
											value={data.startTime}
											onChange={handelInput}
											placeholder="Start Time"
											className="w-[43%] rounded-md px-3 text-sm font-medium font-plusJakartaSans pt-3 pb-3 pl-5 pr-2 mt-3 focus:outline-none focus:ring focus:ring-blue-200"
										/>
										<input
											type="time"
											name="endTime"
											value={data.endTime}
											onChange={handelInput}
											placeholder="End Time"
											className="w-[43%] text-sm font-medium font-plusJakartaSans rounded-md pt-3 pb-3 pl-5 pr-2 mt-3 focus:outline-none focus:ring focus:ring-blue-200"
										/>
									</div>
									<div className="border-b border-gray-300 mt-5 mb-3"></div>
									<div className="flex justify-end mt-5 mb-2">
										<button
											onClick={() => toggleModal()}
											className="font-plusJakartaSans font-semibold text-[#239403] rounded-md px-4 py-2 mr-4 border-[2px] border-[#239403] focus:outline-none"
										>
											Discard
										</button>
										<button
											onClick={createActivity}
											className="bg-[#239403] font-plusJakartaSans font-semibold text-white rounded-md px-4 py-2 focus:outline-none"
										>
											Submit
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div
						className="opacity-25 fixed inset-0 z-40 bg-black"
						onClick={toggleModal}
					></div>
				</>
			) : null}
		</>
	);
}
