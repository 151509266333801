import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { medias } from "../../../config/Resources";
import { GoDotFill } from "react-icons/go";
import { useNavigate } from "react-router-dom";

const BlogsCardsCarousels = ({ blogsData }) => {
	const navigate = useNavigate();

	console.log(blogsData, "AllBlogData");

	const formatDate = (dateString) => {
		const options = { day: "numeric", month: "long", year: "numeric" };
		const date = new Date(dateString);
		return new Intl.DateTimeFormat("en-GB", options).format(date);
	};
	return (
		<div className="relative mt-2">
			<Carousel
				showArrows={false}
				showStatus={false}
				showThumbs={false}
				autoPlay={true}
				interval={3000}
				infiniteLoop={true}
				renderIndicator={(onClickHandler, isSelected, index, label) => {
					const indicatorStyle = isSelected ? "bg-green-500" : "bg-gray-300";
					return (
						<div
							className={`inline-block w-8 h-1 mx-1 cursor-pointer ${indicatorStyle}`}
							onClick={onClickHandler}
							key={index}
							aria-label={`${label} ${index + 1}`}
							style={{ marginTop: "10px" }}
						></div>
					);
				}}
			>
				{blogsData?.featured?.map((data, _index) => {
					return (
						<div
							onClick={() => navigate(`/blog-details/${data?.id}`)}
							className="rounded-[20px] border border-[#707070] cursor-pointer border-opacity-20 overflow-hidden pt-[18px] pb-[18px] pl-[23px] pr-[14px] mb-8 mt-[1%] mr-[1%]"
						>
							<div className="flex w-[100%] gap-4">
								<div className="w-[53%]">
									<div className="flex flex-wrap gap-[10px] mb-2">
										{data?.tags?.split(",")?.map((chip) => (
											<p
												key={chip.id}
												className="font-plusJakartaSans rounded-full bg-[#F37400] bg-opacity-10 pt-[7px] pb-[7px] pl-4 pr-4 inline-flex text-[13px] text-center items-center gap-1 font-semibold cursor-pointer"
											>
												{chip?.trim()}
											</p>
										))}
									</div>

									<p className="mt-2 font-plusJakartaSans text-start leading-[42px] font-bold text-[#000000] text-[27px] ">
										{data?.title}
									</p>
									<div className="flex items-center text-center mt-2 gap-2">
										<div>
											<img
												className="rounded-full pictures"
												src={data?.author_image}
												alt="authorImage"
											/>
										</div>
										<p className="font-plusJakartaSans text-[14px] font-semibold">
											{data?.author_name}
										</p>
										<GoDotFill className="text-[#000000] text-opacity-40" />
										<p className="font-plusJakartaSans text-[14px] font-semibold">
											{data?.time_taken}
										</p>
										<GoDotFill className="text-[#000000] text-opacity-40" />
										<p className="font-plusJakartaSans text-[14px] font-semibold">
											{formatDate(data?.created_at)}
										</p>
									</div>
									<p
										dangerouslySetInnerHTML={{
											__html: data?.summary,
										}}
										className="font-plusJakartaSans text-start leading-[25px] mt-2 text-[13.3px] text-[#000000] font-medium text-opacity-[79%] line-clamp-2"
									></p>
								</div>
								<div className="w-[47%] relative flex justify-end">
									<img
										className="w-64 h-[230px] rounded-[14px] object-cover"
										src={data?.cover_image}
										alt="University"
									/>
									<div className="absolute bottom-0 left-[15px] w-[50%] bg-black bg-opacity-20 text-white p-2 flex items-center text-center gap-8">
										<div className="flex items-center text-center gap-2">
											<img
												className="w-4 h-4"
												src={medias.images.Blogs.HumanEye}
												alt="HumanEye"
											/>
											<p className="font-plusJakartaSans text-[14px] font-medium">
												12K
											</p>
										</div>
										<div className="flex items-center text-center gap-2">
											<img
												className="w-4 h-4"
												src={medias.images.Blogs.Chat1}
												alt="HumanEye"
											/>
											<p className="font-plusJakartaSans text-[14px] font-medium">
												333
											</p>
										</div>
										<div className="flex items-center text-center gap-2">
											<img
												className="w-4 h-4"
												src={medias.images.Blogs.Share}
												alt="HumanEye"
											/>
											<p className="font-plusJakartaSans text-[14px] font-medium">
												1K
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					);
				})}
			</Carousel>
		</div>
	);
};

export default BlogsCardsCarousels;
