/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { PiStarFourFill } from "react-icons/pi";
import { MdVerified } from "react-icons/md";
import { MdInfoOutline } from "react-icons/md";
import { TiStarFullOutline } from "react-icons/ti";
import { medias } from "../../../../config/Resources";
import WriteReviewsAndBlogs from "./WriteReviewsAndBlogs";
import AllReviews from "./AllReviews";
import LikeAndReport from "./LikeAndReport";

const StudentsRatings = () => {
	const CardsDetails = [
		{
			id: 1,
			image: medias.images.College.Aca,
			Text1: "8.1/10",
			Text2: "Academics",
		},
		{
			id: 2,
			image: medias.images.College.Accom,
			Text1: "8.1/10",
			Text2: "Accomodation",
		},
		{
			id: 3,
			image: medias.images.College.Teacher,
			Text1: "8.1/10",
			Text2: "Accomodation",
		},
		{
			id: 4,
			image: medias.images.College.Infrastructur1e,
			Text1: "8.1/10",
			Text2: "Infrastructure",
		},
		{
			id: 5,
			image: medias.images.College.Suitcase,
			Text1: "8.7/10",
			Text2: "Placement",
		},
		{
			id: 6,
			image: medias.images.College.Network,
			Text1: "8.1/10",
			Text2: "Sociallife",
		},
	];

	const progressBarData = [
		{ id: 1, label: "5", value: 80 },
		{ id: 2, label: "4", value: 70 },
		{ id: 3, label: "3", value: 50 },
		{ id: 4, label: "2", value: 30 },
		{ id: 5, label: "1", value: 10 },
	];

	const reviews = [{}, {}, {}, {}];

	return (
		<div className="w-full rounded-xl overflow-hidden shadow-lg mt-5 bg-white">
			<div className="p-6 flex">
				<div className="w-1/2">
					<p className=" flex item-center text-center text-black ">
						<img
							className="w-9 h-9 rounded-md bg-[#2771E7] bg-opacity-5 p-1"
							src={medias.images.College.Thumb}
							alt="Thumb"
						/>
						<span className="pt-[6px] pl-1 font-plusJakartaSans font-bold text-[15px]">
							Students Ratings & Reviews
						</span>
					</p>
					<p className="flex items-center text-center gap-1 text-black mt-1">
						<TiStarFullOutline className="text-[#FFC000] mt-2 h-8 w-8" />
						<span className="text-[40px] font-plusJakartaSans font-bold">
							4.5
						</span>
						<span className="text-[25px] font-plusJakartaSans font-bold mt-4">
							/5
						</span>
					</p>
					<div className="flex items-center gap-1 text-center ml-1 mt-1">
						<p className="font-plusJakartaSans opacity-50 font-bold text-[13px]">
							443 Verified Reviews
						</p>
						<MdInfoOutline className="h-5 w-5 opacity-50" />
					</div>
				</div>
				<div className="w-1/2 space-y-2">
					{progressBarData.map((item) => (
						<div key={item.id} className="flex items-center">
							<TiStarFullOutline className="text-[#FFC000] h-3 w-3" />
							<span className="font-semibold font-plusJakartaSans text-[12px] text-[#000000]">
								{item.label}
							</span>
							<div className="w-full bg-gray-300 ml-2 rounded-full h-2">
								<div
									className="bg-black h-2 rounded-full"
									style={{ width: `${item.value}%` }}
								></div>
							</div>
							{/* <span className="font-semibold text-[#000000] text-[14px] opacity-70 font-plusJakartaSans">{item.value}%</span> */}
						</div>
					))}
					<span className="font-semibold text-[#7D7D7D] flex text-end justify-end items-end text-[12px] font-plusJakartaSans">
						350
					</span>
				</div>
			</div>
			<div className="border border-b ml-4 mr-4"></div>
			<div className="p-6 flex w-[100%]">
				<div className="w-[25%]">
					<p className=" flex item-center text-center text-black ">
						<img
							className="w-9 h-9 rounded-md bg-[#ffffff] shadow-custom1 p-1"
							src={medias.images.College.Logo11}
							alt="Logo11"
						/>
						<span className="pt-[6px] pl-1 font-plusJakartaSans font-bold text-[15px]">
							IIT Delhi Ratings
						</span>
					</p>
					<p className="flex items-center text-center gap-1 text-black mt-1">
						<PiStarFourFill className="text-[#FFC000] mt-2 h-8 w-8" />
						<span className="text-[40px] font-plusJakartaSans font-bold">
							8.7
						</span>
						<span className="text-[25px] font-plusJakartaSans font-bold mt-4">
							/10
						</span>
					</p>
				</div>
				<div className="w-[85%] flex ml-5 flex-wrap">
					{CardsDetails.map((item, index) => (
						<div className="flex items-center gap-2 w-1/3" key={index}>
							<div className="bg-[#BEBEBE] bg-opacity-20 p-2 rounded-full">
								<img className="w-7 h-7" src={item.image} alt="image" />
							</div>
							<div>
								<p className="font-plusJakartaSans text-[#000000] font-bold text-[12px]">
									{item.Text1}
								</p>
								<p className="font-plusJakartaSans text-[#000000] text-opacity-80 font-medium text-[12px]">
									{item.Text2}
								</p>
							</div>
						</div>
					))}
				</div>
				<div className="w-[5%]">
					<p className="font-plusJakartaSans pt-3 font-bold text-[#7D7D7D] text-[10px]">
						78
					</p>
					<p className="font-plusJakartaSans pt-2 font-bold text-[#7D7D7D] text-[10px]">
						45
					</p>
					<p className="font-plusJakartaSans pt-2 font-bold text-[#7D7D7D] text-[10px]">
						8
					</p>
					<p className="font-plusJakartaSans pt-2 font-bold text-[#7D7D7D] text-[10px]">
						2
					</p>
				</div>
			</div>

			<WriteReviewsAndBlogs />
			{reviews.map(() => {
				return (
					<>
						<AllReviews />
						<div className="border border-b ml-4 mr-4 border-dashed mt-5 mb-5"></div>
						<LikeAndReport />
					</>
				);
			})}
		</div>
	);
};

export default StudentsRatings;
