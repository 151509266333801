/* eslint-disable jsx-a11y/anchor-is-valid */
import { medias } from "../../config/Resources";

function OnGoingPlatform() {
    const ongoingData = [
        {
            title: "Master in CSS",
            imageUrl: medias.images.Profile.Master,
            time: "Time 6:00AMIST",
            date: "02 Dec 2023",
            duration: "Duration 1.5 Hrs"
        },
        {
            title: "Master in CSS",
            imageUrl: medias.images.Profile.Master,
            time: "Time 6:00AMIST",
            date: "02 Dec 2023",
            duration: "Duration 1.5 Hrs"
        },
        {
            title: "Master in CSS",
            imageUrl: medias.images.Profile.Master,
            time: "Time 6:00AMIST",
            date: "02 Dec 2023",
            duration: "Duration 1.5 Hrs"
        },
        {
            title: "Master in CSS",
            imageUrl: medias.images.Profile.Master,
            time: "Time 6:00AMIST",
            date: "02 Dec 2023",
            duration: "Duration 1.5 Hrs"
        },
        {
            title: "Master in CSS",
            imageUrl: medias.images.Profile.Master,
            time: "Time 6:00AMIST",
            date: "02 Dec 2023",
            duration: "Duration 1.5 Hrs"
        },
    ];
	return (
        <><div className="flex justify-between tracking-wide text-sm  w-[91.59%] mt-4">
            <p className="font-plusJakartaSans font-bold">On going Platform Projects</p>
            <p className="font-plusJakartaSans font-bold text-[#2DB706]">View More</p>
        </div>
        <div className="flex gap-5 mt-3">
                {ongoingData?.map((items) => {
                    return (

                        <div className="w-[17%] bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                            <img
                                className="rounded-t-lg w-full p-2"
                                src={items.imageUrl}
                                alt="Master" />
                            <div className="ml-2 mt-2">
                                <h5 className="mb-2 text-[15px] font-plusJakartaSans font-bold tracking-tight text-gray-900 dark:text-white">
                                    {items.title}
                                </h5>
                            </div>
                            <div className="font-plusJakartaSans font-semibold text-xs mt-3 mb-5 ml-2 w-[60%]">
                                <p className="rounded-[20px] pl-[10px] pr-[10px] pt-[6px] pb-[6px]" style={{ border: '1px solid #B0B0B0' }}>{items.time}</p>
                            </div>
                            <div className="font-plusJakartaSans font-semibold text-xs mt-2 mb-5 ml-2 w-[60%]">
                                <p className="rounded-[20px] pl-[10px] pr-[10px] pt-[6px] pb-[6px]" style={{ border: '1px solid #B0B0B0' }}>{items.date}</p>
                            </div>
                            <div className="font-plusJakartaSans font-semibold text-xs mt-2 mb-8 ml-2 w-[60%]">
                                <p className="rounded-[20px] pl-[10px] pr-[10px] pt-[6px] pb-[6px]" style={{ border: '1px solid #B0B0B0' }}>{items.duration}</p>
                            </div>
                        </div>

                    );
                })}
            </div></>
	);
}

export default OnGoingPlatform;
