import React, { useState, useEffect } from "react";

function GalleryChips({ setGalleryChip }) {
	const selectedData = [
		{
			id: 1,
			text: "Campus life",
		},
		{
			id: 2,
			text: "Placements",
		},
	];

	const chipTextMapping = {
		1: "campus_life",
		2: "placement",
	};

	const [selectedId, setSelectedId] = useState(1);

	useEffect(() => {
		setGalleryChip("campus_life");
	}, [setGalleryChip]);

	const handleChipClick = (id) => {
		const newSelectedId = selectedId === id ? null : id;
		setSelectedId(newSelectedId);
		setGalleryChip(newSelectedId ? chipTextMapping[newSelectedId] : null);
	};

	return (
		<div>
			<div className="pl-5 pr-5 pt-5">
				<div className="flex flex-wrap gap-4 mt-2 mb-3">
					{selectedData.map((chip) => (
						<p
							key={chip.id}
							onClick={() => handleChipClick(chip.id)}
							className={`font-plusJakartaSans rounded-full border pt-1 pb-1 pl-4 pr-4 inline-flex text-[12px] text-center items-center gap-1 font-medium cursor-pointer ${
								selectedId === chip.id
									? "bg-[#F37400] border-[#F37400] text-white"
									: "border-[#F37400] text-[#F37400]"
							}`}
						>
							{chip.text}
						</p>
					))}
				</div>
			</div>
		</div>
	);
}

export default GalleryChips;
