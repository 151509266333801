import { medias } from "../../../config/Resources";

function Salaries() {
	const allSkills = [
		{
			experience: "0-1yrs",
			Salary: "Rs 1,12,000",
		},
		{
			experience: "1-3yrs",
			Salary: "Rs 1,12,000",
		},
		{
			experience: "3-5yrs",
			Salary: "Rs 1,12,000",
		},
		{
			experience: "5-8yrs",
			Salary: "Rs 1,12,000",
		},
		{
			experience: "8+ yrs",
			Salary: "Rs 1,12,000",
		},
	];
	return (
		<div className="w-auto rounded-[20px] ml-auto mr-auto overflow-hidden p-6 shadow-lg border border-gray-300 mt-10">
			<div className="flex items-center text-center">
				<svg
					className="h-8 w-8"
					xmlns="http://www.w3.org/2000/svg"
					width="49.773"
					height="36.327"
					viewBox="0 0 49.773 36.327"
				>
					<g id="money" transform="translate(0 -6.482)">
						<g
							id="Layer_1_copy"
							data-name="Layer 1 copy"
							transform="translate(1.037 7.521)"
						>
							<rect
								id="Rectangle_9601"
								data-name="Rectangle 9601"
								width="38.831"
								height="22.226"
								transform="translate(5.355 7.371) rotate(-10.943)"
								fill="#569f7d"
							/>
							<rect
								id="Rectangle_9602"
								data-name="Rectangle 9602"
								width="38.831"
								height="22.226"
								transform="matrix(0.995, -0.103, 0.103, 0.995, 2.452, 9.991)"
								fill="#60b18b"
							/>
							<rect
								id="Rectangle_9603"
								data-name="Rectangle 9603"
								width="38.831"
								height="22.226"
								transform="translate(0 12.025)"
								fill="#8ed8b5"
							/>
							<path
								id="Path_105173"
								data-name="Path 105173"
								d="M36.129,34.935a3.118,3.118,0,0,0-3.111,3.118H7.564a3.118,3.118,0,0,0-3.119-3.118v-9.7a3.119,3.119,0,0,0,3.119-3.119H33.018a3.118,3.118,0,0,0,3.111,3.119Z"
								transform="translate(-0.873 -6.944)"
								fill="#b1dfbc"
							/>
							<circle
								id="Ellipse_3063"
								data-name="Ellipse 3063"
								cx="4.679"
								cy="4.679"
								r="4.679"
								transform="translate(14.737 18.459)"
								fill="#8ed8b5"
							/>
							<path
								id="Path_105174"
								data-name="Path 105174"
								d="M42.646,9.855,46.483,29.7l2.026-.392L44.289,7.485,6.165,14.857l.383,1.978Z"
								transform="translate(-0.809 -7.484)"
								fill="#60b18b"
							/>
							<path
								id="Path_105175"
								data-name="Path 105175"
								d="M40.343,15.616l2.073,19.93,1.87-.194-2.3-22.107L3.364,17.263,3.59,19.44Z"
								transform="translate(-0.913 -7.271)"
								fill="#8ed8b5"
							/>
							<path
								id="Path_105176"
								data-name="Path 105176"
								d="M1,19.081v2.105H37.708V41.307h2.123V19.081Z"
								transform="translate(-1 -7.056)"
								fill="#b1dfbc"
							/>
							<path
								id="Path_105177"
								data-name="Path 105177"
								d="M5.3,24.99a3.1,3.1,0,0,1-.853.134v1.259A3.111,3.111,0,0,0,5.3,24.99ZM31.72,37.947h1.3a3.091,3.091,0,0,1,.141-.88,3.1,3.1,0,0,0-1.439.88Z"
								transform="translate(-0.873 -6.837)"
								fill="#8ed8b5"
							/>
							<path
								id="Path_105178"
								data-name="Path 105178"
								d="M32.936,22.111H7.483a3.1,3.1,0,0,1-.83,2.105H30.814a3.118,3.118,0,0,0,3.119,3.118V35.78a3.1,3.1,0,0,1,2.114-.845v-9.7A3.118,3.118,0,0,1,32.936,22.111Z"
								transform="translate(-0.791 -6.944)"
								fill="#c1ecd0"
							/>
							<path
								id="Path_105179"
								data-name="Path 105179"
								d="M19.872,25.286A4.666,4.666,0,0,0,15.7,27.878a4.621,4.621,0,0,1,2.044-.489,4.679,4.679,0,0,1,4.679,4.679,4.624,4.624,0,0,1-.511,2.084,4.666,4.666,0,0,0-2.044-8.868Z"
								transform="translate(-0.457 -6.827)"
								fill="#b1dfbc"
							/>
						</g>
						<g
							id="Layer_1_copy_2"
							data-name="Layer 1 copy 2"
							transform="translate(0 6.482)"
						>
							<path
								id="Path_105180"
								data-name="Path 105180"
								d="M39.867,42.381H1.037A1.037,1.037,0,0,1,0,41.344V19.118a1.037,1.037,0,0,1,1.037-1.037H39.867A1.037,1.037,0,0,1,40.9,19.118V41.344A1.037,1.037,0,0,1,39.867,42.381ZM2.074,40.307H38.831V20.155H2.074Z"
								transform="translate(0 -6.053)"
								fill="#1c1c1b"
							/>
							<path
								id="Path_105181"
								data-name="Path 105181"
								d="M33.055,39.127H7.6A1.037,1.037,0,0,1,6.564,38.09a2.084,2.084,0,0,0-2.082-2.081,1.037,1.037,0,0,1-1.037-1.037v-9.7A1.037,1.037,0,0,1,4.482,24.23a2.085,2.085,0,0,0,2.082-2.082A1.037,1.037,0,0,1,7.6,21.111H33.055a1.037,1.037,0,0,1,1.037,1.037,2.084,2.084,0,0,0,2.074,2.082A1.037,1.037,0,0,1,37.2,25.267v9.7a1.037,1.037,0,0,1-1.037,1.037,2.083,2.083,0,0,0-2.081,2.081,1.037,1.037,0,0,1-1.03,1.037ZM8.512,37.053H32.154a4.174,4.174,0,0,1,2.975-2.992V26.172a4.172,4.172,0,0,1-2.987-2.987H8.506a4.17,4.17,0,0,1-2.987,2.987v7.889a4.172,4.172,0,0,1,2.987,2.992Z"
								transform="translate(0.127 -5.941)"
								fill="#1c1c1b"
							/>
							<path
								id="Path_105182"
								data-name="Path 105182"
								d="M19.928,35.717A5.716,5.716,0,1,1,25.642,30,5.716,5.716,0,0,1,19.928,35.717Zm0-9.356A3.642,3.642,0,1,0,23.568,30,3.642,3.642,0,0,0,19.928,26.361Z"
								transform="translate(0.525 -5.824)"
								fill="#1c1c1b"
							/>
							<path
								id="Path_105183"
								data-name="Path 105183"
								d="M42.085,36.658a1.037,1.037,0,0,1-.1-2.069l1.2-.125L41.073,14.423l-33.382,3.5a1.037,1.037,0,0,1-.217-2.064l34.41-3.613a1.037,1.037,0,0,1,1.141.923l2.322,22.1a1.037,1.037,0,0,1-.923,1.141l-2.228.234C42.158,36.656,42.122,36.658,42.085,36.658Z"
								transform="translate(0.242 -6.269)"
								fill="#1c1c1b"
							/>
							<path
								id="Path_105184"
								data-name="Path 105184"
								d="M46.687,30.711a1.037,1.037,0,0,1-.195-2.055l.675-.13L43.37,8.735l-32.5,6.234a1.038,1.038,0,0,1-.391-2.04L44,6.5a1.037,1.037,0,0,1,1.213.822L49.4,29.152a1.037,1.037,0,0,1-.823,1.213l-1.692.325a1.062,1.062,0,0,1-.2.022Z"
								transform="translate(0.356 -6.482)"
								fill="#1c1c1b"
							/>
						</g>
					</g>
				</svg>

				<h6 className="font-plusJakartaSans font-bold text-lg ml-1">Salaries</h6>
			</div>
			<div className="relative overflow-x-auto mt-2">
				<table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
					<thead className="text-xs text-[#707070] camelcase bg-[#dce7f4] dark:bg-[#789DC9] dark:text-[#707070]">
						<tr>
							<th
								scope="col"
								className="px-6 py-3 rounded-s-lg font-plusJakartaSans font-semibold text-sm"
							>
								Product name
							</th>
							<th
								scope="col"
								className="px-6 py-3 rounded-e-lg font-plusJakartaSans font-semibold text-sm"
							>
								Price
							</th>
						</tr>
					</thead>
					<tbody className="">
						{allSkills.map((data) => {
							return (
								<tr className="shadow-md">
									<th
										scope="row"
										className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap font-plusJakartaSan text-sm dark:text-white "
									>
										{data.experience}
									</th>

									<td className="px-6 py-4 font-plusJakartaSans text-gray-900 font-medium text-sm">
										{data.Salary}
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
		</div>
	);
}

export default Salaries;
