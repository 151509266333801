/* eslint-disable jsx-a11y/img-redundant-alt */
import { medias } from "../../../../config/Resources";

const CourseCards = ({ course }) => {
	const data = [
		{
			id: 1,
			text: "Course Type",
			text1: course?.specializations?.map((data) => data?.course_type),
			image: medias.images.College.Cap3,
		},
		{
			id: 2,
			text: "Duration",
			text1: course?.specializations?.map((data) => data?.course_duration),
			image: medias.images.College.Clock,
		},
		{
			id: 3,
			text: "Number of Seats",
			text1: course?.specializations?.map((data) => data?.total_seats),
			image: medias.images.College.Clock,
		},
		{
			id: 4,
			text: "Total Fees(1stYear)",
			text1: course?.specializations?.map((data) => data?.total_fee),
			image: medias.images.College.Payment,
		},
	];
	return (
		<div className="ml-4 mr-4 pt-3 flex flex-wrap items-center text-center gap-2">
			{data.map((course, index) => {
				return (
					<div
						className="bg-white rounded-lg custom-shadow1 pl-2 pr-2 pt-5 pb-5 w-full  sm:w-[48%] lg:w-[24%] mb-4"
						key={index}
					>
						<div className="flex items-center gap-[5px]">
							<img src={course.image} className="w-5 h-5" alt="image" />
							<p className="font-medium font-plusJakartaSans text-[13px] text-[#000000] text-opacity-70">
								{course.text}
							</p>
						</div>
						<p className="font-plusJakartaSans text-start text-[18px] mt-[14px] font-semibold text-[#000000] text-opacity-100">
							{course.text1}
						</p>
					</div>
				);
			})}
		</div>
	);
};

export default CourseCards;
