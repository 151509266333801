import React from "react";
import Image from "../../Image";
import { guidancePageContant } from "../../../config/pageContent";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import Text from "../../Text";

const Face8 = () => {
  return (
    <div className="linearGradientbg px-[81px] flex gap-[81px] pt-[114px] pb-[108px]">
      <div className="w-1/2 ">
        <div>
          <Text className="text-[47px] font-black  PlusJakartaSans-ExtraBoldCustom ">
            {guidancePageContant.Face6.meetYourMentors}
          </Text>
          <Text className="text-black leading-[27px] mt-[18px] opacity-[69%] text-[16px] ">
            {guidancePageContant.Face6.describe}
          </Text>
        </div>
        <div className="flex bg-white w-fit my-10  rounded-full py-[12.33px]  px-[20.64px] gap-[39px]">
          <div className="!border-[#C2C2C2] rounded-full shadow-sm !border w-[47px] h-[47px] flex items-center justify-center">
            <KeyboardArrowLeft />
          </div>
          <div className="!border !border-[#C2C2C2] rounded-full w-[47px] h-[47px] flex items-center justify-center">
            <KeyboardArrowRight />
          </div>
        </div>
      </div>
      <div className="flex ml-auto gap-[90px]">
        {guidancePageContant.Face6.mentors.map((item) => {
          return (
            <div className="bg-[#FFFFFF] rounded-bl-[31px] rounded-t-[31px] shadow-[0px_-1px_64px_#0000000F] ">
              <div>
                <Image
                  src={item.image}
                  alt="MetaMentor"
                  className="max-w-[260px] max-h-[280px]"
                />
              </div>
              <div className="flex pt-[30px] pb-[20px] justify-between px-[22px] items-center">
                <div>
                  <div className="text-[#2771E7]  text-[23px] font-bold ">
                    {item.name}
                  </div>
                  <div className="text-[#000000] text-[17px] font-bold italic opacity-80 ">
                    -{item.occupation}{" "}
                  </div>
                </div>
                <div className="border border-[#DDDDDD] rounded-full h-[50px] w-[50px] flex items-center justify-center">
                  <Image src={item.logoImage} alt="metaLogo" />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Face8;
