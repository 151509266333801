import React from "react";
import Paper from "@material-ui/core/Paper";
import {
  Chart,
  Title,
  Legend,
  Tooltip,
  PieSeries
} from "@devexpress/dx-react-chart-material-ui";
import { EventTracker } from "@devexpress/dx-react-chart";

const CircularBar = () => {
  const data = [
    { argument: "Monday", value: 10 },
    { argument: "Tuesday", value: 40 },
    { argument: "Wednesday", value: 10 },
    { argument: "Thursday", value: 20 },
    { argument: "Friday", value: 20 },
  ];

  return (
    <Paper>
      <Chart data={data} className="circularBar">
        <PieSeries
          valueField="value"
          argumentField="argument"
          innerRadius={0.6}
        />
        <Legend />
        <EventTracker />
        <Tooltip />
      </Chart>
    </Paper>
  );
};

export default CircularBar;
