import { medias } from "../../../../config/Resources";

function ApplicationAndWebsiteLink({ EntranceExam }) {
	return (
		<div className="ml-4 mr-4">
			<h6 className="mt-5 font-plusJakartaSans flex items-center text-center gap-[6px] font-bold text-[#000000] text-[15px]">
				<img
					className="w-5 h-5"
					src={medias.images.Exam.Phone}
					alt="Registration"
				/>
				<span className="font-plusJakartaSans font-bold mt-[1px]">
					Application Link
				</span>
			</h6>
			<img
				className="w-40 text-[#000000] pt-1"
				src={medias.images.Profile.line1}
				alt="lin1"
			/>
			<div className=" mt-3">
				<p className="font-plusJakartaSans font-semibold text-[13px] text-[#2771E7]">
					{EntranceExam?.application_link}
				</p>
			</div>
			<h6 className="mt-4 font-plusJakartaSans flex items-center text-center gap-[6px] font-bold text-[#000000] text-[15px]">
				<img
					className="w-5 h-5"
					src={medias.images.Exam.Website}
					alt="Registration"
				/>
				<span className="font-plusJakartaSans font-bold mt-[1px]">
					Website Link :
				</span>
			</h6>
			<img
				className="w-40 text-[#000000] pt-1"
				src={medias.images.Profile.line1}
				alt="lin1"
			/>
			<div className=" mt-3">
				<p className="font-plusJakartaSans font-semibold text-[13px] text-[#2771E7]">
					{EntranceExam?.website}
				</p>
			</div>
		</div>
	);
}

export default ApplicationAndWebsiteLink;
