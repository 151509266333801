const FutureGoals = ({activeTab}) => {
    return (
        <div className="leading-6">
            <h1 className="text-xl font-normal font-quitery">About Goal</h1>
            <p className="mt-3 font-plusJakartaSans text-sm font-medium text-[#181818BC] w-[85%]">
                When an unknown printer took a gallery of type and Time scrambled it to
                make a type specimen book. It has survived not only five centuries, but
                also the leap into electronic typesetting, remaining essentially
                unchanged
            </p>
            <div className="mt-3 flex">
                <div>
                    <p className="text-sm font-bold font-plusJakartaSans text-[#707070]">
                        No. of Milestones
                    </p>
                    <p className="text-sm font-extrabold font-plusJakartaSans text-[#239403]">
                        20
                    </p>
                </div>
                <div className="ml-8">
                    <p className="text-sm font-bold font-plusJakartaSans text-[#707070]">
                        No. of tasks
                    </p>
                    <p className="text-sm font-extrabold font-plusJakartaSans text-[#239403]">
                        1,22
                    </p>
                </div>
            </div>
            <h1 className="text-xl font-normal mt-5 font-quitery">Main Focus</h1>
            <p className="mt-3 text-sm font-normal font-plusJakartaSans text-[#181818BC] w-85">
                When an unknown printer took a gallery of type and Time scrambled it to
                make a type specimen book. It has survived not only five centuries, but
                also the leap into electronic typesetting, remaining essentially
                unchanged
            </p>
            {
                activeTab === 2 ?
            <h1 className="text-xl font-normal mt-5 font-quitery">Current Academic Status</h1> :
            <h1 className="text-xl font-normal mt-5 font-quitery">Next Steps</h1> 
            }
            <p className="mt-3 text-sm font-normal font-plusJakartaSans text-[#181818BC] w-85">
                When an unknown printer took a gallery of type and Time scrambled it to
                make a type specimen book. It has survived not only five centuries, but
                also the leap into electronic typesetting, remaining essentially
                unchanged
            </p>
        </div>
    );
};

export default FutureGoals;
