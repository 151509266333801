import AgoraWebRtc from "../../Agora/Index";
import AgoraSdk from "../../Agora/indexSDK";
import Getstartedquiz from "../../Components/questions/Getstartedquiz";
import WebRTCFirebase from "../../FirebaseWebRTC/WebRTCFirebase";
import Careers from "../../Pages/Careers";
import CareerIndividualDetails from "../../Pages/Careers/CareerIndividualDetails";
import CollegDetailsPage from "../../Pages/Collages/CollegeDetails";
import Collages from "../../Pages/Collages/Index";
import Eleven from "../../Pages/Eleven";
import Graduate from "../../Pages/Graduate";
import Guidance from "../../Pages/Guidance";
import Home from "../../Pages/Home";
import LoginSignup from "../../Pages/LoginSignup";
import MentorDetails from "../../Pages/MentorListing/MentorDetails";
import MentorListing from "../../Pages/MentorListing/MentorListing";
import Mentorship from "../../Pages/Mentorship/Mentorship";
import NineTenthPlan from "../../Pages/NineTenthPlan";
import Profile from "../../Pages/Profile";
import Report from "../../Pages/Profile/Report";
import PsychomatricTest from "../../Pages/Psychomatrictest";
import WebRtcCall from "../../WebRtc/Main";
import WebRTCComponent from "../../WebRtcNative";
import WebRtcNew from "../../WebRtcNative";
import EntranceExam from "../../Pages/EntranceExam";
import BlogListing from "../../Pages/Blog";
import EntranceExamDetails from "../../Pages/EntranceExam/EntranceExamDetails";
import Academic from "../../Pages/Profile/Roadmap/Academic/Academic";
import Main from "../../Pages/Profile/Roadmap/Main";
import BlogsDetailsPage from "../../Pages/Blog/BlogDetailsPage";

export const RoutesLists: Array<{
  path: string;
  Element: (props: any) => JSX.Element;
  isHeader?: boolean;
  isFooter?: boolean;
  isPrivate?: boolean;
}> = [
  {
    path: "/guidance",
    Element: Guidance,
  },
  {
    path: "/graduate-and-working-professional",
    Element: Graduate,
  },
  {
    path: "/mentorship",
    Element: Mentorship,
  },
  {
    path: "/mentorship/find-the-best-mentor-of-your-choice",
    Element: MentorListing,
  },

  {
    path: "/mentorship/:name/:id/mentor-details",
    Element: MentorDetails,
  },
  {
    path: "/mentorship/mentor/call",
    Element: WebRtcCall,
    isHeader: false,
    isFooter: false,
  },
  {
    path: "/",
    Element: Home,
  },
  {
    path: "/sygnific_dev",
    Element: Home,
  },
  {
    path: "/get-started",
    Element: Getstartedquiz,
    isHeader: false,
    isFooter: false,
    isPrivate: true,
  },
  {
    path: "/ninth-tenth-plan",
    Element: NineTenthPlan,
  },
  {
    path: "/eleven-twelfth-plan",
    Element: Eleven,
  },
  {
    path: "/login-signup/:state",
    Element: LoginSignup,
    isHeader: false,
    isFooter: false,
  },
  {
    path: "/psychomatric-test",
    Element: PsychomatricTest,
    isHeader: false,
    isFooter: false,
    isPrivate: true,
  },
  {
    path: "/report",
    Element: Report,
    isHeader: false,
    isFooter: false,
    isPrivate: true,
  },
  {
    path: "/profile/:nav",
    Element: Profile,
    isHeader: false,
    isFooter: false,
    isPrivate: true,
  },
  {
    path: "/profile/:nav/:id",
    Element: Profile,
    isHeader: false,
    isFooter: false,
    isPrivate: true,
  },
  {
    path: "/career-list",
    Element: Careers,
    isFooter: false,
  },
  {
    path: "/exam-entrance-list",
    Element: EntranceExam,
    isFooter: false,
  },
  {
    path: "/blog-list",
    Element: BlogListing,
  },
  {
    path: "/blog-details/:id",
    Element: BlogsDetailsPage,
  },
  {
    path: "/career-details/:id",
    Element: CareerIndividualDetails,
    isFooter: false,
  },
  {
    path: "/entrance-exam-details/:id",
    Element: EntranceExamDetails,
    isFooter: false,
  },
  {
    path: "/college-details/:id",
    Element: CollegDetailsPage,
    isFooter: false,
  },
  {
    path: "/Colleges-list",
    Element: Collages,
  },

  {
    path: "/go-live",
    Element: AgoraWebRtc,
  },

  {
    path: "/go-live/sdk",
    Element: AgoraSdk,
  },

  {
    path: "/session-test",
    Element: WebRTCComponent,
  },
  {
    path: "/fire",
    Element: WebRTCFirebase,
  },

  {
    path: "/roadmap/type/:type",
    Element: Main,
    isHeader: false,
    isFooter: false,
    isPrivate: true,
  },

  {
    path: "/roadmap/:roadMapType/view/:goalId",
    Element: Main,
    isHeader: false,
    isFooter: false,
    isPrivate: true,
  },
];
