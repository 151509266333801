const BlogsSearchBar = () => {
	return (
		<div className="relative max-w-md">
			<div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
				<svg
					className="w-5 h-5 text-gray-500"
					xmlns="http://www.w3.org/2000/svg"
					fill="none"
					viewBox="0 0 24 24"
					stroke="currentColor"
				>
					<path
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth={2}
						d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
					/>
				</svg>
			</div>
			<input
				type="search"
				className="w-[18.5rem] pl-9 pr-3 py-[10px] text-[14px] font-plusJakartaSans font-medium rounded-lg border border-[#F2F2F2] bg-[#F2F2F2]"
				placeholder="Search"
			/>
		</div>
	);
};

export default BlogsSearchBar;
