import React from "react";
import { options } from "../../../../config/Constants";
import classNames from "classnames";
import Text from "../../../Text";
import Preview from "../../../Preview";
import { useMediaQuery } from "@mui/material";

const Options = ({
  selected = [],
  isApti = false,
  mainClass,
  optionContainerClass,
  total_question,
  isSubmitted,
  setIsSubmitted,
  optionClass,
  submitQuestion,
  questionInfo,
  currentQuestion,
  setCurrentQuestion,
  addQestion,
}: {
  selected?: Array<any>;
  isApti?: boolean;
  optionContainerClass?: string;
  isSubmitted?: boolean;
  mainClass?: string;
  setIsSubmitted: (data: boolean) => void;
  submitQuestion: () => void;
  total_question: number;
  optionClass?: string;
  questionInfo: any;
  addQestion: any;
  currentQuestion: number;
  setCurrentQuestion: (value: number) => void;
}) => {
  const miniDesk = useMediaQuery("(max-width : 1220px)");
  const Desktop = useMediaQuery("(max-width : 1440px)");
  const onClickHandler = (items: any) => {
    if (!isSubmitted) {
      if (isApti) {
        addQestion({ ...questionInfo, value: items?.value || items });
      } else {
        if (total_question > currentQuestion + 1) {
          addQestion({ ...questionInfo, value: items?.value || items });
          setCurrentQuestion(currentQuestion + 1);
        } else {
          addQestion({ ...questionInfo, value: items?.value || items });
          setIsSubmitted(true);
          submitQuestion();
        }
      }
    }
  };
  const curentOption = questionInfo?.options
    ? JSON.parse(questionInfo?.options)
    : options;
  return (
    <div
      className={classNames(
        "grid  justify-center w-full  grid-rows-2 mt-2 gap-7 max-w-[700px]",
        curentOption.length === 5
          ? `${
              curentOption.filter((items: any) => items.type === "file")
                .length > 0
                ? "!flex"
                : "grid-cols-6"
            }`
          : curentOption.filter((items: any) => items.type === "file").length >
            0
          ? "!flex"
          : "grid-cols-4",
        mainClass
      )}
    >
      {curentOption.map((items: any, index: any) => (
        <div
          className={classNames(
            index > 2 && curentOption.length === 5
              ? `col-span-2 ${index === 3 && "col-start-2"}`
              : "col-span-2",
            items?.type === "file" && curentOption.length < 5
              ? `col-span-1  col-start-${index}`
              : "",
            "flex  items-center  justify-center",
            optionContainerClass
          )}
        >
          <div
            role="button"
            onClick={(e) => {
              e.preventDefault();
              onClickHandler(items);
            }}
            className={classNames(
              "w-fit min-w-[180px] min-h-[60px]  cursor-pointer flex focused:!shadow-none overflow-auto text-ellipsis max-h-[150px] !items-center  bg-[#B745CD] shadow-[0px_10px_0px_0px_rgba(138,1,166,1)] active:relative  active:top-[10px] active:!shadow-none justify-center text-white   desktop:!min-w-[150px] font-semibold rounded-[8px]  text-start  px-[27px] py-[4px]",
              isApti &&
                (curentOption.length === 5 &&
                curentOption.filter((items: any) => items.type === "file")
                  .length > 0
                  ? `${
                      Desktop ? "!min-w-[120px] !w-[120px]" : "!min-w-[160px]"
                    }`
                  : "!min-w-[180px]"),
              curentOption.length === 5 &&
                curentOption.filter((items: any) => items.type === "file")
                  .length > 0
                ? "!min-w-[170px] !max-w-[170px]"
                : "",
              isApti &&
                (selected?.find((data: any) => {
                  return (
                    questionInfo?.id === data?.id &&
                    JSON.stringify(data?.answer) === JSON.stringify(items)
                  );
                })
                  ? "bg-[rgba(27,189,108,1)] !shadow-[0px_10px_0px_0px_rgba(0,125,63,1)]"
                  : "!bg-[#3F7CDA] !shadow-[0px_10px_0px_0px_rgba(1,62,158,1)]"),
              optionClass
            )}
          >
            {items?.type === "file" ? (
              <Preview
                imageClassOverride="object-contain !max-h-[150px] desktop:!max-h-[100px]"
                svgOption={`${
                  curentOption.length === 5 &&
                  curentOption.filter((items: any) => items.type === "file")
                    .length > 0
                    ? "svg5Options"
                    : "svgOption"
                } `}
                height={
                  miniDesk
                    ? 120
                    : curentOption.length === 5 &&
                      curentOption.filter((items: any) => items.type === "file")
                        .length > 0
                    ? 130
                    : Desktop
                    ? 120
                    : 190
                }
                width={
                  miniDesk
                    ? 120
                    : curentOption.length === 5 &&
                      curentOption.filter((items: any) => items.type === "file")
                        .length > 0
                    ? 150
                    : Desktop
                    ? 120
                    : 190
                }
                src={items?.option}
              />
            ) : (
              <Text className="font-ibmPlexMonoRegular text-[14px] miniDesk:text-[10px] desktop:!text-[12px]">
                {items.name || items?.option}
              </Text>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Options;
