/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { useUserInfoStore } from "../../../store/userinfo";
import Career from "../../../Components/CareerMapping/Career";
import CareerAnalysis from "../../../Components/CareerMapping/CareerAnalysis";
import CollageCard from "../../../Components/CareerMapping/CollageCard";
import { API_URLS } from "../../../config/API_URLS";
import axiosInstance from "../../../config/Axios/axiosInstance";
import { Box, CircularProgress } from "@mui/material";
import CareerAnalysisNine from "../../../Components/CareerMapping/CareerAnalysisNine";
import SelectDropdown from "../../../Components/DropDown/Select/SelectDropdown";
import { ExploreCard } from "../SessionSchedule/ExploreCard";
import BookSessionPopup from "../../../Components/BookSessionPopup/BookSessionPopup";

const GroupCard = ({ groupIndex, item, setSelectedGroup, selectedGroup }) => {
  return (
    <div
      className="flex cursor-pointer group"
      onClick={() => setSelectedGroup(groupIndex)}
    >
      <div
        className={` bg-white relative z-30 ${
          groupIndex === selectedGroup
            ? " !border-[3.5px]"
            : "hover:border-blue-500"
        } text-blue-600 h-11 w-11 font-bold justify-center text-[20px] flex text-center items-center border-blue-600 hover:border-blue-500 border-solid group-hover:border-[3.5px] border-[1.5px] rounded-full`}
      >
        0{item?.Rank}
      </div>

      <div
        className={` ${
          groupIndex === selectedGroup
            ? "!bg-blue-600 !text-white !border-[3.5px] "
            : " hover:border-blue-500"
        } group-hover:bg-blue-500 bg-white  -ml-3 rounded-3xl text-center text-nowrap  h-11 w-fit px-8   border-blue-600 border-solid group-hover:border-[3.5px] border-[1.5px] flex justify-center items-center group-hover:text-white text-blue-600 `}
      >
        {" "}
        {item?.groupSecondName || item?.careerGroupName}
      </div>
    </div>
  );
};

const tabs = [
  { name: "Description", id: 1 },
  { name: "Career Analysis", id: 2 },
  { name: "Top Colleges", id: 3 },
  { name: "Entrance Exams", id: 4 },
  { name: "Top Mentors", id: 5 },
  { name: "Events", id: 6 },
];

const nineTentabs = [
  { name: "Description", id: 1 },
  { name: "Subjects", id: 2 },
  { name: "Prospective Careers", id: 3 },
  { name: "Top Mentors", id: 4 },
];

const NineTenth = ({
  data,
  category,
  dataStream,
  active,
  setActive,
  active1,
  setActive1,
}) => {
  const [MentorList, setMentorList] = useState(["", "", "", ""]);
  const [BookedMentorList, setBookedMentorList] = useState([]);
  const [MentorCount, setMentorCount] = useState(0);
  const [mentorData, setMentorData] = useState(null);
  const [modal, setModal] = useState(false);
  const [slide, setSlide] = useState(1);

  const options = [
    { name: "Choose Board", value: "#" },
    { name: "CBSC", value: "1" },
    { name: "ICSC", value: "2" },
    { name: "MP Board", value: "3" },
  ];

  useEffect(() => {
    (async () => {
      try {
        const res = await axiosInstance.get(
          API_URLS?.getMentorList + `?page=${"1"}&limit=${"4"}&search_key=${""}`
        );

        if (res.statusCode === 200) {
          setMentorList(res?.result);
          setMentorCount(res?.total_count);
        } else if (res.statusCode === 400) {
          setMentorList([]);
          setMentorCount(0);
        }
        console.log(res);
        // setLoading(false);
      } catch (error) {
        console.log(error);
        // setLoading(false);
      }
    })();
  }, []);

  return (
    <div className="  w-full h-full pb-3  bg-white  ">
      <CareerAnalysisNine
        data={data}
        dataStream={dataStream}
        category={category}
      />

      <div
        className="my-10"
        // id={"scrollToDescription"}
      >
        <div className=" flex gap-x-10 pl-[4%]  font-bold border-b-[1px] border-gray-300 border-solid">
          {category?.map((tab, index) => {
            return (
              <h3
                onClick={() => {
                  setActive1(index);
                }}
                key={index}
                className={`${
                  active1 === index
                    ? " text-black border-b-4 rounded-sm border-[#FFB83F]  "
                    : "text-gray-500"
                } pb-3 miniDesk:text-[11px] w-fit px-4 text-[14px] cursor-pointer`}
              >
                {tab}
              </h3>
            );
          })}
        </div>
      </div>

      <div
        className="mt-5"
        // id={"scrollToDescription"}
      >
        <div className=" flex gap-x-10 pl-[4%] w-fit font-bold border-b-[1px] border-gray-300 border-solid">
          {nineTentabs.map((tab, index) => {
            return (
              <h3
                onClick={() => {
                  setActive(index + 1);
                }}
                key={index}
                className={`${
                  active === tab?.id
                    ? " text-black border-b-4 rounded-sm border-[#FFB83F]  "
                    : "text-gray-500"
                } pb-3 miniDesk:text-[11px] w-fit px-4 text-[14px] cursor-pointer`}
              >
                {tab?.name}
              </h3>
            );
          })}
        </div>
      </div>
      <div className="mt-14">
        {active === 1 && (
          <div className="px-[5%]">
            <p className="">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptate
              deleniti, dolore vitae necessitatibus neque recusandae. Earum,
              quidem? Ad ex temporibus soluta vel ducimus repellat qui nesciunt?
              Atque iste ipsa saepe? Rerum omnis quaerat dolorum consequatur,
              exercitationem quae, maiores autem adipisci, dolore molestias enim
              distinctio iure. Dolorum, dolores sapiente nulla ab quisquam
              mollitia quos sit suscipit ex reiciendis dolor in. Id, repellat
              culpa! Eos, laudantium architecto? Tempora corporis eveniet rerum
              ducimus beatae odit! Sequi nobis, accusamus aliquam provident
              itaque tempore adipisci dignissimos quasi voluptatem officiis
              repudiandae, ullam quod quam architecto suscipit molestias
              recusandae beatae eveniet, at aut. Placeat voluptate debitis quos?
            </p>
          </div>
        )}
        {active === 2 && (
          <>
            <div className="px-[5%]">
              <SelectDropdown classNames={"w-[200px]"} options={options} />

              <div className="mt-5">
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Voluptate deleniti, dolore vitae necessitatibus neque
                  recusandae. Earum, quidem? Ad ex temporibus soluta vel ducimus
                  repellat qui nesciunt? Atque iste ipsa saepe? Rerum omnis
                  quaerat dolorum consequatur, exercitationem quae, maiores
                  autem adipisci, dolore molestias enim distinctio iure.
                  Dolorum, dolores sapiente nulla ab quisquam mollitia quos sit
                  suscipit ex reiciendis dolor in. Id, repellat culpa! Eos,
                  laudantium architecto? Tempora corporis eveniet rerum ducimus
                  beatae odit! Sequi nobis, accusamus aliquam provident itaque
                  tempore adipisci dignissimos quasi voluptatem officiis
                  repudiandae, ullam quod quam architecto suscipit molestias
                  recusandae beatae eveniet, at aut. Placeat voluptate debitis
                  quos?
                </p>
              </div>
            </div>
          </>
        )}
        {active === 3 && (
          <div className="px-[5%]">
            <div>
              <h2 className="text-[20px] font-bold">1. Engineering</h2>
              <div className="flex overflow-x-scroll space-x-10 mt-5 no-scrollbar">
                {!!nineTentabs.length &&
                  nineTentabs?.slice(0, 2)?.map((item, index) => {
                    return (
                      <div className=" gap-x-5 flex flex-col justify-center items-center">
                        <div
                          // onClick={() => {
                          //   setSelectedCluster(index);
                          // }}
                          className={`p-5  w-fit h-fit rounded-lg cursor-pointer shadow-md border-[1px] border-gray-200 border-solid `}
                        >
                          <AssestCluster />
                        </div>
                        <p className="text-center font-bold opacity-85  w-[50%] ">
                          {item?.careerClusterName || "Test"}
                        </p>
                      </div>
                    );
                  })}
              </div>
            </div>

            <div className="mt-4">
              <h2 className="text-[20px] font-bold">2. Art & Design</h2>
              <div className="flex overflow-x-scroll space-x-10 mt-5 no-scrollbar">
                {!!nineTentabs.length &&
                  nineTentabs?.slice(0, 2)?.map((item, index) => {
                    return (
                      <div className=" gap-x-5 flex flex-col justify-center items-center">
                        <div
                          // onClick={() => {
                          //   setSelectedCluster(index);
                          // }}
                          className={`p-5  w-fit h-fit rounded-lg cursor-pointer shadow-md border-[1px] border-gray-200 border-solid `}
                        >
                          <AssestCluster />
                        </div>
                        <p className="text-center font-bold opacity-85  w-[50%] ">
                          {item?.careerClusterName || "Test"}
                        </p>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        )}
        {active === 4 && (
          <>
            <div className="px-[5%]">
              <div className="mt-1 flex justify-evenly gap-4 px-[5%]  items-center flex-wrap">
                {MentorList?.map((ele, i) => (
                  <ExploreCard
                    isRequested={false}
                    BookedMentorList={BookedMentorList}
                    isMentorPage={false}
                    key={i}
                    setMentorData={setMentorData}
                    mentor={ele}
                    isAdCard={i === 4}
                    cardIndex={i}
                    modal={modal}
                    setModal={setModal}
                  />
                ))}
              </div>
            </div>

            <BookSessionPopup
              modal={modal}
              setModal={setModal}
              mentorData={mentorData}
              setSlide={setSlide}
              slide={slide}
              setActive={setActive}
            />
          </>
        )}
      </div>
    </div>
  );
};

const Report = () => {
  const { userInfo } = useUserInfoStore((state) => state);

  const [seeReport, setSeeReport] = useState(false);
  const [selectedCluster, setSelectedCluster] = useState(0);
  const [selectedGroup, setSelectedGroup] = useState(0);
  const [active, setActive] = useState(1);
  const [active1, setActive1] = useState(0);

  const [allCluster, setAllCluster] = useState([]);
  const [careerId, setCareerId] = useState("");
  const [careerDetails, setCareerDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [streamData, setStreamData] = useState([]);

  const [streamNames, setStreamNames] = useState([]);
  const [streamAvg, setStreamAvg] = useState([]);

  useEffect(() => {
    (async () => {
      if (userInfo?.class == "9-10") {
        try {
          setIsLoading(true);
          const res = await axiosInstance.get(
            API_URLS?.get9th10thStreamSelection
          );
          // console.log(res);

          if (res.statusCode === 200) {
            // setAllCluster(res?.result);
            // console.log("res", res?.result?.data);
            setStreamData(res?.result?.data);
            const namesArray = res?.result?.data.map((item) =>
              item?.groupSecondName
                ? item?.groupSecondName
                : item?.careerGroupName
            );
            setStreamNames(namesArray);

            const groupAverages = res?.result?.data.map((career) => {
              const {
                personalityStenScore,
                aptitudeStenScore,
                socialBehaviourStenScore,
                interestStenScore,
              } = career;
              return parseFloat(
                (
                  (personalityStenScore +
                    aptitudeStenScore +
                    socialBehaviourStenScore +
                    interestStenScore) /
                  4
                ).toFixed(0)
              );
            });

            setStreamAvg(groupAverages);
            // console.log("groupAverages", groupAverages);
          }
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);

          console.log(error);
        }
      } else {
        try {
          setIsLoading(true);
          const res = await axiosInstance.get(API_URLS?.getFinalReport);
          console.log(res);

          if (res.statusCode === 200) {
            setAllCluster(res?.result);
          }
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);

          console.log(error);
        }
      }
    })();
  }, []);

  // console.log("streamData", streamData);
  // console.log("streamNames", streamNames);

  useEffect(() => {
    (async () => {
      if (careerId)
        try {
          setIsLoading(true);

          const res = await axiosInstance.get(
            API_URLS?.getCareerDetails + `?career_id=${careerId}`
          );
          console.log(res);

          if (res.statusCode === 200) {
            setCareerDetails(res?.result);
          }
          setIsLoading(false);
        } catch (error) {
          console.log(error);
          setIsLoading(false);
        } finally {
          setIsLoading(false);
        }
    })();
  }, [careerId]);

  console.log("userInfo", userInfo?.class);
  // console.log("Career Details", careerDetails);

  return (
    <>
      {!!isLoading && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // Optional: Add a semi-transparent background
            backgroundColor: "rgba(255, 255, 255, 0.2)",
            zIndex: 999999999,
            // Optional: Set a higher z-index to overlay on top of other content
          }}
        >
          <CircularProgress size={30} color="warning" />
        </Box>
      )}
      <div className="  w-full h-full pb-3 pt-8 shadow-[0px_5px_16px_rgba(0,0,0,0.06)] bg-white  ">
        <div className="flex justify-center items-center w-full">
          <div className="bg-[#F3F6FF] w-[92%] rounded-2xl  py-3  px-4 ">
            <div className="flex justify-between ">
              <div className="flex items-center gap-x-2">
                <HowReport />

                <h6 className="text-[19px] font-bold ">
                  How to read the report?
                </h6>
              </div>
              <div
                className={`cursor-pointer ${
                  seeReport ? " rotate-180 " : " "
                } `}
                onClick={() => setSeeReport(!seeReport)}
              >
                <svg
                  id="Group_412359"
                  data-name="Group 412359"
                  xmlns="http://www.w3.org/2000/svg"
                  width="35.453"
                  height="35.453"
                  viewBox="0 0 43.453 43.453"
                >
                  <g
                    id="Ellipse_4191"
                    data-name="Ellipse 4191"
                    fill="none"
                    stroke="#2771e7"
                    stroke-width="1"
                  >
                    <circle cx="21.727" cy="21.727" r="21.727" stroke="none" />
                    <circle cx="21.727" cy="21.727" r="21.227" fill="none" />
                  </g>
                  <path
                    id="Icon_ionic-ios-arrow-forward"
                    data-name="Icon ionic-ios-arrow-forward"
                    d="M6.315,7.9.332,1.924a1.125,1.125,0,0,1,0-1.6,1.139,1.139,0,0,1,1.6,0L8.711,7.1a1.128,1.128,0,0,1,.033,1.558L1.937,15.482a1.13,1.13,0,0,1-1.6-1.6Z"
                    transform="translate(29.717 19.12) rotate(90)"
                    fill="#2771e7"
                  />
                </svg>
              </div>
            </div>
            {!!seeReport && (
              <div className="pt-3">
                <p className="text-[14px]">
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit. Hic
                  modi harum inventore, vero quaerat aut. Voluptatum quis earum
                  modi vel, amet cum, impedit neque harum quos placeat velit.
                  Perspiciatis nostrum neque dolor eaque quia aliquid! Deleniti
                  corporis quo voluptatum iure hic, laborum ipsa vel illum
                  facilis, cumque optio, <br /> earum nam. Lorem ipsum, dolor
                  sit amet consectetur adipisicing elit. Recusandae quae nihil
                  doloremque ratione eos autem, consequatur tenetur vel esse
                  necessitatibus iusto dolore facilis aspernatur modi
                  repudiandae, veniam explicabo natus tempora velit praesentium?
                  Sit quibusdam minus voluptatibus ad neque obcaecati
                  repudiandae rerum,
                  <br /> cum soluta inventore nisi fuga, quas in, illo omnis.
                </p>
              </div>
            )}
          </div>
        </div>
        {userInfo?.class !== "9-10" ? (
          <>
            <div className="flex items-center gap-x-2 w-full mx-[4%]  mt-10 ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="33.914"
                height="33.915"
                viewBox="0 0 37.914 37.915"
              >
                <g
                  id="Group_417837"
                  data-name="Group 417837"
                  transform="translate(-421.94 -443.897)"
                >
                  <path
                    id="Path_266827"
                    data-name="Path 266827"
                    d="M32.366,42.124l2.7,7.754,1.7-3.415L41.4,47.6l-5.271-8.832Z"
                    transform="translate(407.572 427.527)"
                    fill="#f16176"
                  />
                  <path
                    id="Path_266828"
                    data-name="Path 266828"
                    d="M43.7,32.128l7.921,4.044-3.68,1,.208,4.777-8.064-6.6Z"
                    transform="translate(404.443 430.219)"
                    fill="#f16176"
                  />
                  <path
                    id="Path_266829"
                    data-name="Path 266829"
                    d="M25.508,34.024,13.782,48.595a2.31,2.31,0,0,1-3.433.185L3.677,42.107a2.31,2.31,0,0,1,.185-3.433l14.57-11.726Z"
                    transform="translate(419.53 432.355)"
                    fill="#f2f1e8"
                  />
                  <path
                    id="Path_266830"
                    data-name="Path 266830"
                    d="M34.024,25.508l14.57-11.726a2.31,2.31,0,0,0,.185-3.433L42.107,3.677a2.31,2.31,0,0,0-3.433.185L26.948,18.432Z"
                    transform="translate(409.808 442.084)"
                    fill="#f2f1e8"
                  />
                  <path
                    id="Path_266831"
                    data-name="Path 266831"
                    d="M14.758,20.129,3.863,28.891a2.31,2.31,0,0,0-.186,3.433l.657.657L17.239,22.61Zm5.01-4.923,2.482,2.482L32.98,4.334l-.657-.657a2.31,2.31,0,0,0-3.433.186Z"
                    transform="translate(419.53 442.084)"
                    fill="#d0d2c7"
                    opacity="0.5"
                  />
                  <path
                    id="Path_266832"
                    data-name="Path 266832"
                    d="M31.867,32.341l-.578.689-.11-.11Z"
                    transform="translate(408.048 430.125)"
                    fill="#fff"
                  />
                  <path
                    id="Path_266833"
                    data-name="Path 266833"
                    d="M32.92,31.178l.11.11-.688.578Z"
                    transform="translate(407.573 430.598)"
                    fill="#fff"
                  />
                  <path
                    id="Path_266834"
                    data-name="Path 266834"
                    d="M24.686,30.057,14.315,42.963l.657.657a2.31,2.31,0,0,0,3.433-.186l8.762-10.9Zm4.923-5.011,2.482,2.482,11.343-9.122a2.31,2.31,0,0,0,.186-3.433l-.657-.657Z"
                    transform="translate(414.968 437.516)"
                    fill="#fff"
                    opacity="0.5"
                  />
                  <path
                    id="Path_266835"
                    data-name="Path 266835"
                    d="M35.184,35.658l-.689.579.11.11Z"
                    transform="translate(406.696 428.773)"
                    fill="#fff"
                  />
                  <path
                    id="Path_266836"
                    data-name="Path 266836"
                    d="M36.347,34.605l-.11-.11-.578.688Z"
                    transform="translate(406.222 429.246)"
                    fill="#fff"
                  />
                  <path
                    id="Path_266837"
                    data-name="Path 266837"
                    d="M24.027,27.366l3.339-3.339a1.185,1.185,0,0,1,1.676,0l6.5,6.5a1.185,1.185,0,0,1,0,1.676L32.2,35.542a1.185,1.185,0,0,1-1.676,0l-6.5-6.5A1.185,1.185,0,0,1,24.027,27.366Z"
                    transform="translate(411.112 433.664)"
                    fill="#f16176"
                  />
                  <path
                    id="Path_266838"
                    data-name="Path 266838"
                    d="M29.287,24.266l2.574,2.575a1.434,1.434,0,0,0-2.027,0L26.84,29.834a1.434,1.434,0,0,0,0,2.027l-2.574-2.575a1.434,1.434,0,0,1,0-2.027l2.994-2.994A1.434,1.434,0,0,1,29.287,24.266Z"
                    transform="translate(411.041 433.592)"
                    fill="#e04b64"
                  />
                  <path
                    id="Path_266839"
                    data-name="Path 266839"
                    d="M35.632,30.611l2.574,2.575a1.427,1.427,0,0,1-.006,2.021L35.206,38.2a1.427,1.427,0,0,1-2.021.006l-2.574-2.575a1.434,1.434,0,0,0,2.027,0l2.994-2.994A1.434,1.434,0,0,0,35.632,30.611Z"
                    transform="translate(408.285 430.836)"
                    fill="#fff"
                    opacity="0.3"
                  />
                  <path
                    id="Path_266840"
                    data-name="Path 266840"
                    d="M39.064,9.53,32.381,2.848a2.9,2.9,0,0,0-4.319.237l-9.011,11.2a1.742,1.742,0,0,0-.93.492l-3.347,3.341a1.78,1.78,0,0,0-.492.936l-11.2,9.01a2.907,2.907,0,0,0-.231,4.318l6.683,6.682a2.911,2.911,0,0,0,2.056.853c.047,0,.1-.006.154-.006a2.926,2.926,0,0,0,2.109-1.078l6.683-8.311,1.6,4.585a.6.6,0,0,0,.521.4h.036a.6.6,0,0,0,.533-.332l1.487-2.991L28.9,33.2a.593.593,0,0,0,.616-.225.6.6,0,0,0,.036-.658l-4.088-6.842.492-.5,6.3,5.153a.579.579,0,0,0,.64.071.574.574,0,0,0,.326-.551l-.184-4.312,3.229-.871a.595.595,0,0,0,.113-1.1l-5.741-2.932,8.187-6.587a2.906,2.906,0,0,0,.231-4.318ZM12.931,38.081a1.687,1.687,0,0,1-1.25.64,1.663,1.663,0,0,1-1.309-.5L3.689,31.536a1.713,1.713,0,0,1,.136-2.553l10.723-8.631a2.013,2.013,0,0,0,.225.278l6.511,6.51a1.964,1.964,0,0,0,.272.225Zm14.876-6.368-3.27-.806a.6.6,0,0,0-.675.314l-1.072,2.156-1.374-3.945,1.451-1.807a1.77,1.77,0,0,0,.936-.486l.8-.806Zm-1.5-8.755L22.961,26.3a.6.6,0,0,1-.841,0l-6.511-6.51a.59.59,0,0,1,0-.835l3.347-3.347a.6.6,0,0,1,.421-.172.607.607,0,0,1,.421.172l6.511,6.51A.6.6,0,0,1,26.309,22.958Zm8.17.764-2.2.6a.593.593,0,0,0-.438.6l.154,3.459L26.8,24.137l.344-.344a1.763,1.763,0,0,0,.492-.93l2-1.611ZM38.092,12.93,27.369,21.554a1.933,1.933,0,0,0-.225-.278l-6.511-6.5a1.619,1.619,0,0,0-.272-.225L28.986,3.825a1.721,1.721,0,0,1,2.559-.136l6.683,6.682a1.72,1.72,0,0,1-.136,2.559Z"
                    transform="translate(419.938 441.9)"
                    fill="#1a1a1a"
                  />
                  <path
                    id="Path_266841"
                    data-name="Path 266841"
                    d="M40.257,15.9a.592.592,0,0,1-.462-.961L43.22,10.65a.591.591,0,0,1,.924.738L40.72,15.673A.59.59,0,0,1,40.257,15.9Zm-1.787,2.078a.591.591,0,0,1-.447-.979l.234-.27a.592.592,0,0,1,.894.775l-.234.27A.592.592,0,0,1,38.471,17.974Z"
                    transform="translate(405.323 439.049)"
                    fill="#1a1a1a"
                  />
                </g>
              </svg>
              <div className="text-[21px] font-bold  relative text-center">
                <span className="relative z-10">
                  Choose Career Cluster :
                  <div className="h-[2.1px]  w-[87%]  bg-gradient-to-r from-gray-400 to-gray-50 left-[-15%] absolute bottom-[-8px] -z-10" />
                </span>{" "}
              </div>
            </div>
            {/* cluster cards */}
            <div className="flex overflow-x-scroll  mx-[4%] mt-5 no-scrollbar">
              {!!allCluster.length &&
                allCluster?.map((item, index) => {
                  return (
                    <div className=" gap-x-5 flex flex-col  items-center">
                      <div
                        onClick={() => {
                          setSelectedCluster(index);
                        }}
                        className={`p-5 ${
                          selectedCluster === index
                            ? "bg-gradient-to-b from-[#FFB83F] to-[#FFEC8D] "
                            : ""
                        } w-fit h-fit rounded-lg cursor-pointer shadow-md border-[1px] border-gray-200 border-solid `}
                      >
                        <img
                          className="w-9 h-9"
                          src={item?.careerClusterIcon}
                          alt={item?.careerClusterName}
                        />
                        {/* <AssestCluster /> */}
                      </div>
                      <p className="text-center font-bold opacity-85  w-[50%] ">
                        {item?.careerClusterName}
                      </p>
                    </div>
                  );
                })}
            </div>

            {/* career groups */}

            <div className="mt-10">
              <div className="flex overflow-x-scroll  gap-x-4 mx-[4%] mt-5 no-scrollbar">
                {!!allCluster.length &&
                  allCluster[selectedCluster]?.group?.map(
                    (item, groupIndex) => {
                      return (
                        <GroupCard
                          key={groupIndex}
                          item={item}
                          groupIndex={groupIndex}
                          setSelectedGroup={setSelectedGroup}
                          selectedGroup={selectedGroup}
                        />
                      );
                    }
                  )}
              </div>
            </div>

            {/* tabs */}
            <div
              className="mt-10"
              // id={"scrollToDescription"}
            >
              <div className=" flex gap-x-10 pl-[4%]  font-bold border-b-[1px] border-gray-300 border-solid">
                {tabs.map((tab, index) => {
                  return (
                    <h3
                      onClick={() => {
                        setActive(index + 1);
                      }}
                      key={index}
                      className={`${
                        active === tab?.id
                          ? " text-black border-b-4 rounded-sm border-[#FFB83F]  "
                          : "text-gray-500"
                      } pb-3 miniDesk:text-[11px] w-fit px-4 text-[14px] cursor-pointer`}
                    >
                      {tab?.name}
                    </h3>
                  );
                })}
              </div>
            </div>

            {/* //tab panel */}
            <div className="mt-14">
              {active === 1 && (
                <>
                  <Career
                    Details={careerDetails}
                    data={allCluster[selectedCluster]?.group[selectedGroup]}
                    setCareerId={setCareerId}
                  />
                </>
              )}
              {active === 2 && (
                <>
                  <CareerAnalysis
                    data={allCluster[selectedCluster]?.group[selectedGroup]}
                    cluster={allCluster[selectedCluster]}
                  />
                </>
              )}
              {active === 3 && (
                <>
                  <CollageCard />
                </>
              )}
              {active === 4 && <>HEllo4</>}
            </div>
          </>
        ) : (
          <NineTenth
            data={allCluster[selectedCluster]?.group[selectedGroup]}
            category={streamNames}
            dataStream={streamAvg}
            active={active}
            setActive={setActive}
            active1={active1}
            setActive1={setActive1}
          />
        )}
      </div>
    </>
  );
};

export default Report;

const AssestCluster = () => {
  return (
    <>
      <svg
        id="g1929"
        xmlns="http://www.w3.org/2000/svg"
        width="45.397"
        height="45.396"
        viewBox="0 0 58.397 58.396"
      >
        <defs>
          <clipPath id="clip-path">
            <path
              id="path1935"
              d="M0-682.665H58.4v58.4H0Z"
              transform="translate(0 682.665)"
            />
          </clipPath>
        </defs>
        <g id="g1931">
          <g id="g1933" clip-path="url(#clip-path)">
            <g id="g1939" transform="translate(24.54 18.088)">
              <path
                id="path1941"
                d="M-238.257-83.88a.245.245,0,0,1-.16-.225v-3.661l.681-2.879a.879.879,0,0,0-.856-1.081h-10.7a.879.879,0,0,0-.856,1.081l.681,2.879v3.6a.246.246,0,0,1-.163.231,16.528,16.528,0,0,0-10.912,15.6,16.507,16.507,0,0,0,16.522,16.47A16.522,16.522,0,0,0-227.5-68.392,16.528,16.528,0,0,0-238.257-83.88"
                transform="translate(260.544 91.726)"
                fill="#c7f0fe"
              />
            </g>
            <g id="g1943" transform="translate(24.54 18.088)">
              <path
                id="path1945"
                d="M-34.156-274.666a16.524,16.524,0,0,1,9.741-15.121,2.246,2.246,0,0,0,1.335-2.043v-1.561a5.988,5.988,0,0,0-.161-1.379l-.029-.121a2.569,2.569,0,0,1,2.5-3.16h5.936l.29-1.226a.879.879,0,0,0-.856-1.082H-26.1a.879.879,0,0,0-.856,1.082l.681,2.879v3.6a.246.246,0,0,1-.163.231,16.528,16.528,0,0,0-10.912,15.6,16.5,16.5,0,0,0,8.516,14.409,16.473,16.473,0,0,1-5.321-12.1"
                transform="translate(37.352 300.359)"
                fill="#9ae6fb"
              />
            </g>
            <g id="g1947" transform="translate(31.792 37.494)">
              <path
                id="path1949"
                d="M-32.341-26.757l2.7-1.335a11.26,11.26,0,0,1,9.619-.175,11.257,11.257,0,0,0,5.246.976l.873-.112a.732.732,0,0,1,.822.664q.045.534.045,1.076A12.994,12.994,0,0,1-25.014-12.725H-35.367v-13.8Z"
                transform="translate(35.367 29.26)"
                fill="#6cf5c2"
              />
            </g>
            <g id="g1951" transform="translate(31.792 39.233)">
              <path
                id="path1953"
                d="M-129.88-155.327a6.181,6.181,0,0,0-.949-4.2l-6.633-10.328-1.543.764-3.025.234v13.8h10.353a12.914,12.914,0,0,0,1.8-.266"
                transform="translate(142.029 169.857)"
                fill="#00f2a6"
              />
            </g>
            <g id="g1955" transform="translate(24.54 28.835)">
              <path
                id="path1957"
                d="M-205.033-292.66a6.733,6.733,0,0,0-.247-6.959l-13.112-20.418a16.483,16.483,0,0,0-5.82,12.638,16.507,16.507,0,0,0,16.522,16.471c.467,0,.928-.02,1.385-.058a6.948,6.948,0,0,0,1.272-1.674"
                transform="translate(224.212 320.037)"
                fill="#9ae6fb"
              />
            </g>
            <g id="g1959" transform="translate(31.791 38.979)">
              <path
                id="path1961"
                d="M-136.219-156.49a6.754,6.754,0,0,0-1.062-4.364l-6.6-10.274-2.057,1.018-3.025.234v13.8h10.353a12.906,12.906,0,0,0,2.389-.412"
                transform="translate(148.961 171.129)"
                fill="#05e29c"
              />
            </g>
            <g id="g1963" transform="translate(0.865 16.723)">
              <path
                id="path1965"
                d="M-434.957-360.93a4.543,4.543,0,0,1-3.822,7h-32.315a4.543,4.543,0,0,1-3.822-7l13.352-20.791a2.372,2.372,0,0,0,.376-1.281v-11.691h12.5V-383a2.371,2.371,0,0,0,.376,1.281Z"
                transform="translate(475.644 394.692)"
                fill="#dff6fe"
              />
            </g>
            <g id="g1967" transform="translate(0.865 17.618)">
              <path
                id="path1969"
                d="M-242.38-8v-.749H-250.6v10.8a2.371,2.371,0,0,1-.376,1.281l-13.352,20.791a4.543,4.543,0,0,0,3.822,7h5.481a4.543,4.543,0,0,1-1.085-6.086l13.352-20.791a2.372,2.372,0,0,0,.376-1.281V-8Z"
                transform="translate(265.054 8.752)"
                fill="#c7f0fe"
              />
            </g>
            <g id="g1971" transform="translate(4.287 36.085)">
              <path
                id="path1973"
                d="M-367.6-173.714a1.085,1.085,0,0,1,.041,1.144,1.085,1.085,0,0,1-.984.584h-32.315a1.085,1.085,0,0,1-.984-.584,1.085,1.085,0,0,1,.041-1.144l10.066-15.672a1.261,1.261,0,0,1,1.064-.578,13.505,13.505,0,0,1,4.161.661h0a13.525,13.525,0,0,0,7.91.138,1.262,1.262,0,0,1,1.415.525Z"
                transform="translate(401.986 189.964)"
                fill="#fe9a9f"
              />
            </g>
            <g id="g1975" transform="translate(4.287 36.085)">
              <path
                id="path1977"
                d="M-367.6-173.714a1.085,1.085,0,0,1,.041,1.144,1.085,1.085,0,0,1-.984.584h-32.315a1.085,1.085,0,0,1-.984-.584,1.085,1.085,0,0,1,.041-1.144l10.066-15.672a1.261,1.261,0,0,1,1.064-.578,13.505,13.505,0,0,1,4.161.661h0a13.525,13.525,0,0,0,7.91.138,1.262,1.262,0,0,1,1.415.525Z"
                transform="translate(401.986 189.964)"
                fill="#fe9a9f"
              />
            </g>
            <g id="g1979" transform="translate(4.287 36.085)">
              <path
                id="path1981"
                d="M-59.058-163.706l9.5-14.791a13.518,13.518,0,0,0-3.708-.523,1.261,1.261,0,0,0-1.064.578L-64.4-162.77a1.085,1.085,0,0,0-.041,1.144,1.085,1.085,0,0,0,.984.584h3.675a4.481,4.481,0,0,1,.721-2.664"
                transform="translate(64.582 179.02)"
                fill="#fd8087"
              />
            </g>
            <g id="g1983" transform="translate(4.287 44.419)">
              <path
                id="path1985"
                d="M-367.6-84.618a1.085,1.085,0,0,1,.041,1.144,1.085,1.085,0,0,1-.984.584h-32.315a1.085,1.085,0,0,1-.984-.584,1.085,1.085,0,0,1,.041-1.144l2.959-4.606,5.032-1.193a13.52,13.52,0,0,1,7.3.3,13.546,13.546,0,0,0,4.184.663,13.506,13.506,0,0,0,5.623-1.224l3.934-1.8.087-.056Z"
                transform="translate(401.986 92.533)"
                fill="#fd8087"
              />
            </g>
            <g id="g1987" transform="translate(4.287 46.42)">
              <path
                id="path1989"
                d="M-59.058-53.223l3.2-4.979c-.182.035-.365.073-.546.116l-5.032,1.193L-64.4-52.286a1.085,1.085,0,0,0-.041,1.144,1.085,1.085,0,0,0,.984.584h3.675a4.481,4.481,0,0,1,.721-2.664"
                transform="translate(64.582 58.201)"
                fill="#fe646e"
              />
            </g>
            <g id="g1991" transform="translate(13.948 15.068)">
              <path
                id="path1993"
                d="M-145.653-34.749h-12a1.625,1.625,0,0,1-1.625-1.625A1.625,1.625,0,0,1-157.653-38h12a1.625,1.625,0,0,1,1.625,1.625,1.625,1.625,0,0,1-1.625,1.625"
                transform="translate(159.278 38)"
                fill="#9ae6fb"
              />
            </g>
            <g id="g1995" transform="translate(43.23 42.59)">
              <path
                id="path1997"
                d="M-53.084-26.542a2.483,2.483,0,0,1-2.483,2.483,2.483,2.483,0,0,1-2.483-2.483,2.483,2.483,0,0,1,2.483-2.483,2.483,2.483,0,0,1,2.483,2.483"
                transform="translate(58.049 29.025)"
                fill="#05e29c"
              />
            </g>
            <g id="g1999" transform="translate(16.463 39.669)">
              <path
                id="path2001"
                d="M-39.817-19.908a1.862,1.862,0,0,1-1.862,1.862,1.862,1.862,0,0,1-1.862-1.862,1.862,1.862,0,0,1,1.862-1.862,1.862,1.862,0,0,1,1.862,1.862"
                transform="translate(43.541 21.771)"
                fill="#fd8087"
              />
            </g>
            <g id="g2003" transform="translate(18.857 5.846)">
              <path
                id="path2005"
                d="M-58.081-29.041A2.717,2.717,0,0,1-60.8-26.324a2.717,2.717,0,0,1-2.717-2.717A2.716,2.716,0,0,1-60.8-31.757a2.716,2.716,0,0,1,2.716,2.717"
                transform="translate(63.515 31.757)"
                fill="#fe9a9f"
              />
            </g>
            <g id="g2007" transform="translate(38.448 8.447)">
              <path
                id="path2009"
                d="M-58.081-29.041A2.717,2.717,0,0,1-60.8-26.324a2.716,2.716,0,0,1-2.717-2.717A2.717,2.717,0,0,1-60.8-31.757a2.717,2.717,0,0,1,2.717,2.717"
                transform="translate(63.515 31.757)"
                fill="#9cf8d3"
              />
            </g>
            <g id="g2011" transform="translate(12.823 0.919)">
              <path
                id="path2013"
                d="M-39.375-19.686a1.842,1.842,0,0,1-1.842,1.842,1.842,1.842,0,0,1-1.842-1.842,1.842,1.842,0,0,1,1.842-1.842,1.842,1.842,0,0,1,1.842,1.842"
                transform="translate(43.059 21.528)"
                fill="#fe9a9f"
              />
            </g>
            <g id="g2015" transform="translate(0.865 25.754)">
              <path
                id="path2017"
                d="M-154.541,0V3.355L-168.27,24.731a4.543,4.543,0,0,0,3.823,7h32.315a4.543,4.543,0,0,0,3.823-7l-8.619-13.42"
                transform="translate(168.998)"
                fill="none"
                stroke="#000"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
              />
            </g>
            <g id="g2019" transform="translate(15.322 18.319)">
              <path
                id="path2021"
                d="M-167.226-167.615l-3.14-4.889v-10.791h-12.5v3.79"
                transform="translate(182.869 183.294)"
                fill="none"
                stroke="#000"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
              />
            </g>
            <g id="g2023" transform="translate(4.287 36.084)">
              <path
                id="path2025"
                d="M-367.6-173.721l-10.109-15.74a13.522,13.522,0,0,1-8.8.151h0a13.521,13.521,0,0,0-4.866-.644L-401.8-173.721a1.083,1.083,0,0,0-.04,1.144,1.082,1.082,0,0,0,.984.584h32.315a1.083,1.083,0,0,0,.984-.584A1.083,1.083,0,0,0-367.6-173.721Z"
                transform="translate(401.989 189.972)"
                fill="none"
                stroke="#000"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
              />
            </g>
            <g id="g2027" transform="translate(7.43 44.474)">
              <path
                id="path2029"
                d="M0-34.788l5.032-1.193a13.521,13.521,0,0,1,7.3.3,13.522,13.522,0,0,0,9.806-.562l3.934-1.8"
                transform="translate(0 38.043)"
                fill="none"
                stroke="#000"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
              />
            </g>
            <g id="g2031" transform="translate(28.676 18.088)">
              <path
                id="path2033"
                d="M-109.22-419.562a16.537,16.537,0,0,0,2.247.154,16.4,16.4,0,0,0,16.4-16.4,16.4,16.4,0,0,0-10.874-15.444v-3.6l.681-2.879a.879.879,0,0,0-.856-1.082h-10.7a.879.879,0,0,0-.856,1.082l.681,2.879v3.6a16.427,16.427,0,0,0-6.939,4.789"
                transform="translate(119.437 458.81)"
                fill="none"
                stroke="#000"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
              />
            </g>
            <g id="g2035" transform="translate(13.948 15.068)">
              <path
                id="path2037"
                d="M-145.653-34.749h-12a1.625,1.625,0,0,1-1.625-1.625A1.625,1.625,0,0,1-157.653-38h12a1.625,1.625,0,0,1,1.625,1.625A1.625,1.625,0,0,1-145.653-34.749Z"
                transform="translate(159.278 38)"
                fill="none"
                stroke="#000"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
              />
            </g>
            <g id="g2039" transform="translate(42.144 51.868)">
              <path
                id="path2041"
                d="M0-23.1a12.9,12.9,0,0,0,6.217-2.161"
                transform="translate(0 25.264)"
                fill="none"
                stroke="#000"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
              />
            </g>
            <g id="g2043" transform="translate(34.817 37.494)">
              <path
                id="path2045"
                d="M-174.149-127.185a12.917,12.917,0,0,0,3.01-8.3q0-.542-.045-1.076a.732.732,0,0,0-.822-.664l-.873.112a11.259,11.259,0,0,1-5.246-.976h0a11.26,11.26,0,0,0-9.619.175l-2.7,1.335"
                transform="translate(190.44 139.082)"
                fill="none"
                stroke="#000"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
              />
            </g>
            <g id="g2047" transform="translate(43.23 42.59)">
              <path
                id="path2049"
                d="M-53.084-26.542a2.483,2.483,0,0,1-2.483,2.483,2.483,2.483,0,0,1-2.483-2.483,2.483,2.483,0,0,1,2.483-2.483A2.483,2.483,0,0,1-53.084-26.542Z"
                transform="translate(58.049 29.025)"
                fill="none"
                stroke="#000"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
              />
            </g>
            <g id="g2051" transform="translate(16.463 39.669)">
              <path
                id="path2053"
                d="M-39.817-19.908a1.862,1.862,0,0,1-1.862,1.862,1.862,1.862,0,0,1-1.862-1.862,1.862,1.862,0,0,1,1.862-1.862A1.862,1.862,0,0,1-39.817-19.908Z"
                transform="translate(43.541 21.771)"
                fill="none"
                stroke="#000"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
              />
            </g>
            <g id="g2055" transform="translate(18.857 5.846)">
              <path
                id="path2057"
                d="M-58.081-29.041A2.717,2.717,0,0,1-60.8-26.324a2.717,2.717,0,0,1-2.717-2.717A2.716,2.716,0,0,1-60.8-31.757,2.716,2.716,0,0,1-58.081-29.041Z"
                transform="translate(63.515 31.757)"
                fill="none"
                stroke="#000"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
              />
            </g>
            <g id="g2059" transform="translate(38.448 8.447)">
              <path
                id="path2061"
                d="M-58.081-29.041A2.717,2.717,0,0,1-60.8-26.324a2.716,2.716,0,0,1-2.717-2.717A2.717,2.717,0,0,1-60.8-31.757,2.717,2.717,0,0,1-58.081-29.041Z"
                transform="translate(63.515 31.757)"
                fill="none"
                stroke="#000"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
              />
            </g>
            <g id="g2063" transform="translate(12.823 0.919)">
              <path
                id="path2065"
                d="M-39.375-19.686a1.842,1.842,0,0,1-1.842,1.842,1.842,1.842,0,0,1-1.842-1.842,1.842,1.842,0,0,1,1.842-1.842A1.842,1.842,0,0,1-39.375-19.686Z"
                transform="translate(43.059 21.528)"
                fill="none"
                stroke="#000"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
              />
            </g>
          </g>
        </g>
      </svg>
    </>
  );
};

const HowReport = () => {
  return (
    <svg
      id="Group_412502"
      data-name="Group 412502"
      xmlns="http://www.w3.org/2000/svg"
      width="30.603"
      height="35.104"
      viewBox="0 0 36.603 40.104"
    >
      <g
        id="Group_412494"
        data-name="Group 412494"
        transform="translate(1.181 1.181)"
      >
        <g id="Group_412493" data-name="Group 412493">
          <path
            id="Path_267656"
            data-name="Path 267656"
            d="M20.121,1.5A17.133,17.133,0,0,0,3,18.621a17.953,17.953,0,0,0,2.716,9.328V39.283L14.335,34.8a15.912,15.912,0,0,0,5.9,1.063A17.133,17.133,0,0,0,37.359,18.739,17.335,17.335,0,0,0,20.121,1.5Z"
            transform="translate(-3 -1.5)"
            fill="#f15e75"
          />
        </g>
      </g>
      <g
        id="Group_412496"
        data-name="Group 412496"
        transform="translate(1.181 1.181)"
      >
        <g id="Group_412495" data-name="Group 412495">
          <path
            id="Path_267657"
            data-name="Path 267657"
            d="M35.234,10.592A17.125,17.125,0,0,0,5.6,22.281a16.924,16.924,0,0,0,2.716,9.328v6.376L5.716,39.4V28.067A17.612,17.612,0,0,1,3,18.621a17.116,17.116,0,0,1,32.234-8.029Z"
            transform="translate(-3 -1.5)"
            fill="#ef3956"
          />
        </g>
      </g>
      <g id="Group_412498" data-name="Group 412498" transform="translate(0 0)">
        <g id="Group_412497" data-name="Group 412497">
          <path
            id="Path_267658"
            data-name="Path 267658"
            d="M5.306,40.527a1.24,1.24,0,0,1-.59-1.063V28.483A18.733,18.733,0,0,1,2,18.8,18.3,18.3,0,1,1,20.3,37.1a19.767,19.767,0,0,1-5.786-.945L6.369,40.409a1.109,1.109,0,0,1-1.063.118Zm15-37.665A15.861,15.861,0,0,0,6.959,27.421a2,2,0,0,1,.236.59v9.328L14.043,33.8a1.332,1.332,0,0,1,.945-.118,17.523,17.523,0,0,0,5.431.945A15.6,15.6,0,0,0,36.241,18.8,15.874,15.874,0,0,0,20.3,2.861Z"
            transform="translate(-2 -0.5)"
            fill="#010101"
          />
        </g>
      </g>
      <g
        id="Group_412501"
        data-name="Group 412501"
        transform="translate(13.224 9.918)"
      >
        <g id="Group_412500" data-name="Group 412500">
          <g id="Group_412499" data-name="Group 412499">
            <path
              id="Path_267659"
              data-name="Path 267659"
              d="M19.694,21.062H16.86V19.645a3.391,3.391,0,0,1,.236-1.417,4.189,4.189,0,0,1,1.063-1.3l1.771-1.771a2.245,2.245,0,0,0,.59-1.535,2.487,2.487,0,0,0-.59-1.535,2.291,2.291,0,0,0-3.07,0,1.9,1.9,0,0,0-.708,1.535H13.2A4.669,4.669,0,0,1,14.971,10.2a5.537,5.537,0,0,1,3.66-1.3,4.953,4.953,0,0,1,3.542,1.181,4.12,4.12,0,0,1,1.417,3.306,3.706,3.706,0,0,1-.827,2.48c-.354.354-.59.708-.708.827a4.5,4.5,0,0,1-.708.59l-.708.708-.472.472A2,2,0,0,0,19.694,20Zm-1.417,5.549a1.776,1.776,0,0,1-1.3-.472,1.891,1.891,0,0,1,1.3-3.188,1.776,1.776,0,0,1,1.3.472,1.724,1.724,0,0,1,0,2.6A1.524,1.524,0,0,1,18.277,26.611Z"
              transform="translate(-13.2 -8.9)"
              fill="#fff"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
