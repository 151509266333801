import { useState } from "react";
import { medias } from "../../../../config/Resources";
import ExamContent from "./ExamContent";
import ExamsTable from "./ExamsTable";

function CoursesOfferedAccordion({ admissions }) {
	const [openId, setOpenId] = useState(null);
	console.log(admissions, "admissionsAll");
	const [examsData, setExamsData] = useState();

	const handleToggle = (id) => {
		setOpenId(openId === id ? null : id);
	};

	return (
		<>
			<div className="ml-5 mt-5">
				<div className="flex item-center gap-1 text-center">
					<img
						className="bg-[#FFEEDB] bg-opacity-70 p-2 w-9 h-9 rounded-md"
						src={medias.images.College.CoursesOffered}
						alt="CoursesOffered"
					/>
					<h1 className="font-plusJakartaSans font-bold text-[14px] mt-[8px]">
						Courses Offered
					</h1>
				</div>
			</div>
			<div className="ml-4 mt-4 mr-4">
				{admissions?.courses?.map((allData) => (
					<div
						key={allData.id}
						className="mb-4 border border-gray-300 rounded-lg overflow-hidden"
					>
						<button
							className="flex justify-between items-center w-full pt-3 pb-3 pl-4 pr-4 focus:outline-none"
							onClick={() => handleToggle(allData.id)}
						>
							<span className="font-plusJakartaSans text-[13px] text-[#2771E7] font-bold">
								{allData?.admission_course_name}
							</span>
							<span className="text-lg">
								{openId === allData.id ? (
									<img
										className="w-6 h-6"
										src={medias.images.College.Up1}
										alt="Up1"
									/>
								) : (
									<img
										className="w-6 h-6"
										src={medias.images.College.DropDown1}
										alt="DropDown1"
									/>
								)}
							</span>
						</button>
						{openId === allData.id && (
							<>
								<div className="border-t ml-4 mr-4"></div>
								<div className="p-4">
									<h6 className="font-plusJakartaSans font-bold text-[15px]">
										Introduction
									</h6>
									<div
										dangerouslySetInnerHTML={{
											__html: allData?.introduction,
										}}
										className="text-[#000000] pt-2 font-plusJakartaSans font-medium text-[10.5px] text-opacity-70"
									></div>
								</div>
								<ExamContent admissions={admissions} data={allData.id} setExamsData={setExamsData} examsData = {examsData}/>
							</>
						)}
					</div>
				))}
			</div>
		</>
	);
}

export default CoursesOfferedAccordion;
