import React, { useState, useEffect } from "react";
import { medias } from "../../../../config/Resources"; // Adjust the path as per your actual file structure

function CampusImage({ campusLife }) {
    const [currentImage, setCurrentImage] = useState();
    const [cardData, setCardData] = useState([]);

    useEffect(() => {
        if (campusLife && campusLife.length > 0) {
            const firstImage = campusLife[0]?.campus_images?.[0]?.image;
            if (firstImage) {
                setCurrentImage(firstImage);
            }

            // Generate cardData dynamically based on campusLife
            const newCardData = [];

            campusLife.forEach((data, index) => {
                data.other_facilities?.forEach((facility, facilityIndex) => {
                    newCardData.push({
                        id: `${index}-${facilityIndex}`,
                        text: facility.facility_name,
                        image: getImageForCategory(facility.facility_name),
                    });
                });
            });

            setCardData(newCardData);
        }
    }, [campusLife]);

    const getImageForCategory = (category) => {
        // Define static images for each category
        const staticImages = {
            Medical: medias.images.College.Medical,
            Sports: medias.images.College.Sports,
            Parking: medias.images.College.Parking,
            Cafeteria: medias.images.College.Cafeteria,
			Wifi: medias.images.College.Cafeteria,
            Library: medias.images.College.Library,
            Gym: medias.images.College.Gym,
            Hostel: medias.images.College.Hostel,
            Auditorium: medias.images.College.Auditorium,
        };

        // Determine the appropriate category key based on facility name
        let categoryKey = "";

        // Match facility name to a category key (simplified example)
        switch (category.toLowerCase()) {
            case "medical facility":
                categoryKey = "Medical";
                break;
            case "sports":
                categoryKey = "Sports";
                break;
            case "parking":
                categoryKey = "Parking";
                break;
            case "gym":
                categoryKey = "Gym";
                break;
            case "cafeteria":
                categoryKey = "Cafeteria";
                break;
            case "wifi":
                categoryKey = "Wifi";
                break;
            case "guest rooms":
                categoryKey = "Hostel";
                break;
            case "library":
                categoryKey = "Library";
                break;
            case "auditorium":
                categoryKey = "Auditorium";
                break;
            default:
                categoryKey = "Default";
                break;
        }

        return staticImages[categoryKey] || medias.images.College.Default;
    };

    console.log(campusLife, "campusLife");
    console.log(cardData, "cardData");

    return (
        <div className="ml-4 mr-4 mt-2">
            <img className="w-full h-[300px] rounded-2xl" src={currentImage} alt="Campus" />
            <div className="flex item-center gap-2 mt-6 ml-3 mr-3">
                {campusLife?.map((data, index) => {
                    return data?.campus_images?.map((images, imgIndex) => {
                        return (
                            <div
                                key={`${index}-${imgIndex}`}
                                onClick={() => setCurrentImage(images?.image)}
                                className="cursor-pointer"
                            >
                                <img className="rounded-2xl" src={images?.image} alt="CampusImage" />
                            </div>
                        );
                    });
                })}
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-4 mt-8">
                {cardData.map((item) => (
                    <div
                        key={item.id}
                        className="max-w-sm rounded overflow-hidden custom-shadow1  pt-[10px] pb-[10px] pl-1 pr-1"
                    >
                        <img
                            className="w-9 h-9 flex ml-auto mr-auto"
                            src={item.image}
                            alt={item.text}
                        />
                        <p className="font-plusJakartaSans mt-[4px] flex justify-center text-center items-center text-[10.5px] font-semibold text-[#000000] text-opacity-55">
                            {item.text}
                        </p>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default CampusImage;
