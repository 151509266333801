import React, { useState } from "react";
import { IoMdArrowForward } from "react-icons/io";
import { medias } from "../../../../config/Resources";

const QuestionAccordionScholarShip = ({ scholarshipDetails }) => {
	const [activeIndex, setActiveIndex] = useState(null);

	const toggleQuestion = (index) => {
		setActiveIndex(activeIndex === index ? null : index);
	};

	return (
		<div className="rounded-lg border border-gray-200 p-2 overflow-hidden mt-8">
			<div className="flex items-center gap-2 mt-5 ml-3">
				<img
					className="w-10 h-10"
					src={medias.images.College.Faq}
					alt="courses"
				/>
				<div>
					<p className="font-bold text-[#000000] font-plusJakartaSans text-[13px]">
						Frequently asked questions
					</p>
					<p className="font-semibold text-[#000000] opacity-50 font-plusJakartaSans text-[11px]">
						On Placements
					</p>
				</div>
			</div>
			{scholarshipDetails?.map((data) =>
				data?.faqs?.map((question, index) => {
					return (
						<div
							key={index}
							className="border-b border-gray-200 last:border-b-0"
						>
							<div
								className="flex items-center cursor-pointer p-4"
								onClick={() => toggleQuestion(index)}
							>
								<span className="font-bold mr-2 font-plusJakartaSans text-[12px] text-[#000000] opacity-70">
									Q.
								</span>
								<p className="font-bold w-[10%] font-plusJakartaSans text-[12px] text-[#000000] opacity-70 flex-grow break-words overflow-hidden line-clamp-2">
									{question?.question}
								</p>
								<svg
									className={`w-3 h-3 transition-transform duration-200 ${
										activeIndex === index ? "transform rotate-180" : ""
									}`}
									fill="none"
									stroke="currentColor"
									viewBox="0 0 24 24"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="2"
										d="M19 9l-7 7-7-7"
									></path>
								</svg>
							</div>
							{activeIndex === index && (
								<div className="p-4 bg-white">
									<p className="font-plusJakartaSans font-semibold text-[12px] opacity-70 break-words overflow-hidden line-clamp-2">
										{question?.answer}
									</p>
								</div>
							)}
						</div>
					);
				})
			)}
			<button className="font-plusJakartaSans font-medium pt-2 pb-2 pl-4 pr-4 border border-[#707070] text-[13px] gap-1 mt-5 ml-auto mr-auto flex items-center text-center justify-center rounded-full">
				View All <IoMdArrowForward />
			</button>
		</div>
	);
};

export default QuestionAccordionScholarShip;
