import React, { useState } from "react";
import { IoSearchSharp } from "react-icons/io5";
import { medias } from "../../../../config/Resources";

const CourseFinder = () => {
	const StreamsAndCourses = [
		{
			id: 1,
			text: "Popular Streams",
		},
		{
			id: 2,
			text: "Popular Courses",
		},
	];

	const streams = [
		{
			id: 1,
			text: "Digital Marketing",
		},
		{
			id: 2,
			text: "Data Science",
		},
		{
			id: 3,
			text: "Computer Science",
		},
		{
			id: 4,
			text: "Psychology",
		},
		{
			id: 5,
			text: "Computer Science",
		},
		{
			id: 6,
			text: "Nursing",
		},
		{
			id: 7,
			text: "Information Technology",
		},
	];

	const courses = [
		{
			id: 1,
			text: "Economics",
		},
		{
			id: 2,
			text: "Chemistry",
		},
		{
			id: 3,
			text: "Mathematics",
		},
		{
			id: 4,
			text: "Physics",
		},
		{
			id: 5,
			text: "Chemistry",
		},
	];

	const [selectedId, setSelectedId] = useState(StreamsAndCourses[0].id);

	const selectedItems = selectedId === 1 ? streams : courses;

	return (
		<div className="w-[100%] rounded-xl flex overflow-hidden shadow-lg mt-5 bg-[#E5EFFF]">
			<div className="pl-5 pt-5 pb-5 w-[75%]">
				<p className="font-plusJakartaSans font-extrabold mb-2 text-[12.5px]">
					Course Finder - Search from 20k+ Courses and 35+ Streams
				</p>
				<div className="relative w-[81%]">
					<input
						type="text"
						className="pl-8 pr-4 py-2 font-plusJakartaSans font-medium text-[11.5px] rounded-md text-[#787878] w-full bg-[#FAFAFA]"
						placeholder="Search Courses"
					/>
					<IoSearchSharp className="absolute left-3 top-1/2 transform -translate-y-1/2 text-[#635D5D]" />
				</div>
				<div className="flex mt-4 items-center text-center gap-2">
					{StreamsAndCourses.map((data) => {
						const isSelected = data.id === selectedId;
						return (
							<div
								key={data.id}
								onClick={() => setSelectedId(data.id)}
								className={`cursor-pointer rounded-t-xl pt-2 pb-2 pl-5 pr-5 ${
									isSelected ? "bg-[#00000080]" : "bg-transparent"
								}`}
							>
								<p
									className={`font-plusJakartaSans font-medium text-[10px] ${
										isSelected ? "text-[#FBFCFF]" : "text-[#000000]"
									}`}
								>
									{data.text}
								</p>
							</div>
						);
					})}
				</div>
				<div className="border border-[#C7C7C7]"></div>
				<div className="mt-3 flex flex-wrap gap-2">
					{selectedItems.map((item) => (
						<div
							key={item.id}
							className="bg-[#FFFFFF] rounded-full py-1 px-4 flex-shrink-0"
						>
							<p className="font-plusJakartaSans font-medium text-[9.5px] text-[#000000]">
								{item.text}
							</p>
						</div>
					))}
				</div>
			</div>
			<div className="w-[30%] mr-3 mt-4 mb-2">
				<img
					className="w-[100%] h-[100%]"
					src={medias.images.College.CourseFinder}
					alt="find"
				/>
			</div>
		</div>
	);
};

export default CourseFinder;
