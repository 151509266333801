import React from "react";

// const data = `
//   <ol className="">
//     <li>
//       1.Take out time for socializing. It will help you communicate your views,
//       opinions, and objectives with others and come up with win-win solutions
//       for the problems.
//     </li>
//     <li>
//       2.Networking. Invest time and effort into building long-lasting
//       relationships with your friends, teachers, and others around you. It will
//       help you build coveted networking skills.
//     </li>
//     <li>
//      3. Use big picture thinking to find new opportunities. It will help you break
//       out of details and may give you the attention and recognition you crave.
//     </li>
//   </ol>`;

const AccordionCard = ({ img, title, desc }) => {
  return (
    <div>
      <div className="flex gap-2.5 items-center">
        {img}
        <h6 className="text-[18px] font-bold mt-2">{title}</h6>
      </div>
      <div
        className="text-[14.5px]"
        dangerouslySetInnerHTML={{ __html: desc }}
      ></div>
    </div>
  );
};

export default AccordionCard;
