/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { medias } from "../../config/Resources";

const TabsDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(null);

  useEffect(() => {
    setSelectedTab(tabs[0]); 
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const selectTab = (tab) => {
    setSelectedTab(tab);
    toggleDropdown();
  };

  const tabs = [
    { name: "Academics", id: 1, imageUrl: medias.images.Profile.Academy },
    { name: "Sports", id: 2, imageUrl: medias.images.Profile.Sport1 },
    { name: "Meals", id: 3, imageUrl: medias.images.Profile.Meals },
    { name: "Household", id: 4, imageUrl: medias.images.Profile.HouseHold },
    { name: "Others", id: 5, imageUrl: medias.images.Profile.Others },
  ];

  return (
    <div className="relative inline-block text-left left-12 mb-5">
      <div>
        <button
          type="button"
          className="inline-flex justify-center w-[200px] rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
          id="options-menu"
          aria-expanded="true"
          aria-haspopup="true"
          onClick={toggleDropdown}
        >
          {selectedTab ? (
            <div className="flex items-center">
              <img
                className="w-6 h-6 mr-2"
                src={selectedTab.imageUrl}
                alt={selectedTab.name}
              />
              <p className="text-sm font-plusJakartaSans font-semibold">
              {selectedTab.name}
              </p>
            </div>
          ) : (
            "Dropdown"
          )}
        </button>
      </div>

      {isOpen && (
        <div
          className="origin-top-right absolute right-0 mt-2 w-[200px] rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
        >
          <div className="py-1" role="none">
            {tabs.map((tab) => (
              <div
                key={tab.id}
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
                role="menuitem"
                onClick={() => selectTab(tab)}
              >
                <div className="flex items-center">
                  <img
                    className="w-6 h-6 mr-2"
                    src={tab.imageUrl}
                    alt={tab.name}
                  />
                 <p className="text-sm font-plusJakartaSans font-semibold">{tab.name}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default TabsDropdown;
