/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import { medias } from "../../config/Resources";
import axiosInstance from "../../config/Axios/axiosInstance";
import axios from "axios";
import { FaPlus } from "react-icons/fa";
import { toast } from "react-toastify";
import { API_URLS } from "../../config/API_URLS";

function CreateGoalAndMilestones({ setData }) {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [category, setCategory] = useState("");
	const [milestones, setMilestones] = useState([
		{ name: "", duration_type: "", start_date: "", end_date: "", notes: "" },
	]);
	const [editingIndex, setEditingIndex] = useState(0);

	const toggleModal = () => {
		setIsModalOpen(!isModalOpen);
	};

	const handleCategoryChange = (e) => {
		setCategory(e.target.value);
	};

	const handleMilestoneChange = (index, key, value) => {
		const updatedMilestones = [...milestones];
		updatedMilestones[index][key] = value;
		setMilestones(updatedMilestones);
	};

	const addMilestone = () => {
		const updatedIndex = editingIndex + 1;
		setMilestones([
			...milestones,
			{ name: "", durationType: "", start_date: "", end_date: "", notes: "" },
		]);
		setEditingIndex(updatedIndex);
		toast("Milestone added successfully", { type: "success" });
	};

	const constructPayload = () => {
		return {
			passionData: {
				category: category,
				milestones: milestones,
			},
		};
	};

	const getAcademicRoadMapData = async () => {
		try {
			const res = await axiosInstance.get(
				`${API_URLS.baseURL}/getPassionRoadmap?type=`
			);
			setData(res.result);
			console.log(res, "responseData");
		} catch (error) {
			console.log(error);
		}
	};

	const createActivity = async () => {
		try {
			const payload = constructPayload();
			await axiosInstance.post(
				`${API_URLS.baseURL}/setPassionRoadmap`,
				payload
			);

			toast("Activity is created successfully", { type: "success" });
			setIsModalOpen(false);
			getAcademicRoadMapData();
			setMilestones("");
			setCategory("");
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<div>
			<button
				data-modal-target="default-modal"
				onClick={toggleModal}
				className="block mb-5 text-black bg-white border border-black focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
				type="button"
			>
				Create Goal
			</button>
			{isModalOpen && (
				<div
					id="default-modal"
					tabIndex="-1"
					aria-hidden="true"
					className="fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full h-screen bg-black bg-opacity-50"
				>
					<div className="relative p-4 w-full max-w-xl">
						<div className="relative bg-[#E4F6FF]  rounded-lg shadow dark:bg-gray-700">
							<div className="flex items-center justify-between p-4 md:p-5 rounded-t dark:border-gray-600">
								<button
									type="button"
									onClick={toggleModal}
									className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
									data-modal-hide="default-modal"
								>
									<svg
										className="w-3 h-3"
										aria-hidden="true"
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 14 14"
									>
										<path
											stroke="currentColor"
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="2"
											d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
										/>
									</svg>
									<span className="sr-only">Close modal</span>
								</button>
							</div>
							<div className="flex text-center ml-5">
								<img
									className="w-8 h-8"
									src={medias.images.Profile.category}
									alt="image"
								/>
								<h6 className="ml-1 mt-2">Main_Goal</h6>
							</div>
							<div className="p-4 md:p-5 space-y-4 max-h-96 overflow-y-auto scrollbar-hide">
								<input
									type="text"
									value={category}
									onChange={handleCategoryChange}
									className="block w-full p-2 rounded-lg focus:outline-none"
									placeholder="Goal"
								/>
								<div>
									<hr className="border-t border-gray-300 my-4 mt-10 mb-12" />
								</div>
								<button
									onClick={addMilestone}
									className=" block w-[20%] text-black bg-[#FFA733] font-medium rounded-full text-sm px-1 py-1 text-center"
								>
									<p className="font-plusJakartaSans flex align-center items-center justify-center text-sm font-bold">
										<FaPlus className="mr-2" /> Add
									</p>
								</button>
								{milestones?.map((milestone, index) => (
									<>
										{index === editingIndex && (
											<>
												<div>
													<p className="font-plusJakartaSans text-sm font-semibold">
														Milestone Name
													</p>
													<input
														type="text"
														value={milestone.name}
														onChange={(e) =>
															handleMilestoneChange(
																index,
																"name",
																e.target.value
															)
														}
														className="block mt-1 w-full p-2 rounded-lg"
														placeholder="Name"
													/>
												</div>
												<div>
													<p className="font-plusJakartaSans text-sm font-semibold">
														Duration
													</p>
													<div className="border rounded-lg mt-2">
														<select
															value={milestone.duration_type}
															onChange={(e) =>
																handleMilestoneChange(
																	index,
																	"duration_type",
																	e.target.value
																)
															}
															className="block w-full p-2 mt-1 rounded-lg"
														>
															<option value="2 week">2 week</option>
															<option value="3 months">3 months</option>
															<option value="6 months">6 months</option>
														</select>
													</div>
												</div>
												<div className="flex justify-between text-center mt-2">
													<div>
														<p className="font-plusJakartaSans text-sm font-semibold">
															Start Date
														</p>
														<input
															type="date"
															value={milestone.start_date}
															onChange={(e) =>
																handleMilestoneChange(
																	index,
																	"start_date",
																	e.target.value
																)
															}
															className="block w-full p-2 rounded-lg"
														/>
													</div>
													<div>
														<p className="font-plusJakartaSans text-sm font-semibold">
															End Date
														</p>
														<input
															type="date"
															value={milestone.end_date}
															onChange={(e) =>
																handleMilestoneChange(
																	index,
																	"end_date",
																	e.target.value
																)
															}
															className="block w-full p-2 ml-2 rounded-lg"
														/>
													</div>
												</div>
												<div>
													<p className="font-plusJakartaSans text-sm font-semibold">
														Notes
													</p>
													<textarea
														value={milestone.notes}
														onChange={(e) =>
															handleMilestoneChange(
																index,
																"notes",
																e.target.value
															)
														}
														className="block w-full p-2 rounded-lg"
														placeholder="Notes"
														rows="4"
													></textarea>
												</div>
											</>
										)}
									</>
								))}
								<button
									onClick={createActivity}
									className="block w-[] mt-2 bg-[#F8D865]  text-black border border-black font-medium rounded-lg text-sm px-5 py-2.5 text-center"
								>
									Submit
								</button>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
}

export default CreateGoalAndMilestones;
