import React from "react";
import { HiMiniArrowLongRight } from "react-icons/hi2";

const SliderCard = ({
  btnText,
  btnBg,
  mainContainerBg,
  img,
  title1,
  title2,
  btnTextClass,
}) => {
  return (
    <div
      className={` ${
        mainContainerBg || " bg-red-200 "
      }  min-h-[150px] min-w-[440px] max-w-[440px] rounded-3xl`}
    >
      <div className="flex justify-between h-full">
        <div className="flex flex-col justify-between p-4 ">
          <div className="text-[21.5px]">
            <p className="font-[PlusJakartaSans-Bold]">{title1}</p>
            <p className="font-[PlusJakartaSans-Bold]"> {title2}</p>
          </div>
          <div
            className={` px-6 py-2 cursor-pointer ${
              btnBg || " bg-[#f39822] "
            } ${
              btnTextClass || "text-white "
            } rounded-[28px] flex items-center text-center gap-x-1 `}
          >
            <button className="text-[15px]"> {btnText || "Connect Now"}</button>
            <HiMiniArrowLongRight />
          </div>
        </div>
        <div className="h-full">
          <div className="flex flex-col justify-center items-center h-full p-[10px]">
            {img}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SliderCard;
