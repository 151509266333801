/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { medias } from "../../config/Resources";

const MilestoneDropDown = () => {
	const [isOpen, setIsOpen] = useState(false);
	const [selectedTab, setSelectedTab] = useState(null);

	useEffect(() => {
		// Set default selected tab when component mounts
		setSelectedTab(tabs[0]); // Assuming "Academics" is the first tab in the tabs array
	}, []);

	const toggleDropdown = () => {
		setIsOpen(!isOpen);
	};

	const selectTab = (tab) => {
		setSelectedTab(tab);
		toggleDropdown(); // Close the dropdown after selecting an item
	};

	const tabs = [
		{ name: "Milestone1", id: 1 },
		{ name: "Milestone2", id: 2 },
		{ name: "Milestone3", id: 3 },
		{ name: "Milestone4", id: 4 },
		{ name: "Milestone5", id: 5 },
	];

	return (
		<div className="relative inline-block text-left left-12 ">
			<div>
				<button
					type="button"
					className="inline-flex justify-center w-auto rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
					id="options-menu"
					aria-expanded="true"
					aria-haspopup="true"
					onClick={toggleDropdown}
				>
					{selectedTab ? (
						<div className="flex items-center text-sm font-plusJakartaSans font-semibold">

							{selectedTab.name}
						</div>
					) : (
						"Dropdown"
					)}
					{/* You can use an icon here if you want */}
				</button>
			</div>

			{isOpen && (
				<div
					className="origin-top-right absolute right-0 mt-2 w-auto rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
					role="menu"
					aria-orientation="vertical"
					aria-labelledby="options-menu"
				>
					<div className="py-1" role="none">
						{tabs.map((tab) => (
							<div
								key={tab.id}
								className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
								role="menuitem"
								onClick={() => selectTab(tab)}
							>
								<div className="flex items-center">
									<p className="text-sm font-plusJakartaSans font-semibold"> {tab.name}</p>
								</div>
							</div>
						))}
					</div>
				</div>
			)}
		</div>
	);
};

export default MilestoneDropDown;
