import React from "react";
import { medias } from "../../config/Resources";
import Image from "../Image";
import Text from "../Text";
import { guidancePageContant } from "../../config/pageContent";
import { useLocation } from "react-router-dom";

const Footer = () => {
  const { pathname } = useLocation();

  const checkLocation = pathname.includes("mentorship");
  //   console.log("location", pathname, checkLocation);

  return (
    <div
      className={`pt-[132px] pb-[87px] pl-[169px] pr-[103px] ${
        checkLocation ? "bg-[#01150D]" : "bg-[#040421]"
      }  border border-[#707070]`}
    >
      <div className="flex justify-between">
        <div>
          <Image src={medias.images.footer.FooterLogo} alt="FooterLogo" />
        </div>
        <div className="flex gap-[16px] items-center">
          <Text className="text-[#FFFFFF] opacity-60 text-[18px]">Follow</Text>
          <div className="p-[12px] bg-[#FFFFFF] flex justify-center items-center h-[51px] w-[51px] rounded-full bg-opacity-20">
            <Image
              src={medias.images.footer.facebook}
              alt="facebook"
              className="bg-white rounded-full"
            />
          </div>
          <div className="p-[12px] bg-[#FFFFFF] rounded-full h-[51px] w-[51px] flex justify-center items-center bg-opacity-20">
            <Image src={medias.images.footer.instagram} alt="instagram" />
          </div>
          <div className="p-[12px] bg-[#FFFFFF] h-[51px] w-[51px] flex justify-center items-center rounded-full bg-opacity-20">
            <Image src={medias.images.footer.youtube} alt="youtube" />
          </div>
          <div className="p-[12px] bg-[#FFFFFF] h-[51px] w-[51px] flex justify-center items-center rounded-full bg-opacity-20">
            <Image src={medias.images.footer.linkedin} alt="linked" />
          </div>
        </div>
      </div>
      <div className="mt-[46px] grid gap-y-[75px] gap-x-[125px] grid-cols-5">
        {guidancePageContant.Face11.footerInfo.map((items) => {
          return (
            <div className="">
              <div className="text-[#FFFFFF] text-[22px] font-semibold">
                {items.Title}
              </div>
              <div className="text-[17px] opacity-60 text-white mt-[35px] leading-[35px] font-light">
                <ul>
                  {items.pointes.map((pointes) => {
                    return <li>{pointes}</li>;
                  })}
                </ul>
              </div>
            </div>
          );
        })}
      </div>
      <div className="flex  items-center gap-[41px] text-white mt-[48px]">
        <Text className="text-[25px]">Download the Unigoal app</Text>
        <div className="flex gap-[16px]">
          <Image src={medias.images.footer.googlePlay} alt="googlePlay" />
          <Image src={medias.images.footer.appStore} alt="appStore" />
        </div>
      </div>
      <div className="border-t mt-[34px] pt-[35px] flex gap-[73px] text-[18px] font-light">
        {guidancePageContant.Face11.lastData.map((items) => {
          return <Text className="text-[#fff] opacity-60">{items.title}</Text>;
        })}
        <Text className="text-[#fff] opacity-60 ml-auto mt-[10px]">
          {guidancePageContant.Face11.copyRight}
        </Text>
      </div>
    </div>
  );
};

export default Footer;
