import CareerDetails from "../../Components/CareersDetail/Careerdetail";

function Careers() {
	return (
		<div className="bg-[#F3F6FF] h-auto">
 <CareerDetails />
		</div>
	);
}

export default Careers;
