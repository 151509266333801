import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

export default function SelectDropdown({ classNames, options }: any) {
  const [age, setAge] = React.useState("#");

  const handleChange = (event: SelectChangeEvent) => {
    setAge(event.target.value);
  };

  return (
    <FormControl
      // sx={{ m: 1, minWidth: 130 }}
      size="small"
      className={`!border-[2px] !border-gray-200 !border-solid rounded-lg ${classNames} `}
    >
      {/* <InputLabel id="demo-select-small-label">Age</InputLabel> */}
      <Select
        labelId="demo-select-small-label"
         value={age}
        // label="Age"
       className = "font-plusJakartaSans font-medium text-[12px]"
        onChange={handleChange}
      >
        {options?.map((option: any, index: any) => {
          return (
            <MenuItem className="font-plusJakartaSans font-medium text-[12px]" key={index} value={option?.value}>
              {option?.name}
            </MenuItem>
          );
        })} 
        {/* <MenuItem value={"2"}>Pricing Low to High</MenuItem>
        <MenuItem value={"3"}>Pricing High to Low</MenuItem>
        <MenuItem value={"4"}>User Rating</MenuItem> */}
      </Select>
    </FormControl>
  );
}
