import { ArrowForwardIos } from "@mui/icons-material";
import { Button } from "@mui/material";
import React from "react";
import { medias } from "../../../config/Resources";
import Image from "../../Image";
import Text from "../../Text";
import { guidancePageContant } from "../../../config/pageContent";

const Face11 = () => {
  return (
    <>
      <div className="pr-[100px] pl-[200px]">
        <div className="flex justify-center pt-[204px]">
          <Text className="text-[47px] font-bold">
            {guidancePageContant.Face8.realStoriesOfRealPeople}
          </Text>
        </div>
        <div className="flex gap-[29.9px] mt-[51px]">
          <div className="w-[17%] h-fit ">
            <Image
              // src={medias.images.guidance.Face6.MetaMentor}
              src={`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSB1FGz88PGlKDZyeT5c59OPCBrnZMFbSMnux3t05w0ZI0Zatiz872MXMisEM-R2jAZWpM&usqp=CAU`}
              className="w-full border-l-[12px] border-[#2771E7]"
              alt="MetaMentor"
            />
            <div className="text-[30px] tracking-wide text-nowrap PlusJakartaSans-ExtraBoldCustom font-extrabold">
              Carolyn Willms
              <Text className="text-[12px] font-normal">
                Global Accountability Officer
              </Text>
            </div>
          </div>
          <div className="w-[60%] leading-[35px] text-[19px] font-light">
            Indian Institute of Technology, Delhi or IIT Delhi is an Institute
            of Eminence offering about 102 undergraduate, postgraduate,
            integrated and doctoral degree courses. Indian Institute of
            Technology, Delhi courses include B.Tech, M.Tech, M.Sc and Ph.D in
            various streams of Engineering, Physical Sciences, Management,
            Humanities, and Social Sciences. Students seeking admission for
            B.Tech in any stream must appear for JEE Advanced and JoSaa
            counselling. Students completing their degrees are offered IIT Delhi
            placements to prestigious organisations at a salary package ranging
            from Rs. 26 LPA to Rs. 40 LPA (approximately).
          </div>
          <div className="w-[100px]">
            <Image
              src={medias.images.guidance.Face8.InvertedComa}
              alt="InvertedComa"
            />
          </div>
        </div>
        <div className="flex">
          <div className="relative w-[70%]">
            <Image
              src={medias.images.guidance.Face8.Testimonial}
              alt="Testimonial"
            />
            <Text className="absolute flex items-center gap-[10px] font-bold text-[23px] bottom-[calc(0%+45px)] left-[62px] text-[#202020] ">
              <Image
                src={medias.images.guidance.Face8.ThumbsUp}
                alt="ThumbsUp"
              />
              Testimonial
            </Text>
          </div>
          <div className="flex flex-col gap-[26px] ml-7 relative bottom-[40px]">
            <div className="flex gap-[20px] w-full">
              <Image
                className="w-[90px]"
                src={medias.images.guidance.Face8.Person1Testimony}
                alt="Person1Testimony"
              />
              <Image
                className="w-[90px]"
                src={medias.images.guidance.Face8.Person2Testimony}
                alt="Person2Testimony"
              />
            </div>
            <div className="flex items-end justify-end ">
              <Button
                className="!bg-[#2771E7] !w-[110px] !h-[45px]  flex justify-center !rounded-[0px]"
                variant="contained"
              >
                Next <ArrowForwardIos className="!w-[14px]" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Face11;
