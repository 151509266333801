function IframeVideos() {
	return (
		<div className="max-w-sm p-4 w-full bg-white rounded-md shadow dark:bg-gray-800 dark:border-gray-700">
           <p className="font-plusJakartaSans font-extrabold text-[13px] mb-2">Videos</p>
            <div>
				<iframe
					width="246"
					height="80"
					src="https://www.youtube.com/embed/ZYYBTKUPfws?si=WU4kTBShQbZAodzl"
					title="YouTube video player"
					frameborder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
					referrerpolicy="strict-origin-when-cross-origin"
					allowfullscreen
				></iframe>
				<iframe
                className="mt-2"
					width="246"
					height="80"
					src="https://www.youtube.com/embed/ZYYBTKUPfws?si=WU4kTBShQbZAodzl"
					title="YouTube video player"
					frameborder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
					referrerpolicy="strict-origin-when-cross-origin"
					allowfullscreen
				></iframe>
			</div>
		</div>
	);
}

export default IframeVideos;
