import Category from "./Category";
import OnGoingPlatform from "./OnGoingPlatform";
import ProjectSkills from "./ProjectSkill";
import Accordion from "./Accordion";
import FutureGoalCards from "./FutureGoalCards";
import FutureGoals from "./FitureGoals";
import Notes from "./Notes";
import CreateGoalAndMilestones from "./CreateGoalAndMilestones";
import { useState } from "react";

function PassionProject({ activeTab }) {
	const [datas, setData] = useState([]);
	return (
		<>	
			<div className="w-[100%] flex">
				<div className="w-[70%]">
					<Accordion activeTab={activeTab} datas={datas} setData={setData}/>
				</div>
				<div className="w-[30%] mt-10">
				<CreateGoalAndMilestones setData={setData}/>
					<FutureGoalCards />
					<FutureGoals />
				</div>
			</div>
			<OnGoingPlatform />
			<ProjectSkills />
			<div className=" flex justify-between mt-5 mb-1 ml-2 mr-5">
				<h1 className="ml-8 font-quitery font-normal text-2xl">Notes</h1>
				<h1 className="text-green-500 text-xl font-plusJakartaSans font-semibold">
					Add Notes
				</h1>
			</div>
			<Notes />
		</>
	);
}

export default PassionProject;
