import React, { useState } from "react";
import { medias } from "../../config/Resources";

const RoadMapAndDailyCards = () => {
    const [selectedCardIndex, setSelectedCardIndex] = useState(null); // State to track the selected card index

    const AcdameyDetails = [
        {
            image: medias.images.Profile.Road1,
            label: "Road Map",
        },
        {
            image: medias.images.Profile.Daily5,
            label: "Daily Planner",
        },
    ];

    const handleClick = (index) => {
        setSelectedCardIndex(index); // Update the selected card index
    };

    return (
        <>
            <div className="w-full flex mt-5">
                {AcdameyDetails?.map((detail, index) => (
                    <div
                        key={index}
                        className="flex justify-center items-center bg-white rounded-xl shadow-md overflow-hidden mt-4 ml-5"
                        style={{
                            border: selectedCardIndex === index ? "2px solid #F37400" : "2px solid #EEEEEE",
                            backgroundColor: selectedCardIndex === index ? "#F374001A" : "white",
                            minWidth: "100px",
                            maxWidth: "150px"
                        }}
                        onClick={() => handleClick(index)}
                    >
                        <div className="p-4">
                            <div className="flex items-center justify-center mb-2">
                                <img
                                    src={detail.image}
                                    alt={detail.label}
                                    className="h-13 w-13 object-cover"
                                />
                            </div>
                            <div className="text-sm text-center">
                                <p className="font-bold font-plusJakartaSans">{detail.label}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default RoadMapAndDailyCards;
