function CouncellingAgencies({ details, selectedChipName }) {
	console.log(selectedChipName, "selectedChipName");
	return (
		<div className="ml-4 mr-4 mt-5">
			<div className="flex flex-wrap gap-3 items-center text-center">
				{details?.counselling_agency?.map((chip) => {
					const counsellingAgencies = chip?.categories_name?.split(",");
					return counsellingAgencies.map((agency, index) => (
						<div key={index} className="flex pt-2">
							{selectedChipName === chip.agency_name && (
								<p className="font-plusJakartaSans rounded-full border pt-1 pb-1 pl-4 pr-4 flex text-[11px] text-center items-center gap-1 font-medium cursor-pointer border-[#D1D1D1] text-[#000000]">
									{agency.trim()}
								</p>
							)}
						</div>
					));
				})}
			</div>

			{details?.counselling_agency?.map((data, index) => {
				console.log(data.agency_name, "agegbhdfs");
				return (
					<>
						{selectedChipName === data.agency_name && (
							<>
								<div className="mt-5">
									<h6 className="font-plusJakartaSans font-bold mt-[2px] text-[12px]">
										Introduction
									</h6>
									<div
										dangerouslySetInnerHTML={{
											__html: data?.introduction,
										}}
										className="font-plusJakartaSans font-medium pt-2 text-[10.5px] text-[#000000] text-opacity-70"
									></div>
								</div>
								<div className="mt-5">
									<h6 className="font-plusJakartaSans font-bold mt-[2px] text-[12px]">
										Quota Reservation
									</h6>
									<div
										dangerouslySetInnerHTML={{
											__html: data?.quota_reservation,
										}}
										className="font-plusJakartaSans font-medium pt-2 text-[10.5px] text-[#000000] text-opacity-70"
									></div>
								</div>
								<div className="mt-5">
									<h6 className="font-plusJakartaSans font-bold mt-[2px] text-[12px]">
										Seat Matrix
									</h6>
									<div
										dangerouslySetInnerHTML={{
											__html: data?.seat_matrix,
										}}
										className="font-plusJakartaSans font-medium pt-2 text-[10.5px] text-[#000000] text-opacity-70"
									></div>
								</div>
								<div className="mt-5">
									<h6 className="font-plusJakartaSans font-bold mt-[2px] text-[12px]">
										Checklist
									</h6>
									<div
										dangerouslySetInnerHTML={{
											__html: data?.checklist,
										}}
										className="font-plusJakartaSans font-medium pt-2 text-[10.5px] text-[#000000] text-opacity-70"
									></div>
								</div>
								<div className="mt-5">
									<h6 className="font-plusJakartaSans font-bold mt-[2px] text-[12px]">
										Choices
									</h6>
									<div
										dangerouslySetInnerHTML={{
											__html: data?.choices,
										}}
										className="font-plusJakartaSans font-medium pt-2 text-[10.5px] text-[#000000] text-opacity-70"
									></div>
								</div>
								<div className="mt-5">
									<h6 className="font-plusJakartaSans font-bold mt-[2px] text-[12px]">
										Counselling Process
									</h6>
									<div
										dangerouslySetInnerHTML={{
											__html: data?.counselling_process,
										}}
										className="font-plusJakartaSans font-medium pt-2 text-[10.5px] text-[#000000] text-opacity-70"
									></div>
								</div>
								<div className="mt-5">
									<h6 className="font-plusJakartaSans font-bold mt-[2px] text-[12px]">
										Cut_off Terminology
									</h6>
									<div
										dangerouslySetInnerHTML={{
											__html: data?.cut_off_imp_terminology,
										}}
										className="font-plusJakartaSans font-medium pt-2 text-[10.5px] text-[#000000] text-opacity-70"
									></div>
								</div>
								<div className="mt-5">
									<h6 className="font-plusJakartaSans font-bold mt-[2px] text-[12px]">
										Cut_off Introduction
									</h6>
									<div
										dangerouslySetInnerHTML={{
											__html: data?.cut_off_introduction,
										}}
										className="font-plusJakartaSans font-medium pt-2 text-[10.5px] text-[#000000] text-opacity-70"
									></div>
								</div>
								<div className="mt-5">
									<h6 className="font-plusJakartaSans font-bold mt-[2px] text-[12px]">
										Cut_off Merit List
									</h6>
									<div
										dangerouslySetInnerHTML={{
											__html: data?.cut_off_merit_list,
										}}
										className="font-plusJakartaSans font-medium pt-2 text-[10.5px] text-[#000000] text-opacity-70"
									></div>
								</div>
								<div className="mt-5">
									<h6 className="font-plusJakartaSans font-bold mt-[2px] text-[12px]">
										Cut_off Top Colleges
									</h6>
									<div
										dangerouslySetInnerHTML={{
											__html: data?.cut_off_top_colleges,
										}}
										className="font-plusJakartaSans font-medium pt-2 text-[10.5px] text-[#000000] text-opacity-70"
									></div>
								</div>
								<div className="mt-5">
									<h6 className="font-plusJakartaSans font-bold mt-[2px] text-[12px]">
										Cut_off Fee Applicable
									</h6>
									<div
										dangerouslySetInnerHTML={{
											__html: data?.fee_applicable,
										}}
										className="font-plusJakartaSans font-medium pt-2 text-[10.5px] text-[#000000] text-opacity-70"
									></div>
								</div>
								<div className="mt-5">
									<h6 className="font-plusJakartaSans font-bold mt-[2px] text-[12px]">
										How to Register
									</h6>
									<div
										dangerouslySetInnerHTML={{
											__html: data?.how_to_register,
										}}
										className="font-plusJakartaSans font-medium pt-2 text-[10.5px] text-[#000000] text-opacity-70"
									></div>
								</div>
							</>
						)}
					</>
				);
			})}
		</div>
	);
}

export default CouncellingAgencies;
