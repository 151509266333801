import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
// import CloseIcon from "@mui/icons-material/Close";
import { IoCloseSharp } from "react-icons/io5";

const ListItem = styled("li")(({ theme }) => ({
	margin: theme.spacing(0.5),
}));

export default function FilterChips({ chipData, setChipData = () => {} }) {
	const handleDelete = (chipToDelete) => {
		setChipData((chips) => chips.filter((chip) => chip.id !== chipToDelete.id));
	};

	return (
		<Paper
			sx={{
				display: "flex",
				// justifyContent: "center",
				flexWrap: "wrap",
				listStyle: "none",
				p: 0,
				m: 0,
			}}
			component="ul"
			className="!shadow-none"
		>
			{chipData?.map((data) => {
				// let icon = ;
				return (
					<ListItem
						key={data?.id}
						className=" bg-[#239403] text-white rounded-[20px] pb-[6px] pt-[4px] px-3 flex items-center text-center gap-1"
					>
						<p className="miniDesk:text-[9px] text-[14px]   ">{data?.label}</p>
						<IoCloseSharp
							title="Remove"
							className="!bg-none !text-white miniDesk:text-[9px] text-[12px] miniDesk:h-[14px] miniDesk:w-[14px] h-[20px] w-[20px]  cursor-pointer relative  z-50"
							onClick={() => {
								handleDelete(data);
							}}
						/>
					</ListItem>
				);
			})}
		</Paper>
	);
}
