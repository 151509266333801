import React from "react";
import Text from "../../../Components/Text";
import { useNavigate, useLocation } from "react-router-dom";
import { Courses } from "../../../config/HeaderConstants";
import { WidgetsRounded } from "@mui/icons-material";
import classNames from "classnames";
import DrowDown1 from "../../../Components/DropDown/DropDown1";
import Menu1 from "../../../config/HeaderConstants/Menus";

type Props = {};

const HeaderBottomSection1 = (props: Props) => {
  const history = useNavigate();
  const location = useLocation();

  const handleExploreMoreClick = () => {
    // Redirect to /career-list
    history("/career-list");
  };

  return (
    <div
      className={classNames(
        "bg-transparent flex py-[15px] desktop:!py-[10px] 3xl:px-10 gap-[25px] text-center items-center font-semibold px-[85px] shadow-md",
        location.pathname !== "/" ? "bg-[#d0defd]" : ""
      )}
    >
      {Courses.map(({ title }: { title: string }, i) => {
        if (title === "Careers" || title === "Colleges" || title === "Exams" || title === "Blogs") {
          return (
            <div
              key={i}
              onClick={() =>
                history(
                  title === "Careers"
                    ? "/career-list"
                    : title === "Colleges"
                    ? "/Colleges-list"
                    : title === "Blogs"
                    ? "/blog-list"
                    : "/exam-entrance-list"
                )
              }
              className={classNames(
                location.pathname === "/" ||
                  location.pathname === "/sygnific_dev"
                  ? "text-white"
                  : "text-black",
                "cursor-pointer md:text-[10px] lg:text-[13px]"
              )}
            >
              {title}
            </div>
          );
        } else {
          return (
            <DrowDown1
              key={i}
              title={
                <Text
                  className={classNames(
                    location.pathname === "/" ||
                      location.pathname === "/sygnific_dev"
                      ? "text-white"
                      : "text-black",
                    " md:text-[10px] lg:text-[13px] "
                  )}
                >
                  {title}
                </Text>
              }
              // isDark={
              //   location.pathname !== "/"
              // }
              menu={<Menu1 />}
            />
          );
        }
      })}
      <div className="ml-auto" onClick={handleExploreMoreClick}>
        <Text
          className={classNames(
            location.pathname === "/" || location.pathname === "/sygnific_dev"
              ? "text-white"
              : "text-black",
            "flex md:text-[10px] lg:text-[13px] items-center gap-1 cursor-pointer"
          )}
        >
          <WidgetsRounded className="!rotate-90 scale-110" />
          Explore More
        </Text>
      </div>
    </div>
  );
};

export default HeaderBottomSection1;
