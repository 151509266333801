import React from 'react'
import loginSignupBgAnimation from "../../animations/gridcircle.json"
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import { KeyboardArrowLeft } from '@mui/icons-material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Login from '../../Widgets/Login';
import Register from '../../Widgets/Register';
import classNames from 'classnames';
import { medias } from '../../config/Resources';
import Image from '../../Components/Image';


type Props = {}

const LoginSignup = (props: Props) => {
  const navigate = useNavigate();

  const imagesList = [medias.images.loginSignup.LoginIcon1, medias.images.loginSignup.LoginIcon2, medias.images.loginSignup.LoginIcon3, medias.images.loginSignup.LoginIcon4, medias.images.loginSignup.LoginIcon5, medias.images.loginSignup.LoginIcon6, medias.images.loginSignup.LoginIcon7, medias.images.loginSignup.LoginIcon8, medias.images.loginSignup.LoginIcon9, medias.images.loginSignup.LoginIcon10]
  const { state } = useParams()
  return (
    <div className={classNames("h-screen overflow-auto", 'w-screen relative flex  profileBackGround')}>
      <div className='w-1/2 tab:hidden'>
        <div className='fixed -translate-x-[25%] w-screen h-screen z-0'>
          <Player
            autoplay
            speed={1.5}
            loop
            background="#0A0A49"
            src={loginSignupBgAnimation}
            style={{ height: "100vh", width: "100%" }}
          >
            <Controls
              visible={false}
              buttons={[
                "play",
                "repeat",
                "frame",
                "debug",
                "snapshot",
                "background"
              ]}
            />
          </Player>

        </div>
        <div className='relative flex items-center justify-center h-full'>
          <div className='absolute w-full'>
            <div className="flex items-center justify-center h-screen">
              <div id="main-container" className="main ">
                {
                  imagesList.map((items, index) => {
                    return <div className="circle ">
                      <Image src={items} className={`rotate-img-${index * (360 / imagesList.length)}`} alt="LoginIcon1" />
                    </div>
                  })
                }
              </div>
            </div>
          </div>

          <Image src={medias.images.header.pagelogo} alt="pagelogo" />
        </div>
      </div>
      <div className='w-1/2 tab:w-full tab:px-[10px] miniDesk:!py-[20px] miniDesk:!px-[40px] profileBackGround bg-contain  fixed top-0 h-screen overflow-auto mt- right-0 z-10  py-8 px-[68px] flex flex-col justify-start '>
        <div className='flex flex-col h-full'>
          <div onClick={(e) => {
          e.preventDefault();
          navigate(-1);
        }} className='text-[24px] miniDesk:!text-[15px] cursor-pointer w-fit mb-[17px] font-[PlusJakartaSans-Bold]'>
            <KeyboardArrowLeft className='!text-[40px] miniDesk:!text-[20px]' />
            Back to home
          </div>
          <div className=' my-auto desktop:p-[20px]  px-[46px] py-[55px] bg-white rounded-[21px] shadow-lg'>
            {
              state === "login" && <Login />
            }
            {
              state === "register" && <Register />
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoginSignup