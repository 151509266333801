import SingleIntershipDetail from "../../../Components/ProfileBuilding/DetailsInterShip";

const IntershipDetails = () => {
    return (
	<div>
   <SingleIntershipDetail />
		</div>
	);
};

export default IntershipDetails;
