import React from "react";
import Homelayout from "../../Components/Homelayout";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import PsychomatricProfileTest from "./PsychomatricProfileTest";
import CareerCounselling from "./Careercounselling";
import Home from "./Home";
import MyCollections from "./MyCollections";
import Mentorship from "./Mentorship";
import MileStoneDetails from "./ProfileBuilding/MileStoneDetails";
import IntershipDetails from "./ProfileBuilding/InternshipDetailPage";
import ViewReport from "./Report/ViewReport";

const Screens: any = {
  "career-counselling": CareerCounselling,
  "dashboard-home": Home,
  "my-colletions": MyCollections,
  mentorship: Mentorship,
  documents: MyCollections,
  "my-application": MyCollections,
  "my-courses": MyCollections,
  "rewards-badges": MyCollections,
  "order-purchases": MyCollections,
  "milestone-details": MileStoneDetails,
  "view-report": ViewReport,
  "internship-details": IntershipDetails,
};

const Profile = () => {
  const { nav } = useParams<{ nav: string }>();

  // console.log("nav", nav);
  // const searchParams : any = useSearchParams();
  const Screen = Screens[nav || "career-counselling"];
  return (
    <>
      <Homelayout>
        <Screen />
      </Homelayout>
    </>
  );
};

export default Profile;
