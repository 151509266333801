import React, { useEffect, useState } from "react";
import { medias } from "../../config/Resources";
import PercentageOptionModal from "./percentageOptionModal";
import { API_URLS } from "../../config/API_URLS";
import axiosInstance from "../../config/Axios/axiosInstance";
import { FaStar } from "react-icons/fa6";
import SubTask from "./SubtaskModal";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import DeleteActivityModal from "./DeleteActivityModal";
import EditTask from "./EditTask";
import { FaRegFileAlt } from "react-icons/fa";

const Accordion = ({
	setDatas,
	datas,
	activeTab,
	setRefresh,
	refresh,
	getTaskListData,
	receivedData,
}) => {
	const initialCompletionPercentages = JSON.parse(
		localStorage.getItem("completionPercentages")
	) || [
		{ percentage: 25, color: "#FFF053" },
		{ percentage: 25, color: "#FFF053" },
		{ percentage: 25, color: "#FFF053" },
		{ percentage: 25, color: "#FFF053" },
		{ percentage: 25, color: "#FFF053" },
	];

	const [completionPercentages, setCompletionPercentages] = useState(
		initialCompletionPercentages
	);

	const [isModalOpen5, setIsModalOpen5] = useState(false);
	const [isModalOpen6, setIsModalOpen6] = useState(false);
	const [activityId, setActivityId] = useState("");
	const [activityId1, setActivityid1] = useState("");
	const [items, setItems] = useState("");

	const toggleModal5 = () => {
		setIsModalOpen5(!isModalOpen5);
	};
	const toggleModal6 = () => {
		setIsModalOpen6(!isModalOpen6);
	};

	const handleOptionSelect = (milestoneIndex, percentage) => {
		const newCompletionPercentages = [...completionPercentages];
		newCompletionPercentages[milestoneIndex] = {
			percentage: percentage,
			color:
				percentage === 100
					? "green"
					: percentage === 75
					? "#C193DC"
					: percentage === 50
					? "#2771E7"
					: percentage === 25
					? "#FFF053"
					: "#E46934",
		};
		setCompletionPercentages(newCompletionPercentages);
	};

	const [isOpen, setIsOpen] = useState(datas?.map((_, index) => index === 0));

	const [subTaskData, setSubTaskData] = useState({});
	const api = {
		1: `${API_URLS.baseURL}/pb/getAcademicRoadmap?`,
		2: `${API_URLS.baseURL}/pb/getExtraCurricularRoadmap?`,
		5: `${API_URLS.baseURL}/getExtraCurricularRoadmap?`,
	};
	const getSubTaskData = async (searchId, index) => {
		console.log(searchId, "searchId");
		try {
			let url = api[activeTab];

			url += `searchId=${searchId || ""}&type=subTasks`;

			const res = await axiosInstance.get(url);

			setSubTaskData((prev) => ({
				...prev,
				[index]: res.result,
			}));
		} catch (error) {
			console.log(error);
		}
	};
	useEffect(() => {
		getSubTaskData();
	}, []);

	const toggleDetails = (index, searchId) => {
		const newIsOpen = [...isOpen];
		newIsOpen[index] = !newIsOpen[index];
		setIsOpen(newIsOpen);
		if (!subTaskData[index]) {
			getSubTaskData(searchId, index);
		}
	};

	const formatDate = (startDateString, endDateString) => {
		const startDate = new Date(startDateString);
		const endDate = new Date(endDateString);

		const startDay = startDate.getDate();
		const endDay = endDate.getDate();
		const month = startDate.toLocaleString("en-US", { month: "short" });
		const year = startDate.getFullYear();

		if (startDay !== endDay) {
			return `${startDay}-${endDay} ${month} ${year}`;
		} else {
			return `${startDay} ${month} ${year}`;
		}
	};

	const tabId = {
		1: "acad_activity_id",
		2: "extra_curr_activity_id",
		5: "",
	};

	const [showModal, setShowModal] = React.useState(false);
	const [selectedTaskData, setSelectedTaskData] = useState([]);

	const toggleModal = (item) => {
		setShowModal(!showModal);
		setSelectedTaskData(item);
	};

	// Update localStorage whenever completionPercentages changes
	useEffect(() => {
		localStorage.setItem(
			"completionPercentages",
			JSON.stringify(completionPercentages)
		);
	}, [completionPercentages]);

	return (
		<div className="mt-10 w-[90%] ">
			<DeleteActivityModal
				setIsModalOpen5={setIsModalOpen5}
				toggleModal5={toggleModal5}
				isModalOpen5={isModalOpen5}
				activityId={activityId}
				setRefresh={setRefresh}
				refresh={refresh}
			/>
			<EditTask
				setIsModalOpen6={setIsModalOpen6}
				toggleModal6={toggleModal6}
				isModalOpen6={isModalOpen6}
				activityId1={activityId1}
				receivedData={receivedData}
				setRefresh={setRefresh}
				getTaskListData={getTaskListData}
				refresh={refresh}
			/>
			<div className="flex justify-between mb-5 ml-10 sm:max-w-3xl">
				<p className="text-lg font-normal font-quitery">Task List</p>
				<p className="text-lg font-normal font-quitery  pl-12">Completed</p>
			</div>
			{datas?.map((item, index) => (
				<div
					className="items-center bg-white shadow-md rounded-2xl sm:max-w-4xl ml-5 mt-5"
					key={index}
				>
					<div className="mx-auto">
						<div className="overflow-x-auto">
							<table className="w-full table-auto">
								<thead>
									<tr className="text-sm font-normal text-gray-600 text-left bg-gray-50">
										<th className="px-4 py-3 flex items-center gap-1">
											<span className="font-semibold font-plusJakartaSans item -center items-center flex mt-1">
												<FaStar className="text-[#FFC107] border-black" />{" "}
												<p className="ml-1 font-plusJakartaSans font-semibold text-opacity-80">
													{item.main_task}{" "}
												</p>
											</span>
											<img
												className="w-8 h-8 ml-4 cursor-pointer"
												src={medias.images.Profile.Edit}
												alt="image2"
												onClick={() => {
													toggleModal(item);
												}}
											/>
											{activeTab === 5 && (
												<>
													<FaRegEdit
														className="w-4 h-4 text-blue-500 cursor-pointer"
														onClick={(e) => {
															console.log(item, "ndmbdf");
															setActivityid1(item);
															toggleModal6();
														}}
													/>
													<MdDelete
														onClick={() => {
															setActivityId(item);
															toggleModal5();
														}}
														className="w-4 h-4 text-red-500 cursor-pointer"
													/>
												</>
											)}
											<span className="font-plusJakartaSans ml-4 font-semibold text-opacity-80 rounded w-fit p-1 text-sm pt-[5px]">
												{formatDate(item.start_date, item.end_date)}
											</span>
										</th>
										<th className="px-4 py-3"></th>
										<th className="px-4 py-3"></th>
										<th className="px-4 py-3 flex item-center justify-center">
											<div
												className="bg-blue-600 h-2.5 rounded-full"
												style={{
													width: `${item.percentage}%`,
													backgroundColor:
														item.percentage >= 100
															? "green"
															: item.percentage >= 75
															? "#C193DC"
															: item.percentage >= 50
															? "#2771E7"
															: item.percentage >= 25
															? "#FFF053"
															: "#E46934",
												}}
											></div>

											<p className="ml-12 font-semibold font-plusJakartaSans">
												{item.percentage}%
											</p>
											{activeTab !== 5 ? (
												<div
													id={`${item.id}Toggle`}
													className={`w-4 h-4 transition-transform border rounded-full duration-300 ml-12 transform ${
														isOpen[index] ? "rotate-180" : ""
													}`}
													onClick={() => {
														toggleDetails(index, item[tabId[activeTab]]);
														setItems(item);
													}}
												>
													<svg
														className="w-4 h-4"
														xmlns="http://www.w3.org/2000/svg"
														viewBox="0 0 24 24"
													>
														<path d="M11.9997 13.1714L16.9495 8.22168L18.3637 9.63589L11.9997 15.9999L5.63574 9.63589L7.04996 8.22168L11.9997 13.1714Z" />
													</svg>
												</div>
											) : (
												<div className=" ml-6 mt-[-5%]">
													<PercentageOptionModal
														handleOptionSelect={handleOptionSelect}
														milestoneIndex={index}
														activeTab={activeTab}
														getTaskListData={getTaskListData}
														items={items}
														milestone={item}
														receivedData={receivedData}
													/>
												</div>
											)}
										</th>
									</tr>
								</thead>
								<tbody className="w-full table-auto">
									{isOpen[index] &&
										subTaskData[index]?.map((milestone, idx) => (
											<tr className=" hover:bg-[#0000000F]" key={idx}>
												<td className="px-4 py-3">
													{" "}
													<div className="flex items-center">
														<span className="font-semibold gap-1 font-plusJakartaSans flex items-center text-center text-sm">
															<img
																className="w-4 h-4"
																src={medias.images.Profile.Task}
																alt="task"
															/>{" "}
															{milestone.sub_task_name}
														</span>
														<img
															className="w-8 h-8 ml-3 cursor-pointer"
															src={medias.images.Profile.Edit}
															alt="image2"
															onClick={() => toggleModal(milestone)}
														/>
														<span className="font-semibold font-plusJakartaSans text-sm ml-5">
															{formatDate(item.start_date, item.end_date)}
														</span>
													</div>
												</td>
												<td>
													<span className="font-bold rounded w-fit p-1 font-plusJakartaSans text-xs ml-4">
														{/* {item.numbers[idx]} */}
													</span>
												</td>
												<td className="px-4 py-3"></td>
												<td className="px-4 py-3 flex align-center items-center justify-center text-sm">
													<div
														className="w-full bg-white rounded-full h-2.5 dark:bg-gray-700 mt-[6px]"
														style={{
															border: "1px solid #F3F6FF",
														}}
													>
														<div
															className="h-2.5 rounded-full"
															style={{
																width: `${completionPercentages[idx].percentage}%`,
																backgroundColor:
																	completionPercentages[idx].color,
															}}
														></div>
													</div>
													<p className="ml-8 font-semibold font-plusJakartaSans text-sm">
														{completionPercentages[idx].percentage}%
													</p>

													<div className="flex align-center justify-center ml-4">
														<PercentageOptionModal
															handleOptionSelect={handleOptionSelect}
															milestoneIndex={idx}
															activeTab={activeTab}
															getTaskListData={getTaskListData}
															items={items}
															milestone={milestone}
														/>
													</div>
												</td>
											</tr>
										))}
									{showModal && (
										<SubTask
											showModal={showModal}
											toggleModal={toggleModal}
											selectedTaskData={selectedTaskData}
										/>
									)}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			))}
		</div>
	);
};
export default Accordion;
