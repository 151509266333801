/* eslint-disable jsx-a11y/anchor-is-valid */
import { medias } from "../../config/Resources";

function ProjectSkills() {
	const ongoingData = [
		{
			title: "Master in CSS",
			imageUrl: medias.images.Profile.Proj5,
			time: "DY Statdium Sector 7",
			date: "12 Aug-14 Aug 2023",
		},
		{
			title: "Master in CSS",
			imageUrl: medias.images.Profile.Proj4,
			time: "DY Statdium Sector 7",
			date: "12 Aug-14 Aug 2023",
		},
		{
			title: "Master in CSS",
			imageUrl: medias.images.Profile.Proj3,
			time: "DY Statdium Sector 7",
			date: "12 Aug-14 Aug 2023",
		},
		{
			title: "Master in CSS",
			imageUrl: medias.images.Profile.Proj1,
			time: "DY Statdium Sector 7",
			date: "12 Aug-14 Aug 2023",
		},
		{
			title: "Master in CSS",
			imageUrl: medias.images.Profile.Proj2,
			time: "DY Statdium Sector 7",
			date: "12 Aug-14 Aug 2023",
		},
	];
	return (
		<>
			<div className="flex justify-between tracking-wide text-sm w-[91.59%] mt-12 mb-5">
				<p className="font-bold font-quitery text-[20px]">
					Project Skill Development Events
				</p>
			</div>
			<div className="flex gap-5 mt-3">
				{ongoingData?.map((items) => {
					return (
						<div className="w-[17%] rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
							<img
								className="rounded-t-lg w-fit"
								src={items.imageUrl}
								alt="Master"
							/>
							<div className="mt-4 mb-2 ml-2">
								<span
									className="flex"
								>
									<span><img className="w-[13px]" src={medias.images.Profile.Marker} alt="location" /></span><p className="ml-2 font-plusJakartaSans font-semibold text-xs ">{items.time}</p>
								</span>
							</div>
							<div className=" text-xs mt-1 mb-5 ml-2">
								<span
									className="flex"
								>
									<span><img className="w-[13px]" src={medias.images.Profile.Calender2} alt="location" /></span><p className="ml-2 font-plusJakartaSans font-semibold text-[#2DB706]">{items.date}</p>
								</span>
                            <div className="border-b-2 mt-2 border-dotted border-[#DFDFDF]"></div>
							</div>
                            <div className="mb-5 ml-1 mr-1 flex align-center justify-between items-center">
                            <p className="font-plusJakartaSans font-bold text-sm">$ 3898</p>
                            <button className="border border-red-500 text-red-500 px-4 py-2 rounded-md"><p className="font-plusJakartaSans font-bold text-xs">Buy Now</p></button>
                            </div>
						</div>
					);
				})}
			</div>
		</>
	);
}

export default ProjectSkills;
