import React from "react";
import HeaderDrops from "../../../Components/HeaderDrops";
import {
  HeaderTopSectionDropDownsLeft,
  HeaderTopSectionDropDownsRight,
} from "../../../config/HeaderConstants";

type Props = {};

const HeaderTopSection1 = (props: Props) => {
  return (
    <div className="flex justify-between pb-1 ">
      <div className="flex gap-x-[31.5px] px-20  3xl:px-10">
        {HeaderTopSectionDropDownsLeft.map(
          ({ title, Menu }: { title: string; Menu: () => JSX.Element }) => {
            return (
              <>
                <HeaderDrops title={title} menu={<Menu />} />
              </>
            );
          }
        )}
      </div>
      <div className="flex gap-x-[31.5px] px-20 pt-2 3xl:px-10">
        {HeaderTopSectionDropDownsRight.map(
          ({ title, Menu }: { title: string; Menu: () => JSX.Element }) => {
            return (
              <>
                <HeaderDrops title={title} menu={<Menu />} />
              </>
            );
          }
        )}
      </div>
    </div>
  );
};

export default HeaderTopSection1;
