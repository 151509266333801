import { GoDotFill } from "react-icons/go";
import { medias } from "../../../config/Resources";
import { IoEyeOutline } from "react-icons/io5";
import { MdKeyboardArrowRight } from "react-icons/md";

function PopularCategoryBlogs() {
	const cardData = [{}, {}, {}, {}, {}, {}];

	return (
		<>
			<div className="flex items-center border-b text-center justify-between mt-7 ml-5 mr-5 pb-3 pl-7 pr-9">
				<h1 className="font-plusJakartaSans font-bold text-[23px] flex items-center text-center gap-[5px]">
					<img
						className="w-11 h-11"
						src={medias.images.Blogs.PopularBlogs}
						alt="Fire"
					/>
					Popular Category Blogs
				</h1>
				<p className="font-plusJakartaSans pt-[11px] pb-[11px] pl-[21px] rounded-full pr-[21px] bg-[#2771E7] bg-opacity-[10%] font-semibold text-[13.5px] text-[#2771E7] flex items-center text-center gap-2">
					<span className="font-plusJakartaSans font-semibold">View all</span>
					<MdKeyboardArrowRight className="w-5 h-5" />
				</p>
			</div>
			<div className="mr-7 ml-9 mt-7 mb-5">
				<div className="grid grid-cols-2 gap-x-10 mt-6">
					{cardData.map((_item, index) => (
						<div
							key={index}
							className={`pb-4 mb-4 ${
								index < cardData.length - 2
									? "border-b-2 border-dashed border-[#707070] border-opacity-20"
									: ""
							}`}
						>
							<div className="flex items-center text-center gap-[12px]">
								<img
									className="w-[8.8rem] h-[7.8rem] mb-2 rounded-lg scale-95"
									src={medias.images.Blogs.CollegeImage}
									alt="CollegeImage"
								/>
								<div className="text-left mt-[-10px]">
									<div className="flex flex-wrap gap-[10px] mb-2">
										<p className="font-plusJakartaSans rounded-full bg-[#F37400] bg-opacity-10 pt-[5px] pb-[5px] pl-4 pr-4 inline-flex text-[13px] text-center gap-1 font-semibold cursor-pointer">
											Scholarship
										</p>
									</div>
									<p className="font-plusJakartaSans text-[17px] font-bold text-[#000000] pr-5 mb-1">
										IIT-Bombay students who can't finish 4-yr B-Tech can exit in
										3 yrs with BSc degree
									</p>
									<p className="flex items-center text-center gap-1">
										<IoEyeOutline className="w-6 h-6 mt-[-3px] text-[#000000] text-opacity-80" />
										<span className="font-plusJakartaSans font-medium text-[14.5px] text-[#000000] text-opacity-80">
											44k views
										</span>
										<GoDotFill className="text-[#000000] text-opacity-40 w-2 h-2" />
										<span className="font-plusJakartaSans font-medium text-[14.5px] text-[#000000] text-opacity-80">
											2 min read
										</span>
										<GoDotFill className="text-[#000000] text-opacity-40 w-2 h-2" />
										<span className="font-plusJakartaSans font-medium text-[14.5px] text-[#000000] text-opacity-80">
											12 May 2023
										</span>
									</p>
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
		</>
	);
}

export default PopularCategoryBlogs;
