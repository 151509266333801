import { useRef } from "react";
import { IoIosArrowDropright } from "react-icons/io";
import { medias } from "../../../../config/Resources";

function TopRecruitingCompanies({ placement }) {
	console.log(placement, "AllPlacementsData");
	const scrollContainerRef = useRef(null);

	const recrutCompanies = [
		{ id: 1, image: medias.images.College.Google },
		{ id: 2, image: medias.images.College.Infosys },
		{ id: 3, image: medias.images.College.Accenture },
		{ id: 4, image: medias.images.College.Amazon },
		{ id: 5, image: medias.images.College.Google },
		{ id: 6, image: medias.images.College.Infosys },
		{ id: 7, image: medias.images.College.Accenture },
		{ id: 8, image: medias.images.College.Amazon },
	];

	const scrollRight = () => {
		if (scrollContainerRef.current) {
			scrollContainerRef.current.scrollBy({
				left: 100,
				behavior: "smooth",
			});
		}
	};

	return (
		<div className="ml-4 mr-4 mt-6">
			<div className="flex items-center text-center gap-1 mb-1">
				<img
					className="bg-[#F0BC5E] bg-opacity-15 p-[6px] rounded-md w-9 h-9"
					src={medias.images.College.Company}
					alt="Placements"
				/>
				<h6 className="font-plusJakartaSans mt-[5.5px] font-bold text-[15px]">
					Top Recruiting Companies
				</h6>
			</div>
			<div className="relative">
				<div
					ref={scrollContainerRef}
					className="flex overflow-x-auto overflow-y-hidden scrollable-content space-x-4 py-2"
				>
					{placement?.top_companies &&
						placement.top_companies.map((company) => (
							<div
								key={company.id}
								className="flex items-center justify-center flex-shrink-0 bg-white pl-2 pr-2 rounded-md custom-shadow w-36 h-20"
							>
								<img
									className="w-24 h-24 object-contain"
									src={company.company_logo}
									alt={`Company ${company.id}`}
								/>
							</div>
						))}
				</div>
				<IoIosArrowDropright
					className="absolute bottom-[30%] right-0 transform -translate-y-1/2 w-7 h-7 bg-white text-black cursor-pointer"
					onClick={scrollRight}
				/>
			</div>
		</div>
	);
}

export default TopRecruitingCompanies;
