import { medias } from "../../../config/Resources";

function ReferEarn() {
	return (
		<div className="text-sm leading-6" style={{ marginTop: "10%" }}>
			<figure
				className="dark:border-gray-600 p-2.5 relative"
				style={{
					borderRadius: "20px",
					width: "40%",
					backgroundColor: "#2771E717",
					height: "auto",
				}}
			>
				<figcaption className="items-center">
					<div className="flex">
						<div className="flex-auto ml-2">
							<div
								className="leading-7"
								style={{
									color: "#102048",
									fontSize: "19px",
									fontWeight: "bolder",
								}}
							>
								{" "}
								Refer & Earn Upto
							</div>
							<div
								className="leading-7"
								style={{
									color: "#102048",
									fontSize: "19px",
									fontWeight: "bolder",
								}}
							>
								Rs.1000 /-
							</div>
							<div className="mt-2 ml-0 mr-2 mb-0">
								<button className="text-green-800 rounded-lg p-1 bg-[#2771E7] rounded-2xl">
									<span
										style={{
											color: "white",
											fontWeight: "bold",
											fontSize: "10px",
											display: "flex",
											paddingLeft: "20px",
											paddingRight: "20px",
										}}
									>
										Connect Now
										<img
											className="w-4 ml-1"
											src={medias.images.mentorship.rightArrow}
											alt="arrow"
											style={{ filter: "brightness(0) invert(1)" }} // Change the color of the image
										/>
									</span>
								</button>
							</div>
						</div>
					</div>
				</figcaption>
			</figure>
		</div>
	);
}

export default ReferEarn;
