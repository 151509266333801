/* eslint-disable array-callback-return */
function FilterChips({ filterData, setFilterData }) {
	const handleRemoveFilter = (filterToRemove) => {
		const updatedFilters = filterData.filter(
			(filter) => filter.id !== filterToRemove.id
		);
		setFilterData(updatedFilters);
	};
	return (
		<>
			{" "}
			{filterData.map((filter) => {
				return (
					<div className="border rounded-full w-[70%] bg-[#2771E7] mt-2">
						<h1 className="flex item-center text-center font-plusJakartaSans font-semibold text-xs text-white pt-2 pb-2 pl-3 pr-3">
							{filter.lable}
							<svg
								className="ml-2 mt-1 h-2 w-2 cursor-pointer"
								xmlns="http://www.w3.org/2000/svg"
								width="11.148"
								height="11.135"
								viewBox="0 0 11.148 11.135"
								onClick={() => handleRemoveFilter(filter)}
							>
								<g
									id="_02_User"
									data-name="02 User"
									transform="translate(0.566 0.563)"
								>
									<path
										id="Path_151859"
										data-name="Path 151859"
										d="M.486,10.071a.492.492,0,0,1-.348-.84L9.229.141a.492.492,0,0,1,.7.7L.834,9.927a.491.491,0,0,1-.348.144Z"
										transform="translate(0 0)"
										fill="#fff"
										stroke="#fff"
										stroke-width="1"
									/>
									<path
										id="Path_151860"
										data-name="Path 151860"
										d="M9.579,10.071a.491.491,0,0,1-.348-.144L.141.837a.492.492,0,0,1,.7-.7L9.927,9.231a.492.492,0,0,1-.348.84Z"
										transform="translate(-0.003 0)"
										fill="#fff"
										stroke="#fff"
										stroke-width="1"
									/>
								</g>
							</svg>
						</h1>
					</div>
				);
			})}
		</>
	);
}

export default FilterChips;
