import { Button } from "@mui/material";
import { medias } from "../../../../config/Resources";
import { FaStar } from "react-icons/fa6";
import { EastRounded } from "@mui/icons-material";
import { IoIosArrowDropright } from "react-icons/io";
import { TfiArrowCircleRight } from "react-icons/tfi";
import React, { useRef, useState } from "react";
import { Controls, Player } from "@lottiefiles/react-lottie-player";
import Animations from "../../../../animations/animation_lkatyghq.json";

function EngineeringColleges() {
    const DegreeOffers = [
        { id: 1, text: "B.tech" },
        { id: 2, text: "M.tech" },
        { id: 3, text: "MBA" },
        { id: 4, text: "MCA" },
        { id: 5, text: "M.tech" },
        { id: 6, text: "BCA" },
        { id: 7, text: "B.tech" },
        { id: 8, text: "M.tech" },
    ];

    const rating = 4;

    const degreeOffersRefs = useRef([]);

    const scrollDegreeOffers = (index) => {
        if (degreeOffersRefs.current[index]) {
            degreeOffersRefs.current[index].scrollBy({
                left: 100, // Adjust the scroll distance as needed
                behavior: 'smooth'
            });
        }
    };

    return (
        <div className="ml-5">
            <div
                className="max-w-full rounded-md overflow-hidden shadow-lg bg-cover bg-center"
                style={{
                    backgroundImage: `url(${medias.images.College.EngineerindCollegesBg})`,
                }}
            >
                <div className="px-6 py-4">
                    <div className="">
                        <p className="font-semibold font-plusJakartaSans flex item-center text-center gap-1 text-[17px]">
                        <Player
									autoplay
									speed={1.5}
									loop
									src={Animations}
									style={{ height: "45px", width: "45px" }}
								>
									<Controls
										visible={false}
										buttons={[
											"play",
											"repeat",
											"frame",
											"debug",
											"snapshot",
											"background",
										]}
									/>
								</Player> <span className="mt-[10px]">Top 20</span>
                        </p>
                        <p className="font-plusJakartaSans font-extrabold flex item-center text-center text-[25px] text-[#000000]">
                            (Engineering Colleges) <img className="w-10 h-10 mt-[2px]" src={medias.images.College.Pen1} alt="Pen1"/>
                        </p>
                    </div>
                    <div className="mt-4 overflow-x-auto scrollable-content relative">
                        <div className="flex space-x-4">
                            {[...Array(8)].map((_, index) => (
                                <div
                                    key={index}
                                    className="flex-shrink-0 bg-white pt-3 rounded-md shadow-md min-w-[230px]"
                                >
                                    <div className="flex gap-2.5 pr-3 pl-3">
                                        <img
                                            className="w-12 h-12 rounded-lg border border-[#E1E1E1]"
                                            src={medias.images.College.Image11}
                                            alt="courses"
                                        />
                                        <div className="">
                                            <p className="font-semibold text-[#2F2D3A] font-plusJakartaSans text-[19px]">
                                                IIT Bombay
                                            </p>
                                            <p className="font-medium text-[#000000] -ml-1 mt-0.5 flex items-center text-center font-plusJakartaSans text-[11px]">
                                                <img
                                                    className="w-4 h-4"
                                                    src={medias.images.Profile.Location}
                                                    alt="location"
                                                />
                                                IIT Delhi Main Rd, Hauz Khas, Delhi 110016
                                            </p>
                                        </div>
                                    </div>
                                    <div>
                                        <p className="ml-[4px] mt-[10px] flex items-center text-center pr-3 pl-3">
                                            <span className="font-plusJakartaSans font-extrabold text-[12.5px] mt-[2.2px]">
                                                4.0
                                            </span>
                                            <div className="flex ml-1">
                                                {[1, 2, 3, 4, 5].map((star) => (
                                                    <FaStar
                                                        key={star}
                                                        className="h-3 w-3"
                                                        color={star <= rating ? "#FFC000" : "#DFDFDF"}
                                                    />
                                                ))}
                                            </div>
                                        </p>
                                    </div>
                                    <div className="relative pr-3 pl-3">
                                        <p className="font-plusJakartaSans ml-[3.5px] mt-2 font-medium text-[11.5px] text-opacity-80">
                                            Degree Offers
                                        </p>
                                        <div className="overflow-x-auto max-w-[300px] scrollable-content" ref={(ref) => { degreeOffersRefs.current[index] = ref; }}>
                                            <div className="flex space-x-2 pt-3 pl-[4px] rounded-md">
                                                {DegreeOffers.map((offer) => (
                                                    <div
                                                        key={offer.id}
                                                        className="border border-[#2771E7] text-[#2771E7] rounded-full font-plusJakartaSans text-[10px] pt-[4px] pb-[4px] pr-5 pl-5 shadow-sm min-w-max"
                                                    >
                                                        {offer.text}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        <div
                                            className="flex items-center pr-3 pl-3 cursor-pointer absolute top-10 transform -translate-y-1/2 right-0 bg-white"
                                            onClick={() => scrollDegreeOffers(index)}
                                        >
                                            <TfiArrowCircleRight className="h-5 w-5 text-opacity-70" />
                                        </div>
                                    </div>
                                    <div className="border border-b"></div>
                                    <div className="flex item-center text-center mb-3 mt-3 pr-3 pl-3 justify-between">
                                        <div className="flex text-center item-center mt-2 gap-1">
                                            <p className="text-[#239403] font-plusJakartaSans font-extrabold text-[16px]">
                                                ₹ 235,700
                                            </p>
                                            <p className="font-plusJakartaSans font-medium text-[10px] mt-[6px] text-[#000000] text-opacity-65">
                                                1st yr Fees
                                            </p>
                                        </div>
                                        <Button
                                            variant="contained"
                                            className="border font-plusJakartaSans pt-[8px] pb-[8px] pl-3 pr-3 gap-1 !h-full flex item-center text-center font-medium !text-[#fff] capitalize text-[10px] !bg-[#F37400] rounded-full"
                                            onClick={() => scrollDegreeOffers(index)}
                                        >
                                            Apply Now
                                            <EastRounded className="3xl:text-[10px] w-3 h-3 miniDesk:hidden" />
                                        </Button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EngineeringColleges;
