import React, { useState } from 'react';
import { medias } from '../../config/Resources';

const Chip = ({ setShowTab }) => {
  const [active, setActive] = useState(1);
  const chipLabels = [
    { label: 'Road Map to Careers', id:1, image: medias.images.Profile.Road },
    { label: 'Daily Activities Schedule', id:2, image: medias.images.Profile.Daily, image2: medias.images.Profile.Image1 },
    { label: 'Progress Ticker', id:3, image: medias.images.Profile.progress }
  ];

  const handleTabClick = (id) => {
    console.log(id, 'id');
    setActive(id);
    setShowTab(id);
};

  return (
    <div className="flex flex-col ml-5">
      <div className="flex">
        {chipLabels?.map(chip => (
          <div
            key={chip.label}
            className={`rounded-full pt-2 pb-2 pl-5 pr-5 cursor-pointer font-bold text-xs mr-2 ${
              active === chip.id ? 'bg-[#239403] text-white' : 'text-[#02020278]'
            }`}
            onClick={() => handleTabClick(chip.id)}
          >
            <img
              src={chip.image}
              alt={chip.label}
              className={`w-5 h-5 inline-block mr-2 ${active === chip.id && ''}`}
            />
            <span className='font-plusJakartaSans'>{chip.label}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Chip;
