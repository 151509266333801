import { medias } from "../../../../config/Resources";
/* eslint-disable jsx-a11y/img-redundant-alt */
function EntraceApplicationProcedure({ EntranceExam }) {
	return (
		<div>
			<div className="ml-4 mr-4">
				<h6 className="mt-5 font-plusJakartaSans flex items-center text-center gap-1 font-bold text-[#000000] text-[15px]">
					<img
						className="w-5 h-5"
						src={medias.images.Exam.ApplicationProcedure}
						alt="ApplicationProcedure"
					/>
					<span className="font-plusJakartaSans font-bold mt-[1px]">
						Application Procedure
					</span>
				</h6>
				<img
					className="w-40 text-[#000000] pt-1"
					src={medias.images.Profile.line1}
					alt="lin1"
				/>
				<div>
					<div>
						<div
							dangerouslySetInnerHTML={{
								__html: EntranceExam?.application_procedure,
							}}
							className="font-plusJakartaSans font-medium text-[11.5px] text-[#000000] text-opacity-70 mt-[8px]"
						></div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default EntraceApplicationProcedure;
