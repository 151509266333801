import { medias } from "../../../../config/Resources";

function EntranceSyllabusPoints({ examPattern }) {
	const AllLinks = [{}, {}, {}, {}];
	const staticText =
		"Indian Institute of Technology, Delhi or IIT Delhi is an Institute of Eminence offering about 102 undergraduate, postgraduate, integrated and doctoral degree courses. Indian Institute of Technology, Delhi courses include B.Tech, M.Tech, M.Sc and Ph.D in various streams of Engineering, Physical Sciences, Management, Humanities, and Social Sciences. Students seeking admission for B.Tech in any stream must appear for JEE Advanced and JoSaa counselling. Students completing their degrees are offered IIT Delhi placements to prestigious organisations at a salary package ranging from Rs. 26 LPA to Rs. 40 LPA (approximately). Established in 1961, IIT Delhi is one of the 23 IITs created to be Centres of Excellence for training, research and development in science, engineering and technology in India. Being an Institution of National Importance under the 'Institutes of Technology’, the institute has bagged 4th rank in overall category as per NIRF 2022 data. The Indian Institute of Technology Delhi covers an area of about 320 acres equipped with all basic infrastructural facilities. The institute also has world class and experienced faculties to ensure extraordinary learning.";
	return (
		<>
			<div className="ml-4 mr-4 mt-8">
				<p className="font-plusJakartaSans text-[13px] font-extrabold">
					Syllabus Points
				</p>

				<img
					className="w-20 h-20 mt-3"
					src={medias.images.Exam.SyllabusPoints}
					alt="Papers"
				/>
			</div>
			{examPattern?.syllabus?.map((data) =>
				data?.subjects?.map((datas) => {
					return (
						<div className="ml-4 mr-4 mt-3">
							<div>
								<div
									dangerouslySetInnerHTML={{
										__html: datas?.details,
									}}
									className="font-plusJakartaSans font-medium text-[11.5px] text-[#000000] text-opacity-70 mt-[8px]"
								></div>
							</div>
						</div>
					);
				})
			)}
			{/* <p className="font-plusJakartaSans text-[13px] font-extrabold ml-4 mr-4 mt-4">
				Another Subheading explain about Exam patterns
			</p> */}
			{/* <div className="ml-4 mr-4 mt-3">
				<div>
					<div
						dangerouslySetInnerHTML={{
							__html: staticText,
						}}
						className="font-plusJakartaSans font-medium text-[11.5px] text-[#000000] text-opacity-70 mt-[8px]"
					></div>
				</div>
			</div> */}
			{/* <p className="font-plusJakartaSans text-[13px] font-extrabold ml-4 mr-4 mt-4">
				Important Links for Exam patterns
			</p>
			<div className="ml-4 mr-4 mt-3">
				<div>
					{AllLinks.map((_, index) => {
						return (
							<div key={index} className="mt-4">
								<div className="inline-block">
									<p className="font-plusJakartaSans font-semibold text-[#2771E7] text-[13px]">
										{index + 1}.) www.iitdc.com/application
									</p>
									<hr className="border-t border-gray-300 mt-1" />
								</div>
							</div>
						);
					})}
				</div>
			</div> */}
		</>
	);
}
export default EntranceSyllabusPoints;
