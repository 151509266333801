import { medias } from "../../../../config/Resources";

function GeneralFacilities({ campusLife }) {
	const truncateText = (text, maxLength) => {
		if (text.length <= maxLength) return text;
		return text.substring(0, maxLength) + "...";
	};

	return (
		<div className="ml-4 mr-4 mt-2">
			<div className="flex items-center text-center gap-1">
				<img
					className="w-9 h-9"
					src={medias.images.College.Opportunity}
					alt="Opportunity"
				/>
				<h6 className="font-plusJakartaSans text-[#000000] mt-[5.5px] font-bold text-[13px]">
					General Facilities
				</h6>
			</div>
			<div>
				{campusLife?.map((item) =>
					item?.general_facilities?.map((data, index) => {
						return (
							<div key={item.id}>
								<div className="flex items-center mt-6 text-center gap-2">
									<div className="rounded bg-white overflow-hidden custom-shadow1 pt-[14px] pb-[14px] pl-[20px] pr-[20px] flex flex-col items-center">
										<img
											className="w-24 h-16 mb-2 rounded-md"
											src={data?.gen_facility_image}
											alt="General Facility"
										/>
										<p className="font-plusJakartaSans text-center text-xs font-semibold text-black text-opacity-55">
											{data?.gen_facility_title}
										</p>
									</div>
									<div
										dangerouslySetInnerHTML={{
											__html: truncateText(data?.gen_facility_description, 200),
										}}
										className="font-plusJakartaSans font-medium text-[#000000] text-[10.5px] text-opacity-70"
									></div>
								</div>
								{index !== data.length - 1 && (
									<div className="my-4 border-t-2 border-dashed border-gray-300"></div>
								)}
							</div>
						);
					})
				)}
			</div>
		</div>
	);
}

export default GeneralFacilities;
