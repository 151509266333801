import React from "react";
import { medias } from "../../../config/Resources";
import { PiStarFourFill } from "react-icons/pi";
import { MdVerified } from "react-icons/md";
import { MdInfoOutline } from "react-icons/md";
import { TiStarFullOutline } from "react-icons/ti";

const StudentsRatingsAndReviewsCards = () => {
	const CardsDetails = [
		{
			id: 1,
			image: medias.images.College.Infrastructure,
			Text1: "8.1/10",
			Text2: "Infrastructure",
		},
		{
			id: 2,
			image: medias.images.College.Admissions,
			Text1: "8.1/10",
			Text2: "Admissions",
		},
		{
			id: 3,
			image: medias.images.College.Accomodations1,
			Text1: "8.1/10",
			Text2: "Accomodation",
		},
		{
			id: 4,
			image: medias.images.College.Courses1,
			Text1: "8.1/10",
			Text2: "Courses",
		},
		{
			id: 5,
			image: medias.images.College.Academics,
			Text1: "8.1/10",
			Text2: "Academics",
		},
		{
			id: 6,
			image: medias.images.College.Ranking,
			Text1: "8.1/10",
			Text2: "Ranking",
		},
		{
			id: 7,
			image: medias.images.College.Placements,
			Text1: "8.1/10",
			Text2: "Placements",
		},
		{
			id: 8,
			image: medias.images.College.SocialLife,
			Text1: "8.1/10",
			Text2: "Social Life",
		},
	];

	const progressBarData = [
		{ id: 1, label: "5", value: 80 },
		{ id: 2, label: "4", value: 70 },
		{ id: 3, label: "3", value: 50 },
		{ id: 4, label: "2", value: 30 },
		{ id: 5, label: "1", value: 10 },
	];

	return (
		<div className="w-full rounded-xl overflow-hidden shadow-lg mt-5 bg-white">
			<div className="relative h-64 flex">
				<img
					src={medias.images.College.Reviews}
					alt="reviews"
					className="w-full h-full object-cover"
				/>
				<div className="absolute top-[25%] left-[4%]">
					<div className="flex items-center gap-2">
						<img
							className="w-20 h-20 rounded-full"
							src={medias.images.College.ImageLogo}
							alt="courses"
						/>
						<div>
							<p className="font-extrabold text-black font-plusJakartaSans text-[30px]">
								IIT, Delhi
							</p>
							<span className="flex items-center text-center gap-1">
								{[...Array(6)].map((_, index) => (
									<PiStarFourFill
										key={index}
										className="text-[#F37400] h-5 w-5 mt-[4px]"
									/>
								))}
								<span className="font-plusJakartaSans font-bold text-[18px]">
									8.7/10
								</span>
							</span>
						</div>
					</div>
					<div className="flex items-center gap-1 text-center ml-1 mt-4">
						<MdVerified className="text-[#2771E7] h-6 w-6" />
						<p className="font-plusJakartaSans font-bold text-[14px]">
							443 Verified Reviews
						</p>
						<MdInfoOutline className="h-5 w-5" />
					</div>
				</div>
				<div className="absolute top-0 right-10 bottom-0 flex items-center">
					<div className="grid grid-cols-4 gap-4">
						{CardsDetails.map((details, index) => (
							<div key={index} className="bg-white p-1 shadow-md rounded-xl">
								<div className="flex items-center">
									<img
										className="w-20 h-20"
										src={details.image}
										alt="courses"
									/>
									<div>
										<p className="font-bold text-black font-plusJakartaSans text-[13.3px]">
											{details.Text1}
										</p>
										<p className="font-medium text-black font-plusJakartaSans text-[12.8px]">
											{details.Text2}
										</p>
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
			<div className="pt-6 pb-6 pl-10 pr-10 flex">
				<div className="w-1/2 mt-5">
					<p className="font-plusJakartaSans text-black font-extrabold text-[20px]">
						Students Ratings & Reviews
					</p>
					<p className="flex items-center text-center gap-1 text-black mt-2">
						<TiStarFullOutline className="text-[#FFC000] mt-2 h-8 w-8" />
						<span className="text-[35px] font-plusJakartaSans font-bold">
							4.5
						</span>
						<span className="text-[25px] font-plusJakartaSans font-bold mt-4">
							/5
						</span>
					</p>
					<div className="flex items-center gap-1 text-center ml-1 mt-2">
						<MdVerified className="text-[#2771E7] h-6 w-6" />
						<p className="font-plusJakartaSans opacity-50 font-bold text-[14px]">
							443 Verified Reviews
						</p>
						<MdInfoOutline className="h-6 w-6 opacity-50" />
					</div>
				</div>
				<div className="w-1/2 mt-4 space-y-2">
					{progressBarData.map((item) => (
						<div key={item.id} className="flex items-center gap-2">
							<span className="font-semibold font-plusJakartaSans text-[14px] text-[#000000]">
								{item.label}
							</span>
							<TiStarFullOutline className="text-[#FFC000] h-4 w-4" />
							<div className="w-full bg-gray-300 rounded-full h-4">
								<div
									className="bg-black h-4 rounded-full"
									style={{ width: `${item.value}%` }}
								></div>
							</div>
							<span className="font-semibold text-[#000000] text-[14px] opacity-70 font-plusJakartaSans">
								{item.value}%
							</span>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default StudentsRatingsAndReviewsCards;
