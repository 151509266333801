/* eslint-disable jsx-a11y/img-redundant-alt */
import { FaStar } from "react-icons/fa";
// import Line from "./Line";
import { Button } from "@mui/material";
import { EastRounded } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { FaLocationDot } from "react-icons/fa6";

export function ChooseCouncellor({
	BookedMentorList,
	isAdCard,
	cardIndex,
	setModal,
	modal,
	mentor,
	setMentorData,
	isMentorPage,
}) {
	// const navigate = useNavigate();

	return (
		<div className="flex gap-5">
			<div
				className={`mt-6  ${
					isMentorPage
						? "max-w-[470px] min-w-[470px] bg-white rounded-lg "
						: "max-w-[33%] rounded-lg  "
				}    shadow-[0px_5px_16px_rgba(0,0,0,0.06)]`}
			>
				<div className="flex p-2">
					<img
						src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&amp;ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTV8fGh1bWFufGVufDB8fDB8fA%3D%3D&amp;auto=format&amp;fit=crop&amp;w=800&amp;q=60"
						alt="image"
						className="w-[140px] h-[142px] rounded-md object-cover"
					/>
					<div className="pl-2">
						<h1 className="text-lg font-bold flex capitalize text-nowrap text-ellipsis">
							Dr. Vibash Rashal
							<span className="text-sm h-6 w-6"></span>
						</h1>

						<h6
							className="mt-1 font-bold text-[12px]"
							style={{
								color: "#808080",
								display: "flex",
								alignItems: "center",
								gap: "2%",
							}}
						>
							<FaLocationDot />
							Mumbai,Maharastra
						</h6>
						<p className="mt-1 text-xs font-bold" style={{ color: "#2771E7" }}>
							Co-founder and Creative head Sygnific Careers/ Surgeon
						</p>
						{false ? (
							<div className="mt-4 flex flex-wrap text-center">
								<div className="flex justify-center items-center text-center gap-2 bg-[#F2F2F2] rounded-[6px] p-[6px] ">
									<FaStar className="text-[#F0D41B] text-[16px]" />
									<span className="text-[#000000] font-bold">4.5</span>
								</div>
								<h5 className="text-[#239403] underline underline-offset-4 m-2">
									35k+ Reviews
								</h5>
							</div>
						) : (
							<>
								{" "}
								<div className="mt-4 flex flex-wrap text-center">
									<p className="font-bold text-xs">10 Years of Experience</p>
								</div>
							</>
						)}
					</div>
				</div>
				<div className="flex justify-between px-2"></div>
				<div className="my-3"></div>

				<div className=" mb-2 text-sm  px-1" style={{ color: "#909090" }}>
					Indian Institute of Technology, Delhi ot IIT Delhi is an Institute{" "}
					<br />
					of Eminence offering about 102 undergraduate, <br />
					postgraduate, integrated and doctoral degree courses.
					<hr
						style={{
							borderTop: "1px solid #0000000F",
							width: "100%",
							marginTop: "15px",
							marginBottom: "20px",
						}}
					/>
				</div>
				<div className="flex justify-between text-center px-1.5 pb-5">
					<h4 className="text-[#000000] text-[23px] font-extrabold">
						$2000/
						<span className="text-[11px] font-normal">per month</span>
					</h4>
					{/* <Link to={"/mentorship/mentor/call"}> */}
					<Button
						onClick={() => {
							setModal(true);
							setMentorData(mentor);
						}}
						variant="contained"
						className="border !h-[45px]  flex gap-[6.2px] miniDesk:!px-[2px] font-medium capitalize !text-[#000000] !bg-[#F0BC5E52]   3xl:px-3 text-[13px]  !shadow-none px-[28px] border-[#239403] rounded-[14px]"
					>
						{/* <svg
						xmlns="http://www.w3.org/2000/svg"
						width="22.188"
						height="19.5"
						viewBox="0 0 26.188 21.5"
					>
						<path
							id="Path_340065"
							data-name="Path 340065"
							d="M3.347,13.314a10.77,10.77,0,0,1,10.487-7.98,10.77,10.77,0,0,1,10.488,7.98.763.763,0,0,1,0,.373,10.77,10.77,0,0,1-10.488,7.98,10.77,10.77,0,0,1-10.487-7.98.764.764,0,0,1,0-.373ZM13.835,3a13.1,13.1,0,0,0-12.75,9.744,3.1,3.1,0,0,0,0,1.512A13.1,13.1,0,0,0,13.835,24a13.1,13.1,0,0,0,12.75-9.744,3.1,3.1,0,0,0,0-1.512A13.1,13.1,0,0,0,13.835,3ZM8,13.5a5.833,5.833,0,1,1,5.833,5.833A5.833,5.833,0,0,1,8,13.5Zm9.333,0a3.5,3.5,0,1,1-3.5-3.5A3.5,3.5,0,0,1,17.335,13.5Z"
							transform="translate(-0.741 -2.75)"
							stroke="#fae9cb"
							stroke-width="0.5"
							fill-rule="evenodd"
						/>
					</svg> */}
						Start Chat
						{/* <EastRounded className="3xl:text-[16px] miniDesk:hidden" /> */}
					</Button>
					{/* </Link> */}
					<Button
						onClick={() => {
							setModal(true);
							setMentorData(mentor);
						}}
						variant="contained"
						className="border w-[30%] !h-[45px] text-center flex gap-x-[2.2px] leading-snug miniDesk:!px-[2px]  font-medium capitalize !text-[#000000] !bg-[#F0BC5E]   3xl:px-3 text-[13px]  !shadow-none px-[28px] border-[#239403] rounded-[14px]"
					>
						Start Now
						<EastRounded className="3xl:text-[16px] miniDesk:hidden" />
					</Button>
				</div>
			</div>
			<div
				className={`mt-6  ${
					isMentorPage
						? "max-w-[470px] min-w-[470px] bg-white rounded-lg "
						: "max-w-[33%] rounded-lg  "
				}    shadow-[0px_5px_16px_rgba(0,0,0,0.06)]`}
			>
				<div className="flex p-2">
					<img
						src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&amp;ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTV8fGh1bWFufGVufDB8fDB8fA%3D%3D&amp;auto=format&amp;fit=crop&amp;w=800&amp;q=60"
						alt="image"
						className="w-[140px] h-[142px] rounded-md object-cover"
					/>
					<div className="pl-2">
						<h1 className="text-lg font-bold flex capitalize text-nowrap text-ellipsis">
							Dr. Vibash Rashal
							<span className="text-sm h-6 w-6"></span>
						</h1>

						<h6
							className="mt-1 font-bold text-[12px]"
							style={{
								color: "#808080",
								display: "flex",
								alignItems: "center",
								gap: "2%",
							}}
						>
							<FaLocationDot />
							Mumbai,Maharastra
						</h6>
						<p className="mt-1 text-xs font-bold" style={{ color: "#2771E7" }}>
							Co-founder and Creative head Sygnific Careers/ Surgeon
						</p>
						{false ? (
							<div className="mt-4 flex flex-wrap text-center">
								<div className="flex justify-center items-center text-center gap-2 bg-[#F2F2F2] rounded-[6px] p-[6px] ">
									<FaStar className="text-[#F0D41B] text-[16px]" />
									<span className="text-[#000000] font-bold">4.5</span>
								</div>
								<h5 className="text-[#239403] underline underline-offset-4 m-2">
									35k+ Reviews
								</h5>
							</div>
						) : (
							<>
								{" "}
								<div className="mt-4 flex flex-wrap text-center">
									<p className="font-bold text-xs">10 Years of Experience</p>
								</div>
							</>
						)}
					</div>
				</div>
				<div className="flex justify-between px-2"></div>
				<div className="my-3"></div>

				<div className=" mb-2 text-sm  px-1" style={{ color: "#909090" }}>
					Indian Institute of Technology, Delhi ot IIT Delhi is an Institute{" "}
					<br />
					of Eminence offering about 102 undergraduate, <br />
					postgraduate, integrated and doctoral degree courses.
					<hr
						style={{
							borderTop: "1px solid #0000000F",
							width: "100%",
							marginTop: "15px",
							marginBottom: "20px",
						}}
					/>
				</div>
				<div className="flex justify-between text-center px-1.5 pb-5">
					<h4 className="text-[#000000] text-[23px] font-extrabold">
						$2000/
						<span className="text-[11px] font-normal">per month</span>
					</h4>
					{/* <Link to={"/mentorship/mentor/call"}> */}
					<Button
						onClick={() => {
							setModal(true);
							setMentorData(mentor);
						}}
						variant="contained"
						className="border !h-[45px]  flex gap-[6.2px] miniDesk:!px-[2px] font-medium capitalize !text-[#000000] !bg-[#F0BC5E52]   3xl:px-3 text-[13px]  !shadow-none px-[28px] border-[#239403] rounded-[14px]"
					>
						{/* <svg
						xmlns="http://www.w3.org/2000/svg"
						width="22.188"
						height="19.5"
						viewBox="0 0 26.188 21.5"
					>
						<path
							id="Path_340065"
							data-name="Path 340065"
							d="M3.347,13.314a10.77,10.77,0,0,1,10.487-7.98,10.77,10.77,0,0,1,10.488,7.98.763.763,0,0,1,0,.373,10.77,10.77,0,0,1-10.488,7.98,10.77,10.77,0,0,1-10.487-7.98.764.764,0,0,1,0-.373ZM13.835,3a13.1,13.1,0,0,0-12.75,9.744,3.1,3.1,0,0,0,0,1.512A13.1,13.1,0,0,0,13.835,24a13.1,13.1,0,0,0,12.75-9.744,3.1,3.1,0,0,0,0-1.512A13.1,13.1,0,0,0,13.835,3ZM8,13.5a5.833,5.833,0,1,1,5.833,5.833A5.833,5.833,0,0,1,8,13.5Zm9.333,0a3.5,3.5,0,1,1-3.5-3.5A3.5,3.5,0,0,1,17.335,13.5Z"
							transform="translate(-0.741 -2.75)"
							stroke="#fae9cb"
							stroke-width="0.5"
							fill-rule="evenodd"
						/>
					</svg> */}
						Start Chat
						{/* <EastRounded className="3xl:text-[16px] miniDesk:hidden" /> */}
					</Button>
					{/* </Link> */}
					<Button
						onClick={() => {
							setModal(true);
							setMentorData(mentor);
						}}
						variant="contained"
						className="border w-[30%] !h-[45px] text-center flex gap-x-[2.2px] leading-snug miniDesk:!px-[2px]  font-medium capitalize !text-[#000000] !bg-[#F0BC5E]   3xl:px-3 text-[13px]  !shadow-none px-[28px] border-[#239403] rounded-[14px]"
					>
						Start Now
						<EastRounded className="3xl:text-[16px] miniDesk:hidden" />
					</Button>
				</div>
			</div>
		</div>
	);
}
