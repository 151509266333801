/* eslint-disable jsx-a11y/img-redundant-alt */
import { useState } from "react";
import { medias } from "../../../../config/Resources";

function AvailableSeatsTable({ scholarshipDetails }) {
	console.log(scholarshipDetails, "scholarshipDetails");
	const TableData = [
		{
			SNo: "1",
			EventName: "Demo Data",
			StartDate: "12 Apr 2023",
			EndDate: "2 May 2023",
		},
		{
			SNo: "2",
			EventName: "Demo Data",
			StartDate: "12 Apr 2023",
			EndDate: "2 May 2023",
		},
		{
			SNo: "3",
			EventName: "Demo Data",
			StartDate: "12 Apr 2023",
			EndDate: "2 May 2023",
		},
	];

	return (
		<div>
			<p className="font-plusJakartaSans font-bold text-[14px] ml-5 flex items-center text-center gap-1">
				<img
					src={medias.images.College.Chair}
					alt="LeftChevron"
					className="w-6 h-6"
				/>{" "}
				Available seats
			</p>

			<div className="ml-4 mr-4 mt-3 mb-5">
				{scholarshipDetails?.map((data, index) => {
					return (
						<div
							dangerouslySetInnerHTML={{
								__html: data?.available_seats,
							}}
							className="font-plusJakartaSans font-medium text-[10.5px] text-[#000000] text-opacity-70 pl-3 pr-3 pt-2 pb-4"
						></div>
					);
				})}
			</div>
		</div>
	);
}

export default AvailableSeatsTable;
