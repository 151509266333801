const ExploreCardChips = ({ name, img, data, status }) => {
  return (
    <div>
      {" "}
      <div>
        <div className="flex gap-x-4  text-center justify-center items-center">
          <span className="w-4 h-4">{img}</span>
          <h5 className="text-[12px] mt-1 ">{name}</h5>
        </div>
        {name == "Request" ? (
          <h4
            className={`mt-3 text-center ${
              status == "2"
                ? " bg-[#FFC2B8] text-[#E80000] "
                : "bg-[#FEEE7E] text-[#7C6D00] "
            }  rounded-md py-[2px] px-[4px]`}
          >
            {data}
          </h4>
        ) : (
          <h4 className="mt-3">{data}</h4>
        )}
      </div>
    </div>
  );
};

export default ExploreCardChips;
