import { medias } from "../../../../config/Resources";

function CourseSpecilization({ course }) {
	return (
		<div>
			<div className="pl-4 pr-4">
				<div className="tracking-wide text-[18px] font-bold pt-4 flex justify-between">
					<h6 className="font-plusJakartaSans flex text-center items-center gap-2">
						<img
							className="w-9 h-9 rounded-md bg-[#FFCBCF] p-1"
							src={medias.images.College.Thumb1}
							alt="courses"
						/>
						Specialization
					</h6>
				</div>
				<div className="flex flex-wrap gap-1 mt-3 mb-3">
					{course?.specializations?.map((chip) => (
						<p
							key={chip.id}
							className="font-plusJakartaSans rounded-full border pt-1 pb-1 pl-4 pr-4 inline-flex text-[13px] text-center items-center gap-1 font-medium cursor-pointer border-[#2771E7] text-[#2771E7]"
						>
							{chip?.specialization_name}
						</p>
					))}
				</div>
			</div>
		</div>
	);
}

export default CourseSpecilization;
