import { RxCross1 } from "react-icons/rx";
import { FaRegFileAlt } from "react-icons/fa";

export default function SubTask({ showModal, toggleModal, selectedTaskData }) {
	console.log(selectedTaskData, "selectedTaskdata");

	// Parse the files string into an array
	let filesArray = [];
	if (selectedTaskData.files) {
		try {
			filesArray = JSON.parse(selectedTaskData.files);
		} catch (error) {
			console.error("Error parsing files array", error);
		}
	}

	return (
		<>
			{showModal ? (
				<>
					<div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
						<div className="relative my-6 w-[30%] ">
							<div className="border-0 rounded-[20px] shadow-lg relative flex flex-col w-full bg-[#EEF1FA] outline-none focus:outline-none">
								<div className="flex items-start justify-between pl-5 pr-5 pt-5">
									<h3 className="text-lg font-plusJakartaSans font-bold">
										{selectedTaskData?.main_task
											? selectedTaskData?.main_task
											: selectedTaskData?.sub_task_name}
									</h3>
									<RxCross1 className="cursor-pointer" onClick={toggleModal} />
								</div>

								<div className="relative pl-6 pr-6 pb-6 pt-3 flex-auto">
									<p className="text-sm font-plusJakartaSans font-semibold">
										Objective
									</p>
									<p className="text-xs font-plusJakartaSans font-normal mt-1">
										{selectedTaskData?.description}
									</p>
									<p className="text-xs font-plusJakartaSans font-bold mt-5">
										Priority
									</p>
									<p className="text-sm font-plusJakartaSans font-bold mt-[1px]">
										{selectedTaskData?.priority}
									</p>
									<p className="text-xs font-plusJakartaSans font-bold mt-5">
										Documents
									</p>
									<div className="mt-1 flex flex-wrap">
										{filesArray.length > 0 ? (
											filesArray.map((file, index) => (
												<div key={index} className="mr-2 mt-2">
													<FaRegFileAlt
														className="text-lg cursor-pointer"
														title={file?.name}
													/>
												</div>
											))
										) : (
											<p className="text-xs font-plusJakartaSans font-normal">
												No documents available
											</p>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div
						className="opacity-25 fixed inset-0 z-40 bg-black"
						onClick={toggleModal}
					></div>
				</>
			) : null}
		</>
	);
}
