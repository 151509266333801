import React, { useEffect, useState } from "react";
import { medias } from "../../../../config/Resources";
import axiosAdminInstance from "../../../../config/Axios/axiosAdminInstance";
import { API_URLS } from "../../../../config/API_URLS";

function Agency({ setStudyLevel, studyLevel }) {
	const [dropdownsData, setDropDownsData] = useState([]);
	const fetchScholarData = async () => {
		const res = await axiosAdminInstance.get(
			`${API_URLS.getAllDropdowns}?type=education_level`
		);
		if (res) {
			setDropDownsData(res?.result?.education_level);
		}
	};

	useEffect(() => {
		fetchScholarData();
	}, []);

	const handleChipClick = (id) => {
		const newSelectedId = studyLevel === id ? null : id;
		setStudyLevel(newSelectedId);
	};

	return (
		<div>
			<div className="pl-4 pr-4">
				<div className="tracking-wide text-[15px] font-bold pt-6 flex justify-between">
					<h6 className="font-plusJakartaSans flex text-center items-center gap-2">
						<img
							className="w-7 h-7"
							src={medias.images.College.Degree}
							alt="degree"
						/>
						Study Level
					</h6>
				</div>
				<div className="flex flex-wrap gap-1 mt-2 mb-3">
					{dropdownsData?.map((chip) => (
						<p
							key={chip.id}
							onClick={() => handleChipClick(chip?.id)}
							className={`font-plusJakartaSans rounded-full border pt-1 pb-1 pl-4 pr-4 inline-flex text-[12px] text-center items-center gap-1 font-medium cursor-pointer ${
								studyLevel === chip.id
									? "bg-[#2771E7] border-[#2771E7] text-white"
									: "border-[#D1D1D1] text-[#000000]"
							}`}
						>
							{chip?.name}
						</p>
					))}
				</div>
			</div>
		</div>
	);
}

export default Agency;
