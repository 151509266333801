import TopGlobalSlider from "../../Components/Mentorship/TopGlobalSlider";
import Hero from "./Hero";
import { medias } from "../../config/Resources";
import ReletableMentorship from "../../Components/Mentorship/ReletableMentorship";
import CareerCoach from "../../Components/Mentorship/CareerCoach";
import Rights from "../../Components/Mentorship/Rights";
import CorporateCard from "../../Components/Mentorship/CorporateCard";
import { useEffect } from "react";
import MediaSlider from "../../Components/Mentorship/MediaSlider";

const Mentorship = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const arr = [
    { url: medias.images.mentorship.netflix },
    { url: medias.images.mentorship.amazon },
    { url: medias.images.mentorship.google },
    { url: medias.images.mentorship.ibm },
    { url: medias.images.mentorship.netflix },
  ];
  return (
    <div>
      <div>
        <Hero />
        <div className="bg-[#022315] py-36 mt-[-75px] relative  ">
          <div className="flex justify-around px-[8%] mt-[-100px] pb-10">
            {arr?.map((ele, i) => {
              return (
                <img
                  key={i}
                  className={` ${i === 3 ? " h-[3%] " : ""}  w-[12%]`}
                  src={ele.url}
                  alt=""
                />
              );
            })}
            {/* <img src={medias.images.mentorship.amazon} alt="" /> */}
          </div>
          <ReletableMentorship medias={medias} />

          <TopGlobalSlider medias={medias} />
        </div>
        <CareerCoach medias={medias} />
        <Rights medias={medias} />
        <div className="bg-[#022315] py-10">
          <MediaSlider />
        </div>
      </div>
    </div>
  );
};

export default Mentorship;
