/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import "./App.css";
import {
  BrowserRouter,
  HashRouter,
  Navigate,
  Route,
  Routes,
  // useNavigate,
} from "react-router-dom";
import { RoutesLists } from "./config/routes";
import Layout from "./Components/layout";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { StyledEngineProvider } from "@mui/material/styles";
import { useUserInfoStore } from "./store/userinfo";
import { CircularProgress } from "@mui/material";
import axiosInstance from "./config/Axios/axiosInstance";
import { toast } from "react-toastify";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
import VideoCallIcon from "@mui/icons-material/VideoCall";
import CallEndIcon from "@mui/icons-material/CallEnd";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { MutableRefObject } from "react";

// ..

import AgoraRTC from "agora-rtc-sdk-ng";
import { AgoraRTCProvider } from "agora-rtc-react";
import { SocketContext } from "./Context";
import { db } from "./firebase.config";

const queryClient = new QueryClient();

function App() {
  const { setIsLoggedin, isloggedIn, isLoading }: any = useUserInfoStore(
    (state) => state
  );
  const { setIsloading }: any = useUserInfoStore((state) => state);

  const { userInfo }: any = useUserInfoStore((state) => state);

  const { setUserInfo }: any = useUserInfoStore((state) => state);

  const {
    callStatus,
    setCallStatus,
    setStreamData,
    streamData,
    statusMessage,
    setStatusMessage,
  } = useContext(SocketContext);

  let element: any = document.getElementById("ringtonecall");

  // const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("token-student")) {
      setIsLoggedin(true);
      // axiosInstance.get("/getUserProfile").then((res:any) => setUserInfo(res?.result)).catch(err => console.error(err))
    } else {
      setIsLoggedin(false);
    }
  }, []);

  useEffect(() => {
    const disableRightClick = (event: MouseEvent) => {
      // alert("Right-clicking is disabled on this page.");

      if (event.button === 2) {
        event.preventDefault();
      }
    };

    document.addEventListener("contextmenu", disableRightClick);

    return () => {
      document.removeEventListener("contextmenu", disableRightClick);
    };
  }, []);

  useEffect(() => {
    if (isloggedIn) {
      axiosInstance
        .get("/getUserProfile")
        .then((res: any) => {
          console.log(res);
          if (res?.statusCode === 401) {
            toast.info(res?.statusMessage || "Some thing went wrong");
            localStorage.clear();
            window.location.href =
              window.location.origin == "http://localhost:3000" ||
              window.location.origin == "https://www.techugoapps.com" ||
              window.location.origin == "https://techugoapps.com"
                ? "/sygnific_dev"
                : "/";
            console.log(res?.statusMessage);
          }
          setUserInfo(res?.result);

          // setIsLoggedin(false);
          setIsloading(false);
        })
        .catch((err) => {
          console.error(err);
          setIsloading(false);
        });
    }
  }, [isloggedIn]);

  AOS.init();

  useEffect(() => {
    const callDoc = db.collection("meet").doc("chatId");
    callDoc.onSnapshot((snapshot) => {
      const data = snapshot.data();
      console.log("firebase data", data, data?.callerId == userInfo?.id);
      console.log(
        "condition",
        data?.callerId,
        userInfo?.id,
        data?.calleeId == userInfo?.id
      );
      setStreamData(data);
      if (data?.offer && data?.calleeId == userInfo?.id && !data?.answer) {
        setCallStatus(true);
        element.play();
        element.muted = false;

        return;
      } else if (data?.answer || !data?.offer) {
        element.muted = true;
        setCallStatus(false);
      }
    });
  }, [userInfo?.id]);

  const hangUp = async () => {
    setStatusMessage("Disconnectig..");
    let roomRef = db.collection("meet").doc("chatId");
    await roomRef
      .collection("answerCandidates")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          doc.ref.delete();
        });
      });
    await roomRef
      .collection("offerCandidates")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          doc.ref.delete();
        });
      });

    await roomRef.delete();
    element.muted = true;

    setCallStatus(false);
    setStatusMessage("Incoming Video Call");
  };

  const callReceive = () => {
    element.muted = true;

    setStatusMessage("Receiving Video Call");

    const callUrl = `https://video-call-firebase-kgfu.vercel.app/meet?type=join&user=student&callerName=${
      userInfo?.firstName + " " + userInfo?.lastName
    }&calleeName=${streamData?.callerName}&calleeId=1&callerId=20&chatId=1020`;
    window.open(callUrl, "_black");
    setCallStatus(false);
    setStatusMessage("Incoming Video Call");
  };

  return (
    <QueryClientProvider client={queryClient}>
      {/* <AgoraRTCProvider
        client={AgoraRTC.createClient({ mode: "rtc", codec: "vp8" })}
      > */}
      <div className="App overflow-x-hidden">
        {isLoading && (
          <div className="absolute flex bg-[#00000040]  items-center justify-center w-screen h-screen z-[999]">
            <CircularProgress className="!text-white" />
          </div>
        )}
        {/* <audio playsInline loop ref={audioRef} src={ringtone} /> */}

        {callStatus && (
          <div className="fixed flex bg-[#00000040]   w-full h-screen z-[999] text-red-600">
            <div className="flex justify-end  rounded-full  text-black  items-end h-fit w-full mt-8 mr-10 ">
              <div className="w-52 h-52 space-y-3  bg-cover border-blue-400 border-solid border-[3px] shadow-md bg-white profileBackGround rounded-full p-6">
                <div className="flex items-center justify-center">
                  <img
                    src="https://img.freepik.com/premium-photo/male-female-profile-avatar-user-avatars-gender-icons_1020867-75560.jpg"
                    alt=""
                    className="w-12 h-12 rounded-full"
                  />
                </div>
                <div className="text-center w-full">
                  <h2 className="font-plusJakartaSans font-bold">
                    {streamData?.callerName}
                  </h2>
                  <h6 className="text-[12px]">{statusMessage}</h6>
                </div>
                <div className="flex items-center justify-around ">
                  <div
                    onClick={() => {
                      callReceive();
                    }}
                    className="bg-green-600 animate-pulse flex cursor-pointer justify-center items-center text-center w-9 h-9  p-1 rounded-full"
                    title="Call Recieved"
                  >
                    <VideoCallIcon className="  text-white " />
                  </div>
                  <div
                    onClick={() => {
                      hangUp();
                    }}
                    className="bg-red-600 w-9 h-9 animate-pulse cursor-pointer flex justify-center items-center text-center p-1 rounded-full"
                    title="Call End"
                  >
                    <CallEndIcon className="  text-white " />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <StyledEngineProvider injectFirst>
          <HashRouter>
            {/* <BrowserRouter
            basename={
              process.env.PUBLIC_URL ||
              "https://www.techugoapps.com/sygnific_dev"
            }
          > */}
            <Routes>
              {RoutesLists.map(
                ({
                  path,
                  Element,
                  isFooter = true,
                  isHeader = true,
                  isPrivate = false,
                }) => {
                  return (
                    <Route
                      path={path}
                      element={
                        <Layout isFooter={isFooter} isHeader={isHeader}>
                          {(isPrivate && isloggedIn) || !isPrivate ? (
                            <Element />
                          ) : (
                            <Navigate
                              to={
                                window.location.origin ==
                                  "http://localhost:3000" ||
                                window.location.origin ==
                                  "https://www.techugoapps.com" ||
                                window.location.origin ==
                                  "https://techugoapps.com"
                                  ? "/sygnific_dev/"
                                  : "/"
                              }
                            />
                          )}
                        </Layout>
                      }
                    />
                  );
                }
              )}
            </Routes>
            {/* </BrowserRouter> */}
          </HashRouter>
        </StyledEngineProvider>
      </div>
      {/* </AgoraRTCProvider> */}
    </QueryClientProvider>
  );
}

export default App;
