/* eslint-disable no-lone-blocks */
import { medias } from "../../../config/Resources";

function Responsibilities({details}) {
	return (
		<div className="w-[100%] rounded-[20px] ml-auto mr-auto overflow-hidden p-6 shadow-lg border border-gray-300">
			<div className="flex text-center items-center">
				<img
					className="h-6 w-6"
					src={medias.images.Profile.growth}
					alt="growth"
				/>
				<h6 className="font-plusJakartaSans ml-2 font-bold text-lg text-[#000000]">
					Responsibilities
				</h6>
			</div>
			<div className="mt-2">
        <p className="font-plusJakartaSans text-[#000000] font-medium text-[12px] opacity-70" dangerouslySetInnerHTML={{__html: details?.responsibilities}}>
        </p>
    </div>
		</div>
	);
}

export default Responsibilities;

{/* <div className="mt-5">
	<h6 className="font-plusJakartaSans font-extrabold text-sm">
		Exam Patter Exam in the following Patterns
	</h6>
	<ul>
		<li className="font-plusJakartaSans mt-2 font-medium text-sm">
			&#8226; Chat with our counselor
		</li>
		<li className="font-plusJakartaSans mt-2 font-medium text-sm">
			&#8226; Get your personalised list of colleges & exams matching your
			preferences
		</li>
		<li className="font-plusJakartaSans mt-2 font-medium text-sm">
			&#8226; Chat with our counselor
		</li>
		<li className="font-plusJakartaSans mt-2 font-medium text-sm">
			&#8226; Get your personalised list of colleges & exams matching your
			preferences
		</li>
		<li className="font-plusJakartaSans mt-2 font-medium text-sm">
			&#8226; Get your personalised list of colleges & exams matching your
			preferences
		</li>
		<li className="font-plusJakartaSans mt-2 font-medium text-sm">
			&#8226; Chat with our counselor
		</li>
		<li className="font-plusJakartaSans mt-2 font-medium text-sm">
			&#8226; Get your personalised list of colleges & exams matching your
			preferences
		</li>
		<li className="font-plusJakartaSans mt-2 font-medium text-sm">
			&#8226; Chat with our counselor
		</li>
		<li className="font-plusJakartaSans mt-2 font-medium text-sm">
			&#8226; Get your personalised list of colleges & exams matching your
			preferences
		</li>
		<li className="font-plusJakartaSans mt-2 font-medium text-sm">
			&#8226; Get your personalised list of colleges & exams matching your
			preferences
		</li>
		<li className="font-plusJakartaSans mt-2 font-medium text-sm">
			&#8226; Get your personalised list of colleges & exams matching your
			preferences
		</li>
		<li className="font-plusJakartaSans mt-2 font-medium text-sm">
			&#8226; Get your personalised list of colleges & exams matching your
			preferences
		</li>
		<li className="font-plusJakartaSans mt-2 font-medium text-sm">
			&#8226; Get your personalised list of colleges & exams matching your
			preferences
		</li>
		<li className="font-plusJakartaSans mt-2 font-medium text-sm">
			&#8226; Get your personalised list of colleges & exams matching your
			preferences
		</li>
		<li className="font-plusJakartaSans mt-2 font-medium text-sm">
			&#8226; Get your personalised list of colleges & exams matching your
			preferences
		</li>
	</ul>
</div> */}