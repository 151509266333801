/* eslint-disable jsx-a11y/anchor-is-valid */
import { CompareRounded } from "@mui/icons-material";
import { medias } from "../../../config/Resources";
import { IoLocationOutline } from "react-icons/io5";
import { GoDownload } from "react-icons/go";
import { FaRegHeart } from "react-icons/fa";

function SimilarCollegeCards() {
    const SimilarColleges= [{},{},{},{}]
	return (
        <><div>
            <h6 className="font-plusJakartaSans ml-5 font-bold text-[20px] mt-5 mb-3 text-[#000000]">Similar Colleges</h6>
        </div>
        <div className="flex item-center gap-6 w-[95%] ml-5">
                {SimilarColleges.map((_, index) => {
                    return (
                        <div className="max-w-sm p-4 w-[40%] bg-white border border-gray-200 rounded-xl shadow dark:bg-gray-800 dark:border-gray-700">
                            <div className="relative">
                            <img
                                    className="rounded-t-lg"
                                    src={medias.images.College.BackGroundIMage}
                                    alt="BackgroundImage"
                                />
                                <div className="absolute bg-white p-2 rounded-full top-2 right-2">
                                    <FaRegHeart className="text-[#2771E7]  text-sm" />
                                </div>
                                <div className="absolute bottom-0 left-10 transform -translate-x-1/2 translate-y-1/2 w-14 h-auto bg-white p-2 rounded-lg shadow-custom1">
                                    <img
                                        className="w-16 h-auto"
                                        src={medias.images.College.CollegeLogo}
                                        alt="CollegeLogo" />
                                </div>
                            </div>
                            <div className="pb-5 pl-3 pr-1 pt-10">
                                <h6 className="font-plusJakartaSans font-semibold text-[13.5px]">
                                    IIT Bombay
                                </h6>

                                <p className="font-plusJakartaSans font-medium ml-[-2px] flex gap-1 mt-1 text-[#000000] text-opacity-80 text-[12px]">
                                    <IoLocationOutline className="h-4 w-4" /> IIT Delhi Main Rd, Hauz
                                    Khas, Delhi 110016
                                </p>
                                <p className="font-plusJakartaSans font-medium mt-2 text-[#000000] text-opacity-80 text-[12px]">
                                    If you choose not to provide any of the information requested, we may
                                    be unable to send you information, fully process your application or
                                    properly consider you for employment.
                                </p>

                            </div>
                            <div className="flex text-center gap-2 pl-3">
                                <button
                                    variant="outlined"
                                    className="border items-center flex text-center pt-1 pb-1 pl-3 pr-3 gap-1 border-[#707070] font-semibold text-[#000000] rounded-full text-[8px] font-plusJakartaSans"
                                >
                                    <CompareRounded className="text-[#000000] h-4 w-4" />
                                    <span className="mt-[1px]">Compare</span>
                                </button>
                                <button
                                    variant="outlined"
                                    className="font-semibold flex gap-1 text-center pt-1 pb-1 pl-3 pr-3 bg-[#239403] text-white rounded-full text-[8px] font-plusJakartaSans"
                                >
                                    <GoDownload className="text-white h-4 w-4" />
                                    <span className="mt-[1px]">Download brochure</span>
                                </button>
                            </div>
                        </div>
                    );
                })}
            </div></>  
	);
}

export default SimilarCollegeCards;
