/* eslint-disable jsx-a11y/img-redundant-alt */
import { useRef } from "react";
import {
	MdOutlineKeyboardArrowLeft,
	MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import { medias } from "../../../../config/Resources";

function Accomodation({ campusLife }) {
	const scrollContainerRef = useRef(null);

	console.log(campusLife, "sdjhhgfh");

	const scrollLeft = () => {
		if (scrollContainerRef.current) {
			scrollContainerRef.current.scrollBy({
				left: -200,
				behavior: "smooth",
			});
		}
	};

	const scrollRight = () => {
		if (scrollContainerRef.current) {
			scrollContainerRef.current.scrollBy({
				left: 200, // Adjust the scroll distance as needed
				behavior: "smooth",
			});
		}
	};

	return (
		<div className="ml-4 mr-4 mt-4">
			<div className="flex items-center text-center gap-1">
				<img
					className="bg-[#F2E27E] bg-opacity-35 p-[6px] rounded-lg w-9 h-9"
					src={medias.images.College.Accomodation}
					alt="Placements"
				/>
				<h6 className="font-plusJakartaSans mt-[5.5px] font-bold text-[15px]">
					Accommodation
				</h6>
			</div>
			<img
				className="mt-[8px] w-[93%]"
				src={medias.images.College.Rectangle2}
				alt="Rectangle"
			/>
			{campusLife?.map((data) =>
				data?.accomodation?.map((hostel) => (
					<div key={hostel?.hostel_id}>
						<div className="flex mt-5 text-center item-center justify-between">
							<div className="flex items-center text-center gap-1">
								<img
									className="w-9 h-9"
									src={
										hostel.hostel_name === "Boys"
											? medias.images.College.Boys
											: medias.images.College.Girls
									}
									alt={hostel.hostel_name}
								/>
								<h6 className="font-plusJakartaSans text-[#404040] text-opacity-70 mt-[5.5px] font-bold text-[13px]">
									{hostel?.hostel_name} Hostel
								</h6>
							</div>
							<p className="font-plusJakartaSans mt-[9px] font-extrabold text-[#239403] text-[14px]">
								₹ {hostel?.fee_structure}
							</p>
						</div>
						<p
							dangerouslySetInnerHTML={{
								__html: hostel.hostel_description,
							}}
							className="text-[#000000] text-opacity-70 mt-4 font-plusJakartaSans font-medium text-[10.5px]"
						></p>
						<div className="relative">
							<div
								className="flex overflow-x-scroll space-x-4 pt-4 scrollable-content"
								ref={scrollContainerRef}
							>
								{hostel?.images?.map((imageItem) => (
									<img
										key={imageItem.hostel_image_id}
										className="w-36 h-20 rounded-lg"
										src={imageItem.accomodation_image}
										alt={`Image ${imageItem.hostel_image_id}`}
									/>
								))}
							</div>
							<div className="absolute top-0 left-[-10px] h-full flex items-center">
								<MdOutlineKeyboardArrowLeft
									onClick={scrollLeft}
									className="bg-[#ffffff] w-6 h-6 rounded-full p-1 border border-[#C2C2C2] cursor-pointer"
								/>
							</div>
							<div className="absolute top-0 right-0 h-full flex items-center">
								<MdOutlineKeyboardArrowRight
									onClick={scrollRight}
									className="bg-[#ffffff] w-6 h-6 rounded-full p-1 border border-[#C2C2C2] cursor-pointer"
								/>
							</div>
						</div>
					</div>
				))
			)}
		</div>
	);
}

export default Accomodation;
