import { StarRounded, Verified } from "@mui/icons-material";
import { useState } from "react";
import { FaAngleUp } from "react-icons/fa";
import { RiArrowDropDownLine } from "react-icons/ri";
import { medias } from "../../../../config/Resources";
import LikeAndReport from "../CoursesAndFees/LikeAndReport";

function CutOffReviews() {
	const [isExpanded, setIsExpanded] = useState(false);

	const toggleReadMore = () => {
		setIsExpanded(!isExpanded);
	};

	const ReportData = [{}, {}, {}, {}, {}];

	return (
		<div>
			{ReportData?.map((_index) => {
				return (
					<div className="pt-8 pl-4 pr-4">
						<p className="font-plusJakartaSans text-[14px] font-semibold">
							Ques. Which is better IIT-D electrical or IIT Madras Electrical?
						</p>
						<div className="flex items-center mt-1 text-center gap-1">
							<p className="font-plusJakartaSans w-6 h-6 font-bold text-[#AC4E0F] bg-[#F8D0B9] p-2 rounded-full text-[14px] flex justify-center items-center">
								H
							</p>
							<p className="font-plusJakartaSans font-semibold text-[10px]">
								Top Answer by Vibhash Patel
							</p>
						</div>
						<div className="">
							<div className="relative overflow-hidden">
								<div
									className={`font-plusJakartaSans font-medium text-[11.5px] text-[#000000] text-opacity-60 mt-1 ${
										!isExpanded ? "line-clamp-5" : ""
									}`}
								>
									<p>
										Lorem Ipsum is simply dummy text of the printing and
										typesetting industry. Lorem Ipsum has been the industry's
										standard dummy text ever since the 1500s, when an unknown
										printer took a galley of type and scrambled it to make a
										type specimen book. It has survived not only five centuries,
										but also the leap into electronic typesetting, remaining
										essentially unchanged. It was popularised in the 1960s with
										the release of Letraset sheets containing Lorem Ipsum
										passages, and more recently with desktop publishing software
										like Aldus PageMaker including versions of Lorem Ipsum.Lorem
										Ipsum is simply dummy text of the printing and typesetting
										industry. Lorem Ipsum has been the industry's standard dummy
										text ever since the 1500s, when an unknown printer took a
										galley of type and scrambled it to make a type specimen
										book. It has survived not only five centuries, but also the
										leap into electronic typesetting, remaining essentially
										unchanged. It was popularised in the 1960s with the release
										of Letraset sheets containing Lorem Ipsum passages, and more
										recently with desktop publishing software like Aldus
										PageMaker including versions of Lorem Ipsum.
									</p>
								</div>
								{!isExpanded && (
									<div className="absolute bottom-0 left-4 w-full h-8 bg-gradient-to-t from-white to-transparent"></div>
								)}
							</div>
							<button
								onClick={toggleReadMore}
								className="text-blue-500 font-plusJakartaSans font-bold text-[12px] flex items-center ml-[80%]"
							>
								{isExpanded ? (
									<>
										Read less
										<FaAngleUp className="w-4 h-4 ml-1" />
									</>
								) : (
									<>
										Read more
										<RiArrowDropDownLine className="w-6 h-6" />
									</>
								)}
							</button>
							<div className="border-t-2 border-dashed mb-5 border-gray-300 mt-2"></div>
							<LikeAndReport />
						</div>
					</div>
				);
			})}
		</div>
	);
}

export default CutOffReviews;
