import { medias } from "../../../../config/Resources";
import { MdCompare } from "react-icons/md";

function RankingTable({ rankingData }) {
	return (
		<div className="">
			<div className="flex items-center gap-2 mt-5 ml-4 mr-4">
				<img
					className="w-10 h-10"
					src={medias.images.College.Eligibility}
					alt="courses"
				/>
				<div>
					<p className="font-semibold text-[#000000] opacity-50 font-plusJakartaSans text-[11px]">
						IIT Delhi
					</p>
				</div>
			</div>
			<div className="mt-3 ml-4 mr-4">
				<div className="overflow-x-auto mt-4">
					{rankingData?.ranking?.ranking_data?.map((tableData, index) => {
						return (
							<table key={index} className="min-w-full border-collapse mb-12">
								<thead>
									<tr>
										<th className="px-4 py-2 bg-[#FFEDC0] text-center font-plusJakartaSans font-bold text-[12px] rounded-l-sm border-r-2 border-white">
											Career Cluster
										</th>
										<th className="px-4 py-2 bg-[#FFEDC0] text-center font-plusJakartaSans font-bold text-[12px] border-r-2 border-white">
											<p className="text-center font-plusJakartaSans text-[#000000] text-[12px] text-opacity-70">
												Ranking Type
											</p>
										</th>
										<th className="px-4 py-2 bg-[#FFEDC0] text-center font-plusJakartaSans font-bold text-[12px] border-r-2 border-white">
											<p className="text-center font-plusJakartaSans text-[#000000] text-[12px] text-opacity-70">
												Ranking
											</p>
										</th>
									</tr>
								</thead>

								<tbody className="bg-[#ffffff]">
									<tr className="border-b border-dotted last:border-0">
										<td className="px-4 py-2 font-plusJakartaSans font-bold text-[11px]">
											{tableData?.career_cluster_name}
											<p className="flex item-center gap-1 texts-center font-plusJakartaSans font-medium text-[#000000] text-[8.5px]">
												<MdCompare className="w-4 h-4 text-[#2771E7]" />{" "}
												<span className="font-medium font-plusJakartaSans mt-[3px]">
													Compare
												</span>
												<img
													src={medias.images.College.Arrow2}
													alt="RightIcon"
													className="w-4 h-4"
												/>
											</p>
										</td>
										<td className="px-4 py-2 text-center font-plusJakartaSans font-bold text-[11px]">
											{tableData?.ranking_type}
										</td>
										<td className="px-4 py-2 text-center font-plusJakartaSans font-bold text-[11px]">
											{tableData?.ranks}
										</td>
									</tr>
								</tbody>
							</table>
						);
					})}
				</div>
			</div>
		</div>
	);
}

export default RankingTable;
