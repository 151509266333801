/* eslint-disable jsx-a11y/img-redundant-alt */
import { useEffect, useState } from "react";
import BlogCareerClusterSelect from "../../Components/Blogs/BlogsListing/BlogCareerClusterSelect";
import BlogListingTitle from "../../Components/Blogs/BlogsListing/BlogListingTitle";
import BlogsChips from "../../Components/Blogs/BlogsListing/BlogsChips";
import BlogsSearchBar from "../../Components/Blogs/BlogsListing/BlogSerachBar";
import { medias } from "../../config/Resources";
import FeaturedBlogs from "../../Components/Blogs/BlogsListing/FeaturedBlogs";
import PopularCategoryBlogs from "../../Components/Blogs/BlogsListing/PopularCategoryBlogs";
import Banner from "../../Components/Blogs/BlogsListing/Banner";
import EveryOneReading from "../../Components/Blogs/BlogsListing/EveryOneReading";
import EditorsPicks from "../../Components/Blogs/BlogsListing/EditorsPick";
import ExploreOtherSection from "../Profile/SessionSchedule/ExploreOtherSection";
import RecentlyAdded from "../../Components/Blogs/BlogsListing/RecenetlyAdded";
import NewsletterSubscribe from "../../Components/Blogs/BlogsListing/NewsLetter";
import StudyAbroadCards from "../../Components/Blogs/BlogsListing/StudyAbroad.jsx/StudyAbroadCards";
import StudyAbroadGridCards from "../../Components/Blogs/BlogsListing/StudyAbroad.jsx/StudyAbroadGridCards";
import EditorsPick1 from "../../Components/Blogs/BlogsListing/StudyAbroad.jsx/EditorsPick1";
import RecentlyAdded1 from "../../Components/Blogs/BlogsListing/StudyAbroad.jsx/RecentlyAdded1";
import WhatEveryOneReading1 from "../../Components/Blogs/BlogsListing/StudyAbroad.jsx/WhatEveryOneReading1";
import axiosInstance from "../../config/Axios/axiosInstance";
import { API_URLS } from "../../config/API_URLS";

function BlogListing() {
	const [activeChip, setActiveChip] = useState(1);
	const [blogsData, setBlogsData] = useState();
	const [loading, setLoading] = useState(false);
	const [page, setPage] = useState(1);
	const [totalCount, setTotalCount] = useState(0);
	const dataLimit = 10;

	useEffect(() => {
		(async () => {
			const res = await axiosInstance.get(`${API_URLS.getAllBlogs}`, {
				params: {
					page: page,
					limit: dataLimit,
				},
			});
			console.log(res, "AllResponseData");
			if (res?.success === true) {
				setBlogsData(res?.data);
				setTotalCount(res?.total_count);
			}
			setLoading(false);
		})();
	}, [page]);

	const ChangePage = (event, value) => {
		setLoading(true);
		setPage(value);
	};

	console.log(blogsData, "blogsData");

	return (
		<div className="bg-[#F3F6FF] w-[100%] h-[100%]">
			{activeChip === 1 && (
				<>
					<div className="w-[80%] h-[100%] pt-5 pb-5 ml-auto mr-auto ">
						<div className="bg-white rounded-[15px] shadow-custom1">
							<img
								src={medias.images.Blogs.HeaderImage}
								className="w-full"
								alt="Header Image"
							/>
							<div className="flex items-center text-center justify-between ml-9 mr-8 mt-5">
								<div>
									<BlogListingTitle />
								</div>
								<div className="flex items-center text-center gap-2">
									<BlogsSearchBar />
									<BlogCareerClusterSelect />
								</div>
							</div>
							<div className="bg-[#6C6C6C] bg-opacity-[10%] pl-[35px] pr-[10px] mt-5 mb-5">
								<BlogsChips
									setActiveChip={setActiveChip}
									activeChip={activeChip}
								/>
							</div>
							<FeaturedBlogs blogsData={blogsData} />
							<PopularCategoryBlogs />
							<Banner />
							<EveryOneReading />
							<EditorsPicks blogsData={blogsData} />
							<div className="ml-9 mr-4">
								<ExploreOtherSection />
							</div>
							<RecentlyAdded
								ChangePage={ChangePage}
								dataLimit={dataLimit}
								totalCount={totalCount}
								blogsData={blogsData}
							/>
						</div>
					</div>
					<div className="w-[75%] ml-auto mr-auto">
						<NewsletterSubscribe />
					</div>
				</>
			)}
			{/* {activeChip === 2 && (
				<>
					<div className="w-[86%] ml-auto mr-auto pt-10">
						<img
							src={medias.images.Blogs.HeaderImage}
							className="w-full"
							alt="Header Image"
						/>
						<div className="">
							<div className="h-[100%] pt-5 pb-5 ml-auto mr-auto flex gap-2">
								<div className="bg-white w-[67%] rounded-[15px] shadow-custom1">
									<div className="ml-9 mr-8 pt-8">
										<BlogListingTitle />
									</div>
									<div className="bg-[#6C6C6C] bg-opacity-[10%] pl-[35px] pr-[10px] mt-2 mb-5">
										<BlogsChips
											setActiveChip={setActiveChip}
											activeChip={activeChip}
										/>
									</div>
									<StudyAbroadCards />
									<StudyAbroadGridCards />
								</div>
								<div className="w-[33%]">
									<div className="bg-white h-[500px] overflow-y-scroll scrollable-content rounded-[10px] shadow-custom1">
										<EditorsPick1 />
									</div>
									<div className="bg-white mt-4 h-[500px] overflow-y-scroll scrollable-content rounded-[10px] shadow-custom1">
										<RecentlyAdded1 />
									</div>
									<div className="bg-white mt-4 h-[500px] overflow-y-scroll scrollable-content rounded-[10px] shadow-custom1">
										<WhatEveryOneReading1 />
									</div>
								</div>
							</div>
						</div>
					</div>
				</>
			)} */}
		</div>
	);
}

export default BlogListing;
