import { medias } from "../../../../config/Resources";
import BrachTable from "./BranchTable";
import IndustryTable from "./IndustryTable";

function IndustryPlacement({placement}) {
	return (
		<div className="ml-4 mr-4 mt-4">
			<div className="flex items-center text-center gap-1">
				<img
					className="bg-[#E8AE4D] bg-opacity-[15%] p-[6px] rounded-md w-9 h-9"
					src={medias.images.College.Salary}
					alt="Placements"
				/>
				<h6 className="font-plusJakartaSans mt-[5.5px] font-bold text-[15px]">
                Industry wise placement
				</h6>
			</div>
			<div>
				<p dangerouslySetInnerHTML={{
						__html: placement?.industry_summary,
					}} className="font-plusJakartaSans font-medium text-[10.5px] pt-[7px] text-opacity-60 text-[#000000]">
				</p>
			</div>
			<IndustryTable placement={placement} />
		</div>
	);
}

export default IndustryPlacement;
