import { RiQuestionMark } from "react-icons/ri";
import { medias } from "../../../../config/Resources";
import { Controls, Player } from "@lottiefiles/react-lottie-player";
import QuestionAnswer from "../../../../animations/question-answer.json";

function AskQuestion() {
	console.log(medias.images.Exam.QuestionBg);
	return (
		<div
			className="relative max-w-sm p-4 w-full bg-no-repeat bg-center"
			style={{
				backgroundImage: `url(${medias.images.Exam.QuestionBg})`,
				backgroundSize: "cover",
				backgroundPosition: "center",
			}}
		>
			<div className="bg-white shadow-custom1 w-[56px] h-[57px] absolute top-[-15%] rounded-lg left-1/2 transform -translate-x-1/2">
				<Player
					autoplay
					speed={1.5}
					loop
					src={QuestionAnswer}
					className="h-14 w-14"
				>
					<Controls
						visible={false}
						buttons={[
							"play",
							"repeat",
							"frame",
							"debug",
							"snapshot",
							"background",
						]}
					/>
				</Player>
			</div>

			<div className="flex justify-center text-center mt-8">
				<p className="font-plusJakartaSans font-bold text-[15px]">
					Any Question & confusion Arise about this Exam
				</p>
			</div>
			<div className="flex items-center mt-[8px] justify-center">
				<button className=" text-[10.5px] text-white font-plusJakartaSans font-medium bg-[#239403] flex items-center text-center gap-1 rounded-full r pt-3 pb-3 pl-4 pr-4">
					<span className="bg-white rounded-full p-[2px]">
						<RiQuestionMark className="w-3 h-3 text-[green]" />
					</span>
					<span className="font-plusJakartaSans font-medium mt-[1px]">
						Ask Questions
					</span>
				</button>
			</div>
		</div>
	);
}

export default AskQuestion;
