import { medias } from "../Resources";
import Menu1 from "./Menus";
import Guidance from "./Menus/Guidance";
import Mentorship from "./Menus/Mentorship";

export const HeaderTopSectionDropDownsLeft = [
  {
    title: "Guidance",
    Menu: Guidance,
  },
  {
    title: "Mentorship",
    Menu: Mentorship,
  },
  {
    title: "Study Abroad",
    Menu: Menu1,
  },
];

export const HeaderTopSectionDropDownsRight: Array<{
  title: string;
  Menu: () => JSX.Element;
}> = [
  {
    title: "Admission",
    Menu: Menu1,
  },
  {
    title: "Courses",
    Menu: Menu1,
  },
  {
    title: "Jobs",
    Menu: Menu1,
  },
  {
    title: "Events",
    Menu: Menu1,
  },
];

export const HeaderMiddleSectionGoalsDrop = {
  title: `Select   your  Goal`,
  subTitle: "Hello",
  logo: medias.images.header.graduationCap,
};

export const offers = [
  {
    title: "Write a Review",
    offer: (
      <>
        {" "}
        Get upto <span className="!font-extrabold"> ₹ 1000 </span>
      </>
    ),
    className: "animationbackground",
  },
  {
    title: "For Mentors",
    offer: "New",
    className: "text-white bg-[#239403]",
  },
  {
    title: "For Institution",
    offer: "Exclusive",
    className: "text-white bg-[#035A94]",
  },
];

export const Courses = [
  {
    title: "Events",
  },
  {
    title: "BBA",
  },
  {
    title: "Science",
  },
  {
    title: "MBBS",
  },
  {
    title: "Colleges",
  },
  {
    title: "Exams",
  },
  {
    title: "Scholarships",
  },
  {
    title: "Blogs",
  },
  {
    title: "Latest news",
  },
  {
    title: "Careers",
  },
  {
    title: "Resources",
  },
];
