import React, { useEffect, useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const PdfViewer = ({ pdfUrl, height=200, width=200}: { pdfUrl: string, height?: number,width?:number }) => {
  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState(1);
    const canvasRef = useRef<any>(null)
  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };
  useEffect(() => {
    if(canvasRef && canvasRef?.current){
       canvasRef.current.width = 120
       canvasRef.current.height = 120

    }
  },[canvasRef])


  return (
    <div>
      <Document className={"rounded-xl overflow-hidden "}  file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
        <Page
          renderTextLayer={false}
          renderAnnotationLayer={false}
          height={height|| 80}
          width={width || 80}
          loading={""}
          canvasRef={canvasRef}
          className={`p-1 max-h-[${height}px] max-w-[${width}px]`}
          pageNumber={pageNumber}
          onLoadError={(error) => console.error('Error loading page:', error)}
        />
      </Document>
    </div>
  );
};

export default PdfViewer;
