import { medias } from "../../../../config/Resources";
import { IoBag } from "react-icons/io5";
import { MdPhone } from "react-icons/md";
import { MdEmail } from "react-icons/md";

function FacultyCards({ faculty }) {
	console.log(faculty, "AllFacultyData");
	return (
		<div className="ml-4 mr-4 mt-4">
			{/* <div className="border-t-2 border-dashed border-gray-300 mt-5"></div> */}
			{faculty?.map((data, index) => {
				return (
					<div
						key={index}
						className="bg-white shadow-md rounded-lg p-4 mb-4 mt-4"
					>
						<div className="flex gap-28">
					 		<div>
								<h1 className="font-plusJakartaSans text-[12px] font-extrabold">
									{data?.faculty_name}
								</h1>
								<p className="font-plusJakartaSans mt-[4px] text-[11px] font-medium flex gap-1 ">
									<IoBag className="w-4 h-4" />
									<span className="mt-[2px] font-plusJakartaSans">
										{data?.department}
									</span>
								</p>
								<p className="font-plusJakartaSans mt-[7px] text-[10px] font-medium flex gap-1">
									<img
										className="w-4 h-4"
										src={medias.images.College.Linkdein1}
										alt="linkedin"
									/>
									<span className="mt-[2.5px] font-plusJakartaSans text-[#2771E7] underline underline-offset-2">
										{data?.linkedin_link}
									</span>
								</p>
							</div>
							<div>
								<h1 className="font-plusJakartaSans text-[12px] font-bold">
									Contact Details
								</h1>
								<p className="font-plusJakartaSans mt-[5px] text-[11px] font-medium flex gap-1 items-center text-center">
									<MdPhone className="w-4 h-4" />
									<span className="mt-[2.5px] font-plusJakartaSans">
										{data?.phone_no}
									</span>
								</p>
								<p className="font-plusJakartaSans mt-[5px] text-[10px] font-medium flex gap-1 items-center text-center">
									<MdEmail className="w-4 h-4" />
									<span className="mt-[2px] font-plusJakartaSans">
										{data?.email_address}
									</span>
								</p>
							</div>
						</div>
					</div>
				);
			})}
		</div>
	);
}

export default FacultyCards;
