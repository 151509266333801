import React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import axiosInstance from "../../config/Axios/axiosInstance";
import { API_URLS } from "../../config/API_URLS";

const options = [
	{ label: "Mark as completed", percentage: 100 },
	{ label: "75% Completed", percentage: 75 },
	{ label: "50% Completed", percentage: 50 },
	{ label: "25% Completed", percentage: 25 },
	{ label: "10% Completed", percentage: 10 },
];

const ITEM_HEIGHT = 48;

const PercentageOptionModal = ({
	handleOptionSelect,
	getTaskListData,
	milestoneIndex,
	activeTab,
	items,
	milestone,
	datas,
	receivedData
}) => {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);

	console.log(items, "details");
	console.log(receivedData, 'receivedData')
	console.log(milestone, "Milestone");

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleOptionClick = async (percentage) => {
		handleOptionSelect(milestoneIndex, percentage);
		handleClose();

		let apiUrl;
		let payload;

		if (activeTab === 1) {
			apiUrl = `${API_URLS.baseURL}/pb/updateAcadRoadPercentage`;
			payload = {
				percentage,
				task_id: `${milestone.sub_task_id}`,
				acad_activity_id: `${items?.acad_activity_id}`,
			};
		} else if (activeTab === 2) {
			apiUrl = `${API_URLS.baseURL}/pb/updateExtraCurrRoadPercentage`;
			payload = {
				percentage,
				task_id: `${milestone?.sub_task_id}`,
				extra_curr_activity_id: `${items?.extra_curr_activity_id}`,
			};
		} else if (activeTab === 5) {
			apiUrl = `${API_URLS.baseURL}/updatePassionPercentage`;
			payload = {
				percentage,
				activity_id: `${milestone?.passion_activity_id}`,
				passion_milestone_id: `${receivedData}`,
			};
		}

		if (apiUrl && payload) {
			try {
				const response = await axiosInstance.put(apiUrl, payload);

				if (response) {
					getTaskListData();
					console.log("API call successful");
				} else {
					console.error("API call failed");
				}
			} catch (error) {
				console.error("Error during API call", error);
			}
		}
	};

	return (
		<div>
			<IconButton
				aria-label="more"
				aria-controls={open ? "long-menu" : undefined}
				aria-expanded={open ? "true" : undefined}
				aria-haspopup="true"
				onClick={handleClick}
			>
				<MoreVertIcon className="rotate-90" />
			</IconButton>
			<Menu
				id="long-menu"
				MenuListProps={{
					"aria-labelledby": "long-button",
				}}
				className="no-scrollbar"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				PaperProps={{
					style: {
						maxHeight: ITEM_HEIGHT * 4.5,
						width: "12ch",
					},
				}}
			>
				{options.map((option) => (
					<MenuItem
						className="font-semibold font-plusJakartaSans text-xs"
						key={option.label}
						onClick={() => handleOptionClick(option.percentage)}
					>
						{option.label}
					</MenuItem>
				))}
			</Menu>
		</div>
	);
};

export default PercentageOptionModal;
