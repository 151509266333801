import {
	Checkbox,
	FormControlLabel,
	TextField,
	FormGroup,
} from "@mui/material";
import { medias } from "../../config/Resources";
import CommonSearch from "./AllSearch";
import { useEffect } from "react";

function FilterSearch({ filterData, setFilterData }) {

	const options = [
		{
			id: 1,
			lable: "IIT Bhubaneswar",
			number: "446",
		},
		{
			id: 2,
			lable: "IIT Bhubaneswar",
			number: "446",
		},
		{
			id: 3,
			lable: "IIT Bhubaneswar",
			number: "446",
		},
		{
			id: 4,
			lable: "IIT Bhubaneswar",
			number: "446",
		},
		{
			id: 5,
			lable: "IIT Bhubaneswar",
			number: "446",
		},
		{
			id: 6,
			lable: "IIT Bhubaneswar",
			number: "446",
		},
		{
			id: 7,
			lable: "IIT Bhubaneswar",
			number: "446",
		},
		{
			id: 8,
			lable: "IIT Bhubaneswar",
			number: "446",
		},
	];

	const handleCheckboxChange = (option) => {
		const index = filterData.findIndex((filter) => filter.id === option.id);
		if (index === -1) {
			setFilterData([...filterData, option]);
		} else {
			const updatedFilters = [...filterData];
			updatedFilters.splice(index, 1);
			setFilterData(updatedFilters);
		}
	};

	useEffect(() => {}, [filterData]);

	return (
		<>
			<div className="flex item-center text-center justify-between mt-4 ml-5">
				<div className="flex item-center text-center">
					<img
						className="h-6 w-6"
						src={medias.images.Profile.company1}
						alt="company 1"
					/>
					<p className="font-plusJakartaSans ml-2 text-[15px] mt-1 font-semibold">
						College
					</p>
				</div>
				<svg
					className="mr-5 mt-2"
					xmlns="http://www.w3.org/2000/svg"
					width="12.036"
					height="7.252"
					viewBox="0 0 12.036 7.252"
				>
					<path
						id="Icon_ionic-ios-arrow-back"
						data-name="Icon ionic-ios-arrow-back"
						d="M1.956,5.677,6.25,9.969A.811.811,0,1,1,5.1,11.114L.236,6.252A.81.81,0,0,1,.213,5.133L5.1.237A.811.811,0,0,1,6.247,1.382Z"
						transform="matrix(-0.017, 1, -1, -0.017, 11.781, 0.51)"
						stroke="#000"
						stroke-width="0.5"
						opacity="0.49"
					/>
				</svg>
			</div>
			<div>
				<div className="mt-5 ml-5 mr-5 mb-1">
					<CommonSearch />
				</div>
			</div>
			<FormGroup className="h-[300px] overflow-y-scroll flex pt-4 pb-6 pl-5 flex-col flex-nowrap no-scrollbar">
				{options?.map((option) => (
					<div className="flex text-center items-center justify-between !p-0 !pr-5 !m-0  ">
						<FormControlLabel
							className="miniDesk:!text-[10px] !font-plusJakartaSans !text-nowrap !overflow-ellipsis !text-[13px]"
							key={option.id}
							control={
								<div className="flex text-center items-center">
									<Checkbox
										color="success"
										checked={filterData.some(
											(filter) => filter.id === option.id
										)}
										onChange={() => handleCheckboxChange(option)}
									/>
									<p className="ml-[-6px] normal-case font-plusJakartaSans font-semibold">{option?.lable}</p>
								</div>
							}
						/>
						<p className="miniDesk:!hidden !font-plusJakartaSans !overflow-ellipsis !text-[13px] font-semibold text-xs">
							{option?.number}
						</p>
					</div>
				))}
			</FormGroup>
		</>
	);
}

export default FilterSearch;
