/* eslint-disable eqeqeq */
import React from "react";
import Text from "../../Text";
import {
  guidancePageContant,
  graduatePageContant,
} from "../../../config/pageContent";
import Flippingcard from "../../Flippingcard";
import Image from "../../Image";
import classNames from "classnames";

interface Props {
  page?: string | JSX.Element | JSX.Element[];
  title?: string | JSX.Element | JSX.Element[];
  titleClassName?: string;
  CardsList?: Array<any>;
  containerClassName?: string;
  bottomClasses?: string;
  cardContainerClass?: string;
  containerCardClasses?: string;
  backSideClass?: string;
  cardContainerImageClass?: string;
  cardBackClass?: string;
}

const Face2 = ({
  page,
  cardContainerClass,
  cardBackClass,
  backSideClass,
  cardContainerImageClass,
  containerCardClasses,
  containerClassName,
  bottomClasses,
  title = page == "graduate"
    ? graduatePageContant.Face2.howCareerGuidance
    : guidancePageContant.Face2.howCareerGuidance,
  titleClassName,
  CardsList = page == "graduate"
    ? graduatePageContant.Face2.careerGuidance
    : guidancePageContant.Face2.careerGuidance,
}: Props) => {
  return (
    <div className={classNames(containerClassName, "pt-[89px] z-10  ")}>
      <div>
        <Text
          className={classNames(
            titleClassName,
            "text-[50px] font-extrabold w-[40%] relative text-center mx-auto PlusJakartaSans-ExtraBoldCustom "
          )}
        >
          {title}
        </Text>
      </div>
      <div
        className={classNames(
          cardContainerClass,
          " z-20 gap-y-10 mt-[70.77px] items-center flex flex-wrap gap-4  justify-between mx-auto  h-screen max-w-[1200px] "
        )}
      >
        {CardsList.map((items, i) => {
          return (
            <Flippingcard
              key={i}
              containerClasses={classNames(containerCardClasses)}
              backClass={classNames(cardBackClass)}
              front={
                <>
                  <div
                    className={` overflow-hidden w-full  shadow-[0px_-1px_64px_#0000000F] rounded-[21px]  ${items.className}`}
                  >
                    <div
                      className={classNames(
                        cardContainerImageClass,
                        "h-[300px] w-full flex items-center justify-center overflow-hidden"
                      )}
                    >
                      <Image src={items.image} alt={"CareerGuide1"} />
                    </div>
                    <div
                      className={classNames(
                        bottomClasses,
                        "pt-[33.33px]  text-[24px] font-bold bg-white pb-[46px] text-center PlusJakartaSans-ExtraBoldCustom "
                      )}
                    >
                      {items.title}
                    </div>
                  </div>
                </>
              }
              back={
                <>
                  <div
                    className={`w-[376px] text-[15px] font-bold   leading-[28px] pl-[31px] pr-[18px] pt-[64.86px] pb-[46.91px] shadow-[0px -1px 64px #0000000F] rounded-[21px] h-[431px] ${backSideClass} ${items.className}`}
                  >
                    {items.desc}
                  </div>
                </>
              }
            />
          );
        })}
      </div>
    </div>
  );
};

export default Face2;
