import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { ExploreCard } from "../../Pages/Profile/SessionSchedule/ExploreCard";

function MentorSlider({ data }) {
  console.log("menotr data", data);
  var settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 3400,
    cssEase: "linear",
    slidesToShow: 2,
    slidesToScroll: 2,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="slider-container">
      <Slider {...settings}>
        {data?.map((mentor, i) => {
          return (
            <div key={i}>
              {/* <h3>1</h3> */}
              <ExploreCard mentor={mentor} isSlider={true} />
            </div>
          );
        })}
      </Slider>
    </div>
  );
}

export default MentorSlider;
