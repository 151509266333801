/* eslint-disable jsx-a11y/img-redundant-alt */
import { medias } from "../../../config/Resources";

const UpcomingSession = () => {
	return (
		<div>
			<div className="text-sm leading-6 mt-5">
				<figure
					className="dark:border-gray-600 p-2.5"
					style={{
						borderRadius: "20px",
						width: "100%",
						backgroundColor: "#FFC4F9",
					}}
				>
					<figcaption className="items-center">
						<div className="flex">
							<div
								style={{ position: "relative", width: "36%", height: "83px" }}
							>
								<img
									src={medias.images.mentorship.hand}
									style={{
										position: "relative",
										top: "-35px",
										width: "100%",
									}}
									alt=""
									className="flex-none"
									loading="lazy"
									decoding="async"
								/>
							</div>
							<div className="flex-auto w-2/5">
								<div
									className="leading-8"
									style={{
										color: "#102048",
										fontSize: "20px",
										fontWeight: "bolder",
									}}
								>
									Use Code&nbsp;
									<span
										style={{
											fontSize: "18px",
											background: "white",
											padding: "2px 15px",
											fontWeight: "bolder",
											border: "dotted",
											borderRadius: "10%",
											color: "#102048",
										}}
									>
										XX49MJ
									</span>
								</div>
								<div
									className="leading-9"
									style={{
										color: "#102048",
										fontSize: "20px",
										fontWeight: "bolder",
									}}
								>
									& Get 20% off on
								</div>
							</div>
						</div>
						<div className="flex justify-between ml-4">
							<span
								className="leading-8"
								style={{
									color: "#102048",
									fontSize: "20px",
									fontWeight: "bolder",
								}}
							>
								Career Counselling Plan Use Now !!
							</span>
						</div>
					</figcaption>
				</figure>
			</div>
		</div>
	);
};

export default UpcomingSession;
