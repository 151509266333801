import React, { useEffect, useRef, useState } from "react";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import axiosInstance from "../../../../config/Axios/axiosInstance";
import { API_URLS } from "../../../../config/API_URLS";

function ScholarShipChip({ id, setScholarChip, showTabs }) {
	const [selectedId, setSelectedId] = useState(null);
	const [scholarData, setScholarData] = useState([]);

	const handleChipClick = (id) => {
		const newSelectedId = selectedId === id ? null : id;
		setSelectedId(newSelectedId);
		setScholarChip(newSelectedId);
	};

	const scrollContainerRef = useRef(null);

	const scrollRight = () => {
		if (scrollContainerRef.current) {
			scrollContainerRef.current.scrollBy({
				left: 200,
				behavior: "smooth",
			});
		}
	};

	const fetchScholarData = async () => {
		const res = await axiosInstance.get(
			`${API_URLS.getIndianCollegeScholarships}?college_id=${id}`
		);
		if (res) {
			const data = res?.result?.scholarships;
			setScholarData(data);
			if (data && data.length > 0) {
				setSelectedId(data[0].id);
				setScholarChip(data[0].id);
			}
		}
	};

	useEffect(() => {
		if (showTabs === 5) {
			fetchScholarData();
		}
	}, [showTabs]);

	console.log(selectedId, "selectedId");

	return (
		<div className="relative mt-5">
			<div className="pl-4 pr-4">
				<div
					ref={scrollContainerRef}
					className="flex overflow-x-auto scrollable-content gap-1 mb-3"
				>
					{scholarData?.map((chip) => (
						<p
							key={chip.id}
							onClick={() => handleChipClick(chip?.id)}
							className={`font-plusJakartaSans whitespace-nowrap rounded-full border pt-1 pb-1 pl-4 pr-4 inline-flex text-[12px] text-center items-center gap-1 font-medium cursor-pointer ${
								selectedId === chip.id
									? "bg-[#2771E7] border-[#2771E7] text-white"
									: "border-[#D1D1D1] text-[#000000]"
							}`}
						>
							{chip?.scholarship_title}
						</p>
					))}
				</div>
			</div>
			<div className="absolute bottom-[8px] right-1 h-full flex items-center">
				<MdOutlineKeyboardArrowRight
					onClick={scrollRight}
					className="bg-[#ffffff] w-6 h-6 rounded-full p-1 border border-[#C2C2C2] cursor-pointer"
				/>
			</div>
		</div>
	);
}

export default ScholarShipChip;
