import React, { useContext, useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import { SocketContext } from "../Context";

const ringtone =
  "https://firebasestorage.googleapis.com/v0/b/my-app-bceb5.appspot.com/o/ring.mp3?alt=media&token=3eb08261-c59a-4dba-9970-8c29208f9b22";

const Notifications = () => {
  const { answerCall, call, callAccepted } = useContext(SocketContext);

  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = React.createRef();

  const togglePlay = () => {
    if (isPlaying) {
      audioRef?.current?.pause();
    } else {
      audioRef?.current?.play();
    }
    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    if (call.isReceivingCall && !callAccepted) {
      setIsPlaying(true);
      togglePlay();
    } else {
      setIsPlaying(false);
    }
  }, [call.isReceivingCall, callAccepted]);

  return (
    <>
      {call.isReceivingCall && !callAccepted && (
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <h1>{call.name} is calling:</h1>
          <Button variant="contained" color="primary" onClick={answerCall}>
            Answer
          </Button>
        </div>
      )}

      {call.isReceivingCall && !callAccepted && (
        <audio playsInline loop ref={audioRef} src={ringtone} />
      )}
    </>
  );
};

export default Notifications;
