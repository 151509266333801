import { medias } from "../../../config/Resources";

function BlogsDetailsImages({ blogsDetails }) {
	return (
		<div className="pt-9 pl-7 pr-7">
			<div className="relative flex justify-end">
				<img
					className="w-full h-[355px] rounded-[14px] object-cover"
					src={blogsDetails?.cover_image}
					alt="University"
				/>
				<div className="absolute bottom-0 left-[15px] w-[50%] bg-black bg-opacity-20 text-white p-2 flex items-center text-center gap-10">
					<div className="flex items-center text-center gap-2">
						<img
							className="w-8 h-8"
							src={medias.images.Blogs.HumanEye}
							alt="HumanEye"
						/>
						<p className="font-plusJakartaSans text-[15px] font-medium">12K</p>
					</div>
					<div className="flex items-center text-center gap-2">
						<img
							className="w-6 h-6"
							src={medias.images.Blogs.Chat1}
							alt="HumanEye"
						/>
						<p className="font-plusJakartaSans text-[15px] font-medium">333</p>
					</div>
					<div className="flex items-center text-center gap-2">
						<img
							className="w-6 h-6"
							src={medias.images.Blogs.Share}
							alt="HumanEye"
						/>
						<p className="font-plusJakartaSans text-[15px] font-medium">1K</p>
					</div>
				</div>
			</div>
		</div>
	);
}

export default BlogsDetailsImages;
