/* eslint-disable eqeqeq */
import { useParams } from "react-router-dom";
import Academic from "./Academic/Academic";
import ViewPage from "./Academic/ViewPage";

const Main = () => {
  const { type, roadMapType } = useParams();

  return (
    <div>
      {type && <Academic />}
      {roadMapType && <ViewPage />}
    </div>
  );
};

export default Main;
