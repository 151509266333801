import { FaStar } from "react-icons/fa";
import ExploreCardChips from "./ExploreCardChips";
import Line from "./Line";
import { Button } from "@mui/material";
import { EastRounded } from "@mui/icons-material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ScheduleIcon from "@mui/icons-material/Schedule";
// import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useUserInfoStore } from "../../../store/userinfo";

export function BookedCard({ cardIndex, data }) {
  const { userInfo } = useUserInfoStore((state) => state);

  // const navigate = useNavigate();

  const callCreate = () => {
    const callUrl = `https://video-call-firebase-kgfu.vercel.app/meet?type=create&user=student&callerName=${
      userInfo?.firstName + " " + userInfo?.lastName
    }&calleeName=${data?.firstName + " " + data?.lastName}&calleeId=${
      data?.mentor_id
    }&callerId=${userInfo?.id}&chatId=1020`;

    window.open(callUrl, "_black");
  };

  console.log("data", data);

  return (
    <div className=" mt-6 max-w-[440px] min-w-[440px] rounded-md border shadow-[0px_5px_16px_rgba(0,0,0,0.06)]">
      <div className="flex p-2">
        <img
          src={
            !!data?.profileImage
              ? data?.profileImage
              : "https://png.pngitem.com/pimgs/s/35-350426_profile-icon-png-default-profile-picture-png-transparent.png"
          }
          alt={data?.firstName}
          className="w-[154px] h-[157px] rounded-md object-cover"
        />
        <div className="pl-2">
          <h1 className="text-lg font-semibold flex">
            Dr. {data?.firstName + " " + data?.lastName}
            <span className="text-sm h-6 w-6"></span>
          </h1>

          <h6 className="mt-1 font-medium text-[12px]">Sessions Schedule</h6>
          <div className="flex  mt-3 justify-between w-full items-center bg-opacity-25  text-center gap-2 bg-gradient-to-r from-[#ffffff] to-[#23940341] rounded-[8px] px-[10px] py-[3px] ">
            <div className="flex items-center justify-center">
              <CalendarMonthIcon className="text-black   text-[16px]" />
              <span className="text-[#000000] font-bold text-[15px]">
                {moment(data?.schedule_date, "YYYY-MM-DD")?.format("DD MMM YY")}
              </span>
            </div>
            <div className="flex items-center justify-center">
              <ScheduleIcon className="text-black ck text-[16px]" />
              <span className="text-[#000000] font-bold text-[14px]">
                {moment(data?.start_time, "HH:mm:ss").format("h:mmA")}-
                {moment(data?.end_time, "HH:mm:ss").format("h:mmA")}
              </span>
            </div>
          </div>
          <div className="mt-4 flex flex-wrap text-center">
            <div className="flex justify-center items-center text-center gap-2 bg-[#F2F2F2] rounded-[6px] p-[6px] ">
              <FaStar className="text-[#F0D41B] text-[16px]" />
              <span className="text-[#000000] font-bold">4.5</span>
            </div>
            <h5 className="text-[#239403] underline underline-offset-4 m-2">
              35k+ Reviews
            </h5>
          </div>
        </div>
      </div>
      <div className="flex justify-between px-2">
        <ExploreCardChips />
        <ExploreCardChips />
        <ExploreCardChips />
      </div>
      <div className="my-3">
        <Line />
      </div>
      <div className="flex justify-around gap-x-4 text-center mb-2  px-5">
        <Button
          variant="contained"
          className="!border-[1px] w-full border-solid py-[19px] flex gap-[6.2px] miniDesk:!px-[2px] text-[16px] font-medium capitalize !text-[#000000] !bg-[#FAE9CB00] 3xl:py-[10px]  3xl:px-3 3xl:text-[13px] 3xl:h-fit !shadow-none px-[28px] !border-[#F0BC5E] rounded-[9px]"
        >
          <svg
            id="Group_412100"
            data-name="Group 412100"
            xmlns="http://www.w3.org/2000/svg"
            width="25.251"
            height="25.251"
            viewBox="0 0 25.251 25.251"
          >
            <path
              id="Path_267069"
              data-name="Path 267069"
              d="M13.565,1.254q.155,0,.31,0t.31,0a.881.881,0,1,1-.043,1.761q-.134,0-.268,0t-.268,0a.881.881,0,1,1-.043-1.761ZM10.469,2.6a.881.881,0,0,1-.5,1.139q-.249.1-.492.2a.881.881,0,0,1-.715-1.61q.283-.126.573-.237A.881.881,0,0,1,10.469,2.6ZM6.415,4.762a.881.881,0,0,1-.03,1.245q-.193.184-.377.377A.881.881,0,1,1,4.732,5.17q.214-.224.438-.438a.881.881,0,0,1,1.245.03ZM3.493,8.31a.881.881,0,0,1,.448,1.162q-.108.243-.2.492A.881.881,0,1,1,2.093,9.33q.112-.29.237-.573A.881.881,0,0,1,3.493,8.31Zm22.1,4.4a.881.881,0,0,1,.9.859q0,.155,0,.31t0,.31a.881.881,0,0,1-1.761-.043q0-.134,0-.268t0-.268A.881.881,0,0,1,25.6,12.706Zm-23.44,0a.881.881,0,0,1,.859.9q0,.134,0,.268t0,.268a.881.881,0,1,1-1.761.043q0-.155,0-.31t0-.31A.881.881,0,0,1,2.156,12.706Zm23,4.576a.881.881,0,0,1,.5,1.139q-.112.29-.237.573a.881.881,0,1,1-1.61-.715q.108-.243.2-.492a.881.881,0,0,1,1.139-.5Zm-22.555,0a.881.881,0,0,1,1.139.5q.1.249.2.492a.881.881,0,1,1-1.61.715q-.126-.283-.237-.573a.881.881,0,0,1,.5-1.139Zm20.391,4.054a.881.881,0,0,1,.03,1.245q-.214.224-.438.438a.881.881,0,0,1-1.215-1.276q.193-.184.377-.377A.881.881,0,0,1,22.989,21.336Zm-18.227,0a.881.881,0,0,1,1.245.03q.184.193.377.377A.881.881,0,0,1,5.17,23.019q-.224-.214-.438-.438a.881.881,0,0,1,.03-1.245ZM8.31,24.258a.881.881,0,0,1,1.162-.448q.243.108.492.2a.881.881,0,1,1-.634,1.643q-.29-.112-.573-.237a.881.881,0,0,1-.448-1.162Zm11.131,0a.881.881,0,0,1-.448,1.162q-.283.126-.573.237a.881.881,0,1,1-.634-1.643q.249-.1.492-.2a.881.881,0,0,1,1.162.448ZM12.706,25.6a.881.881,0,0,1,.9-.859q.134,0,.268,0t.268,0a.881.881,0,1,1,.043,1.761q-.155,0-.31,0t-.31,0A.881.881,0,0,1,12.706,25.6Z"
              transform="translate(-1.25 -1.25)"
              fill="#999"
              fill-rule="evenodd"
            />
            <g
              id="Group_412099"
              data-name="Group 412099"
              transform="translate(11.745)"
            >
              <path
                id="Path_267070"
                data-name="Path 267070"
                d="M12.131,3.012A10.864,10.864,0,0,1,22.995,13.875a.881.881,0,1,0,1.762,0A12.625,12.625,0,0,0,12.131,1.25a.881.881,0,0,0,0,1.762Z"
                transform="translate(-11.25 -1.25)"
                fill="#222"
                fill-rule="evenodd"
              />
              <path
                id="Path_267071"
                data-name="Path 267071"
                d="M12.131,8.25a.881.881,0,0,1,.881.881v3.817h3.817a.881.881,0,1,1,0,1.762h-4.7a.881.881,0,0,1-.881-.881v-4.7A.881.881,0,0,1,12.131,8.25Z"
                transform="translate(-11.25 -0.029)"
                fill="#222"
                fill-rule="evenodd"
              />
            </g>
          </svg>
          Reschedule
          {/* <EastRounded className="3xl:text-[16px] miniDesk:hidden" /> */}
        </Button>
        <Button
          onClick={() => callCreate()}
          variant="contained"
          className="!border-[1px] border-solid border-[#F0BC5E] text-nowrap w-full py-[20px] flex gap-[6.2px] miniDesk:!px-[2px] text-[16px] font-medium capitalize !text-[#000000] !bg-[#F0BC5E] 3xl:py-[11px]  3xl:px-3 3xl:text-[13px] 3xl:h-fit !shadow-none px-[28px]  rounded-[9px]"
        >
          Start Session{" "}
          <EastRounded className="3xl:text-[16px] miniDesk:hidden" />
        </Button>
      </div>
    </div>
  );
}
