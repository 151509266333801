import React from "react";
import { medias } from "../../config/Resources";

const CurrentSenerios = () => {
	const AcdameyDetails = [
		{
			image: medias.images.Profile.WeightLoass,
			label: "Body Weight",
			label1: "75kg",
		},
		{ image: medias.images.Profile.Height, label: "Height", label1: "123cm" },
		{ image: medias.images.Profile.Date, label: "Date", label1: "22 Nov 2023" },
		{ image: medias.images.Profile.Bmi, label: "BMI", label1: "N/A" },
	];

	return (
		<div className="max-w-md bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl mt-10 ml-5 border border-gray-300">
			<div className="w-full">
				<div className="uppercase tracking-wide text-sm font-bold pt-4 pl-4 pr-4">
					<h6 className="font-plusJakartaSans">Current Senerios</h6>
				</div>
				<div className="mt-4">
					<div className="h-px bg-gray-300"></div>
				</div>
				<div className="mt-2 pl-7 pt-6 flex items-center gap-24">
					{AcdameyDetails &&
						AcdameyDetails.map((detail, index) => (
							<div key={index} className="flex items-center gap-24">
								<div className="items-center">
									<img
										src={detail.image}
										alt={detail.label}
										className="h-10 w-10"
									/>
									<div className="text-sm mt-1">
										<p className="text-gray-600 font-plusJakartaSans text-xs">
											{detail.label}
										</p>
										<p className="font-bold text-sm font-plusJakartaSans">
											{detail.label1}
										</p>
									</div>
								</div>
							</div>
						))}
				</div>
			</div>
			<div className="p-5">
				<p className="font-plusJakartaSans text-sm font-extrabold">Body Weight</p>
				<p className="font-plusJakartaSans text-xs font-semibold mt-2 text-[#707070]">
					when an unknown printer took a galley of type and scrambled it to make
					a type specimen book. It has survived not only five centuries, but
					also the leap into electronic typesetting, remaining essentially
					unchanged. It was popularised in the 1960s with the release of
					Letraset sheets containing Lorem Ipsum passages, and more recently
					with desktop publishing software like Aldus PageMaker including
					versions of Lorem Ipsum.
				</p>
			</div>
		</div>
	);
};

export default CurrentSenerios;
