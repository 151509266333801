import { medias } from "../../../config/Resources";

function UniversityDetailsCards({ CollegeDetails }) {
	const cards = [
		{
			id: 1,
			title: "University Affiliated",
			text: CollegeDetails?.university_affiliated_name,
			image: `${medias.images.College.University}`,
		},
		{
			id: 2,
			title: "Approved By",
			text: CollegeDetails?.approved_by_name,
			image: `${medias.images.College.Approved}`,
		},
		{
			id: 3,
			title: "Affiliations",
			text: CollegeDetails?.affiliations,
			image: `${medias.images.College.Affiliations}`,
		},
		{
			id: 4,
			title: "Accommodation",
			boy: "Boys Hostel",
			girl: "Girls Hostel",
			text3: CollegeDetails?.boys_hostel,
			text4: CollegeDetails?.girls_hostel,
			image3: `${medias.images.College.Yes}`,
			image2: `${medias.images.College.Girl}`,
			image1: `${medias.images.College.Boy}`,
			image: `${medias.images.College.Accomodations}`,
		},
		{
			id: 5,
			title: "Accreditations",
			text: CollegeDetails?.accrediations_name,
			image: `${medias.images.College.Acrreditations}`,
		},
		{
			id: 6,
			title: "Associations",
			text: CollegeDetails?.associations,
			image: `${medias.images.College.Associations}`,
		},
	];

	return (
		<div className="container pt-5 pl-4 pr-4">
			<div className="grid grid-cols-1 md:grid-cols-2 gap-5">
				{cards.map((card, index) => (
					<div
						key={card.id}
						className="bg-[#FFFFFF] border border-[#E3E3E3] mt-4 rounded-lg"
					>
						<div className="bg-[#FFEDC0] rounded-t-lg">
							<h2 className="text-[14.5px] opacity-80 flex items-center text-center gap-2 font-plusJakartaSans font-extrabold pt-2 pb-2 pl-3">
								<img src={card.image} className="h-6 w-6" alt="images" />
								{card.title}
							</h2>
						</div>
						<div className="m-3 h-20 custom-scrollbar overflow-y-auto">
							{card.id === 4 ? (
								<>
									<div className="flex justify-between">
										<p className="font-plusJakartaSans opacity-80 gap-2 font-bold text-[12px] flex items-center">
											<img className="h-6 w-6" src={card.image1} alt="boy" />
											{card.boy}
										</p>
										<p className="flex text-center opacity-80 gap-1 font-plusJakartaSans text-[12px] text-[#239403] font-medium items-center">
											<img
												className="h-3 w-3 mt-[3px]"
												src={card.image3}
												alt="yes"
											/>
											<span>{card.text3}</span>
										</p>
									</div>
									<div className="flex justify-between mt-3">
										<p className="font-plusJakartaSans opacity-80 gap-2 font-bold text-[12px] flex items-center">
											<img
												className="h-6 w-6 mt-[5px]"
												src={card.image2}
												alt="girl"
											/>
											{card.girl}
										</p>
										<p className="flex opacity-80 text-center gap-1 font-plusJakartaSans text-[12px] text-[#239403] font-medium items-center">
											<img className="h-3 w-3" src={card.image3} alt="yes" />
											<span>{card.text4}</span>
										</p>
									</div>
								</>
							) : (
								<div className="flex flex-wrap gap-2">
									{card.text?.split(",").map((item, idx) => (
										<span
											key={idx}
											className="inline-block rounded-full px-3 py-1 bg-[#F3F6FF]"
										>
											<p className="font-plusJakartaSans opacity-80 font-bold text-[12px]">
												{item.trim()}
											</p>
										</span>
									))}
								</div>
							)}
						</div>
					</div>
				))}
			</div>
		</div>
	);
}

export default UniversityDetailsCards;
