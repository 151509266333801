/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from "react";
import { medias } from "../../../config/Resources";
import { Verified } from "@mui/icons-material";
import { IoTimeOutline } from "react-icons/io5";
import { RiArrowDropDownLine } from "react-icons/ri";
import { FaAngleUp } from "react-icons/fa";
import classNames from "classnames";
import { PiStarFourFill } from "react-icons/pi";
import { GoDotFill } from "react-icons/go";
import { GoThumbsup } from "react-icons/go";
import { GoThumbsdown } from "react-icons/go";
import { FaRegCommentDots } from "react-icons/fa6";

const WriteReviews = () => {
	const [selectedChip, setSelectedChip] = useState(null);
	const [selectedChip1, setSelectedChip1] = useState(null);
	const [showAllImages, setShowAllImages] = useState(false);

	const [isExpanded, setIsExpanded] = useState(false);

	const toggleReadMore = () => {
		setIsExpanded(!isExpanded);
	};

	const Chips = [
		{ id: 1, text: "Affiliated Colleges" },
		{ id: 2, text: "Stream" },
		{ id: 3, text: "Course Tag" },
		{ id: 4, text: "Course" },
		{ id: 5, text: "Batch" },
	];

	const popularTags = [
		{ id: 1, text: "Placement(30)" },
		{ id: 2, text: "Scholarship(128)" },
		{ id: 3, text: "Internship(30)" },
		{ id: 4, text: "Campus(135)" },
		{ id: 5, text: "Faculty(302)" },
		{ id: 6, text: "Engineering(83)" },
		{ id: 7, text: "HostelRooms" },
		{ id: 8, text: "Fee Structure(35)" },
		{ id: 9, text: "HostelFees(35)" },
		{ id: 10, text: "Senior Help(50)" },
		{ id: 11, text: "Mass Food(54)" },
		{ id: 12, text: "Major Exam(54)" },
		{ id: 13, text: "Gender Ratio(50)" },
	];

	const handleChipClick = (id) => {
		setSelectedChip(id);
	};

	const handleChipClick1 = (id) => {
		setSelectedChip1(id);
	};

	const chunkArray = (array, chunkSize) => {
		const result = [];
		for (let i = 0; i < array.length; i += chunkSize) {
			result.push(array.slice(i, i + chunkSize));
		}
		return result;
	};

	const rows = chunkArray(popularTags, 7);

	const selectedData = [
		{
			id: 1,
			text: "Scholarship",
		},
		{
			id: 2,
			text: "Internship",
		},
		{
			id: 3,
			text: "Hostel Fees",
		},
	];

	const data = [
		{
			id: 1,
			text: "Infrastructure 3",
		},
		{
			id: 2,
			text: "Campus life 3",
		},
		{
			id: 3,
			text: "Admissions 3",
		},
		{
			id: 4,
			text: "Value for money 3",
		},
	];

	const rating = 3;
	const images = new Array(15).fill(medias.images.College.Image10);

	const displayedImages = showAllImages ? images : images.slice(0, 6);
	const imageChunks = chunkArray(displayedImages, 10);

	const handleShowMore = () => {
		setShowAllImages(true);
	};

	return (
		<>
			<div className="flex items-center text-center gap-5 ml-5 mt-5">
				<p className="font-plusJakartaSans bg-[#2771E7] text-[white] flex item-center gap-2 text-center pt-2 pb-2 pl-4 pr-4 text-[15px] rounded-t-2xl">
					<img className="w-5 h-5" src={medias.images.College.Pen} alt="Pen" />
					Write Reviews
				</p>
				<p className="font-plusJakartaSans text-[#000000] flex item-center gap-2 text-center pt-2 pb-2 pl-4 pr-4 text-[15px] rounded-t-lg">
					<img
						className="w-5 h-5"
						src={medias.images.College.PlayButton}
						alt="Pen"
					/>
					Vlogs Reviews
				</p>
			</div>
			<div className="w-[100%] rounded-xl overflow-hidden shadow-lg pb-5 bg-[#FFFFFF]">
				<div className="flex justify-between p-5 items-center text-center">
					<div className="flex gap-4">
						<p className="font-plusJakartaSans rounded-md bg-[#E7E7E7] pt-1 pb-1 pl-4 pr-4 flex text-[13.5px] text-center items-center gap-1 font-medium text-[#000000]">
							<img
								className="h-4 w-4"
								src={medias.images.College.Filters}
								alt="filter"
							/>
							Filter By
						</p>
						{Chips.map((chip) => (
							<p
								key={chip.id}
								onClick={() => handleChipClick(chip.id)}
								className={`font-plusJakartaSans rounded-full border pt-1 pb-1 pl-4 pr-4 flex text-[13.5px] text-center items-center gap-1 font-medium cursor-pointer ${
									selectedChip === chip.id
										? "border-[#2771E7] text-[#2771E7]"
										: "border-[#D1D1D1] text-[#000000]"
								}`}
							>
								{chip.text}
							</p>
						))}
					</div>
					<div className="flex text-center items-center gap-4">
						<p className="font-plusJakartaSans rounded-md pt-1 pb-1 pl-4 pr-4 flex text-[13.5px] text-center items-center gap-1 font-medium text-[#000000]">
							<img
								className="h-4 w-4"
								src={medias.images.College.SortBy}
								alt="filter"
							/>
							Sort By
						</p>
						<p className="font-plusJakartaSans rounded-md border border-[#E7E7E7] pt-1 pb-1 pl-4 pr-4 flex text-[13.5px] text-center items-center gap-1 font-medium text-[#000000]">
							Relevance
							<img
								className="h-3 w-3 mt-[3px]"
								src={medias.images.College.DropDown}
								alt="filter"
							/>
						</p>
					</div>
				</div>
				<div className="border-b-2 border-[#E7E7E7] mb-3 ml-5 mr-5"></div>
				<div className="pl-5 pr-5 pb-2">
					<p className="font-plusJakartaSans rounded-full border opacity-100 border-[#E7E7E7] pt-1 pb-1 pl-4 pr-4 inline-flex text-[13.5px] text-center items-center gap-1 font-medium text-[#000000]">
						Department of management Studies IIT Delhi - [DMS IITD][39]
					</p>
				</div>
				<div className="pl-5 pr-5 pb-2 pt-5">
					<h6 className="font-plusJakartaSans mb-5 font-bold text-[17px] text-[#000000]">
						Most Popular Tags
					</h6>
					{rows.map((row, rowIndex) => (
						<div key={rowIndex} className="flex flex-wrap gap-4 mt-2 ">
							{row.map((chip) => (
								<p
									key={chip.id}
									onClick={() => handleChipClick1(chip.id)}
									className={`font-plusJakartaSans rounded-full border pt-1 pb-1 pl-4 pr-4 inline-flex text-[13.5px] text-center items-center gap-1 font-medium cursor-pointer ${
										selectedChip1 === chip.id
											? "border-[#2771E7] text-[#2771E7]"
											: "border-[#D1D1D1] text-[#000000]"
									}`}
								>
									{chip.text}
								</p>
							))}
						</div>
					))}
				</div>
				<div className="pl-5 pr-5 pt-5">
					<div className="flex flex-wrap gap-4 mt-2 mb-3">
						{selectedData.map((chip) => (
							<p
								key={chip.id}
								className="font-plusJakartaSans bg-[#F37400] bg-opacity-10 rounded-full border pt-1 pb-1 pl-4 pr-4 inline-flex text-[13.5px] text-center items-center gap-1 font-medium cursor-pointer"
							>
								{chip.text}
							</p>
						))}
					</div>
				</div>
				<div className="ml-5 mt-5 flex items-center mb-2">
					<p className="font-plusJakartaSans w-12 h-12 font-bold text-[#2771E7] bg-[#2771E7] bg-opacity-10 p-2 rounded-xl text-[25px] flex justify-center items-center">
						V
					</p>
					<div className="ml-1">
						<p className="font-plusJakartaSans text-[17px] font-semibold flex items-center text-center gap-1">
							Jaypal Sharma
							<Verified className="text-[rgba(39,113,231,1)] ml-1 3xl:text-[18px]" />
						</p>
						<p className="font-plusJakartaSans mt-[2px] text-[#707070] text-[12px] font-medium flex items-center text-center gap-1">
							<IoTimeOutline />
							24 May 2023, 4:43 IST
						</p>
					</div>
				</div>
				<div className="ml-5 mt-4 flex items-center mb-2">
					<p className="font-plusJakartaSans mt-[1px] font-extrabold text-[13px]">
						3.0
					</p>
					<div className="flex ml-2">
						{[1, 2, 3, 4, 5].map((star) => (
							<svg
								key={star}
								xmlns="http://www.w3.org/2000/svg"
								className="h-4 w-4"
								viewBox="0 0 20 20"
								fill={star <= rating ? "#FFC000" : "#DFDFDF"}
							>
								<path d="M9.049 2.927a1 1 0 011.902 0l1.286 4.145a1 1 0 00.95.69h4.282a1 1 0 01.618 1.82l-3.467 2.52a1 1 0 00-.364 1.118l1.286 4.145a1 1 0 01-1.54 1.118L10 15.347l-3.467 2.52a1 1 0 01-1.54-1.118l1.286-4.145a1 1 0 00-.364-1.118L2.449 9.582a1 1 0 01.618-1.82h4.282a1 1 0 00.95-.69L9.049 2.927z" />
							</svg>
						))}
					</div>
					<div className="flex flex-wrap gap-4 ml-8">
						{data.map((chip) => (
							<p
								key={chip.id}
								className="font-plusJakartaSans rounded-full border border-[#D1D1D1] pt-1 pb-1 pl-4 pr-4 inline-flex text-[12px] text-center items-center item-center gap-1 font-semibold cursor-pointer"
							>
								{chip.text}
								<PiStarFourFill className="text-[#F37400]" />
							</p>
						))}
					</div>
				</div>
				<div className="ml-5 mt-4 mb-2">
					<p className="font-plusJakartaSans text-[#000000] font-extrabold text-[14px]">
						15 Photos From Students
					</p>
					{imageChunks.map((chunk, chunkIndex) => (
						<div key={chunkIndex} className="flex items-center gap-4 mt-4">
							{chunk.map((src, index) => (
								<div key={index} className="relative">
									<img
										className="rounded-lg w-20 h-20"
										src={src}
										alt={`image${index + 1}`}
										onClick={
											index === chunk.length - 1 && !showAllImages
												? handleShowMore
												: null
										}
									/>
									{index === chunk.length - 1 &&
										!showAllImages &&
										chunkIndex === imageChunks.length - 1 && (
											<div
												className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center rounded-lg cursor-pointer"
												onClick={handleShowMore}
											>
												<p className="text-white font-plusJakartaSans text-[12.5px] font-bold">
													+{images.length - displayedImages.length} more
												</p>
											</div>
										)}
								</div>
							))}
						</div>
					))}
				</div>
				<div className="ml-5 mt-2 mb-2 mr-3">
					<div className="relative overflow-hidden">
						<p
							className={classNames(
								"font-plusJakartaSans font-medium opacity-70 text-[12px] text-[#000000] mt-1",
								{ "line-clamp-6": !isExpanded }
							)}
						>
							Lorem Ipsum is simply dummy text of the printing and typesetting
							industry. Lorem Ipsum has been the industry's standard dummy text
							ever since the 1500s, when an unknown printer took a galley of
							type and scrambled it to make a type specimen book. It has
							survived not only five centuries, but also the leap into
							electronic typesetting, remaining essentially unchanged. It was
							popularised in the 1960s with the release of Letraset sheets
							containing Lorem Ipsum passages, and more recently with desktop
							publishing software like Aldus PageMaker including versions of
							Lorem Ipsum.Lorem Ipsum is simply dummy text of the printing and
							typesetting industry. Lorem Ipsum has been the industry's standard
							dummy text ever since the 1500s, when an unknown printer took a
							galley of type and scrambled it to make a type specimen book. It
							has survived not only five centuries, but also the leap into
							electronic typesetting, remaining essentially unchanged. It was
							popularised in the 1960s with the release of Letraset sheets
							containing Lorem Ipsum passages, and more recently with desktop
							publishing software like Aldus PageMaker including versions of
							Lorem Ipsum.
						</p>
						{!isExpanded && (
							<div className="absolute bottom-0 left-0 w-full h-8 bg-gradient-to-t from-white to-transparent"></div>
						)}
					</div>
					<button
						onClick={toggleReadMore}
						className="mt-2 text-blue-500 font-plusJakartaSans font-bold ml-[90%] text-[13px] flex items-center"
					>
						{isExpanded ? (
							<>
								Read less
								<FaAngleUp className="w-4 h-4 ml-1" />
							</>
						) : (
							<>
								Read more
								<RiArrowDropDownLine className="w-6 h-6" />
							</>
						)}
					</button>
				</div>
				<div className="ml-5">
					<h6 className="font-plusJakartaSans font-extrabold text-[14px] flex items-center text-center gap-1">
						What students says about IIT Dehi
					</h6>
					<p className="font-plusJakartaSans mt-3 font-extrabold text-[14.5px] flex items-center text-center gap-1">
						<img
							className="h-8 w-8"
							src={medias.images.College.Pros}
							alt="Pros"
						/>
						Pros
					</p>
					<p className="font-plusJakartaSans flex text-center text-[12px] mt-3 font-semibold text-[#000000] text-opacity-70">
						<GoDotFill className="mt-1 w-2 h-2" /> Get your personalised list of
						colleges & exams matching your preferences
					</p>
					<p className="font-plusJakartaSans flex text-center text-[12px] mt-1 font-semibold text-[#000000] text-opacity-70">
						<GoDotFill className="mt-1 w-2 h-2" /> Get your personalised list of
						colleges & exams matching your preferences
					</p>
					<p className="font-plusJakartaSans flex text-center text-[12px] mt-1 font-semibold text-[#000000] text-opacity-70">
						<GoDotFill className="mt-1 w-2 h-2" /> Chat with our counselor
					</p>
					<p className="font-plusJakartaSans flex text-center text-[12px] mt-1 font-semibold text-[#000000] text-opacity-70">
						<GoDotFill className="mt-1 w-2 h-2" /> Get your personalised list of
						colleges & exams matching your preferences
					</p>

					<p className="font-plusJakartaSans mt-3 font-extrabold text-[14.5px] flex items-center text-center gap-1">
						<img
							className="h-8 w-8"
							src={medias.images.College.Cons}
							alt="Pros"
						/>
						Pros
					</p>
					<p className="font-plusJakartaSans flex text-center text-[12px] mt-3 font-semibold text-[#000000] text-opacity-70">
						<GoDotFill className="mt-1 w-2 h-2" />
						Get your personalised list of colleges & exams matching your
						preferences
					</p>
					<p className="font-plusJakartaSans flex text-center text-[12px] mt-1 font-semibold text-[#000000] text-opacity-70">
						<GoDotFill className="mt-1 w-2 h-2" /> Get your personalised list of
						colleges & exams matching your preferences
					</p>
				</div>
				<div className="border-b ml-5 mr-3 mb-3 mt-5 border-dashed border-opacity-20 border-black"></div>
				<div className="flex item-center text-center justify-between mr-5">
					<div className="flex item-center text-center gap-4">
						<p className="font-plusJakartaSans ml-5 text-[13px] font-semibold flex item-center text-center space-2 text-[#239403]">
							<GoThumbsup className="w-5 h-5" />{" "}
							<span className="ml-2 mt-[2px]">1</span>
						</p>
						<p className="font-plusJakartaSans ml-5 text-[13px] font-semibold flex item-center text-center space-2 text-[#D91F1F]">
							<GoThumbsdown className="w-5 h-5" />{" "}
							<span className="ml-2 mt-[2px]">0</span>
						</p>
						<p className="font-plusJakartaSans ml-5 text-[12px] font-semibold flex item-center text-center space-2 text-[#2771E7]">
							<FaRegCommentDots className="w-5 h-5" />{" "}
							<span className="ml-2 mt-[2px]">Comments</span>
						</p>
					</div>
					<p className="font-plusJakartaSans ml-5 text-[12px] font-semibold flex item-center text-center space-2 text-[#D91F1F]">
						<img
							src={medias.images.College.Report}
							alt="Report"
							className="w-5 h-5"
						/>{" "}
						<span className="ml-2 mt-[2px]">Report</span>
					</p>
				</div>
			</div>
		</>
	);
};

export default WriteReviews;
