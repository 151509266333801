import React, { useState } from "react";
import { medias } from "../../../../config/Resources";

const ReviewTabs = () => {
	const [activeTab, setActiveTab] = useState(null);
	const [selectedChip, setSelectedChip] = useState(null);
	const [selectedChip1, setSelectedChip1] = useState(null);

	const Chips = [
		{ id: 1, text: "Affiliated Colleges" },
		{ id: 2, text: "Stream" },
		{ id: 3, text: "Course Tag" },
		{ id: 4, text: "Course" },
		{ id: 5, text: "Batch" },
	];

	const popularTags = [
		{ id: 1, text: "Placement(30)" },
		{ id: 2, text: "Scholarship(128)" },
		{ id: 3, text: "Internship(30)" },
		{ id: 4, text: "Campus(135)" },
		{ id: 5, text: "Faculty(302)" },
		{ id: 6, text: "Engineering(83)" },
		{ id: 7, text: "HostelRooms" },
		{ id: 8, text: "Fee Structure(35)" },
		{ id: 9, text: "HostelFees(35)" },
		{ id: 10, text: "Senior Help(50)" },
		{ id: 11, text: "Mass Food(54)" },
		{ id: 12, text: "Major Exam(54)" },
		{ id: 13, text: "Gender Ratio(50)" },
	];

	const handleChipClick1 = (id) => {
		setSelectedChip1(id);
	};

	const chunkArray = (array, chunkSize) => {
		const result = [];
		for (let i = 0; i < array.length; i += chunkSize) {
			result.push(array.slice(i, i + chunkSize));
		}
		return result;
	};

	const rows = chunkArray(popularTags, 7);

	const handleChipClick = (id) => {
		setSelectedChip(id);
	};

	const handleTabClick = (tab) => {
		setActiveTab(tab);
	};

	const inactiveTabStyle = {
		borderBottom: "2px solid rgba(39, 113, 231, 0.1)",
	};

	return (
		<div>
			<div className="flex items-center text-center gap-5 ml-5 mt-5">
				<p
					className={`font-plusJakartaSans flex items-center gap-2 text-center pt-2 pb-2 pl-4 pr-4 text-[13px] cursor-pointer rounded-t-2xl ${
						activeTab === "write"
							? "bg-[#2771E7] text-white"
							: "border-2 border-[#2771E7] text-[#2771E7]"
					}`}
					style={activeTab !== "write" ? inactiveTabStyle : {}}
					onClick={() => handleTabClick("write")}
				>
					<img
						className="w-4 h-4 mt-[2px]"
						src={medias.images.College.Pen}
						alt="Pen"
					/>
					Write Reviews
				</p>
				<p
					className={`font-plusJakartaSans flex items-center gap-2 text-center pt-2 pb-2 pl-4 pr-4 text-[13px] cursor-pointer rounded-t-2xl ${
						activeTab === "vlogs"
							? "bg-[#2771E7] text-white"
							: "border-2 border-[#2771E7] text-[#2771E7]"
					}`}
					style={activeTab !== "vlogs" ? inactiveTabStyle : {}}
					onClick={() => handleTabClick("vlogs")}
				>
					<img
						className="w-4 h-4 mt-[2px]"
						src={medias.images.College.PlayButton}
						alt="Pen"
					/>
					Vlogs Reviews
				</p>
			</div>
			<div className="w-[100%] rounded-xl pt-1">
				<div className="flex justify-between p-5 items-center text-center">
					<div className="flex gap-4">
						<p className="font-plusJakartaSans rounded-md bg-[#E7E7E7] pt-1 pb-1 pl-4 pr-4 flex text-[12.5px] text-center items-center gap-1 font-medium text-[#000000]">
							<img
								className="h-4 w-4"
								src={medias.images.College.Filters}
								alt="filter"
							/>
							Filter By
						</p>
						{Chips.map((chip) => (
							<p
								key={chip.id}
								onClick={() => handleChipClick(chip.id)}
								className={`font-plusJakartaSans rounded-full border pt-1 pb-1 pl-4 pr-4 flex text-[12.5px] text-center items-center gap-1 font-medium cursor-pointer ${
									selectedChip === chip.id
										? "border-[#2771E7] text-[#2771E7]"
										: "border-[#D1D1D1] text-[#000000]"
								}`}
							>
								{chip.text}
							</p>
						))}
					</div>
					<div className="flex text-center items-center gap-4">
						<p className="font-plusJakartaSans rounded-md pt-1 pb-1 pl-4 pr-4 flex text-[12.5px] text-center items-center gap-1 font-medium text-[#000000]">
							<img
								className="h-4 w-4"
								src={medias.images.College.SortBy}
								alt="filter"
							/>
							Sort By
						</p>
						<p className="font-plusJakartaSans rounded-md border border-[#E7E7E7] pt-1 pb-1 pl-4 pr-4 flex text-[12.5px] text-center items-center gap-1 font-medium text-[#000000]">
							Relevance
							<img
								className="h-3 w-3 mt-[3px]"
								src={medias.images.College.DropDown}
								alt="filter"
							/>
						</p>
					</div>
				</div>
			</div>
			<div className="border-b-2 border-[#E7E7E7] mb-3 ml-5 mr-5"></div>
			<div className="pl-5 pr-5 pb-2">
				<p className="font-plusJakartaSans rounded-full border opacity-100 border-[#E7E7E7] pt-1 pb-1 pl-4 pr-4 inline-flex text-[12.5px] text-center items-center gap-1 font-medium text-[#000000]">
					Department of management Studies IIT Delhi - [DMS IITD][39]
				</p>
			</div>
			<div className="pl-6 pr-5 pb-2 pt-5">
				<h6 className="font-plusJakartaSans mb-4 font-bold text-[14px] text-[#000000]">
					Most Popular Tags
				</h6>
				{rows.map((row, rowIndex) => (
					<div key={rowIndex} className="flex flex-wrap gap-4 mt-2 ">
						{row.map((chip) => (
							<p
								key={chip.id}
								onClick={() => handleChipClick1(chip.id)}
								className={`font-plusJakartaSans rounded-full border pt-1 pb-1 pl-4 pr-4 inline-flex text-[12px] text-center items-center gap-1 font-medium cursor-pointer ${
									selectedChip1 === chip.id
										? "border-[#2771E7] text-[#2771E7]"
										: "border-[#D1D1D1] text-[#000000]"
								}`}
							>
								{chip.text}
							</p>
						))}
					</div>
				))}
			</div>
		</div>
	);
};

export default ReviewTabs;
