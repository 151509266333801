/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { medias } from "../../../config/Resources";
import { Chart } from "react-google-charts";

function CollegeFacts({ CollegeDetails }) {
	const data = [
		["Activity", "Hours per Day"],
		["Work", 11],
		["Eat", 2],
		["Commute", 2],
		["Watch TV", 2],
		["Sleep", 7],
	];

	const options = {
		is3D: true,
		legend: "none",
		pieSliceText: "none",
		backgroundColor: "none",
	};

	return (
		<div className="ml-4 mr-4 relative">
			<div className="flex items-center mt-5">
				<span className="bg-[#E7E7E7] rounded pt-[5px] pb-[5px] pl-[7px] pr-[7px]">
					<img
						className="h-[35px] w-8"
						src={medias.images.College.Contract}
						alt="image 6"
					/>
				</span>
				<h6 className="font-plusJakartaSans ml-[8px] mt-[5px] font-bold text-[18px]">
					College Facts
				</h6>
			</div>
			<div className="flex items-center mt-5">
				<img
					className="h-14 w-14"
					src={medias.images.College.CollegeFounded}
					alt="image 6"
				/>
				<div className="mt-[5px]">
					<p className="font-plusJakartaSans ml-[8px] font-medium text-[13px]">
						Founded
					</p>
					<p className="font-plusJakartaSans ml-[8px] font-bold text-[17px]">
						{CollegeDetails?.founded}
					</p>
				</div>
			</div>
			<div className="flex items-center">
				<div className="flex items-center">
					<div className="ml-1"> 
						<p className="font-plusJakartaSans w-[100%] text-[#000000] mt-6 flex item-center text-center font-medium text-[15px] mr-4">
							<div className="bg-[#FFBE1D] rounded-lg h-[4px] w-[20px] mt-2 mr-1"></div>
							Graduated Students
							<span className="font-plusJakartaSans ml-[26%] text-[16px] font-bold">
								400
							</span>
						</p>
						<p className="font-plusJakartaSans w-[100%] text-[#000000] mt-5 flex item-center text-center font-medium text-[15px] mr-4">
							<div className="bg-[#003E56] rounded-lg h-[4px] w-[20px] mt-2 mr-1"></div>
							Post Graduated Students
							<span className="font-plusJakartaSans ml-8 text-[16px] font-bold">
								{CollegeDetails?.post_grad}
							</span>
						</p>
					</div>
					<div className="absolute left-[40%] top-[15%] bottom-2">
						<Chart chartType="PieChart" data={data} options={options} />
					</div>
					<div className="mt-[18.5%] ml-36 mr-14">
						<p className="font-plusJakartaSans text-[#000000] flex item-center text-center font-medium text-[15px] mr-4">
							Total Students
							<span className="font-plusJakartaSans ml-5 text-[16px] font-bold">
								{CollegeDetails?.total_students}
							</span>
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}

export default CollegeFacts;
