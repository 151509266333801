import { medias } from "../../../config/Resources";

function SpecializationCareer({ details }) {
	return (
		<div className="w-auto rounded-[20px] ml-auto mr-auto overflow-hidden p-6 shadow-lg border border-gray-300 mt-10">
			<div className="flex items-center text-center">
				<img src={medias.images.Profile.cap} alt="cap" />
				<h6 className="font-plusJakartaSans font-bold text-lg ml-1">
					Specialization in the career
				</h6>
			</div>
			<div className="grid grid-cols-2 gap-4">
				{details?.specialisation?.map((item, index) => (
					<div
						key={index}
						className="w-auto h-[300px] mt-5 scrollable-content rounded-[20px] overflow-x-hidden overflow-y-scroll p-6 shadow-lg border border-[#F0BC5E]"
						style={{ boxShadow: "1px 1px 1px 1px rgb((240,188,94))" }}
					>
						<div className="">
							<h6 className="font-plusJakartaSans font-bold text-lg text-[#000000]">
								{item.specialisation_name}
							</h6>
							<img
								className="h-12 w-12 mt-1"
								src={medias.images.Profile.healthCare}
								alt="growth"
							/>
						</div>
						<div className="mt-2">
							<p className="font-plusJakartaSans font-semibold text-[#0606068c] text-[12px]" dangerouslySetInnerHTML={{__html:item.summary}}>
							</p>
						</div>
					</div>
				))}
			</div>
		</div>
	);
}

export default SpecializationCareer;
