import React, { useState } from "react";
import { medias } from "../../../config/Resources";

const CardsChips = () => {
	const [active, setActive] = useState(1);
	const tabs = [
		{ name: "Responsibilities", id: 1 },
		{ name: "Personalities", id: 2 },
		{ name: "Known as", id: 3 },
		{ name: "Specialization", id: 4 },
		{ name: "Edu. Pathway", id: 5 },
		{ name: "Skill", id: 6 },
		{ name: "Salaries", id: 7 },
		{ name: "Growth", id: 8 },
		{ name: "Future Growth", id: 9 },
		{ name: "Top Recruiting Companies", id: 10 },
		{ name: "Avg. Salary", id: 11 },
	];

	const handleTabClick = (id) => {
		setActive(id);
		// setActiveTab(id);
	};

	return (
		<div className="flex flex-col mt-5 ml-3">
			<div className={`${active === 2 ? "w-full" : "w-[100%]"}`}>
				<div className="flex font-bold border-b w-[100%] border-gray-200">
					{tabs?.map((tab) => (
						<div
							key={tab.id}
							onClick={() => handleTabClick(tab.id)}
							className={`relative  cursor-pointer flex items-center mx-1 ${
								active === tab.id
									? "bg-[#FFEEDB] rounded-t-lg border-[#04043B] border-b-4"
									: ""
							}`}
						>
							<div className="flex items-center pt-2 pl-2 pr-2">
								<h3
									className={` text-[12px]  overflow-scroll scrollable-content ${
										active === tab.id
											? "font-extrabold text-[#04043B]"
											: "font-light text-[#707070]"
									}`}
								>
									<span className="font-plusJakartaSans">{tab.name}</span>
								</h3>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default CardsChips;
