/* eslint-disable jsx-a11y/img-redundant-alt */
import moment from "moment";
import { medias } from "../../../../config/Resources";

function ImportantDatesTables({ ImportantDates }) {
	return (
		<div className="w-[100%]">
			{ImportantDates?.dates_to_remember?.map((data) => {
				return (
					<div>
						<p className="flex items-center mt-7 ml-4 font-plusJakartaSans font-bold text-[15px] text-center gap-2">
							<img
								className="h-5 w-5"
								src={medias.images.Exam.ImportantDates}
								alt="image"
							/>

							{data?.category}
						</p>
						<div className="ml-4 mr-4 mt-3">
							<div className="overflow-x-auto">
								<table className="min-w-full border-collapse">
									<thead>
										<tr>
											<th className="px-4 py-2 bg-[#FFEDC0] text-center font-plusJakartaSans font-bold text-[12px] rounded-l-sm border-r-2 border-white">
												Event Name
											</th>
											<th className="px-4 py-2 bg-[#FFEDC0] text-center font-plusJakartaSans font-bold text-[12px] border-r-2 border-white">
												Start Date
											</th>
											<th className="px-4 py-2 bg-[#FFEDC0] text-center font-plusJakartaSans font-bold text-[12px] rounded-r-sm">
												End Date
											</th>
										</tr>
									</thead>
									<tbody className="bg-[#ffffff]">
										{data?.event_details?.map((detail, index) => (
											<tr
												key={index}
												className="border-b border-dotted last:border-0"
											>
												<td className="px-4 py-2 text-center font-plusJakartaSans font-medium text-[11px]">
													{detail?.event_name}
												</td>
												<td className="px-4 py-2 text-center font-plusJakartaSans font-medium text-[11px]">
													{detail?.from_date
														? moment(detail.from_date).format("DD MMM YYYY")
														: ""}
												</td>
												<td className="px-4 py-2 text-center font-plusJakartaSans font-medium text-[11px]">
													{detail?.to_date
														? moment(detail.to_date).format("DD MMM YYYY")
														: ""}
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				);
			})}
		</div>
	);
}

export default ImportantDatesTables;
