import React, { useEffect, useState } from "react";
import { medias } from "../../config/Resources";
import axiosAdminInstance from "../../config/Axios/axiosAdminInstance";
import { API_URLS } from "../../config/API_URLS";

const CareerCluster = () => {
	const [clickedIndex, setClickedIndex] = useState(null);

	const [cluster, setCluster] = useState([]);

	useEffect(() => {
		(async () => {
			const res = await axiosAdminInstance.get(
				`${API_URLS.getAllDropdowns}?type=cluster
			`
			);
			if (res?.statusCode === 200) {
				setCluster(res?.result.cluster);
			}
		})();
	}, []);


	return (
		<div className="flex justify-center">
			<div className="max-w-md bg-[#FFFFFF] md:max-w-[70%]  rounded-xl overflow-hidden shadow-md mt-16 mb-10">
				<div className="w-full">
					<div className="tracking-wide text-[20px]  font-bold pt-4 pl-4 pr-4 flex justify-between">
						<h6 className="font-plusJakartaSans pl-10 flex text-center items-center gap-2">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="29"
								height="29"
								viewBox="0 0 37.914 37.915"
							>
								<g id="degree" transform="translate(0 -0.002)">
									<path
										id="Path_103831"
										data-name="Path 103831"
										d="M32.366,42.124l2.7,7.754,1.7-3.415L41.4,47.6l-5.271-8.832Z"
										transform="translate(-14.368 -16.962)"
										fill="#f16176"
									/>
									<path
										id="Path_103832"
										data-name="Path 103832"
										d="M43.7,32.128l7.921,4.044-3.68,1,.208,4.777-8.064-6.6Z"
										transform="translate(-17.497 -14.269)"
										fill="#f16176"
									/>
									<path
										id="Path_103833"
										data-name="Path 103833"
										d="M25.508,34.024,13.782,48.595a2.31,2.31,0,0,1-3.433.185L3.677,42.107a2.31,2.31,0,0,1,.185-3.433l14.57-11.726Z"
										transform="translate(-2.41 -12.133)"
										fill="#f2f1e8"
									/>
									<path
										id="Path_103834"
										data-name="Path 103834"
										d="M34.024,25.508l14.57-11.726a2.31,2.31,0,0,0,.185-3.433L42.107,3.677a2.31,2.31,0,0,0-3.433.185L26.948,18.432Z"
										transform="translate(-12.133 -2.405)"
										fill="#f2f1e8"
									/>
									<path
										id="Path_103835"
										data-name="Path 103835"
										d="M14.758,20.129,3.863,28.891a2.31,2.31,0,0,0-.186,3.433l.657.657L17.239,22.61Zm5.01-4.923,2.482,2.482L32.98,4.334l-.657-.657a2.31,2.31,0,0,0-3.433.186Z"
										transform="translate(-2.41 -2.405)"
										fill="#d0d2c7"
										opacity="0.5"
									/>
									<path
										id="Path_103836"
										data-name="Path 103836"
										d="M31.867,32.341l-.578.689-.11-.11Z"
										transform="translate(-13.892 -14.364)"
										fill="#fff"
									/>
									<path
										id="Path_103837"
										data-name="Path 103837"
										d="M32.92,31.178l.11.11-.688.578Z"
										transform="translate(-14.367 -13.89)"
										fill="#fff"
									/>
									<path
										id="Path_103838"
										data-name="Path 103838"
										d="M24.686,30.057,14.315,42.963l.657.657a2.31,2.31,0,0,0,3.433-.186l8.762-10.9Zm4.923-5.011,2.482,2.482,11.343-9.122a2.31,2.31,0,0,0,.186-3.433l-.657-.657Z"
										transform="translate(-6.972 -6.973)"
										fill="#fff"
										opacity="0.5"
									/>
									<path
										id="Path_103839"
										data-name="Path 103839"
										d="M35.184,35.658l-.689.579.11.11Z"
										transform="translate(-15.244 -15.716)"
										fill="#fff"
									/>
									<path
										id="Path_103840"
										data-name="Path 103840"
										d="M36.347,34.605l-.11-.11-.578.688Z"
										transform="translate(-15.719 -15.242)"
										fill="#fff"
									/>
									<path
										id="Path_103841"
										data-name="Path 103841"
										d="M24.027,27.366l3.339-3.339a1.185,1.185,0,0,1,1.676,0l6.5,6.5a1.185,1.185,0,0,1,0,1.676L32.2,35.542a1.185,1.185,0,0,1-1.676,0l-6.5-6.5A1.185,1.185,0,0,1,24.027,27.366Z"
										transform="translate(-10.827 -10.825)"
										fill="#f16176"
									/>
									<path
										id="Path_103842"
										data-name="Path 103842"
										d="M29.287,24.266l2.574,2.575a1.434,1.434,0,0,0-2.027,0L26.84,29.834a1.434,1.434,0,0,0,0,2.027l-2.574-2.575a1.434,1.434,0,0,1,0-2.027l2.994-2.994A1.434,1.434,0,0,1,29.287,24.266Z"
										transform="translate(-10.899 -10.897)"
										fill="#e04b64"
									/>
									<path
										id="Path_103843"
										data-name="Path 103843"
										d="M35.632,30.611l2.574,2.575a1.427,1.427,0,0,1-.006,2.021L35.206,38.2a1.427,1.427,0,0,1-2.021.006l-2.574-2.575a1.434,1.434,0,0,0,2.027,0l2.994-2.994A1.434,1.434,0,0,0,35.632,30.611Z"
										transform="translate(-13.655 -13.653)"
										fill="#fff"
										opacity="0.3"
									/>
									<path
										id="Path_103844"
										data-name="Path 103844"
										d="M39.064,9.53,32.381,2.848a2.9,2.9,0,0,0-4.319.237l-9.011,11.2a1.742,1.742,0,0,0-.93.492l-3.347,3.341a1.78,1.78,0,0,0-.492.936l-11.2,9.01a2.907,2.907,0,0,0-.231,4.318l6.683,6.682a2.911,2.911,0,0,0,2.056.853c.047,0,.1-.006.154-.006a2.926,2.926,0,0,0,2.109-1.078l6.683-8.311,1.6,4.585a.6.6,0,0,0,.521.4h.036a.6.6,0,0,0,.533-.332l1.487-2.991L28.9,33.2a.593.593,0,0,0,.616-.225.6.6,0,0,0,.036-.658l-4.088-6.842.492-.5,6.3,5.153a.579.579,0,0,0,.64.071.574.574,0,0,0,.326-.551l-.184-4.312,3.229-.871a.595.595,0,0,0,.113-1.1l-5.741-2.932,8.187-6.587a2.906,2.906,0,0,0,.231-4.318ZM12.931,38.081a1.687,1.687,0,0,1-1.25.64,1.663,1.663,0,0,1-1.309-.5L3.689,31.536a1.713,1.713,0,0,1,.136-2.553l10.723-8.631a2.013,2.013,0,0,0,.225.278l6.511,6.51a1.964,1.964,0,0,0,.272.225Zm14.876-6.368-3.27-.806a.6.6,0,0,0-.675.314l-1.072,2.156-1.374-3.945,1.451-1.807a1.77,1.77,0,0,0,.936-.486l.8-.806Zm-1.5-8.755L22.961,26.3a.6.6,0,0,1-.841,0l-6.511-6.51a.59.59,0,0,1,0-.835l3.347-3.347a.6.6,0,0,1,.421-.172.607.607,0,0,1,.421.172l6.511,6.51A.6.6,0,0,1,26.309,22.958Zm8.17.764-2.2.6a.593.593,0,0,0-.438.6l.154,3.459L26.8,24.137l.344-.344a1.763,1.763,0,0,0,.492-.93l2-1.611ZM38.092,12.93,27.369,21.554a1.933,1.933,0,0,0-.225-.278l-6.511-6.5a1.619,1.619,0,0,0-.272-.225L28.986,3.825a1.721,1.721,0,0,1,2.559-.136l6.683,6.682a1.72,1.72,0,0,1-.136,2.559Z"
										transform="translate(-2.002 -1.995)"
										fill="#1a1a1a"
									/>
									<path
										id="Path_103845"
										data-name="Path 103845"
										d="M40.257,15.9a.592.592,0,0,1-.462-.961L43.22,10.65a.591.591,0,0,1,.924.738L40.72,15.673A.59.59,0,0,1,40.257,15.9Zm-1.787,2.078a.591.591,0,0,1-.447-.979l.234-.27a.592.592,0,0,1,.894.775l-.234.27A.592.592,0,0,1,38.471,17.974Z"
										transform="translate(-16.617 -5.439)"
										fill="#1a1a1a"
									/>
								</g>
							</svg>
							Choose Career Cluster:
						</h6>
					</div>
					<img
						className="w-56 text-[#000000] pl-14 pt-2"
						src={medias.images.Profile.line1}
						alt="lin1"
					/>
					<div className="grid grid-cols-10 gap-10 pt-5 pb-5 pl-10 pr-10">
						{cluster?.map((detail, index) => (
							<div
								key={index}
								className="flex item-center justify-center text-center gap-24"
							>
								<div className="">
									<div
										key={index}
										className={`bg-[#FFFFFF] rounded-lg ml-[5px] flex item-center w-16 h-16 text-center pt-[15px] pb-[15px] pl-[10px] pr-[10px] justify-center aspect-square shadow-custom1 ${
											clickedIndex === index
												? "bg-gradient-to-br from-[#FFB83F] to-[#FFEC8D]"
												: ""
										}`}
										onClick={() => setClickedIndex(index)}
									>
										<img
											src={detail.icon}
											alt={detail.name}
											className="h-9 w-9 scale-105"
										/>
									</div>
									<div className="text-sm  mt-4">
										<p className="text-[#000000] text-center font-medium w-full font-plusJakartaSans text-xs">
											{detail.name}
										</p>
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default CareerCluster;
