import classNames from 'classnames';
import React, { useState } from 'react';

const Flippingcard = ({ front, back,backClass, containerClasses }: {
    front   : string | JSX.Element | JSX.Element[],
    back    : string | JSX.Element | JSX.Element[],
    containerClasses?: string;
    backClass?: string;
}) => {
  const [isFlipped, setIsFlipped] = useState(false);


  return (
    <div className={`${containerClasses} flippercard mx-auto ${isFlipped ? 'flipped' : ''}`} onMouseOver={()=>setIsFlipped(true)} onMouseLeave={()=>setIsFlipped(false)}>
      <div className="front">
        {front}
      </div>
      <div className={classNames(backClass,"back")}>
        {back}
      </div>
    </div>
  );
};

export default Flippingcard;