import React, { useState } from "react";
import SuggestCurricularActivites from "./SuggestCurricularActivities";
import Accordion from "./Accordion";
import FutureGoals from "./FitureGoals";
import FutureGoalCards from "./FutureGoalCards";

function ExtraCurricularActivities({ activeTab }) {
	console.log(activeTab, "activeTab");
	const [selectedCardIndex, setSelectedCardIndex] = useState("Performing Arts");
	const [datas, setData] = useState([]);
	return (
		<div className="w-[100%]">
			<SuggestCurricularActivites
				setSelectedCardLabel={setSelectedCardIndex}
				selectedCardLabel={selectedCardIndex}
				datas={datas}
			/>
			<div className="flex mt-8">
				<div className="w-[70%]">
					<Accordion
						datas={datas}
						setData={setData}
						activeTab={activeTab}
						selectedCardLabel={selectedCardIndex}
					/>
				</div>
				<div className="w-[30%] mt-8">
					<FutureGoalCards />
					<FutureGoals activeTab={activeTab} />
				</div>
			</div>
		</div>
	);
}

export default ExtraCurricularActivities;
