import { medias } from "../../../../config/Resources";
import { WiTime5 } from "react-icons/wi";
import { useState, forwardRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

function NoticeBoard({CollegeDetails, noticeBoard, setStartDate, startDate }) {
	const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

	const handleDateChange = (date) => {
		const formattedDate = moment(date).format("YYYY-MM-DD");
		setStartDate(formattedDate);
	};

	const handleDatePickerToggle = () => {
		setIsDatePickerOpen(!isDatePickerOpen);
	};

	const handleDatePickerClose = () => {
		setIsDatePickerOpen(false);
	};

	// Handle month change to set the date to the 1st of the month
	const handleMonthChange = (date) => {
		const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
		setStartDate(moment(firstDayOfMonth).format("YYYY-MM-DD"));
	};

	// Custom input component for DatePicker
	const CustomInput = forwardRef(({ value, onClick }, ref) => (
		<img
			className="w-4 h-4 cursor-pointer"
			src={medias.images.College.Calender3}
			alt="calender3"
			onClick={onClick}
			ref={ref}
		/>
	));

	return (
		<div className="mt-4 w-full">
			<div className="ml-4 mr-4">
				<h6 className="font-plusJakartaSans text-xs font-semibold">
					Choose Date
				</h6>
				<div className="flex items-center text-center gap-1 mt-1">
					<DatePicker
						selected={startDate}
						onChange={handleDateChange}
						onMonthChange={handleMonthChange}
						customInput={<CustomInput />}
						onClickOutside={handleDatePickerClose}
						open={isDatePickerOpen}
						onInputClick={handleDatePickerToggle}
					/>
					<p className="font-plusJakartaSans mt-0.5 font-semibold text-sm">
						{startDate
							? moment(startDate).format("DD MMM YYYY")
							: "12 Feb 2022"}
					</p>
					<img
						className="w-8 h-8"
						src={medias.images.College.Icon}
						alt="Icon"
					/>
				</div>
			</div>
			<img
				className="mt-1.5 ml-4 mr-4"
				src={medias.images.College.Rectangle2}
				alt="Rectangle2"
			/>
			<div
				className="mt-4 w-full h-[770px] pt-[11%] pl-[7%] pr-[7%] overflow-y-auto bg-no-repeat bg-center"
				style={{
					background: `url(${medias.images.College.Frame})`,
					backgroundSize: "100% 100%",
				}}
			>
				<div className="flex item-center justify-center">
					<p className="inline-block rounded-lg bg-gradient-to-r from-[#FFB83F] to-[#FFEC8D] text-[15px] text-black font-plusJakartaSans font-semibold px-4 py-1">
						Notice Board
					</p>
				</div>
				<div className="flex item-center text-center mt-[6px] justify-center gap-[4px]">
					<div className="shadow-custom1 bg-white p-1 rounded-lg">
					<img
						className="w-8 h-8 rounded-full"
						src={CollegeDetails?.image}
						alt="logo20"
					/>
					</div>
					<p className="font-plusJakartaSans font-medium mt-[10px] text-[13px]">
						{CollegeDetails?.clg_name}
					</p>
				</div>
				<div className="h-[550px] overflow-y-auto scrollbar-container">
					{noticeBoard && noticeBoard.length > 0 ? (
						noticeBoard.map((data, index) => (
							<div key={index} className="border border-[#DEDEDE] rounded-lg p-4 mt-[10px]">
								<p className="font-plusJakartaSans font-bold text-[13px]">
									{data?.notice_subject}
								</p>
								<p className="font-plusJakartaSans flex text-center items-center font-medium text-[10.5px] text-opacity-70">
									<WiTime5 className="text-opacity-70" /> {data?.notice_date}, {data?.notice_time} IST
								</p>
								<img
									className="mt-[8px]"
									src={medias.images.College.Rectangle2}
									alt="Rectangle2"
								/>
								<p dangerouslySetInnerHTML={{
									__html: data?.notice,
								}} className="font-plusJakartaSans pt-[8px] font-medium text-[10.5px] line-clamp-4">
									
								</p>
								<p className="font-plusJakartaSans mt-[7px] gap-[5px] flex item-center text-center font-medium text-[10.5px]">
									<img
										className="w-4 h-4"
										src={medias.images.College.HumanEye}
										alt="humanEye"
									/>
									200 Views
								</p>
							</div>
						))
					) : (
						<div className="flex items-center justify-center h-full">
							<p className="font-plusJakartaSans text-sm font-medium text-gray-600">
								No data is available
							</p>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}

export default NoticeBoard;
