function BlogsDetailsChips1({ blogsDetails }) {
	const tagsArray = blogsDetails?.tags?.split(",") || [];
	console.log(tagsArray, "TagsArray");

	return (
		<div className="ml-7">
			<div className="flex flex-wrap gap-[10px]">
				{tagsArray.map((tag, index) => (
					<p
						key={index}
						className="font-plusJakartaSans rounded-full bg-[#F37400] bg-opacity-10 pt-[7px] pb-[7px] pl-4 pr-4 inline-flex text-[13.5px] text-center items-center gap-1 font-semibold cursor-pointer"
					>
						{tag.trim()}
					</p>
				))}
			</div>
		</div>
	);
}

export default BlogsDetailsChips1;
