import { medias } from "../../../../config/Resources";

function CourseAvailable({ ExamOverview }) {
	return (
		<div>
			<div className="ml-4 mr-4">
				<h6 className="mt-5 font-plusJakartaSans flex items-center text-center gap-1 font-bold text-[#000000] text-[15px]">
					<img
						className="w-5 h-5"
						src={medias.images.Exam.Cap}
						alt="Highlights"
					/>
					<span className="font-plusJakartaSans font-bold mt-[1px]">
						Course Available
					</span>
				</h6>
				<img
					className="w-40 text-[#000000] pt-1"
					src={medias.images.Profile.line1}
					alt="line1"
				/>
			</div>
			<div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-5 ml-4 mr-4">
				{ExamOverview?.courses?.map((course) => (
					<div
						key={course.id}
						className="bg-white flex items-center text-center gap-[5px] custom-shadow rounded-md pt-2 pb-2 pl-3"
					>
						<img
							className="w-4 h-5 object-cover"
							src={medias.images.Exam.Engineering}
							alt="Engineering"
						/>
						<h2 className="font-medium font-plusJakartaSans mt-[2px] text-[11px]">
							{course?.course}
						</h2>
					</div>
				))}
			</div>
		</div>
	);
}

export default CourseAvailable;
