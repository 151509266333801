function GalleryPhotos({ galleryData, galleryChip }) {
	return (
		<div className="ml-4 mr-4">
			<div className="flex flex-wrap gap-[8px]">
				{galleryData?.map((data, index) => {
					return (
						<div key={index} className=" p-1">
							<img
								className="w-44 h-20 object-cover rounded-sm"
								src={
									galleryChip === "campus_life"
										? data?.image
										: data?.placement_image
								}
								alt="CollegeData"
							/>
						</div>
					);
				})}
			</div>
		</div>
	);
}

export default GalleryPhotos;
