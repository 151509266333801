import axios from "axios";
import { API_URLS } from "../../config/API_URLS";

const axiosAdminInstance = axios.create({
  baseURL: API_URLS.adminbaseURL,
});

axiosAdminInstance.interceptors.request.use(
  (config) => {
    // const token = localStorage.getItem("token-student");
    return config;
  },
  (err) => Promise.reject(err)
);

axiosAdminInstance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    return Promise.reject(error?.response?.data);
  }
);

export default axiosAdminInstance;
