import { medias } from "../../../config/Resources";
import { MdEmail } from "react-icons/md";
import { MdPhone } from "react-icons/md";

function FacultyNumber() {
	const FacultyData = [{}, {}, {}];
	return (
		<div className="max-w-sm p-4 w-full bg-white rounded-md shadow dark:bg-gray-800 dark:border-gray-700">
			<div className="flex item-center text-center justify-between">
				<p className="font-plusJakartaSans font-extrabold text-[13px]">
					Faculty
				</p>
				<p className="font-plusJakartaSans font-bold text-[#2771E7] text-[13px]">
					View All
				</p>
			</div>
			{FacultyData.map(() => {
				return (
					<>
						<div className="flex item-center text-center gap-1 mt-4">
							<p className="font-plusJakartaSans text-[14px] font-semibold">
								Shivam Kaushik
							</p>
							<img
								className="w-5 h-5"
								src={medias.images.College.Linkdein1}
								alt="linkdein"
							/>
						</div>
						<div className="mt-2">
							<p className="font-plusJakartaSans font-normal text-[10px] mt-[2px] flex item-center text-center gap-1">
								<MdEmail className="mt-[2px]" />
								shivamkaushik@gmail.com
							</p>
							<p className="font-plusJakartaSans font-normal text-[10px] mt-[2px] flex item-center text-center gap-1">
								<MdPhone className="mt-[2px]" />
								+91-8800245069
							</p>
							<p className="font-plusJakartaSans font-normal text-[10px] mt-[2px] flex item-center text-center gap-1">
								<img
									src={medias.images.College.BriefCase}
									alt="briefCase"
									className="mt-[2px] h-2 w-2"
								/>
								Prof. Department of Chemical Engineering
							</p>
						</div>
					</>
				);
			})}
		</div>
	);
}

export default FacultyNumber;
