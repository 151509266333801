import React from 'react'


interface Props {
    className?: string;
    src?: string;
    alt?: string;
    onClick?: (event: React.MouseEvent<HTMLImageElement, MouseEvent>) => void; 
  }

const Image = ({className, src, alt, onClick} : Props) => {
  return (
    <img src={src} crossOrigin='anonymous' onClick={onClick} alt={alt} className={className} />
  )
}

export default Image