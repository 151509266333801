import React, { useState } from "react";
import { medias } from "../../config/Resources";

const RequiredQualities = () => {
	const [selectedCard, setSelectedCard] = useState(null);

	const AcdameyDetails = [
		{
			image: medias.images.Profile.WorkHard,
			label: "Work Hard",
		},
		{
			image: medias.images.Profile.Sympathy,
			label: "Sympathy",
		},
		{ image: medias.images.Profile.LoveAffection, label: "Love & Affection" },
		{ image: medias.images.Profile.Emotion, label: "Soft Hearted" },
	];

	const handleCardClick = (index) => {
		setSelectedCard(index);
	};

	return (
		<>
			<div className="tracking-wide text-sm font-plusJakartaSans font-bold pt-8 pl-4 pr-4">
				Required Qualities (Improve / Train Yourself)
			</div>
			<div className="w-full flex">
				{AcdameyDetails?.map((detail, index) => (
					<div
						key={index}
						className={`flex justify-center items-center bg-white rounded-xl shadow-md overflow-hidden mt-4 ml-5 border border-gray-300 w-[15%] ${
							selectedCard === index ? "bg-blue-600 text-white" : ""
						}`}
						onClick={() => handleCardClick(index)}
					>
						<div className="p-4">
							<div className="flex items-center justify-center mb-2">
								<img
									src={detail.image}
									alt={detail.label}
									className="h-[55%] w-[55%] object-cover"
								/>
							</div>
							<div className="text-xs text-center">
								<p className="font-bold font-plusJakartaSans">{detail.label}</p>
							</div>
						</div>
					</div>
				))}
			</div>
		</>
	);
};

export default RequiredQualities;
