import CalendarContent from "../../Pages/Profile/Mentorship/calenderContent";
import Accordion from "./Accordion";
import BigMonthly from "./BioMonthly";
import FutureGoals from "./FitureGoals";
import FutureGoalCards from "./FutureGoalCards";
import ProjectSkills from "./ProjectSkills";
import QualitiesOfYours from "./QualitiesOfYours";
import RequiredQualities from "./RequiredQualities";

function LifeSkills() {
    return (
<div className="w-[100%]">
    <QualitiesOfYours />
    <div className="flex">
    <div className="w-[65%]">
    <RequiredQualities />
    <BigMonthly />
    <ProjectSkills />
    <Accordion />
    </div>
    <div className="w-[35%] mt-8">
        <FutureGoalCards />
        <FutureGoals />
        <CalendarContent />
    </div>
    </div>
</div>
    )
}

export default LifeSkills;