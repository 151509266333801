import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Slider from "@mui/material/Slider";
import MuiInput from "@mui/material/Input";

const Input = styled(MuiInput)`
	width: 42px;
`;

export default function SliderCompAdvnc({
	isHeader = false,
	title,
	placeholder,
	img,
}) {
	const [value, setValue] = React.useState(0);

	const handleSliderChange = (event, newValue) => {
		console.log(newValue);
		setValue(newValue);
	};

	const handleInputChange = (event) => {
		setValue(event.target.value === "" ? 0 : Number(event.target.value));
	};

	const handleBlur = () => {
		if (value < 0) {
			setValue(0);
		} else if (value > 10) {
			setValue(10);
		}
	};

	return (
		<>
			{!!isHeader && (
				<div className="mb-3 flex items-center gap-2">
					{!!img ? (
						img
					) : (
						<svg
							id="company_1_"
							data-name="company (1)"
							xmlns="http://www.w3.org/2000/svg"
							width="22.324"
							height="22.324"
							viewBox="0 0 22.324 22.324"
						>
							<path
								id="Path_111843"
								data-name="Path 111843"
								d="M3,14H16.395V31.487H3Z"
								transform="translate(-2.628 -9.535)"
								fill="#f0b77d"
							/>
							<path
								id="Path_111844"
								data-name="Path 111844"
								d="M19.465,54.093H15V51.044A1.044,1.044,0,0,1,16.044,50H18.42a1.044,1.044,0,0,1,1.044,1.044Z"
								transform="translate(-10.163 -32.141)"
								fill="#65cecc"
							/>
							<path
								id="Path_111845"
								data-name="Path 111845"
								d="M5,10H16.906v1.488H5Z"
								transform="translate(-3.884 -7.023)"
								fill="#de2f55"
							/>
							<path
								id="Path_111846"
								data-name="Path 111846"
								d="M7.744,3h8.93V5.6H7.744ZM7,8.581H8.488v1.488H7Zm4.465,0h1.488v1.488H11.465Zm4.465,0h1.488v1.488H15.93ZM7,12.674H8.488v1.488H7Zm4.465,0h1.488v1.488H11.465Zm4.465,0h1.488v1.488H15.93ZM7,16.767H8.488v1.488H7Zm4.465,0h1.488v1.488H11.465Zm4.465,0h1.488v1.488H15.93ZM7,20.859H8.488v1.488H7Zm8.93,0h1.488v1.488H15.93Z"
								transform="translate(-5.14 -2.628)"
								fill="#65cecc"
							/>
							<path
								id="Path_111847"
								data-name="Path 111847"
								d="M39,28h8.186V40.278H39Z"
								transform="translate(-25.233 -18.326)"
								fill="#e1c6be"
							/>
							<path
								id="Path_111848"
								data-name="Path 111848"
								d="M48.977,42.79H46V40.4a.963.963,0,0,1,.963-.963h1.051a.963.963,0,0,1,.963.963ZM46.372,32H48.6v2.232H46.372Zm0,3.721H48.6v2.232H46.372Z"
								transform="translate(-29.629 -20.838)"
								fill="#65cecc"
							/>
							<path
								id="Path_111849"
								data-name="Path 111849"
								d="M48.724,12a2.058,2.058,0,0,0,.106,2.977,2.057,2.057,0,0,0,2.977.106Z"
								transform="translate(-30.971 -8.279)"
								fill="#816892"
							/>
							<path
								id="Path_111850"
								data-name="Path 111850"
								d="M7.86,17H6.372A.372.372,0,0,0,6,17.372V18.86a.372.372,0,0,0,.372.372H7.86a.372.372,0,0,0,.372-.372V17.372A.372.372,0,0,0,7.86,17Zm-.372,1.488H6.744v-.744h.744ZM12.325,17H10.837a.372.372,0,0,0-.372.372V18.86a.372.372,0,0,0,.372.372h1.488a.372.372,0,0,0,.372-.372V17.372A.372.372,0,0,0,12.325,17Zm-.372,1.488h-.744v-.744h.744ZM16.79,17H15.3a.372.372,0,0,0-.372.372V18.86a.372.372,0,0,0,.372.372H16.79a.372.372,0,0,0,.372-.372V17.372A.372.372,0,0,0,16.79,17Zm-.372,1.488h-.744v-.744h.744Zm-8.558,2.6H6.372A.372.372,0,0,0,6,21.465v1.488a.372.372,0,0,0,.372.372H7.86a.372.372,0,0,0,.372-.372V21.465A.372.372,0,0,0,7.86,21.093Zm-.372,1.488H6.744v-.744h.744Zm4.837-1.488H10.837a.372.372,0,0,0-.372.372v1.488a.372.372,0,0,0,.372.372h1.488a.372.372,0,0,0,.372-.372V21.465A.372.372,0,0,0,12.325,21.093Zm-.372,1.488h-.744v-.744h.744Zm4.837-1.488H15.3a.372.372,0,0,0-.372.372v1.488a.372.372,0,0,0,.372.372H16.79a.372.372,0,0,0,.372-.372V21.465A.372.372,0,0,0,16.79,21.093Zm-.372,1.488h-.744v-.744h.744Zm-8.558,2.6H6.372A.372.372,0,0,0,6,25.558v1.488a.372.372,0,0,0,.372.372H7.86a.372.372,0,0,0,.372-.372V25.558A.372.372,0,0,0,7.86,25.186Zm-.372,1.488H6.744V25.93h.744Zm4.837-1.488H10.837a.372.372,0,0,0-.372.372v1.488a.372.372,0,0,0,.372.372h1.488a.372.372,0,0,0,.372-.372V25.558A.372.372,0,0,0,12.325,25.186Zm-.372,1.488h-.744V25.93h.744Zm4.837-1.488H15.3a.372.372,0,0,0-.372.372v1.488a.372.372,0,0,0,.372.372H16.79a.372.372,0,0,0,.372-.372V25.558A.372.372,0,0,0,16.79,25.186Zm-.372,1.488h-.744V25.93h.744Zm-8.558,2.6H6.372A.372.372,0,0,0,6,29.65v1.488a.372.372,0,0,0,.372.372H7.86a.372.372,0,0,0,.372-.372V29.65A.372.372,0,0,0,7.86,29.278Zm-.372,1.488H6.744v-.744h.744Zm9.3-1.488H15.3a.372.372,0,0,0-.372.372v1.488a.372.372,0,0,0,.372.372H16.79a.372.372,0,0,0,.372-.372V29.65A.372.372,0,0,0,16.79,29.278Zm-.372,1.488h-.744v-.744h.744Z"
								transform="translate(-4.512 -11.419)"
							/>
							<path
								id="Path_111851"
								data-name="Path 111851"
								d="M23.952,11.3H20.231V9.439a2.521,2.521,0,0,0,1.1.321c.037,0,.078,0,.117,0a2.32,2.32,0,0,0,1.653-.7.372.372,0,0,0,0-.526L21.821,7.262l.906-.906L22.2,5.83l-.906.906L20.017,5.458a.383.383,0,0,0-.526,0,2.409,2.409,0,0,0,0,3.384V11.3H16.139V6.465a.372.372,0,0,0-.372-.372h-.372V4.977a.372.372,0,0,0-.372-.372H13.906V2.372A.372.372,0,0,0,13.534,2H4.6a.372.372,0,0,0-.372.372V4.6H3.116a.372.372,0,0,0-.372.372V6.093H2.372A.372.372,0,0,0,2,6.465V23.952a.372.372,0,0,0,.372.372h21.58a.372.372,0,0,0,.372-.372V11.674A.372.372,0,0,0,23.952,11.3ZM4.977,2.744h8.186V4.6H4.977Zm-1.488,2.6H14.65v.744H3.488ZM8.7,23.58H7.209V20.9a.673.673,0,0,1,.672-.673H8.7Zm2.232,0H9.441V20.231h.816a.673.673,0,0,1,.672.673Zm4.465-11.906V23.58H11.674V20.9a1.418,1.418,0,0,0-1.416-1.417H7.881A1.418,1.418,0,0,0,6.465,20.9V23.58H2.744V6.837h12.65Zm4.387-5.4,2.5,2.5a1.526,1.526,0,0,1-.916.241,1.909,1.909,0,0,1-1.244-.583,1.712,1.712,0,0,1-.34-2.159Zm1.194,17.3H18.743V21.566a.591.591,0,0,1,.59-.59h1.051a.591.591,0,0,1,.59.59Zm2.6,0H21.72V21.566a1.336,1.336,0,0,0-1.335-1.335H19.334A1.336,1.336,0,0,0,18,21.566V23.58h-1.86V12.046H23.58Z"
								transform="translate(-2 -2)"
							/>
							<path
								id="Path_111852"
								data-name="Path 111852"
								d="M46.372,33.977H48.6a.372.372,0,0,0,.372-.372V31.372A.372.372,0,0,0,48.6,31H46.372a.372.372,0,0,0-.372.372V33.6A.372.372,0,0,0,46.372,33.977Zm.372-2.232h1.488v1.488H46.744ZM46.372,37.7H48.6a.372.372,0,0,0,.372-.372V35.093a.372.372,0,0,0-.372-.372H46.372a.372.372,0,0,0-.372.372v2.232A.372.372,0,0,0,46.372,37.7Zm.372-2.232h1.488v1.488H46.744Z"
								transform="translate(-29.629 -20.21)"
							/>
						</svg>
					)}

					<h5 className="miniDesk:text-[14px]  w-[80%] text-[14px] font-plusJakartaSans font-bold ">
						{title}
					</h5>
				</div>
			)}
			<Box className="my-3 max-w-[220px]">
				<Grid container spacing={2} alignItems="center">
					<Grid item xs>
						<Slider
							value={typeof value === "number" ? value : 0}
							onChange={handleSliderChange}
							aria-labelledby="input-slider"
						/>
					</Grid>
				</Grid>
				<Grid item className="flex justify-between ">
					<div></div>

					<div className="!border-[2px] min-w-5 rounded-xl !border-solid px-2 py-1 text-black">
						{value + (!!value && "+")}
					</div>
				</Grid>
			</Box>
		</>
	);
}
