/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import Image from "../../../Components/Image";
import { medias } from "../../../config/Resources";
import DropDown2 from "../../../Components/DropDown/DropDown2";
import {
  HeaderMiddleSectionGoalsDrop,
  offers,
} from "../../../config/HeaderConstants";
import Text from "../../../Components/Text";
import Menu1 from "../../../config/HeaderConstants/Menus";
import MainSearch from "../../../Components/MainSearch";
import { Button, IconButton } from "@mui/material";
import { ArrowDropDown, Person } from "@mui/icons-material";
import GridViewIcon from "@mui/icons-material/GridView";
import { useLocation, useNavigate } from "react-router-dom";
import classNames from "classnames";
import { useUserInfoStore } from "../../../store/userinfo";
import ProfileMenu from "../../../Components/Homelayout/ProfileMenu";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const HeaderMiddleSection1 = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { pathname } = useLocation();
  const { userInfo }: any = useUserInfoStore((state) => state);

  console.log("userInfo", userInfo);

  const GoToHomepage = () => {
    if (
      window.location.origin === "http://localhost:3000" ||
      window.location.origin === "https://techugoapps.com" ||
      window.location.origin == "https://www.techugoapps.com"
    ) {
      navigate("/sygnific_dev");
    } else {
      navigate("/");
    }
  };

  const checkLocation = pathname.includes("mentorship");
  return (
    <div
      className={classNames(
        location.pathname === "/" || location.pathname === "/sygnific_dev"
          ? "bg-transparent"
          : checkLocation
          ? "bg-[#01150D]"
          : "bg-[#0D0D53]",
        "flex desktop:pt-[10px] desktop:pb-[5px] justify-between  pt-3 pb-5 3xl:px-10  px-[85px]"
      )}
    >
      <div className="flex items-center justify-center gap-x-4">
        <div className="">
          <Image
            onClick={() => {
              GoToHomepage();
            }}
            className="lg:max-w-[140px] max-w-[115px] cursor-pointer"
            src={medias.images.header.pagelogo}
            alt={"pageLogo"}
          />
        </div>
        <div className="">
          <DropDown2
            dropIconClass={"opacity-60  "}
            title={
              <>
                <div className="flex gap-3">
                  <Image
                    src={HeaderMiddleSectionGoalsDrop.logo}
                    alt="HeaderMiddleSection"
                    className="desktop:w-[16px]"
                  />
                  <div>
                    <Text className="text-base lg:!text-[13px]  opacity-80">
                      {HeaderMiddleSectionGoalsDrop.subTitle}
                    </Text>
                    <Text className=" text-[14px]  lg:-[16px] font-semibold  ">
                      {/* {HeaderMiddleSectionGoalsDrop.title} */}
                      Select <span> </span> <span> </span>your <span> </span>{" "}
                      <span> </span> Goal
                    </Text>
                  </div>
                </div>
              </>
            }
            menu={<Menu1 />}
          />
        </div>
      </div>
      <div className="md:w-[28%] lg:w-[32%]">
        <MainSearch />
      </div>

      <div className="flex gap-2 ">
        {offers.map(({ offer, title, className }: any, index) => {
          return (
            <>
              {index === 0 && (
                <div className=" ">
                  <IconButton className="!bg-white md:min-w-[50px] lg:!min-w-[50px]   lg:!p-3 md:w-[50px] lg-!w-[50px]   !p-4 !bg-opacity-10">
                    <Image
                      className="desktop:w-[20px]"
                      src={medias.images.header.select}
                    />
                  </IconButton>
                </div>
              )}
              <div className="flex flex-col items-start gap-1 3xl:gap-[2px]">
                <div
                  className={`px-[14px] py-[4.5px] rounded-full ${className}`}
                >
                  <Text className=" text-[11px]  uppercase font-semibold">
                    {offer}
                    {/* {index === 0 && <span>₹1000</span>} */}
                  </Text>
                </div>
                <div className="px-2  ">
                  <Text className="font-medium text-[13.6px]  1500:text-[17px]  text-white">
                    {title}
                  </Text>
                </div>
              </div>
            </>
          );
        })}
      </div>
      <div>
        {!!userInfo?.email ? (
          <div className=" space-x-2 flex text-center items-center">
            <IconButton className="!bg-white md:min-w-[45px] lg:!min-w-[45px]   lg:!p-3 md:w-[45px] lg-!w-[45px]   !p-4 !bg-opacity-10">
              <Image
                className="desktop:w-[20px]"
                src={medias.images.header.bell}
              />
            </IconButton>
            <div className="flex text-center items-center text-white">
              <ProfileMenu
                userInfo={userInfo}
                children={
                  <>
                    <div className="mr-[5px]  !capitalize">
                      <IconButton className="!text-white bg-white md:min-w-[45px] lg:!min-w-[45px]   lg:!p-3 md:w-[45px] lg-!w-[45px]   !p-4 !bg-opacity-10">
                        <Image
                          className="desktop:w-[20px]"
                          src={medias.images.header.profile}
                        />
                      </IconButton>
                    </div>
                    <Text className="!text-white !capitalize">
                      {" "}
                      {userInfo?.firstName}
                    </Text>
                    <KeyboardArrowDownIcon className=" !text-white" />
                  </>
                }
              />
            </div>
          </div>
        ) : (
          <Button
            onClick={() =>
              navigate(
                !!userInfo?.email
                  ? "/profile/career-counselling?location=plans"
                  : "/login-signup/register"
              )
            }
            variant="outlined"
            className="!border-white   gap-1  3xl:!text-[12px] miniDesk:!text-[10px]   !capitalize py-[6px] md:py-[10px] lg:py-[12px] !text-white !rounded-[15px] !text-[16px] !font-bold px-6"
          >
            {!userInfo?.email ? (
              <svg
                id="Group_298663"
                data-name="Group 298663"
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="21"
                viewBox="0 0 23 23"
              >
                <path
                  id="Icon_awesome-user-alt"
                  data-name="Icon awesome-user-alt"
                  d="M11.5,12.938A6.469,6.469,0,1,0,5.031,6.469,6.47,6.47,0,0,0,11.5,12.938Zm5.75,1.438H14.775a7.82,7.82,0,0,1-6.55,0H5.75A5.75,5.75,0,0,0,0,20.125v.719A2.157,2.157,0,0,0,2.156,23H20.844A2.157,2.157,0,0,0,23,20.844v-.719A5.75,5.75,0,0,0,17.25,14.375Z"
                  fill="#fff"
                />
              </svg>
            ) : (
              <GridViewIcon className="!w-[24px]  miniDesk:!w-[14px]" />
            )}
            <Text className="desktop:text-[15px]">
              {!!userInfo?.email ? "Dashboard" : "Login/Signup"}
            </Text>
          </Button>
        )}
      </div>
    </div>
  );
};

export default HeaderMiddleSection1;
