/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { medias } from "../../../config/Resources";

function ClosingRankTable() {
	const TableDetails = [
		{
			Courses: "MSc in Economics",
			Number: "10",
			Number1: "188",
			Number2: "136",
		},
		{
			Courses: "MSc in Chemistry",
			Number: "10",
			Number1: "10",
			Number2: "10",
		},
		{
			Courses: "MSc in Mathematics",
			Number: "136",
			Number1: "10",
			Number2: "10",
		},
		{
			Courses: "MSc in Physics",
			Number: "188",
			Number1: "188",
			Number2: "188",
		},
	];

	return (
		<div>
			<div className="flex items-center gap-2 mt-5">
				<img
					className="w-10 h-10"
					src={medias.images.College.Eligibility}
					alt="courses"
				/>
				<div>
					<p className="font-semibold text-[#000000] opacity-50 font-plusJakartaSans text-[13px]">
						IIT Delhi
					</p>
					<p className="font-bold text-[#000000] font-plusJakartaSans text-[17px]">
						B.tech Cutoff 2023
					</p>
				</div>
			</div>
			<p className=" mt-2 font-bold text-[#000000] font-plusJakartaSans text-[17px]">
				IIT JAM Round 3 Closing Rank (General-All India)
			</p>
			<div className=" mt-3">
				<div className="overflow-x-auto">
					<table className="min-w-full border-collapse">
						<thead>
							<tr>
								<th className="px-4 py-2 bg-[#FFEDC0] text-center font-plusJakartaSans font-bold text-[13.3px] rounded-l-sm border-r-2 border-white">
									Courses
								</th>
								<th className="px-4 py-2 bg-[#FFEDC0] text-center font-plusJakartaSans font-bold text-[13.3px] border-r-2 border-white">
									2021
								</th>
								<th className="px-4 py-2 bg-[#FFEDC0] text-center font-plusJakartaSans font-bold text-[13.3px] border-r-2 border-white">
									2022
								</th>
								<th className="px-4 py-2 bg-[#FFEDC0] text-center font-plusJakartaSans font-bold text-[13.3px]">
									2023
								</th>
							</tr>
						</thead>
						<tbody className="bg-[#ffffff]">
							{TableDetails.map((detail, index) => (
								<tr
									key={index}
									className="border-b border-dotted last:border-0"
								>
									<td className="px-4 py-2 text-center font-plusJakartaSans font-medium text-[11.5px]">
										{detail.Courses}
									</td>
									<td className="px-4 py-2 text-center font-plusJakartaSans font-medium text-[11.5px]">
										{detail.Number}
									</td>
									<td className="px-4 py-2 text-center font-plusJakartaSans font-medium text-[11.5px]">
										{detail.Number1}
									</td>
									<td className="px-4 py-2 text-center font-plusJakartaSans font-medium text-[11.5px]">
										{detail.Number2}
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
}

export default ClosingRankTable;
