import { useState } from "react";

function AgenciesChips({ councelling, setCouncellingChips }) {
	const [selectedId, setSelectedId] = useState();

	const handleChipClick = (agency_name) => {
		const newSelectedId = selectedId === agency_name ? null : agency_name;
		setSelectedId(newSelectedId);
		setCouncellingChips(newSelectedId);
	};
	return (
		<>
			<h6 className="font-plusJakartaSans mt-3 ml-4 text-[15px] font-bold text-[#000000]">
				Agencies
			</h6>
			<div className="flex flex-wrap gap-[10px] mt-3 mb-2 ml-4 mr-4">
				{councelling?.counselling_agencies?.map((chip) => (
					<p
						key={chip.agency_name}
						onClick={() => handleChipClick(chip?.agency_name)}
						className={`font-plusJakartaSans rounded-full border pt-1 pb-1 pl-4 pr-4 inline-flex text-[12px] text-center items-center gap-1 font-medium cursor-pointer ${
							selectedId === chip.agency_name
								? "bg-[#2771E7] border-[#2771E7] text-white"
								: "border-[#D1D1D1] text-[#000000]"
						}`}
					>
						{chip?.agency_name}
					</p>
				))}
			</div>
		</>
	);
}

export default AgenciesChips;
