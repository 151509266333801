import { medias } from "../../config/Resources";

const ProjectSkills = () => {
	const AcdameyDetails = [
		{
			image: medias.images.Profile.Study1,
			label: "Study Module 1",
		},
		{
			image: medias.images.Profile.Study1,
			label: "Study Module 1",
		},
		{
			image: medias.images.Profile.Study1,
			label: "Study Module 1",
		},
	];

	return (
		<>
			<div className="tracking-wide text-sm font-plusJakartaSans font-bold pt-8 pl-4 pr-4">
				Project Skill Development
			</div>
			<div className="w-full flex">
				{AcdameyDetails?.map((detail, index) => (
					<div
						key={index}
						className='flex justify-center items-center bg-white rounded-xl shadow-md overflow-hidden mt-4 ml-5 border border-gray-300 w-[15%]'
					>
						<div className="p-4">
							<div className="flex items-center justify-center mb-2">
								<img
									src={detail.image}
									alt={detail.label}
									className="h-[55%] w-[55%] object-cover"
								/>
							</div>
							<div className="text-xs text-center">
								<p className="font-bold font-plusJakartaSans">{detail.label}</p>
							</div>
						</div>
					</div>
				))}
			</div>
		</>
	);
};

export default ProjectSkills;
