/* eslint-disable eqeqeq */
/* eslint-disable no-dupe-keys */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { createContext, useState, useRef, useEffect } from "react";
import { io } from "socket.io-client";
import Peer from "simple-peer";
import { db } from "./firebase.config";
// import { collection, doc, onSnapshot } from "firebase/firestore";

const SocketContext = createContext();

// const socket = io("http://localhost:5000");
// const socket = io('https://videocall-backend-red.vercel.app');
const socket = io("https://webrtc-server-y19h.onrender.com");

const ContextProvider = ({ children }) => {
  const [callStatus, setCallStatus] = useState(false);

  const [statusMessage, setStatusMessage] = useState("Incoming Video Call");

  const [streamData, setStreamData] = useState({});

  const [callAccepted, setCallAccepted] = useState(false);
  const [callEnded, setCallEnded] = useState(false);
  const [stream, setStream] = useState();
  const [screenStream, setScreenStream] = useState();

  const [name, setName] = useState("");
  const [call, setCall] = useState({});
  const [me, setMe] = useState("");
  const [activeVideo, setActiveVideo] = useState(true);
  const [activeAudio, setActiveAudio] = useState(true);
  const connecting = useRef(false);
  const pc = useRef(null);

  const [sockectData, setSocket] = useState({});

  const [localStream, setLocalStream] = useState(null);
  const [remoteStream, setRemoteStream] = useState(null);
  const [peerConnection, setPeerConnection] = useState(null);
  // "homepage": "https://www.techugoapps.com/sygnific_dev/",
  const [sharingStatus, setSharingStatus] = useState(false);

  const currentPath = window.location.href?.includes("call");

  console.log(currentPath);

  const myVideo = useRef();
  const userVideo = useRef();
  const connectionRef = useRef();
  const screenRef = useRef(null);
  const remoteScreenRef = useRef();

  // useEffect(() => {
  //   const getData = async () => {
  //     const val = doc(db, "Users", 0);
  //     const notificationsCollection = collection(val, "Notifications");
  //     onSnapshot(notificationsCollection, (snapshot) => {

  //     });
  //   };
  //   getData();
  // }, []);

  const create = async () => {
    // alert("Calling");
    // connecting.current = true;
    // const userId = 20;
    // const calleeId = 1;
    // const cRef = db.collection("meet").doc("chatId");
    // collectIceCandidates(cRef, "caller", "callee");
    // if (pc.current) {
    //   const offer = await pc.current.createOffer();
    //   await pc.current.setLocalDescription(offer);
    //   const cWithOffer = {
    //     offer: {
    //       type: offer.type,
    //       sdp: offer.sdp,
    //     },
    //     callerId: userId,
    //     calleeId: calleeId,
    //   };
    //   cRef.set(cWithOffer);
    // }
  };

  // const setupWebrtc = async () => {
  //   pc.current = new RTCPeerConnection(configuration)
  //   // Get the audio and video stream for the call
  //   const stream: any = await getMediaStream()
  //   if (stream) {
  //       setlocalStream(stream)
  //       stream.getTracks().forEach(track => {
  //           pc.current.addTrack(track, stream);
  //       });

  //   }
  //   // Get the remote stream once it is available
  //   pc.current.ontrack = (event) => {
  //       setRemoteStream(event.streams[0]); // Assuming the first stream is what you want
  //   };
  // }

  const collectIceCandidates = async (cRef, localName, remoteName) => {
    // const candidateCollection = cRef.collection(localName);
    // if (pc.current) {
    //   pc.current.onicecandidate = (event) => {
    //     if (event.candidate) {
    //       candidateCollection.add(event.candidate);
    //     }
    //   };
    // }
    // cRef.collection(remoteName).onSnapshot((snapshot) => {
    //   snapshot.docChanges().forEach((change) => {
    //     if (change.type == "added") {
    //       const candidate = new RTCIceCandidate(change.doc.data());
    //       pc.current?.addIceCandidate(candidate);
    //     }
    //   });
    // });
  };

  useEffect(() => {
    // Connect to the signaling server
    const socket = io("https://webrtc-server-y19h.onrender.com");
    // const socket = io("http://localhost:5000");

    setSocket(socket);

    // Listen for incoming call signaling messages
    socket.on("answerCall", handleCall);

    // Clean up socket connection on unmount
    return () => {
      if (socket) {
        socket.disconnect();
      }
    };
  }, []);

  const handleCall = async (data) => {
    // if (data.offer) {
    //   try {
    //     const pc = new RTCPeerConnection();
    //     pc.addStream(localStream);
    //     pc.onaddstream = (event) => {
    //       setRemoteStream(event.stream);
    //     };
    //     await pc.setRemoteDescription(new RTCSessionDescription(data.offer));
    //     const answer = await pc.createAnswer();
    //     await pc.setLocalDescription(answer);
    //     socket.emit("answerCall", { targetUserId: data.userId, answer });
    //   } catch (error) {
    //     console.error("Error handling call:", error);
    //   }
    // } else if (data.answer) {
    //   await peerConnection.setRemoteDescription(
    //     new RTCSessionDescription(data.answer)
    //   );
    // } else if (data.candidate) {
    //   await peerConnection.addIceCandidate(new RTCIceCandidate(data.candidate));
    // }
  };

  console.log("sockectData", sockectData);

  useEffect(() => {
    if (currentPath) {
      navigator.mediaDevices
        .getUserMedia({ video: activeVideo, audio: activeAudio })
        .then((currentStream) => {
          setStream(currentStream);
          console.log("my Stream", currentStream);
          myVideo.current.srcObject = currentStream;
          if (callAccepted) {
            connectionRef.current.replaceTrack(
              currentStream.getVideoTracks()[0],
              currentStream
            );
          }
        });

      let connectSocket = socket.on("me", (id) => {
        setMe(id);
        socket.emit("customId", { id, id });
      });

      socket.on("customId", (val, id) => {
        console.log("customId---", val, id);
      });

      console.log("me", me, connectSocket);

      socket.on("callUser", ({ from, name: callerName, signal }) => {
        setCall({ isReceivingCall: true, from, name: callerName, signal });
      });

      socket.on("startScreenShare", ({ stream }) => {
        remoteScreenRef.current.srcObject = stream;
      });
    }
  }, [activeVideo, currentPath]);

  useEffect(() => {
    const peer = new Peer({ initiator: false, trickle: false, stream });

    try {
      peer.on("startScreenShare", (userScreen) => {
        console.log("User Stream Data", userScreen);
        remoteScreenRef.current.srcObject = userScreen;
        console.log("remoteScreenRef ", userScreen);
      });
    } catch (error) {
      console.log(
        "err on getting user stream                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       ",
        error
      );
    }
  }, [remoteScreenRef]);

  // useEffect(() => {
  //   if (currentPath) {
  //     navigator.mediaDevices
  //       .getUserMedia({ video: activeVideo, audio: activeAudio })
  //       .then((currentStream) => {
  //         setStream(currentStream);
  //         console.log("currentStream", currentStream);
  //         myVideo.current.srcObject = currentStream;

  //         let connectSocket = socket.on("me", (id) => setMe(id));

  //         console.log("me", me, connectSocket);

  //         socket.on("callUser", ({ from, name: callerName, signal }) => {
  //           setCall({ isReceivingCall: true, from, name: callerName, signal });

  //         peer.signal(call.signal);
  //       })
  //       .catch((error) => {
  //         console.error("Error accessing media devices:", error);
  //       });
  //   }
  // }, [activeVideo]);

  const pausePlayVideo = () => {
    setActiveVideo((prev) => !prev);
    setStream((prevStream) => {
      if (prevStream) {
        const tracks = prevStream.getVideoTracks();
        if (tracks.length > 0) {
          tracks[0].enabled = !activeVideo;
        }
      }
      return prevStream;
    });
  };

  console.log("activeVideo", activeVideo);

  const answerCall = () => {
    setCallAccepted(true);
    const peer = new Peer({ initiator: false, trickle: false, stream });
    // console.log("answer Call function chala", stream);

    peer.on("signal", (data) => {
      console.log("answer Call signal data", data);
      socket.emit("answerCall", { signal: data, to: call.from });
    });

    try {
      peer.on("stream", (userStream) => {
        console.log("User Stream Data", userStream);
        userVideo.current.srcObject = userStream;

        console.log("userVideo ref", userVideo);
      });
    } catch (error) {
      console.log(
        "err on getting user stream                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       ",
        error
      );
    }

    peer.signal(call.signal);
    connectionRef.current = peer;
  };

  const callUser = (id) => {
    const peer = new Peer({ initiator: true, trickle: false, stream });
    console.log("clinet side 2", id, peer);
    peer.on("signal", (data) => {
      console.log("data", data);
      socket.emit("callUser", {
        userToCall: id,
        signalData: data,
        from: me,
        name,
      });
    });

    peer.on("stream", (currentStream) => {
      userVideo.current.srcObject = currentStream;
    });

    socket.on("callAccepted", (signal) => {
      setCallAccepted(true);

      peer.signal(signal);
    });

    connectionRef.current = peer;
  };

  const beginShare = () => {
    console.log("request permissions ");
    const peer = new Peer({ initiator: true, trickle: false, stream });
    navigator.mediaDevices.getDisplayMedia().then((feed) => {
      setScreenStream(feed);
      setSharingStatus(true);
      screenRef.current.srcObject = feed;
      if (peer) {
        console.log("sending video feed");
        peer.addStream(feed);
        socket.emit("startScreenShare", {
          userToCall: peer.id,
          signalData: feed,
          from: me,
          name,
        });
      }
    });
  };

  const leaveCall = () => {
    setCallEnded(true);

    connectionRef?.current?.destroy();

    window?.location?.reload();
  };

  console.log("mYscreeNStream", screenStream);
  return (
    <SocketContext.Provider
      value={{
        call,
        callAccepted,
        myVideo,
        userVideo,
        stream,
        name,
        setName,
        callEnded,
        me,
        create,
        callUser,
        leaveCall,
        answerCall,
        setActiveAudio,
        pausePlayVideo,
        beginShare,
        screenRef,
        sharingStatus,
        remoteScreenRef,
        callStatus,
        setCallStatus,
        setStreamData,
        streamData,
        setStatusMessage,
        statusMessage,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export { ContextProvider, SocketContext };
