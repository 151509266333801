import MentorCard from "./MentorCard";

const Right = ({ main, arrow, title, desc }) => {
  return (
    <div className="flex items-center  gap-x-4 px-5">
      <div className="bg-white bg-opacity-15  rounded-full p-[14px] text-white ">
        {main}
      </div>
      <div>
        <div className="flex items-center text-white gap-x-2">
          <h4 className="text-[28px] font-plusJakartaSans">{title}</h4>
          {arrow}
        </div>
        <div className="text-white opacity-50">
          Indian Institute of Technology, Delhi or IIT Delhi is an Institute of
          Eminence offering about 102 undergraduate, postgraduate, integrated
          and doctoral degree courses. Indian Institute of Technology, Delhi
          courses include
        </div>
      </div>
    </div>
  );
};

const Rights = ({ medias }) => {
  return (
    <div className={`bg-[#022315] px-10 pt-20 pb-10`}>
      <div>
        <h4 className="text-[#F0BC5E] text-[48px] font-plusJakartaSans">
          Not Sure if Mentorship is Right for you? <br /> Give it a Try with a
          One on One Session
        </h4>
        <p className="text-white opacity-50 w-[86%] leading-30">
          Indian Institute of Technology, Delhi or IIT Delhi is an Institute of
          Eminence offering about 102 undergraduate, postgraduate, integrated
          and doctoral degree courses. Indian Institute of Technology, Delhi
          courses include B.Tech, M.Tech, M.Sc and Ph.D in various streams of
          Engineering, Physical Sciences, Management, Humanities, and Social
          Sciences. Students seeking admission for B.Tech in any stream must
          appear for JEE Advanced and JoSaa counselling. Students completing
          their degrees are offered IIT Delhi placements to prestigious
          organisations at a salary package ranging from Rs. 26 LPA to Rs. 40
          LPA (approximately). Indian Institute of Technology, Delhi or IIT
          Delhi is an Institute of Eminence offering about 102 undergraduate,
        </p>
      </div>

      <div className="py-10 flex">
        <img className="w-[30%]" src={medias.images?.mentorship?.Man} alt="" />
        <div className="flex flex-col justify-between">
          <Right
            main={
              <>
                {" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="34.999"
                  height="34.332"
                  viewBox="0 0 34.999 23.332"
                >
                  <path
                    id="Path_190342"
                    data-name="Path 190342"
                    d="M33.55,5.454a3.276,3.276,0,0,0-3.4.178L23.832,9.869V6.5a3.5,3.5,0,0,0-3.5-3.5H4A3.5,3.5,0,0,0,.5,6.5V22.832a3.5,3.5,0,0,0,3.5,3.5H20.332a3.5,3.5,0,0,0,3.5-3.5v-3.37L30.148,23.7a3.252,3.252,0,0,0,3.4.178A3.788,3.788,0,0,0,35.5,20.495V8.838A3.788,3.788,0,0,0,33.55,5.454ZM21.5,22.832A1.168,1.168,0,0,1,20.332,24H4a1.168,1.168,0,0,1-1.167-1.167V6.5A1.168,1.168,0,0,1,4,5.333H20.332A1.168,1.168,0,0,1,21.5,6.5Zm11.666-2.338a1.462,1.462,0,0,1-.713,1.326.929.929,0,0,1-1-.057l-7.616-5.111V12.679l7.615-5.11a.945.945,0,0,1,1-.057,1.462,1.462,0,0,1,.713,1.325Z"
                    transform="translate(-0.5 -3)"
                    fill="#fff"
                  />
                </svg>
              </>
            }
            title={" Introductory Call"}
            arrow={
              <>
                {" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="36"
                  height="36"
                  viewBox="0 0 36 36"
                >
                  <g
                    id="Group_317820"
                    data-name="Group 317820"
                    transform="translate(-1097 -4835)"
                  >
                    <circle
                      id="Ellipse_4174"
                      data-name="Ellipse 4174"
                      cx="18"
                      cy="18"
                      r="18"
                      transform="translate(1097 4835)"
                      fill="#f0bc5e"
                    />
                    <path
                      id="Path_190344"
                      data-name="Path 190344"
                      d="M20.375,9.75a12.181,12.181,0,0,0-9.1,7H8.486a12.2,12.2,0,0,1,5.237-7H0V7H13.716a12.193,12.193,0,0,1-5.23-7h2.789a12.181,12.181,0,0,0,9.1,7Z"
                      transform="matrix(0.914, -0.407, 0.407, 0.914, 1102.287, 4849.494)"
                    />
                  </g>
                </svg>
              </>
            }
          />
          <Right
            main={
              <>
                {" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="34.339"
                  height="34.319"
                  viewBox="0 0 48.339 40.319"
                >
                  <path
                    id="Path_190343"
                    data-name="Path 190343"
                    d="M47.4,53.247a1.416,1.416,0,0,0,0-2.67L24.64,42.55a1.415,1.415,0,0,0-.942,0L.945,50.578a1.416,1.416,0,0,0,0,2.67l9.013,3.2V65.69c0,1.525,1.591,2.814,4.727,3.832A31.662,31.662,0,0,0,24.169,70.9a31.663,31.663,0,0,0,9.487-1.376c3.137-1.018,4.727-2.307,4.727-3.832V56.452L42.1,55.129v12.8a4.259,4.259,0,0,0,0,8.035v5.408a1.416,1.416,0,0,0,2.832,0V75.964a4.259,4.259,0,0,0,0-8.035V54.122ZM43.521,73.375a1.428,1.428,0,1,1,1.428-1.428A1.43,1.43,0,0,1,43.521,73.375ZM24.169,45.387,42.68,51.918,24.169,58.5,5.658,51.918ZM35.551,65.476a8.747,8.747,0,0,1-3.436,1.555,29.858,29.858,0,0,1-7.946,1.035,29.859,29.859,0,0,1-7.946-1.035,8.746,8.746,0,0,1-3.436-1.555V57.459l10.907,3.878a1.415,1.415,0,0,0,.949,0l10.907-3.878v8.017Z"
                    transform="translate(0 -42.469)"
                    fill="#fff"
                  />
                </svg>
              </>
            }
            title={" About Academics"}
            arrow={
              <>
                {" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="36"
                  height="36"
                  viewBox="0 0 36 36"
                >
                  <g
                    id="Group_317821"
                    data-name="Group 317821"
                    transform="translate(-1097 -4835)"
                  >
                    <circle
                      id="Ellipse_4174"
                      data-name="Ellipse 4174"
                      cx="18"
                      cy="18"
                      r="18"
                      transform="translate(1097 4835)"
                      fill="#69dd8c"
                    />
                    <path
                      id="Path_190344"
                      data-name="Path 190344"
                      d="M20.375,9.75a12.181,12.181,0,0,0-9.1,7H8.486a12.2,12.2,0,0,1,5.237-7H0V7H13.716a12.193,12.193,0,0,1-5.23-7h2.789a12.181,12.181,0,0,0,9.1,7Z"
                      transform="matrix(0.914, -0.407, 0.407, 0.914, 1102.287, 4849.494)"
                    />
                  </g>
                </svg>
              </>
            }
          />
          <Right
            main={
              <>
                {" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="34.208"
                  height="34.208"
                  viewBox="0 0 41.208 41.208"
                >
                  <defs>
                    <clipPath id="clip-path">
                      <path
                        id="path3831"
                        d="M0-682.665H41.208v41.208H0Z"
                        transform="translate(0 682.665)"
                        fill="#fff"
                      />
                    </clipPath>
                  </defs>
                  <g id="g3825" transform="translate(0 682.665)">
                    <g id="g3827" transform="translate(0 -682.665)">
                      <g id="g3829" clip-path="url(#clip-path)">
                        <g id="g3835" transform="translate(14.648 0)">
                          <path
                            id="path3837"
                            d="M-406.719-220a13.3,13.3,0,0,1,13.28,13.28,13.221,13.221,0,0,1-2.9,8.281l2.6,3a1.207,1.207,0,0,1,.186,1.292,1.207,1.207,0,0,1-1.1.706h-12.073A13.3,13.3,0,0,1-420-206.72,13.3,13.3,0,0,1-406.719-220Zm9.427,24.145-1.555-1.793a1.207,1.207,0,0,1,.034-1.619,10.822,10.822,0,0,0,2.96-7.453,10.878,10.878,0,0,0-10.865-10.865,10.878,10.878,0,0,0-10.865,10.865,10.878,10.878,0,0,0,10.865,10.865Z"
                            transform="translate(419.999 219.999)"
                            fill="#fff"
                          />
                        </g>
                        <g id="g3839" transform="translate(26.721 19.075)">
                          <path
                            id="path3841"
                            d="M0-18.793a1.207,1.207,0,0,0,1.207,1.207,1.207,1.207,0,0,0,1.207-1.207A1.207,1.207,0,0,0,1.207-20,1.207,1.207,0,0,0,0-18.793"
                            transform="translate(0 20)"
                            fill="#fff"
                          />
                        </g>
                        <g id="g3843" transform="translate(23.296 4.99)">
                          <path
                            id="path3845"
                            d="M-15.368-63.886a1.207,1.207,0,0,1-1.207-1.207v-3.581a1.207,1.207,0,0,1,1.207-1.207,2.2,2.2,0,0,0,1.608-.691,2.2,2.2,0,0,0,.606-1.645,2.222,2.222,0,0,0-2.168-2.1,2.217,2.217,0,0,0-2.242,1.906,2.255,2.255,0,0,0-.021.311,1.207,1.207,0,0,1-1.207,1.207A1.207,1.207,0,0,1-20-72.1a4.688,4.688,0,0,1,.045-.644,4.633,4.633,0,0,1,4.682-3.987,4.645,4.645,0,0,1,4.53,4.386,4.594,4.594,0,0,1-1.266,3.433,4.652,4.652,0,0,1-2.152,1.282v2.535A1.207,1.207,0,0,1-15.368-63.886Z"
                            transform="translate(20 76.73)"
                            fill="#fff"
                          />
                        </g>
                        <g id="g3847" transform="translate(0 14.648)">
                          <path
                            id="path3849"
                            d="M-255.354-25.968a13.33,13.33,0,0,1,3.228.4,1.207,1.207,0,0,1,.879.879,10.832,10.832,0,0,0,7.9,7.9,1.207,1.207,0,0,1,.879.879,13.329,13.329,0,0,1,.4,3.228A13.3,13.3,0,0,1-255.354.592h-12.073a1.207,1.207,0,0,1-1.1-.706,1.207,1.207,0,0,1,.186-1.292l2.6-3a13.221,13.221,0,0,1-2.9-8.281A13.3,13.3,0,0,1-255.354-25.968Zm1.97,2.593a10.939,10.939,0,0,0-1.97-.179A10.878,10.878,0,0,0-266.22-12.688a10.822,10.822,0,0,0,2.96,7.453,1.207,1.207,0,0,1,.034,1.619l-1.555,1.793h9.427a10.878,10.878,0,0,0,10.865-10.865,10.94,10.94,0,0,0-.179-1.97A13.237,13.237,0,0,1-253.384-23.375Z"
                            transform="translate(268.634 25.968)"
                            fill="#fff"
                          />
                        </g>
                        <g id="g3851" transform="translate(8.048 24.306)">
                          <path
                            id="path3853"
                            d="M-10.744-17.585h-8.048A1.207,1.207,0,0,1-20-18.793,1.207,1.207,0,0,1-18.793-20h8.048a1.207,1.207,0,0,1,1.207,1.207A1.207,1.207,0,0,1-10.744-17.585Z"
                            transform="translate(20 20)"
                            fill="#fff"
                          />
                        </g>
                        <g id="g3855" transform="translate(8.048 29.135)">
                          <path
                            id="path3857"
                            d="M-10.744-17.585h-8.048A1.207,1.207,0,0,1-20-18.793,1.207,1.207,0,0,1-18.793-20h8.048a1.207,1.207,0,0,1,1.207,1.207A1.207,1.207,0,0,1-10.744-17.585Z"
                            transform="translate(20 20)"
                            fill="#fff"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </>
            }
            title={"Interview Preparations"}
            arrow={
              <>
                {" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="36"
                  height="36"
                  viewBox="0 0 36 36"
                >
                  <g
                    id="Group_317822"
                    data-name="Group 317822"
                    transform="translate(-1097 -4835)"
                  >
                    <circle
                      id="Ellipse_4174"
                      data-name="Ellipse 4174"
                      cx="18"
                      cy="18"
                      r="18"
                      transform="translate(1097 4835)"
                      fill="#dd69be"
                    />
                    <path
                      id="Path_190344"
                      data-name="Path 190344"
                      d="M20.375,9.75a12.181,12.181,0,0,0-9.1,7H8.486a12.2,12.2,0,0,1,5.237-7H0V7H13.716a12.193,12.193,0,0,1-5.23-7h2.789a12.181,12.181,0,0,0,9.1,7Z"
                      transform="matrix(0.914, -0.407, 0.407, 0.914, 1102.287, 4849.494)"
                    />
                  </g>
                </svg>
              </>
            }
          />
        </div>
      </div>

      {/* Mentor Cards */}
      <div>
        <MentorCard />
      </div>
    </div>
  );
};

export default Rights;
