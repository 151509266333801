import { useEffect, useState } from "react";
import { medias } from "../../config/Resources";
import { FaStar } from "react-icons/fa";
import { Button } from "@mui/material";
import { EastRounded } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { scrollToSection } from "../../helpers/ScrollToId";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Controls, Player } from "@lottiefiles/react-lottie-player";
import arrows from "../../animations/verify.json";

const arr = [
  "10 Years of Experience",
  "#Career Counselling",
  "#Career Counselling",
  "#Career Counselling",
];

const tabs = [
  { name: "About", id: 1 },
  { name: "Skills", id: 2 },
  { name: "Plans Offer", id: 3 },
  { name: "Services", id: 4 },
  { name: "Post", id: 5 },
  { name: "Education", id: 6 },
  { name: "Projects", id: 7 },
  { name: "Courses", id: 8 },
  { name: "Events", id: 9 },
];

const TopCard = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="">
        <img
          // src="https://png.pngtree.com/background/20210712/original/pngtree-neon-double-color-futuristic-frame-colorful-background-picture-image_1178693.jpg"
          src={medias.images?.mentorship?.MentorCover}
          alt="Banner"
          className="h-[250px] w-screen rounded-t-3xl"
        />
        <div className="flex gap-4 pt-4 pb-8 px-4 bg-white rounded-b-3xl">
          <img
            src="https://media.istockphoto.com/id/1171169127/photo/headshot-of-cheerful-handsome-man-with-trendy-haircut-and-eyeglasses-isolated-on-gray.jpg?s=612x612&w=0&k=20&c=yqAKmCqnpP_T8M8I5VTKxecri1xutkXH7zfybnwVWPQ="
            alt=""
            className="min-w-36 max-w-40 min-h-40 max-h-48 rounded-xl bg-cover"
          />
          <div className="space-y-2">
            <div className="flex gap-2 items-center">
              <h4 className="text-[36px] font-plusJakartaSans font-bold">
                Dr. Vibhash Rasal
              </h4>
              <div className="flex justify-center items-center text-center gap-2 bg-[#FAE9CB] rounded-[6px] p-[6px]">
                <p>Male</p>
              </div>
              <span>
                {" "}
                <Player
                  autoplay
                  speed={1.5}
                  loop
                  src={arrows}
                  style={{
                    height: "40px",
                    padding: 0,
                    width: "40px",
                  }}
                >
                  <Controls
                    visible={false}
                    buttons={[
                      "play",
                      "repeat",
                      "frame",
                      "debug",
                      "snapshot",
                      "background",
                    ]}
                  />
                </Player>
              </span>
            </div>
            <div className="flex items-center text-center gap-x-2">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28.574"
                  height="26.342"
                  viewBox="0 0 28.574 26.342"
                >
                  <g id="XMLID_15_" transform="translate(0 -20)">
                    <path
                      id="XMLID_971_"
                      d="M142.287,32.456v-.223A2.232,2.232,0,0,0,140.055,30h-9.822A2.232,2.232,0,0,0,128,32.232v.223Z"
                      transform="translate(-120.856 -9.442)"
                      fill="#c68a65"
                    />
                    <path
                      id="XMLID_781_"
                      d="M35.226,149.645H12.232A2.232,2.232,0,0,1,10,147.412v-15.18A2.232,2.232,0,0,1,12.232,130H35.226a2.232,2.232,0,0,1,2.232,2.232v15.18A2.232,2.232,0,0,1,35.226,149.645Z"
                      transform="translate(-9.442 -103.861)"
                      fill="#d1a184"
                    />
                    <path
                      id="XMLID_973_"
                      d="M37.458,265.6V255.174l-13.729,4.157L10,255.174h0V265.6a2.232,2.232,0,0,0,2.232,2.232H35.226A2.232,2.232,0,0,0,37.458,265.6Z"
                      transform="translate(-9.442 -222.049)"
                      fill="#c68a65"
                    />
                    <path
                      id="XMLID_780_"
                      d="M208,200h5.358v2.679H208Z"
                      transform="translate(-196.392 -169.954)"
                      fill="#e2e9ee"
                    />
                    <g id="XMLID_1090_" transform="translate(0 20)">
                      <path
                        id="XMLID_1098_"
                        d="M28.574,28.371a2.794,2.794,0,0,0-2.79-2.79h-3.8V22.79A2.794,2.794,0,0,0,19.2,20H9.376a2.794,2.794,0,0,0-2.79,2.79v2.79H2.79A2.794,2.794,0,0,0,0,28.371v15.18a2.794,2.794,0,0,0,2.79,2.79H25.784a2.794,2.794,0,0,0,2.79-2.79Zm-19.2-7.255H19.2a1.677,1.677,0,0,1,1.641,1.339H7.735a1.677,1.677,0,0,1,1.641-1.339ZM2.79,26.7h8.992a.558.558,0,1,0,0-1.116H7.7V23.572H20.873v2.009H16.806a.558.558,0,0,0,0,1.116h8.978a1.676,1.676,0,0,1,1.674,1.674v4.339L14.287,36.7,1.116,32.711V28.371A1.676,1.676,0,0,1,2.79,26.7ZM25.784,45.226H2.79a1.676,1.676,0,0,1-1.674-1.674V33.877l13.009,3.939a.558.558,0,0,0,.323,0l13.009-3.939v9.674A1.676,1.676,0,0,1,25.784,45.226Z"
                        transform="translate(0 -20)"
                      />
                      <path
                        id="XMLID_1119_"
                        d="M198.558,193.8h5.358a.558.558,0,0,0,.558-.558v-2.679a.558.558,0,0,0-.558-.558h-5.358a.558.558,0,0,0-.558.558v2.679A.558.558,0,0,0,198.558,193.8Zm.558-2.679h4.241v1.563h-4.241Z"
                        transform="translate(-186.95 -180.512)"
                      />
                      <path
                        id="XMLID_1126_"
                        d="M246.688,121.116a.557.557,0,1,0-.4-.164A.562.562,0,0,0,246.688,121.116Z"
                        transform="translate(-232.394 -114.419)"
                      />
                    </g>
                  </g>
                </svg>
              </span>
              <p>Co-founder and Creative head Sygnific Careers/ Surgeon</p>
            </div>
            <div className="flex items-center text-center gap-x-2">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18.891"
                  height="25.91"
                  viewBox="0 0 18.891 25.91"
                >
                  <path
                    id="Icon_material-location-on"
                    data-name="Icon material-location-on"
                    d="M16.2,3a8.555,8.555,0,0,0-8.7,8.421c0,6.316,8.7,15.639,8.7,15.639s8.7-9.324,8.7-15.639A8.555,8.555,0,0,0,16.2,3Zm0,11.429A3.009,3.009,0,1,1,19.3,11.421,3.059,3.059,0,0,1,16.2,14.429Z"
                    transform="translate(-6.75 -2.25)"
                    fill="#ff5a5a"
                    stroke="#131313"
                    stroke-width="1.5"
                  />
                </svg>
              </span>
              <p>IIT Delhi Main Rd, Hauz Khas, Delhi 110016</p>
              <div className="mt-4 flex flex-wrap text-center">
                <div className="flex justify-center items-center text-center gap-2 bg-[#F2F2F2] rounded-[6px] p-[6px] ">
                  <FaStar className="text-[#F0D41B] text-[16px]" />
                  <span className="text-[#000000] font-bold">4.5</span>
                </div>
                <h5 className="text-[#239403] underline underline-offset-4 m-2">
                  35k+ Reviews
                </h5>
              </div>
            </div>

            <div className="flex items-center text-center gap-x-2">
              {arr.map((ele, i) => {
                return (
                  <p
                    key={i}
                    className={` ${
                      i === 0 ? "bg-black  text-white" : ""
                    }  border-[#707070] border-[1px] border-solid px-3 py-1 rounded-md`}
                  >
                    {ele}
                  </p>
                );
              })}
            </div>
          </div>
          <div className="flex flex-col justify-between">
            <div className="flex justify-between text-center mb-2  px-1 gap-x-5 font-plusJakartaSans">
              <div>
                <div className="flex flex-col  justify-center items-center text-center text-[20px] mb-3">
                  <h5 className="font-extrabold">122k</h5>
                  <h6>Followers</h6>
                </div>
                <Button
                  onClick={() => navigate("/mentorship/mentor/call")}
                  variant="contained"
                  className="border py-[19px] flex gap-[6.2px] miniDesk:!px-[2px] text-[19px] font-medium capitalize !text-[#000000] !bg-[#F0BC5E52] 3xl:py-[10px]  3xl:px-3 3xl:text-[13px] 3xl:h-fit !shadow-none px-[28px] border-[#239403] rounded-[9px]"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22.151"
                    height="22.558"
                    viewBox="0 0 22.151 22.558"
                  >
                    <g
                      id="chat_2_"
                      data-name="chat (2)"
                      transform="translate(0)"
                    >
                      <g id="Group_411891" data-name="Group 411891">
                        <path
                          id="Path_266927"
                          data-name="Path 266927"
                          d="M14.493,0h-.036A11.066,11.066,0,0,0,5.778,17.963L4.164,21.716a.6.6,0,0,0,.314.792.614.614,0,0,0,.345.042l5.917-1.04a10.663,10.663,0,0,0,3.687.641A11.076,11.076,0,0,0,14.493,0ZM10.7,8.861h3.264a.6.6,0,0,1,0,1.209H10.7a.6.6,0,1,1,0-1.209Zm7.495,4.231H10.7a.6.6,0,1,1,0-1.209h7.495a.6.6,0,1,1,0,1.209Z"
                          transform="translate(-3.366)"
                        />
                      </g>
                    </g>
                  </svg>
                  Start Chat
                  {/* <EastRounded className="3xl:text-[16px] miniDesk:hidden" /> */}
                </Button>
              </div>
              <div>
                <div className="flex flex-col  justify-center items-center text-center text-[20px] mb-3">
                  <h5 className="font-extrabold">133</h5>
                  <h6>Following</h6>
                </div>
                <Button
                  // onClick={() => {
                  //   setModal(true);
                  //   setMentorData(mentor);
                  // }}
                  variant="contained"
                  className="border py-[19px] flex gap-[6.2px] miniDesk:!px-[2px] text-[19px] font-medium capitalize !text-[#000000] !bg-[#F0BC5E] 3xl:py-[10px]  3xl:px-3 3xl:text-[13px] 3xl:h-fit !shadow-none px-[28px] border-[#239403] rounded-[9px]"
                >
                  Buy Now{" "}
                  <EastRounded className="3xl:text-[16px] miniDesk:hidden" />
                </Button>
              </div>
            </div>
            <div>
              <Button
                variant="contained"
                className="border-[1px] border-solid py-[19px] flex gap-[6.2px] w-full miniDesk:!px-[2px] text-[19px] font-medium capitalize !text-[#000000] !bg-[#F0BC5E0A] 3xl:py-[10px]  3xl:px-3 3xl:text-[13px] 3xl:h-fit !shadow-none px-[28px] border-[#F0BC5E] rounded-[9px]"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22.11"
                  height="22.997"
                  viewBox="0 0 22.11 22.997"
                >
                  <g
                    id="Layer_2_00000150809447564471313360000017511645015593875105_"
                    transform="translate(-0.098 0.897)"
                  >
                    <g id="_07.add_friend" transform="translate(0.098 0)">
                      <path
                        id="Path_190368"
                        data-name="Path 190368"
                        d="M93,8.614A5.058,5.058,0,0,1,96.605,0h.026a5.022,5.022,0,0,1,5.028,5.015v.043a5.022,5.022,0,0,1-4.98,5.062h-.073A5.064,5.064,0,0,1,93,8.614Z"
                        transform="translate(-87.57 -0.896)"
                      />
                      <path
                        id="Path_190369"
                        data-name="Path 190369"
                        d="M17.4,256.555a3.551,3.551,0,0,1-2.689,1.273c-.652.026-1.351.039-2.2.039-.578,0-1.17,0-1.735-.009-.432,0-.872-.009-1.312-.009v.009H9.057c-.531,0-1.062,0-1.592,0s-1.062,0-1.592,0c-.747,0-1.368,0-1.946-.009A3.862,3.862,0,0,1,.788,256.52a3.879,3.879,0,0,1-.539-3.375,8.635,8.635,0,0,1,3.211-4.834A9.314,9.314,0,0,1,9.07,246.4c.086,0,.173,0,.255,0a9.037,9.037,0,0,1,8.765,7.229,3.606,3.606,0,0,1-.686,2.922Z"
                        transform="translate(-0.098 -235.766)"
                      />
                      <path
                        id="Path_190370"
                        data-name="Path 190370"
                        d="M337.628,104.177a1.373,1.373,0,0,1-1.372-1.372V101.7h-1.113a1.37,1.37,0,0,1,0-2.74h1.113V97.842a1.37,1.37,0,0,1,2.74,0v1.113h1.113a1.37,1.37,0,0,1,.047,2.74H339v1.113A1.363,1.363,0,0,1,337.628,104.177Z"
                        transform="translate(-319.394 -92.331)"
                      />
                    </g>
                  </g>
                </svg>
                Follow
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const DetailsCard = ({ title, desc, id, descClasses }) => {
  return (
    <div id={id}>
      <div className="w-fit">
        <h3 className="text-[32px] font-bold -mb-3">{title}</h3>
        <div className="border-t-8 border-[#F0BC5E] w-full "></div>
      </div>
      <p className={`text-black opacity-80 text-[15px] mt-12  ${descClasses}`}>
        {desc}
      </p>
    </div>
  );
};

const MentorDetails = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const [active, setActive] = useState(1);
  const [activePlan, setActivePlan] = useState(1);

  return (
    <div className="bg-[#ECF7EE] px-32 py-10 space-y-5 font-plusJakartaSans">
      <TopCard />

      <div className="bg-white pb-4 px-2 rounded-[20px]">
        <div className=" flex justify-evenly pr-[20%]  font-bold border-gray-200 border-b-[1px] ">
          {tabs.map((tab, index) => {
            return (
              <h4
                onClick={() => {
                  scrollToSection(tab?.name);
                  setActive(index + 1);
                }}
                key={index}
                className={`${
                  active === tab?.id
                    ? " !text-black  border-b-4 rounded-sm border-[#F0BC5E]  bg-[#F0BC5E] bg-opacity-10  "
                    : ""
                } pb-4 miniDesk:text-[11px] text-[17px] cursor-pointer !pt-6 px-2 text-gray-400 `}
              >
                {tab?.name}
              </h4>
            );
          })}
        </div>
        {/* details Sections */}

        {active != 3 && (
          <div className="mt-4 flex px-10 border-b-[1px] border-gray-300 pb-6 ">
            <div className="w-1/2 space-y-12 ">
              <DetailsCard
                id="About"
                title={"About"}
                desc={
                  "   Indian Institute of Technology, Delhi or IIT Delhi is an Institute of Eminence offering about 102 undergraduate, postgraduate, integrated and  doctoral degree courses. Indian Institute of Technology, Delhi courses include B.Tech, M.Tech, M.Sc and Ph.D in various streams of Engineering, Physical Sciences, Management, Humanities"
                }
              />
              <DetailsCard
                id={"Skills"}
                title={"Skills"}
                desc={
                  "   Indian Institute of Technology, Delhi or IIT Delhi is an Institute of Eminence offering about 102 undergraduate, postgraduate, integrated and  doctoral degree courses. Indian Institute of Technology, Delhi courses include B.Tech, M.Tech, M.Sc and Ph.D in various streams of Engineering, Physical Sciences, Management, Humanities"
                }
              />

              {/* <div>
                <DetailsCard title={"Plans Offer"} />
                <div className="text-[16px] opacity-80 font-bold">
                  <p>Rs 5000/-Hour</p>
                  <p>Rs 5000/-Hour</p>
                  <p>Rs 5000/-Hour</p>
                </div>
              </div> */}
              <DetailsCard
                id={"Services"}
                title={"Services"}
                desc={
                  "   Indian Institute of Technology, Delhi or IIT Delhi is an Institute of Eminence offering about 102 undergraduate, postgraduate, integrated and  doctoral degree courses. Indian Institute of Technology, Delhi courses include B.Tech, M.Tech, M.Sc and Ph.D in various streams of Engineering, Physical Sciences, Management, Humanities"
                }
              />
              <DetailsCard
                id={"Currently"}
                title={"Currently"}
                desc={
                  "   Indian Institute of Technology, Delhi or IIT Delhi is an Institute of Eminence offering about 102 undergraduate, postgraduate, integrated and  doctoral degree courses. Indian Institute of Technology, Delhi courses include B.Tech, M.Tech, M.Sc and Ph.D in various streams of Engineering, Physical Sciences, Management, Humanities"
                }
              />
            </div>
            <div className="w-1/2 px-5 space-y-12">
              <div className="">
                <DetailsCard id={"Post"} title={"Post"} />
                <div className="flex  py-1 max-h-96">
                  <img
                    className="w-[60%] rounded-xl"
                    src="https://www.shutterstock.com/image-photo/view-over-businesswoman-shoulder-sit-600nw-1707297181.jpg"
                    alt=""
                  />
                  <div className="w-[40%] px-6 space-y-6">
                    <img
                      className="rounded-xl"
                      src="https://www.shutterstock.com/image-photo/view-over-businesswoman-shoulder-sit-600nw-1707297181.jpg"
                      alt=""
                    />{" "}
                    <img
                      className="rounded-xl"
                      src="https://www.shutterstock.com/image-photo/view-over-businesswoman-shoulder-sit-600nw-1707297181.jpg"
                      alt=""
                    />{" "}
                    <img
                      className="rounded-xl"
                      src="https://www.shutterstock.com/image-photo/view-over-businesswoman-shoulder-sit-600nw-1707297181.jpg"
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <div>
                <DetailsCard id={"Education"} title={"Education"} desc={""} />

                <div className="flex gap-x-3">
                  <img
                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRZe4ZECHultZxvFzw9Smm9QOSQ2toLQgF9sKca2CG_nr0FYP897qpf&usqp=CAE&s"
                    alt=""
                    className="w-10 h-10"
                  />
                  <div>
                    <h5>AIIMS Delhi</h5>
                    <p className="text-[#818181]">5yrs 9 mons</p>
                  </div>
                </div>
              </div>
              <div>
                <DetailsCard id={"Education"} title={"Experience"} desc={""} />
              </div>
            </div>
          </div>
        )}

        {active != 3 && (
          <div className="mt-4 flex px-10   pb-6 ">
            <div className="w-1/2 space-y-20 ">
              <DetailsCard id={"Projects"} title={"Projects"} desc={""} />
              <DetailsCard id={"Courses"} title={"Courses"} desc={""} />
              <DetailsCard id={"Events"} title={"Events"} desc={""} />
            </div>
            <div className="w-1/2"></div>
          </div>
        )}

        {active == 3 && (
          <>
            {" "}
            <div className="w-full mt-10 flex">
              <div className="w-[70%] pl-10">
                <div className="flex items-start text-center overflow-x-scroll gap-x-10 no-scrollbar">
                  {tabs?.slice(0, 4)?.map((ele, i) => {
                    return (
                      <div
                        onClick={() => setActivePlan(i + 1)}
                        className={`border py-[19px] cursor-pointer text-nowrap flex gap-[6.2px] miniDesk:!px-[8px] text-[21px] font-medium capitalize !text-[#000000] ${
                          activePlan === i + 1 ? "!bg-[#F0BC5E] " : ""
                        } 3xl:py-[10px]   3xl:text-[16px] 3xl:h-fit !shadow-none px-[35px] !font-extrabold border-[#F0BC5E] rounded-[25px]`}
                      >
                        {" "}
                        Plan Name
                      </div>
                    );
                  })}
                </div>
                <div className="space-y-8 mt-8">
                  <DetailsCard
                    id={"Key Points"}
                    title={"Key Points"}
                    descClasses=" !mt-5 "
                    desc={
                      "   Indian Institute of Technology, Delhi or IIT Delhi is an Institute of Eminence offering about 102 undergraduate, postgraduate, integrated and  doctoral degree courses. Indian Institute of Technology, Delhi courses include B.Tech, M.Tech, M.Sc and Ph.D in various streams of Engineering, Physical Sciences, Management, Humanities Delhi or IIT Delhi is an Institute of Eminence offering about 102 undergraduate, postgraduate, integrated and  doctoral degree courses. Indian Institute of Technology, Delhi courses include B.Tech, M.Tech"
                    }
                  />{" "}
                  <DetailsCard
                    id={"Who should Attend"}
                    title={"Who should Attend"}
                    descClasses=" !mt-5 "
                    desc={
                      "   Indian Institute of Technology, Delhi or IIT Delhi is an Institute of Eminence offering about 102 undergraduate, postgraduate, integrated and  doctoral degree courses. Indian Institute of Technology, Delhi courses include B.Tech, M.Tech, M.Sc and Ph.D in various streams of Engineering, Physical Sciences, Management, Humanities Delhi or IIT Delhi is an Institute of Eminence offering about 102 undergraduate, postgraduate, integrated and  doctoral degree courses. Indian Institute of Technology, Delhi courses include B.Tech, M.Tech"
                    }
                  />{" "}
                  <DetailsCard
                    id={"Terms & Conditions"}
                    title={"Terms & Conditions"}
                    descClasses=" !mt-5 "
                    desc={
                      "   Indian Institute of Technology, Delhi or IIT Delhi is an Institute of Eminence offering about 102 undergraduate, postgraduate, integrated and  doctoral degree courses. Indian Institute of Technology, Delhi courses include B.Tech, M.Tech, M.Sc and Ph.D in various streams of Engineering, Physical Sciences, Management, Humanities Delhi or IIT Delhi is an Institute of Eminence offering about 102 undergraduate, postgraduate, integrated and  doctoral degree courses. Indian Institute of Technology, Delhi courses include B.Tech, M.Tech"
                    }
                  />
                </div>
              </div>
              <div className="w-[30%] p-4  pl-8   rounded-2xl">
                {" "}
                <div className="w-full space-y-5 h-fit border-[0.3px] px-5 border-gray-200 rounded-2xl border-solid shadow-sm  ">
                  {" "}
                  <div className="flex gap-2 items-center  mt-6">
                    <div className="flex justify-center font-medium items-center text-center gap-1 bg-[#2771E7] bg-opacity-10 rounded-[18px] p-[8px] px-[14px]">
                      <HelpOutlineIcon />
                      <p>Help & Support</p>
                    </div>
                  </div>
                  <div className="flex items-center text-center justify-between">
                    <h2>Service Fees</h2>
                    <h2>₹ 499</h2>
                  </div>
                  <div className="flex items-center text-center justify-between">
                    <h2>Convenience Fees</h2>
                    <h2>₹ 20</h2>
                  </div>{" "}
                  <div className="flex items-center text-center font-bold text-[25px] justify-between">
                    <h2>Grand Total</h2>
                    <h2 className="text-[40px]">₹ 519</h2>
                  </div>
                  <div>
                    <Button
                      variant="contained"
                      className="border-[1px] mb-2 border-solid py-[19px] flex gap-[6.2px] w-full miniDesk:!px-[2px] text-[19px] font-medium capitalize !text-[#000000] !bg-[#F0BC5E] 3xl:py-[10px]  3xl:px-3 3xl:text-[13px] 3xl:h-fit !shadow-none px-[28px] border-[#F0BC5E] rounded-[9px]"
                    >
                      Buy Now
                      <EastRounded className="3xl:text-[16px] miniDesk:hidden" />
                    </Button>
                  </div>
                </div>{" "}
              </div>
            </div>{" "}
          </>
        )}
      </div>
    </div>
  );
};

export default MentorDetails;
