import { medias } from "../../../../config/Resources";
import EntranceCourseChip from "./EntranceCourseChip";
import EntranceDocuments from "./EntranceDocuments";
import EntranceExamChips from "./EntranceExamChips";
import EntranceSubjects from "./EntranceSubjects";

function EntranceSyllabus({ examPattern }) {
	return (
		<>
			<div className="ml-4">
				<h6 className="mt-5 font-plusJakartaSans flex items-center text-center gap-[5px] font-bold text-[#000000] text-[15px]">
					<img
						className="w-5 h-5"
						src={medias.images.Exam.Syllabus}
						alt="Privilege"
					/>
					<span className="font-plusJakartaSans font-bold mt-[1px]">
						Syllabus
					</span>
				</h6>

				<img
					className="w-40 text-[#000000] pt-1"
					src={medias.images.Profile.line1}
					alt="lin1"
				/>
			</div>
			<EntranceCourseChip examPattern={examPattern} />
			<EntranceExamChips examPattern={examPattern} />
			<EntranceSubjects examPattern={examPattern} />
			<EntranceDocuments examPattern={examPattern} />
		</>
	);
}

export default EntranceSyllabus;
