import React, { useEffect, useState } from "react";
import { medias } from "../../../../config/Resources";
import axiosInstance from "../../../../config/Axios/axiosInstance";
import { API_URLS } from "../../../../config/API_URLS";
import { Category } from "@mui/icons-material";

function CutoffTable({
	id,
	setSelectedCoursechip,
	setSelectedYear,
	selectedYear,
	cutOff,
}) {
	const [selectedCourse, setSelectedCourse] = useState("");
	const [cutoffData, setCutoffData] = useState([]);

	useEffect(() => {
		const fetchEntranceDetails = async () => {
			try {
				const res = await axiosInstance.get(
					`${API_URLS.getCutOffEntranceExam}?college_id=${id}`
				);
				if (res) {
					setCutoffData(res?.result);
				}
			} catch (error) {
				console.error("Error fetching entrance details", error);
			}
		};
		fetchEntranceDetails();
	}, [id]);

	const handleCourseChange = (event) => {
		setSelectedCourse(event.target.value);
		setSelectedCoursechip(event.target.value);
	};

	const currentYear = new Date().getFullYear();
	const startYear = 2015;
	const years = [];

	for (let year = startYear; year <= currentYear; year++) {
		years.push(year);
	}

	const handleChange = (event) => {
		setSelectedYear(event.target.value);
	};
	return (
		<div className="">
			<div className="flex items-center gap-2 mt-5 ml-4 mr-4">
				<img
					className="w-10 h-10"
					src={medias.images.College.Eligibility}
					alt="courses"
				/>
				<div>
					<p className="font-semibold text-[#000000] opacity-50 font-plusJakartaSans text-[11px]">
						IIT Delhi
					</p>
					<p className="font-bold text-[#000000] font-plusJakartaSans text-[14px]">
						B.tech Cutoff 2023
					</p>
				</div>
			</div>
			<div>
				<p className="font-plusJakartaSans font-semibold text-[13px] mt-3 ml-4 mr-4">
					Filters
				</p>
			</div>
			<div className="flex items-center mt-1 ml-4 text-center gap-3">
				<select
					className="block w-[17%] border font-plusJakartaSans text-[14px] pt-2 pb-2 pl-1 pr-3 border-black rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
					value={selectedYear}
					onChange={handleChange}
				>
					{years.map((year) => (
						<option
							className="font-plusJakartaSans text-[10px]"
							key={year}
							value={year}
						>
							{year}
						</option>
					))}
				</select>
				<select
					className="block w-[17%] border font-plusJakartaSans text-[14px] pt-2 pb-2 pl-1 pr-3 border-black rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
					value={selectedCourse}
					onChange={handleCourseChange}
				>
					<option value="">Courses</option>
					{cutoffData?.map((detail, index) => (
						<option key={index} value={detail?.entrance_exam}>
							{detail?.exam_name}
						</option>
					))}
				</select>
			</div>
			<div className="mt-3 ml-4 mr-4">
				<div className="overflow-x-auto mt-4">
					<table className="min-w-full border-collapse">
						<thead>
							<tr>
								<th className="px-4 py-2 bg-[#FFEDC0] text-center font-plusJakartaSans font-bold text-[12px] rounded-l-sm border-r-2 border-white">
									Courses
								</th>
								<th className="px-4 py-2 bg-[#FFEDC0] text-center font-plusJakartaSans font-bold text-[12px] border-r-2 border-white">
									<p className="text-center font-plusJakartaSans text-[#000000] text-[12px] text-opacity-70">
										Cutoff by Marks
									</p>
								</th>
							</tr>
						</thead>
						<tbody className="bg-[#ffffff]">
							{cutOff?.cutoff_data?.map((detail, index) => {
								return detail?.counselling_agencies?.map((councelling) => {
									return councelling?.rounds?.map((round) => {
										return round?.quota_data?.map((QuotaData) => {
											return QuotaData?.categories?.map((categoriesData) => {
												return (
													<tr
									key={index}
									className="border-b border-dotted last:border-0"
								>
									<td className="px-4 py-2 font-plusJakartaSans font-bold text-[11px]">
										{categoriesData?.category_name}
										<p className=" flex item-center gap-2 texts-center font-plusJakartaSans font-medium text-[#000000] text-[8.5px]">
											Check fees{" "}
											<img
												src={medias.images.College.RightIcon}
												alt="RightIcon"
												className=" w-4 h-4"
											/>
										</p>
									</td>
									<td className="px-4 py-2 text-center font-plusJakartaSans font-medium text-[11px]">
										{categoriesData?.number}
									</td>
								
								</tr>
												)
											});
										});
									});
								});
							})}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
}

export default CutoffTable;
