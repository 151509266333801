import { CompareRounded, EastRounded, StarRounded } from "@mui/icons-material";
import { medias } from "../../../../config/Resources";
import { GoDownload } from "react-icons/go";
import { Button } from "@mui/material";

function CourseDetials({ CourseDetails }) {
	return (
		<>
			{CourseDetails?.courses?.map((data, index) => {
				return (
					<div className="ml-4 mr-4 mt-5 mb-5 rounded-xl cursor-pointer overflow-hidden shadow-md bg-white">
						<div className="pt-4 pl-4 pr-4 flex items-center text-center justify-between">
							<h6 className="font-plusJakartaSans font-bold text-[18px]">
								{data?.course_name}
							</h6>
						</div>
						<div className="flex pl-4 pt-5">
							<p className="bg-[#F3F3F3] w-fit items-center flex pl-2 pr-2 pt-[1.5px] pb-[1.5px] text-[12.5px] gap-1 text-center rounded-[4px] font-semibold font-plusJakartaSans">
								<StarRounded className="!text-[#FFC000] w-5 h-5" />
								<span className="mt-[1px]">4.5</span>
							</p>
							<p className="font-plusJakartaSans font-normal ml-4 mt-[1px] underline text-[15px]">
								525 reviews
							</p>
						</div>
						<div className="pt-4 pl-4 flex items-center text-center gap-[10px]">
							<p className="font-plusJakartaSans gap-[2px] text-[#000000] text-opacity-100 font-medium text-[12px] flex item-center text-center">
								<img
									className="w-6 h-6"
									src={medias.images.College.Bachelors}
									alt="Bachelors"
								/>
								<span className="mt-[1.5px] font-plusJakartaSans font-medium">
									{data?.degree_name}
								</span>
							</p>
							<p className="font-plusJakartaSans text-[#000000] text-opacity-100 gap-[5px] font-medium text-[12px] flex items-center text-center">
								<img
									className="w-6 h-6"
									src={medias.images.College.Seats}
									alt="Seats"
								/>
								<span className="mt-[1.5px] font-plusJakartaSans font-medium">
									{data?.no_of_seat} Seats
								</span>
							</p>
							<p className="font-plusJakartaSans text-[#000000] text-opacity-100 gap-[2px] font-medium text-[12px] flex item-center text-center">
								<img
									className="w-6 h-6"
									src={medias.images.College.FullTime}
									alt="Fulltime"
								/>
								<span className="mt-[1.5px] font-plusJakartaSans font-medium">
									Fulltime
								</span>
							</p>
							<p className="font-plusJakartaSans text-[#000000] text-opacity-100 gap-[2px] font-medium text-[12px] flex items-center text-center">
								<img
									className="w-6 h-6"
									src={medias.images.College.UnderGraduate}
									alt="Undergraduate"
								/>
								<span className="mt-[1.5px] font-plusJakartaSans font-medium">
									{data?.study_level}
								</span>
							</p>
						</div>
						<div className="pl-4 pt-5">
							<h6 className="font-plusJakartaSans font-bold text-[15px] gap-[3px] flex">
								<img
									className="w-5 h-5"
									src={medias.images.College.ExamAccepted}
									alt="Specializations"
								/>
								Exam Accepted:
							</h6>
						</div>
						<div className="pl-5 pr-4 pt-3 pb-2 relative flex items-center">
							<div className="flex gap-2 items-center text-center overflow-x-auto scrollable-content">
								<div>
									<p className="font-plusJakartaSans bg-[#ffffff] shadow-md rounded-sm pt-1 pb-1 pl-3 pr-3 mb-2 font-medium text-[13px] whitespace-nowrap border-l-4 border-[#ABA7DF]">
										{data?.exams_accepted}
									</p>
								</div>
							</div>
						</div>
						<div className="mt-[20px] ml-4 mr-4 flex item-center text-center justify-between mb-[20px]">
							<div>
								<button
									variant="outlined"
									className="font-medium border border-[#707070] flex text-center gap-[2px] pt-2 pb-2 pl-5 pr-5  rounded-full text-[12px] font-plusJakartaSans"
								>
									<CompareRounded className="text-[#000000] h-5 w-5" />
									<span className="">Compare</span>
								</button>
							</div>
							<div className="flex items-center text-center gap-2 ml-6">
								<div>
									<p className="flex gap-[3px] font-extrabold">
										<span className="text-[22px] font-plusJakartaSans text-[#239403] font-bold">
											₹ {data?.average_fee}
										</span>
										<span className="text-[14px] opacity-60 font-medium mt-[6px] font-plusJakartaSans">
											1st yr Fees
										</span>
									</p>
								</div>
								<Button
									variant="contained"
									className="border font-plusJakartaSans pt-2 pb-2 pl-5 pr-5  gap-1 !h-full flex item-center text-center font-medium !text-[#fff] capitalize text-[12px] !bg-[#F37400] rounded-full"
								>
									Apply Now
									<EastRounded className="w-5 h-5 miniDesk:hidden" />
								</Button>
							</div>
						</div>
					</div>
				);
			})}
		</>
	);
}

export default CourseDetials;
