import { MdKeyboardArrowRight } from "react-icons/md";
import { medias } from "../../../config/Resources";
import EveryOneReadingCards from "./EveryOneReadingCards";

function EveryOneReading() {
	return (
		<div>
			<div className="flex items-center border-b text-center justify-between mt-14 ml-5 mr-5 mb-10 pb-3 pl-7 pr-9">
				<h1 className="font-plusJakartaSans font-bold text-[23px] flex items-center text-center gap-[5px]">
					<img
						className="w-11 h-11"
						src={medias.images.Blogs.Books}
						alt="Fire"
					/>
					What Everyone’s Reading
				</h1>
				<p className="font-plusJakartaSans pt-[11px] pb-[11px] pl-[21px] rounded-full pr-[21px] bg-[#F37400] bg-opacity-[10%] font-semibold text-[13.5px] text-[#F37400] flex items-center text-center gap-2">
					<span className="font-plusJakartaSans font-semibold">View all</span>
					<MdKeyboardArrowRight className="w-5 h-5" />
				</p>
			</div>
			<div className="ml-7 mr-2">
				<EveryOneReadingCards />
			</div>
		</div>
	);
}

export default EveryOneReading;
