import { useState } from "react";
import { FaAngleUp } from "react-icons/fa";
import { RiArrowDropDownLine } from "react-icons/ri";

function RankingSummary({ rankingData }) {
	const [isExpanded, setIsExpanded] = useState(false);

	const toggleReadMore = () => {
		setIsExpanded(!isExpanded);
	};

	return (
		<div>
			<div className="mt-5">
				<div className="ml-4 mr-4">
					<h6 className="mt-5 font-plusJakartaSans font-bold text-[#000000] text-[15px]">
						Ranking Summary
					</h6>
					{rankingData?.ranking?.summary ? (
						<div className="relative overflow-hidden">
							<div>
								<div
									dangerouslySetInnerHTML={{
										__html: rankingData?.ranking?.summary,
									}}
									className={`font-plusJakartaSans font-medium text-[10.5px] text-[#000000] text-opacity-70 mt-1 ${
										!isExpanded ? "line-clamp-5" : ""
									}`}
								></div>
								{!isExpanded && (
									<div className="absolute bottom-0 left-0 w-full h-8 bg-gradient-to-t from-white to-transparent"></div>
								)}
							</div>
						</div>
					) : (
						<p className="font-plusJakartaSans font-medium text-[10.5px] text-[#000000] text-opacity-70 mt-1">
							N/A
						</p>
					)}
					{rankingData?.ranking?.summary && (
						<button
							onClick={toggleReadMore}
							className="mt-1 text-blue-500 font-plusJakartaSans font-bold text-[12px] flex items-center"
						>
							{isExpanded ? (
								<>
									Read less
									<FaAngleUp className="w-4 h-4 ml-1" />
								</>
							) : (
								<>
									Read more
									<RiArrowDropDownLine className="w-6 h-6" />
								</>
							)}
						</button>
					)}
				</div>
			</div>
		</div>
	);
}

export default RankingSummary;
