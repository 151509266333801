import React from "react";
import Text from "../../Text";
import Image from "../../Image";
import { guidancePageContant } from "../../../config/pageContent";
import { medias } from "../../../config/Resources";

const Face13 = () => {
  return (
    <>
      <div>
        <div className="flex flex-col items-center mt-[61px] gap-[18px]">
          <Text className="text-[48px] font-semibold">
            Why there is nothing quite Like
          </Text>
          <Image
            src={medias.images.guidance.Face10.LogoBetween}
            alt="Logobetween"
            className="bg-[#F0BC5E] rounded-[11px] py-[13px] px-[22px] w-[18%]"
          />
        </div>
        <div className="grid grid-cols-2 gap-x-[100px] gap-y-[75px] px-[200px] py-[60px]">
          {guidancePageContant.Face10.Perks.map((items) => {
            return (
              <div className="flex items-center gap-[2px] shadow-sm rounded-[17px] border border-[#a8a8a8] overflow-hidden">
                <div
                  className={`px-[8px] flex min-w-[120px] items-center h-full py-[8px] ${items.back} `}
                >
                  <Image src={items.img} className="w-[73%]" alt="Perk3" />
                </div>
                <div className=" ">
                  <Text className="text-[22px] text-[#00114C] font-bold px-1">
                    {items.desc}
                  </Text>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Face13;
