import { CompareRounded } from "@mui/icons-material";
import React from "react";
import { IoStar } from "react-icons/io5";
import { GoDownload } from "react-icons/go";
import { IoMdArrowForward } from "react-icons/io";
import { medias } from "../../../config/Resources";
import ClosingRankTable from "./ClosingRankTable";
import QuestionAccordion from "./QuestionAccordion";

const PopularCourses = ({ CollegeDetails }) => {
	const PopularCourses = [{}, {}, {}, {}];
	return (
		<div className="pt-5 pb-5 pl-4 pr-4">
			<div className="flex items-center gap-2 mt-5">
				<img
					className="w-12 h-12"
					src={medias.images.College.Courses}
					alt="courses"
				/>
				<div>
					<p className="font-semibold text-[#000000] opacity-50 font-plusJakartaSans text-[13px]">
						IIT Delhi
					</p>
					<p className="font-bold text-[#000000] font-plusJakartaSans  text-[18px]">
						Popular courses
					</p>
				</div>
			</div>
			{PopularCourses.map((_, idx) => {
				return (
					<div key={idx} className="rounded-lg mt-2 overflow-hidden shadow-md">
						<div className="px-6 py-4">
							<p className="text-[#000000] font-plusJakartaSans font-bold text-[14.5px]">
								B.E/B.Tech
							</p>
							<div className="flex item-center text-center justify-between">
								<div className="flex text-center gap-3 mt-1">
									<p className="text-[#000000] mt-[px] font-plusJakartaSans font-bold text-[14.5px] opacity-50">
										18 Courses
									</p>
									<div className="border-r border-gray-300 h-5"></div>
									<div className="flex item-center h-[20px] text-center bg-[#F3F3F3] pt-[3px] pb-[3px] pl-[10px] pr-[10px] rounded-sm">
										<p className="text-[#000000] flex gap-1 text-center items-center font-plusJakartaSans font-bold text-[13px]">
											<span className="text-[#FFC000] ">
												<IoStar />
											</span>
											4.5
										</p>
									</div>
									<p className="text-[#000000] mt-[2px] font-plusJakartaSans font-bold text-[13px]">
										48-50 Months
									</p>
								</div>
								<div className="flex text-center gap-2">
									<button
										variant="outlined"
										className="border items-center flex text-center pt-[8px] pb-[8px] pl-[18px] pr-[18px] gap-1 border-[#707070] font-semibold text-[#000000] rounded-full text-[11.5px] font-plusJakartaSans"
									>
										<CompareRounded className="text-[#000000] h-4 w-4" />
										<span className="">Compare</span>
									</button>
									<button
										variant="outlined"
										className="font-semibold flex text-center gap-1 pt-[8px] pb-[8px] pl-[18px] pr-[18px] bg-[#239403] text-white rounded-full text-[11.5px] font-plusJakartaSans"
									>
										<GoDownload className="text-white h-4 w-4" />
										<span className="">Download brochure</span>
									</button>
								</div>
							</div>
						</div>
					</div>
				);
			})}
			<button className="font-plusJakartaSans font-medium pt-2 pb-2 pl-4 pr-4 border border-[#707070] text-[15px] gap-1 mt-5 ml-auto mr-auto flex items-center text-center justify-center rounded-full">
				View All 11 Courses <IoMdArrowForward />
			</button>
			<ClosingRankTable />
			<QuestionAccordion CollegeDetails={CollegeDetails} />
		</div>
	);
};

export default PopularCourses;
