/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import AgoraRTC, {
  LocalVideoTrack,
  RemoteUser,
  useJoin,
  useLocalCameraTrack,
  useLocalMicrophoneTrack,
  usePublish,
  useRTCClient,
  useRemoteUsers,
  useClientEvent,
  AgoraRTCProvider,
} from "agora-rtc-react";
import { IMicrophoneAudioTrack, ICameraVideoTrack } from "agora-rtc-sdk-ng";
import { useEffect, useState } from "react";
import AgoraManager from "./AgoraManager";
import config from "./config";

const appId = "e011fb2b67bf4fe69c5b9eb42fb93a8d";
const channelName = "Channel1";
const token =
  "007eJxTYDjM4qvI8mYdW5k3L6/Zw00Kv/R5HVPub3g7g7VDa7fkMX8FhlQDQ8O0JKMkM/OkNJO0VDPLZNMky9QkE6O0JEvjRIuUrETjtIZARgbVugfMjAwQCOJzMDhnJOblpeYYMjAAAACaHng=";

const AgoraSdk = () => {
  const [joined, setJoined] = useState(false);

  const [client, setClient] = useState(null);
  const [localStream, setLocalStream] = useState(null);
  const [remoteStreams, setRemoteStreams] = useState([]);
  const [userData, setUerData] = useState();

  // const handleJoinClick = () => {
  //   setJoined(true);
  // };

  // const handleLeaveClick = () => {
  //   setJoined(false);
  // };

  // const renderActionButton = () => {
  //   return joined ? (
  //     <button onClick={handleLeaveClick}>Leave</button>
  //   ) : (
  //     <button onClick={handleJoinClick}>Join</button>
  //   );
  // };

  // const localCameraTrack = useLocalCameraTrack();

  // const localMicrophoneTrack = useLocalMicrophoneTrack();

  // useEffect(() => {
  //   return () => {
  //     localCameraTrack?.close();
  //     localMicrophoneTrack?.close();
  //   };
  // }, []);

  // const agoraEngine = useRTCClient(
  //   AgoraRTC.createClient({ codec: "vp8", mode: config.selectedProduct })
  // );

  // useClientEvent(agoraEngine, "user-joined", (user) => {
  //   console.log("The user", user.uid, " has joined the channel");
  // });

  // useClientEvent(agoraEngine, "user-left", (user) => {
  //   console.log("The user", user.uid, " has left the channel");
  // });

  // useClientEvent(agoraEngine, "user-published", (user, mediaType) => {
  //   console.log("The user", user.uid, " has published media in the channel");
  // });

  // const remoteUsers = useRemoteUsers();

  useEffect(() => {
    const initClient = async () => {
      const agoraClient = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
      setClient(agoraClient);

      // Handle user-published events
      agoraClient.on("user-published", async (user, mediaType) => {
        setUerData(user);
        await agoraClient.subscribe(user, mediaType);
        if (mediaType === "video") {
          const remoteVideoTrack = user.videoTrack;
          remoteVideoTrack.play(`remote_video_${user.uid}`);
        }
        if (mediaType === "audio") {
          user.audioTrack.play();
        }
      });

      // Handle user-unpublished events
      agoraClient.on("user-unpublished", (user) => {
        setUerData(user);
        document.getElementById(`remote_video_${user.uid}`)?.remove();
      });
    };

    initClient();

    return () => {
      client?.leave();
      client?.close();
    };
  }, []);

  const joinCall = async (channelName) => {
    if (!client) return;
    await client.join(appId, channelName, token);
    const [videoTrack, audioTrack] = await Promise.all([
      AgoraRTC.createCameraVideoTrack(),
      AgoraRTC.createMicrophoneAudioTrack(),
    ]);

    await client.publish([videoTrack, audioTrack]);
    setJoined(true);
    videoTrack.play("local_video");
  };

  // Function to leave a call
  const leaveCall = async () => {
    if (!client) return;
    await client.unpublish();
    await client.leave();
    setJoined(false);
    // Clean up local video
    document.getElementById("local_video").innerHTML = "";
    // Optionally remove all remote videos
    document.getElementById("remote_video").innerHTML = "";
  };

  return (
    // <div>
    //   {renderActionButton()}
    //   {joined && (
    //     <AgoraRTCProvider client={agoraEngine}>
    //       <AgoraManager
    //         config={config}
    //         children={
    //           <>
    //             <div className="vid" style={{ height: 300, width: 600 }}>
    //               <LocalVideoTrack track={localCameraTrack} play={true} />
    //             </div>
    //             {remoteUsers.map((remoteUser) => (
    //               <div
    //                 className="vid"
    //                 style={{ height: 300, width: 600 }}
    //                 key={remoteUser.uid}
    //               >
    //                 <RemoteUser
    //                   user={remoteUser}
    //                   playVideo={true}
    //                   playAudio={true}
    //                 />
    //               </div>
    //             ))}
    //           </>
    //         }
    //       />
    //     </AgoraRTCProvider>
    //   )}
    // </div>
    <div>
      <div id="local_video" style={{ width: "320px", height: "240px" }}></div>
      <div
        id={`remote_video_${userData?.uid}`}
        style={{ width: "320px", height: "240px" }}
      ></div>
      {!joined ? (
        <button onClick={() => joinCall(channelName)}>Join Call</button>
      ) : (
        <button onClick={leaveCall}>Leave Call</button>
      )}
    </div>
  );
};

export default AgoraSdk;
